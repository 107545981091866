import Backbone from 'backbone';
import _ from 'underscore';
import RecommendedPriorityModel from 'models/clients/measures/recommended-priority';

var RecommendedPriorityCollection = Backbone.Collection.extend({
  model: RecommendedPriorityModel,
  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.coordinates = true;
  },

  parse: function (data) {
    this.priorities = data.recommendedPriorities;
    this.getCoordinates();
    return data;
  },

  url: function () {
    return `clients/${this.client}/priorityindex/csat`;
  },

  getCoordinates: function () {
    this.priorityGroup = [];
    this.mapPaddings = [];
    this.chartWidth = 100;
    this.chartHeight = 100;

    this.createPriorityGroup();
    this.createMapPadding();

    this.priorityGroup.forEach((priority) => {
      var score = priority.data.score || 0,
        impact = priority.data.latentImpact || 0,
        impactRatio =
          (impact - this.mapPaddings.minImpact) / this.mapPaddings.impactRange,
        scoreRatio =
          (score - this.mapPaddings.minScore) / this.mapPaddings.scoreRange,
        xPos = impactRatio * this.chartWidth,
        yPos = scoreRatio * this.chartHeight;

      priority.ratioState = 'IMPACT';
      priority.impactRatio = impactRatio;
      priority.scoreRatio = scoreRatio;
      priority.x = xPos;
      priority.y = yPos;
      priority.isTopPriority = xPos > 50 && yPos < 50;
    });
  },

  createPriorityGroup: function () {
    this.priorities.forEach((priority) => {
      if (priority.data.latentImpact !== null) {
        this.priorityGroup.push(priority);
      }
    });
  },

  createMapPadding: function () {
    this.mapPaddings = this.setMaxMin(this.priorityGroup);
  },

  setMaxMin: function (priorities) {
    var minScore = parseFloat(_.min(priorities.map((p) => p.data.score))),
      maxScore = parseFloat(_.max(priorities.map((p) => p.data.score))),
      minImpact = parseFloat(_.min(priorities.map((p) => p.data.latentImpact))),
      maxImpact = parseFloat(_.max(priorities.map((p) => p.data.latentImpact))),
      scoreRange = maxScore - minScore,
      impactRange = maxImpact - minImpact;

    // by subtracting the min score by the score range and increasing the score range by 10
    // we create a range where the data will be buffered by 5 points on each side
    minScore -= 5;
    scoreRange += 10;

    // Add left and right padding to the map by increasing the range and decreasing the minimum
    // impact by a percentage
    impactRange += 0.5;
    minImpact -= 0.25;
    return {
      minImpact: minImpact,
      impactRange: impactRange,
      minScore: minScore,
      scoreRange: scoreRange,
    };
  },
});

export default RecommendedPriorityCollection;

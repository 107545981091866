import _ from 'underscore';
import BaseController from 'controllers/base';
import PublishModel from 'models/clients/projects/publish';
import PublishProdStatusProjectModel from 'models/clients/projects/publish-prod-status-project';
import PublishStagingStatusProjectModel from 'models/clients/projects/publish-staging-status-project';
import $ from 'jquery';
import moment from 'moment';

class PublishingController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'publishing:site:get': 'getSite',
        'publishing:staging': 'publishStaging',
        'publishing:prod': 'publishProd',
        'publishing:publish': 'handlePublish',
        'publishing:stagingStatus:get': 'stagingStatus',
        'publishing:prodStatus:get': 'prodStatus',
      },
      name: 'publishing',
      dependsOn: ['feedback-survey', 'feedback-settings', 'hosted-code'],
    });
  }

  initialize(opts) {
    var options = opts || {};

    this.clientId = options.clientId = this.getClientId();
    this.options = options;
    this.publishProjectModel = new PublishModel(null, options);
    this.publishStagingStatusProjectModel =
      new PublishStagingStatusProjectModel(null, options);
    this.publishProdStatusProjectModel = new PublishProdStatusProjectModel(
      null,
      options
    );
    this.publishingStatus = {
      staging: false,
      production: false,
    };
  }

  /*
   * Parses UberJS timestamps and returns an object with time as milliseconds since 1970 and a formatted time string
   */
  parseTime(time) {
    var timeOffset = '+00:00';
    var suffix = time.slice(-3);
    var includesTimeOffset = time.indexOf('+') !== -1;
    var format = 'YYYY-MM-DDTHH:mm:ss.SSSSZ';

    if (isNaN(parseInt(suffix, 10)) && !includesTimeOffset) {
      format = 'YYYY-MM-DD hh:mm:ss A ZZ';
      if (suffix === 'IDT') {
        timeOffset = '+03:00';
      } else if (suffix === 'IST') {
        timeOffset = '+02:00';
      } else if (suffix === 'EDT') {
        timeOffset = '-04:00';
      } else if (suffix === 'EST') {
        timeOffset = '-05:00';
      }

      time = time.slice(0, time.length - 3);
    }

    if (!includesTimeOffset) time += timeOffset;
    var momentTime = moment(time, format);
    var timeDate = momentTime._d;

    var minutes = timeDate.getMinutes();
    var minutesString = '' + minutes;
    if (minutesString.length === 1) {
      minutes = '0' + minutes;
    }

    var timeString =
      timeDate.getMonth() +
      1 +
      '/' +
      timeDate.getDate() +
      '/' +
      timeDate.getFullYear() +
      ' ' +
      timeDate.getHours() +
      ':' +
      minutes;
    var unixTime = momentTime.valueOf();
    return {
      time: unixTime,
      timeString: timeString,
    };
  }

  getSite(payload) {
    const { callback, errorCallback } = payload;
    const def = new $.Deferred();

    this.publish('hosted:get:sites', {
      callback: (site) => {
        this.site = site;

        def.resolve(site);
        callback(site);
      },
      errorCallback: (err) => {
        if (errorCallback) {
          errorCallback(err);
        }
      },
    });

    return def;
  }

  getConfig(container) {
    const def = new $.Deferred();
    this.publish('hosted:get:config', {
      container: container,
      site: this.site.name,
      callback: (config) => {
        if (config) {
          def.resolve(config.data);
          return;
        }
        def.resolve();
      },
    });
    return def;
  }

  /*
   * Publishes project to Staging and returns staging project status when publish is complete
   */
  publishStaging(payload) {
    var projectId = payload.projectId;
    this.publishProjectModel.setEnvironment('staging');
    this.publishProjectModel.setProjectId(projectId);

    this.publishProjectModel
      .save(null, {
        doNotAbort: true,
        error: (model, err) => {
          const error = err.responseJSON;
          if (error) {
            if (error.errorCode === 409) {
              this.publish('app:messageFlash', {
                messageText: this.strings.projects.publishing.error409Staging,
                messageType: 'error',
              });
            } else {
              this.publish('app:messageFlash', {
                messageText: error.message,
                messageType: 'error',
              });
            }

            payload.publishing = false;
            this.stagingStatus(payload);
          }
        },
      })
      .done(
        function (data) {
          payload.publishing = true;
          this.stagingStatus(payload);
        }.bind(this)
      );
  }

  publishProd(payload) {
    var projectId = payload.projectId;
    this.publishProjectModel.setEnvironment('production');
    this.publishProjectModel.setProjectId(projectId);
    let attributes = {},
      options = { doNotAbort: true };

    if (typeof payload.configOnly !== 'undefined') {
      attributes.configOnly = payload.configOnly;
    }

    if (typeof payload.enabled !== 'undefined') {
      attributes.enabled = payload.enabled;
    }

    options.success = (r, response) => {
      this.publish('app:messageFlash', {
        messageText: response.message,
        messageType: 'notify',
      });
    };
    options.error = (r, response) => {
      this.publish('app:messageFlash', {
        messageText: response.responseJSON.message,
        messageType: 'error',
      });
    };

    this.publishProjectModel.save(attributes, options).done(
      _.bind(function (res) {
        payload.publishing = true;
        //this.prodStatus(payload);
        if (payload.callback) {
          payload.callback(res);
        }
      }, this)
    );
  }

  /*
   * Publishes project to Production and returns production project status when publish is complete
   */
  handlePublish(payload) {
    //Cancel publish if a one if already in progress.
    if (this.publishingStatus[payload.environment]) {
      return;
    }
    var projectId = payload.projectId;
    this.publishProjectModel.setEnvironment(payload.environment);
    this.publishingStatus[payload.environment] = true;
    this.publishProjectModel.setProjectId(projectId);
    let attributes = {},
      options = { doNotAbort: true };

    if (typeof payload.configOnly !== 'undefined') {
      attributes.configOnly = payload.configOnly;
    }

    if (typeof payload.enabled !== 'undefined') {
      attributes.enabled = payload.enabled;
    }

    const handler = (res) => {
      payload.publishing = true;
      if (attributes.configOnly && payload.environment === 'staging') {
        this.publish('feedback-settings:publishedToStaging');
      }

      this.publishingStatus[payload.environment] = false;
      if (payload.callback) {
        payload.callback(res);
      }
    };

    options.success = (r, response) => {
      this.publish('app:messageFlash', {
        messageText: response.message,
        messageType: 'notify',
      });
    };
    options.error = (r, response, post) => {
      const error = response.responseJSON;
      if (error) {
        this.publish('app:messageFlash', {
          messageText: error.message,
          messageType: 'error',
        });

        if (post.callback) {
          post.callback({ error: true });
        }
        handler({ error: true });
      }
    };

    if (payload.callback) {
      options.callback = payload.callback;
    }
    this.publishProjectModel.save(attributes, options).done(handler);
  }

  getStatus(status) {
    switch (status) {
      case 'failure':
        return 'UNSUCCESSFUL';
      case 'success':
        return 'SUCCESSFUL';
      default:
        return 'STATUS_NOT_FOUND';
    }
  }

  /*
   * returns staging status
   */
  stagingStatus(payload) {
    if (payload.noStatusUpdate) {
      return;
    }

    const publishing = payload.publishing ? true : false;
    this.getConfig('staging').then((config) => {
      var projectId = payload.projectId;
      this.publishStagingStatusProjectModel.setProjectId(projectId);

      this.publishStagingStatusProjectModel
        .fetch({
          error: (model, err) => {
            if (err.status === 404) {
              this.publish('publishing:stagingStatus:data', {
                status: 'STATUS_NOT_FOUND',
              });
            }
          },
        })
        .then((stagingProject) => {
          let codeVer = null;
          var stagingTime = { time: null, timeString: null },
            status = this.getStatus(stagingProject.status);

          stagingTime = this.parseTime(stagingProject.publishedOn);

          if (config) {
            config = JSON.parse(config.config);
            codeVer = config.codeVer;
          }

          if (publishing && status === 'SUCCESSFUL') {
            this.publish('app:messageFlash', {
              messageText: this.strings.projects.publish.publishSuccessNotice,
              messageType: 'notify',
              action: this.strings.projects.publish.iGotIt,
            });
          }
          var stagingStatus = {
            config: config,
            codeVer: codeVer,
            status: status,
            timeString: stagingTime.timeString,
            time: stagingTime.time,
          };

          if (payload.refreshSurvey) {
            this.publish('feedback-survey:refresh');
          }
          this.publish('publishing:stagingStatus:data', stagingStatus);
        });
    });
  }

  /*
   * Return production status
   */
  prodStatus(payload) {
    const publishing = payload.publishing ? true : false;
    this.getConfig('production').then((config) => {
      var prodTime = { time: null, timeString: null };
      var projectId = payload.projectId;
      this.publishProdStatusProjectModel.setProjectId(projectId);

      this.publishProdStatusProjectModel
        .fetch({
          error: (model, err) => {
            if (err.status === 404) {
              this.publish('publishing:prodStatus:data', {
                status: 'STATUS_NOT_FOUND',
                prodTime: prodTime,
              });
            }
          },
        })
        .then((prodProject) => {
          let codeVer = null;
          var status = this.getStatus(prodProject.status);

          prodTime = prodProject.publishedOn || null;
          prodTime = prodTime
            ? this.parseTime(prodTime)
            : { time: null, timeString: null };

          if (config) {
            config = JSON.parse(config.config);
            codeVer = config.codeVer;
          }

          if (publishing && status === 'SUCCESSFUL') {
            this.publish('app:messageFlash', {
              messageText: this.strings.projects.publish.publishSuccessNotice,
              messageType: 'notify',
              action: this.strings.projects.publish.iGotIt,
            });
          }

          var prodStatus = {
            config: config,
            codeVer: codeVer,
            status: status,
            timeString: prodTime.timeString,
            time: prodTime.time,
          };

          if (payload.refreshSurvey) {
            this.publish('feedback-survey:refresh');
          }
          this.publish('publishing:prodStatus:data', prodStatus);
        });
    });
  }
}

export default PublishingController;

import _ from 'underscore';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Constants from 'core/constants';
import BaseController from 'controllers/base';
import DateRange from 'models/dateRange';
import LeaderboardModel from 'models/clients/hierarchies/leaderboard';
import CardBookModel from 'models/export/card-book';
import PPTRequestModel from 'models/export/ppt-request';
import PPTFileModel from 'models/export/ppt-file';

class LeaderboardsController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'location:change': 'getRoute',
        'leaderboards:daterange:lookup': 'handleCalendarLookup',
        'leaderboards:daterange:calendarChange': 'handleCalendarChange',
        'leaderboards:daterange:get': 'getDateRange',
        'leaderboards:hierarchies:get': 'getHierarchies',
        'leaderboards:hierarchy:get': 'getHierarchy',
        'leaderboards:levels:get': 'getLevels',
        'leaderboards:latents:get': 'getLatents',
        'leaderboards:summary:get': 'getSummaryData',
        'leaderboards:hierarchy:measure:get': 'getHierarchyMeasureAssociation',
        'leaderboards:export:details:csv': 'exportLeaderboardDetailsCSV',
        'leaderboard:export:ppt': 'exportLeaderboardDashboard',
        'leaderboard:store:rank:get': 'getRankFromStorage',
        'leaderboard:store:rank:set': 'setRankToStorage',
        'leaderboard:store:status:get': 'getStatusFromStorage',
        'leaderboard:store:status:set': 'setStatusToStorage',
      },
      name: 'leaderboards',
      dependsOn: ['measures', 'hierarchy', 'cxmeasure'],
    });
  }

  exportLeaderboardDetailsCSV(payload) {
    const { hierarchy, level, view, nodeId } = payload;
    this.publish('app:messageFlash', {
      messageText: this.getString('export.generateCSV'),
      messageType: 'info',
      indefinite: true,
      spinner: true,
    });
    this.publish('export:leaderboard:details:csv', {
      hierarchy,
      level,
      view,
      nodeId,
      ...this.getSimpleLeaderboardCriteria(payload),
    });
  }

  exportLeaderboardDashboard(chartData) {
    var today = new moment().startOf('day');
    this.exportPPT(
      chartData,
      'leaderboard',
      `ForeSee Leaderboard: ${today.format('MMM DD, YYYY')}`
    );
  }

  exportPPT(allChartCardData, url, fileName) {
    var today = new moment().startOf('day');
    var cardBook = new CardBookModel();
    if (url) {
      cardBook.urlRoute = url;
    }

    var slides = [];
    for (var key in allChartCardData) {
      const chart = allChartCardData[key];
      const data = this.clone(chart);
      if (!data) {
        continue;
      }

      delete data.export;
      slides.push({
        chart: {
          route: chart.export.route || key,
          data: data,
          resolution:
            chart.export.width && chart.export.height
              ? {
                  width: chart.export.width,
                  height: chart.export.height,
                }
              : null,
        },
        footer: {
          exported: `${this.strings.user.user_full_name} on ${today.format(
            'MMM DD, YYYY'
          )}`,
          n: chart.export.NCount,
          source: this.controllers.user.client.get('name'),
        },
        header: {
          subtitle: {
            date: chart.export.date,
          },
          title: chart.export.title,
        },
      });
    }
    var _this = this;

    cardBook.set('fileName', fileName);
    cardBook.set('slides', slides);
    cardBook.save(function (err, response, status, jqXHR) {
      if (!response) {
        _this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*A server error occurred*',
        });
        return;
      } else {
        const pptRequestModel = new PPTRequestModel(response);
        pptRequestModel.save().then(() => {
          _this.pollPPT(pptRequestModel);
        });
      }
    });
  }

  pollPPT(pptRequestModel) {
    var _this = this;

    pptRequestModel.fetch().then(() => {
      const completed = pptRequestModel.get('completed');
      const failed = pptRequestModel.get('failed');

      if (failed) {
        _this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*A server error occurred*',
        });
        return;
      }

      if (!completed) {
        _.delay(_.bind(_this.pollPPT, _this), 2500, pptRequestModel);
      } else {
        const pptFileModel = new PPTFileModel({
          fileName: pptRequestModel.get('fileName'),
          exportId: pptRequestModel.get('exportId'),
        });

        pptFileModel.fetch().then(() => {
          saveAs(pptFileModel.blob, pptFileModel.get('fileName') + '.pptx');
        });
      }
    });
  }

  initialize(opts) {
    this.clientId = this.getClientId();
    this.clientModel = this.getClientModel();

    this.measureInitialized = false;
    this.hierarchies = null;
    this.currentHierarchy = {};

    let fiscalConfig;
    if (this.clientModel.hasFiscalCalendar) {
      fiscalConfig = {
        fiscalWeekStart: this.clientModel.fiscalWeekStart,
        fiscalYears: this.clientModel.fiscalYears,
      };
    }

    this.dateRange = {
      customerKey: this.clientId,
      rangeType: 'M',
      presetIndex: 2,
      calendarType: 'G',
      number: 0,
      dateRangeType: 'DATE_ONLY',
      fiscalConfig: fiscalConfig,
    };

    this.publish('storage:get', {
      key: 'leaderboards-dateRange',
      callback: (payload) => {
        if (!payload.expired && payload.data && payload.data.data) {
          const dateRange = payload.data.data;
          this.dateRange = {
            customerKey: this.clientId,
            startDate: new moment(dateRange.startDate),
            endDate: new moment(dateRange.endDate),
            rangeType: dateRange.rangeType,
            calendarType: dateRange.calendarType,
            number: dateRange.number,
            presetIndex: dateRange.presetIndex,
            fiscalConfig: fiscalConfig,
          };
        }

        this.handleCalendarLookup(this.dateRange);
      },
    });

    this.fetchMeasures();
    this.fetchHierarchies();
  }

  fetchMeasures() {
    this.publish('measures:get');
  }

  fetchHierarchies(payload = {}) {
    const { callback } = payload;

    this.publish('hierarchy:get', {
      callback: (data) => {
        const hierarchies = data.results;
        this.hierarchies = hierarchies;
        this.getRoute();

        if (callback) {
          callback(hierarchies);
        } else {
          this.publish('leaderboards:hierarchies:data', this.hierarchies);
        }
      },
    });
  }

  getHierarchies(payload = {}) {
    const { callback } = payload;

    if (callback) {
      callback(this.hierarchies);
    }
  }

  getRoute(payload) {
    this.publish('route:get', {
      callback: (data) => {
        data = data || {};
        data = data.pathIds || {};

        const hierarchyId = Number(data.hierarchy);
        if (_.isNumber(hierarchyId) && !isNaN(hierarchyId)) {
          const hierarchy = _.findWhere(this.hierarchies, {
            id: hierarchyId,
          });

          this.setHierarchy(hierarchy);
        }
      },
    });
  }

  handleCalendarChange(calendarType) {
    this.dateRange.calendarType = calendarType;
    this.handleCalendarLookup(this.dateRange);
  }

  handleCalendarLookup(dateRange, payload) {
    const options = payload || {};
    const dateRangeType = dateRange.dateRangeType || 'DATE_ONLY',
      calendarType = dateRange.calendarType || 'G',
      clientId = calendarType === 'G' ? '' : this.clientId,
      periodType = dateRange.periodType || 'D',
      presetIndex = dateRange.selected || 0,
      onlyReturnDateRange = options.onlyReturnDateRange || null,
      callback = dateRange.callback || null;

    let today = dateRange.today || new moment(),
      first = null,
      last = null;

    if (dateRange.rangeType === 'C') {
      first = dateRange.startDate;
      last = dateRange.endDate;
    }

    if (
      (dateRangeType === 'DATE_TIME' &&
        dateRange.number === 0 &&
        dateRange.rangeType === 'D') ||
      dateRange.rangeType === 'H'
    ) {
      today = null;
    }

    const date = new DateRange({
      customerKey: clientId,
      calendarType: calendarType,
      rangeType: dateRange.rangeType,
      number: dateRange.number,
      asOf: today,
      first: first,
      last: last,
      periodType: periodType,
      dateRangeType: dateRangeType,
    });

    _.extend(date, {
      urlparams: {
        dateRange: JSON.stringify(date.toJSON({ format: 'YYYY-MM-DD' })),
      },
    });

    return date.fetch().then((data) => {
      const dateRange = this.setDateRange(this.parseDates(data), presetIndex);

      if (onlyReturnDateRange) {
        onlyReturnDateRange(dateRange);
        return;
      }

      this.dateRange = _.extend({}, this.dateRange, dateRange);
      this.publish('storage:set', {
        key: 'leaderboards-dateRange',
        data: this.dateRange,
      });
      this.publish('leaderboards:dateRange', this.dateRange);

      if (callback) {
        callback(data);
      }
    });
  }

  parseDates(dateRange) {
    const parseItem = function (t, type) {
      var types = DateRange[type];
      if (_.contains(_.keys(types), t)) {
        t = types[t];
      }
      return t;
    };

    return {
      c: parseItem(dateRange.c, 'calendarTypes'),
      r: parseItem(dateRange.r, 'rangeTypes'),
      p: parseItem(dateRange.p, 'periodTypes'),
      n: dateRange.n,
      a: dateRange.a,
      f: dateRange.f,
      l: dateRange.l,
      k: dateRange.k,
      t: dateRange.t,
      v: dateRange.v,
    };
  }

  getCompareDate(dateRange) {
    if (dateRange.rangeType === 'C') {
      const startDate = dateRange.startDate;
      const endDate = dateRange.endDate;

      return _.extend({}, dateRange, {
        startDate: new moment(startDate)
          .subtract(
            new moment(endDate).diff(new moment(startDate), 'days'),
            'days'
          )
          .subtract(1, 'days'),
        endDate: new moment(startDate).subtract(1, 'days'),
      });
    }

    return _.extend({}, dateRange, {
      periodType: 'P',
      startDate: '',
      endDate: '',
      number: dateRange.number,
    });
  }

  setDateRange(data, presetIndex) {
    return {
      startDate: new moment(data.f),
      endDate: new moment(data.l),
      calendarType: data.c,
      number: data.n,
      rangeType: data.r,
      presetIndex: presetIndex,
    };
  }

  getDateRange() {
    this.publish('leaderboards:dateRange', this.dateRange);
  }

  getLatents(payload) {
    const { measurementKey, callback } = payload;

    this.publish('measures:latents:get', {
      measurementKey,
      callback,
    });
  }

  getLevels(payload) {
    const { hierarchyId, callback } = payload;

    this.publish('hierarchy:levels:get', {
      hierarchyId: hierarchyId,
      capabilityName: Constants.CAPABILITIES.CXMEASURE_ACCESS,
      callback: (levels) => {
        levels = _.values(levels);

        if (callback) {
          levels.length ? callback(levels) : callback([]);
        }
      },
    });
  }

  getSimpleLeaderboardCriteria(payload) {
    const { filterInfo, noCompareDate } = payload;
    const requestedMetrics = payload.metrics;

    let sortMetric = {};
    const metrics = [];
    for (let i = 0; i < requestedMetrics.length; i++) {
      const requestedMetric = requestedMetrics[i];
      const { columnType, mKey, metricKey, suffix } = requestedMetric;
      const isSortMetric = requestedMetric.sortMetric;
      const sortMetricKey = metricKey + suffix;

      const noColumnType =
        columnType === 'l' || columnType === 'ad' || columnType === 'q';
      const metric = {
        serviceType: 'cxmeasure',
        metricKey: noColumnType
          ? '' + metricKey
          : metricKey + columnType.toUpperCase(),
        columnType: columnType.toLowerCase(),
        serviceMetadata: {
          mkey: '' + mKey,
        },
      };

      if (isSortMetric) {
        sortMetric = {
          ...metric,
          metricKey: suffix ? sortMetricKey : metric.metricKey,
        };
      }

      metrics.push(metric);
    }

    const date = new DateRange(this.dateRange);
    const compareDate = this.getCompareDate(this.dateRange);

    return {
      dateRange: date.toJSON({ format: 'YYYY-MM-DD' }),
      compareDateRange: noCompareDate
        ? undefined
        : new DateRange(compareDate).toJSON({ format: 'YYYY-MM-DD' }),
      sortMetric: sortMetric,
      metrics: metrics,
      filterInfo: filterInfo || {},
    };
  }

  getSummaryData(payload) {
    const {
      nodeId,
      hierarchyId,
      level,
      view,
      isSparse,
      limit,
      offset,
      callback,
    } = payload;

    const model = new LeaderboardModel(
      this.getSimpleLeaderboardCriteria(payload),
      {
        clientId: this.clientId,
        hierarchyId: hierarchyId,
        nodeId: nodeId,
        level: level,
        limit: limit || 10,
        offset: offset || 1,
        view: view || 'level',
        isSparse: isSparse,
      }
    );

    model
      .save(null, {
        error: (model, response) => {
          if (response.status === 504) {
            //To handle the error from services when querying a very large hierarchy with multiple measures.
            if (callback) {
              callback({
                tooLargeError: true,
              });
            }
          }
        },
      })
      .then(() => {
        if (callback) {
          callback(model.toJSON());
        }
      });
  }

  setHierarchy(hierarchy) {
    this.currentHierarchy = hierarchy;
    this.publish('leaderboards:hierarchy:current', this.currentHierarchy);
    //this.publish('measures:set', measure);
  }

  getHierarchy() {
    this.publish('leaderboards:hierarchy:current', this.currentHierarchy);
  }

  getHierarchyMeasureAssociation(payload) {
    const { hierarchyId, callback } = payload;

    this.publish('hierarchy:associations:get', {
      capabilityGroup: Constants.CAPABILITY.CX_MEASURE,
      hierarchyId: hierarchyId,
      callback: (associations) => {
        let validMeasures = [];
        if (associations && associations.config && associations.config.length) {
          const clientMeasures = this.controllers.measures.measures.toJSON();
          const measures = _.filter(associations.config, (measure) => {
            return measure.measurementKey && measure.measurementKey !== 'null';
          });
          const ids = {};
          _.each(clientMeasures, function (measure) {
            ids[measure.measurementKey] = true;
          });

          validMeasures = _.filter(measures, function (measure) {
            return ids[measure.measurementKey];
          });
          validMeasures.forEach((vm) => {
            const fullMeasure = _.findWhere(clientMeasures, {
              measurementKey: vm.measurementKey,
            });
            if (fullMeasure) {
              vm.activeStatus = fullMeasure.activeStatus;
              vm.measurementKey = `${fullMeasure.measurementKey}`;
              vm.label = fullMeasure.name;
            }
          });
        }

        this.publish('leaderboards:hierarchy:measure:data', validMeasures);
        if (callback) {
          callback(validMeasures);
        }
      },
    });
  }

  transformKeyWithClient(key) {
    return `${key}-${this.clientId}`;
  }

  getFromStorage(key, callback) {
    this.publish('storage:user:get', {
      key: this.transformKeyWithClient(key),
      callback: (store) => {
        if (store && !store.expired && store.data && store.data.data) {
          callback(store.data.data);
        }
      },
    });
  }
  setFromStorage(key, data) {
    this.publish('storage:user:set', {
      key: this.transformKeyWithClient(key),
      update: true,
      data,
    });
  }

  getRankFromStorage(payload) {
    const { callback } = payload;
    this.getFromStorage('leaderboards-rank', callback);
  }
  setRankToStorage(rank) {
    this.setFromStorage('leaderboards-rank', rank);
  }

  getStatusFromStorage(payload) {
    const { callback } = payload;
    this.getFromStorage('leaderboards-status', callback);
  }
  setStatusToStorage(status) {
    this.setFromStorage('leaderboards-status', status);
  }
}

export default LeaderboardsController;

import React from 'react';

export const initialState = {
  dashboards: {
    // Objects here should have a shape of
    // dashboardId: {
    //   isEnabled: <bool>,
    //   dateRange: <DateRange>,
    //   cardData: {
    //     dateRange: <DateRange>,
    //     selectedDateId: <int>
    //    }
    // },
  },
  toggleDashboardFiltering: () => {},
  toggleCardFiltering: () => {},
  toggleDateFilter: () => {},
};

const DateFilterContext = React.createContext(initialState);

DateFilterContext.displayName = 'DateFilterContext';

export default DateFilterContext;

import Backbone from 'backbone';
import _ from 'underscore';
import MasterDataVersionsModel from 'models/clients/masterdata/versions';

const MasterDataVersionsCollection = Backbone.Collection.extend({
  model: MasterDataVersionsModel,
});
const MasterDataPageableVersionsModel = Backbone.Model.extend({
  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.masterDataId = options.masterDataId;
    this.masterDataTypeId = options.masterDataTypeId;
    this.offset = options.offset;
    this.limit = options.limit;
  },

  parse: function (data) {
    data.results = new MasterDataVersionsCollection(data.results, {
      parse: true,
    });
    return data;
  },

  toJSON: function () {
    return _.extend(this.attributes, {
      results: this.attributes.results.toJSON(),
    });
  },

  getUserIds: function () {
    return this.get('results').map((result) => {
      return result.get('updatedBy');
    });
  },

  setUsers: function (users) {
    this.get('results').forEach((result) => {
      const user = _.findWhere(users, { userId: result.get('updatedBy') });
      result.set('updatedByUser', user);
    });
  },

  url: function () {
    const query = {
      offset: this.offset,
      limit: this.limit,
    };
    let id = this.masterDataId;
    let type = 'masterdata';
    //If masterdatatypeid is defined, we are wanting to fetch masterdatatype versions.
    if (this.masterDataTypeId) {
      id = this.masterDataTypeId;
      type = 'masterdatatypes';
    }
    return `clients/${this.client}/${type}/${id}/versions?${new URLSearchParams(
      query
    )}`;
  },
});

export default MasterDataPageableVersionsModel;

import Backbone from 'backbone';

var OpenEnds = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    date: '',
    comment: '',
    scores: [],
    commentKey: 0,
    respondentKey: 0,
    answers: [],
  },

  parse: function (data) {
    return {
      date: data.responseDate,
      timestamp: data.responseDateTime,
      comment: typeof data.comments !== 'undefined' ? data.comments[0] : '',
      comments: data.comments,
      scores: data.scores,
      commentKey:
        typeof data.comments !== 'undefined'
          ? parseInt(data.comments[0].qKey, 10)
          : '',
      respondentKey: parseInt(data.respondentKey, 10),
      answers: data.answers,
    };
  },

  idAttribute: 'respondentKey',

  serviceId: 'foresee-services',

  /*toJSON: function () {
      return _.extend(this.attributes, {
        openends: parseFloat(this.get('score').toFixed(1))
      });
    },*/

  /**
   * Constructor
   */
  initialize: function (opts) {},

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default OpenEnds;

import Backbone from 'backbone';

const GlobalConfigModal = Backbone.Model.extend({
  initialize: function (attributes, opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.site = options.siteId;
    this.container = options.containerId;
    this.posting = options.posting;
  },

  parse: function (data) {
    if (typeof data.config !== 'undefined') {
      this.config = JSON.parse(data.config);
      data.config = this.config;
      this.posting = data.posting;
    }
    return data;
  },

  url: function () {
    if (this.get('posting')) {
      return `clients/${this.client}/sites/${this.site}/containers/${this.container}/publish`;
    }
    return `clients/${this.client}/sites/${this.site}/containers/${this.container}/currentConfig`;
  },
});

export default GlobalConfigModal;

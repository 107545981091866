import Backbone from 'backbone';
import moment from 'moment';

var Respondents = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    answers: [],
    responseDate: '',
    scores: [],
    respondentKey: '',
  },
  parse: function (data) {
    return {
      globalId: data.globalId,
      answers: data.answers,
      comments: data.comments,
      rawResponseDate: data.responseDate,
      responseDate: moment(data.responseDateTime).format('YYYY-MM-DD'),
      responseDateTime: data.responseDateTime,
      score:
        data.scores && data.scores[0] ? Math.round(data.scores[0].score) : 0,
      scores: data.scores,
      respondentKey: parseInt(data.respondentKey, 10),
      respondentId: data.respondentId,
      npsSegment: data.npsSegment,
    };
  },
  idAttribute: 'latentKeys',

  /**
   * Constructor
   */
  initialize: function (opts) {},

  serviceId: 'foresee-services',

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default Respondents;

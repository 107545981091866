import _ from 'underscore';
import constants from 'core/constants';

var Permissions = function (options) {
  this.options = options;

  this.userRoles = ['NO_ACCESS'];

  this.populateUserRoles = function (roles) {
    this.userRoles = _.pluck(roles, 'authority');
  };
};
/**
 * takes a string corresponding to the users human readable role defined in the app/constants PERMISSIONS_MAP object, eg. { 'builder': [ROLES] }
 * and compares the [ROLES] with the roles a user has in their user object.
 * returns true if ANY match
 */
Permissions.prototype.userRole = function (request, requireAll) {
  var permitted = false,
    requiredRoles = [];

  if (_.isArray(request)) {
    _.each(
      request,
      function (role) {
        requiredRoles.push(this.options.globalPermissions[role]);
      },
      this
    );

    requiredRoles = _.flatten(requiredRoles);
  } else {
    requiredRoles = this.options.globalPermissions[request];
  }

  if (requiredRoles) {
    if (requireAll) {
      permitted = _.every(
        requiredRoles,
        function (req) {
          return !!_.find(this.userRoles, function (role) {
            return req === role;
          });
        },
        this
      )
        ? true
        : permitted;
    } else {
      _.each(
        requiredRoles,
        function (req) {
          permitted = _.some(this.userRoles, function (role) {
            return role === req;
          })
            ? true
            : permitted;
        },
        this
      );
    }
  }

  return permitted;
};
// Filters out config settings where the user doesn't meet permissions criteria
Permissions.prototype.checkPermission = function (request) {
  var permitted = [];

  if (_.isArray(request)) {
    _.each(
      request,
      function (role) {
        console.log(role);
        if (
          role.permissions === undefined ||
          this.userRole(role.permissions, true)
        ) {
          permitted.push(role);
        }
      },
      this
    );
  } else {
    if (this.userRole(request.permissions)) {
      permitted.push(request);
    }
  }
  return permitted[0];
};
Permissions.prototype.getInsightsUrl = function () {
  var openends = this.userRole('feedback_access'),
    questions = this.userRole('feedback_access'),
    summary = this.userRole('feedback_access'),
    insightsUrl = null;

  insightsUrl = openends ? 'openends' : insightsUrl;
  insightsUrl = questions ? 'questions' : insightsUrl;
  insightsUrl = summary ? 'summary' : insightsUrl;
  return insightsUrl;
};

export default new Permissions({
  globalPermissions: constants.PERMISSION_MAP,
});

import Backbone from 'backbone';
import AssignmentRulesModel from 'models/authorization-service/clients/assignment-rules';

const ClientRolesCollection = Backbone.Collection.extend({
  model: AssignmentRulesModel,

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
  },

  parse: function (data) {
    return data.items;
  },

  url: function () {
    return `authorization-service/clients/${this.client}/assignment-rules`;
  },
});

export default ClientRolesCollection;

import { getAttribute } from 'core/utils/card';

function getPriorityResponseCount(priorities, card) {
  const hiddenKeys = getAttribute(card, 'hiddenMeasurementKeys') || [];
  return priorities
    .filter(
      (p) =>
        !hiddenKeys.includes(p.measurement.key.toString()) &&
        (!p.data.errorType || p.data.errorType !== 'UnknownCalculationError')
    )
    .reduce((sum, p) => sum + (p.data.ncount || 0), 0);
}

export function getCardResponseCount(data, card) {
  // priority data is only requested once then filtered client-side, hence
  // logic for response count needs handled client-side.
  return data.priorities
    ? getPriorityResponseCount(data.priorities, card)
    : data.totalCount;
}

export function isCardDataEmpty(cardData) {
  return (
    cardData === null ||
    cardData === undefined ||
    Object.keys(cardData).length <= 1
  );
}

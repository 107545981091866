import Backbone from 'backbone';

const ApiConsumerModel = Backbone.Model.extend({
  idAttribute: 'clientId',
  initialize: function (opts) {
    this.clientId = opts.clientId || '';
  },
  url: function () {
    return `api-administration/consumers/${this.clientId}`;
  },
});

export default ApiConsumerModel;

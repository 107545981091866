import React from 'react';
import BasePanel from 'views/panels/base';

import { SvgIcon } from 'cx-component-library';
import './styles.scss';

class WidgetPWPolicies extends BasePanel {
  static defaultProps = {
    passwordPolicies: {},
  };

  constructor(props) {
    super(props);

    this.newPassword = '';
    this.verifyassword = '';
  }

  // ref method
  setNewPassword(newPassword) {
    this.newPassword = newPassword;
  }
  // ref method
  setVerifyPassword(verifyPassword) {
    this.verifyPassword = verifyPassword;
  }

  // ref method
  arePWPoliciesValid() {
    return (
      this.isPWPolicyValid('minLength') &&
      this.isPWPolicyValid('minUpperCase') &&
      this.isPWPolicyValid('minLowerCase') &&
      this.isPWPolicyValid('minNumber') &&
      this.isPWPolicyValid('minSymbol') &&
      this.isPWPolicyValid('passwordMatch')
    );
  }

  clientIsCheckingPolicy(pwPolicy) {
    const { passwordPolicies } = this.props;
    const { minLength, minUpperCase, minLowerCase, minNumber, minSymbol } =
      passwordPolicies;

    switch (pwPolicy) {
      case 'minLength':
        return minLength !== 0;
      case 'minUpperCase':
        return minUpperCase !== 0;
      case 'minLowerCase':
        return minLowerCase !== 0;
      case 'minNumber':
        return minNumber !== 0;
      case 'minSymbol':
        return minSymbol !== 0;
      default:
        return true;
    }
  }

  isPWPolicyValid(pwPolicy) {
    const { passwordPolicies } = this.props;
    const { newPassword, verifyPassword } = this;

    const { minLength } = passwordPolicies;

    if (!newPassword) {
      return false;
    }

    if (!this.clientIsCheckingPolicy(pwPolicy)) {
      return true;
    }

    switch (pwPolicy) {
      case 'minLength':
        return newPassword.length >= minLength;
      case 'minUpperCase':
        const upperCaseChars = newPassword.replace(/[^A-Z]/g, '').length;
        return upperCaseChars > 0;
      case 'minLowerCase':
        const lowerCaseChars = newPassword.replace(/[^a-z]/g, '').length;
        return lowerCaseChars > 0;
      case 'minNumber':
        const numberChars = newPassword.replace(/[^\d]/g, '').length;
        return numberChars > 0;
      case 'minSymbol':
        const specialCharactersRegex =
          /[~!@#$%^&*_\-+=`\\|(){}\[\]:;"'<>,.?\/]+/;
        return specialCharactersRegex.test(newPassword);
      case 'passwordMatch':
        return newPassword === verifyPassword;
    }

    return false;
  }

  render() {
    const { passwordPolicies } = this.props;
    const { minLength, minUpperCase, minLowerCase, minNumber, minSymbol } =
      passwordPolicies;

    const renderPolicy = (pwPolicy, label) => {
      const validClassName = this.isPWPolicyValid(pwPolicy)
        ? 'w-pw-policies__checked'
        : '';
      return (
        <li className={validClassName}>
          <SvgIcon icon='checkboxCircle' theme='body' label={label} />
        </li>
      );
    };

    return (
      <ul className='w-pw-policies'>
        {!!minLength &&
          renderPolicy(
            'minLength',
            this.getTemplatedString('password.minCharacters', { minLength })
          )}
        {!!minUpperCase &&
          renderPolicy(
            'minUpperCase',
            this.getString('password.upperCaseLetter')
          )}
        {!!minLowerCase &&
          renderPolicy(
            'minLowerCase',
            this.getString('password.lowerCaseLetter')
          )}
        {!!minNumber &&
          renderPolicy('minNumber', this.getString('password.number'))}
        {!!minSymbol &&
          renderPolicy(
            'minSymbol',
            this.getString('password.specialCharacter')
          )}
        {renderPolicy(
          'passwordMatch',
          this.getString('password.passwordsMatch')
        )}
      </ul>
    );
  }
}

export default WidgetPWPolicies;

import Backbone from 'backbone';
import moment from 'moment';

var Template = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  // defaults: {
  //   name: "",
  //   content: ""
  // },
  defaults: {
    active: false,
    content: '',
    customAttributes: {},
    eventName: '',
    // id: 0,
    // lastModified: "",
    name: '',
    templateType: '',
    tenantId: '',
    // userId: ""
  },

  idAttribute: 'id',

  /**
   * Constructor
   */
  initialize: function (model, opts) {
    var options = opts || {};

    this.tenantId = options.tenantId;
    this.id = options.id;
  },

  parse: function (data) {
    data = data[0] ? data[0] : data;
    data.name = window.atob(data.name);
    data.content = window.atob(data.content);
    data.lastModified = new moment(data.lastModified);
    data.lastModifiedDisplay = data.lastModified.format('MM/DD/YYYY');
    return data;
  },

  url: function () {
    if (this.id) {
      return `platform-communication-service/tenants/${this.tenantId}/templates/${this.id}/`;
    }
    return `platform-communication-service/tenants/${this.tenantId}/templates`;
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default Template;

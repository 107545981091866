import BaseController from 'controllers/base';
import Permissions from 'core/permissions';
import Config from 'core/config';

class SettingsController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'settings:getTabs': '_getTabs',
      },
      name: 'settings',
    });
  }

  _hasPermission(item) {
    const { permissions } = item;
    const hasPermission = !permissions || Permissions.userRole(permissions);
    return hasPermission;
  }

  _getTabs(payload) {
    let tabs = [];
    const tabList = this.clone(Config.tabs.settings);

    tabList.forEach((tab) => {
      if (this._hasPermission(tab)) {
        let menus = [];
        const menuList = this.clone(tab.menus);
        menuList.forEach((menu) => {
          if (this._hasPermission(menu)) {
            let items = [];
            const itemList = this.clone(menu.items);
            itemList.forEach((item) => {
              if (this._hasPermission(item)) {
                items.push(item);
              }
            });
            if (items.length) {
              menu.items = items;
              menus.push(menu);
            }
          }
        });
        if (menus.length) {
          tab.menus = menus;
          tabs.push(tab);
        }
      }
    });

    if (payload.callback) {
      payload.callback(tabs);
    } else {
      this.publish('settings:tabs', tabs);
    }
  }
}

export default SettingsController;

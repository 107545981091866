import Backbone from 'backbone';

const MasterDataTypeModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.id = options.id;
  },

  parse: function (model, options) {
    const value = model.value;
    const icon = model.icon;
    let label = value;
    this.set('label', label);
    model.label = label;
    //Default to gear icon if it's set to null.
    if (!icon) {
      this.set('icon', 'gear');
      model.icon = 'gear';
    }
    if (value === 'locations' || value === 'employees' || value === 'agents') {
      label = value.charAt(0).toUpperCase() + value.slice(1);
      this.set('label', label);
      this.set('icon', value);
      model.label = label;
      model.icon = value;
    }
    return model;
  },

  url: function () {
    if (this.id) {
      return `clients/${this.client}/masterdatatypes/${this.id}`;
    }
    return `clients/${this.client}/masterdatatypes`;
  },
});

export default MasterDataTypeModel;

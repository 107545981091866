import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'underscore';
import DispatcherMixin from 'views/mixins/dispatcher';
import StateMixin from 'views/mixins/state';
import I18nMixin from 'views/mixins/i18n';
import Button from 'views/widgets/form/button';
import { SvgIcon } from 'cx-component-library';

var ConfirmModal = createReactClass({
  mixins: [DispatcherMixin, I18nMixin, StateMixin],

  componentDidMount: function () {
    document.addEventListener('keydown', this.handleKeyPress);
    this.getModalPadding();
  },

  update: function () {
    this.forceUpdate();
  },

  componentWillUnmount: function () {
    this.removeEventListeners();
  },

  removeEventListeners: function () {
    document.removeEventListener('keydown', this.handleKeyPress);
  },

  getDefaultProps: function () {
    return {
      icon: '',
      heading: 'heading',
      description: [
        { content: 'description regular' },
        { content: 'description bold', style: 'bold' },
        { content: 'description italic', style: 'italic' },
      ],
      action: false,
      payload: false,
      callback: () => {},
    };
  },

  getInitialState: function () {
    return {};
  },

  render: function () {
    var confirmButton = this.props.confirm ? (
      <Button
        style='warning'
        label={this.props.confirm}
        onClick={this.handleConfirm}
      />
    ) : null;
    var cancelButton = null;

    if (this.props.cancel) {
      cancelButton = this.props.confirm ? (
        <Button
          style='warningtertiary'
          label={this.props.cancel}
          onClick={this.handleClose}
        />
      ) : (
        <Button
          style='warning'
          label={this.props.cancel}
          onClick={this.handleClose}
        />
      );
    }

    return (
      <div className='confirm-modal'>
        <div
          className='confirm-modal__overlay'
          style={{
            padding: this.getModalPadding().y + ' ' + this.getModalPadding().x,
          }}
        >
          <div className='confirm-modal__content-container'>
            <SvgIcon
              icon='close'
              className='modal__close'
              onClick={this.handleClose}
            />
            <div className='confirm-modal__content'>
              {this.props.icon && (
                <div className='confirm-modal__icon'>
                  <SvgIcon icon={this.props.icon} iconOnly />
                </div>
              )}
              <div className='confirm-modal__heading'>{this.props.heading}</div>
              <div className='confirm-modal__description'>
                {this.props.description.map(function (description, i) {
                  return (
                    <span
                      key={i}
                      className={
                        description.style
                          ? 'confirm-modal__description--' + description.style
                          : ''
                      }
                    >
                      {description.content}
                      {this.props.description[i] ? ' ' : ''}
                    </span>
                  );
                }, this)}
              </div>
              {confirmButton}
              {cancelButton}
            </div>
          </div>
        </div>
      </div>
    );
  },

  getModalPadding: function (update) {
    var y = window.innerHeight,
      x = window.innerWidth,
      padding = { y: 15, x: 20 };

    padding.y = y < 800 ? 0 : padding.y;
    padding.x = x < 500 ? 0 : padding.x;

    padding.x += '%';
    padding.y += '%';

    return padding;
  },

  handleKeyPress: function (e) {
    if (e.keyCode === 27) {
      this.handleClose();
    }
  },

  handleConfirm: function () {
    if (this.props.action && this.props.payload) {
      this.publish(this.props.action, this.props.payload);
    }
    if (_.isFunction(this.props.callback)) {
      this.props.callback();
    }
    this.handleClose();
    this.removeEventListeners();
  },

  handleClose: function () {
    if (this.props.actionOnCancel && this.props.payload) {
      this.publish(this.props.actionOnCancel, this.props.payload);
    }
    this.removeEventListeners();
    this.publish('app:showModal', { modal: false });
  },
});

export default ConfirmModal;

import Backbone from 'backbone';

const UserCapabiltiesCollection = Backbone.Collection.extend({
  model: Backbone.Model,

  initialize: function (models, opts) {
    const options = opts || {};

    this.user = options.user;
  },

  url: function () {
    return `authorization-service/users/${this.user}/capabilities`;
  },
});

export default UserCapabiltiesCollection;

export default function (str) {
  if (!str) {
    return null;
  }

  var arr = str.split('&');
  var obj = {};
  for (var i = 0; i < arr.length; i++) {
    var keyValue = arr[i].split('=');
    obj[keyValue[0]] = keyValue[1];
  }
  return obj;
}

import $ from 'jquery';
import Backbone from 'backbone';
import constants from 'core/constants';
import i18n from 'core/i18n';
import dispatcher from 'core/dispatcher';
import '@foresee/backbone-oauth-1.0a'; // This is needed before controllers
import Controllers from 'controllers/controllers';

import 'core/utils/eventtracker';
import 'core/utils/sync';
import 'public/dist/alloyeditor/dist/alloy-editor/assets/alloy-editor-ocean.css';

import '@foresee/component-library/dist/index.css';
import 'css.scss';

/**
 * Entry point for entire application
 */
var root = constants.CLIENT_ROOT,
  controllers = {},
  app = startController.call(this, 'app');

trackAjaxRequests();
if (app.initialized && app.initialized.state() === 'resolved') {
  start();
} else {
  dispatcher.listenToOnce(dispatcher, 'app:initialized', start.bind(this));
}

function trackAjaxRequests() {
  // helper method to abort all pending requests
  $.xhrPool = [];
  $.abortAllPendingRequests = () => {
    for (let i = 0; i < $.xhrPool.length; i++) {
      const jqXHR = $.xhrPool[i];
      jqXHR.abort();
    }
    $.xhrPool = [];
  };

  // before every request is sent we add to our xhrPool
  // then remove completed requests
  $(document)
    .bind('ajaxSend', (event, jqXHR, request) => {
      // property exposed to prevent abort
      if (request.doNotAbort) {
        return;
      }

      $.xhrPool.push(jqXHR);
    })
    .bind('ajaxComplete', (event, jqXHR) => {
      var i = $.inArray(jqXHR, $.xhrPool);
      if (i > -1) $.xhrPool.splice(i, 1);
    });
}
//  Fix for ACSPUI-1196 -- Issue with Highcharts adapter not loading properly on occasion.
//  Once app is ready and authenticated, with router initialized, start history
function start() {
  // Create main view for app
  app.render();

  if (!Backbone.History.started) {
    Backbone.history.start({
      pushState: true,
      root: root,
    });
  }

  // Track our first page loaded, unless it's the login
  if (Backbone.history.fragment.indexOf('login') < 0) {
    app.eventTracker.trackPageView(root + Backbone.history.fragment);
  }

  // If we're not on a valid route trigger default
  if (!Backbone.history.fragment) {
    app.router.triggerDefaultRoute();
  }
}
/**
 * Fire up controller and its dependencies, storing it in controllers.
 * @param controllerName
 */
function startController(controllerName) {
  console.log('startController', controllerName);
  var strings = i18n.strings,
    options = {
      strings: strings,
      app: controllerName === 'app' ? null : controllers[app],
    },
    controller = new Controllers[controllerName](
      options,
      controllers,
      startController.bind(this)
    );

  controllers[controllerName] = controller;
  // What?
  controllers[controllerName].name = controllerName;

  return controller;
}

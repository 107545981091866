import Backbone from 'backbone';

var HierarchyFilterModel = Backbone.Model.extend({
  idAttribute: 'filterId',

  //  defaults: {
  //    terms: [],
  //    operator: Constants.OPERATOR_AND
  //  },

  initialize: function (attributes, opts) {
    this.addOptions(opts);
  },

  addOptions: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;

    this.options = options;
  },

  url: function () {
    if (this.get('filterId')) {
      return `clients/${this.client}/measures/${
        this.measure
      }/hierarchyfilters/${this.get('filterId')}/filter`;
    }
    return `clients/${this.client}/measures/${this.measure}/hierarchyfilters/filters`;
  },

  serviceId: 'foresee-services',
});

export default HierarchyFilterModel;

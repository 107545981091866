import BaseSeriesModel from 'models/baseSeries';
import constants from 'core/constants';
import URL from 'core/utils/url';
import moment from 'moment';
const MetricsModel = BaseSeriesModel.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.measurementKey = options.measurementKey;
    this.offset = options.offset;
    this.limit = options.limit;
    this.parseScoreByAnswer = options.parseScoreByAnswer;
    this.originalCriteria = model;
    this.metrics = options.metrics; // See if this can be refactored
    this.dimensions = options.dimensions; // See if this can be refactored
    this.useMeasureLabel = options.useMeasureLabel; // See if this can be refactored
  },

  parse: function (data) {
    if (this.metrics) {
      return {
        series: this.parseSeries(data),
      };
    }
    if (this.parseScoreByAnswer) {
      data = this.parseScoreByAnswerData(data);
    }
    return data;
  },

  parseScoreByAnswerData: function (data) {
    let questionKey = '';
    let latentKey = false;
    let metricType;
    data.question = {
      dateRange: {},
      answers: [],
    };

    data.criteria.metrics.forEach((m) => {
      if (m.type === 'QUESCOUNT') {
        questionKey = m.key;
      } else if (m.type === 'LATSCORE') {
        latentKey = m.key;
      } else if (m.type === 'SATSCORE') {
        metricType = constants.METRIC_TYPES.SAT;
      } else if (m.type === 'NPSSCORE') {
        metricType = constants.METRIC_TYPES.NPS;
      }
    });
    data.question.respondents = data.aggregates[`${questionKey}QC`];
    data.question.dateRange.start_date = moment(
      data.criteria.dateRange.f
    ).format('MM/DD/YYYY');
    data.question.dateRange.end_date = moment(data.criteria.dateRange.l).format(
      'MM/DD/YYYY'
    );
    data.question.answers = [];
    for (let prop in data.metadata[`${data.criteria.groupMetric.key}QG`]
      .groups) {
      const node = data.nodes.find((n) => {
        return n.name === prop;
      });
      const answer =
        data.metadata[`${data.criteria.groupMetric.key}QG`].groups[prop];
      const score = !latentKey
        ? node.metrics[`${data.measurementKey}${metricType}`]
        : node.metrics[`${latentKey}LS`];
      data.question.answers.push({
        answerKey: answer.key,
        displayLabel: answer.label,
        n: node.metrics[`${data.measurementKey}RC`],
        score: score ? parseFloat(score).toFixed(1) : parseFloat(0).toFixed(1),
        answerPercentage:
          (node.metrics[`${data.measurementKey}RC`] /
            data.question.respondents) *
          100,
      });
    }
    return data;
  },

  _getSeriesType: function () {
    return 'cxmeasure';
  },

  url: function () {
    let url = `clients/${this.client}/measures/${this.measurementKey}/metrics`;
    url = URL.insertParam(url, 'showCriteria', true);
    url = URL.insertParam(url, 'showMetadata', true);
    return url;
  },
});

export default MetricsModel;

import Backbone from 'backbone';

const HierarchyDraftsModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.hierarchyId = options.hierarchyId;
    this.id = options.id;
  },

  idAttribute: 'draftId',

  url: function () {
    if (this.id) {
      return `clients/${this.client}/hierarchies/${this.hierarchyId}/drafts/${this.id}`;
    }
    return `clients/${this.client}/hierarchies/${this.hierarchyId}/drafts`;
  },
});

export default HierarchyDraftsModel;

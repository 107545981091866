import Backbone from 'backbone';

var Hierarchy = Backbone.Model.extend({
  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measurementKey;

    this.options = options;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/hierarchy`;
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  cacheMS: 1000 * 60 * 60,
});

export default Hierarchy;

import dispatcher from 'core/dispatcher';
import _ from 'underscore';
import Backbone from 'backbone';

var DispatcherMixin = {
  /**
   * Reference to dispatcher singleton
   */
  _dispatcher: dispatcher,

  /**
   * Add a listener to the dispatcher for an action
   * The action will only be called if the component is mounted
   * @param action
   * @param cb
   */
  subscribe: function (action, cb) {
    var handler = _.bind(function () {
      if (this.isMounted()) {
        cb.apply(this, arguments);
      }
    }, this);

    this._events.listenTo(this._dispatcher, action, handler);
  },

  /**
   * Remove a particular callback from the dispatcher
   * @param action
   * @param cb
   */
  unsubscribe: function (action, cb) {
    this._events.stopListening(this._dispatcher, action, cb);
  },

  /**
   * Publish an action to the dispatcher with a particular payload.
   * @param action
   * @param payload
   */
  publish: function (action, payload) {
    this._dispatcher.trigger(action, payload);
  },

  /**
   * Set up mixin once component is mounted
   */
  componentDidMount: function () {
    var updateAction, action, callback;

    // Keep events here so we can tear down just this on unmount.
    this._events = _.extend({}, Backbone.Events);

    // Set listeners for all actions in actions declarative object.
    _.each(
      this.actions,
      _.bind(function (cb, action) {
        var handler = typeof cb === 'string' ? this[cb] : cb;

        this.subscribe(action, handler);
      }, this)
    );

    // Look for updateAction in props or props.config to indicate source of new data
    updateAction =
      this.props.updateAction ||
      (this.props.config && this.props.config.updateAction) ||
      undefined;

    // If the key is present in props and the handler is present, hook up a listener.
    if (typeof updateAction === 'string' && this.update instanceof Function) {
      this.subscribe(updateAction, _.bind(this.update, this));
    } else if (updateAction instanceof Object) {
      for (action in updateAction) {
        callback = this[updateAction[action]];

        if (action && callback instanceof Function) {
          this.subscribe(action, _.bind(callback, this));
        }
      }
    }
  },

  /**
   * Remove listeners when component is being unmounted
   */
  componentWillUnmount: function () {
    this._events.stopListening();
  },
};

export default DispatcherMixin;

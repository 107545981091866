import Backbone from 'backbone';
import moment from 'moment';

var InsightsOpenEndModel = Backbone.Model.extend({
  idAttribute: 'respondentId',
  serviceId: 'acs-services',

  initialize: function (opts) {
    var options = opts || {};
    options.cache = false;

    this.options = options;
  },

  _decodeEntities: function (encodedString) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
  },

  _cleanOpenEnds: function (answers) {
    for (let answer in answers) {
      let response = answers[answer].freeFormRespText;

      if (response && response.length) {
        answers[answer].freeFormRespText = this._decodeEntities(response);
      }
    }
    return answers;
  },

  parse: function (data) {
    const dateTime =
        data && data.responseDate ? data.responseDate.split('T') : [],
      time = dateTime[1],
      replaySorter = function (objA, objB) {
        try {
          let d1 = new Date(objA.recordedAt),
            d2 = new Date(objB.recordedAt);
          return d1 > d2;
        } catch (e) {
          return false;
        }
      };

    // return moment object localized to user timezone
    const localizeUtc = function (date) {
      const localTime = moment.utc(date).toDate();
      return moment(localTime);
    };

    if (data.replayMetadata && data.replayMetadata.length) {
      data.replayMetadata.forEach((metadata) => {
        metadata.recordedAt = this.prettyTime(metadata.recordedAt);
      });
      if (data.replayMetadata.length > 1) {
        data.replayMetadata = data.replayMetadata.sort(replaySorter);
      }
    }

    if (data.respondentId) {
      data.answers.push({
        questionId: 'respondent_id',
        questionLabel: 'respondent_id',
        questionText: 'respondent_id',
        questionKind: undefined,
        questionType: null,
        displayOrder: 9999,
        freeFormRespText: data.respondentId,
      });
    }

    return {
      replayMetadata: data.replayMetadata,
      globalId: data.globalId,
      respondentId: data.respondentId,
      answers: this._cleanOpenEnds(data.answers),
      date: localizeUtc(data.responseDate).format('MM/DD/YYYY'),
      time: time,
      dateTime: data.responseDate,
      rating: data.rating,
      experienceDate: data.experienceDate,
      url: data.url,
    };
  },

  prettyTime(t) {
    // Add 4 hours to the time and the local timezone offset
    // to the time the replay was recorded at
    // The cxreplayer retrieves a date time that is returned in ms while this endpoint returns the date time in UTC format and
    // from what I can tell is offset to be in ET
    var recordingDate = new Date(
      new Date(t).getTime() +
        240 * 60 * 1000 +
        new Date().getTimezoneOffset() * 60 * 1000
    );

    // Create an array with the current month, day and time
    var date = [
      recordingDate.getMonth() + 1,
      recordingDate.getDate(),
      recordingDate.getFullYear(),
    ];

    // Create an array with the current hour, minute and second
    var time = [
      recordingDate.getHours(),
      recordingDate.getMinutes(),
      recordingDate.getSeconds(),
    ];

    // Determine AM or PM suffix based on the hour
    var suffix = time[0] < 12 ? 'AM' : 'PM';

    // Convert hour from military time
    time[0] = time[0] < 12 ? time[0] : time[0] - 12;

    // If hour is 0, set it to 12
    time[0] = time[0] || 12;

    // If seconds and minutes are less than 10, add a zero
    for (var i = 1; i < 3; i++) {
      if (time[i] < 10) {
        time[i] = '0' + time[i];
      }
    }

    // Return the formatted string
    return date.join('/') + ' ' + time.join(':') + ' ' + suffix;
  },
});

export default InsightsOpenEndModel;

import FeatureFlags from 'core/featureFlags';

export default () => {
  const routes = {
    controller: 'user',
    permissions: 'portal_access',
    tabs: [
      {
        routes: ['setup', 'integrate', 'apps'],
      },
    ],
    layout: 'navigation',
    roles: {
      controller: 'hierarchy',
      permissions: 'permissions_edit',
      ':id': {
        label: 'Roles',
        controller: 'measures',
        layout: 'settings-role-edit',
      },
      new: {
        label: 'Roles',
        controller: 'measures',
        layout: 'settings-role-new',
      },
    },
    rules: {
      controller: 'hierarchy',
      permissions: 'permissions_edit',
      ':id': {
        layout: 'settings-rule-edit',
      },
      new: {
        layout: 'settings-rule-new',
      },
    },
    users: {
      controller: 'authorization',
      permissions: 'user_admin',
      ':id': {
        label: 'Users',
        controller: 'measures',
        layout: 'settings-user-details',
      },
    },
    setup: {
      label: 'Setup',
      layout: 'navigation',
      tabs: [
        {
          group: 'User Management',
          routes: ['access', 'users', 'roles', 'assignments', 'changes'],
        },
        {
          group: 'Hierarchy Management',
          routes: ['hierarchy', 'masterdata'],
        },
      ],
      access: {
        label: 'Client Access',
        layout: 'settings-general-client-access',
        controller: 'authorization',
        permissions: 'view_client',
      },
      users: {
        label: 'Users',
        layout: 'settings-general-users',
        controller: 'authorization',
        permissions: 'view_user',
      },
      roles: {
        label: 'Roles',
        layout: 'settings-general-roles',
        controller: 'hierarchy',
        permissions: 'permissions_edit',
      },
      assignments: {
        label: 'Bulk Role Assignments',
        layout: 'settings-general-role-assignments',
        controller: 'hierarchy',
        permissions: 'permissions_edit',
      },
      hierarchy: {
        label: 'Hierarchy',
        layout: 'settings-general-hiearchy',
        controller: 'hierarchy',
        permissions: 'hierarchy_view',
        new: {
          label: 'Hierarchy',
          layout: 'hierarchy-new-settings',
          permissions: 'hierarchy_edit',
          ':id': {
            layout: 'hierarchy-setup',
          },
        },
        ':id': {
          label: 'Hierarchy',
          layout: 'hierarchy-details-settings',
          tabs: [
            {
              routes: ['details', 'versions', 'drafts', 'settings'],
            },
          ],
          details: {
            label: 'Details',
          },
          versions: {
            label: 'Versions',
            ':id': {
              layout: 'hierarchy-versions-detail-settings',
              tabs: [
                {
                  routes: ['details'],
                },
              ],
              details: {
                label: 'Details',
              },
            },
          },
          drafts: {
            label: 'Drafts',
            ':id': {
              layout: 'hierarchy-drafts-detail-settings',
            },
          },
          settings: {
            controller: 'measures',
            label: 'Associations',
          },
        },
      },
      masterdata: {
        label: 'Master Data',
        layout: 'settings-general-masterdata',
        controller: 'master-data',
        permissions: 'hierarchy_view',
        new: {
          label: 'Master Data',
          permission: 'hierarchy_edit',
          layout: 'content-settings-masterdata-create',
        },
        ':id': {
          layout: 'masterdata-details-settings',
          tabs: [
            {
              routes: ['records', 'attributes'],
            },
          ],
          records: {
            label: 'Records',
            ':id': {
              tabs: [
                {
                  routes: ['details', 'versions'],
                },
              ],
              details: {
                label: 'Details',
              },
              versions: {
                label: 'Change Log',
              },
            },
          },
          attributes: {
            label: 'Attributes',
          },
        },
      },
      changes: {
        label: 'Change Log',
        layout: 'settings-general-change-log',
        controller: 'authorization',
        permissions: 'user_admin',
      },
    },
    apps: {
      label: 'Apps',
      layout: 'navigation',
      tabs: [
        {
          group: 'Text Analytics',
          parentRoute: 'text-analytics',
          routes: ['custom-feeds', 'keyword-manager'],
        },
        {
          group: 'Feedback',
          parentRoute: 'feedback',
          routes: ['inpage'],
        },
      ],

      feedback: {
        permissions: 'feedback_admin',
        inpage: {
          label: 'In-page Reporting',
          layout: 'settings-feedback-inpage',
        },
      },

      'text-analytics': {
        controller: 'text-analytics-config',
        permissions: 'text_analytics_settings',
        product: 'text_analytics',
        'custom-feeds': {
          label: 'Custom Data Feeds',
          layout: 'settings-text-feeds-custom',
          ':id': {
            layout: 'settings-text-feeds-custom-detail',
          },
        },
        'keyword-manager': {
          label: 'Keyword Manager',
          layout: 'settings-text-keyword-manager',
        },
      },
    },
    integrate: {
      label: 'Integrate',
      layout: 'navigation',
      tabs: [
        {
          group: 'Web SDK',
          routes: ['hosted-code'],
          permissions: 'hosted_code',
        },
        {
          group: 'Data Connectors',
          routes: ['data-connectors'],
          permissions: 'global_settings_api_credentials',
        },
      ],
      'hosted-code': {
        label: 'Settings',
        layout: 'integrate-settings2',
        permissions: 'hosted_code',
      },
      'data-connectors': {
        controller: 'api-administration',
        label: 'Connectors',
        layout: 'settings-data-connectors',
        permissions: 'global_settings_api_credentials',
      },
    },
  };

  if (FeatureFlags.hasFeature('custom-data-sources')) {
    routes.setup.tabs.unshift({
      group: 'General',
      routes: ['custom-data-sources'],
    });
    routes.setup['custom-data-sources'] = {
      label: 'Custom Data Sources',
      layout: 'settings-general-custom-data-sources',
      permissions: 'create_custom_data_sources',
      controller: 'custom-data-sources',
    };
  }

  return routes;
};

import Backbone from 'backbone';
import AlertModel from 'models/clients/alert';

var AlertsCollection = Backbone.Collection.extend({
  model: AlertModel,

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
  },

  /**
   * Uses lastUpdateDateTime to sort collection
   * @param a
   * @param b
   */
  comparator: function (a, b) {
    var dateA = new Date(a.get('lastUpdateDateTime')),
      dateB = new Date(b.get('lastUpdateDateTime'));

    return dateA < dateB ? 1 : -1;
  },

  serviceId: 'acs-services',

  url: function () {
    var client = this.client;
    return `clients/${client}/alerts`;
  },

  parse: function (data) {
    return data.alerts instanceof Array ? data.alerts : [data.alerts];
  },
});

export default AlertsCollection;

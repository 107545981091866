import BaseSeriesModel from 'models/baseSeries';
import _ from 'underscore';

const ClientMetricsModel = BaseSeriesModel.extend({
  initialize: function (model, opts) {
    var options = opts || {};

    this.showCriteria = options.showCriteria === false ? false : true;
    this.showMetaData = options.showMetaData === false ? false : true;

    this.client = options.clientId;
    this.measurementKey = options.measurementKey;
    this.offset = options.offset;
    this.limit = options.limit;
    this.originalCriteria = model;
    this.metrics = options.metrics; // See if this can be refactored
    this.dimensions = options.dimensions; // See if this can be refactored
    this.useMeasureLabel = options.useMeasureLabel; // See if this can be refactored
    this.combinedSourceName = options.combinedSourceName;
  },

  parse: function (data) {
    if (data.aggregates) {
      return {
        series: this.parseCustomDS(data),
      };
    }
    return data;
  },

  _getSeriesType: function () {
    return 'custom-data-sources';
  },

  url: function () {
    return `clients/${this.client}/metrics`;
  },

  parseCustomDS: function (data) {
    const { nodes = [], aggregates = {}, metadata = {}, criteria = {} } = data;
    const { dimensions = [], originalCriteria = {} } = this;
    const parsedDateRange = this._parseRange(criteria.dateRange);
    const metrics = criteria.combinedMetrics;
    const dateRange = criteria.dateRange;
    const dateDimension = dimensions
      .filter((dimension) => dimension.type === 'DATE')
      .pop();
    const rc = [];
    rc['RC'] = data.aggregates[this.client + 'RC'];
    const totalResponses = data.aggregates[this.client + 'RC'];
    let series = [];
    metrics.forEach((metric) => {
      const agg = [];
      const metricKeys = metric.keys;
      let metricType = '';
      let metricLabel = '';
      switch (metric.type) {
        case 'SATSCORE':
          metricType = 'SAT';
          metricLabel = this.getString('dashboards.metricLabels.' + metricType);
          break;
        case 'NPSSCORE':
          metricType = 'NPS';
          metricLabel = this.getString('dashboards.metricLabels.' + metricType);
          break;
        case 'RESPCOUNT':
          metricType = 'RC';
          metricLabel = this.getString('dashboards.responseCount');
          break;
        default:
          break;
      }

      if (metricType === 'RC') {
        agg[metricType] = rc[metricType];
      } else {
        agg[metricType] = aggregates[this.client + metricType];
      }

      if (metricType === 'NPS') {
        agg[metricType] = parseInt(agg[metricType], 10);
      }

      if (dimensions.length === 2) {
        nodes.forEach((node, index) => {
          let seriesMeta = {};
          if (dimensions[1].type === 'DATE') {
            seriesMeta = this._getDates(
              index === 0 ? dateRange.f : node.name,
              dimensions[1].key,
              this._getEndDate(index, nodes, dateRange),
              index === 0 && dateRange ? dateRange.f : node.name
            );
          } else {
            const key = Object.keys(metricKeys)[0];
            const groupKey =
              originalCriteria.groupBy === 'METRIC'
                ? `${key}${originalCriteria.groupMetric.type}`
                : false;
            seriesMeta = this._getMeasuresSeriesMeta(
              metadata,
              groupKey,
              `${key}${node.name.substr(node.name.length - 3)}`
            );
          }
          let serie = this.parseCustomSeries({
            dateRange,
            nodes: node.nodes,
            metricKeys,
            metricType,
            dateDimension,
            metadata,
            aggregate: agg[metricType],
            parsedDateRange,
            responses: totalResponses,
            seriesMeta,
            dimensions,
          });
          series.push(serie);
        });
      } else {
        const useMeasureKey = !_.contains(
          ['LS', 'QA', 'AD', 'AV'],
          metric.type
        );
        let scoreKey = useMeasureKey
          ? `${this.client}${metric.type}`
          : `${metric.keys[0]}${metric.type}`;
        let seriesMeta = this._getMeasuresSeriesMeta(metadata, scoreKey);
        seriesMeta.dateRange = dateRange;
        seriesMeta.parsedDateRange = this._parseRange(dateRange);
        seriesMeta.label = metricLabel;
        let serie = this.parseCustomSeries({
          dateRange: data.criteria.dateRange,
          nodes,
          metricKeys,
          metricType,
          dateDimension,
          metadata,
          aggregate: agg[metricType],
          parsedDateRange,
          responses: totalResponses,
          seriesMeta,
          dimensions,
        });
        series.push(serie);
      }
    });
    return series;
  },

  parseCustomSeries({
    nodes,
    metricKeys,
    metricType,
    dateRange,
    dateDimension,
    metadata,
    aggregate,
    parsedDateRange,
    responses,
    seriesMeta,
    dimensions,
  }) {
    const npsDimension = _.findWhere(dimensions, { type: 'NSG' });
    const primaryKey = Object.keys(metricKeys)[0];
    const points = nodes.map((node, index) => {
      let meta = {};
      let dates = {};
      let value = node.metrics && node.metrics[this.client + metricType];
      let rc = 0;

      for (const key in metricKeys) {
        rc = rc + (node.metrics && node.metrics[key + 'RC']);
      }

      if (npsDimension) {
        meta = this._getMeasuresSeriesMeta(
          metadata,
          `${primaryKey}NSG`,
          `${primaryKey}${node.name.substr(node.name.length - 3)}`
        );
        meta.categoryName = meta.label;
      }

      if (dimensions && dimensions.length && dimensions[0].type === 'DATE') {
        dates = this._getDates(
          node.name,
          dateDimension && dateDimension.key,
          this._getEndDate(index, nodes, dateRange),
          index === 0 && dateRange ? dateRange.f : node.name
        );
      }

      return {
        ...meta,
        ...dates,
        name: node.name,
        rank: node.rank,
        value: metricType === 'RC' ? rc : value,
        responses: rc,
      };
    });

    const metricMeta = this._getMeasuresSeriesMeta(
      metadata,
      `${this.client}${metricType}`
    );

    const scoreKey = `${this.client}${metricType}`;
    return {
      ...seriesMeta,
      dataSourceLabel: metadata[scoreKey].label,
      aggregate,
      dateRange,
      metricReference: undefined,
      metricType,
      parsedDateRange,
      points,
      responses,
      metricLabel: metricMeta ? metricMeta.label : metricKeys[0],
      type: this._getSeriesType(),
    };
  },
});

export default ClientMetricsModel;

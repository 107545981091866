import Backbone from 'backbone';

var PublishStatusModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};

    this.client = options.clientId;
  },

  setProjectId: function (projectId) {
    this.project = projectId;
  },

  url: function () {
    return `clients/${this.client}/projects/${this.project}/publish/staging/status`;
  },
});

export default PublishStatusModel;

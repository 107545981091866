import $ from 'jquery';
import _ from 'underscore';
import { cloneDeep } from 'lodash';
import BaseController from 'controllers/base';
import PageFilterModel from 'models/clients/measures/page-filter';
import PageFilterDetailsModel from 'models/clients/measures/page-filter-details';
import PageFiltersCollection from 'collections/clients/measures/page-filters';

/**
 * Controller for manipulating page filters
 */
class PageFiltersController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'pagefilters',
      actions: {
        'measures:currentMeasure': 'setCurrentMeasure',
        'pagefilters:save': 'saveFilter',
        'pagefilters:update': 'updateFilter',
        'pagefilters:delete': 'deleteFilter',
        'pagefilters:set': 'setFilter',
        'pagefilters:get': 'getFilters',
        'pagefilters:details:get': 'getFilterById',
        'pagefilters:reset': 'resetFilter',
        'pagefilters:reset:noaction': 'resetFilterNoAction',
        'pagefilters:chip:add': 'addFilterChip',
        'pagefilters:chip:remove': 'removeFilterChip',
      },
      dependsOn: ['user'],
    });
  }

  initialize(opts) {
    // global filter model
    this.client = this.getClientId();
    this.pageFilter = new PageFilterDetailsModel();
  }

  cloneFilter(filter) {
    const filterDetailsModel = new PageFilterDetailsModel(
      _.clone(filter.attributes)
    );

    if (filter.measure) {
      filterDetailsModel.measure = filter.measure;
    }

    // strip ids
    filterDetailsModel.unset('pageFilterId');
    filterDetailsModel.unset('lastUpdated');
    filterDetailsModel.unset('vndType');
    filterDetailsModel.unset('links');
    filterDetailsModel.set('isTemp', true);

    const pages = filterDetailsModel.get('pageFilterPages');
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      delete page.id;
    }

    return filterDetailsModel;
  }

  deleteFilter(payload) {
    const { pageFilter, callback, measurementKey } = payload;
    const options = {
      clientId: this.client,
      measure: measurementKey || this.currentMeasure.measurementKey,
    };

    const filterModel = new PageFilterModel(pageFilter, options);
    filterModel.destroy().then(() => {
      if (callback) {
        callback(pageFilter);
      }
      this.publish('pagefilters:filter:deleted', pageFilter);
    });
  }

  updateFilter(replacementFilter) {
    const options = {
      clientId: this.client,
      measure:
        replacementFilter.measurementKey || this.currentMeasure.measurementKey,
    };

    this.pageFilter.set('pageFilterId', replacementFilter.pageFilterId);
    this.pageFilter.set('name', replacementFilter.name);
    this.pageFilter.set('isTemp', false);

    const filterDetailsModel = new PageFilterDetailsModel(
      this.pageFilter.toJSON(),
      options
    );
    filterDetailsModel.save().then(() => {
      this.publish('pagefilters:filter:changed', filterDetailsModel.toJSON());
      this.publish('pagefilters:filter:new');
    });
  }

  saveFilter(payload) {
    const { filterName, measurementKey } = payload;
    const options = {
      clientId: this.client,
      measure: measurementKey || this.currentMeasure.measurementKey,
    };

    this.pageFilter.set('name', filterName);
    this.pageFilter.set('isTemp', false);

    const filterModel = new PageFilterModel(this.pageFilter.toJSON(), options);
    filterModel.save().then(() => {
      this.pageFilter.fetch().then(() => {
        this.publish('pagefilters:filter:changed', this.pageFilter.toJSON());
        this.publish('pagefilters:filter:new');
      });
    });
  }

  saveTempFilter(filter) {
    console.log('saveTempFilter', filter);
    const options = {
      clientId: this.client,
      measure: filter.measure || this.currentMeasure.measurementKey,
    };

    const filterModel = new PageFilterModel(filter.toJSON(), options);
    filterModel.save().then(() => {
      this.pageFilter = new PageFilterDetailsModel(
        filterModel.toJSON(),
        options
      );
      this.pageFilter.fetch().then(() => {
        this.publish('pagefilters:filter:changed', this.pageFilter.toJSON());
      });
    });
  }

  setCurrentMeasure(measure) {
    this.currentMeasure = measure;
  }

  setFilter(payload) {
    const { isInitialization, noReload, ...filter } = payload;
    const options = {
      clientId: this.client,
      measure: filter.measurementKey || this.currentMeasure.measurementKey,
    };
    this.pageFilter = new PageFilterDetailsModel(filter, options);
    this.pageFilter.fetch().then(() => {
      this.publish('pagefilters:filter:changed', {
        ...this.pageFilter.toJSON(),
        noReload,
        isInitialization,
      });
    });
  }

  getFilterById(filter) {
    const options = {
      clientId: this.client,
      measure: filter.measurementKey,
    };
    const model = new PageFilterDetailsModel(filter, options);
    const errorHandler = {
      error: () => {},
    };
    model.fetch(errorHandler).then(() => {
      this.publish('app:getData', {
        key: 'pageFilterDetails',
        callback: (currentPageFilterDetails) => {
          const pageFilterDetail = model.toJSON();
          const pageFilterDetails = cloneDeep(currentPageFilterDetails) || {};
          pageFilterDetails[model.get('pageFilterId')] = pageFilterDetail;
          this.publish('app:updateData', { pageFilterDetails });
          if (filter.callback) {
            filter.callback(pageFilterDetail);
          }
        },
      });
    });
  }

  getFilters(payload) {
    const { callback, measurementKey } = payload;
    const options = {
      clientId: this.client,
      measure: measurementKey || this.currentMeasure.measurementKey,
    };

    const filters = new PageFiltersCollection(options);

    filters.fetch().then(() => {
      const defs = [];

      filters.each((model, index) => {
        const def = new $.Deferred();
        defs.push(def);

        model.addOptions(options);
        model.fetch().then(() => {
          def.resolve(model);
        });
      });

      $.when.apply(null, defs).then(() => {
        if (callback) {
          callback(filters.toJSON());
        } else {
          this.publish('pagefilters:get:data', filters.toJSON());
        }
      });
    });
  }

  buildPages(payload) {
    const { pageType, urls } = payload;

    const pages = [];
    for (let i = 0; i < urls.length; i++) {
      const page = urls[i];

      pages.push({
        pageType: pageType,
        subjectPredicate: page.selectedOperator,
        text: page.text,
      });
    }
    return pages;
  }

  addFilterChip(payload) {
    this.pageFilter.measure = payload.measurementKey;
    const pages = this.buildPages(payload);
    this.publish('pagefilters:filter:page:added', pages);
    this.addPageToFilter(pages, payload.operator, payload.pageType);
  }

  addPageToFilter(pages, pageOperator, pageType) {
    this.pageFilter = this.cloneFilter(this.pageFilter);

    if (pageOperator) {
      this.pageFilter.set('pageOperator', pageOperator);
    }

    const newPages = this.removeAllPagesByType(pageType);
    newPages.push(...pages);
    const groupedPages = Object.keys(_.groupBy(newPages, 'pageType'));

    this.pageFilter.set('name', `Untitled (${groupedPages.length})`);
    this.pageFilter.set('pageFilterPages', newPages);
    this.saveTempFilter(this.pageFilter);
  }

  removeFilterChip(type) {
    this.publish('pagefilters:filter:page:removed', type);
    this.removePageFilter(type);
  }

  removeAllPagesByType(type) {
    const newPages = [];
    const pages = this.pageFilter.get('pageFilterPages');
    const groupedPages = _.groupBy(pages, 'pageType');

    for (let pageType in groupedPages) {
      if (pageType !== type) {
        newPages.push(...groupedPages[pageType]);
      }
    }
    return newPages;
  }

  removePageFilter(type) {
    this.pageFilter = this.cloneFilter(this.pageFilter);
    const newPages = this.removeAllPagesByType(type);

    this.pageFilter.set('pageFilterPages', newPages);
    const groupedPages = Object.keys(_.groupBy(newPages, 'pageType'));

    if (newPages.length > 0) {
      this.pageFilter.set('name', `Untitled (${groupedPages.length})`);
      this.saveTempFilter(this.pageFilter);
    } else {
      this.resetFilter();
    }
  }

  resetFilterNoAction() {
    this.pageFilter.reset();
  }

  resetFilter(payload = {}) {
    const { callback, isInitialization } = payload;
    const reset = () => {
      return new Promise((resolve) => {
        this.pageFilter.reset();
        resolve();
      });
    };
    reset().then(() => {
      if (callback) {
        callback();
      }
    });
    this.publish('pagefilters:filter:changed', {
      ...this.pageFilter.toJSON(),
      isInitialization,
    });
  }
}

export default PageFiltersController;

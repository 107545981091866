import Backbone from 'backbone';
import _ from 'underscore';
import Respondents from 'models/clients/measures/respondents';

var RespondentsCollection = Backbone.Collection.extend({
  model: Respondents,
  initialize: function (opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.measure = options.measure;
    this.nextPage = options.nextPage;
    this.commentKeys = options.commentKeys;
  },

  parse: function (data) {
    var next = {},
      links = data.links;

    if (links !== undefined) {
      links.forEach((link) => {
        if (link.rel === 'next') {
          next = link.href;
        }
      });
    }

    data.responses = data.responses || [];

    this.nextPage = next;
    this.links = data.links;
    this.criteria = data.criteria;

    for (var i = 0; i < data.responses.length; i++) {
      var response = data.responses[i];
      var comments = [];

      for (var j = 0; j < this.commentKeys.length; j++) {
        var qKey = this.commentKeys[j];
        var index = _.indexOf(_.pluck(response.answers, 'qKey'), qKey);

        if (index > -1) {
          comments.push(response.answers[index]);
          response.answers.splice(index, 1);
        }
      }

      response.comments = comments;
    }

    return data.responses;
  },

  serviceId: 'foresee-services',

  url: function () {
    var page = this.nextPage ? this.nextPage.split('/respondents/').pop() : '';
    return `clients/${this.client}/measures/${this.measure}/respondents${
      page ? '/' + page : ''
    }`;
  },
});

export default RespondentsCollection;

import Backbone from 'backbone';
import ProjectModel from 'models/clients/project';

var ProjectsCollection = Backbone.Collection.extend({
  model: ProjectModel,
  initialize: function (models, opts) {
    var options = opts || {};

    this.client = options.clientId;
  },

  /**
   * Uses lastUpdateDateTime to sort collection
   * @param a
   * @param b
   */
  comparator: function (a, b) {
    var dateA = new Date(a.get('lastUpdateDateTime')),
      dateB = new Date(b.get('lastUpdateDateTime'));

    return dateA < dateB ? 1 : -1;
  },

  serviceId: 'acs-services',

  url: function () {
    return `clients/${this.client}/projects`;
  },
});

export default ProjectsCollection;

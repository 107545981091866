import Backbone from 'backbone';

const CapabilityGroupCollection = Backbone.Collection.extend({
  model: Backbone.Model,

  initialize: function (models, opts) {},

  parse: function (data) {
    return data.items;
  },

  url: function () {
    return `authorization-service/capabilities/groups`;
  },
});

export default CapabilityGroupCollection;

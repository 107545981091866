import Backbone from 'backbone';

const HierarchyMDCSVModel = Backbone.Model.extend({
  exportFileType: 'csv',

  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.type = options.type; //hierarchies or masterdatatypes
    this.typeId = options.typeId;
    this.fileName = options.fileName;
  },

  // export related methods
  getSaveFileOptions() {
    return { headers: { Accept: 'text/csv' } };
  },
  hasExportFailed: function (response) {
    return response.status === 'FAILED';
  },
  hasExportCompleted: function (response) {
    return response.status === 'COMPLETED';
  },

  url: function () {
    if (this.get('exportCompleted')) {
      return `clients/${this.client}/${this.type}/${
        this.typeId
      }/downloads/${this.get('exportId')}/file`;
    }

    if (this.get('exportId')) {
      return `clients/${this.client}/${this.type}/${
        this.typeId
      }/downloads/${this.get('exportId')}`;
    }

    return `clients/${this.client}/${this.type}/${this.typeId}/downloads`;
  },
});

export default HierarchyMDCSVModel;

import Backbone from 'backbone';
import featureFlags from 'core/featureFlags';

var TAConfigModel = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {},

  idAttribute: 'configId',

  /**
   * Constructor
   */
  initialize: function (model, options = {}) {
    const { configId } = options;
    this.clientId = model.clientId;
    this.showDeleted = options.showDeleted ? options.showDeleted : false;
    if (configId) {
      this.set('configId', configId);
    }
  },

  url: function () {
    let url = 'ta-config-beta/clients/' + this.clientId + '/config';
    if (featureFlags.hasFeature('new_ta-admin')) {
      url = 'ta-admin/clients/' + this.clientId + '/config';
    }
    if (this.showDeleted) {
      url += '?showDeleted=true';
    }
    return url;
  },
});

export default TAConfigModel;

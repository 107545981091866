import Backbone from 'backbone';
import CustomQuestions from 'models/clients/measures/customquestions';

var CustomQuestionsCollection = Backbone.Collection.extend({
  model: CustomQuestions,
  initialize: function (opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.measure = options.measure;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/customquestions`;
  },

  comparator: 'questionOrder',
});

export default CustomQuestionsCollection;

import Backbone from 'backbone';

var ScoresByAnswer = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    answerKey: 0,
    displayLabel: '',
    label: '',
    text: '',
    value: 0,
    vndType: 'application/vnd.foresee.answer',
  },

  idAttribute: 'answerKey',

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default ScoresByAnswer;

import _ from 'underscore';

var JsonParse = (function () {
  var parseString = function (string) {
    return string.replace(/(<([^>]+)>)/gi, '');
  };

  var parseObject = function (object) {
    var newObject = {};
    _.each(Object.keys(object), function (key) {
      newObject[key] = parseUnknownObject(object[key]);
    });
    return newObject;
  };

  var parseArray = function (array) {
    var newArray = [];
    _.each(array, function (arrayItem) {
      var obj = parseUnknownObject(arrayItem);
      newArray.push(obj);
    });
    return newArray;
  };

  var parseUnknownObject = function (object) {
    var obj;
    if (_.isString(object)) {
      obj = parseString(object);
    } else if (_.isArray(object)) {
      obj = parseArray(object);
    } else if (_.isObject(object)) {
      obj = parseObject(object);
    } else if (_.isNull(object)) {
      obj = undefined;
    } else {
      obj = object;
    }
    return obj;
  };

  return {
    // Recursively strip HTML from object of unknown depth
    stripHtml: function (data) {
      data = data || {};
      _.each(Object.keys(data), function (key) {
        data[key] = parseUnknownObject(data[key]);
      });
      return data;
    },
  };
})();

export default JsonParse;

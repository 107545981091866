import Backbone from 'backbone';
import AnswerModel from 'models/clients/measures/questions/answer';

var AnswersCollection = Backbone.Collection.extend({
  model: AnswerModel,
  initialize: function (models, opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
    this.question = options.questionKey;
  },

  comparator: 'displayOrder',

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/questions/${this.question}/answers`;
  },
});

export default AnswersCollection;

import Backbone from 'backbone';
import _ from 'underscore';
import UserRolesModel from 'models/clients/user-roles';

const UserRolesCollection = Backbone.Collection.extend({
  model: UserRolesModel,

  initialize: function (models, opts) {
    var options = opts || {};
    this.userId = options.userId;
    this.clientId = options.clientId;
  },

  setUserNames: function (users) {
    this.models.forEach((model) => {
      const user = users.find(
        (user) => user.userId === model.get('assignedById')
      );
      if (user) {
        model.setUserNames(user);
      }
    });
  },

  setRuleNames: function (rules) {
    this.models.forEach((model) => {
      const rule = rules.find(
        (rule) => rule.assignmentRuleId === model.get('assignedById')
      );
      if (rule) {
        model.setRuleNames(rule);
      }
    });
  },

  getUserIds: function () {
    return _.compact(
      _.uniq(
        this.models.map((model) => {
          return model.getUserIds();
        })
      )
    );
  },

  getRuleIds: function () {
    return _.compact(
      _.uniq(
        this.models.map((model) => {
          return model.getRuleIds();
        })
      )
    );
  },

  parse: function (data) {
    return data.items;
  },

  url: function () {
    return `authorization-service/clients/${this.clientId}/users/${this.userId}/roles`;
  },
});

export default UserRolesCollection;

const FeatureFlags = function () {
  // this.ldClient = {
  //   variation: function() {
  //     return false;
  //   },
  // };
  this.ldClient = {
    variation: function (flag) {
      // TEMP
      if (flag === 'enable-benchmarks') {
        return true;
      }
      return false;
    },
  };
  this.hasFlags = false;
  this.setFeatureFlags = function (ldClient) {
    this.ldClient = ldClient;
    this.hasFlags = true;
  };
};

FeatureFlags.prototype.hasFeature = function (flag) {
  return this.ldClient.variation(flag);
};

export default new FeatureFlags();

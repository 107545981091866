import publish from 'core/utils/publish';

export const graphqlErrorHandler = ({ networkError, graphQLErrors }) => {
  const publishExpiredSession = () => {
    publish('route:get', {
      callback: (route) => {
        if (route.route === 'chart') {
          return;
        }
        publish('app:logout', {
          previousRoute: route.route,
          expiredSession: true,
        });
      },
    });
  };

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions?.response?.status === 401) {
        publishExpiredSession();
      }

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);

    if (networkError?.statusCode === 401 || networkError?.status === 401) {
      publishExpiredSession();
    }
  }
};

import Backbone from 'backbone';

const RespondentsMetaData = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    clientId: '',
    measure: '',
    questionType: '',
    questionKey: 142466388,
    questionLabel: '',
    questionPhrase: '',
    answerKey: 1013411872,
    answerLabel: '',
    answerValue: 10,
    respondentKey: '',
  },

  idAttribute: 'respondentKey',

  /**
   * Constructor
   */
  initialize: function (opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.measure = options.measure;
    this.respondents = options.respondentKey;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/respondents/id/${this.respondents}`;
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default RespondentsMetaData;

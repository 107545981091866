import _ from 'underscore';
import $ from 'jquery';
import BaseController from 'controllers/base';
import AssignmentRulesCollection from 'collections/authorization-service/clients/assignment-rules';
import AssignmentRulesModel from 'models/authorization-service/clients/assignment-rules';
import ClientRolesCollection from 'collections/authorization-service/clients/roles';
import ClientRolesModel from 'models/authorization-service/clients/roles';
import Constants from 'core/constants';
import FilterRolesCollection from 'collections/authorization-service/roles/filter';
import FilterUserRoles from 'models/authorization-service/clients/userRoles/filter';
import UserRolesCollection from 'collections/authorization-service/clients/users/roles';
import UserRolesModel from 'models/authorization-service/clients/userRoles';
import AuditModel from 'models/audit';
import PoliciesModel from 'models/clients/policies';
import CredentialsModel from 'models/clients/users/credentials';
import CountriesCollection from 'collections/countries';
import UserModel from 'models/platform-scim-service/user';
import UserSearchCollection from 'collections/platform-scim-service/users/search';
import CapabilityGroupCollection from 'collections/authorization-service/capabilities/groups';
import CapabilityCollection from 'collections/authorization-service/clients/capabilities/';
import CapabilityModel from 'models/authorization-service/clients/capabilities/';
import RestrictionTypesCollection from 'collections/authorization-service/restrictions/types';
import UserRestrictionsCollection from 'collections/authorization-service/users/restrictions';
import UserCapabiltiesCollection from 'collections/authorization-service/users/capabilities';
import CustomBasicAuthModel from 'models/custom-basic-auth';
import UserEntitlementsModel from 'models/authorization-service/users/entitlements';

class AuthorizationController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'authorization:users:get': '_getUsers',

        'authorization:user:password:reset': '_resetUserPassword',

        'authorization:user:post': '_postNewUser',
        'authorization:user:put': '_putUser',
        'authorization:user:get': '_fetchUser',
        'authorization:user:roles:get': '_getUserRoles',
        'authorization:user:roles:put': '_putUserRoles',
        'authorization:user:status:patch': '_patchUserStatus',
        'authorization:user:capabilities:get': '_getUserCapabilities',
        'authorization:user:restrictions:get': '_getUserRestrictions',

        'authorization:assignment:rules:get': '_getAssignmentRules',
        'authorization:assignment:rules:delete': '_deleteAssignmentRule',
        'authorization:assignment:rules:post': '_postAssignmentRules',

        'authorization:roles:get': '_getRoles',
        'authorization:roles:filter': '_filterRoles',
        'authorization:user:roles:filter': '_filterUserRoles',
        'authorization:roles:create': '_createRole',
        'authorization:roles:delete': '_deleteRole',
        'authorization:roles:post:default': '_postDefaultRole',
        'authorization:roles:put:default': '_putDefaultRole',

        'authorization:countries:get': '_getCountries',

        'authorization:changes:get': '_getChanges',
        'authorization:capabilities:get': '_getCapabilities',
        'authorization:capabilities:set': '_setCapabilities',
        'authorization:capabilities:groups:get': '_getCapabilityGroups',

        'authorization:restrictions:types:get': '_getRestrictionTypes',

        'authorization:policies:password:get': '_getPasswordPolicies',

        'authorization:odata:credentials:get': '_getOdataCredentials',
        'authorization:odata:credentials:set': '_setOdataCredentials',
        'authorization:odata:credentials:delete': '_deleteOdataCredentials',

        'authorization:user:entitlements:get': '_getUserEntitlements',
        'authorization:user:entitlements:update': '_updateUserEntitlements',
      },
      name: 'authorization',
      dependsOn: ['user'],
    });
  }

  initialize() {
    this.publish('app:getData', {
      key: 'currentUser',
      callback: (currentUser) => {
        this.currentUser = currentUser;
      },
    });
  }

  createUserSearchFilter(payload) {
    const {
      ids,
      clientId,
      search,
      internal,
      activeOnly,
      restrictInternal = false,
    } = payload;

    let userIds = ids;
    if (typeof ids === 'number') {
      userIds = [ids];
    }

    userIds = _.compact(_.uniq(userIds));

    const client = clientId || this.getClientId();
    let filter =
      typeof activeOnly === 'boolean'
        ? 'active eq ' + activeOnly + ' and '
        : '';
    filter += `clientid in ${client}${internal ? ',-1' : ''}`;
    if (userIds && userIds.length > 0) {
      // search internal foresee users along with client users
      //
      let clientSearch = !restrictInternal ? `${client},-1` : `${client}`;

      // remove duplicate internal client search
      if (client === -1) {
        clientSearch = client;
      }
      filter =
        typeof activeOnly === 'boolean'
          ? 'active eq ' + activeOnly + ' and '
          : '';
      filter += `clientid in ${clientSearch} and id in ${userIds.join()}`;
    }

    if (search) {
      filter +=
        ` and (username co "${search}" or ` +
        `emails.value co "${search}" or ` +
        `name.givenname co "${search}" or ` +
        `name.familyname co "${search}")`;
    }

    if (payload.entitlements) {
      payload.entitlements.forEach((e) => {
        filter += ` and (${e})`;
      });
    }

    return filter;
  }

  _getUsers(payload) {
    const {
      callback,
      limit, // was count
      startIndex,
      sortBy,
      sortOrder,
      attributes = 'roles',
    } = payload;

    const def = new $.Deferred();
    const model = new UserSearchCollection();
    const data = {
      schemas: ['urn:ietf:params:scim:api:messages:2.0:SearchRequest'],
      filter: this.createUserSearchFilter(payload),
      count: limit || 50,
      startIndex: startIndex || 1,
      sortBy: sortBy,
      sortOrder: sortOrder,
      attributes: attributes,
    };

    model
      .fetch({ data: JSON.stringify(data), type: 'POST', doNotAbort: true })
      .then((data) => {
        def.resolve(model.toJSON());
        if (callback) {
          callback(model.toJSON(), {
            totalRecords: data.totalResults,
            startIndex: data.startIndex,
            itemsPerPage: data.itemsPerPage,
          });
        }
      });

    return def;
  }

  _getRoles(payload) {
    const { id, clientId, callback, fetchDefaultRole } = payload;

    let options = {
      clientId: clientId || this.getClientId(),
      defaultRole: fetchDefaultRole,
    };

    let model = new ClientRolesCollection(false, options);
    if (id) {
      model = new ClientRolesModel(false, { ...options, id });
    }

    model.fetch().then(() => {
      const userIds = model.getUserIds();

      if (userIds && userIds.length > 0) {
        this._getUsers({
          ids: userIds,
          restrictInternal: this.currentUser.clientId !== -1,
          callback: (users) => {
            model.setUserNames(users);
            model.models.forEach((m) => {
              if (Number(m.get('lastUpdatedByUser'))) {
                const placeholder =
                  m.get('lastUpdatedByUser') === -1
                    ? 'System'
                    : 'ForeSee Admin';
                m.set('lastUpdatedByUser', placeholder);
              }
            });

            if (callback) {
              callback(model.toJSON());
            }
          },
        });

        return;
      }

      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _filterRoles(payload) {
    const { startIndex, count, sortBy, sortOrder, clientId, callback, search } =
      payload;

    const searchString = search
      ? ` and (displayName co "${search}" or displayDesc co "${search}")`
      : '';
    const model = new FilterRolesCollection();
    const data = {
      filter: `clientId eq ${clientId || this.getClientId()}${searchString}`,
      startIndex: startIndex || 0,
      count: count || 10,
      sortBy: sortBy,
      sortOrder: sortOrder,
    };

    model.fetch({ data: JSON.stringify(data), type: 'POST' }).then((data) => {
      if (callback) {
        callback(model.toJSON(), {
          totalRecords: data.totalItems,
          hasMore: data.hasMore,
        });
      }
    });
  }

  _filterUserRoles(payload) {
    const { userId, roleId, offset, limit, clientId, callback, defaultRole } =
      payload;

    const options = {
      userIds: userId,
      roleIds: roleId,
      offset: offset,
      limit: limit,
      clientId: clientId ? clientId : this.getClientId(),
      defaultRole: defaultRole ? defaultRole : false,
    };
    let model = new FilterUserRoles(false, options);

    model.fetch({ type: 'GET' }).then((data) => {
      if (callback) {
        callback({
          totalRecords: data.totalItems,
          hasMore: data.hasMore,
          items: data.items,
        });
      }
    });
  }

  _postDefaultRole(payload) {
    this._createOrUpdateDefaultRole(payload);
  }
  _putDefaultRole(payload) {
    this._createOrUpdateDefaultRole(payload, payload.roleId);
  }
  _createOrUpdateDefaultRole(payload, roleId) {
    const { capabilities, callback } = payload;

    const model = new ClientRolesModel(
      {
        id: roleId,
        displayName: 'Default Role',
        displayDesc: 'Default role for client',
        rolePredicates: [
          {
            restrictionDefinition: {
              capabilities: capabilities,
            },
          },
        ],
      },
      {
        clientId: this.getClientId(),
        id: roleId,
        defaultRole: roleId ? false : true,
      }
    );

    model.save().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _createRole(payload) {
    const { request, callback } = payload;

    const model = new ClientRolesModel(
      {
        ...request,
        clientId: this.getClientId(),
      },
      {
        clientId: this.getClientId(),
        id: request.roleId,
      }
    );

    const options = {};
    options.error = (r, response) => {
      this.publish('app:messageFlash', {
        messageText: response.responseJSON.message,
        messageType: 'error',
      });
      if (callback) {
        callback({ error: true });
      }
    };

    model.save(null, options).then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _deleteRole(payload) {
    const { roleId, callback } = payload;

    const model = new ClientRolesModel(
      {
        roleId: roleId,
      },
      {
        clientId: this.getClientId(),
        id: roleId,
      }
    );

    model.destroy().then(() => {
      if (callback) {
        callback();
      }
    });
  }

  _fetchUser(payload) {
    const { userId, callback, attributes = 'roles' } = payload;

    const model = new UserModel(null, {
      id: userId,
      attributes: attributes,
    });

    model.fetch().then((data) => {
      if (callback) {
        callback(data);
      }
    });
  }

  _postNewUser(payload) {
    this._createOrUpdateUser(payload);
  }
  _putUser(payload) {
    this._createOrUpdateUser(payload, payload.userId);
  }
  _createOrUpdateUser(payload, userId) {
    const {
      clientId = this.getClientId(),
      givenName,
      familyName,
      emailAddress,
      userName,
      active,
      callback,
      errorCallback,
    } = payload;

    const userModal = new UserModel(
      {
        id: userId,
        schemas: [
          'urn:ietf:params:scim:schemas:core:2.0:User',
          'urn:ietf:params:scim:schemas:extension:foresee:1.0:User',
        ],
        userName: userName,
        name: {
          familyName: familyName,
          givenName: givenName,
        },
        emails: [
          {
            value: emailAddress,
            type: 'work',
            primary: true,
          },
        ],
        active: active,
        'urn:ietf:params:scim:schemas:extension:foresee:1.0:User': {
          clientId: clientId,
          authenticationProvider: 'FORESEE',
        },
      },
      {
        id: userId,
      }
    );

    userModal
      .save(null, {
        error: errorCallback,
        headers: { 'Content-Type': 'application/scim+json' },
      })
      .then(() => {
        if (callback) {
          callback(userModal.toJSON());
        }
      });
  }

  _patchUserStatus(payload) {
    const { user, status, callback } = payload;

    const userModal = new UserModel(
      {
        schemas: ['urn:ietf:params:scim:api:messages:2.0:PatchOp'],
        Operations: [
          {
            op: 'replace',
            path: 'active',
            value: status,
          },
        ],
      },
      {
        id: user.userId || user.id,
      }
    );

    userModal
      .save(null, {
        patch: true,
        headers: { 'Content-Type': 'application/scim+json' },
      })
      .then(() => {
        if (callback) {
          callback();
        }
      });
  }

  _getUserRoles(payload) {
    const { userId, clientId, callback } = payload;

    let model = new UserRolesCollection(false, {
      clientId: clientId || this.getClientId(),
      userId: userId,
    });

    model.fetch().then(() => {
      const userIds = model.getUserIds() || [];
      const ruleIds = model.getRuleIds() || [];

      const defUsers =
        userIds.length > 0 ? this._getUsers({ ids: userIds }) : [];
      const defRules = ruleIds.map((ruleId) =>
        this._getAssignmentRules({ id: ruleId, clientId })
      );

      $.when
        .apply($, [defUsers, ...defRules])
        .then((users, ...assignmnetRules) => {
          model.setUserNames(users);
          model.setRuleNames(assignmnetRules);

          if (callback) {
            callback(model.toJSON());
          }
        });
    });
  }

  _putUserRoles(payload) {
    const { userId, roles, clientId, callback } = payload;

    const model = new UserRolesModel(
      {
        id: -1,
        items: [
          {
            userId: userId,
            userRoles: roles.map((role) => ({
              ...role,
              clientId: clientId || this.getClientId(),
            })),
          },
        ],
      },
      {
        clientId: clientId || this.getClientId(),
      }
    );

    model.save().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _postUserRole(payload) {
    const {
      userId,
      roleOverride,
      selectedRole,
      restrictionDefinitions,
      callback,
    } = payload;

    if (restrictionDefinitions) {
      delete restrictionDefinitions.lastUpdatedDate;
    }

    const model = new UserRolesModel(
      {
        overrideRole: roleOverride ? 'Y' : 'N',
        restrictionDefinitions: restrictionDefinitions,
      },
      {
        roleId: selectedRole.roleId,
        userId: userId,
      }
    );

    model.save().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _deleteUserRole(payload) {
    const { user, role, callback } = payload;

    const model = new UserRolesModel(
      {
        roleId: role.roleId,
      },
      {
        roleId: role.roleId,
        userId: user.userId,
      }
    );

    model.destroy().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _getChanges(payload) {
    const { limit, eventClassifiers, callback, role, user, offset } = payload;

    const criteria = {
      clientId: this.getClientId(),
      limit: limit,
      offset: offset,
    };

    if (role) {
      criteria.roleId = role.roleId;
    }

    if (user) {
      criteria.userId = user.userId;
    }

    const model = new AuditModel(false, {
      criteria: criteria,
      eventClassifiers: eventClassifiers,
    });

    model.fetch().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _getCapabilities(payload) {
    const { callback } = payload;

    const model = new CapabilityCollection(false, {
      clientId: this.getClientId(),
    });

    model.fetch().then(() => {
      this.publish('app:updateData', { clientCapabilities: model.toJSON() });
      if (callback) {
        callback(model.toJSON());
      }
    });
  }
  _setCapabilities(payload) {
    const { callback, capabilities } = payload;

    const model = new CapabilityModel(null, {
      clientId: this.getClientId(),
    });

    model
      .save({
        id: -1,
        items: capabilities.map((capability) => ({ capabilityId: capability })),
      })
      .then(() => {
        if (callback) {
          const data = model.toJSON();
          callback(data.items);
        }
      });
  }

  _getCapabilityGroups(payload) {
    const { callback } = payload;

    const model = new CapabilityGroupCollection();

    model.fetch().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _getPasswordPolicies(payload) {
    const { clientId, callback } = payload;

    const model = new PoliciesModel(false, {
      clientId: clientId || this.getClientId(),
    });

    model.fetch().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _resetUserPassword(payload) {
    const {
      userId,
      clientId,
      callback,
      errorCallback,
      oldPassword,
      newPassword,
    } = payload;

    const model = new CredentialsModel(
      {
        password: {
          oldPassword,
          newPassword,
        },
      },
      {
        clientId: clientId || this.getClientId(),
        userId,
      }
    );

    model
      .save(null, {
        error: errorCallback,
      })
      .then(() => {
        if (callback) {
          callback();
        }
      });
  }

  _getCountries(payload) {
    const { callback } = payload;

    const model = new CountriesCollection();

    model.fetch().then((data) => {
      if (callback) {
        callback(data);
      }
    });
  }

  _getRestrictionTypes(payload) {
    const { callback } = payload;

    const model = new RestrictionTypesCollection();
    model.fetch().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _getOdataCredentials(payload) {
    const { callback, username } = payload;

    const model = new CustomBasicAuthModel(undefined, {
      username: username,
    });
    model.fetch().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _setOdataCredentials(payload) {
    const { callback, username, limit } = payload;

    const model = new CustomBasicAuthModel(
      {
        limit: limit,
        timeUnit: 'DAYS',
      },
      {
        username: username,
      }
    );

    model.save().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _deleteOdataCredentials(payload) {
    const { callback, username } = payload;

    const model = new CustomBasicAuthModel(
      {
        id: -1,
      },
      {
        username: username,
      }
    );
    model.destroy().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _getUserRestrictions(payload) {
    const { callback, userId } = payload;

    const model = new UserRestrictionsCollection(null, {
      user: userId,
    });
    model.fetch().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _getUserCapabilities(payload) {
    const { callback, userId } = payload;

    const model = new UserCapabiltiesCollection(null, {
      user: userId,
    });
    model.fetch().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _getAssignmentRules(payload) {
    const { id, clientId, startIndex, callback } = payload;

    let options = {
      clientId: clientId || this.getClientId(),
    };

    let model = new AssignmentRulesCollection(false, options);
    if (id) {
      model = new AssignmentRulesModel(false, { ...options, id });
    }

    _.extend(model, {
      urlparams: {
        offset: startIndex,
      },
    });

    const def = new $.Deferred();
    model.fetch().then((data) => {
      def.resolve(model.toJSON());
      if (callback) {
        callback(model.toJSON(), data);
      }
    });

    return def;
  }

  _postAssignmentRules(payload) {
    const {
      assignmentRuleId,
      assignmentRuleName,
      assignmentRuleDescription,
      role,
      rule,
      callback,
      errorCallback,
    } = payload;

    const model = new AssignmentRulesModel(
      {
        id: assignmentRuleId,
        assignmentRuleId: assignmentRuleId,
        assignmentRuleName,
        assignmentRuleDescription,
        role,
        rule,
        clientId: this.getClientId(),
      },
      {
        id: assignmentRuleId,
        clientId: this.getClientId(),
      }
    );

    model.save(null, { error: errorCallback }).then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _deleteAssignmentRule(payload) {
    const { clientId, assignmentRuleId, callback } = payload;

    const model = new AssignmentRulesModel(
      {
        id: assignmentRuleId,
        assignmentRuleId: assignmentRuleId,
      },
      {
        id: assignmentRuleId,
        clientId: clientId || this.getClientId(),
      }
    );

    model.destroy().then(() => {
      if (callback) {
        callback();
      }
    });
  }

  _getUserEntitlements(payload) {
    const { callback, userId } = payload;

    const model = new UserEntitlementsModel({
      userId: userId,
    });
    const options = {};
    options.error = (r, response) => {
      if (callback) {
        callback({ capabilities: [], restrictions: [] });
      }
    };
    model.fetch(options).then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _updateUserEntitlements(payload) {
    const { callback, request } = payload;
    const restrictions = this._getRestrictions(payload.request);
    let entitlements = {};
    entitlements.capabilities = request.capabilities;

    if (restrictions.length > 0) {
      entitlements.restrictions = restrictions;
    } else {
      entitlements.restrictions = [];
    }
    const model = new UserEntitlementsModel({
      userId: request.userId,
      entitlements: entitlements,
    });
    const options = {};
    options.error = (r, response) => {
      if (response.responseJSON.errorCode !== 404) {
        this.publish('app:messageFlash', {
          messageText: response.responseJSON.message,
          messageType: 'error',
        });
      }
      if (callback) {
        callback({ capabilities: [] });
      }
    };
    if (
      entitlements.capabilities.length === 0 &&
      entitlements.restrictions.length === 0
    ) {
      model.destroy(options).then(() => {
        if (callback) {
          callback();
        }
      });
    } else {
      model.save(options).then(() => {
        if (callback) {
          callback(model.toJSON());
        }
      });
    }
  }

  _getRestrictions(payload) {
    const { restrictions } = payload;
    const allowedRestrictions = [
      Constants.INFORMATION_TYPES.MEASUREMENT,
      Constants.INFORMATION_TYPES.SITE,
    ];
    if (this.hasFeature('definition-access-roles')) {
      allowedRestrictions.push(Constants.INFORMATION_TYPES.DEFINITION);
    }
    if (restrictions.length === 0) return [];
    let clientRestrictions = [];
    let restriction = restrictions.filter(
      (restriction) =>
        restriction.informationType === Constants.INFORMATION_TYPES.CLIENT
    );
    let restrictionKeys = [];
    if (this.getClientId() === -1 && restriction && restriction[0].keys) {
      restrictionKeys = restriction[0].keys;

      clientRestrictions.push({
        informationType: Constants.INFORMATION_TYPES.CLIENT,
        accessScope: 'SPECIFIC',
        accessType: 'EXPLICIT',
        enforcementMode: 'INCLUSIVE',
        capabilityScope: 'ALL',
        keys: restrictionKeys || undefined,
      });
    }

    restrictions.forEach((restriction) => {
      if (allowedRestrictions.includes(restriction.informationType)) {
        if (restriction.accessScope === Constants.ACCESS_SCOPE.SPECIFIC) {
          if (restriction.keys && restriction.keys.length !== 0) {
            clientRestrictions.push(restriction);
          }
        } else {
          clientRestrictions.push(restriction);
        }
      } else if (
        restriction.informationType === Constants.INFORMATION_TYPES.HIERARCHY &&
        !restriction.deleted
      ) {
        clientRestrictions.push({
          ...restriction,
          informationTypeId: restriction.informationTypeId,
          keyScope: restriction.keyScope,
        });
      }
    });

    return clientRestrictions;
  }
}

export default AuthorizationController;

import Backbone from 'backbone';
import BMCatModel from 'models/clients/measures/slatents/bmcat';

var BMCatCollection = Backbone.Collection.extend({
  model: BMCatModel,

  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
    this.slatent = options.sLatentKey;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/slatents/${this.slatent}/bmcat`;
  },
});

export default BMCatCollection;

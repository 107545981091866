import Backbone from 'backbone';

var AnswerModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    this.set('customQuestionId', this.collection.customQuestionId);
  },

  idAttribute: 'customAnswerId',
  serviceId: 'acs-services',
  validate: function (attr) {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = attr.label;
    var test = tmp.textContent || tmp.innerText || '';
    if (test.length < 1 || test.length > 1024) {
      return true;
    }
  },

  parse: function (data) {
    delete data.displayLabel;
    delete data.displaySurveyLabel;
    delete data.displaySurveyValue;
    delete data.displayValue;
    delete data.links;
    delete data.value;
    delete data.vndType;

    return data;
  },
});

export default AnswerModel;

import Backbone from 'backbone';

var BenchmarkCategoryDetailModel = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    name: '',
    description: '',
    members: [],
  },

  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
    this.slatent = options.sLatentKey;
    this.bmcat = options.bmCatKey;
  },

  parse: function (data) {
    data.name = data.desc;
    data.description = data.def;
    delete data.desc;
    delete data.def;
    return data;
  },

  // toJSON: function () {
  //   return _.extend(this.attributes,{
  //     categories: this.categories.toJSON(),
  //     score: (this.get('score')) ? parseFloat(this.get('score').toFixed(1)) : null
  //   });
  // },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/slatents/${this.slatent}/bmcat/${this.bmcat}/details`;
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default BenchmarkCategoryDetailModel;

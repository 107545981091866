import Backbone from 'backbone';

const CapabilityCollection = Backbone.Collection.extend({
  model: Backbone.Model,

  initialize: function (models, opts) {
    var options = opts || {};
    this.clientId = options.clientId;
  },

  parse: function (data) {
    return data.items;
  },

  url: function () {
    return `authorization-service/clients/${this.clientId}/capabilities`;
  },
});

export default CapabilityCollection;

import moment from 'moment';

//  Do Moment I8ln here
if (typeof moment === 'function') {
  moment.locale('pt', {
    months:
      'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split(
        '_'
      ),
    monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_'),
    weekdays:
      'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split(
        '_'
      ),
    weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
    weekdaysMin: 'Dom_2ª_3ª_4ª_5ª_6ª_Sáb'.split('_'),
    longDateFormat: {
      LT: 'HH:mm',
      L: 'DD/MM/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY LT',
      LLLL: 'dddd, D [de] MMMM [de] YYYY LT',
    },
    calendar: {
      sameDay: '[Hoje às] LT',
      nextDay: '[Amanhã às] LT',
      nextWeek: 'dddd [às] LT',
      lastDay: '[Ontem às] LT',
      lastWeek: function () {
        return this.day() === 0 || this.day() === 6
          ? '[Último] dddd [às] LT' // Saturday + Sunday
          : '[Última] dddd [às] LT'; // Monday - Friday
      },
      sameElse: 'L',
    },
    relativeTime: {
      future: 'em %s',
      past: '%s atrás',
      s: 'segundos',
      m: 'um minuto',
      mm: '%d minutos',
      h: 'uma hora',
      hh: '%d horas',
      d: 'um dia',
      dd: '%d dias',
      M: 'um mês',
      MM: '%d meses',
      y: 'um ano',
      yy: '%d anos',
    },
    ordinal: '%dº',
  });
}
/**
 * Brazilian Portuguese
 * @type {Object}
 */

export default {
  /**
   *  The name of the language
   */
  _name: 'Português',

  /*
   *  Site strings
   */
  General: {
    help: 'Ajuda',
    logout: 'Encerrar sessão',
    cancel: 'Cancelar',
    submit: 'Enviar',
    ok: 'Ok',
  },
};

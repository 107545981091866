import Backbone from 'backbone';
import _ from 'underscore';
import ScoresByAnswerCollection from 'collections/clients/measures/questions/scoresbyanswer';
import AnswerCollection from 'collections/clients/measures/questions/answers';
import ScoresByAnswerModel from 'models/clients/measures/questions/scoresbyanswer';

var Question = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    label: '',
    latentKey: '',
    phrase: '',
    questionId: '',
    questionKey: 0,
    questionType: '',
    questionTypeId: 0,
    questionTypeKey: '',
    respTypeId: 0,
    text: '',
    vndType: 'application/vnd.foresee.question',
  },

  idAttribute: 'questionKey',

  /**
   * Constructor
   */
  initialize: function (model, opts) {
    var options = opts || {};
    this.averageRating = 0;

    this.client = options.clientId;
    this.measure = options.measurementKey;

    this.answers = new AnswerCollection();
    this.answerScores = new ScoresByAnswerCollection();
  },

  serviceId: 'foresee-services',

  toJSON: function (options = {}) {
    let answers;
    if (options.joined) {
      answers = this.get('answers');
    } else {
      answers = this.answers.map((answer) => {
        let scoreRecord = this.answerScores.findWhere({
          answerKey: answer.get('answerKey'),
        });
        scoreRecord = scoreRecord || new ScoresByAnswerModel();
        return _.extend(scoreRecord.toJSON(), answer.toJSON());
      });
    }

    return _.extend(this.attributes, {
      answers: answers,
      averageRating: parseFloat(this.averageRating).toFixed(1),
    });
  },

  getAnswers: function (options, data) {
    options.questionKey = this.get('questionKey');
    this.answers = new AnswerCollection(false, options);
    return this.answers;
  },

  getScoresByAnswers: function (options, data) {
    options.questionKey = this.get('questionKey');
    this.answerScores = new ScoresByAnswerCollection(false, options);
    return this.answerScores;
  },

  joinAnswerData: function (data) {
    let answers = this.answers.toJSON();
    answers.forEach((a) => {
      let joinData = data.question.answers.find((q) => {
        return parseInt(q.answerKey, 10) === a.answerKey;
      });
      if (joinData) {
        a.answerLabel = a.displayLabel;
        a.answerPercentage = joinData.answerPercentage;
        a.n = joinData.n;
        a.score = joinData.score;
      }
    });
    this.set('answers', answers);
  },

  getAverageRating: function () {
    var ratings = this.answerScores.map((score) => {
      var answerDetails = this.answers.findWhere({
          answerKey: score.get('answerKey'),
        }),
        n = score.get('n'),
        value =
          answerDetails.get('value') === 9999 ? 0 : answerDetails.get('value');
      return {
        n: value ? n : 0,
        totalRating: n * value,
      };
    });

    var ns = _.pluck(ratings, 'n'),
      rs = _.pluck(ratings, 'totalRating'),
      totalNCount = 0,
      totalRatingValue = 0;

    for (let i = 0; i < ns.length; i++) {
      totalNCount += ns[i];
    }

    for (let i = 0; i < rs.length; i++) {
      totalRatingValue += rs[i];
    }

    this.averageRating = totalRatingValue / totalNCount;
  },

  url: function () {
    return `clients/${this.client}/measures/${
      this.measure
    }/questions/${this.get('questionKey')}`;
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default Question;

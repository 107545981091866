const LUX_STORAGE_KEY = 'lux';

export const showLux = () => {
  return localStorage.getItem(LUX_STORAGE_KEY) === 'true' || false;
};

export const LuxConditionalWrapper = ({ wrapper, children }) =>
  showLux() ? children : wrapper(children);

export const getAccessToken = () => {
  // The default value is an empty JSON object
  const sonomaToken = localStorage.getItem('sonomaAccessToken') || '.e30=';
  const access = sonomaToken?.split('.')[1];
  return JSON.parse(atob(access) || {});
};

import Backbone from 'backbone';

import _ from 'underscore';
import semver from 'semver';

var CodeVersionCollection = Backbone.Collection.extend({
  initialize: function (opts) {},

  setLatest: function (versions) {
    const latestVersion = _.max(versions, function (a) {
      return new Date(a.latest).getTime();
    });
    latestVersion.isLatest = true;
  },

  parse: function (data) {
    const versions = data.message;
    this.setLatest(versions);

    const versionsSortedByVersion = versions.sort(function (a, b) {
      return semver.rcompare(a.version, b.version);
    });

    const versionsSortedBySupport = _.sortBy(
      versionsSortedByVersion,
      function (obj) {
        return obj.invalid;
      }
    );

    return versionsSortedBySupport;
  },

  url: function () {
    return `hostedcode/versions`;
  },
});

export default CodeVersionCollection;

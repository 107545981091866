import React from 'react';
import ReactDom from 'react-dom';
import { merge } from 'lodash';
import ClassNames from 'classnames';
import BaseView from 'views/base';

/*
    Flyout featuring React-based position calculation with deferred rendering

    @prop {Object} parentFrame:
          Defines the frame to which the flyout will be bound, presumably the frame
          of the initiating element.

          - {Number} top:
            The offset of the initiating element from the top of the visible page
          - {Number} left:
            The offset of the initiating element from the left edge of the page
          - {Number} width:
            The width of the initiating element
          - {Number} height:
            The height of the initiating element

    @prop {Number} maxWidth: (default: 5* the width of parentFrame)
          Pass a number via this prop to directly set the max-width independant of
          the size of parentFrame. Useful for small icon buttons that may need long
          tooltips.

    @prop {Boolean} setWidth: (default: false)
          Set to true, this forces the width of the flyout to be equal to the width
          of parentFrame.

    @prop {Boolean} setMinWidth: (default: false)
          Set to true, this sets the minimum width of the flyout to be equal to the
          width of parentFrame.

    @prop {Object} attachment:
          Defines the corner of the parentFrame to which the flyout will be attached
          and the axis along which the flyout will extend.

          - {String} vertical: [top|middle|bottom] (default: "bottom")
          - {String} horizontal: [left|center|right] (default: "left")
          - {String} axis: [horizontal|vertical] (default: vertical)
  */
class Flyout extends BaseView {
  constructor(props) {
    super(props);

    var vertical = this.props.attachment.vertical,
      horizontal = this.props.attachment.horizontal,
      axis = this.props.attachment.axis,
      maxWidth = this.props.maxWidth
        ? this.props.maxWidth
        : this.props.parentFrame.width * 5,
      state = {
        style: {
          top: -2000,
          left: -2000,
          maxWidth: this.props.hasMaxWidth ? maxWidth : null,
        },
        attachment: {
          vertical: vertical,
          horizontal: horizontal,
          axis: axis,
        },
      },
      widthConstraint = false;

    if (this.props.setMinWidth && !this.props.setWidth)
      widthConstraint = 'minWidth';
    if (this.props.setWidth) widthConstraint = 'width';

    if (widthConstraint) {
      state.style[widthConstraint] = this.props.parentFrame.width;
    }

    this.state = state;

    this.flyoutStates = {
      top: 'flyout--attachment-top',
      middle: 'flyout--attachment-middle',
      bottom: 'flyout--attachment-bottom',
      left: 'flyout--attachment-left',
      center: 'flyout--attachment-center',
      right: 'flyout--attachment-right',
      vertical: 'flyout--axis-vertical',
      horizontal: 'flyout--axis-horizontal',
    };
  }

  componentDidMount() {
    let flyout = ReactDom.findDOMNode(this),
      composedStyle = this.getState('style'),
      attachment = this.state.attachment,
      parentFrame = this.props.parentFrame,
      flyoutFrame = {
        size: {
          width: parseInt(flyout.offsetWidth, 10),
          height: parseInt(flyout.offsetHeight, 10),
        },
        origin: {},
      },
      metrics = this.modifyFrameForAttachment(
        flyoutFrame,
        attachment,
        parentFrame
      );

    composedStyle = merge({}, composedStyle, metrics.flyoutFrame.origin);

    this.setState({ style: composedStyle, attachment: metrics.attachment });
  }

  modifyFrameForAttachment(flyoutFrame, attachment, parentFrame, reflow) {
    var attachmentPoint = {
        x: 0,
        y: 0,
      },
      flyoutOffset = {
        x: 0,
        y: 0,
      },
      vertical = attachment.axis === 'vertical',
      options;

    switch (attachment.vertical) {
      case 'top':
        attachmentPoint.y = parentFrame.top;
        flyoutOffset.y = vertical ? 0 - flyoutFrame.size.height : 0;
        break;
      case 'middle':
        attachmentPoint.y = parentFrame.top + parentFrame.height / 2;
        flyoutOffset.y = 0 - flyoutFrame.size.height / 2;
        break;
      default:
        attachmentPoint.y = parentFrame.top + parentFrame.height;
        flyoutOffset.y = !vertical ? 0 - flyoutFrame.size.height : 0;
    }
    switch (attachment.horizontal) {
      case 'right':
        attachmentPoint.x = parentFrame.left + parentFrame.width;
        flyoutOffset.x = vertical ? 0 - flyoutFrame.size.width : 0;
        break;
      case 'center':
        attachmentPoint.x = parentFrame.left + parentFrame.width / 2;
        flyoutOffset.x = 0 - flyoutFrame.size.width / 2;
        break;
      default:
        attachmentPoint.x = parentFrame.left;
        flyoutOffset.x = !vertical ? 0 - flyoutFrame.size.width : 0;
    }

    flyoutFrame.origin.top = attachmentPoint.y
      ? attachmentPoint.y + flyoutOffset.y
      : null;
    flyoutFrame.origin.left = attachmentPoint.x + flyoutOffset.x;

    var metrics = {
      flyoutFrame: flyoutFrame,
      attachment: attachment,
    };

    options = {};

    // Readjust flyout origin if needed
    if (
      (flyoutFrame.origin.top + flyoutFrame.size.height >
        window.innerHeight - 20 ||
        flyoutFrame.origin.top < 20) &&
      !reflow
    ) {
      options = {
        vertical: ['top', 'bottom'],
        horizontal: ['bottom', 'top'],
      };

      attachment.vertical =
        flyoutFrame.origin.top > window.innerHeight / 2
          ? options[attachment.axis][0]
          : options[attachment.axis][1];
      metrics = this.modifyFrameForAttachment(
        flyoutFrame,
        attachment,
        parentFrame,
        true
      );
    }
    if (
      (flyoutFrame.origin.left + flyoutFrame.size.width >
        window.innerWidth - 20 ||
        flyoutFrame.origin.left < 20) &&
      !reflow
    ) {
      options = {
        vertical: ['right', 'left'],
        horizontal: ['left', 'right'],
      };

      attachment.horizontal =
        flyoutFrame.origin.left > window.innerWidth / 2
          ? options[attachment.axis][0]
          : options[attachment.axis][1];
      metrics = this.modifyFrameForAttachment(
        flyoutFrame,
        attachment,
        parentFrame,
        true
      );
    }

    return metrics;
  }

  render() {
    var className =
        (this.props.className ? this.props.className + ' ' : '') +
        this.flyoutStates[this.state.attachment.vertical] +
        ' ' +
        this.flyoutStates[this.state.attachment.horizontal] +
        ' ' +
        this.flyoutStates[this.state.attachment.axis],
      caretClass = ClassNames({
        tooltip__caret: true,
        'tooltip__caret--inverse': this.props.inverse,
      });
    return (
      <div className={className} style={this.state.style}>
        {this.props.children}
        <div className={caretClass} />
      </div>
    );
  }
}
Flyout.defaultProps = {
  setWidth: false,
  setMinWidth: false,
  hasMaxWidth: true,
  attachment: {
    vertical: 'bottom',
    horizontal: 'left',
    axis: 'vertical',
  },
};

export default Flyout;

import BaseController from 'controllers/base';
import $ from 'jquery';
import moment from 'moment';
import AggregateDataModel from 'models/clients/projects/topics/summary';
import OpenEndsCollection from 'collections/clients/projects/respondents';
import OpenEndsModel from 'models/clients/projects/respondent';
import _ from 'underscore';
import store from 'core/store';
import cxRAPIRequest from 'core/utils/cxr-api-request';
import JSTimezoneDetect from 'jstimezonedetect';
import DateRange from 'models/dateRange';

class InsightsController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'insights',
      actions: {
        'route:data': 'onRouteData',
        'location:change': 'requestRouteData',

        'projects:data': 'onProjectsData',
        'feedback-survey:data': '_handleSurveyData',

        'insights:initialized': 'requestRouteData',
        'insights:projectChange': 'setCurrentProject',
        'insights:getCurrentProject': 'getCurrentProject',
        'insights:getProjects': 'getProjects',
        'insights:getFilters': 'getFilters',

        'insights:daterange:getInitialDates': 'getParsedInitialDates',
        'insights:daterange:getFiscalConfig': 'getFiscalConfig',
        'insights:daterange:lookup': 'handleCalendarLookup',
        'insights:daterange:calendarChange': 'handleCalendarChange',
        'insights:daterange:set': 'handleDateRangeChanged',
        'insights:daterange:get': 'getDates',

        'insights:type': 'setInsightType',
        'insights:filter': 'setFilters',
        'insights:clearFilters': 'clearFilters',
        'insights:undoFilter': 'undoLastFilter',
        'insights:setUrl': 'setInsightUrl',
        'insights:setSearch': 'setSearchTerm',
        'insights:page': 'setInPageUrl',
        'insights:respondents:setSortCriteria': 'setSortCriteria',
        'insights:respondents:setSearch': 'setSearch',
        'insights:respondents:resetSort': 'resetSortCriteria',
        'insights:respondents:resetSearch': 'resetSearchTerm',
        'insights:respondents:delete': 'deleteResponse',
        'insights:replayMetadata:get': 'getReplayMetadata',
        'insights:deleteReplay': 'deleteReplay',
        'insights:export:respondents:csv': 'exportRespondentsCSV',
      },
      dependsOn: ['projects', 'user', 'reports', 'filters', 'feedback-survey'],
    });
  }

  initialize(opts) {
    var clientId = this.getClientId();

    this.options = {
      clientId: clientId,
      app: opts.app,
    };

    this.selected = 1;
    this.replayMetadata = {};
    this.parseBaseDates = {};
    this.undoDatePayload = {};
    this.fixedRatingQuestionId = null;
    this.resetSortCriteria();

    if (this.project) {
      this.insightFilters =
        store.get(
          clientId + '_' + this.project.projectId + '_insightFilters'
        ) || [];
      this.insightFilterDetails =
        store.get(
          clientId + '_' + this.project.projectId + '_insightFilterDetails'
        ) || [];
    }

    this.clientModel = this.getClientModel();
    this.fiscalConfig = false;
    // if(this.clientModel.hasFiscalCalendar) {
    //   this.fiscalConfig = {};
    //   this.fiscalConfig.fiscalWeekStart = this.clientModel.fiscalWeekStart;
    //   this.fiscalConfig.fiscalYears = this.clientModel.fiscalYears;
    //   this.publish("insights:daterange:fiscalConfig", this.fiscalConfig);
    // }

    this.publish('storage:get', {
      key: 'insights-dateRange',
      callback: function (payload) {
        var start_date = new moment().startOf('day'),
          end_date = new moment().endOf('day'),
          rangeType = 'D',
          selected = 1,
          calendarType = 'G',
          number = 0;

        if (!payload.expired && payload.data && payload.data.data) {
          var dateRange = payload.data.data;
          start_date = new moment(dateRange.start_date);
          end_date = new moment(dateRange.end_date);
          rangeType = dateRange.rangeType;
          this.selected = selected = payload.data.data.selected;
          calendarType =
            typeof payload.data.data.calendarType === 'undefined'
              ? 'G'
              : payload.data.data.calendarType;
          number =
            typeof payload.data.data.number === 'undefined'
              ? 0
              : payload.data.data.number;
          this.parseBaseDates.fiscalConfig = this.fiscalConfig;
        }

        this.start_date = start_date;
        this.end_date = end_date;
        if (rangeType !== 'D') {
          this.start_date = start_date = start_date.startOf('day');
          this.end_date = end_date = end_date.endOf('day');
        }

        if (!payload.data) {
          this.publish('storage:set', {
            key: 'insights-dateRange',
            data: {
              start_date: start_date,
              end_date: end_date,
              rangeType: rangeType,
              selected: selected,
              fiscalConfig: this.fiscalConfig,
              calendarType: calendarType,
              number: number,
            },
          });
        }

        this.publish('storage:get', {
          key: 'insights-dateRangeType',
          callback: function (payload) {
            var dateRangeType = 'DATE_TIME';
            if (!payload.data) {
              this.publish('storage:set', {
                key: 'insights-dateRangeType',
                data: {
                  dateRangeType: dateRangeType,
                },
              });
            } else {
              dateRangeType = payload.data.data.dateRangeType;
            }
            this.handleCalendarLookup({
              calendarType: calendarType,
              rangeType: rangeType,
              number: number,
              startDate: start_date,
              endDate: end_date,
              setParseDate: true,
              dateRangeType: dateRangeType,
              callback: function (data) {
                this.parseBaseDates.selected = this.selected;
                this.parseBaseDates.fiscalConfig = this.fiscalConfig;
                this.publish(
                  'insights:daterange:parsedDateRange',
                  this.parseBaseDates
                );
                if (
                  this.isToDatePreset({
                    dateRangeType: data.t,
                    number: data.n,
                    rangeType: data.r,
                  })
                ) {
                  data.a = null;
                }
                this.publish('insights:daterange:criteria', data);
              }.bind(this),
            });

            this.setBaseDate(
              start_date.format('YYYY-MM-DDTHH:mm:ssZZ'),
              end_date.format('YYYY-MM-DDTHH:mm:ssZZ'),
              dateRangeType,
              rangeType,
              calendarType,
              number
            );

            this.rangeType = rangeType;

            _.extend(this, {
              currentProjectId: null,
              insightData: {},
              unfilteredData: {
                results: {},
                dateRange: {},
              },
              insightRange: this.baseDate,
              insightType: '',
              insightUrl: '',
              insightSearchTerm: '',
              insightPageRequest: null,
              lastFilterChange: null,
            });

            this.publish('route:get', {
              callback: (data) => {
                data = data || {};
                data = data.pathIds || {};

                var projectId = Number(data.projects);

                if (_.isNumber(projectId) && !isNaN(projectId)) {
                  this.currentProjectId = projectId;
                }
              },
            });
            this.publish('projects:get', {
              callback: (data) => {
                this.onProjectsData(data);
              },
            });
          }.bind(this),
        });
      }.bind(this),
    });
  }

  resetSortCriteria() {
    this.insightSortCriteria = {
      orderByRating: false,
      ascending: false,
    };
  }

  resetSearchTerm() {
    this.insightSearchTerm = '';
  }

  setSortCriteria(data) {
    this.insightSortCriteria = data.sortCriteria;
    if (data.callback) {
      data.callback();
    }
  }

  _handleSurveyData(data) {
    if (typeof data.questions === 'undefined' || data.questions.length <= 0) {
      return;
    }

    const fixedRatingQuestion = data.questions.filter(
      (question) => question.type === 'QT_STAR_RATING'
    );

    this.fixedRatingQuestionId = fixedRatingQuestion[0].customQuestionId;
    this.publish('insights:fixedRatingQuestionId', this.fixedRatingQuestionId);
  }

  requestRouteData(data) {
    this.publish('route:get');
  }

  // function emits number of total respondents to display in page
  // post message controller listens for action
  sendNumResponses(model) {
    model = model || {};
    model.get = model.get || _.noop();
    var respondents = model.get('totalRespondents');
    respondents = !!respondents ? respondents : 0;
    this.publish('insights:respondentsCount', {
      numResponses: respondents,
    });
  }

  // route data is used to maintain project state with in app insights
  onRouteData(data) {
    data = data || {};
    data = data.pathIds || {};

    var projectId = Number(data.projects);

    if (_.isNumber(projectId) && !isNaN(projectId)) {
      this.currentProjectId = projectId;
      this.setCurrentProject();
    }
  }

  // function: attempts to set current project
  // emits projects to project dropdown
  onProjectsData(data) {
    this.projects = data.projects || {};
    this.setCurrentProject();
    this.publish('insights:projects', this.projects);
  }

  // Insighting controller can be instantiated in multiple scenarios
  // multiple functions call setCurrentProject
  // Setting current project key aspect of insights initialization
  // Necessary prior to getting current topics or hit respondent endpoints
  // - Projects are directly passed to function due to user input
  // - In page insights does not contain project id in route initially,
  //   when projects defined, default to first one
  // - Routed projects require project list and route data to set current
  setCurrentProject(project) {
    const feedbackProjects = _.filter(this.projects, { type: 'FEEDBACK' });
    if (!_.isEmpty(project)) {
      // project = project;
    } else if (!_.isEmpty(feedbackProjects) && !this.currentProjectId) {
      // This is for the iframe, it takes the first project as there is no project Id to begin with.
      // set the current project to the first feedback project
      project = feedbackProjects[0];
    } else if (!!this.currentProjectId && !_.isEmpty(this.projects)) {
      // For both iframe and the other route where projectId is set, just get the project here.
      project = _.findWhere(this.projects, {
        projectId: this.currentProjectId,
      });
    }

    // Scenario of changing a project.
    if (!!project && this.currProject !== project) {
      this.insightSearchTerm = '';
      this.currentProjectId = project.projectId;
      this.currProject = project;
      this.insightFilters =
        store.get(
          this.options.clientId +
            '_' +
            this.currentProjectId +
            '_insightFilters'
        ) || [];
      this.insightFilterDetails =
        store.get(
          this.options.clientId +
            '_' +
            this.currentProjectId +
            '_insightFilterDetails'
        ) || [];
      this.publish('insights:currentProject', this.currProject);
      this.getInsight({
        type: this.insightType,
      });
    }
  }

  handleCalendarLookup(payload) {
    let today =
        typeof payload.today !== 'undefined' ? payload.today : new moment(),
      calendarType = payload.calendarType || this.baseDate.c,
      clientId = calendarType === 'G' ? '' : this.getClientId(),
      callback = payload.callback || null,
      first = null,
      last = null,
      dateRangeType = payload.dateRangeType
        ? payload.dateRangeType
        : 'DATE_TIME';

    let toDatePresetData = {
      dateRangeType: dateRangeType,
      number: payload.number,
      rangeType: payload.rangeType,
    };

    if (payload.rangeType === 'C') {
      first = payload.startDate;
      last = payload.endDate;
    }

    if (this.isToDatePreset(toDatePresetData)) {
      today = null;
    }

    var criteria = {
      customerKey: clientId,
      calendarType: calendarType,
      rangeType: payload.rangeType,
      number: payload.number,
      asOf: today,
      first: first,
      last: last,
      dateRangeType: dateRangeType,
    };

    var date = new DateRange(criteria);

    _.extend(date, {
      urlparams: {
        dateRange: JSON.stringify(date.toJSON({ format: 'YYYY-MM-DD' })),
      },
    });

    return date.fetch().then(
      function (setParseDate, data) {
        data = this.parseDates(data, this.parseItem);
        const start_date = new moment(data.f),
          end_date = new moment(data.l),
          number = data.n;
        if (setParseDate) {
          this.parseBaseDates.start_date = start_date;
          this.parseBaseDates.end_date = end_date;
          this.parseBaseDates.calendarType = data.c;
          this.parseBaseDates.number = number;
          this.parseBaseDates.rangeType = data.r;
        }

        if (callback) {
          callback(data);
        }

        const dateCriteria = {
          rangeType: data.r,
          start_date: data.f,
          end_date: data.l,
          number: data.n,
          calendarType: calendarType,
          selected: payload.selected,
          selectedDateId: payload.id,
        };
        this.handleDateRangeChanged(dateCriteria);
      }.bind(this, payload.setParseDate)
    );
  }

  parseDates(dateRange, parseItem) {
    var parsed = {
      c: parseItem(dateRange.c, 'calendarTypes'),
      r: parseItem(dateRange.r, 'rangeTypes'),
      p: parseItem(dateRange.p, 'periodTypes'),
      n: dateRange.n,
      a: dateRange.a,
      f: dateRange.f,
      l: dateRange.l,
      k: dateRange.k,
      t: dateRange.t,
      v: dateRange.v,
    };
    return parsed;
  }

  parseItem(t, type) {
    var types = DateRange[type];
    if (_.contains(_.keys(types), t)) {
      t = types[t];
    }
    return t;
  }

  handleDateRangeChanged(payload) {
    this.lastFilterChange = {
      payload: this.undoDatePayload,
      undoHandler: 'handleDateRangeChanged',
    };
    this.undoDatePayload = payload;

    var today =
        typeof payload.today !== 'undefined' ? payload.today : new moment(),
      calendarType = payload.calendarType || this.baseDate.c,
      rangeType = 'C',
      start_date = null,
      end_date = null,
      number = null,
      verbose = false,
      rolling = false,
      clientId = calendarType === 'G' ? '' : this.getClientId(),
      dateRangeType = payload.dateRangeType
        ? payload.dateRangeType
        : 'DATE_TIME';

    let toDatePresetData = {
      dateRangeType: dateRangeType,
      number: payload.number,
      rangeType: payload.rangeType,
    };

    this.selected = payload.selected || 0;

    if (this.isToDatePreset(toDatePresetData)) {
      today = null;
    }

    if (typeof payload.rangeType === 'undefined' || payload.rangeType === 'C') {
      start_date = payload.start_date;
      end_date = payload.end_date;
    } else {
      rangeType = payload.rangeType;
      number = payload.number;
    }

    var criteria = {
      customerKey: clientId,
      calendarType: calendarType,
      rangeType: rangeType,
      number: number,
      asOf: today,
      first: start_date,
      last: end_date,
      verbose: verbose,
      rolling: rolling,
      dateRangeType: dateRangeType,
    };

    var date = new DateRange(criteria);
    const daterange = date.toJSON({ format: 'YYYY-MM-DD' });
    _.extend(date, {
      urlparams: {
        dateRange: JSON.stringify(date.toJSON({ format: 'YYYY-MM-DD' })),
      },
    });

    date.fetch().then(
      function (daterange, data) {
        data = this.parseDates(data, this.parseItem);
        var start_date = new moment(data.f),
          end_date = new moment(data.l),
          number = data.n,
          dateRangeType = data.t;

        this.start_date = start_date;
        this.end_date = end_date;

        if (rangeType !== 'D') {
          this.start_date = start_date = start_date.startOf('day');
          this.end_date = end_date = end_date.endOf('day');
        }

        this.setBaseDate(
          start_date.format('YYYY-MM-DDTHH:mm:ssZZ'),
          end_date.format('YYYY-MM-DDTHH:mm:ssZZ'),
          dateRangeType,
          rangeType,
          calendarType,
          number
        );

        this.rangeType = data.r;

        this.publish('storage:set', {
          key: 'insights-dateRange',
          data: {
            start_date: start_date,
            end_date: end_date,
            rangeType: rangeType,
            selected: this.selected,
            calendarType: calendarType,
            number: number,
          },
        });

        this.publish('insights:dateRange', {
          unformatted_start_date: moment(data.f),
          unformatted_end_date: moment(data.l),
          start_date: start_date,
          end_date: end_date,
          rangeType: rangeType,
          selected: this.selected,
          calendarType: calendarType,
          dateRangeType: dateRangeType,
          number: number,
        });
        if (
          this.isToDatePreset({
            dateRangeType: daterange.t,
            number: daterange.n,
            rangeType: daterange.r,
          })
        ) {
          daterange.a = null;
        }
        daterange.result = data;
        this.publish('insights:daterange:criteria', daterange);
      }.bind(this, daterange)
    );
  }

  handleCalendarChange(calendarType) {
    this.baseDate.c = calendarType;
    var dateCriteria = {
      rangeType: this.baseDate.r,
      start_date: this.baseDate.f,
      end_date: this.baseDate.l,
      number: this.baseDate.n,
      calendarType: calendarType,
    };
    this.handleDateRangeChanged(dateCriteria);
  }

  getParsedInitialDates(dateRangeType) {
    dateRangeType = dateRangeType === 'DATE_TIME' ? dateRangeType : 'DATE_ONLY';
    this.publish('storage:get', {
      key: 'insights-dateRange',
      callback: function (payload) {
        if (!payload.expired && payload.data && payload.data.data) {
          var dateRange = payload.data.data,
            start_date = new moment(dateRange.start_date),
            end_date = new moment(dateRange.end_date),
            rangeType = dateRange.rangeType,
            calendarType =
              typeof payload.data.data.calendarType === 'undefined'
                ? 'G'
                : payload.data.data.calendarType,
            number =
              typeof payload.data.data.number === 'undefined'
                ? 0
                : payload.data.data.number;

          this.selected = payload.data.data.selected;

          this.handleCalendarLookup({
            calendarType: calendarType,
            rangeType: rangeType,
            number: number,
            startDate: start_date,
            endDate: end_date,
            setParseDate: true,
            dateRangeType: dateRangeType,
            callback: function (data) {
              this.parseBaseDates.selected = this.selected;
              this.publish(
                'insights:daterange:initialDates',
                this.parseBaseDates
              );
            }.bind(this),
          });
        }
      }.bind(this),
    });
  }

  getFiscalConfig() {
    this.fiscalConfig = false;
    // if(this.clientModel.hasFiscalCalendar) {
    //   this.fiscalConfig = {};
    //   this.fiscalConfig.fiscalWeekStart = this.clientModel.fiscalWeekStart;
    //   this.fiscalConfig.fiscalYears = this.clientModel.fiscalYears;
    // }
    this.publish('insights:daterange:fiscalConfig', this.fiscalConfig);
  }

  getDates(payload) {
    var options = payload || {},
      callback = options.callback || null;
    if (callback) {
      callback(this.dates);
    }
  }

  isToDatePreset(data) {
    if (
      (data.dateRangeType === 'DATE_TIME' &&
        data.number === 0 &&
        data.rangeType !== 'C') ||
      data.rangeType === 'H'
    ) {
      return true;
    }
    return false;
  }

  setBaseDate(
    start_date,
    end_date,
    dateRangeType,
    rangeType,
    calendarType,
    number
  ) {
    this.dates = {
      start_date: start_date,
      end_date: end_date,
    };

    let range = this.baseDate;
    let payload = {
      dateRangeType: dateRangeType,
      number: number,
      rangeType: rangeType,
    };
    let asOf = new moment().format('YYYY-MM-DD');
    if (rangeType === 'C') {
      this.baseDate = {
        c: calendarType,
        t: dateRangeType,
        r: 'C',
        p: 'D',
        n: 0,
        k: this.client,
        v: '',
        a: asOf,
        f: start_date,
        l: end_date,
      };
    } else {
      if (this.isToDatePreset(payload)) {
        asOf = null;
      }
      this.baseDate = {
        c: calendarType,
        t: dateRangeType,
        r: rangeType,
        p: 'D',
        n: number,
        a: asOf,
        k: this.client,
        o: true,
      };
    }

    if (!_.isEqual(this.baseDate, range)) {
      this.insightRange = this.baseDate;
      if (this.insightType !== 'openends') {
        this.getInsight({
          type: this.insightType,
        });
      }
    }
  }

  // function invoked by postMessage controller event listener
  // - invokes functions to format url
  // - sets in page controller state
  // - emits url to ui components
  setInPageUrl(payload) {
    payload = payload || {};

    var storedUrlNameData = store.get(
      this.options.clientId + '_insightUrlName'
    );
    var storedUrlName = 'current';
    var url;

    if (!_.isUndefined(storedUrlNameData)) {
      var now = new Date().getTime();
      var expires = new Date(storedUrlNameData.timeStamp);
      expires.setHours(expires.getHours() + 12);

      if (now < expires.getTime()) {
        storedUrlName = storedUrlNameData.urlName;
      }
    }

    if (!_.isUndefined(payload.location)) {
      url = this.generateLocationObject(payload.location);
      this.setInsightUrl({
        url: url.href,
        urlName: storedUrlName,
      });
    }

    if (url && url.href) {
      this.publish('insights:inPageUrl', url.href);
    }
  }

  // responds to "insights:getProjects" event
  getProjects() {
    this.publish('projects:get');
  }

  getFilterDetails(questionFilter, surveyQuestions, oldFilterDetail) {
    const topicQuestions =
      this.filterDetailsDataUnfiltered.get('topicQuestions') || [];
    const ratingQuestion =
      this.filterDetailsDataUnfiltered.get('ratingQuestion') || [];
    const topicQuestion =
      this.filterDetailsDataUnfiltered.get('topicQuestion') || [];

    const allQuestions = topicQuestions
      .concat(ratingQuestion)
      .concat(topicQuestion);

    let question =
      _.findWhere(allQuestions, { questionId: questionFilter.questionId }) ||
      _.findWhere(surveyQuestions, { questionId: questionFilter.questionId });

    if (oldFilterDetail) {
      question = oldFilterDetail.question;
    }

    if (question) {
      let answers = [];
      _.each(questionFilter.answerIds, function (id) {
        let answer = _.findWhere(question.feedbackAnswers, { answerId: id });
        if (answer) {
          if (!answer.answerLabel && !!answer.answerValue) {
            const stars = Number(answer.answerValue);
            answer.answerLabel = stars + ' Star';
          }

          answers.push(answer);
        }
      });

      return {
        question,
        answers,
      };
    }

    return false;
  }

  // responds to "insights:getFilters" event
  getFilters(surveyQuestions) {
    var filterDetails = this.insightFilterDetails || [];

    if (filterDetails.length === 0) {
      _.each(
        this.insightFilters,
        function (filter) {
          var detail = this.getFilterDetails(filter, surveyQuestions);
          if (detail) {
            filterDetails.push(detail);
          }
        },
        this
      );
    }

    this.publish('insights:filters', filterDetails);
    return filterDetails;
  }

  // repsponds to "insights:getCurrentProject" event
  getCurrentProject() {
    if (!this.currProject) {
      return;
    }
    this.publish('insights:currentProject', this.currProject);
  }

  // ----------------------- Fetch Data ------------------------ //

  /*
   * repondent count fetch
   */
  getRespondentCount() {
    var summaryCollectionType = 'summary';

    if (!this.hasRespondentCountSettings()) {
      return false;
    }

    // if open ends currently fetched, use as length
    if (
      this.insightType === summaryCollectionType &&
      !_.isEmpty(this.insightData)
    ) {
      this.sendNumResponses(this.insightData);
    } else {
      this.resCount = this.dataCollection(summaryCollectionType);
      this.extendInsightDataParams(this.resCount);
      this.fetchData('resCount').done(
        _.bind(function () {
          // invoke function to publish count to postMessage controller
          this.sendNumResponses(this.resCount);
        }, this)
      );
    }
  }

  /*
   *  insight fetch
   */
  getInsight(params, callback) {
    // This was added to fix an issue with see respondents filter chipbar.
    // Now, we fetch summary/questions insight data in order to always have
    // filter details.
    if (!this.filterDetailsData && params.type === 'openends') {
      this.getInsight(
        { type: 'questions' },
        this.getInsight.bind(this, params)
      );
      return;
    }

    this.loading = true;

    var fullReload = params.fullReload;
    if (this.loading && !fullReload) {
      this.load();
    }

    if (!this.hasInsightSettings()) {
      return false;
    }
    if (
      !this.filterDetailsDataUnfiltered ||
      this.unfilteredResultsDirty(params.type)
    ) {
      this.getUnfilteredResults(params, callback);
    } else {
      this.getFilteredResults(params, callback);
    }
  }

  unfilteredResultsDirty(type) {
    if (!type.match('questions') && !type.match('summary')) {
      return false;
    }

    if (!_.isEqual(this.unfilteredData.dateRange, this.insightRange)) {
      return true;
    }

    if (!_.isEqual(this.unfilteredData.pageRequest, this.insightPageRequest)) {
      return true;
    }

    if (!_.isEqual(this.unfilteredData.url, this.insightUrl)) {
      return true;
    }

    if (!_.isEqual(this.unfilteredData.projectId, this.currProject.projectId)) {
      return true;
    }

    return false;
  }

  getFilteredResults(params, callback) {
    params = params || {};
    this.insightData = this.dataCollection(params.type);
    var insightData = this.insightData;
    this.extendInsightDataParams(this.insightData, true, params.type);

    this.fetchData('insightData').done(() => {
      this.insightData = insightData;
      if (params.type.match('summary') || params.type.match('questions')) {
        this.filterDetailsData = insightData;
      }

      this.emitData(params.type);
      this.offLoad();
      this.getRespondentCount();

      if (callback) {
        callback();
      }
    });
  }

  getUnfilteredResults(params, callback) {
    params = params || {};
    this.unfilteredData = this.dataCollection(params.type);
    const unfilteredData = this.unfilteredData;

    this.extendInsightDataParams(this.unfilteredData, false);

    this.fetchData('unfilteredData').done(
      _.bind(function () {
        if (params.type.match('summary') || params.type.match('questions')) {
          this.filterDetailsDataUnfiltered = unfilteredData;
        }

        // set unfiltered data with range, so that we only fetch it when date changes
        this.unfilteredData = {
          results: unfilteredData,
          dateRange: this.insightRange,
          url: this.insightUrl,
          projectId: this.currProject.projectId,
        };

        this.getFilteredResults(params, callback);
      }, this)
    );
  }

  // ----------------------- Fetch Utility ------------------------ //

  // Ensure all params necessary for insight fetch defined/populated
  hasInsightSettings() {
    // Current project assigned prior to insight
    if (_.isEmpty(this.currProject) || _.isEmpty(this.insightRange)) {
      return false;
    }

    // if in page insights, wait for post message url
    // only allow without url if user's entire site
    if (!this.currentProjectId) {
      if (!this.isUsersEntireSite && this.insightUrl !== undefined) {
        return false;
      }
    }
    return true;
  }

  // Ensure all params necessary for data fetch defined/populated
  hasRespondentCountSettings() {
    // Do not send count if not using in-page insights
    if (typeof this.currentProjectId !== 'undefined') {
      return false;
    }

    // Current project assigned and topics fetched prior to insight
    // only allow without url if user's entire site
    if (
      _.isEmpty(this.currProject) ||
      _.isEmpty(this.insightRange) ||
      (!this.isUsersEntireSite && this.insightUrl !== undefined)
    ) {
      return false;
    }

    return true;
  }

  getSummaryView(type) {
    if (type === 'summary') {
      return 'analyzesurvey';
    }

    return type === 'questions' ? 'viewsurvey' : undefined;
  }

  // create new data model/collection
  dataCollection(type) {
    var data;
    if (type.match('summary') || type.match('questions')) {
      data = new AggregateDataModel(false, this.options);
    } else if (type.match('openends')) {
      data = new OpenEndsCollection(false, this.options);
    }
    return data;
  }

  // Extend current params onto collection/model object
  // projectId && dateRange required
  // url optional
  extendInsightDataParams(dataCollection, includeFilters, type) {
    if (includeFilters === undefined) {
      includeFilters = true;
    }

    this.filterAnswerIds = includeFilters
      ? _.map(this.insightFilters, function (question) {
          return question.answerIds;
        })
      : [];

    var timezone = JSTimezoneDetect.determine().name();

    var criteria = {
      dateRange: this.insightRange,
      timezone: timezone,
      filterAnswerIds: this.filterAnswerIds,
      pageRequest: this.insightPageRequest,
      searchTerm: this.insightSearchTerm,
      url: this.insightUrl,
      ...this.insightSortCriteria,
    };

    _.extend(dataCollection, {
      projectId: this.currProject.projectId,
      urlparams: {
        view: this.getSummaryView(type),
        criteria: JSON.stringify(criteria),
      },
    });

    return dataCollection;
  }

  // Emits current insight data && date range to ui components
  emitData(fetchedType) {
    var data = {
      currentProject: this.currProject,
      type: fetchedType,
      dateRange: {
        start_date: this.start_date,
        end_date: this.end_date,
      },
      filters: this.getFilters(),
    };
    switch (this.insightType) {
      case 'summary':
        _.extend(data, this.processSummaryData());
        break;
      case 'questions':
        _.extend(data, this.processQuestionsData());
        break;
      case 'openends':
        _.extend(data, this.processOpenendsData());
        break;
      default:
        break;
    }
    this.getInsightUrl();
    if (this.insightPageRequest) {
      data.insightPageRequest = this.insightPageRequest;
    }
    this.publish('insights:data', data);
  }

  _inObject(object, search) {
    for (var prop in object) {
      if (object.hasOwnProperty(prop)) {
        if (object[prop] && typeof object[prop] !== 'object') {
          var check;
          if (typeof object[prop] === 'number') {
            check = object[prop].toString();
          } else if (typeof object[prop] === 'string') {
            check = object[prop].toLowerCase();
          }
          if (check.indexOf(search.toLowerCase()) > -1) {
            return true;
          }
        }
      }
    }
    return false;
  }

  processOpenendsData() {
    var results = this.insightData;

    return {
      openEnds: results.toJSON(),
      search: this.insightSearchTerm,
      fixedRatingQuestionId: this.fixedRatingQuestionId,
      totalRespondents: this.insightData.totalRespondents,
    };
  }

  processSummaryData() {
    var graphColors = [
      '#85cbff',
      '#616161',
      '#0d3e86',
      '#5a6675',
      '#007aff',
      '#e1f4ff',
      '#262a33',
      '#0057bf',
      '#e6e6ec',
      '#b0b6bb',
    ];

    var topicQuestion = this.insightData.get('topicQuestion');
    var topics =
      topicQuestion && topicQuestion.feedbackAnswers
        ? topicQuestion.feedbackAnswers
        : [];

    var anyFilters = false;
    topics = _.each(
      topics,
      function (topic) {
        topic.inFilter = this.isInFilter(topic.answerId);
        if (topic.inFilter) {
          anyFilters = true;
        }
      },
      this
    );

    topics = _.map(topics, function (topic) {
      var excluded = anyFilters && !topic.inFilter;
      topic = {
        label: topic.answerLabel,
        answerId: topic.answerId,
        color: graphColors[0],
        total: topic.totalRespondents,
        visible: !excluded,
      };
      graphColors.splice(graphColors.length, 0, graphColors.splice(0, 1)[0]);
      return topic;
    });

    return {
      averageRating: this.insightData.get('averageRating'),
      totalRespondents: this.insightData.get('totalRespondents'),
      topicSubmissions: this.insightData.get('topicSubmissions'),
      siteRatingAverages: this.insightData.get('siteRatingAverages'),
      topics: topics,
      topicQuestion: topicQuestion,
    };
  }

  processQuestionsData() {
    var topicQuestion = this.insightData.get('topicQuestion');
    var ratingQuestion = this.insightData.get('ratingQuestion');
    var topicQuestions = this.insightData.get('topicQuestions');

    if (ratingQuestion) {
      _.each(
        ratingQuestion.feedbackAnswers,
        function (answer) {
          answer.inFilter = this.isInFilter(answer.answerId);
        },
        this
      );
    }

    var topicSections = [];
    if (topicQuestion) {
      _.each(
        topicQuestion.feedbackAnswers,
        function (answer) {
          answer.inFilter = this.isInFilter(answer.answerId);
        },
        this
      );

      //initialize array of questions for each topic
      topicSections = topicQuestion.feedbackAnswers;
      _.each(topicSections, function (topic) {
        topic.questions = [];
      });
    }

    _.each(
      topicQuestions,
      function (question) {
        var filtered = _.findWhere(topicSections, {
          answerId: question.parentAnswerId,
        });
        if (typeof filtered !== 'undefined') {
          //add question to correct topicSection
          filtered.questions.push(question);
        } else {
          question.total = 0;
        }

        _.each(
          question.feedbackAnswers,
          function (answer) {
            answer.inFilter = this.isInFilter(answer.answerId);
          },
          this
        );
      },
      this
    );

    return {
      ratingQuestion: ratingQuestion,
      topicQuestion: topicQuestion,
      topicQuestions: topicSections,
      totalRespondents: this.insightData.get('totalRespondents'),
    };
  }

  // Invocation publishes loading state to ui components
  load() {
    this.publish('insights:loading', true);
  }

  // Invocation publishes ends loading state to ui components
  offLoad() {
    this.loading = false;
  }

  // ------------------- User Input State ------------------- //
  // When insightRange, insightType, or insightUrl change,
  // fetch insight with updated params

  setSearch(data) {
    this.insightSearchTerm = data.searchTerm;
    if (data.callback) {
      data.callback();
    }
  }

  setSearchTerm(payload) {
    this.insightSearchTerm = payload.search;
    this.insightData = new OpenEndsCollection(payload.openends);

    if (payload.unMounting) {
      return;
    }
    this.emitData('openends');
  }

  resetCurrentPage() {
    if (this.insightPageRequest && this.insightPageRequest.currentPage) {
      this.insightPageRequest.currentPage.start = 0;
    }
  }

  findInsightFilterByQuestionId(questionId) {
    return this.insightFilters.find(
      (filter) => filter.questionId === questionId
    );
  }

  setFilters(payload) {
    this.resetCurrentPage();
    const { filters, surveyQuestions } = payload;

    for (let filter of filters) {
      const insightFilter = this.findInsightFilterByQuestionId(
        filter.questionId
      );
      const newAnswerId = filter.answerId;

      if (insightFilter) {
        const oldAnswerIds = insightFilter.answerIds;

        if (oldAnswerIds.indexOf(newAnswerId) === -1) {
          oldAnswerIds.push(newAnswerId);
        }
      } else {
        this.insightFilters.push({
          questionId: filter.questionId,
          answerIds: [newAnswerId],
        });
      }
    }

    this.lastFilterChange = {
      payload: payload,
      undoHandler: 'clearFilters',
    };

    for (let insightFilter of this.insightFilters) {
      const oldFilterDetail = this.insightFilterDetails.find(
        (detail) => detail.question.questionId === insightFilter.questionId
      );
      this.insightFilterDetails = this.insightFilterDetails.filter(
        (detail) => detail.question.questionId !== insightFilter.questionId
      );

      const detail = this.getFilterDetails(
        insightFilter,
        surveyQuestions,
        oldFilterDetail
      );
      if (detail) {
        this.insightFilterDetails.push(detail);
      }
    }

    store.set(
      this.options.clientId + '_' + this.currentProjectId + '_insightFilters',
      this.insightFilters
    );
    store.set(
      this.options.clientId +
        '_' +
        this.currentProjectId +
        '_insightFilterDetails',
      this.insightFilterDetails
    );

    this.getFilters(surveyQuestions);
    this.getInsight({
      type: this.insightType,
    });
  }

  clearFilters(payload) {
    this.resetCurrentPage();
    const { filters, callback } = payload;

    for (let filter of filters) {
      const insightFilter = this.findInsightFilterByQuestionId(
        filter.questionId
      );
      const questionDetail = this.insightFilterDetails.find(
        (detail) => detail.question.questionId === filter.questionId
      );

      if (insightFilter && questionDetail) {
        const answerDetails = questionDetail.answers.find(
          (answer) => answer.answerId === filter.answerId
        );
        if (!answerDetails) {
          continue;
        }

        // remove filter answers
        insightFilter.answerIds = insightFilter.answerIds.filter(
          (answerId) => answerId !== filter.answerId
        );
        questionDetail.answers = questionDetail.answers.filter(
          (answer) => answer.answerId !== answerDetails.answerId
        );

        // remove filters when there are no answers
        if (!insightFilter.answerIds.length) {
          this.insightFilters = this.insightFilters.filter(
            (insightFilter) => insightFilter.questionId !== filter.questionId
          );
        }
        if (!questionDetail.answers.length) {
          this.insightFilterDetails = this.insightFilterDetails.filter(
            (detail) => detail.question.questionId !== filter.questionId
          );
        }
      }
    }

    if (callback) {
      callback();
      return;
    }

    store.set(
      this.options.clientId + '_' + this.currentProjectId + '_insightFilters',
      this.insightFilters
    );
    store.set(
      this.options.clientId +
        '_' +
        this.currentProjectId +
        '_insightFilterDetails',
      this.insightFilterDetails
    );
    this.lastFilterChange = {
      payload: payload,
      undoHandler: 'setFilters',
    };

    this.getFilters();
    this.getInsight({
      type: this.insightType,
    });
  }

  undoLastFilter() {
    if (this.lastFilterChange) {
      var payload = this.lastFilterChange.payload;
      switch (this.lastFilterChange.undoHandler) {
        case 'clearFilters':
          this.clearFilters(payload);
          break;
        case 'setFilters':
          this.setFilters(payload);
          break;
        case 'handleDateRangeChanged':
          this.handleDateRangeChanged(payload);
          break;
        default:
          break;
      }
    }
  }

  setInsightType(payload) {
    var type = payload.type;
    switch (type) {
      case type.match('summary'):
        type = 'summary';
        break;
      case type.match('questions'):
        type = 'questions';
        break;
      case type.match('openends'):
        type = 'openends';
        break;
      default:
        break;
    }

    //set insightPageRequest
    this.insightPageRequest = payload.pageRequest || null;

    if (this.insightPageRequest) {
      const nextPage = this.insightPageRequest.nextPage;
      nextPage.first = this.parseBaseDates.start_date.format('YYYY-MM-DD');
      nextPage.last = this.parseBaseDates.end_date.format('YYYY-MM-DD');

      if (this.insightPageRequest.currentPage) {
        const currentPage = this.insightPageRequest.currentPage;
        currentPage.first = this.parseBaseDates.start_date.format('YYYY-MM-DD');
        currentPage.last = this.parseBaseDates.end_date.format('YYYY-MM-DD');
      }
    }

    this.publish('insights:groupable', !!type.match('openends'));

    if (
      this.insightType !== type ||
      this.insightPageRequest !== null ||
      payload.force
    ) {
      this.insightType = type;

      this.getInsight({
        type: type,
        fullReload: payload.fullReload,
      });
    }
  }

  setInsightUrl(payload) {
    payload = payload || {};

    var url = payload.url,
      name = payload.urlName;

    this.isUsersEntireSite = !!'entireSite'.match(name);

    url = !!'withChildren'.match(name) ? this.removeUrlFilename(url) : url;

    if (this.isUsersEntireSite) {
      url = '';
    }

    if (this.insightUrlName !== name) {
      this.insightUrlName = name;
      this.insightUrl = url;

      var insightUrlData = {
        url: this.insightUrl,
        urlName: this.insightUrlName,
      };
      store.set(this.options.clientId + '_insightUrl', insightUrlData.url);
      store.set(this.options.clientId + '_insightUrlName', {
        urlName: insightUrlData.urlName,
        timeStamp: new Date().getTime(),
      });
      this.getInsight({
        type: this.insightType,
      });
      this.getRespondentCount();
    }
  }

  getInsightUrl() {
    this.publish('insights:url', {
      url: this.insightUrl,
      urlName: this.insightUrlName,
    });
  }

  // ------------------- Utility ------------------- //

  // jQuery deferred wrapper
  // @param data === Backbone Collection or Model
  // ex)
  //    this.myCollection = new Backbone.Collection();
  //    this.fetchData('myCollection').done(function(){ emitData(); });
  fetchData(data) {
    var def = $.Deferred();
    data = this[data];

    if (!!data && !!data.fetch) {
      $.when(data.fetch()).done(function () {
        def.resolve();
      });
    }

    return def;
  }

  // create an element to simplify url parsing
  // provides functionality similar to Window.location
  // (location.hash, location.pathname, location.origin, location.search)
  generateLocationObject(location) {
    var url = document.createElement('a');
    url.href = location;
    url.href = url.href.replace(url.hash, '');
    return url;
  }

  // Strips filename from url && adds wildcard character
  // used for in page insights && current + children user option selected
  removeUrlFilename(url) {
    var baseUrl = '';
    var location = this.generateLocationObject(url);

    if (location.pathname.match('\\.')) {
      // assign expected filename to variable
      var splitPathname = location.pathname.split('.');
      var path = splitPathname.shift();
      var fileExtension = splitPathname.join('.');
      path = path.split('/');
      var filename = path.pop() + '.' + fileExtension;
      var directory = '';

      // check if expected filename exists within path
      if (location.pathname.match(filename)) {
        directory = location.pathname.replace(filename, '');
      }

      baseUrl = location.origin + directory;
    } else {
      // var hasSlash = location.href[location.href.length - 1] === '/';
      // var wildCard = hasSlash ? '*' : '/*';
      baseUrl = location.href;
    }

    return baseUrl;
  }

  isInFilter(needle) {
    return _.find(this.insightFilters, function (hay) {
      return hay.answerIds.indexOf(needle) > -1;
    });
  }

  getResponse(payload) {
    var model = new OpenEndsModel(),
      ctx = this,
      respondentId = payload.respondentId,
      projectId = this.currentProjectId,
      clientId = this.options.clientId;

    model.url =
      'clients/' +
      clientId +
      '/projects/' +
      projectId +
      '/respondents/' +
      respondentId;

    model.fetch({
      headers: {
        'Cache-Control': 'no-cache',
      },
      success: function (res) {
        if (payload.callback && typeof payload.callback === 'function') {
          payload.callback.call(ctx, res);
        } else {
          ctx.publish('insights:response:data', res);
        }
      },
    });
  }

  /**
   * Removes a response from the backend
   */
  deleteResponse(payload) {
    var options = { wait: true };
    var rModel = this.insightData.get(payload.respondentId);
    var url =
      'clients/' +
      this.options.clientId +
      '/projects/' +
      this.currentProjectId +
      '/respondents/' +
      payload.respondentId;

    if (payload.gid) {
      var expungeurl = '/services/' + payload.gid + '/expungereplay',
        security = cxRAPIRequest(expungeurl, true);

      url += '?token=' + security.token + '&sig=' + security.sig;
    }

    rModel.url = url;

    // On success broadcast the new data
    options.success = function (model) {
      if (payload.callback) {
        payload.callback();
      }
    }.bind(this, rModel);

    // Just update data on error for now
    options.error = function (model) {
      this.getInsight({ type: this.insightType });
    }.bind(this, rModel);

    rModel.destroy(options);
  }

  getReplayMetadata(payload) {
    var respondentId = payload.respondentId;

    if (payload.reset) {
      this.replayMetadata = {};
    }

    //handles refresh of replayMetadata in cases where we have received the replayMetadata but it is empty because the migration to services from video creation hasn't happened yet
    if (
      this.replayMetadata[respondentId] &&
      this.replayMetadata[respondentId].length === 0
    ) {
      delete this.replayMetadata[respondentId];
    }

    if (!this.replayMetadata[respondentId]) {
      this.getResponse({
        respondentId: payload.respondentId,
        callback: this.handleReplayMetadata,
      });
    }
  }

  handleReplayMetadata(res) {
    var respondentId = res.get('respondentId'),
      replayMetadata = res.get('replayMetadata');

    if (replayMetadata) {
      this.replayMetadata[respondentId] = replayMetadata;
    }

    this.publish('insights:replayMetadata:data', this.replayMetadata);
    this.publish('insights:respondentDetail:data', res.toJSON());
  }

  exportRespondentsCSV(payload) {
    const { currentProject, metrics, searchTerm } = payload;

    this.publish('export:feedback:respondents:csv', {
      project: currentProject,
      metrics: metrics,
      searchTerm: searchTerm,
      filterAnswerIds: this.filterAnswerIds,
      dateRange: this.insightRange,
      timezone: JSTimezoneDetect.determine().name(),
    });
  }
}

export default InsightsController;

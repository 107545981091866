import Backbone from 'backbone';
import _ from 'underscore';

const MasterDataValuesModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.typeId = options.typeId;
    this.fields = options.fields;
    this.params = options.params || {};
  },

  url: function () {
    const query = _.extend(
      {
        fields: this.fields.join(','),
      },
      this.params
    );
    return `clients/${this.client}/masterdatatypes/${
      this.typeId
    }/values?${new URLSearchParams(query)}`;
  },
});

export default MasterDataValuesModel;

import _ from 'underscore';
import BaseController from 'controllers/base';
import MasterDataTypeCollection from 'collections/clients/masterdatatype';
import MasterDataTypeModel from 'models/clients/masterdatatype';
import MasterDataCollection from 'collections/clients/masterdata';
import MasterDataModel from 'models/clients/masterdata';
import MasterDataVersionsCollection from 'collections/clients/masterdata/versions';
import MasterDataVersionsModel from 'models/clients/masterdata/versions';
import MasterDataTypeVersionsModel from 'models/clients/masterdatatype/versions';
import MasterDataValuesModel from 'models/clients/masterdatatype/values';

const dataTypeOptions = [
  { label: 'Text Entry', id: 'string' },
  { label: 'Single Select', id: 'select' },
  { label: 'Number', id: 'number' },
  { label: 'Email', id: 'email' },
  { label: 'Date', id: 'date' },
];
class MasterDataController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'master-data:get': '_get',
        'master-data:post': '_post',
        'master-data:versions:get': '_getVersions',
        'master-data:versions:post': '_postVersion',
        'master-data:type:get': '_getType',
        'master-data:type:post': '_postType',
        'master-data:type:getAttributes': '_getTypeAttributes',
        'master-data:type:versions:post': '_postTypeVersion',
        'master-data:type:values:get': '_getValues',
      },
      name: 'master-data',
      dependsOn: ['user', 'authorization', 'uploads', 'files'],
    });
  }

  initialize(opts) {
    let options = opts || {};
    options.clientId = this.getClientId();
    this.types = new MasterDataTypeCollection(false, options);
  }

  _get(payload) {
    const { id, typeId, callback } = payload;

    let options = {
      clientId: this.getClientId(),
      typeId: typeId,
    };

    let model = false;
    if (id) {
      options.id = id;
      model = new MasterDataModel(false, options);
    } else {
      options.offset = payload.offset;
      options.limit = payload.limit;
      options.sort = payload.sort;
      options.search = payload.search;
      model = new MasterDataCollection(false, options);
    }

    model
      .fetch({
        error: () => {
          this._dispatch(callback, 'master-data:data', model.toJSON());
        },
      })
      .then(() => {
        this._dispatch(callback, 'master-data:data', model.toJSON());
      });
  }

  _getVersions(payload) {
    const { version, masterDataId, masterDataTypeId, callback, populateUsers } =
      payload;

    let options = {
      clientId: this.getClientId(),
      masterDataId: masterDataId,
      masterDataTypeId: masterDataTypeId,
    };

    let model = false;
    if (version) {
      options.version = version;
      model = new MasterDataVersionsModel(false, options);
    } else {
      options.offset = payload.offset;
      options.limit = payload.limit;
      model = new MasterDataVersionsCollection(false, options);
    }

    model.fetch().then(() => {
      if (populateUsers) {
        this.publish('authorization:users:get', {
          ids: model.getUserIds(),
          callback: (users) => {
            model.setUsers(users);
            this._dispatch(
              callback,
              'master-data:versions:data',
              model.toJSON()
            );
          },
        });
      } else {
        this._dispatch(callback, 'master-data:versions:data', model.toJSON());
      }
    });
  }

  _getValues(payload) {
    const { fields, params, typeId, callback } = payload;
    let model = new MasterDataValuesModel(false, {
      clientId: this.getClientId(),
      typeId: typeId,
      fields: fields,
      params: params,
    });
    model
      .fetch({
        error: () => {
          this._dispatch(
            callback,
            'master-data:type:values:data',
            model.toJSON()
          );
        },
      })
      .then(() => {
        this._dispatch(
          callback,
          'master-data:type:values:data',
          model.toJSON()
        );
      });
  }

  _saveMasterDataRecord(isNew, payload, options) {
    const { metadata, typeId, callback, onError } = payload;
    const record = {
      typeRef: {
        clientId: this.getClientId(),
        id: typeId,
      },
      metadata: metadata,
    };
    options.clientId = this.getClientId();
    const model = isNew
      ? new MasterDataModel(record, options)
      : new MasterDataVersionsModel(record, options);
    model
      .save(null, {
        error: onError,
      })
      .then(() => {
        if (callback) {
          callback(model.toJSON());
        }
      });
  }

  _post(payload) {
    this._saveMasterDataRecord(true, payload, {});
  }

  _postVersion(payload) {
    this._saveMasterDataRecord(false, payload, {
      masterDataId: payload.masterDataId,
    });
  }

  _saveMasterDataTypeRecord(isNew, payload) {
    const { typeId, value, schema, callback, icon } = payload;
    const options = {
      clientId: this.getClientId(),
    };
    let record = {
      attributeSchema: schema,
      value: value,
    };

    if (typeof icon !== 'undefined') {
      record.icon = icon;
    }
    if (!isNew) {
      options.typeId = typeId;
    }
    const model = isNew
      ? new MasterDataTypeModel(record, options)
      : new MasterDataTypeVersionsModel(record, options);
    model.save().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _postType(payload) {
    this._saveMasterDataTypeRecord(true, payload);
  }

  _postTypeVersion(payload) {
    this._saveMasterDataTypeRecord(false, payload);
  }

  _getDataType(attribute) {
    const { type, format } = attribute;
    const isString = type === 'string';
    if (isString && attribute.enum) return 'select';
    if (isString && ['date-time', 'date'].indexOf(format) > -1) return 'date';
    if (isString && format === 'email') return 'email';
    return type;
  }

  _getTypeAttributes(payload) {
    const cb = payload.callback;
    if (!this.masterDataType) {
      return;
    }
    const type = this.clone(this.masterDataType.toJSON());
    if (_.isEmpty(type)) {
      return;
    }
    const attributes = _.map(type.attributeSchema.properties, (prop, key) => {
      const { label } = prop;
      const dataTypeOption = _.findWhere(dataTypeOptions, {
        id: this._getDataType(prop),
      });
      prop.dataType = dataTypeOption.label;
      prop.key = key;
      prop.title = label && label['*'] ? label['*'] : key;
      return prop;
    });
    cb(attributes);
  }

  _processTypes(model, callback) {
    let types = [];
    model.forEach((m) => {
      types.push(m.toJSON());
    });

    this._dispatch(callback, 'master-data:type:data', types);
  }

  _getType(payload) {
    const { id, callback } = payload;

    let model = this.types;
    if (id) {
      model = new MasterDataTypeModel(false, {
        clientId: this.getClientId(),
        id: id,
      });
      this.masterDataType = model;
    }

    const _handler = () => {
      if (id) {
        this._dispatch(callback, 'master-data:type:data', model.toJSON());
      } else {
        this._processTypes(model, callback);
      }
    };
    model.fetch({ error: _handler }).then(_handler);
  }

  _dispatch(callback, action, payload) {
    if (callback) {
      callback(payload);
    } else {
      this.publish(action, payload);
    }
  }
}

export default MasterDataController;

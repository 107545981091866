import Backbone from 'backbone';
import _ from 'underscore';

const MasterDataVersionsModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.masterDataId = options.masterDataId;
    this.masterDataTypeId = options.masterDataTypeId;
    this.version = options.version;
  },

  idAttribute: 'version',

  parse: function (data) {
    return data;
  },

  getUserIds: function () {
    return [this.get('updatedBy')];
  },

  setUsers: function (users) {
    const user = _.findWhere(users, { userId: this.get('updatedBy') });
    this.set('updatedByUser', user);
  },

  url: function () {
    let id = this.masterDataId;
    let type = 'masterdata';
    if (this.masterDataTypeId) {
      id = this.masterDataTypeId;
      type = 'masterdatatypes';
    }
    if (this.version) {
      return `clients/${this.client}/${type}/${id}/versions/${this.version}`;
    }
    return `clients/${this.client}/${type}/${id}/versions`;
  },
});

export default MasterDataVersionsModel;

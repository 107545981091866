import Backbone from 'backbone';

const HierarchyAncestryModel = Backbone.Model.extend({
  headers: {
    Accept: 'application/json',
  },

  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.hierarchyId = options.hierarchyId;
    this.nodeId = options.nodeId;
  },

  url: function () {
    return `clients/${this.client}/hierarchies/${this.hierarchyId}/ancestry/${this.nodeId}`;
  },
});

export default HierarchyAncestryModel;

import Backbone from 'backbone';
import _ from 'underscore';

var wrapArray = function (array) {
  var ArrMethods = {
    where: function (attrs) {
      var nodes = [];
      _.each(this, function (model) {
        nodes = nodes.concat(model.where(attrs));
      });
      return wrapArray(_.uniq(nodes));
    },
  };

  return _.extend(array, ArrMethods);
};
var TreeModel = (Backbone.TreeModel = Backbone.Model.extend({
  constructor: function tree(node, options) {
    Backbone.Model.prototype.constructor.apply(this, arguments);
    const { parent } = options;
    let nodes = [];
    if (options.getChildren) {
      nodes = options.getChildren(this) || [];
    }
    if (node && node.children) {
      nodes = node.children;
    }

    this._nodes = new this.collectionConstructor(
      nodes,
      _.extend(options, {
        model: this.constructor,
      })
    );
    this.additionalNodeParent = parent;
    this._nodes.parent = this;

    if (options.callback) {
      options.callback(this);
    }
  },

  collectionConstructor: null,

  /**
   * returns JSON object representing tree, account for branch changes
   */
  toJSON: function () {
    var jsonObj = _.clone(_.omit(this.attributes, 'nodes'));
    var children = this._nodes.toJSON();
    if (children.length) jsonObj.nodes = children;
    jsonObj.level = this.level();
    jsonObj.children = children;
    return jsonObj;
  },

  /**
   * returns descendant matching :id
   */
  find: function (id) {
    return this.findWhere({ id: id });
  },

  /**
   * return first matched descendant
   */
  findWhere: function (attrs) {
    return this.where(attrs, true);
  },

  /**
   * return all matched descendants
   */
  where: function (attrs, first, excludeCurrentNode) {
    var nodes = [],
      matchedNode;

    // manual (non-collection method) check on the current node
    if (!excludeCurrentNode && _.where([this.toJSON()], attrs)[0])
      nodes.push(this);

    if (first) {
      // return if first/current node is a match
      if (nodes[0]) return nodes[0];

      // return first matched node in children collection
      matchedNode = this._nodes.where(attrs, true);
      if (_.isArray(matchedNode)) matchedNode = matchedNode[0];
      if (matchedNode instanceof Backbone.Model) return matchedNode;

      // recursive call on children nodes
      for (var i = 0, len = this._nodes.length; i < len; i++) {
        matchedNode = this._nodes.at(i).where(attrs, true, true);
        if (matchedNode) return matchedNode;
      }
    } else {
      // add all matched children
      nodes = nodes.concat(this._nodes.where(attrs));

      // recursive call on children nodes
      this._nodes.each(function (node) {
        nodes = nodes.concat(node.where(attrs, false, true));
      });

      // return all matched nodes
      return wrapArray(nodes);
    }
  },

  /**
   * returns true if current node is root node
   */
  isRoot: function () {
    return this.parent() === null;
  },

  /**
   * returns true if current node is a leaf node
   */
  isLeaf: function () {
    const leafNode = this.get('leafNode');
    if (typeof leafNode !== 'undefined') {
      return leafNode;
    }
    return this.nodes() === null;
  },

  /**
   * returns the root for any node
   */
  root: function () {
    return (this.parent() && this.parent().root()) || this;
  },

  /**
   * checks if current node contains argument node
   */
  contains: function (node) {
    if (!node || !(node.isRoot && node.parent) || node.isRoot()) return false;
    var parent = node.parent();
    return parent === this || this.contains(parent);
  },

  /**
   * returns the parent node
   */
  parent: function () {
    return (
      (this.collection && this.collection.parent) ||
      this.additionalNodeParent ||
      null
    );
  },

  /**
   * returns the children Backbone Collection if children nodes exist
   */
  nodes: function () {
    return (this._nodes.length && this._nodes) || null;
  },

  allDescendants: function () {
    return this.where({});
  },

  allAncestors: function () {
    var parent = this.parent();
    if (!parent) {
      return null;
    }

    var nodes = [parent];
    while (!parent.isRoot()) {
      parent = parent.parent();
      nodes.push(parent);
    }

    return wrapArray(nodes);
  },

  level: function () {
    var level = 0;
    var node = this;
    while (!node.isRoot()) {
      level += 1;
      node = node.parent();
    }
    return level;
  },

  /**
   * returns index of node relative to collection
   */
  index: function () {
    if (this.isRoot()) return null;
    return this.collection.indexOf(this);
  },

  /**
   * returns the node to the right
   */
  next: function () {
    if (this.isRoot()) return null;
    var currentIndex = this.index();
    if (currentIndex < this.collection.length - 1) {
      return this.collection.at(currentIndex + 1);
    } else {
      return null;
    }
  },

  /**
   * returns the node to the left
   */
  prev: function () {
    if (this.isRoot()) return null;
    var currentIndex = this.index();
    if (currentIndex > 0) {
      return this.collection.at(currentIndex - 1);
    } else {
      return null;
    }
  },

  /**
   * removes current node if no attributes arguments is passed,
   * otherswise remove matched nodes or first matched node
   */
  remove: function (attrs, first) {
    if (!attrs) {
      if (this.isRoot()) return false; // can't remove root node
      this.collection.remove(this);
      return true;
    } else {
      if (first) {
        this.where(attrs, true).remove();
      } else {
        _.each(this.where(attrs), function (node) {
          if (node.collection) node.remove();
        });
      }
      return this;
    }
  },

  /**
   * removes all children nodes
   */
  empty: function () {
    this._nodes.reset();
    return this;
  },

  /**
   * add child/children nodes to Backbone Collection
   */
  add: function (node) {
    if (node instanceof Backbone.Model && node.collection)
      node.collection.remove(node);
    this._nodes.add.apply(this._nodes, arguments);
    return this;
  },

  /**
   * inserts a node before the current node
   */
  insertBefore: function (node) {
    if (!this.isRoot()) {
      if (node instanceof Backbone.Model && node.collection)
        node.collection.remove(node);
      this.parent().add(node, { at: this.index() });
    }
    return this;
  },

  /**
   * inserts a node after the current node
   */
  insertAfter: function (node) {
    if (!this.isRoot()) {
      if (node instanceof Backbone.Model && node.collection)
        node.collection.remove(node);
      this.parent().add(node, { at: this.index() + 1 });
    }
    return this;
  },

  /**
   * shorthand for getting/inserting nodes before
   */
  before: function (nodes) {
    if (nodes) return this.insertBefore(nodes);
    return this.prev();
  },

  /**
   * shorthand for getting/inserting nodes before
   */
  after: function (nodes) {
    if (nodes) return this.insertAfter(nodes);
    return this.next();
  },

  getSelectedNodeLevels: function (node, onlySelectChildren) {
    const parentNode = node || this.toJSON();
    if (this.find(parentNode.id).isRoot() && parentNode.selected) {
      return [parentNode];
    }

    const childNodes = parentNode.nodes;

    let isAllChildrenSelected = true;
    let childLevels = [];
    let levels = [];

    // handle leaf node
    if (!childNodes) {
      return [];
    }

    // loop through all child nodes and add the selected nodes to our list of
    // levels. if child is unselected we recursively find selected levels
    for (let i = 0; i < childNodes.length; i++) {
      const childNode = childNodes[i];

      if (!childNode.selected) {
        isAllChildrenSelected = false;
        childLevels = childLevels.concat(
          this.getSelectedNodeLevels(childNode, onlySelectChildren)
        );
      } else {
        levels.push(childNode);
      }
    }

    // clear all child nodes if all are selected we want to add only the parent node to
    // the list of levels
    if (isAllChildrenSelected && !onlySelectChildren) {
      levels = [parentNode];
    }

    levels = levels.concat(childLevels);

    return levels;
  },
}));
var TreeCollection = (Backbone.TreeCollection = Backbone.Collection.extend({
  model: TreeModel,
  where: function (attrs, opts) {
    if (opts && opts.deep) {
      var nodes = [];
      this.each(function (model) {
        nodes = nodes.concat(model.where(attrs));
      });
      return wrapArray(nodes);
    } else {
      return Backbone.Collection.prototype.where.apply(this, arguments);
    }
  },
}));
TreeModel.prototype.collectionConstructor = TreeCollection;
TreeModel._ = _;
TreeModel.Backbone = Backbone;

export default TreeModel;

import Backbone from 'backbone';
import _ from 'underscore';
import URL from 'core/utils/url';

const LeaderboardModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.hierarchyId = options.hierarchyId;
    this.id = options.id;
    this.nodeId = options.nodeId;
    this.level = options.level;
    this.view = options.view;
    this.isSparse = options.isSparse;
    this.offset = options.offset;
    this.limit = options.limit;
  },

  parse: function (data) {
    if (data) {
      return _.extend({}, data, {
        nodes: _.sortBy(data.nodes, function (node) {
          return node.rank;
        }),
      });
    }

    return;
  },

  url: function () {
    let url = `clients/${this.client}/hierarchies/${this.hierarchyId}/leaderboard`;

    if (this.level) {
      url = URL.insertParam(url, 'level', this.level);
    }

    if (this.view) {
      url = URL.insertParam(url, 'view', this.view);
    }

    if (this.nodeId) {
      url = URL.insertParam(url, 'nodeId', this.nodeId);
    }

    if (this.isSparse) {
      url = URL.insertParam(url, 'isSparse', this.isSparse);
    }

    if (this.offset) {
      url = URL.insertParam(url, 'offset', this.offset);
    }

    if (this.limit) {
      url = URL.insertParam(url, 'limit', this.limit);
    }

    return url;
  },
});

export default LeaderboardModel;

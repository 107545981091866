export default {
  layout: 'projects',
  controller: 'publishing',
  icon: 'navSurveys',
  label: 'Surveys',
  permissions: 'portal_access',
  ':id': {
    layout: 'projects',
    controller: 'projects',
    tabs: [
      {
        routes: ['edit', 'collection', 'publish'],
      },
    ],

    edit: {
      layout: 'project-edit',
      controller: 'publishing',
      permissions: 'feedback_create',
      label: 'Edit Survey',
    },
    collection: {
      layout: 'project-feedback-collection',
      controller: 'publishing',
      permissions: 'feedback_create',
      label: 'Collection Methods',
    },
    weblink: {
      layout: 'project-feedback-weblink',
      controller: 'publishing',
      permissions: 'feedback_create',
      label: 'Weblink Methods',
    },
    publish: {
      layout: 'project-feedback-publish',
      controller: 'publishing',
      permissions: 'feedback_create',
      label: 'Publishing Settings',
    },
    type: {
      layout: 'project-feedback-type',
      controller: 'feedback-settings',
      permissions: 'feedback_create',
    },
    analytics: {
      controller: 'insights',
      summary: {
        layout: 'insights-summary',
        permissions: 'feedback_access',
      },
      survey: {
        layout: 'insights-questions',
        permissions: 'feedback_access',
      },
      respondents: {
        layout: 'insights-openends',
        ':id': {
          detail: {
            layout: 'insights-openends',
          },
        },
      },
    },
  },
};

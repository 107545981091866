import Backbone from 'backbone';

const WhiteListModel = Backbone.Model.extend({
  initialize: function (opts) {},

  setParams: function (params) {
    const { url, namespace } = params;
    this.urlparams = {
      u: url,
      n: namespace,
    };
  },

  noOauth: true,

  noUrlRoot: true,

  url: '!!_whitelistUrl_!!',
});

export default WhiteListModel;

import Backbone from 'backbone';
import $ from 'jquery';
import constants from 'core/constants';

var File = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    fileKey: [],
    storageId: '',
  },

  /**
   * Constructor
   */
  initialize: function (model, opts) {
    this.file = opts.file;
    this.fileMetaData = opts.meta;
    this.fileKey = opts.fileKey;
    this.semiAuth = opts.semiAuth || false;
  },

  _getData: function () {
    var data = new FormData();
    data.append('file', this.file, encodeURIComponent(this.file.name));
    data.append('fileMetaData', this.fileMetaData);
    return data;
  },

  save: function () {
    return Backbone.sync.oauth.apiRequest(constants.SERVER_ROOT + this.url(), {
      data: this._getData(),
      multipart: true,
      type: 'POST',
      success: (data) => {
        this.attributes = data;
      },
    });
  },

  post: function () {
    if (this.semiAuth) {
      var model = this;
      return $.ajax({
        data: this._getData(),
        multipart: true,
        type: 'POST',
        processData: false,
        contentType: false,
        url: constants.SERVER_ROOT + 'semiauth/' + this.url(),
        headers: { 'X-Auth-Token': this.semiAuth },
        success: (resp) => {
          model.set(resp);
        },
      });
    } else {
      return this.save();
    }
  },

  fetch: function () {
    var oauth = Backbone.sync.oauth;
    return oauth.apiRequest(constants.SERVER_ROOT + this.url(), {
      type: 'GET',
      responseType: 'arraybuffer',
      success: (data, status, request) => {
        var contentType = request.getResponseHeader('Content-Type'),
          blobType = contentType.substr(0, contentType.indexOf(';')),
          arrayBufferView = new Uint8Array(data);

        this.blob = new Blob([arrayBufferView], { type: blobType });
      },
    });
  },

  url: function () {
    if (this.fileKey) {
      return `platform-file-service/files/${window.btoa(this.fileKey)}`;
    } else if (this.external) {
      return 'platform-file-service/files?file-key-type=external';
    }
    return `platform-file-service/files`;
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default File;

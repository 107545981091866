import React from 'react';
import BasePanel from 'views/panels/base';
import EmptyState from 'views/widgets/empty-state-wrapper';
import { Modal } from 'cx-component-library';

class UpdateAccess extends BasePanel {
  render() {
    return (
      <Modal
        isOpen
        theme='fullscreen'
        fullScreenPadding={true}
        showClose={false}
      >
        {
          <div
            className='gutter-p--top'
            style={{ maxWidth: '60rem', margin: 'auto' }}
          >
            <EmptyState
              heading={this.getString('general.errors.processing.header')}
              subheading={this.getString(
                'general.errors.processing.description'
              )}
            />
          </div>
        }
      </Modal>
    );
  }
}

export default UpdateAccess;

import Backbone from 'backbone';
import LatentsCollection from 'collections/clients/measures/latents';
import SLatentsCollection from 'collections/clients/measures/slatents';
import QuestionsCollection from 'collections/clients/measures/questions';
import BMCatsCollection from 'collections/clients/measures/slatents/bmcat';

var Measure = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties and provide an example of the type of data for each.
   */
  defaults: {
    measurementKey: 0,
    name: '',
    modelInstanceId: '',
    channelIdentifier: '',
    channelType: 0,
    createDate: '2016-01-01',
    currentlyActive: 0,
    clientId: 0,
    vndType: 'application/vnd.foresee.measure',
  },

  idAttribute: 'measurementKey',

  /**
   * Constructor
   */
  initialize: function (model, opts) {
    this.client = opts.client;
    this.measurementKey = opts.measurementKey;
  },

  getImpacts: function (options, data) {},

  getLatents: function (options, data) {
    return new LatentsCollection(options);
  },

  getQuestions: function (options, data) {
    return new QuestionsCollection(options);
  },

  getAnswers: function (options, data) {},

  getSLatents: function (options, data) {
    return new SLatentsCollection(options);
  },

  getBMCats: function (options, data) {
    return new BMCatsCollection(options);
  },

  getBMCatGroups: function (options, data) {},

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measurementKey}`;
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  // cacheMS: 1000 * 60 * 60
});

export default Measure;

import Backbone from 'backbone';

const ClientSettingsModel = Backbone.Model.extend({
  idAttribute: 'namespace',

  initialize: function (attributes, opts) {
    var options = opts || {};

    this.client = options.clientId;
  },

  url: function (method) {
    if (this.get('namespace')) {
      switch (method) {
        case 'read':
        case 'delete':
          return `clients/${this.client}/settings/${this.get('namespace')}`;
        default:
          break;
      }
    }

    return `clients/${this.client}/settings`;
  },
});

export default ClientSettingsModel;

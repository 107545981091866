import Backbone from 'backbone';

const AggregrationEventPropertiesModel = Backbone.Collection.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.event = options.event;
    this.source = options.source;
  },

  parse: function (data) {
    return data.properties;
  },

  serviceId: 'big-data-services',

  url: function () {
    const preRoute = 'internal/in/ui-endpoints/metadata';
    return `${preRoute}/customer/${this.client}/app/funcxm/event/${this.event}/source/${this.source}/properties/`;
  },
});

export default AggregrationEventPropertiesModel;

import Backbone from 'backbone';

const CountriesCollection = Backbone.Model.extend({
  model: Backbone.Model,

  url: function () {
    return `platform-scim-service/countries`;
  },
});

export default CountriesCollection;

import { gql } from '@apollo/client';

export const GET_TEXT_DATA_SOURCES = gql`
  query getTextDataSources($input: TextSourcesInput!) {
    textDataSources(input: $input) {
      active
      id
      name
      questions {
        id
        parentId
        questionId
        questionKey
        tag
        text
      }
      type
    }
  }
`;

export const GET_TEXT_QUESTIONS = gql`
  query getTextMeasureQuestions($input: TextMeasureInput!) {
    textMeasureQuestions(input: $input) {
      questionId
      questionKey
    }
  }
`;

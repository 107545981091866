import BaseController from 'controllers/base';

class AuthorizedMeasureController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {},
      name: 'authorized-measure',
      dependsOn: ['authorization', 'measures'],
    });
  }

  initialize() {}
}

export default AuthorizedMeasureController;

import Backbone from 'backbone';

const EventFilterDetailsModel = Backbone.Model.extend({
  idAttribute: 'filterId',

  defaults: {
    userId: -1,
    clientId: -1,
    conditionGroup: {
      conditionGroupType: 'EVENTS',
      conditions: [],
      operator: 'AND',
    },
    name: 'Untitled Filter',
  },

  initialize: function (attributes, opts) {
    this.addOptions(opts);
  },

  addOptions: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.appKey = options.appKey || 'funcxm';

    this.options = options;
  },

  url: function () {
    const preRoute = 'dcos-internal-gateway-service/internal/in/ui-endpoints';
    const route = `${preRoute}/filters/clients/${this.client}/filters/appId/${this.appKey}/events`;

    return `${route}/${this.get('filterId')}`;
  },

  reset: function () {
    this.set({
      filterId: null,
      conditionGroup: {
        conditionGroupType: 'EVENTS',
        conditions: [],
        operator: 'AND',
      },
    });
  },

  serviceId: 'foresee-services',
});

export default EventFilterDetailsModel;

import Backbone from 'backbone';
import _ from 'underscore';
import HierarchyFilterModel from 'models/clients/measures/hierarchyfilter';

var HierarchyFiltersCollection = Backbone.Collection.extend({
  model: HierarchyFilterModel,

  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
  },

  serviceId: 'foresee-services',

  parse: function (filters) {
    return _.filter(filters, (f) => {
      return f.filterId;
    });
  },

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/hierarchyfilters/filters`;
  },
});

export default HierarchyFiltersCollection;

import Backbone from 'backbone';
import _ from 'underscore';
import AnswersCollection from 'collections/clients/projects/questions/answers';

var QuestionModel = Backbone.Model.extend({
  defaults: {},

  idAttribute: 'customQuestionId',
  serviceId: 'acs-services',
  initialize: function (model, opts) {
    var options = opts || {},
      type,
      props;

    if (!this.answers && this.id) {
      this.answers = new AnswersCollection(false, options);
      this.answers.url = _.result(this, 'url') + '/answers';
      this.answers.options = options;
      this.answers.customQuestionId = this.id;
    }

    if (model.type) {
      props = this.getProps(model.type);
      this.set(props);
    } else if (model.questionType) {
      type = this.getType(model.questionType, model.displayType);
      this.set('type', type);
    }

    this.options = options;
  },
  validate: function (attr) {
    // remove html tags before testing by putting text string
    // including html tags into a temporary div that never gets rendered,
    // then extract only the text from there
    var invalid = false;

    var tmp = document.createElement('DIV');
    tmp.innerHTML = attr.questionText;
    var test = tmp.textContent || tmp.innerText || '';
    if (test.length < 1 || attr.questionText.length > 1024) {
      invalid = true;
    }
    return invalid;
  },
  toJSON: function (opts) {
    var options = opts || {},
      attributes = _.extend({}, this.attributes),
      answers = this.answers;

    // We're either sending data to the view, or to the server
    if (options.toView) {
      delete attributes.responseType;
      delete attributes.questionType;
      delete attributes.displayType;
    } else {
      delete attributes.fixed;
      delete attributes.type;
    }

    if (answers && answers.length) {
      this.answers.sort();
      attributes.answers = answers.toJSON();
    }

    return attributes;
  },

  parse: function (data, options) {
    if (!this.answers) {
      this.answers = new AnswersCollection(false, options);
      this.answers.url =
        _.result(this, 'url') + '/' + data.customQuestionId + '/answers';
      this.answers.options = options;
      this.answers.customQuestionId = data.customQuestionId;
    }
    if (data && data.answers && data.answers.length) {
      this.answers.reset(data.answers, options);
    }
    data.rules = !data.rules ? [] : JSON.parse(data.rules);

    // We're not using this anymore. We use questionTYpe and displayType independently.
    delete data.responseType;
    // We've already handled answers and created a collection if necessary.
    delete data.answers;

    return data;
  },

  // Get question properties when a question is created in the builder
  getProps: function (type) {
    var props = {
      displayType: null,
      questionType: null,
      displayStatus: 'SHOWN',
    };

    switch (type) {
      case 'QT_OPENENDS':
        props.questionType = 'OPENEND';
        props.displayType = 'TEXT_AREA';
        break;

      case 'QT_RADIOBUTTON':
        props.questionType = 'RADIO_BUTTON';
        props.displayType = 'ONE_UP';
        break;

      case 'QT_DROPDOWN':
        props.questionType = 'DROP_DOWN';
        props.displayType = 'SINGLE';
        break;

      case 'QT_STAR_RATING':
        props.questionType = 'SCALED';
        props.displayType = 'WITHOUT_DONT_KNOW';
        props.minScale = 1;
        props.maxScale = 5;
        break;

      case 'QT_CHECKBOX':
        props.questionType = 'CHECKBOX';
        props.displayType = 'ONE_UP';
        break;

      case 'QT_TEXT':
        props.questionType = 'OPENEND';
        props.displayType = 'TEXT_FIELD';
        break;

      default:
        break;
    }

    return props;
  },

  // Lookup type of question by questionType and displayType attributes
  getType: function (questionType, displayType) {
    var type;

    switch (questionType) {
      case 'OPENEND':
        type = displayType === 'TEXT_AREA' ? 'QT_OPENENDS' : 'QT_TEXT';
        break;

      case 'DROP_DOWN':
        type = 'QT_DROPDOWN';
        break;

      case 'RADIO_BUTTON':
        type = 'QT_RADIOBUTTON';
        break;

      case 'SCALED':
        type = 'QT_STAR_RATING';
        break;

      case 'CHECKBOX':
        type = 'QT_CHECKBOX';
        break;

      default:
        type = 'QT_UNKNOWN';
        break;
    }

    return type;
  },
});

export default QuestionModel;

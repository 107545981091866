import _ from 'underscore';
import { merge } from 'lodash';
import $ from 'jquery';
import store from './store';
import constants from './constants';
import en from './i18n/languages/en';
import en_uk from './i18n/languages/en-uk';
import es from './i18n/languages/es';
import fr_ca from './i18n/languages/fr-ca';
import pt from './i18n/languages/pt';
import moment from 'moment';

var _LangSets = {
    en: en,
    'en-uk': en_uk,
    es: es,
    'fr-ca': fr_ca,
    pt: pt,
  },
  I18n = function () {
    /**
     * The collection of localized strings
     *
     * @type Object
     */
    this.strings = {};

    /**
     * Currently selected language
     *
     * 'en' by default.
     * @type String
     */
    this._lang = _.isString(store.get('lang')) ? store.get('lang') : 'en';

    // Set the lang for window.momentjs lib
    moment.locale(this._lang);

    var langPriority = [this._lang],
      i,
      langList = [];

    // Sort the incoming arguments
    for (i = 0; i < arguments.length; i++) {
      if (typeof arguments[i] === 'string') {
        langPriority.push(arguments[i]);
      } else if (typeof arguments[i] === 'object') {
        this.strings = merge({}, this.strings, arguments[i]);
      }
    }

    // First coallate the language priorities and make sure english is in there somewhere, at the end.
    if (!_.contains(langPriority, 'en')) langPriority.push('en');

    // Now do a merge on the language tables
    for (i = langPriority.length - 1; i >= 0; i--) {
      if (_LangSets[langPriority[i]]) {
        this.strings = merge({}, this.strings, _LangSets[langPriority[i]]);
      }
    }

    // Construct the lang list
    $.each(_LangSets, function (itm) {
      langList.push({
        code: itm,
        name: _LangSets[itm]._name,
      });
    });

    // Remove the private members
    delete this.strings._name;

    // Decorate the document with the language
    $('html').attr('lang', langPriority[0]);

    // Keep the language list
    this.supportedLanguages = langList;

    // Add the langlist and the client app root path to the strings
    // collection so templates can resolve the app root
    this.strings.Globals.supportedLanguages = langList;
  };
/**
 * Given a string of form 'a.b.c' will return value from that location in strings.
 * @param string
 */
I18n.prototype.getString = function (string) {
  var fn;

  if (string) {
    try {
      fn = [].constructor.constructor('return arguments[0].' + string);
      return fn(this.strings);
    } catch (e) {
      return '**_' + string + '_**';
    }
  } else {
    return '';
  }
};
/**
 *  Given a string defined with interpolation tokens of the form ${token},
 *  resolve those tokens from the properties of a provided object.
 *
 *
 **/
I18n.prototype.getTemplatedString = function (string, tokens) {
  var template = this.getString(string);

  _.templateSettings = {
    interpolate: /\$\{(.+?)\}/g,
  };

  if (string && tokens) {
    return _.template(template)(tokens);
  } else {
    return '';
  }
};
/**
 * Get/sets the selected language
 *
 * @param {String} l Optional. The two letter language code.
 * E.g. en -> English es -> Spanish
 *
 * @type String
 */
I18n.prototype.lang = function (l) {
  if (typeof l === 'undefined') return this._lang;

  this._lang = l;
  store.set('lang', l);
  moment.locale(l);
};

export default new I18n({
  Globals: {
    // Templates need to resolve paths, so add the Globals collection to all language
    // packs
    clientroot: constants.GLOBAL_CLIENT_ROOT,
  },
});

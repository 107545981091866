import Backbone from 'backbone';

var AlertModel = Backbone.Model.extend({
  idAttribute: 'alertId',

  url: function () {
    return (
      'clients/' +
      this.clientId +
      '/projects/' +
      this.projectId +
      '/alerts/' +
      this.alertId
    );
  },
});

export default AlertModel;

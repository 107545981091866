import BaseController from 'controllers/base';
import $ from 'jquery';
import TopicsCollection from 'collections/clients/projects/topics';
import _ from 'underscore';
import moment from 'moment';

class ReportsController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'reports',
      actions: {
        'route:data': 'onRouteData',
        'location:change': 'requestRouteData',
        'reports:initialized': 'requestRouteData',
        'reports:get': 'handleReportsData',
        'projects:data': 'onProjectsData',
        'reports:topic': 'setTopic',
        'reports:projectChange': 'changeProject',
        'reports:getProjects': 'getProjects',
        'reports:getCurrentProject': 'getCurrentProject',
        'reports:getTopics': 'getTopics',
        'insights:daterange:criteria': 'handleDateRangeChanged',
      },
      dependsOn: ['user', 'projects'],
    });
  }

  initialize(opts) {
    opts = opts || {};

    var clientId = opts.clientId || this.getClientId();

    this.options = {
      clientId: clientId,
    };
  }

  getCurrentProject() {
    this.publish('reports:currentProject', this.currProject);
  }

  getProjects() {
    this.publish('projects:get');
  }

  onRouteData(data) {
    data = data || {};
    data = data.pathIds || {};

    var projectId = Number(data.projects);

    if (_.isNumber(projectId) && !isNaN(projectId)) {
      this.projectId = projectId;
      this.setCurrentProject(projectId);
    }
  }

  requestRouteData(data) {
    this.publish('route:get');
  }

  // function: attempts to set current project
  // emits projects to project dropdown
  onProjectsData(data) {
    this.projects = data.projects || {};
    this.publish('reports:projects', this.projects);

    this.setCurrentProject(this.projectId);
  }

  changeProject(project) {
    this.publish('route:change', {
      route: '/projects/' + project.projectId + '/reports',
    });

    this.setCurrentProject(project.projectId);
  }

  setCurrentProject(projectId) {
    var project;

    if (!_.isEmpty(this.projects)) {
      project = _.findWhere(
        this.projects,
        {
          projectId: projectId,
        },
        this
      );
    }

    if (project && this.currProjectId !== projectId) {
      this.currProject = project;
      this.currProjectId = projectId;
      this.options.projectId = projectId;

      this.publish('reports:currentProject', this.currProject);
      this.getTopics();
    }
  }

  setTopic(topic) {
    console.log('setting topic...', topic);
    this.topic = topic;
    this.topicId = this.topic ? this.topic.get('topicId') : 0;
  }

  handleReportsData(payload) {
    this.publish('reports:data', {
      test: 'success',
    });
  }

  // ------------------- Fetching ------------------- //

  getTopics() {
    if (!this.currProjectId || _.isEmpty(this.options)) {
      return false;
    }

    this.topics = new TopicsCollection(false, this.options);
    this.topics.projectId = this.currProjectId;

    this.topics.fetch().done(() => {
      this.publish('reports:topics', this.topics);
    });
  }

  handleDateRangeChanged(criteria) {
    const format = 'YYYY-MM-DDTHH:mm:ssZZ';
    let result = {
      first: criteria.f,
      last: criteria.l,
    };
    if (criteria.result) {
      result.first = criteria.result.f;
      result.last = criteria.result.l;
      delete criteria.result;
    }
    this.exportLabels = { first: result.first, last: result.last };
    criteria.k = String(this.options.clientId);

    if (criteria.r === 'C' || criteria.r === 'CUSTOM') {
      criteria.f = moment(criteria.f).startOf('day').format(format);
      criteria.l = moment(criteria.l).endOf('day').format(format);
    } else {
      criteria.f = criteria.l = '';
    }
    this.dateRange = criteria;
  }

  // ------------------- Utility ------------------- //

  // jQuery deferred wrapper
  // @param data === Backbone Collection or Model
  // ex)
  //    this.myCollection = new Backbone.Collection();
  //    this.fetchData('myCollection').done(function () { emitData(); });
  fetchData(data, options) {
    var def = $.Deferred();

    data = this[data];
    options = options && typeof options !== 'undefined' ? options : {};

    if (!!data && !!data.fetch) {
      $.when(data.fetch(options)).done(function () {
        def.resolve();
      });
    }

    return def;
  }

  // create an element to simplify url parsing
  // provides functionality similar to Window.location
  // (location.hash, location.pathname, location.origin, location.search)
  generateLocationObject(location) {
    var url = document.createElement('a');
    url.href = location;
    url.href = url.href.replace(url.hash, '');
    return url;
  }

  // Strips filename from url && adds wildcard character
  // used for in page reporting && current + children user option selected
  removeUrlFilename(url) {
    var baseUrl = '';
    var location = this.generateLocationObject(url);

    if (location.pathname.match('\\.')) {
      // assign expected filename to variable
      var splitPathname = location.pathname.split('.');
      var path = splitPathname.shift();
      var fileExtension = splitPathname.join('.');
      path = path.split('/');
      var filename = path.pop() + '.' + fileExtension;
      var directory = '';
      // check if expected filename exists within path
      if (location.pathname.match(filename)) {
        directory = location.pathname.replace(filename, '');
      }

      baseUrl = location.origin + directory;
    } else {
      // var hasSlash = location.href[location.href.length - 1] === '/';
      // var wildCard = hasSlash ? '*' : '/*';
      baseUrl = location.href;
    }

    return baseUrl;
  }
}

export default ReportsController;

import Backbone from 'backbone';
import moment from 'moment';
import ScoresByAnswerModel from 'models/clients/measures/questions/scoresbyanswer';

var ScoresByAnswerCollection = Backbone.Collection.extend({
  model: ScoresByAnswerModel,
  initialize: function (models, opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
    this.question = options.questionKey;
  },

  comparator: 'answerKey',

  parse: function (data) {
    this.respondents = data.primaryResult.n;
    this.dateRange = {
      start_date: new moment(data.criteria.dateRange.f),
      end_date: new moment(data.criteria.dateRange.l),
    };
    if (data.primaryResult.n) {
      return data.primaryResult.scores;
    }
    return [];
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/questions/${this.question}/scoresbyanswer`;
  },
});

export default ScoresByAnswerCollection;

export default () => {
  const config = {
    controller: 'text-analytics',
    layout: 'navigation',
    permissions: 'text_analytics',
    icon: 'navTa',
    label: 'Text Analytics',
    tabs: [
      {
        routes: ['overview', 'topics', 'keywords'],
      },
    ],
    overview: {
      label: 'Overview',
      layout: 'text-analytics-overview',
      permissions: 'text_analytics',
      hasTAFilters: true,
      dashboards: {
        ':id': {
          card: {
            ':id': {
              controller: 'text-analytics',
              layout: 'text-analytics-overview-card-detail',
              hasTAFilters: true,
            },
          },
        },
      },
    },
    topics: {
      label: 'Topics',
      layout: 'text-analytics-topics',
      permissions: 'text_analytics',
    },
    keywords: {
      label: 'Keywords',
      layout: 'text-analytics-keywords',
      permissions: 'text_analytics',
      hasTAFilters: true,
    },
  };

  return config;
};

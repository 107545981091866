import BaseController from 'controllers/base';
import UploadProcessesCollection from 'collections/clients/upload/processes';
import UploadProcessesModel from 'models/clients/upload/processes';
import UploadProcessesTasksModel from 'models/clients/upload/processes/tasks';

class UploadsController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'uploads:processes:get': '_get',
        'uploads:processes:post': '_post',
        'uploads:processes:delete': '_delete',
        'uploads:processes:tasks:get': '_getTasks',
        'uploads:processes:tasks:post': '_postTasks',
      },
      name: 'uploads',
      dependsOn: [],
    });
  }

  initialize(opts) {
    // let options = opts || {};
    // options.clientId = this.getClientId();
  }

  _get(payload) {
    const { id, status, callback, populateUsers, uploadType } = payload;

    let options = {
      clientId: this.getClientId(),
      uploadType: uploadType,
    };

    if (status) {
      options.status = status;
    }

    let model = false;
    if (id) {
      options.id = id;
      model = new UploadProcessesModel(false, options);
    } else {
      model = new UploadProcessesCollection(false, options);
    }

    model
      .fetch({
        error: () => {
          // this._dispatch(callback, "master-data:data", model.toJSON());
        },
        headers: { Accept: 'application/json' },
      })
      .then(() => {
        if (populateUsers && model.getUserIds().length) {
          this.publish('authorization:users:get', {
            ids: model.getUserIds(),
            callback: (users) => {
              model.setUsers(users);
              this.dispatch(callback, 'uploads:processes:data', model.toJSON());
            },
          });
        } else {
          this.dispatch(callback, 'uploads:processes:data', model.toJSON());
        }
      });
  }

  _post(payload) {
    console.log('_post new upload', payload);
    const model = new UploadProcessesModel(payload, {
      clientId: this.getClientId(),
      uploadType: payload.uploadType,
    });
    console.log('_post model', model);
    model.save().then(() => {
      console.log('in callback of save', model);
      if (payload.callback) {
        payload.callback(model.toJSON());
      }
    });
  }

  _delete(payload) {
    console.log('delete process', payload);
    const model = new UploadProcessesModel(
      {
        id: payload.id,
      },
      {
        clientId: this.getClientId(),
        id: payload.id,
      }
    );
    model.destroy({
      headers: { Accept: 'application/json' },
    });
  }

  _getTasks(payload) {
    const { activityType, processId, callback, populateUsers, uploadType } =
      payload;
    const model = new UploadProcessesTasksModel(false, {
      clientId: this.getClientId(),
      processId: processId,
      activityType: activityType,
      uploadType: uploadType,
    });

    model
      .fetch({
        headers: { Accept: 'application/json' },
      })
      .then(() => {
        if (populateUsers) {
          this.publish('authorization:users:get', {
            ids: model.getUserIds(),
            callback: (users) => {
              model.setUsers(users);
              this.dispatch(
                callback,
                'uploads:processes:tasks:data',
                model.toJSON()
              );
            },
          });
        } else {
          this.dispatch(
            callback,
            'uploads:processes:tasks:data',
            model.toJSON()
          );
        }
      });
  }

  _postTasks(payload) {
    const { task, activityType, processId, callback, uploadType } = payload;
    const model = new UploadProcessesTasksModel(false, {
      clientId: this.getClientId(),
      processId: processId,
      activityType: activityType,
      uploadType: uploadType,
    });

    model
      .save(task, {
        headers: { Accept: 'application/json' },
      })
      .then(() => {
        console.log('in callback of save', model);
        if (callback) {
          callback(model.toJSON());
        }
      });
  }
}

export default UploadsController;

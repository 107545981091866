import Backbone from 'backbone';

const CSVExportModel = Backbone.Model.extend({
  exportFileType: 'csv',

  initialize: function (model, opts) {
    var options = opts || {};
    this.clientId = options.clientId;
    this.type = options.type;
    this.fileName = options.fileName;
  },

  // export related methods
  getSaveFileOptions() {
    return { headers: { Accept: 'text/csv' } };
  },
  hasExportFailed: function (response) {
    return response.failed;
  },
  hasExportCompleted: function (response) {
    return response.completed;
  },

  url: function () {
    if (this.get('exportCompleted')) {
      return `clients/${this.clientId}/csv/export/${this.type}/${this.get(
        'exportId'
      )}/file`;
    }

    if (this.get('exportId')) {
      return `clients/${this.clientId}/csv/export/${this.type}/${this.get(
        'exportId'
      )}`;
    }

    return `clients/${this.clientId}/csv/export/${this.type}`;
  },
});

export default CSVExportModel;

import Backbone from 'backbone';
// import Constants from 'core/constants';

const EventsFilterModel = Backbone.Model.extend({
  idAttribute: 'filterId',

  defaults: {
    //    terms: [],
    //    operator: Constants.OPERATOR_AND
    name: 'Untitled Filter',
  },

  initialize: function (attributes, opts) {
    this.addOptions(opts);
  },

  addOptions: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.appKey = options.appKey || 'funcxm';

    this.options = options;
  },

  url: function () {
    const preRoute = 'dcos-internal-gateway-service/internal/in/ui-endpoints';
    const route = `${preRoute}/filters/clients/${this.client}/filters/appId/${this.appKey}/events`;
    if (this.get('filterId')) {
      return `${route}/${this.get('filterId')}`;
    }
    return route;
  },

  // POST /filter-service/clients/418144/filters/appId/funcxm/events HTTP/1.1

  serviceId: 'foresee-services',
});

export default EventsFilterModel;

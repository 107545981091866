import BaseController from 'controllers/base';
import $ from 'jquery';
import constants from 'core/constants';
import PasswordResetModel from 'models/platform-scim-service/password';
import PasswordRecoveryModel from 'models/platform-scim-service/password/recovery';
import TokenVerifyModel from 'models/platform-scim-service/password/recovery/token';

class AuthenticateController extends BaseController {
  constructor() {
    super(arguments, {
      serverRoot: constants.SERVER_ROOT,
      actions: {
        'authenticate:login': 'login',
        'authenticate:validateSSO': 'validateSSO',
        'authenticate:validateToken': 'validateAccessToken',
        'authenticate:authorizeAAP': 'authorizeAAP',
        'authenticate:password:recover': 'passwordRecover',
        'authenticate:resetToken:verify': 'verifyResetToken',
        'authenticate:reset': 'reset',
        'authenticate:passwordPolicy': 'getPasswordPolicy',
        'authenticate:removeOktaSession': 'removeOKTASession',
      },
      name: 'authenticate',
    });
  }

  validateSSO(payload) {
    const { username, consumer, identityProviderId } = payload.userData;
    let params = identityProviderId
      ? `identityProviderId=${identityProviderId}`
      : `username=${username}`;
    this.ajax({
      type: 'GET',
      url: `${this.serverRoot}idps?${params}&consumer=${consumer}`,
      callback: payload.callback,
      failureEvent: 'authenticate:failed',
      logFailureEvent: { name: 'authentication', type: 'ssoFailure' },
      flag: 'validateSSO',
    });
  }

  validateAccessToken(payload) {
    const { accessTokenData, previousRoute } = payload;

    this.ajax({
      type: 'POST',
      url: `${this.serverRoot}token`,
      data: accessTokenData,
      successEvent: 'app:authenticated',
      failureEvent: 'authenticate:tokenExchangFailure',
      logFailureEvent: { name: 'authentication', type: 'tokenExchangFailure' },
      previousRoute,
      flag: 'validateAccessToken',
    });
  }

  authorizeAAP(payload) {
    this.ajax({
      type: 'POST',
      url: `${this.serverRoot}authorize`,
      data: payload,
      successEvent: 'authenticate:aapSuccess',
      failureEvent: 'authenticate:failed',
      logSuccessEvent: { name: 'authentication:app', type: 'success' },
      logFailureEvent: { name: 'authenticate:aap', type: 'failure' },
      flag: 'authorizeAAP',
    });
  }

  login(payload) {
    const { loginData, previousRoute } = payload;
    this.ajax({
      type: 'POST',
      url: `${this.serverRoot}access`,
      data: loginData,
      successEvent: 'app:authenticated',
      failureEvent: 'authenticate:failed',
      logSuccessEvent: { name: 'authentication:login', type: 'success' },
      logFailureEvent: { name: 'authenticate:login', type: 'failure' },
      flag: 'login',
      previousRoute,
    });
  }

  removeOKTASession() {
    this.ajax({
      type: 'DELETE',
      url: `!!_oktaURL_!!api/v1/sessions/me`,
      failureEvent: 'authenticate:failed',
      successEvent: 'app:deleteSession',
      logSuccessEvent: { name: 'app:deleteSession', type: 'success' },
      logFailureEvent: { name: 'app:deleteSession', type: 'failure' },
      flag: 'session',
      withCredentials: true,
    });
  }

  ajax(payload) {
    const controller = this;
    const {
      type,
      headers,
      url,
      data,
      callback,
      successEvent,
      failureEvent,
      logSuccessEvent,
      logFailureEvent,
      previousRoute,
      flag,
      withCredentials,
    } = payload;

    $.ajax({
      type,
      headers: headers && {
        'X-AUTH-TOKEN': data.token,
      },
      xhrFields: withCredentials && {
        withCredentials: true,
      },
      doNotAbort: withCredentials ? true : '',
      url,
      contentType: 'application/json',
      data: data && JSON.stringify(data),
      dataType: 'json',
      success: (response) => {
        switch (flag) {
          case 'validateSSO':
            callback && callback(response);
            break;

          case 'validateAccessToken':
            controller.publish(successEvent, {
              response: response,
              previousRoute: previousRoute,
            });
            controller.publish('app:sessionexpired', {
              expiredSession: false,
            });
            break;

          case 'authorizeAAP':
            controller.logEvent(logSuccessEvent.name, logSuccessEvent.type);
            controller.publish(successEvent, response);
            break;

          case 'login':
            controller.logEvent(logSuccessEvent.name, logSuccessEvent.type);
            controller.publish(successEvent, {
              response: response,
              previousRoute,
            });
            controller.publish('app:sessionexpired', {
              expiredSession: false,
            });
            break;

          case 'forgot':
            controller.publish(successEvent, {
              response: response,
            });
            break;

          case 'reset':
            controller.publish(successEvent, {
              response: response,
            });
            break;

          case 'session':
            break;

          case 'default':
            break;
        }
      },
      error: (error) => {
        //TO DO : change error messages to geenric error message for reset and forgot password

        switch (flag) {
          case 'forgot':
            controller.publish(failureEvent, {
              exception: error.responseJSON.exception,
              message: error.responseJSON.message,
            });
            break;

          case 'reset':
            controller.publish(failureEvent, error.responseJSON);
            break;

          case 'session':
            break;

          default:
            flag !== 'forgot' &&
              flag !== 'reset' &&
              controller.publish(failureEvent, error);
            controller.logEvent(logFailureEvent.name, logFailureEvent.type);
        }
      },
    });
  }

  passwordRecover(payload) {
    const { callback, userIdentifier, factorType } = payload;

    const model = new PasswordRecoveryModel({
      userIdentifier,
      factorType,
    });

    model.save().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }
  verifyResetToken(payload) {
    const { errorCallback, callback, token, passCode, factorType } = payload;

    const model = new TokenVerifyModel({
      passCode,
      token,
      factorType,
    });

    model.save(null, { error: errorCallback }).then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  reset(payload) {
    const {
      callback,
      stateToken,
      newPassword,
      confirmNewPassword,
      errorCallback,
    } = payload;

    const model = new PasswordResetModel({
      stateToken,
      newPassword,
      confirmNewPassword,
    });

    model.save(null, { error: errorCallback }).then(() => {
      if (callback) {
        callback();
      }
    });
  }
}

export default AuthenticateController;

export default {
  parentNav: 'leaderboard',
  ':id': {
    measures: {
      ':id': {
        leaderboard: {
          controller: 'leaderboards',
          layout: 'leaderboard-details',
        },
      },
    },
  },
};

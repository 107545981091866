import Backbone from 'backbone';

const PageFilterDetailsModel = Backbone.Model.extend({
  idAttribute: 'pageFilterId',

  defaults: {
    pageFilterPages: [],
  },

  initialize: function (attributes, opts) {
    this.addOptions(opts);
  },

  addOptions: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;

    this.options = options;
  },

  url: function () {
    return `clients/${this.client}/measures/${
      this.measure
    }/pagefilters/${this.get('pageFilterId')}/details`;
  },

  reset: function () {
    this.clear().set({
      pageFilterPages: [],
    });
  },

  serviceId: 'foresee-services',
});

export default PageFilterDetailsModel;

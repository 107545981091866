import Backbone from 'backbone';

const UserModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    const options = opts || {};
    this.id = options.id;
    this.scimAttributes = options.attributes;
  },

  parse: function (data) {
    const { name, emails, phoneNumbers } = data;
    return {
      firstName: name.givenName,
      lastName: name.familyName,
      userName: data.userName,
      userId: data.id,
      active: data.active,
      emailAddress: emails && emails[0] && emails[0].value,
      phoneNumber: phoneNumbers && phoneNumbers[0] && phoneNumbers[0].value,
      lastLogonDate:
        data['urn:ietf:params:scim:schemas:extension:foresee:1.0:User']
          .lastLoggedIn,
      profileStatus:
        data['urn:ietf:params:scim:schemas:extension:foresee:1.0:User']
          .profileStatus,
      internal:
        data['urn:ietf:params:scim:schemas:extension:foresee:1.0:User']
          .clientId === -1,
      ssoEnabled:
        data['urn:ietf:params:scim:schemas:extension:foresee:1.0:User']
          .authenticationProvider !== 'FORESEE',
      roles: data.roles,
    };
  },

  isNew: function () {
    return !this.id;
  },

  url: function () {
    if (this.id) {
      return `platform-scim-service/Users/${this.id}?attributes=${this.scimAttributes}`;
    }
    return `platform-scim-service/Users`;
  },
});

export default UserModel;

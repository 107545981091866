import Backbone from 'backbone';
import _ from 'underscore';

var Template = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    templateName: '',
  },

  /**
   * Constructor
   */
  initialize: function (opts) {
    this.configs = {};
  },

  toJSON: function () {
    return _.extend(
      {
        configs: this.configs,
      },
      this.attributes
    );
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default Template;

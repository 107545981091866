import moment from 'moment';
import _ from 'underscore';
import BaseController from 'controllers/base';
import DateRange from 'models/dateRange';
import constants from 'core/constants';
const { DATE_ONLY } = constants;

class DatesController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'dates:lookup': '_handleLookup',
        'dates:limits:lookup': '_getLimitDates',
        'dates:criteria:get': '_getCriteria',
      },
      name: 'dates',
      dependsOn: [],
    });

    this.calendarType = 'GREGORIAN';
  }

  initialize() {}

  _getCriteria(payload) {
    const lastMonth = {
      k: this.getClientId(),
      c: this.calendarType,
      r: 'MONTHS',
      n: 1,
      p: 'DEFINED',
      a: new moment().startOf('month').format('YYYY-MM-DD'),
      f: null,
      l: null,
      vndType: 'application/vnd.foresee.date.range',
    };
    payload.callback(lastMonth);
  }

  _getLimitDates(payload) {
    const { dateRange, callback } = payload;
    let date = new DateRange(dateRange);
    const format =
      dateRange.t === 'DATE_TIME' ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD';
    _.extend(date, {
      urlparams: {
        dateRange: JSON.stringify(date.toJSON({ format })),
      },
    });
    date.fetch().then((data) => {
      if (callback) {
        callback({
          f: data.f,
          l: data.l,
        });
      }
    });
  }

  _handleLookup(payload) {
    let today =
      typeof payload.today !== 'undefined' ? payload.today : new moment();
    const calendarType = payload.calendarType || this.calendarType;
    const clientId = calendarType === 'G' ? '' : this.getClientId();
    const callback = payload.callback || null;
    let first = null;
    let last = null;
    const dateRangeType = payload.dateRangeType
      ? payload.dateRangeType
      : DATE_ONLY;
    const includeTimeZone = payload.includeTimeZone || false;

    if (payload.rangeType === 'C') {
      first = payload.startDate;
      last = payload.endDate;
    }

    if (
      payload.rangeType === 'D' ||
      payload.rangeType === 'H' ||
      (payload.rangeType === 'M' && payload.number === 12)
    ) {
      today = null;
    }

    var criteria = {
      customerKey: clientId,
      calendarType: calendarType,
      rangeType: payload.rangeType,
      number: payload.number,
      asOf: today,
      first: first,
      last: last,
      dateRangeType: dateRangeType,
    };
    var date = new DateRange(criteria);

    _.extend(date, {
      urlparams: {
        dateRange: JSON.stringify(date.toJSON({ format: 'YYYY-MM-DD' })),
      },
    });

    return date.fetch({ doNotAbort: true }).then((data) => {
      data = this.parseDates(data, this.parseItem, includeTimeZone);
      if (callback) {
        callback(data);
      }
    });
  }

  parseDates(dateRange, parseItem, includeTimeZone = false) {
    var parsed = {
      c: parseItem(dateRange.c, 'calendarTypes'),
      r: parseItem(dateRange.r, 'rangeTypes'),
      p: parseItem(dateRange.p, 'periodTypes'),
      n: dateRange.n,
      a: dateRange.a,
      f: dateRange.f,
      l: dateRange.l,
      k: dateRange.k,
      t: dateRange.t,
      v: dateRange.v,
    };

    if (includeTimeZone) {
      parsed.z = dateRange.z;
    }
    return parsed;
  }

  parseItem(t, type) {
    var types = DateRange[type];
    if (_.contains(_.keys(types), t)) {
      t = types[t];
    }
    return t;
  }
}

export default DatesController;

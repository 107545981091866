import Backbone from 'backbone';

const UserRolesModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    const options = opts || {};
    this.client = options.clientId;
    this.forceUpdate = options.forceUpdate;
  },

  url: function () {
    return `authorization-service/clients/${this.client}/userRoles`;
  },
});

export default UserRolesModel;

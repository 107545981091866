import Backbone from 'backbone';

const PromoteGlobalConfigModal = Backbone.Model.extend({
  initialize: function (attributes, opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.site = options.siteId;
    this.container = options.containerId;
    this.targetContainer = options.targetContainerId;
  },

  url: function () {
    return `clients/${this.client}/sites/${this.site}/containers/${this.container}/promote/${this.targetContainer}`;
  },
});

export default PromoteGlobalConfigModal;

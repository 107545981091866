import Backbone from 'backbone';

const PoliciesModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.type = options.type || 'PASSWORD';
  },

  url: function () {
    return `platform-scim-service/clients/${this.client}/policies?type=${this.type}`;
  },
});

export default PoliciesModel;

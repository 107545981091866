import React from 'react';
import BasePanel from 'views/panels/base';
import PasswordPolicyWidget from 'views/widgets/pw-policies';
import { Text, FormInput, FormButton, Divider } from 'cx-component-library';

class ContentResetView extends BasePanel {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      verifyPassword: '',
      passwordPolicies: props.passwordPolicies || {},
      stateToken: props.stateToken || '',
      isResettingPassword: false,
      hasVerifiedToken: props.stateToken ? true : false,
      showDetailedErrors: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.postVerifyToken();
  }

  postVerifyToken() {
    const { config } = this.props;
    const params = (config && config.params) || {};
    const resetPasswordToken = params.passwordResetToken;

    if (!resetPasswordToken) {
      return null;
    }

    this.publish('authenticate:resetToken:verify', {
      token: resetPasswordToken,
      factorType: 'EMAIL',
      callback: (data) => {
        const { passwordPolicy, stateToken } = data;
        this.setState({
          passwordPolicies: passwordPolicy,
          stateToken,
          hasVerifiedToken: true,
        });
      },
      errorCallback: () => {
        this.changeRoute('forgot.expired');
        this.publish('app:messageFlash', {
          messageText: this.getString('password.reset.expired'),
          messageType: 'error',
        });
      },
    });
  }

  postPasswordReset() {
    const { stateToken, newPassword, verifyPassword } = this.state;

    this.setState({ isResettingPassword: true, failedToResetPassword: false });
    this.publish('authenticate:reset', {
      stateToken,
      newPassword,
      confirmNewPassword: verifyPassword,
      callback: (data) => {
        this.changeRoute('login');
        this.publish('app:messageFlash', {
          messageText: this.getString('password.reset.success'),
        });
      },
      errorCallback: (data) => {
        this.setState({
          isResettingPassword: false,
          failedToResetPassword: true,
          detailedErrors: data._ajaxRef.responseJSON.detailedErrors,
        });
      },
    });
  }

  arePWPoliciesValid() {
    if (this.pwPolicyWidget) {
      return this.pwPolicyWidget.arePWPoliciesValid();
    }

    return false;
  }

  render() {
    const {
      newPassword,
      verifyPassword,
      passwordPolicies,
      hasVerifiedToken,
      isResettingPassword,
      failedToResetPassword,
      detailedErrors,
      showDetailedErrors,
    } = this.state;

    if (!hasVerifiedToken) {
      return null;
    }

    const { maxAgeDays, historyCount } = passwordPolicies;
    return (
      <div className='login login--wide'>
        <div className='login__header'>
          <div className='logo-image' />
        </div>
        <div className='login__content'>
          <div className='grid'>
            <div className='grid__item one-half'>
              <div className='gutter--bottom'>
                <Text theme='h1' type='div' className='gutter-half--bottom'>
                  {this.getString('password.forgot.resetPassword')}
                </Text>
                {failedToResetPassword && (
                  <Text
                    theme='body'
                    type='div'
                    error
                    className='gutter-half--bottom'
                  >
                    {this.getString('password.reset.failure')}&nbsp;
                    <Text
                      error
                      type='span'
                      onClick={this.handleErrorClick.bind(this)}
                    >
                      {showDetailedErrors &&
                        detailedErrors &&
                        detailedErrors.length > 0 && (
                          <Text type='span'>
                            {detailedErrors.join('.')}.&nbsp;
                          </Text>
                        )}
                      <Text
                        type='span'
                        error
                        theme='bold-body'
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        {showDetailedErrors
                          ? this.getString('password.reset.hideDetails')
                          : this.getString('password.reset.showDetails')}
                      </Text>
                    </Text>
                  </Text>
                )}
                <FormInput
                  className='gutter-half--bottom'
                  label={this.getString('password.reset.newPassword')}
                  placeholder={this.getString('password.reset.typePassword')}
                  size='xlarge'
                  autoFocus
                  type='password'
                  name='newPassword'
                  value={newPassword}
                  onChange={this.handleInputChange}
                />
                <FormInput
                  className='gutter-half--bottom'
                  label={this.getString('password.reset.confirmNewPassword')}
                  placeholder={this.getString('password.reset.typePassword')}
                  size='xlarge'
                  type='password'
                  name='verifyPassword'
                  value={verifyPassword}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className='clearfix'>
                <Text
                  theme='body'
                  className='left input-lh'
                  type='div'
                  linkTo={this.handleReturnToLoginClick}
                >
                  {this.getString('password.forgot.returnToLogin')}
                </Text>
                <FormButton
                  className='right'
                  label={this.getString('password.reset.saveNewPassword')}
                  disabled={!this.arePWPoliciesValid() || isResettingPassword}
                  onClick={this.handlePasswordSave}
                />
              </div>
            </div>
            <div className='grid__item one-half'>
              <Text theme='h1' type='div' className='gutter-half--bottom'>
                {this.getString('password.reset.passwordRequirements')}
              </Text>
              <PasswordPolicyWidget
                ref={(c) => {
                  this.pwPolicyWidget = c;
                }}
                passwordPolicies={passwordPolicies}
              />
              <Divider />
              {!!maxAgeDays && !!historyCount && (
                <Text theme='body' type='div'>
                  {this.getTemplatedString('password.descriptionBoth', {
                    maxAgeDays,
                    historyCount,
                  })}
                </Text>
              )}
              {!!maxAgeDays && !historyCount && (
                <Text type='div' theme='body'>
                  {this.getTemplatedString('password.descriptionAge', {
                    maxAgeDays,
                  })}
                </Text>
              )}
              {!maxAgeDays && !!historyCount && (
                <Text type='div' theme='body'>
                  {this.getTemplatedString('password.descriptionHistory', {
                    historyCount,
                  })}
                </Text>
              )}
            </div>
          </div>
        </div>
        <div className='login__content--bottom' />
      </div>
    );
  }

  passPasswordsToWidget(name, value) {
    if (this.pwPolicyWidget) {
      if (name === 'newPassword') {
        this.pwPolicyWidget.setNewPassword(value);
      }
      if (name === 'verifyPassword') {
        this.pwPolicyWidget.setVerifyPassword(value);
      }
    }
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.passPasswordsToWidget(name, value);
    this.setState({ [name]: value });
  };

  handleReturnToLoginClick = () => {
    this.changeRoute('login');
  };

  handlePasswordSave = () => {
    this.postPasswordReset();
  };

  handleErrorClick = () => {
    this.setState({
      showDetailedErrors: !this.state.showDetailedErrors,
    });
  };
}

export default ContentResetView;

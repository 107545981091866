/* eslint no-sequences: 0 */

import _ from 'underscore';
import moment from 'moment';
import Backbone from 'backbone';
import BaseView from 'views/base';
import permissions from 'core/permissions';
import dispatcher from 'core/dispatcher';
import getQueryObject from 'core/utils/get-query-object';

class BasePanel extends BaseView {
  constructor(props) {
    super(props);
    this.state = {};

    this.dataActions = {};

    /**
     * Reference to dispatcher singleton
     */
    this._dispatcher = dispatcher;

    /**
     * References to permissions singleton
     */
    this.permissions = permissions;
    this.options = permissions.options;
    this.unMounted = false;
  }

  componentDidMount() {
    /**
     * Set up for dispatcher methods
     */
    var updateAction, action, callback;

    // Keep events here so we can tear down just this on unmount.
    this._events = _.extend({}, Backbone.Events);

    // Set listeners for all actions in actions declarative object.
    _.each(this.actions, (cb, action) => {
      var handler = typeof cb === 'string' ? this[cb] : cb;
      this.subscribe(action, handler);
    });

    // Look for updateAction in props or props.config to indicate source of new data
    updateAction =
      this.props.updateAction ||
      (this.props.config && this.props.config.updateAction) ||
      undefined;

    // If the key is present in props and the handler is present, hook up a listener.
    if (typeof updateAction === 'string' && this.update instanceof Function) {
      this.subscribe(updateAction, this.update.bind(this));
    } else if (updateAction instanceof Object) {
      for (action in updateAction) {
        callback = this[updateAction[action]];

        if (action && callback instanceof Function) {
          this.subscribe(action, callback.bind(this));
        }
      }
    }
    /**
     * End of set up for dispatcher methods
     */

    this._initDataActions();
  }

  _initDataActions() {
    _.each(this.dataActions, (da, prop) => {
      if (!this.props[prop]) {
        if (typeof da === 'string' && this[da]) {
          this[da]();
        } else {
          this.publish(da.action, {
            retryIfUninitialized: true,
            ...da.payload,
          });
        }
      }
    });
  }

  componentWillUnmount() {
    /**
     * Remove dispatcher listeners when component is being unmounted
     */
    if (this._events) {
      this._events.stopListening();
    }

    this.unMounted = true;
  }

  /**
   * Some controllers invoke a callback function instead of dispatching events
   * Use this method when updating state from within a callback function
   */
  _updateState(payload, cb) {
    if (!this.unMounted) {
      this.setState(
        (state) => ({
          ...state,
          ...payload,
        }),
        cb
      );
    }
  }

  /**
   * Dispatcher Methods
   */

  /**
   * Add a listener to the dispatcher for an action
   * @param action
   * @param cb
   */
  subscribe(action, cb) {
    this._events.listenTo(
      this._dispatcher,
      action,
      function () {
        cb.apply(this, arguments);
      }.bind(this)
    );
  }

  /**
   * Remove a particular callback from the dispatcher
   * @param action
   * @param cb
   */
  unsubscribe(action, cb) {
    this._events.stopListening(this._dispatcher, action, cb);
  }

  /**
   * Publish an action to the dispatcher with a particular payload.
   * @param action
   * @param payload
   * @param rest
   */
  publish(action, payload) {
    this._dispatcher.trigger(action, payload);
  }

  /**
   * End of Dispatcher Methods
   */

  /**
   * Publish an action to collect event data in analytics system
   * @param category
   * @param action
   **/
  logEvent(category, action) {
    this.publish('track:event', {
      category: category,
      action: action,
    });
  }

  _formatDate(date) {
    return new moment(date).format('MM/DD/YYYY');
  }

  _formatDateTime(date) {
    return new moment(date).format('MM/DD/YYYY h:mm A');
  }

  changeRoute(key, tokens) {
    this.publish('route:change', {
      route: this.generateRoute(key, tokens),
    });
  }

  publishRoute(route) {
    this.publish('route:change', { route });
  }

  _getQueryObject(str) {
    return getQueryObject(str);
  }

  _getFragmentObject(str) {
    return (str || document.location.hash)
      .replace(/(^\#)/, '')
      .split('&')
      .map(
        function (n) {
          return (n = n.split('=')), (this[n[0]] = n[1]), this;
        }.bind({})
      )[0];
  }

  /**
   * Encodes the Base64 string
   * @param str
   */
  _encodeBase64String(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }
      )
    );
  }

  /**
   * Decodes the Base64 string
   * @param str
   */
  _decodeBase64String(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  _safeResize() {
    if (typeof Event === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('resize'));
    } else {
      //This will be executed on old browsers and especially IE
      var resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }
  }
}

export default BasePanel;

import Backbone from 'backbone';
import SitesModel from 'models/clients/settings/integrate/sites';

var SitesCollection = Backbone.Collection.extend({
  model: SitesModel,
  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.sites = [];
  },

  parse: function (data) {
    this.setSiteKeyDisplayName(data.message);
    return this.getSites();
  },

  url: function () {
    return `clients/${this.client}/sites`;
  },

  /**
   * Set display name of each site
   * @param {*} sites
   */

  setSiteKeyDisplayName: function (sites) {
    for (let site of sites) {
      site.displayName =
        !site.alias || site.alias === '0' ? site.name : site.alias;
    }
    this.sites = sites;
  },

  /**
   * Return sites with displayName value
   */

  getSites: function () {
    return this.sites;
  },
});

export default SitesCollection;

import $ from 'jquery';
import Constants from 'core/constants';

const CardBookModel = function (attributes) {
  this.urlRoute = 'cx-survey-dashboard';
  this.attributes = attributes || {
    slides: [
      {
        chart: {
          route: null, // required: phantomjs needs this to know which chart to generate [see ChartPanel]
          data: null, // required: the data needed to render the chart details [see getChartData in benchmark gauge card]
        },
        footer: {
          exported: '',
          n: '',
          source: '',
        },
        header: {
          subtitle: {
            date: '',
            segment: '',
          },
          title: '', // required: used to save the image to the s3 bucket
        },
      },
    ],
  };
};

CardBookModel.prototype.set = function (key, value) {
  this.attributes[key] = value;
};

CardBookModel.prototype.save = function (callback, options = {}) {
  $.ajax({
    ...options,
    type: 'POST',
    url: this.url(),
    contentType: 'application/json',
    data: JSON.stringify(this.attributes),
    dataType: 'json',
    success: function (response, status, jqXHR) {
      callback(null, response, status, jqXHR);
    },
    error: function (jqXHR, status, errorThrown) {
      callback(errorThrown, null, status, jqXHR);
    },
  });
};

CardBookModel.prototype.url = function () {
  return Constants.CARD_SERVICE + this.urlRoute;
};

export default CardBookModel;

import Backbone from 'backbone';

var ClientModel = Backbone.Model.extend({
  initialize: function (opts) {},

  //cacheMS: 60 * 60 * 1000,
  idAttribute: 'clientId',

  url: function () {
    return 'clients/' + this.id;
  },
});

export default ClientModel;

export default {
  navigation: {
    panels: ['header-verintTop', 'sidebar-unified', 'content-navigation'],
  },
  authenticate: {
    appClass:
      'frame__row frame__body frame__body--login frame__scroll-y no-margin',
    panels: ['content-auth'],
  },
  reset_password: {
    appClass:
      'frame__row frame__body frame__body--login frame__scroll-y no-margin',
    panels: ['content-resetpw'],
  },
  forgot_password: {
    appClass:
      'frame__row frame__body frame__body--login frame__scroll-y no-margin',
    panels: ['content-forgotpw'],
  },
  forgot_password_email: {
    appClass:
      'frame__row frame__body frame__body--login frame__scroll-y no-margin',
    panels: ['content-forgotpw-email'],
  },
  forgot_password_sms: {
    appClass:
      'frame__row frame__body frame__body--login frame__scroll-y no-margin',
    panels: ['content-forgotpw-sms'],
  },
  chart: {
    appClass: 'frame__row frame__body--chart no-margin',
    panels: ['content-chart-panel'],
  },
  'user-profile': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-user-profile'],
  },
  'server-error': {
    appClass:
      'frame__row frame__body frame__body--login frame__scroll-y no-margin',
    panels: ['content-error'],
  },
  'application-settings': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-application-settings',
    ],
  },
  dashboards: {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-dashboards',
      'content-dashboards',
    ],
  },
  dashboard: {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-dashboards',
      'content-dashboard',
    ],
  },
  'dashboard-detail': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-dashboard-detail'],
  },
  'create-new-dashboard': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-new-dashboard'],
  },

  'leaderboard-dashboard': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-leaderboard-dashboard',
    ],
  },
  'leaderboard-details': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-leaderboard-details',
      'content-leaderboard-details',
    ],
  },
  'settings2-case-emails': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings2-case-emails',
    ],
  },
  'settings2-case-emails-edit': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings2-case-emails-edit',
    ],
  },
  'settings2-case-response': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings2-case-response',
    ],
  },
  'settings2-case-response-edit': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings2-case-response-edit',
    ],
  },
  'settings2-case-configuration-templates': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings2-case-configuration-templates',
    ],
  },
  'settings2-case-configuration-templates-edit': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings2-case-configuration-templates-edit',
    ],
  },
  'settings-case-projects': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-case-projects',
    ],
  },
  'settings-case-project-detail': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-case-project-detail',
    ],
  },

  'settings-feedback-inpage': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-inpage',
    ],
  },

  'settings-text-feeds-custom': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-text-feeds-custom',
    ],
  },
  'settings-text-feeds-custom-detail': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-text-feeds-custom-detail',
    ],
  },

  'integrate-settings2': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings2-integrate',
    ],
  },
  'settings-data-connectors': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-data-connectors',
    ],
  },
  'settings-text-keyword-manager': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-text-keyword-manager',
    ],
  },
  'settings-text-taxonomy': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-text-taxonomy',
    ],
  },
  projects: {
    panels: {
      'header-verintTop': {},
      'content-projects': {
        all: true,
        selectedTab: '0',
      },
    },
  },
  'project-edit': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-project-edit'],
  },
  'project-feedback-collection': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-project-feedback-collection',
    ],
  },
  'project-feedback-weblink': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-project-feedback-weblink',
    ],
  },
  'project-feedback-publish': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-project-feedback-publish',
    ],
  },
  'project-feedback-type': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-project-feedback-type',
    ],
  },
  'create-feedback': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-create-feedback'],
  },
  cases: {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-filter-cases',
      'content-case-landing',
    ],
  },
  'case-projects': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-filter-cases',
      'content-case-projects',
    ],
  },
  'case-view-cases': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-filter-cases',
      'content-case-view-cases',
    ],
  },
  'case-detail': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-case-detail'],
  },
  'case-respondent': {
    appClass: 'frame__row frame__body frame__body--case-respondent no-margin',
    panels: ['content-case-respondent'],
  },
  'insights-summary': {
    panels: {
      'header-verintTop': {},
      'subnav-feedback-filters': {},
      'content-insights-summary': {
        inpage: false,
      },
    },
  },
  'insights-questions': {
    panels: {
      'header-verintTop': {},
      'subnav-feedback-filters': {},
      'content-insights-questions': {
        inpage: false,
      },
    },
  },
  'insights-openends': {
    panels: {
      'header-verintTop': {},
      'subnav-feedback-filters': {},
      'content-insights-openends': {
        inpage: false,
      },
    },
  },
  alerts: {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-alerts',
      'subnav-filter-notifications',
    ],
  },
  'alerts-edit': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-alerts-edit'],
  },
  'alerts-create': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-alerts-create'],
  },
  'cx-projects': {
    panels: [],
  },
  'cx-insights-dashboard': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-cx-filters',
      'content-cx-insights-summary',
    ],
  },
  'cx-survey': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-cx-survey'],
  },
  'cx-insights-respondents': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-cx-filters',
      'content-cx-insights-respondents',
    ],
  },
  //
  // Start of Text Analytic Routes

  'text-analytics-overview': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-ta-overview'],
  },
  'text-analytics-topics': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-filter-ta',
      'content-ta-topics',
    ],
  },
  'text-analytics-keywords': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-filter-ta',
      'content-ta-keywords',
    ],
  },
  'text-analytics-overview-card-detail': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-ta-overview-card-detail',
    ],
  },

  //
  // Start of Setting Layouts
  //
  // General Setting Layouts
  'settings-general-client-access': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-general-client-access',
    ],
  },
  'settings-general-users': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-general-users',
    ],
  },
  'settings-general-roles': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-general-roles',
    ],
  },
  'settings-general-role-assignments': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-general-role-assignments',
    ],
  },
  'settings-general-change-log': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-general-change-log',
    ],
  },
  //
  // Custom Data Sources
  //
  'settings-general-custom-data-sources': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-general-custom-data-sources',
    ],
  },
  //
  // Start of Hierarchy Settings
  //
  'settings-general-hiearchy': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-hierarchy',
    ],
  },
  'hierarchy-details-settings': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-hierarchy-details',
    ],
  },
  'hierarchy-versions-detail-settings': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-hierarchy-versions-detail',
    ],
  },
  'hierarchy-drafts-detail-settings': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-hierarchy-drafts-detail',
    ],
  },
  'hierarchy-new-settings': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-hierarchy-new',
    ],
  },
  'hierarchy-versions': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-hierarchy-versions',
    ],
  },
  'hierarchy-versions-detail': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-hierarchy-versions-detail',
    ],
  },
  'hierarchy-new': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-hierarchy-new',
    ],
  },
  'hierarchy-setup': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-settings-hierarchy-setup',
    ],
  },
  //
  // Start of Master Data Settings
  //
  'settings-general-masterdata': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-masterdata',
    ],
  },
  'masterdata-details-settings': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-masterdata-details',
    ],
  },
  'content-settings-masterdata-create': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-masterdata-create',
    ],
  },
  //
  // Start of User Settings
  //
  'settings-user-details': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-user-details',
    ],
  },
  //
  // Start of Role Settings
  //
  'settings-role-edit': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-role-edit',
    ],
  },
  'settings-role-new': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-role-new',
    ],
  },
  //
  // Start of Assignment Rule Settings
  //
  'settings-rule-edit': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'subnav-settings',
      'content-settings-rule-edit',
    ],
  },
  'settings-rule-new': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-settings-rule-new',
    ],
  },

  //
  // End of Setting Layouts
  //

  //
  // Start of Analyze Layouts
  //
  analyze: {
    panels: ['header-verintTop', 'sidebar-unified', 'content-anomaly-segments'],
  },
  'anomaly-detail': {
    panels: ['header-verintTop', 'sidebar-unified', 'content-anomaly-detail'],
  },
  'anomaly-embed': {
    appClass: 'frame__row frame__body no-margin',
    panels: ['content-anomaly-segments'],
  },
  'anomaly-detail-embed': {
    appClass: 'frame__row frame__body no-margin',
    panels: ['content-anomaly-detail'],
  },
  //
  // Start of Anomaly Detection

  //
  // End of Analyze Layouts
  //

  //
  // Start of Benchmarks
  'category-information': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-benchmark-category-information',
    ],
  },
  'participant-information': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-benchmark-participant-information',
    ],
  },
  'customer-information': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-customer-information',
    ],
  },
  // End of Benchmarks

  // Start of Data Sources
  'datasources-list': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-data-sources-list',
    ],
  },
  datasources: {
    panels: ['header-verintTop', 'sidebar-unified', 'content-data-sources'],
  },
  'datasources-detail': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-data-source-detail',
    ],
  },
  'favorite-datasources': {
    panels: [
      'header-verintTop',
      'sidebar-unified',
      'content-data-source-favorites',
    ],
  },
  // End of Data Sources
  // DBA Redir
  dba: {
    panels: ['content-dba'],
  },
  'dba-replay': {
    panels: ['content-dba-replay'],
  },
  // End of DBA Redir
};

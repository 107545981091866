import Backbone from 'backbone';
import TopicModel from 'models/clients/projects/topic';

var TopicsCollection = Backbone.Collection.extend({
  model: TopicModel,
  initialize: function (models, opts) {
    var options = opts || {};

    this.options = options;
    this.client = options.clientId;
    this.projectId = options.projectId;
  },

  comparator: 'topicOrder',

  parse: function (data) {
    if (!(data instanceof Array)) {
      data = [data];
    }
    data.projectId = this.projectId;

    return data;
  },

  serviceId: 'acs-services',

  url: function () {
    var client = this.client,
      project = this.projectId;

    return `clients/${client}/projects/${project}/topics`;
  },
});

export default TopicsCollection;

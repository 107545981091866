import Backbone from 'backbone';
import TemplateModel from 'models/clients/notifications/template';

var TemplatesCollection = Backbone.Collection.extend({
  model: TemplateModel,

  initialize: function (models, opts) {
    var options = opts || {};

    this.tenantId = options.tenantId;
    this.templateType = options.templateType;
  },

  url: function () {
    return `platform-communication-service/tenants/${this.tenantId}/templates?templateType=${this.templateType}`;
  },
});

export default TemplatesCollection;

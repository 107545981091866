import Backbone from 'backbone';

var CustomBasicAuthModel = Backbone.Model.extend({
  initialize: function (attrs, opts) {
    const options = opts || {};
    this.username = options.username;
  },

  url: function () {
    if (this.username) {
      return `custom-basic-auth?username=${encodeURIComponent(this.username)}`;
    }

    return 'custom-basic-auth';
  },
});

export default CustomBasicAuthModel;

export default {
  layout: 'alerts',
  controller: 'alert',
  icon: 'navAlerts',
  label: 'Alerts',
  permissions: 'feedback_alerts',
  create: {
    layout: 'alerts-edit',
  },
  ':id': {
    layout: 'alerts-edit',
    edit: {
      layout: 'alerts-edit',
    },
  },
};

import Backbone from 'backbone';
import OpenEnds from 'models/clients/measures/openends';

var OpenEndsCollection = Backbone.Collection.extend({
  model: OpenEnds,
  initialize: function (opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.measure = options.measure;
    this.nextPage = options.nextPage;
  },

  parse: function (data) {
    var next = '',
      links = data.links;

    if (links !== undefined) {
      links.forEach((link) => {
        if (link.rel === 'next') {
          next = link.href;
        }
      });
    }

    this.nextPage = next;
    this.links = data.links;
    this.criteria = data.criteria;
    this.totalResponses = data.totalResponses;
    this.totalRespondents = data.totalRespondents;

    return data.responses;
  },

  serviceId: 'foresee-services',

  url: function () {
    var page = this.nextPage ? this.nextPage.split('/openends/').pop() : '';
    return (
      'clients/' +
      this.client +
      '/measures/' +
      this.measure +
      '/openends/' +
      page
    );
  },
});

export default OpenEndsCollection;

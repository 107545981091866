import Backbone from 'backbone';
import _ from 'underscore';

var ScoresByAnswer = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    answerKey: 0,
    answerLabel: '',
    score: null,
    answerPercentage: 0,
    n: 0,
  },

  idAttribute: 'latentKey',

  toJSON: function () {
    return _.extend(this.attributes, {
      answerPercentage: parseFloat(this.get('answerPercentage').toFixed(2)),
      score: this.get('score')
        ? parseFloat(this.get('score')).toFixed(1)
        : parseFloat(0).toFixed(1),
    });
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default ScoresByAnswer;

import React from 'react';
import BaseView from 'views/base';
import classNames from 'classnames';

class LoadingView extends BaseView {
  render() {
    const { size } = this.props;
    const preloaderClasses = classNames({
      preloader: true,
      'preloader--small': size === 'small',
    });
    const spacerClasses = classNames({
      spacer: true,
      'spacer--small': size === 'small',
    });
    return (
      <div role='progressbar' className={preloaderClasses}>
        <div className={spacerClasses} />
        <div className='dot bounce1' />
        <div className='dot bounce2' />
        <div className='dot bounce3' />
      </div>
    );
  }
}

export default LoadingView;

import Backbone from 'backbone';
import QuestionsCollection from 'collections/clients/projects/questions';

var TopicModel = Backbone.Model.extend({
  idAttribute: 'topicId',
  serviceId: 'acs-services',
  initialize: function (model, opts) {
    var options = opts || this.collection.options || {};

    // Add this for child collections to generate path
    options.topicId = this.id;

    if (!this.questions && this.id) {
      this.questions = new QuestionsCollection(false, options);
      this.questions.url = this.url() + '/' + this.id + '/questions';
    }
  },

  toJSON: function (options) {
    var attributes = this.attributes;

    if (this.questions && this.questions.length) {
      this.questions.sort();
      attributes.questions = this.questions.toJSON(options);
      if (attributes.questions) {
        attributes.questions.forEach(function (question) {
          question.rules = JSON.stringify(question.rules);
        });
      }
    } else {
      attributes.questions = [];
    }

    return attributes;
  },

  parse: function (data, options) {
    var questions = data.questions;

    if (questions && !(questions instanceof Array)) {
      questions = [questions];
    }

    if (!this.questions && data.topicId) {
      this.questions = new QuestionsCollection(false, options);
      this.questions.url = this.url() + '/' + data.topicId + '/questions';
    }
    if (questions && questions.length) {
      this.questions.reset(questions, options);
    }

    delete data.questions;

    return data;
  },
});

export default TopicModel;

import Backbone from 'backbone';
import featureFlags from 'core/featureFlags';

const TAKeywordManagerModel = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {},

  /**
   * Constructor
   */
  initialize: function (opts) {
    var options = opts || {};
    this.clientId = options.clientId;
  },

  idAttribute: 'clientId',

  url: function () {
    if (featureFlags.hasFeature('new_ta-admin')) {
      return `ta-admin/clients/${this.clientId}/keywords`;
    } else {
      return `ta-config-beta/clients/${this.clientId}/keywords`;
    }
  },
});

export default TAKeywordManagerModel;

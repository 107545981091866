import React from 'react';
import _ from 'underscore';

import constants from 'core/constants';
import BasePanel from 'views/panels/base';
import store from 'core/store';
import { PublicClientApplication } from '@verint/msal-browser';
import {
  SsoWithRedirect,
  msalConfig,
  msalLoginConfig,
} from 'core/utils/sso-authorization';
import {
  FormSelect,
  FormInput,
  FormButton,
  Banner,
  Text,
  Tabs,
} from 'cx-component-library';
import LoadingView from 'views/widgets/loading';
import TemplatedComponent from 'views/widgets/templated-component';

class ContentAuthView extends BasePanel {
  constructor(props) {
    super(props);

    //Define Actions
    this.actions = {
      'authenticate:failed': 'handleLoginFail',
      'authenticate:aapSuccess': 'redirectAAP',
      'authenticate:tokenExchangFailure': 'handleAccessTokenFail',
    };

    // Set State with initial values
    this.state = {
      selectedTab: 0,
      btnText: this.getString('login.next'),
      icon: false,
      formReady: true,
      errorType: '',
      showPassword: false,
      tabs: [
        {
          children: this.getString('login.predictiveExperience'),
        },
      ],
      auth: {
        username: '',
        password: '',
      },
      VIEW: {
        USERNAME: true,
        PASSWORD: false,
        MULTIPLE_ACCOUNTS: false,
      },
      isLoading: false,
      accountOptions: [],
      options: [],
      AP_TOKEN: '',
      isSSOError: false,
      disableLoginButton: false,
    };
    this.oktaAuth = {};

    this.passwordInput = React.createRef();
    this.usernameInput = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    // get query params
    const params = this._getFragmentObject();

    if (
      params.access_token ||
      params.id_token ||
      params.app_token ||
      params.code
    ) {
      let subjectToken = {};
      if (params.access_token) {
        subjectToken = {
          subjectTokenType: constants.SUBJECT_URN_TOKEN_TYPE,
          subjectToken: params.access_token,
        };
      } else if (params.app_token) {
        subjectToken = {
          subjectTokenType: constants.SUBJECT_URN_TOKEN_TYPE,
          subjectToken: params.app_token,
        };
      } else if (params.id_token) {
        subjectToken = {
          subjectTokenType: constants.SUBJECT_TOKEN_URN_ID_TYPE,
          subjectToken: params.id_token,
        };
        store.set('idToken', params.id_token);
      } else if (params.code) {
        const msalInstance = new PublicClientApplication(msalConfig);
        this._updateState({ isLoading: true });
        msalInstance
          .handleRedirectPromise()
          .then((res) => {
            if (res) {
              this._updateState({ isLoading: true });
              store.set('idToken', res.idToken);
              subjectToken = {
                subjectTokenType: constants.SUBJECT_TOKEN_URN_ID_TYPE,
                subjectToken: res.idToken,
              };
              this.validateAccessToken(subjectToken);
            } else {
            }
          })
          .catch((err) => {
            //throw errors to the console for support purposes
            console.error(err);
          });
      }
      if (!params.code) {
        this.validateAccessToken(subjectToken);
      }
    }

    //extract sso_context_id and auth_token
    // get query params
    const ap_params = this._getQueryObject();
    const SSO_CONTEXT_ID = _.values(_.pick(ap_params, 'sso_context_id'))[0];
    const TOKEN = _.values(_.pick(ap_params, 'oauth_token'))[0];
    const SSO_ERROR = _.values(_.pick(ap_params, 'errorCode'))[0];
    store.remove('xImpersonatedUserId');
    if (ap_params.identityProviderId && ap_params.consumer) {
      //Handle IDP Initiated SSO
      this.setState(() => {
        return {
          isLoading: true,
          selectedTab: ap_params.consumer === 'CX_SUITE' ? 0 : 1,
        };
      }, this.validateSSO(ap_params));
    } else if (SSO_CONTEXT_ID) {
      //select the AAP tab if there SSO_CONTEXT_ID
      this._updateState({
        selectedTab: 1,
        AP_TOKEN: TOKEN,
      });
    } else if (SSO_ERROR) {
      // Redirect the user to SSO_ERROR page
      this._updateState({
        isSSOError: true,
        errorType: 'ssoLoginError',
      });
    } else if (
      !(params.access_token || params.id_token || params.code) &&
      !store.get('idToken') &&
      !this.props.config.user
    ) {
      const msalInstance = new PublicClientApplication(msalConfig);
      msalInstance
        .acquireTokenByIframe(msalLoginConfig)
        .then((res) => {
          if (res) {
            this._updateState({ isLoading: true });
            store.set('idToken', res.idToken);
            this.validateAccessToken({
              subjectTokenType: constants.SUBJECT_TOKEN_URN_ID_TYPE,
              subjectToken: res.idToken,
            });
          }
        })
        .catch((e) => {
          /*
        if there is an error during the auth check, the MSAL library leaves an
        interaction session value of in_progress which causes errors on reload,
        so remove the value and the user can refresh without needing to clear session cache
         */

          //user is not logged in, this is an expected error code so silently ignore
          if (e.name === 'InteractionRequiredAuthError') {
            return;
          }

          let sessionKeys = Object.keys(sessionStorage);
          let interactionKey = sessionKeys.filter(
            (sessionKey) => sessionKey.indexOf('interaction') !== -1
          );
          if (interactionKey && interactionKey.length) {
            sessionStorage.removeItem(interactionKey[0]);
          }
          //throw errors to the console for support purposes
          console.log(e);
        });
    }
  }

  validateSSO(userData) {
    //Check whether SSO is enabled
    //If SSO is not enabled, redirect the user to password screen

    this.publish('authenticate:validateSSO', {
      userData,
      callback: (resp) => {
        const { AP_TOKEN } = this.state;

        const clientAccounts = resp?.identityProviders
          ? resp?.identityProviders
          : [];
        this.setClientAccountOptions(clientAccounts);
        let oktaRedirect = false;
        let vcpEnabled = false;
        const sonomaMigrated = resp?.sonomaMigrated ? true : false;
        const smAuthentication = resp?.smAuthentication ? true : false;

        const smLogin = (sonomaMigrated || smAuthentication) && AP_TOKEN === '';

        if (
          (clientAccounts && clientAccounts.length === 1) ||
          (!!clientAccounts.length &&
            _.filter(
              clientAccounts,
              (clientAccount) => !!clientAccount.vcpEnabled
            ).length === clientAccounts.length)
        ) {
          oktaRedirect =
            clientAccounts[0].ssoEnabled && !clientAccounts[0].vcpEnabled;
          vcpEnabled = clientAccounts[0].vcpEnabled;
        }
        if (smLogin) {
          this._updateState({
            VIEW: {
              USERNAME: true,
              PASSWORD: false,
            },
            isLoader: false,
            icon: true,
            errorType: 'errorDisabled',
            error: true,
            disableLoginButton: true,
          });
        }
        //Check the length of accounts and see If SSO is enabled
        if (oktaRedirect && !sonomaMigrated && !smAuthentication) {
          //call OKTA
          this.redirectOKTA(clientAccounts[0]);
        } else if (vcpEnabled) {
          this.redirectAAD(clientAccounts[0]);
        } else if (
          (clientAccounts.length === 0 && !smLogin) ||
          (clientAccounts.length === 1 &&
            !clientAccounts[0].ssoEnabled &&
            !smLogin)
        ) {
          store.remove('previousRoute');
          //Show the password field if CONSUMER is CX_SUITE
          this._updateState({
            VIEW: {
              USERNAME: true,
              PASSWORD: true,
            },
            isLoader: false,
            icon: true,
            btnText: this.getString('login.login'),
            disableLoginButton: false,
          });
        }
        // If length of acconts is greater than 1, show the dropdown of client accounts
        else if (
          clientAccounts.length > 1 &&
          !sonomaMigrated &&
          !smAuthentication
        ) {
          this._updateState({
            VIEW: {
              USERNAME: true,
              PASSWORD: false,
              MULTIPLE_ACCOUNTS: true,
            },
            isLoader: false,
            icon: true,
            btnText: this.getString('login.next'),
            disableLoginButton: false,
          });
        }
      },
    });
  }

  validateLogin(login) {
    store.remove('previousRoute');
    const payload = {
      previousRoute: this.getPreviousRoute(),
      loginData: {
        username: login.username,
        password: login.password,
      },
    };
    if (this.state.selectedTab === 0) {
      payload.loginData.consumerKey = constants.CONSUMER_KEY;
      payload.loginData.consumerSecret = constants.CONSUMER_SECRET;
      this.publish('authenticate:login', payload);
    } else {
      payload.loginData.requestToken = this.state.AP_TOKEN;
      this.publish('authenticate:authorizeAAP', payload.loginData);
    }
  }

  validateAccessToken({ subjectTokenType, subjectToken }) {
    // Show the loading state
    this._updateState({ isLoading: true, errorType: null, isSSOError: true });
    const ACTOR_TOKEN = this._encodeBase64String(
      `${constants.CONSUMER_KEY}:${constants.CONSUMER_SECRET}`
    );
    const payload = {
      previousRoute: this.getPreviousRoute(),
      accessTokenData: {
        audience: constants.AUDIENCE,
        subject_token: subjectToken,
        subject_token_type: subjectTokenType,
        grant_type: constants.GRANT_TYPE,
        actor_token: ACTOR_TOKEN,
        actor_token_type: constants.ACTOR_TOKEN_TYPE,
      },
    };
    //remove previouRoute from local storage
    store.remove('previousRoute');
    this.publish('authenticate:validateToken', payload);
  }

  handleBack = () => {
    this._updateState({
      isLoading: false,
      isSSOError: false,
    });
    this.publish('route:change', {
      route: 'login',
    });
  };

  handleLoginSubmit = (e) => {
    e.preventDefault();

    if (this.state.formReady) {
      // Update state
      this.setFormState(false);
      const login = this.getState('auth');
      // Get selected client account
      const selectedAccount = _.find(
        this.state.accountOptions,
        (account) => account.selected === true
      );

      //fetch VIEW NAME
      if (
        login.username !== '' &&
        this.state.VIEW.USERNAME &&
        !this.state.VIEW.PASSWORD &&
        login.password === '' &&
        !selectedAccount
      ) {
        //call IDP Discovery API and display PASSWORD view in the success callback

        this.validateSSO({
          username: login.username,
          consumer:
            this.state.selectedTab === 1
              ? constants.CONSUMERS[1]
              : constants.CONSUMERS[0],
        });
      } else if (
        this.state.VIEW.PASSWORD &&
        login.username &&
        login.password &&
        !selectedAccount
      ) {
        this.validateLogin(login);
      } else if (this.state.VIEW.MULTIPLE_ACCOUNTS && selectedAccount) {
        //Get client account
        const clientAccount = _.find(
          this.state.options,
          (account) => account.clientName === selectedAccount.value
        );
        //this.setFormState(true);
        //If ssoEnabled is true, redirect to OKTA
        if (clientAccount && clientAccount.vcpEnabled) {
          this.redirectAAD(clientAccount);
        } else if (clientAccount && clientAccount.ssoEnabled) {
          //Call Okta
          this.redirectOKTA(clientAccount);
        } else {
          this.validateLogin(login);
        }
      } else {
        this.setFormState(true, true);
      }
    }
  };

  handleFieldChange = (e, value) => {
    let auth = this.getState('auth');
    auth[e.target.name] = e.target.value;
    this._updateState({ auth: auth });
  };

  handleToggleIcon = (e) => {
    this.passwordInput && this.passwordInput.current.select();
    this.setState({ showPassword: !this.state.showPassword });
  };
  focusTextInput = (e) => {
    this.usernameInput && this.usernameInput.current.select();
    this.handleReset();
  };
  handleReset = (e) => {
    const login = this.getState('auth');
    this._updateState({
      errorType: '',
      error: false,
      icon: false,
      VIEW: {
        USERNAME: true,
        PASSWORD: false,
        MULTIPLE_ACCOUNTS: false,
      },
      auth: {
        username: login.username,
        password: '',
      },
      showPassword: false,
      options: [],
      accountOptions: [],
      btnText: this.getString('login.next'),
      disableLoginButton: false,
    });
  };

  handleAccountChange = (item) => {
    //Show/Hide Password field if SSO is enabled or not
    let accounts = this.state.accountOptions.map((account) => {
      return {
        id: account.id,
        label: account.label,
        value: account.value,
        selected: account.value === item.value ? true : false,
        showPassword: account.showPassword,
      };
    });

    // Get selected client account
    const selectedAccount = _.find(
      accounts,
      (account) => account.selected === true
    );

    this._updateState({
      accountOptions: accounts,
      VIEW: {
        USERNAME: true,
        PASSWORD: selectedAccount.showPassword,
        MULTIPLE_ACCOUNTS: true,
      },
      btnText: selectedAccount.showPassword
        ? this.getString('login.login')
        : this.getString('login.next'),
      disableLoginButton: false,
    });
  };

  handleForgotPassword = (event) => {
    event.preventDefault();
    this.publish('route:change', { route: '/password/forgot' });
  };

  handleLoginFail(payload) {
    this.setFormState(true, true, payload);
  }

  handleAccessTokenFail() {
    this._updateState({
      isLoading: false,
      errorType: 'ssoLoginError',
      isSSOError: true,
    });
    store.remove('idToken');
  }

  getPreviousRoute() {
    const previouRoute = store.get('previousRoute');
    return this.props.userSessionExpired
      ? this.props.config.previousRoute
      : previouRoute === null
      ? null
      : previouRoute;
  }

  setFormState(enabled, error, errResponse) {
    const formError = error || false;
    const errorMessageType = this.getErrorMessage(errResponse);

    if (error) {
      const auth = this.getState('auth');
      auth.password = '';

      this._updateState({ auth });
    }

    if (errorMessageType !== null) {
      this._updateState({
        formReady: true,
        errorType: errorMessageType,
        isLoader: !formError,
        error: formError,
        btnText: this.getString('login.login'),
      });
    } else if (enabled) {
      this._updateState({
        formReady: true,
        error: formError,
        errorType: 'error',
        isLoader: !formError,
        btnText: this.getString('login.login'),
        disableLoginButton: false,
      });
    } else {
      this._updateState({
        error: formError,
        errorType: null,
        isLoader: !formError,
        btnText: '',
        disableLoginButton: false,
      });
    }
  }

  getErrorMessage(errResponse) {
    let message = null;
    if (errResponse && errResponse.responseText) {
      try {
        const parsedResponse = JSON.parse(errResponse.responseText);
        message = parsedResponse && parsedResponse.message;
      } catch (e) {
        message = '500: Network error / Server IDP';
      }
    }

    switch (message) {
      case '401.1: Bad Credentials':
        return 'error';
      case '401.2: Credentials expired':
        return 'errorExpiredPassword';
      case '401.3: User account is locked':
        return 'errorLockedAccount';
      case '401.4: User is disabled':
        return 'errorDisabledAccount';
      case '401.5: Account expired':
        return 'errorExpiredAccount';
      case '404: Communication between front and backend failure':
        return 'errorCommunication';
      case '500: Network error / Server IDP':
        return 'errorNetwork';
      case 'SSO Error':
        return 'ssoLoginError';
      default:
        return null;
    }
  }

  /**
   * @name setClientAccountOptions
   * @param {any} clientAccounts
   * @memberof ContentAuthView
   * @description Sets the client accounts to display in dropdown
   */
  setClientAccountOptions(clientAccounts) {
    const accounts = clientAccounts.map((account) => {
      return {
        id: account.identityProviderId,
        label: account.clientName,
        value: account.clientName,
        selected: false,
        showPassword: account.ssoEnabled ? false : true,
      };
    });
    this._updateState({ accountOptions: accounts, options: clientAccounts });
  }

  /**
   * @name redirectOKTA
   * @param {any} clientAccount
   * @memberof ContentAuthView
   * @description Redirect the user to OKTA Login Page
   */
  redirectOKTA(clientAccount) {
    // Save the previous route in localstorage
    (this.props.userSessionExpired ||
      this.props.userSessionExpired === false) &&
      this.props.config.previousRoute !== 'login' &&
      store.set('previousRoute', this.props.config.previousRoute);

    const clientAccountParams = this._getQueryObject(
      clientAccount.defaultParams
    );
    let responseType = this.state.selectedTab === 0 ? 'token' : 'code';
    let responseMode = this.state.selectedTab === 0 ? 'fragment' : 'query';
    if (clientAccount.vcpEnabled) {
      responseType = clientAccountParams.response_type;
    }

    const redirectUri =
      document.location.href === '!!_localHostURL_!!'
        ? '!!_localHostURL_!!'
        : clientAccount.defaultRedirectUrl;

    SsoWithRedirect({
      clientId: clientAccount.clientId,
      authorizeUrl: clientAccount.baseAuthRequestUrl,
      identityProviderId: clientAccount.identityProviderId,
      redirectUri,
      responseType: responseType,
      scopes: clientAccountParams.scope,
      loginHint: clientAccountParams.login_hint || '',
      responseMode,
    });
  }

  /**
   * @name redirectAAP
   * @param {any} response
   * @memberof ContentAuthView
   * @description Redirect the user to AAP after successfull authentication
   */
  redirectAAP(response) {
    window.location = `${response.redirectUrl}?oauth_token=${response.token}&oauth_verifier=${response.verifier}`;
  }

  /**
   * @name redirectOKTA
   * @param {any} clientAccount
   * @memberof ContentAuthView
   * @description Redirect the user to OKTA Login Page
   */
  redirectAAD(clientAccount) {
    // Save the previous route in localstorage
    (this.props.userSessionExpired ||
      this.props.userSessionExpired === false) &&
      this.props.config.previousRoute !== 'login' &&
      store.set('previousRoute', this.props.config.previousRoute);
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.loginRedirect({
      ...msalLoginConfig,
      loginHint: this.state.auth.username,
    });
  }

  renderLoginOptions() {
    return (
      <Tabs
        tabs={this.state.tabs}
        selectedIndex={this.state.selectedTab}
        tabStyle='underline'
      />
    );
  }

  renderLoginScreen() {
    return (
      <div id='login'>
        {this.renderLoginOptions()}
        {this.state.error && (
          <div className='login__error gutter--top'>
            <Banner
              border
              text={
                <TemplatedComponent
                  string={'login.' + this.state.errorType}
                  tokens={{
                    link: (
                      <Text
                        target='_blank'
                        type='a'
                        href='https://connect.verint.com/'
                      >
                        {this.getString('general.serviceName')}
                      </Text>
                    ),
                  }}
                />
              }
              theme='error'
            />
          </div>
        )}
        <FormInput
          className={
            this.state.icon
              ? 'gutter--bottom gutter--top login__input cursor'
              : 'gutter--bottom gutter--top login__input'
          }
          size='xlarge'
          placeholder={this.getString('login.username')}
          onChange={this.handleFieldChange}
          onClick={this.handleReset}
          value={this.state.auth.username}
          name='username'
          id='username'
          ref={this.usernameInput}
          onIconClick={this.focusTextInput}
          icon={this.state.icon ? 'edit' : ''}
          autoFocus
        />
        {this.state.VIEW.MULTIPLE_ACCOUNTS && (
          <FormSelect
            className='gutter--bottom'
            size='xlarge'
            theme='bordered'
            defaultLabel={this.getString('login.selectLabel')}
            options={this.state.accountOptions}
            onItemClick={this.handleAccountChange}
          />
        )}
        {this.state.VIEW.PASSWORD && (
          <div>
            <FormInput
              className={
                this.state.showPassword
                  ? 'gutter--bottom gutter--top login__input text'
                  : 'gutter--bottom gutter--top login__input'
              }
              size='xlarge'
              placeholder={this.getString('login.password')}
              onChange={this.handleFieldChange}
              value={this.state.auth.password}
              ref={this.passwordInput}
              name='password'
              id='password'
              icon='preview'
              onIconClick={this.handleToggleIcon}
              type={this.state.showPassword ? 'text' : 'password'}
              autoFocus
            />
          </div>
        )}
        <div className='login__buttons'>
          {!this.state.disableLoginButton && (
            <FormButton
              type='submit'
              label={this.state.btnText}
              size='xlarge'
              icon={this.state.isLoader ? 'loader' : ''}
              iconSpin={this.state.isLoader}
            />
          )}
        </div>
        <div className='login__buttons'>
          <ul className='login__sublinks'>
            {this.state.VIEW.PASSWORD && (
              <li className='login__sublink pull-left'>
                <Text
                  type='a'
                  href='/client/password/forgot'
                  onClick={this.handleForgotPassword}
                >
                  {this.getString('login.forgotPassword')}
                </Text>
              </li>
            )}
            <li className='login__sublink pull-right'>
              <Text
                type='a'
                href='http://www.foresee.com/about-us/privacy-policy/'
                target='_blank'
                rel='noopener noreferrer'
              >
                {this.getString('login.privacy')}
              </Text>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  render() {
    return (
      <form onSubmit={this.handleLoginSubmit}>
        <div className='login login--desktop'>
          <div className='grid--full'>
            <div className='grid__item'>
              {this.props.userSessionExpired && (
                <Text className='login__header--error'>
                  {this.getString('security.expiredSession')}
                </Text>
              )}
              <div className='login__header'>
                <div className='logo-image' />
              </div>
            </div>

            <div className='grid__item' style={{ marginTop: '0' }}>
              <div className='login__content login__content--desktop'>
                {!this.state.isLoading &&
                  !this.state.isSSOError &&
                  this.state.VIEW.USERNAME &&
                  this.renderLoginScreen()}

                {(this.state.isLoading || this.state.isSSOError) && (
                  <div>
                    {this.state.errorType && (
                      <Text className='gutter--bottom' theme='h3'>
                        <TemplatedComponent
                          string={'login.' + this.state.errorType}
                          tokens={{
                            link: (
                              <Text
                                theme='h3'
                                className='gutter-half--bottom'
                                color='error'
                                target='_blank'
                                type='a'
                                href='https://connect.verint.com/'
                              >
                                {this.getString('general.serviceName')}
                              </Text>
                            ),
                          }}
                        />
                      </Text>
                    )}
                    {this.state.isLoading && <LoadingView />}

                    <div className='login__back gutter--bottom'>
                      {!this.state.isLoading && (
                        <FormButton
                          className='gutter--top gutter--bottom button-wide'
                          icon='arrowLeft'
                          theme='secondary'
                          label='Back to Login'
                          onClick={this.handleBack}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ContentAuthView;

import Backbone from 'backbone';
import CustomFeedModel from 'models/clients/text-analytics/custom-feed';
import featureFlags from 'core/featureFlags';

var CustomFeedsCollection = Backbone.Collection.extend({
  model: CustomFeedModel,

  initialize: function (models, opts) {
    this.clientId = opts.clientId;
  },

  parse: function (data) {
    return data.clientCustomFeedList;
  },
  url: function () {
    if (featureFlags.hasFeature('new_ta-admin')) {
      return `ta-admin/clients/` + this.clientId + '/customFeed';
    } else {
      return `ta-config-beta/clients/` + this.clientId + '/customFeed';
    }
  },
});

export default CustomFeedsCollection;

import AppController from 'controllers/app';
import RoutesController from 'controllers/routes';
import AuthenticateController from 'controllers/authenticate';
import AuthorizationController from 'controllers/authorization';
import ResponseController from 'controllers/response-status';
import PostMessageController from 'controllers/post-message';
import ProjectsController from 'controllers/projects';
import UserController from 'controllers/user';
import FeedbackSurveyController from 'controllers/feedback-survey';
import FeedbackSettingsController from 'controllers/feedback-settings';
import PublishingController from 'controllers/publishing';
import InsightsController from 'controllers/insights';
import LeaderboardsController from 'controllers/leaderboards';
import ReportsController from 'controllers/reports';
import AlertController from 'controllers/alert';
import ConfigController from 'controllers/config';
import HierarchyController from 'controllers/hierarchy';
import MasterDataController from 'controllers/master-data';
import MeasuresController from 'controllers/measures';
import CXMeasureController from 'controllers/cxmeasure';
import CollectionEventsController from 'controllers/collection-events';
import DatesController from 'controllers/dates';
import FiltersController from 'controllers/filters';
import EventFiltersController from 'controllers/event-filters';
import HierarchyFiltersController from 'controllers/hierarchy-filters';
import HostedCodeController from 'controllers/hosted-code';
import PageFiltersController from 'controllers/page-filters';
import TAFiltersController from 'controllers/ta-filters';
import StorageController from 'controllers/storage';
import NotificationsController from 'controllers/notifications';
import FilesController from 'controllers/files';
import ExportController from 'controllers/export';
import TextAnalyticsController from 'controllers/text-analytics';
import TextAnalyticsConfigController from 'controllers/text-analytics-config';
import SettingsController from 'controllers/settings';
import UploadsController from 'controllers/uploads';
import WhitelistController from 'controllers/whitelist';
import DashboardController from 'controllers/dashboards';

import ApiAdministrationController from 'controllers/api-administration';

import DashboardDataController from 'controllers/dashboards-data';
import AuthorizedMeasureController from 'controllers/authorized-measure';
import CustomDataSourcesController from 'controllers/custom-data-sources';
import SurveysController from 'controllers/surveys';

var Controllers = {
  app: AppController,
  'authorized-measure': AuthorizedMeasureController,
  routes: RoutesController,
  authenticate: AuthenticateController,
  authorization: AuthorizationController,
  'response-status': ResponseController,
  'post-message': PostMessageController,
  projects: ProjectsController,
  user: UserController,
  'feedback-survey': FeedbackSurveyController,
  'feedback-settings': FeedbackSettingsController,
  publishing: PublishingController,
  insights: InsightsController,
  leaderboards: LeaderboardsController,
  reports: ReportsController,
  alert: AlertController,
  config: ConfigController,
  hierarchy: HierarchyController,
  'master-data': MasterDataController,
  cxmeasure: CXMeasureController,
  'collection-events': CollectionEventsController,
  dates: DatesController,
  measures: MeasuresController,
  filters: FiltersController,
  'event-filters': EventFiltersController,
  hierarchyfilters: HierarchyFiltersController,
  'hosted-code': HostedCodeController,
  pagefilters: PageFiltersController,
  tafilters: TAFiltersController,
  storage: StorageController,
  notifications: NotificationsController,
  files: FilesController,
  export: ExportController,
  'text-analytics': TextAnalyticsController,
  'text-analytics-config': TextAnalyticsConfigController,
  settings: SettingsController,
  uploads: UploadsController,
  whitelist: WhitelistController,
  dashboards: DashboardController,
  'api-administration': ApiAdministrationController,
  'dashboards-data': DashboardDataController,
  'custom-data-sources': CustomDataSourcesController,
  surveys: SurveysController,
};

export default Controllers;

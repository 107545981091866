import Backbone from 'backbone';
import _ from 'underscore';
import UploadProcessesModel from 'models/clients/upload/processes';

const UploadProcessesCollection = Backbone.Collection.extend({
  model: UploadProcessesModel,
});
const UploadProcessesPageableModel = Backbone.Model.extend({
  defaults: {
    total: 0,
    results: [],
  },

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.uploadType = options.uploadType || 'combo';
    this.limit = options.limit || 10;
    this.offset = options.offset || 0;
    this.status = options.status;
  },

  parse: function (data) {
    return {
      results: new UploadProcessesCollection(data, { parse: true }),
      total: data.length,
    };
  },

  toJSON: function () {
    return _.extend(this.attributes, {
      results: this.attributes.results.toJSON(),
    });
  },

  getUserIds: function () {
    return this.get('results').map((result) => {
      return result.get('startedBy');
    });
  },

  setUsers: function (users) {
    this.get('results').forEach((result) => {
      const user = _.findWhere(users, { userId: result.get('startedBy') });
      result.set('startedByUser', user);
    });
  },

  url: function () {
    let query = {
      limit: this.limit,
      offset: this.offset,
    };
    if (this.status) {
      query.status = this.status;
    }
    return `clients/${this.client}/upload/${
      this.uploadType
    }/processes?${new URLSearchParams(query)}`;
  },
});

export default UploadProcessesPageableModel;

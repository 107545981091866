import $ from 'jquery';
import _ from 'underscore';
import { cloneDeep } from 'lodash';
import constants from 'core/constants';
import BaseController from 'controllers/base';
import FilterModel from 'models/clients/measures/model-filter';
import FilterDetailsModel from 'models/clients/measures/model-filter-details';
import FiltersCollection from 'collections/clients/measures/model-filters';
import i18n from 'core/i18n';

/**
 * Controller for manipulating filters
 */
class FiltersController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'filters',
      actions: {
        'measures:currentMeasure': 'setCurrentMeasure',
        'filters:save': 'saveFilter',
        'filters:update': 'updateFilter',
        'filters:delete': 'deleteFilter',
        'filters:set': 'setFilter',
        'filters:get': 'getFilters',
        'filters:details:get': 'getFilterById',
        'filters:extend': 'getFilterByIdAndExtend',
        'filters:reset': 'resetFilter',
        'filters:reset:noaction': 'resetFilterNoAction',
        'filters:chip:add': 'addFilterChip',
        'filters:chips:add': 'addMultipleFilterTerms',
        'filters:chip:edit': 'editFilterChip',
        'filters:chip:remove': 'removeFilterChip',
        'filters:operator:set': 'setFilterOperator',
        'filters:term:add': 'addTermToFilter',
        'filters:term:edit': 'editTermFilter',
      },
      dependsOn: ['user'],
    });
  }

  initialize(opts) {
    // global filterm model
    this.client = this.getClientId();
    this.cxFilter = new FilterDetailsModel();
  }

  cloneFilter(filter) {
    const filterDetailsModel = new FilterDetailsModel(
      _.clone(filter.attributes)
    );

    if (filter.measure) {
      filterDetailsModel.measure = filter.measure;
    }

    // strip ids
    filterDetailsModel.unset('modelFilterId');
    filterDetailsModel.unset('createDateTime');
    filterDetailsModel.unset('vndType');
    filterDetailsModel.unset('links');
    filterDetailsModel.set('filterType', 'ADHOC');

    const terms = filterDetailsModel.get('terms');
    for (let i = 0; i < terms.length; i++) {
      const term = terms[i];
      delete terms.id;
      if (term.conditions) {
        for (let j = 0; j < term.conditions.length; j++) {
          delete term.conditions[j].id;
        }
      }
    }

    return filterDetailsModel;
  }

  deleteFilter(payload) {
    const { filter, callback, measurementKey } = payload;
    const options = {
      clientId: this.client,
      measure: measurementKey || this.currentMeasure.measurementKey,
    };

    const filterModel = new FilterModel(filter, options);
    filterModel.destroy().then(() => {
      if (callback) {
        callback(filter);
      }
      this.publish('filters:filter:deleted', filter);
    });
  }

  updateFilter(replacementFilter) {
    const options = {
      clientId: this.client,
      measure:
        replacementFilter.measurementKey ||
        this.cxFilter.measure ||
        this.currentMeasure.measurementKey,
    };

    this.cxFilter.set('modelFilterId', replacementFilter.modelFilterId);
    this.cxFilter.set('name', replacementFilter.name);
    this.cxFilter.set('filterType', 'ACTIVE');
    this.cxFilter.set('version', replacementFilter.version);

    this.cxFilter = new FilterDetailsModel(this.cxFilter.toJSON(), options);
    this.cxFilter.save().then(() => {
      this.publish('filters:filter:update', this.cxFilter.toJSON());
      this.publish('filters:filter:new');
    });
  }

  saveFilter(filterName) {
    const options = {
      clientId: this.client,
      measure: this.cxFilter.get('measurementKey'),
    };

    this.cxFilter.set('name', filterName);
    this.cxFilter.set('filterType', 'ACTIVE');
    this.cxFilter.set('temp', false);

    const filterModel = new FilterModel(this.cxFilter.toJSON(), options);
    filterModel.save().then(() => {
      this.cxFilter.fetch().then(() => {
        this.publish('filters:filter:changed', this.cxFilter.toJSON());
        this.publish('filters:filter:new');
      });
    });
  }

  saveAdhocFilter(filter, params = {}) {
    const { setFilter = true, callback } = params;
    const options = {
      clientId: this.client,
      measure: filter.measure || this.currentMeasure.measurementKey,
    };

    const filterModel = new FilterModel(filter.toJSON(), options);
    filterModel.save().then((data) => {
      const newFilter = new FilterDetailsModel(filterModel.toJSON(), options);
      if (setFilter) {
        this.cxFilter = newFilter;
        this.cxFilter.fetch().then(() => {
          let cxFilter = this.cxFilter.toJSON();
          this.getChips(cxFilter.terms, cxFilter.measurementKey).then(
            (data) => {
              this.publish('filters:filter:changed', {
                ...cxFilter,
                chips: data,
              });
            }
          );
        });
      }
      if (callback) {
        callback(data);
      }
    });
  }

  fetchChipByType = (chip, measurementKey) =>
    new Promise((resolve, reject) => {
      const newChip = { ...chip };
      switch (chip.type) {
        case constants.FILTER_TYPE_TEXT:
        case constants.FILTER_TYPE_QUESTION:
          this.publish('measures:questions:question:get', {
            retryIfUninitialized: true,
            measurementKey: measurementKey,
            questionKey: chip.questionKey,
            callback: (success, data) => {
              if (success) {
                const question = data.question;
                newChip.title = question.label;
                newChip.filterData = question;
              } else {
                newChip.name = i18n.getString('dashboards.filterUnavailable');
                newChip.description = '';
              }
              resolve(newChip);
            },
          });
          break;
        case constants.FILTER_TYPE_LATENT:
          this.publish('measures:latents:latent:get', {
            retryIfUninitialized: true,
            measurementKey: measurementKey,
            latentKey: chip.latentKey,
            callback: (data) => {
              const latent = data.latent;
              newChip.title = latent.name;
              newChip.filterData = latent;
              resolve(newChip);
            },
          });
          break;
        case constants.FILTER_TYPE_REPLAY:
          newChip.filterData = newChip.replayAttribute;
          resolve(newChip);
          break;
        default:
          resolve();
          return;
      }
    });

  async getChips(chips, measurementKey) {
    let chipsArray = [];
    for (const chip of chips) {
      if (chip.id) {
        let newChip = await this.fetchChipByType(chip, measurementKey);
        chipsArray.push(newChip);
      }
    }
    return chipsArray;
  }

  setCurrentMeasure(measure) {
    this.currentMeasure = measure;
  }

  setFilter(payload) {
    const { isInitialization, noReload, ...filter } = payload;
    const options = {
      clientId: this.client,
      measure: filter.measurementKey || this.currentMeasure.measurementKey,
    };
    this.cxFilter = new FilterDetailsModel(filter, options);
    this.cxFilter
      .fetch({
        error: () => {},
      })
      .then(() => {
        let cxFilter = this.cxFilter.toJSON();
        this.getChips(cxFilter.terms, cxFilter.measurementKey).then((data) => {
          this.publish('filters:filter:changed', {
            ...cxFilter,
            chips: data,
            noReload,
            isInitialization,
          });
        });
      });
  }

  getFilterById(filter) {
    const options = {
      clientId: this.client,
      measure: filter.measurementKey,
    };
    const model = new FilterDetailsModel(filter, options);
    const errorHandler = {
      error: () => {},
    };
    model.fetch(errorHandler).then(() => {
      this.publish('app:getData', {
        key: 'modelFilterDetails',
        callback: (currentModelFilterDetails) => {
          const modelFilterDetail = model.toJSON();
          const modelFilterDetails = cloneDeep(currentModelFilterDetails) || {};
          modelFilterDetails[model.get('modelFilterId')] = modelFilterDetail;
          this.publish('app:updateData', { modelFilterDetails });
          if (filter.callback) {
            filter.callback(modelFilterDetail);
          }
        },
      });
    });
  }

  getFilterByIdAndExtend({ newTerms = [], ...filter }) {
    const options = {
      clientId: this.client,
      measure: filter.measurementKey,
    };
    const model = new FilterDetailsModel(filter, options);
    model
      .fetch({
        error: () => {},
      })
      .then(() => {
        const modelFilterDetail = model.toJSON();
        const questionIds = new Set(
          newTerms.map((term) => `${term.question.questionKey}`)
        );

        const filteredTerms = (modelFilterDetail?.terms || []).filter(
          (curr) => !questionIds.has(`${curr.questionKey}`)
        );

        this.addMultipleFilterTerms({
          measurementKey: filter.measurementKey,
          existingTerms: filteredTerms,
          newTerms,
          augmentExisting: false,
        });
      });
  }

  getFilters(payload) {
    const { callback, measurementKey } = payload;
    const options = {
      clientId: this.client,
      measure:
        measurementKey ||
        this.cxFilter.get('measurementKey') ||
        this.currentMeasure.measurementKey,
    };

    const filters = new FiltersCollection(options);

    filters.fetch().then(() => {
      const defs = [];

      filters.each((model, index) => {
        const def = new $.Deferred();
        defs.push(def);

        model.addOptions(options);
        model.fetch().then(() => {
          def.resolve(model);
        });
      });

      $.when.apply(null, defs).then(() => {
        if (callback) {
          callback(filters.toJSON());
        } else {
          this.publish('filters:get:data', filters.toJSON());
        }
      });
    });
  }

  buildChipTerm(payload) {
    const {
      filterType,
      respType,
      question,
      selectedItems,
      latent,
      operator,
      replayItem,
      replayName,
    } = payload;

    let { value, higherValue } = payload;

    const valueNum = parseInt(value, 10);
    const higherValueNum = parseInt(higherValue, 10);

    const newValue = !higherValueNum
      ? valueNum
      : valueNum > higherValueNum
      ? higherValueNum
      : valueNum;
    const newHigherValue =
      !higherValueNum || operator !== constants.OPERATOR_BETWEEN
        ? null
        : valueNum > higherValueNum
        ? valueNum
        : higherValueNum;

    const getDescription = (operator, value, higherValue) => {
      if (!higherValue) {
        return `${constants.OPERATOR_MAPPING[operator]} ${value}`;
      }
      return `${constants.OPERATOR_MAPPING[operator]} ${value} and ${higherValue}`;
    };

    const buildAnswerConditions = (answers) => {
      const conditions = [];
      for (let i = 0; i < answers.length; i++) {
        const answer = answers[i];
        if (Array.isArray(answer.value)) {
          answer.value.forEach((a) => {
            conditions.push({ answerKey: a.answerKey });
          });
        } else {
          conditions.push({ answerKey: answer.value.answerKey });
        }
      }
      return conditions;
    };

    const buildQuestionTerm = (respType) => {
      switch (respType) {
        case constants.QT_STAR_RATING_DONT_KNOW:
        case constants.QT_RADIOBUTTON_THREE:
        case constants.QT_RADIOBUTTON_TWO:
        case constants.QT_RADIOBUTTON:
        case constants.QT_STAR_RATING:
        case constants.QT_DROPDOWN:
        case constants.QT_CHECKBOX:
        case constants.QT_CHECKBOX_TWO:
        case constants.QT_CHECKBOX_THREE:
        case constants.QT_MEASURED:
          return {
            name: question.label,
            description: `= ${_.pluck(selectedItems, 'label').join(', ')}`,
            type: filterType,
            questionKey: question.questionKey,
            conditions: buildAnswerConditions(selectedItems),
          };
        case constants.QT_NUMERIC:
        case constants.QT_TEXT:
        case constants.QT_OPENENDS:
          return {
            name: question.label,
            description: ``,
            type: filterType,
            questionKey: question.questionKey,
            textOperator: operator,
            textQuery: value,
          };
        default:
          return {};
      }
    };

    const buildReplayTerm = () => {
      const replayType = constants.REPLAY_ATTRIBUTES[replayItem].type;
      switch (replayType) {
        case constants.REPLAY_TYPE_NUMBER:
          return {
            name: replayName,
            description: getDescription(operator, newValue, newHigherValue),
            replayAttribute: replayItem,
            type: filterType,
            operator,
            value: newValue,
            higherValue: newHigherValue,
          };
        case constants.REPLAY_TYPE_TEXT:
          return {
            name: replayName,
            description: getDescription(operator, value),
            replayAttribute: replayItem,
            type: filterType,
            textOperator: operator,
            textQuery: value,
          };
        default:
          break;
      }
    };

    let term = {};
    switch (filterType) {
      case constants.FILTER_TYPE_REPLAY:
        term = buildReplayTerm();
        break;
      case constants.FILTER_TYPE_TEXT:
      case constants.FILTER_TYPE_QUESTION:
        term = buildQuestionTerm(respType);
        break;
      case constants.FILTER_TYPE_LATENT:
        term = {
          name: latent.name,
          description: getDescription(operator, newValue, newHigherValue),
          latentKey: latent.latentKey,
          type: filterType,
          operator,
          value: newValue,
          higherValue: newHigherValue,
        };
        break;
      default:
        break;
    }
    return term;
  }

  setFilterOperator(operator) {
    this.cxFilter.set('operator', operator);
    this.cxFilter = this.cloneFilter(this.cxFilter);
    this.cxFilter.set(
      'name',
      `Untitled (${this.cxFilter.get('terms').length})`
    );
    this.saveAdhocFilter(this.cxFilter);
  }

  addFilterChip(payload) {
    this.cxFilter.measure = payload.measurementKey;
    const term = this.buildChipTerm(payload);
    console.log('addFilterChip', this.cxFilter);
    this.publish('filters:filter:term:added', term);
    this.addTermToFilter(term);
  }

  addMultipleFilterTerms(payload) {
    const {
      newTerms = [],
      measurementKey,
      augmentExisting = true,
      existingTerms = [],
      setFilter = true,
      callback = () => {},
    } = payload;
    const filterClone = augmentExisting
      ? this.cloneFilter(this.cxFilter)
      : new FilterDetailsModel();
    const params = {
      setFilter,
      callback,
    };
    let terms = filterClone.get('terms');

    terms = terms.concat(existingTerms);

    //Check for existing terms.
    newTerms.forEach((t) => {
      const existingTerm = terms.find((e) => {
        return e.questionKey === t.question.questionKey;
      });
      if (existingTerm) {
        const existingAnswer = existingTerm.conditions.find((a) => {
          return a.answerKey === t.selectedItems[0].value.answerKey;
        });
        if (!existingAnswer) {
          existingTerm.push(t.selectedItems[0].value.answerKey);
        }
      } else {
        const term = this.buildChipTerm(t);
        terms.push(term);
      }
    });

    //Set new filter values
    filterClone.set('name', `Untitled (${terms.length})`);
    if (!augmentExisting) {
      filterClone.set('terms', terms);
      filterClone.set('filterType', 'ADHOC');
    }
    filterClone.measure = filterClone.measure || measurementKey;

    this.saveAdhocFilter(filterClone, params);
  }

  addTermToFilter(term) {
    this.cxFilter = this.cloneFilter(this.cxFilter);
    const terms = this.cxFilter.get('terms');
    terms.push(term);

    this.cxFilter.set('name', `Untitled (${terms.length})`);
    this.saveAdhocFilter(this.cxFilter);
  }

  editFilterChip(payload) {
    const term = this.buildChipTerm(payload);
    term.id = payload.id;

    this.publish('filters:filter:term:edited', term);
    this.editTermFilter(term);
  }

  editTermFilter(term) {
    const index = _.findIndex(this.cxFilter.get('terms'), (obj) => {
      return obj.id === term.id;
    });

    if (index > -1) {
      this.cxFilter = this.cloneFilter(this.cxFilter);
      const terms = this.cxFilter.get('terms');
      delete term.id;
      terms[index] = term;

      this.cxFilter.set('name', `Untitled (${terms.length})`);
      this.saveAdhocFilter(this.cxFilter);
    }
  }

  removeFilterChip(termId) {
    this.publish('filters:filter:term:removed', termId);
    this.removeTermFilter(termId);
  }

  removeTermFilter(termId) {
    const index = _.findIndex(this.cxFilter.get('terms'), (obj) => {
      return obj.id === termId;
    });
    if (index > -1) {
      this.cxFilter = this.cloneFilter(this.cxFilter);
      const terms = this.cxFilter.get('terms');
      terms.splice(index, 1);

      if (terms.length > 0) {
        this.cxFilter.set('name', `Untitled (${terms.length})`);
        this.saveAdhocFilter(this.cxFilter);
      } else {
        this.cxFilter.reset();
        this.publish('filters:filter:changed', this.cxFilter.toJSON());
      }
    }
  }

  resetFilterNoAction() {
    this.cxFilter.reset();
  }

  resetFilter(payload = {}) {
    const { noReload, callback, isInitialization } = payload;
    const reset = () => {
      return new Promise((resolve) => {
        this.cxFilter.reset();
        resolve();
      });
    };
    reset().then(() => {
      if (callback) {
        callback();
      }
    });
    this.publish('filters:filter:changed', {
      ...this.cxFilter.toJSON(),
      isInitialization,
      noReload,
    });
  }
}

export default FiltersController;

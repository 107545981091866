import Backbone from 'backbone';
import FilterModel from 'models/clients/measures/model-filter';

var FiltersCollection = Backbone.Collection.extend({
  model: FilterModel,

  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/modelfilters`;
  },
});

export default FiltersCollection;

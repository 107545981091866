import Backbone from 'backbone';

const CapabilityModel = Backbone.Model.extend({
  initialize: function (models, opts) {
    var options = opts || {};
    this.clientId = options.clientId;
  },

  url: function () {
    return `authorization-service/clients/${this.clientId}/capabilities`;
  },
});

export default CapabilityModel;

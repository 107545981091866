export default {
  hasValidTabs(view, baseRouteObj) {
    return this.getTabsWithValidPermissions(view, baseRouteObj).length > 0;
  },

  hasPermission(view, item) {
    const { permissions } = item;
    const hasPermission = !permissions || view.userRole(permissions);
    return hasPermission;
  },

  getLastChildRoute(view, parent, baseRoute) {
    const { tabs } = baseRoute;
    if (!tabs) {
      return parent;
    }

    const tab = tabs[0];
    const newParent = tab.routes[0];

    let path = '/';
    let route = baseRoute;
    if (tab.parentRoute) {
      path += tab.parentRoute + '/';
      route = route[tab.parentRoute];
    }

    return (
      parent + path + this.getLastChildRoute(view, newParent, route[newParent])
    );
  },

  getTabsWithValidPermissions(view, baseRouteObj, primaryTabs) {
    const tabs = baseRouteObj.tabs || primaryTabs;
    if (!tabs) {
      return [];
    }

    const validTabs = [];
    for (let i = 0; i < tabs.length; i++) {
      const tab = view.clone(tabs[i]);

      // check tab parents route permissions
      if (tab.parentRoute) {
        const parentRouteObj = baseRouteObj[tab.parentRoute];

        // not a valid tab [ do not add to list ]
        if (!this.hasPermission(view, parentRouteObj)) {
          continue;
        }
      }

      // loop through all tab routes
      // pop off route if it doesnt have valid permissions
      const validRoutes = [];
      for (let j = 0; j < tab.routes.length; j++) {
        const route = tab.routes[j];
        let routeObj = baseRouteObj[route];

        if (tab.parentRoute) {
          routeObj = baseRouteObj[tab.parentRoute][route];
        }

        // if tab has nested tabs remove parent tab if no valid children tabs
        if (routeObj.tabs) {
          const validChildrenTabs = this.getTabsWithValidPermissions(
            view,
            routeObj
          );
          if (validChildrenTabs.length === 0) {
            continue;
          }
          routeObj.tabs = validChildrenTabs;
        }

        if (this.hasPermission(view, routeObj)) {
          validRoutes.push(route);
        }
      }

      if (validRoutes.length !== 0) {
        tab.routes = validRoutes;
        validTabs.push(tab);
      }
    }

    return validTabs;
  },
};

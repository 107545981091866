const REPLAY_TYPE_TEXT = 'REPLAY_TEXT';
const REPLAY_TYPE_NUMBER = 'REPLAY_NUMBER';
/**
 * Constants used throughout application
 */

export default {
  // Application formats
  CONSUMERS: ['CX_SUITE', 'ANALYTIC_PORTAL'],
  FORMATS: {
    NUMBER: {
      DECIMALS: 1,
    },
  },
  PRODUCTION: 'production',
  // OAuth
  CONSUMER_KEY: 'xic8Y3ioKKA9MUfgIzWadRwT',
  CONSUMER_SECRET: 'u1uBRiUOz4tLjeWR',
  AUDIENCE: 'CX_SUITE',
  SUBJECT_URN_TOKEN_TYPE: 'urn:ietf:params:oauth:token-type:access_token',
  SUBJECT_TOKEN_URN_ID_TYPE: 'urn:ietf:params:oauth:token-type:id_token',
  SUBJECT_TOKEN_ID_TYPE: 'id_token',
  GRANT_TYPE: 'urn:ietf:params:oauth:grant-type:token-exchange',
  ACTOR_TOKEN_TYPE: 'urn:ietf:params:oauth:grant_type:client_credentials',
  SURVEY_TYPES: {
    csat: ['PREDCSAT', 'PREDCSAT_NPS', 'PREDCSAT_NODR_NPS', 'PREDCSAT_NODR'],
    nps: ['PREDNPS'],
    CX_MEASURE: 'CX_MEASURE',
    FORESEE_FEEDBACK: 'FORESEE_FEEDBACK',
    MICROCSAT: 'MICROCSAT',
    MICRONPS: 'MICRONPS',
    MICROMODEL: 'MICROMODEL',
    EFM: ['EFM_SURVEY', 'EFM_IVR', 'OL_SURVEY', 'EFM_SMS'],
  },

  METRIC_TYPES: {
    QC: 'QC',
    QG: 'QG',
    RC: 'RC',
    LS: 'LS',
    SAT: 'SAT',
    NPS: 'NPS',
    RP: 'RP',
  },

  SURVEY_METRICS: {
    SAT: [
      'CSAT',
      'CSAT_NPS',
      'PREDCSAT',
      'PREDCSAT_NPS',
      'PREDCSAT_NODR',
      'PREDCSAT_NODR_NPS',
      'MICROCSAT',
    ],
    NPS: [
      'CSAT_NPS',
      'PREDCSAT_NPS',
      'PREDNPS',
      'NPS',
      'PREDCSAT_NODR_NPS',
      'MICRONPS',
    ],
    LS: [
      'CSAT',
      'PREDCSAT',
      'PREDCSAT_NODR',
      'MICROCSAT',
      'CSAT_NPS',
      'PREDCSAT_NPS',
      'PREDNPS',
      'NPS',
      'PREDCSAT_NODR_NPS',
      'MICRONPS',
    ],
  },

  TAB_LABELS: {
    DATA_SOURCES: 'Data Sources',
  },

  METRICS_LABELS: {
    RESPCOUNT: 'Response Count',
    RESPPERC: 'Response Percent',
    RA: 'Average Rating',
    NPS: 'NPS®',
    SAT: 'CSAT Score',
    KF: 'Keyword Frequency',
    CC: 'Comment Count',
    SCC: 'Survey Complete Count',
    SPC: 'Survey Presentation Count',
    SCR: 'Survey Completion Rate %',
  },

  METRIC_KEYS_TO_DISPLAY_TOTALS: ['RC', 'RP'],

  TABLE_TOTAL_KEYS: {
    SHOW_ROW_TOTALS: 'showRowTotals',
    SHOW_COLUMN_TOTALS: 'showColumnTotals',
  },

  SCHEMAS: {
    INTERACTION_ANALYTICS:
      'urn:verint.com:predictive:reporting-schema:interaction-analytics',
    ADHOC: 'urn:verint.com:xm:reporting-schema:sm-adhoc',
  },

  LATENT_TYPE: {
    ELEMENT: 'Element',
    FUTURE_BEHAVIOR: 'Future Behavior',
    SATISFACTION: 'Satisfaction',
  },

  // Web server locations
  GLOBAL_CLIENT_ROOT: !!window.requireRoot ? window.requireRoot : '/assets',
  SERVER_ROOT: '!!_serverRoot_!!',
  CARD_SERVICE: '!!_cardService_!!',
  CLIENT_ROOT: '/client/',
  CLIENT_BASE_URL: window.location.origin + '/client/',

  INFORMATION_TYPES: {
    SITE: 'SITE',
    CLIENT: 'CLIENT',
    MEASUREMENT: 'MEASUREMENT',
    HIERARCHY: 'HIERARCHY',
    DEFINITION: 'DEFINITION',
  },

  ACCESS_SCOPE: {
    SPECIFIC: 'SPECIFIC',
    ALL: 'ALL',
    NONE: 'NONE',
  },

  CAPABILITY: {
    CX_MEASURE: 'cx-measure',
  },

  ATTRIBUTES: {
    LEVEL: '__level__',
  },

  CAPABILITY_GROUPS: {
    CX_MEASURE: 'CX_MEASURE',
    FEEDBACK_BASIC: 'FEEDBACK_BASIC',
    AAP: 'AAP',
  },

  CAPABILITIES: {
    RESTRICT: {
      HIERARCHY: 'HIERARCHY',
      HIERARCHY_LIMITED: 'HIERARCHY_LIMITED',
    },
    CXMEASURE_ACCESS: 'CXMEASURE_ACCESS',
    CM_ACCESS: 'CM_ACCESS',
    CX_MEASURE_SETTINGS: 'CX_MEASURE_SETTINGS',
    FEEDBACK: 'FEEDBACK',
  },

  HIERARCHY: {
    CAPABILITIES: {
      CXMEASURE: 'cx-measure',
    },
    ACCESS: {
      EXPLICIT: 'EXPLICIT',
      CASCADE: 'CASCADE',
      ALL: 'ALL',
    },
  },

  // Question Types
  QUESTION_TYPES: {
    UNKNOWN: 'QT_UNKNOWN',
    OPENEND: 'QT_OPENENDS',
    DROP_DOWN: 'QT_DROPDOWN',
    RADIO_BUTTON: 'QT_RADIOBUTTON',
    SCALED: 'QT_STAR_RATING',
    CHECKBOX: 'QT_CHECKBOX',
    LIST: 'QT_UNKNOWN',
    RANKED: 'QT_UNKNOWN',
    MATRIX_RADIO: 'QT_UNKNOWN',
    MATRIX_SCALED: 'QT_UNKNOWN',
    LIST_TEXT: 'QT_UNKNOWN',
    LIST_SELECT: 'QT_UNKNOWN',
    COMP_MATRIX: 'QT_UNKNOWN',
    RATING: 'QT_UNKNOWN',
    NESTED_MATRIX: 'QT_UNKNOWN',
  },

  //Question Operators associated with Question Types
  LOGIC_OPERATORS: {
    //Question Type to Operators
    MAPPINGS: {
      QT_OPENENDS: 'openend',
      QT_TEXT: 'openend',
      QT_DROPDOWN: 'equals',
      QT_RADIOBUTTON: 'equals',
      QT_STAR_RATING: 'numeric',
      QT_CHECKBOX: 'equals',
    },
    //Operator models
    OPERATOR_MODELS: {
      equals: [
        {
          reference: 'logicOperators.numeric.equals',
          value: 'equals',
        },
      ],
      numeric: [
        {
          reference: 'logicOperators.numeric.equals',
          value: 'equals',
        },
        {
          reference: 'logicOperators.numeric.gt',
          value: 'gt',
        },
        {
          reference: 'logicOperators.numeric.lt',
          value: 'lt',
        },
      ],
      openend: [
        {
          reference: 'logicOperators.openend.contains',
          value: 'contains',
        },
      ],
    },
  },

  FILTER_COMPONENT_TYPES: {
    GENERIC_FILTER: 'GENERIC_FILTER',
  },

  QUICK_FILTERS: {
    SENTIMENT: 'Sentiment',
    TOPICS: 'Topics',
  },

  TEXT_ANALYTICS: {
    SOURCE_TYPES: {
      CX_MEASURE: 'CX_MEASURE',
      FEEDBACK: 'FEEDBACK',
      CUSTOM_FEED: 'CUSTOM_FEED',
      CQ: 'CQ',
    },
    SENTIMENT_FILTER: 'TEXT_SENTIMENT',
    SENTIMENT_FILTER_ITEM_TYPE: 'Sentiment',
    TOPIC_FILTER: 'TEXT_TOPIC',
    TOPIC_FILTER_ITEM_TYPE: 'Topics',
    QUESTION_KEY: 'QUESTION_KEY',
    QUESTION_ID: 'QUESTION_ID',
    QUESTION_TAG: 'QUESTION_TAG',
    POSITIVE_SENTIMENT: 'positive',
    NEUTRAL_SENTIMENT: 'neutral',
    NEGATIVE_SENTIMENT: 'negative',
  },

  FILTER_TYPE_HIERARCHY_LEVEL: 'HIERARCHY_LEVEL',
  FILTER_TYPE_HIERARCHY_ATTRIBUTE: 'HIERARCHY_ATTRIBUTE',
  FILTER_TYPE_BD: 'BD_FILTER',
  FILTER_TYPE_QUESTION: 'QUESTION',
  FILTER_TYPE_PARAM: 'PARAM',
  FILTER_TYPE_LATENT: 'LATENT',
  FILTER_TYPE_TEXT: 'TEXT',
  FILTER_TYPE_REPLAY: 'REPLAY',
  FILTER_TYPE_TEXT_ANALYTICS: 'TEXT_ANALYTICS',
  FILTER_TYPE_MODEL: 'MODEL',
  FILTER_TYPE_TEXT_ANALYTICS_SENTIMENT: 'SENTIMENT',
  FILTER_TYPE_TEXT_ANALYTICS_TOPICS: 'TOPICS',
  FILTER_TYPE_FORESEE_FEEDBACK: 'FORESEE_FEEDBACK',
  QT_EPILOGUE: 'EPILOGUE',
  QT_PROLOGUE: 'PROLOGUE',
  QT_STAR_RATING: 'RADIO_BUTTON_SCALE_NO_DONT_KNOW',
  QT_STAR_RATING_DONT_KNOW: 'RADIO_BUTTON_SCALE_WITH_DONT_KNOW',
  QT_DROPDOWN: 'SELECT_ONE_DROPDOWN',
  QT_RADIOBUTTON: 'RADIO_BUTTON_ONE_UP_VERTICAL',
  QT_RADIOBUTTON_TWO: 'RADIO_BUTTON_TWO_UP_VERTICAL',
  QT_RADIOBUTTON_THREE: 'RADIO_BUTTON_THREE_UP_VERTICAL',
  QT_CHECKBOX: 'CHECKBOX_ONE_UP_VERTICAL',
  QT_CHECKBOX_TWO: 'CHECKBOX_TWO_UP_VERTICAL',
  QT_CHECKBOX_THREE: 'CHECKBOX_THREE_UP_VERTICAL',
  QT_NUMERIC: 'OPENEND_NUMERIC',
  QT_TEXT: 'TEXT_FIELD',
  QT_OPENENDS: 'TEXT_AREA',
  QT_MEASURED: 'MEASURED_QUESTION',

  OPERATOR_DOES_NOT_CONTAIN: '',
  OPERATOR_CONTAINS: 'CONTAINS',
  OPERATOR_LIKE: 'REGEXP',
  OPERATOR_GREATER_THAN_OR_EQUAL: 'GTE',
  OPERATOR_GREATER_THAN: 'GT',
  OPERATOR_LESS_THAN_OR_EQUAL: 'LTE',
  OPERATOR_LESS_THAN: 'LT',
  OPERATOR_DOES_NOT_EQUAL: '',
  OPERATOR_TEXT_EQUALS: 'EQUALS',
  OPERATOR_EQUALS: 'EQ',
  OPERATOR_NOT: 'NOT',
  OPERATOR_IN: 'IN',
  OPERATOR_BETWEEN: 'BETWEEN',
  OPERATOR_AND: 'AND',
  OPERATOR_OR: 'OR',

  PAGE_OPERATOR_URL_CONTAINS: 'URL_CONTAINS',
  PAGE_OPERATOR_URL_STARTS_WITH: 'URL_START',
  PAGE_OPERATOR_PAGE_CONTAINS: 'TITLE_CONTAINS',
  PAGE_OPERATOR_PAGE_EQUALS: 'TITLE_EXACT',
  PAGE_OPERATOR_PAGE_STARTS_WITH: 'TITLE_START',

  OPERATOR_MAPPING: {
    CONTAINS: 'CONTAINS',
    REGEXP: 'CONTAINS',
    BETWEEN: 'BETWEEN',
    AND: 'AND',
    OR: 'OR',
    GTE: '>=',
    GT: '>',
    LTE: '<=',
    LT: '<',
    EQUALS: '=',
    EQ: '=',
  },

  REPLAY_TYPE_TEXT: REPLAY_TYPE_TEXT,
  REPLAY_TYPE_NUMBER: REPLAY_TYPE_NUMBER,

  PAGE_TYPES: {
    ENTRY: {
      description: 'pageFilters.pageTypes.entryDescription',
      text: 'pageFilters.pageTypes.entry',
      type: 'ENTRY',
    },
    ANY: {
      description: 'pageFilters.pageTypes.visitDescription',
      text: 'pageFilters.pageTypes.visit',
      type: 'ANY',
    },
    EXIT: {
      description: 'pageFilters.pageTypes.exitDescription',
      text: 'pageFilters.pageTypes.exit',
      type: 'EXIT',
    },
  },

  //FEEDBACK FILTER TYPES
  FEEDBACK: {
    RATING: {
      type: 'projects.filters.feedbackFilter.rating',
    },
    TOPIC: {
      type: 'projects.filters.feedbackFilter.topic',
    },
  },

  // HOSTED CODE PUBLISHING
  HCP_ADVANCED_SETTINGS: {
    COOKIE: {
      type: 'COOKIE',
      text: 'globalSettings.hostedCode.advanced.cookie',
    },
    MICROCOOKIE: {
      type: 'MICROCOOKIE',
      text: 'globalSettings.hostedCode.advanced.micro',
    },
    //COOKIELESS: {
    //  type: "COOKIELESS",
    //  text: "globalSettings.hostedCode.advanced.cookieless"
    //},
    DOMSTORAGE: {
      type: 'DOMSTORAGE',
      text: 'globalSettings.hostedCode.advanced.dom',
    },
  },

  REPLAY_ATTRIBUTES: {
    SESSION_CLICKS: {
      text: 'replayAttributes.sessionClicks',
      type: REPLAY_TYPE_NUMBER,
    },
    SESSION_EVENTS: {
      text: 'replayAttributes.sessionEvents',
      type: REPLAY_TYPE_NUMBER,
    },
    SESSION_DURATION: {
      text: 'replayAttributes.sessionDuration',
      type: REPLAY_TYPE_NUMBER,
    },
    SESSION_ERRORS: {
      text: 'replayAttributes.sessionErrors',
      type: REPLAY_TYPE_NUMBER,
    },
    SESSION_START_PAGE: {
      text: 'replayAttributes.sessionStartPage',
      type: REPLAY_TYPE_TEXT,
    },
    SESSION_END_PAGE: {
      text: 'replayAttributes.sessionEndPage',
      type: REPLAY_TYPE_TEXT,
    },
    SESSION_MILES: {
      text: 'replayAttributes.sessionMouseMiles',
      type: REPLAY_TYPE_NUMBER,
    },
    SESSION_REF_TITLE: {
      text: 'replayAttributes.sessionReferrerTitle',
      type: REPLAY_TYPE_TEXT,
    },
    SESSION_REF_URL: {
      text: 'replayAttributes.sessionReferrerURL',
      type: REPLAY_TYPE_TEXT,
    },
    SESSION_PAGE_COUNT: {
      text: 'replayAttributes.sessionPageCount',
      type: REPLAY_TYPE_NUMBER,
    },
    SESSION_PAGE_URLS: {
      text: 'replayAttributes.sessionPageURLS',
      type: REPLAY_TYPE_TEXT,
    },
    SESSION_PAGE_TITLES: {
      text: 'replayAttributes.sessionPageTitles',
      type: REPLAY_TYPE_TEXT,
    },
    SCREEN_WIDTH: {
      text: 'replayAttributes.screenWidth',
      type: REPLAY_TYPE_NUMBER,
    },
    SCREEN_HEIGHT: {
      text: 'replayAttributes.screenHeight',
      type: REPLAY_TYPE_NUMBER,
    },
    PAGE_CLICKS: {
      text: 'replayAttributes.pageClicks',
      type: REPLAY_TYPE_NUMBER,
    },
    PAGE_EVENTS: {
      text: 'replayAttributes.pageEvents',
      type: REPLAY_TYPE_NUMBER,
    },
    PAGE_DURATION: {
      text: 'replayAttributes.pageDuration',
      type: REPLAY_TYPE_NUMBER,
    },
    PAGE_ERRORS: {
      text: 'replayAttributes.pageErrors',
      type: REPLAY_TYPE_NUMBER,
    },
    PAGE_MILES: {
      text: 'replayAttributes.pageMouseMiles',
      type: REPLAY_TYPE_NUMBER,
    },
    PAGE_PRIOR_URL: {
      text: 'replayAttributes.pageReferrerURL',
      type: REPLAY_TYPE_TEXT,
    },
    PAGE_PRIOR_TITLE: {
      text: 'replayAttributes.pageReferrerTitle',
      type: REPLAY_TYPE_TEXT,
    },
  },
  REPLAY_STATUSES: {
    PROCESSED: 'PROCESSED',
    IN_PROCESS: 'IN_PROCESS',
    FAILED: 'FAILED',
    UNKNOWN: 'UNKNOWN',
  },
  // Display Types
  DT_ONE_UP: 'ONE_UP',
  DT_TWO_UP: 'TWO_UP',
  DT_THREE_UP: 'THREE_UP',

  // Topic types
  TT_EMPTY_TOPIC: 'TOPIC',

  // Sidebar props TYPE
  SIDEBAR_PROJECT_DND: {
    TYPE: 'SIDEBAR_PROJECT_DND',
  },

  // Security Permissions and Roles
  PERMISSION_MAP: {
    login: ['ACS_USER'],
    error: ['ACS_USER'],
    portal_access: ['ACS_USER'],
    portal_admin: ['ACS_ADMIN'],
    feedback_admin: ['FEEDBACK_ADMIN'],
    feedback_access: ['ACS_FB_OVERVIEW'],
    //feedback_reports: ["ACS_FB_REPORTS"],
    feedback_create: ['ACS_FB_CREATE'],
    //measure_analytics: [""],
    //feedback_insights: ["ACS_FB_INSIGHTS"],
    //feedback_insights_summary: ["ACS_FB_OVERVIEW"],
    //feedback_insights_questions: ["ACS_FB_QUESTIONS"],
    //feedback_insights_openends: ["ACS_FB_OPENENDS"],
    feedback_alerts: ['ACS_FB_ALERTS'],
    feedback_publish: ['ACS_FB_PUBLISH'],
    feedback_weblink: ['FEEDBACK_WEBLINK'],
    feedback_privacy_policy: ['FEEDBACK_PRIVACY_POLICY'],
    ep_access: [
      'P3_ACCESS',
      'P3_ADHOC_QUERY',
      'P3_DASHBOARD_ADMIN',
      'P3_DASHBOARD_MANAGE',
      'P3_DASHBOARD_SHARE',
    ],
    foresee_workbench: ['FORESEE_WORKBENCH'],
    text_analytics: ['OVERVIEW'],
    text_analytics_settings: ['DATA_FEED_MANAGEMENT'],
    survey_type_fullpage: ['ACS_FB_KIOSK_DISPLAY'],
    cx_measure: ['CXMEASURE_VIEW'],
    cx_measure_view: ['CXMEASURE_VIEW'],
    adhoc_view: ['VIEW_ADHOC'],
    interaction_analytics_view: ['VIEW_IA'],
    trigger: ['CXMEASURE_VIEW'],
    feedback: ['ACS_FB_OVERVIEW'],
    replay: ['ADHOC-REPLAY'],
    replay_download: ['CXREPLAY_DOWNLOAD_LINK'],
    record: ['ADHOC-REPLAY'],
    mpathy: ['ADHOC-REPLAY'],
    opinionlab: ['DX_ADMIN'],
    mpathy_super_user: ['MP_SUPER_USER'],
    mpathy_ux_insight: ['MP_UX_INSIGHT'],
    mpathy_embedded_replay: ['MP_EMBEDDED_REPLAY'],
    replay_create: ['ACS_FB_CREATE'],
    hosted_code: ['CXS_GLOBAL_HC_SETTINGS'],
    hierarchy_edit: ['HIERARCHY_EDIT'],
    hierarchy_view: ['HIERARCHY_VIEW', 'HIERARCHY_EDIT'],
    global_settings_export_data: ['EXPORT_DATA'],
    global_settings_export_report: ['EXPORT_REPORT'],
    global_settings_manage_dashboards: ['MANAGE_PUBLIC_ACCESS'],
    global_settings_api_credentials: ['ADMIN', 'SUPPORT', 'API_CREDENTIALS'],
    global_settings_tableau_access: ['TABLEAU_ACCESS'],
    tableau_access: ['TABLEAU_AUTHORING_ACCESS'],
    user_admin: ['PERMISSIONS_EDIT', 'VIEW_USERS'],
    permissions_edit: ['PERMISSIONS_EDIT'],
    view_admin_support: ['VIEW_USERS', 'SUPPORT', 'ADMIN'],
    view_user: ['VIEW_USERS'],
    view_client: ['VIEW_CLIENTS'],
    admin_support: ['ADMIN', 'SUPPORT'],
    admin: ['ADMIN'],
    odata_access: ['ODATA_ACCESS'],
    create_custom_data_sources: ['CREATE_CUSTOM_DATA_SOURCES'],
    anomaly_detection_enabled: ['ENABLE_AD'],
    dashboard_admin: ['DASHBOARD_ADMIN'],
    login_as: ['LOGIN_AS'],
    benchmark_manage_category: ['BM_MANAGE_CATEGORY'],
    use_benchmarks: ['BENCHMARK'],
  },

  PERMISSIONS: {
    LOGIN_AS: 'login_as',
  },
  SERVICE_GATEWAY: {
    'foresee-services': '!!_serverRoot_!!',
    'big-data-services': '!!_bigDataServerRoot_!!',
    'acs-services': '!!_serverRoot_!!',
    'acs-reporting': '!!_serverRoot_!!',
    'replay-api': '!!_replayAPI_!!',
    default: '!!_serverRoot_!!',
  },
  dateRangeTypes: {
    DAY: 'DY',
    WEEK: 'WK',
    MONTH: 'MO',
    QUARTER: 'QR',
    YEAR: 'YR',
    DAYS: 'D',
    WEEKS: 'W',
    MONTHS: 'M',
    QUARTERS: 'Q',
    YEARS: 'Y',
    CUSTOM: 'C',
  },
  dateDisplay: 'MM/DD/YYYY',
  datePickerCardDefault: {
    id: 'cardDefault',
    label: 'datePicker.presetMenu.cardDefault',
    start_date: null,
    end_date: null,
    rangeType: 'C',
  },
  datePickerAdditionalButtons: [
    {
      id: 'custom',
      label: 'datePicker.presetMenu.customRange',
      start_date: null,
      end_date: null,
      rangeType: 'C',
    },
    {
      id: 'yesterday',
      label: 'datePicker.presetMenu.yesterday',
      rangeType: 'D',
      number: 0,
      break: true,
    },
    {
      id: 'weekToDate',
      label: 'datePicker.presetMenu.weekToDate',
      rangeType: 'W',
      number: 0,
    },
    {
      id: 'monthToDate',
      label: 'datePicker.presetMenu.monthToDate',
      rangeType: 'M',
      number: 0,
    },
    {
      id: 'quarterToDate',
      label: 'datePicker.presetMenu.quarterToDate',
      rangeType: 'Q',
      number: 0,
    },
    {
      id: 'yearToDate',
      label: 'datePicker.presetMenu.yearToDate',
      rangeType: 'Y',
      number: 0,
    },
    {
      id: 'lastWeek',
      label: 'datePicker.presetMenu.lastWeek',
      rangeType: 'W',
      number: 1,
      break: true,
    },
    {
      id: 'lastMonth',
      label: 'datePicker.presetMenu.lastMonth',
      rangeType: 'M',
      number: 1,
    },
    {
      id: 'lastQuarter',
      label: 'datePicker.presetMenu.lastQuarter',
      rangeType: 'Q',
      number: 1,
    },
    {
      id: 'lastYear',
      label: 'datePicker.presetMenu.lastYear',
      rangeType: 'Y',
      number: 1,
    },
    {
      id: 'last7Days',
      label: 'datePicker.presetMenu.last7Days',
      number: 7,
      rangeType: 'D',
      break: true,
    },
    {
      id: 'last30Days',
      label: 'datePicker.presetMenu.last30Days',
      number: 30,
      rangeType: 'D',
    },
    {
      id: 'last60Days',
      label: 'datePicker.presetMenu.last60Days',
      number: 60,
      rangeType: 'D',
    },
    {
      id: 'last90Days',
      label: 'datePicker.presetMenu.last90Days',
      number: 90,
      rangeType: 'D',
    },
    {
      id: 'last120Days',
      label: 'datePicker.presetMenu.last120Days',
      number: 120,
      rangeType: 'D',
    },
    {
      id: 'last12Months',
      label: 'datePicker.presetMenu.last12Months',
      number: 12,
      rangeType: 'M',
    },
  ],
  datePickerButtons: [
    {
      id: 'custom',
      label: 'datePicker.presetMenu.customRange',
      start_date: null,
      end_date: null,
      rangeType: 'C',
      selected: 0,
    },
    {
      id: 'yesterday',
      label: 'datePicker.presetMenu.yesterday',
      rangeType: 'D',
      number: 0,
      break: true,
      selected: 1,
    },
    {
      id: 'weekToDate',
      label: 'datePicker.presetMenu.weekToDate',
      rangeType: 'W',
      number: 0,
      selected: 2,
    },
    {
      id: 'monthToDate',
      label: 'datePicker.presetMenu.monthToDate',
      rangeType: 'M',
      number: 0,
      selected: 3,
    },
    {
      id: 'quarterToDate',
      label: 'datePicker.presetMenu.quarterToDate',
      rangeType: 'Q',
      number: 0,
      selected: 4,
    },
    {
      id: 'yearToDate',
      label: 'datePicker.presetMenu.yearToDate',
      rangeType: 'Y',
      number: 0,
      selected: 5,
    },
    {
      id: 'lastWeek',
      label: 'datePicker.presetMenu.lastWeek',
      rangeType: 'W',
      number: 1,
      break: true,
      selected: 6,
    },
    {
      id: 'lastMonth',
      label: 'datePicker.presetMenu.lastMonth',
      rangeType: 'M',
      number: 1,
      selected: 7,
    },
    {
      id: 'lastQuarter',
      label: 'datePicker.presetMenu.lastQuarter',
      rangeType: 'Q',
      number: 1,
      selected: 8,
    },
    {
      id: 'lastYear',
      label: 'datePicker.presetMenu.lastYear',
      rangeType: 'Y',
      number: 1,
      selected: 9,
    },
    {
      id: 'last7Days',
      label: 'datePicker.presetMenu.last7Days',
      number: 7,
      rangeType: 'D',
      break: true,
      selected: 10,
    },
    {
      id: 'last30Days',
      label: 'datePicker.presetMenu.last30Days',
      number: 30,
      rangeType: 'D',
      selected: 11,
    },
    {
      id: 'last12Months',
      label: 'datePicker.presetMenu.last12Months',
      number: 12,
      rangeType: 'M',
      selected: 12,
    },
  ],
  MASTERDATA: {
    icons: {
      locations: 'locations',
      employees: 'employees',
      agents: 'agents',
      web: 'web',
      survey: 'survey',
      gear: 'gear',
    },
    schema: 'http://json-schema.org/draft-04/schema#',
  },
  ODI: {
    TOO_FEW_RESPONDENTS: 'TOO_FEW_RESPONDENTS',
    RC_LIMIT_EXCEEDED: 'RC_LIMIT_EXCEEDED',
    TOO_MANY_RESPONDENTS: 'TOO_MANY_RESPONDENTS',
    PARTITION_THRESHOLD_NOT_MET: 'PARTITION_THRESHOLD_NOT_MET',
    GENERATE: 'GENERATE',
    INITIALIZED: 'INITIALIZED',
    PROCESSING: 'PROCESSING',
    FETCH_RESPONDENTS: 'FETCH_RESPONDENTS',
    CALCULATE_IMPACTS: 'CALCULATE_IMPACTS',
    SUBMIT_TO_ENGINE: 'SUBMIT_TO_ENGINE',
    COMPLETED: 'COMPLETED',
    ERROR: 'ERROR',
    ENGINE_ERROR: 'ENGINE_ERROR',
    NOT_ENOUGH_DATA_POINTS: 'NOT_ENOUGH_DATA_POINTS',
  },
  DASHBOARD: {
    CARD_LOAD_OFFSET_MILLIS: 200,
    SENSITIVE_QUESTIONS_ERROR: 'CXMR_SENSITIVE_QUESTION_KEY',
    chartTypes: {
      kpi: 'kpi',
      gauge: 'gauge',
      trendLine: 'trendLine',
      verticalBar: 'verticalBar',
      horizontalBar: 'horizontalBar',
      tableCard: 'tableCard',
      donut: 'donut',
      scoreSummary: 'scoreSummary',
      scatterPlot: 'scatterPlot',
      priorityMap: 'priorityMap',
      priorityIndex: 'priorityIndex',
      voc: 'voc',
      wordCloud: 'wordCloud',
      external: 'external',
      summary: 'summary',
      map: 'map',
    },
    colors: [
      '#007aff',
      '#fdd800',
      '#28D0C9',
      '#E44FA5',
      '#00ce7c',
      '#fa6e1e',
      '#965cc5',
      '#f43d58',
      '#8E5941',
      '#0057bf',
      '#262a33',
    ],
    taColors: ['#5a6675'],
    cardSizeOptions: [
      {
        label: 'S',
        value: 'small',
      },
      {
        label: 'M',
        value: 'medium',
      },
      {
        label: 'L',
        value: 'large',
      },
      {
        label: 'Full',
        value: 'full',
      },
    ],
    cardSizeMap: {
      stamp: {
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 2,
      },
      small: {
        gridColumnStart: 1,
        gridColumnEnd: 3,
        gridRowStart: 1,
        gridRowEnd: 3,
      },
      medium: {
        gridColumnStart: 1,
        gridColumnEnd: 4,
        gridRowStart: 1,
        gridRowEnd: 3,
      },
      large: {
        gridColumnStart: 1,
        gridColumnEnd: 5,
        gridRowStart: 1,
        gridRowEnd: 3,
      },
      full: {
        gridColumnStart: 1,
        gridColumnEnd: 7,
        gridRowStart: 1,
        gridRowEnd: 3,
      },
    },
    cardHeight: [500, 750, 1000, 1500],
    dimensionsKeywords: 'keywords',
    dimensionsTopics: 'topics',
    priorityErrorTypes: {
      insufficientRespondentsError: 'InsufficientRespondentsError',
      receiptIntervalError: 'ReceiptIntervalError',
      unknownCalculationError: 'UnknownCalculationError',
      tooFewRespondentsError: 'TOO_FEW_RESPONDENTS',
      tooManyRespondentsError: 'RC_LIMIT_EXCEEDED',
      partitionThresholdError: 'PARTITION_THRESHOLD_NOT_MET',
      calculationAPIError: 'API_ERROR_SYSTEM',
      calculationBDError: 'ERROR',
    },
    fullCardHeight: 550,
    metricType: {
      benchmark: 'benchmark',
      commentCount: 'CC',
      keywordFrequency: 'KF',
    },
    sourceType: {
      cxmeasure: 'cxmeasure',
      cxmeasurebd: 'cxmeasurebd',
      feedback: 'feedback',
      customFeed: 'customFeed',
      definition: 'definition',
      event: 'event',
      customDataSources: 'customDataSources',
      digital_cxmeasure: 'digital_cxmeasure',
      nondigital_cxmeasure: 'nondigital_cxmeasure',
    },
    csvExportLimit: {
      BIG_DATA: 15000,
      CXMEASURE: 5000,
    },
    dimensionType: {
      datasource: 'DATASOURCE',
      date: 'DATE',
    },
    textAnalytics: {
      sentiment: {
        positive: 'Positive',
        neutral: 'Neutral',
        negative: 'Negative',
      },
      source: {
        cxmeasure: 'CX_MEASURE',
        feedback: 'FEEDBACK',
        customFeed: 'CUSTOM_FEED',
      },
    },
    kpi: {
      sparkline: {
        trend: 'trend',
        gauge: 'gauge',
        bar: 'bar',
      },
    },
    SCHEDULE: {
      userRunStatus: {
        READY: 'READY',
        COMPLETED: 'COMPLETED',
        IN_PROCESS: 'IN_PROCESS',
        PERMANENTLY_FAILED: 'PERMANENTLY_FAILED',
      },
    },
    EXTERNAL_TYPES: {
      TEXT_ANALYTICS: 'TEXT_ANALYTICS',
    },
    TYPES: {
      TEXT_ANALYTICS: 'text-analytics',
    },
  },
  HOLLOW_DASHBOARD_TYPE: 'HOLLOW',
  FORESEE_SUPPORT_URL: 'https://community.verint.com/support',
  SURVEY_QUESTION_TYPES: {
    CX_MEASURE: 'CxMeasureQuestion',
    FORESEE_FEEDBACK: 'ForeseeFeedbackSurveyQuestion',
  },
  ANOMALY_DETECTION: {
    DATASOURCE_TYPES: {
      CX_MEASURE: 'measure',
    },
    ACTION_TYPES: {
      ARCHIVE: 'archive',
      UNARCHIVE: 'unarchive',
      DELETE: 'delete',
    },
  },
  CX_FILTER_TYPES: {
    CQ: 'CQ',
    MQ: 'MQ',
    USER: 'USER',
    ELEMENTS: 'ELEMENTS',
    SATISFACTION: 'SATISFACTION',
    FUTURE_BEHAVIORS: 'FUTURE_BEHAVIORS',
  },
  PENDO_EVENTS: {
    OT_LAND: 'ot_land',
    OT_SURVEY_FILTER: 'ot_survey_filter',
    OT_HIERARCHY_FILTER: 'ot_hierarchy_filter',
    OT_TOPICS_FILTER: 'ot_topics_filter',
    OT_SENTIMENT_FILTER: 'ot_sentiment_filter',
    OT_DW_CSV: 'ot_dw_csv',
    OT_EDIT_TREND: 'ot_edit_trend',
    OT_EDIT_HBAR: 'ot_edit_hbar',
    OT_EDIT_VOC: 'ot_edit_voc',
    TOPICS_LAND: 'topics_land',
    KEYWORDS_LAND: 'keywords_land',
    KEYWORDS_KW_FILTER: 'keywords_kw_filter',
  },
  ISO_DATE_ONLY_FORMAT: 'YYYY-MM-DD',
  ISO_DATE_TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
  ISO_TIMESTAMP_FORMAT: 'YYYY-MM-DDTHH:mm:ssZ',
  DEFAULT_TIME_ZONE: 'America/New_York',
  DATE_ONLY: 'DATE_ONLY',
  DATE_TIME: 'DATE_TIME',
  DATE_MIXED: 'DATE_MIXED',
  PREDICTIVE_EXPERIENCE: 'Verint Experience Management',
  SHOW_DATA_SOURCES_HEIGHT: 32,
  MAX_DATE_RANGE: 366,
  EXTENDED_MAX_DATE_RANGE: 400,
  UNIFIED_EXTENDED_MAX_DATE_RANGE: 1127,
  UNIFIED_EXTENDED_MAX_DATE_RANGE_AS_MONTHS: 37,
  BENCHMARK_TYPE: 'BENCHMARK',
  DATASOURCE_TYPES: {
    INTERACTION_ANALYTICS: 'interaction-analytics',
    PREDICTIVE: 'predictive',
    ADHOC: 'adhoc',
  },
};

import React from 'react';
import BasePanel from 'views/panels/base';

import { Text, Tooltip } from 'cx-component-library';
import './styles.scss';
import TemplatedComponent from 'views/widgets/templated-component';

class ContentForgotEmail extends BasePanel {
  renderTooltipContent() {
    return (
      <ul className='c-forgot-email__list'>
        <li>
          <Text theme='body' type='div'>
            {this.getString('password.forgot.email.tooltip.spam')}
          </Text>
        </li>
        <li>
          <Text theme='body' type='div'>
            {this.getString('password.forgot.email.tooltip.note')}
          </Text>
        </li>
        <li>
          <Text theme='body' type='div'>
            <TemplatedComponent
              string='password.forgot.email.tooltip.support'
              tokens={{
                link: (
                  <Text
                    target='_blank'
                    type='a'
                    href='https://connect.verint.com/'
                  >
                    {this.getString('general.serviceName')}
                  </Text>
                ),
              }}
            />
          </Text>
        </li>
      </ul>
    );
  }

  render() {
    return (
      <div className='login login--desktop'>
        <div className='login__header'>
          <div className='logo-image' />
        </div>
        <div className='login__content gutter--bottom'>
          <Text theme='body' type='div' className='gutter--bottom'>
            <TemplatedComponent
              string='password.forgot.email.sent'
              tokens={{
                link: (
                  <Text
                    target='_blank'
                    type='a'
                    href='https://connect.verint.com/'
                  >
                    {this.getString('general.serviceName')}
                  </Text>
                ),
              }}
            />
          </Text>

          <div className='gutter-p--vertical'>
            <div className='login__emailImage' />
          </div>

          <div className='clearfix gutter--vertical'>
            <Text
              theme='body'
              className='left'
              type='div'
              linkTo={this.handleReturnToLoginClick}
            >
              {this.getString('password.forgot.returnToLogin')}
            </Text>
            <Tooltip content={this.renderTooltipContent()}>
              <Text theme='body' className='right' type='div' linkTo={() => {}}>
                {this.getString('password.forgot.email.notReceivingEmail')}
              </Text>
            </Tooltip>
          </div>
        </div>
        <div className='login__content--bottom' />
      </div>
    );
  }

  handleReturnToLoginClick = () => {
    this.changeRoute('login');
  };
}

export default ContentForgotEmail;

import _ from 'underscore';
import BaseSeriesModel from 'models/baseSeries';
import moment from 'moment';

const AggregrationEventsModel = BaseSeriesModel.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.event = options.event;
    this.eventLabel = options.eventLabel;
    this.source = options.source;
    this.dateDimension = options.dateDimension;
    this.propertyDimension = options.propertyDimension;
  },

  _parseDateAndDimension: function (data) {
    const { propertyDimension, dateDimension } = this;
    let series = [];
    let points = [];
    let propertyValues = [];
    data.forEach((date) => {
      date.result.forEach((r) => {
        propertyValues.push(r[propertyDimension.key]);
      });
    });
    propertyValues = _.uniq(propertyValues);

    propertyValues.forEach((pv) => {
      points = data.map((p, index) => {
        let dates = {};
        if (dateDimension) {
          dates = this._getDates(
            moment.utc(p.timestamp),
            dateDimension.key,
            moment.utc(
              this._getEndDate(index, data, {
                f: this.get('startDate'),
                l: moment.utc(this.get('endDate')),
              })
            )
          );
        }
        const value = _.find(p.result, (r) => {
          return r[propertyDimension.key] === pv;
        });
        return {
          ...dates,
          value: value ? value.count : null,
        };
      });
      series.push({
        type: this._getSeriesType(),
        dataSourceLabel: 'Digital CX Measures',
        metricType: 'event',
        metricKey: pv,
        metricLabel: pv,
        label: pv || 'Not Set',
        points: points,
      });
    });
    return series;
  },

  _getNodeTimestamp(node) {
    return node.timestamp;
  },

  _getSeriesType: function () {
    return 'event';
  },

  _parseTimeSeries: function (data) {
    console.log('_parseTimeSeries', data);
    return [
      {
        type: this._getSeriesType(),
        dataSourceLabel: 'Digital CX Measures',
        metricType: 'event',
        metricKey: this.event,
        metricLabel: this.eventLabel,
        label: this.eventLabel,
        points: data.map((p, index) => {
          let dates = {};
          if (this.dateDimension) {
            dates = this._getDates(
              moment.utc(p.timestamp),
              this.dateDimension.key,
              moment.utc(
                this._getEndDate(index, data, {
                  f: this.get('startDate'),
                  l: moment.utc(this.get('endDate')),
                })
              )
            );
          }
          return {
            ...dates,
            value: p.result ? p.result.count : null,
          };
        }),
      },
    ];
  },

  _parseNonTimeSeries: function (data) {
    let aggregate = 0;
    let points = [];
    if (this.propertyDimension) {
      if (data && data[0] && data[0].result) {
        points = data[0].result.map((r) => {
          return {
            categoryName: r[this.propertyDimension.key] || 'Not Set',
            value: r.count,
          };
        });
      }
    } else {
      points = [];
      aggregate = data[0] ? data[0].result.count : 0;
    }

    return [
      {
        type: this._getSeriesType(),
        dataSourceLabel: 'Digital CX Measures',
        metricType: 'event', // metric.type,
        metricKey: this.event, //metric.key,
        metricLabel: this.eventLabel,
        label: this.eventLabel,
        points: points,
        aggregate,
        parsedDateRange: this._parseRange({
          f: this.get('startDate'),
          l: this.get('endDate'),
        }),
      },
    ];
  },

  parse: function (data) {
    let series = [];
    const { propertyDimension, dateDimension } = this;
    if (dateDimension) {
      if (propertyDimension) {
        series = this._parseDateAndDimension(data);
      } else {
        series = this._parseTimeSeries(data);
      }
    } else {
      series = this._parseNonTimeSeries(data);
    }
    return { series };
  },

  serviceId: 'big-data-services',

  url: function () {
    return `internal/in/ui-endpoints/query/customer/${this.client}/app/funcxm/event/${this.event}/source/${this.source}`;
  },
});

export default AggregrationEventsModel;

import BaseController from 'controllers/base';
import UserModel from 'models/currentUser';
import ClientModel from 'models/client';
import ClientDetailsModel from 'models/clients/details';
import ClientCollection from 'collections/clients';
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import { initialize } from 'launchdarkly-js-client-sdk';
import FeatureFlags from 'core/featureFlags';
import { getAccessToken } from 'views/utils/lux-util';

/**
 * User controller
 * */
class UserController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'user:isInitialized': 'isInitialized',
        'app:authenticated': 'fetch',
        'user:clients:setSearch': 'filterClientCollection',
        'user:clients:update': 'setSelectedClient',
        'user:get': '_getUserData',
        'user:clients:get': 'getClientsList',
        'user:featureflags:get': 'getFeatureFlags',
        'user:clients:details:get': 'getClientsDetails',
      },
      name: 'user',
      dependsOn: ['settings', 'hosted-code', 'routes'],
    });
  }

  initialize(opts) {
    this.model = new UserModel();
    this.client = new ClientModel();
    this.clientsList = new ClientCollection();
    this.selectClientFlag = false;
    this.selectedClient = new ClientModel();
    if (this.authenticated()) {
      return this.fetch();
    } else {
      return $.Deferred();
    }
  }

  isInitialized() {
    this.publish('user:initialized');
  }

  getClientIdForForeseeUser() {
    return new Promise((resolve) => {
      let clientId;
      const key = 'clientId-' + this.model.get('username');
      this.publish('storage:get', {
        key: key,
        bare: true,
        callback: async (storedKey) => {
          await this.getClientCollection();
          if (storedKey.data !== undefined) {
            clientId = storedKey?.data?.data;
          }

          if (!clientId) {
            const sonomaAccessToken = getAccessToken();

            const thisClient = this.clientList
              .toJSON()
              .filter((client) => client.sonomaMigrated || false)
              .find((c) => {
                if (
                  c.hasOwnProperty('externalId') &&
                  sonomaAccessToken.hasOwnProperty('v_tid')
                ) {
                  return (
                    c.externalId.toLowerCase() ===
                    sonomaAccessToken.v_tid.toLowerCase()
                  );
                }
                return false;
              });
            // Set the client id
            clientId = thisClient?.clientId || -1;
          }

          resolve(clientId);
        },
      });
    });
  }

  /**
   * Retrieve user and client models,
   */
  fetch() {
    var def = $.Deferred();
    // Set strings if not done yet and listen for changes
    if (!this.strings.user) {
      this.strings.user = this.model.toJSON();
      this.strings.client = this.client.toJSON();
      this.strings.user.user_client = this.getUserClientText();
      this.strings.user.user_initials = this.getUserInitials();

      this.listenTo(
        this.client,
        'change',
        _.bind(function () {
          this.setClientStrings();
        }, this)
      );
    }
    this.model
      .fetch({
        doNotAbort: true,
        error: (err) => {
          this.publish('app:responseStatus', err._ajaxRef);
        },
      })
      .done(
        _.bind(async function () {
          this.setModelStrings();

          let clientId = this.model.get('clientId');
          const defs = [];

          //Load client collection if it's a Foresee user
          if (clientId === -1) {
            clientId = await this.getClientIdForForeseeUser();
          }

          defs.push(this.fetchClient(clientId, $.Deferred()));
          defs.push(this.fetchFeatureFlags(clientId, $.Deferred()));

          // Once all dependencies are loaded
          $.when.apply($, defs).done(() => {
            //set storage on done to make sure client model will not be reset
            if (this.model.get('clientId') === -1) {
              const list = this.formatClientCollection(this.clientList);
              this.publish('storage:set', {
                key: 'client-list',
                data: { clients: list },
              });
              this.publish('user:clients:data', { clients: list });
            }

            def.resolve();
          });
        }, this)
      );

    return def;
  }

  _toJSON() {
    // Set User Email and Client Name as CPPs for Embed Script
    /* global FSR, fsReady */
    if (typeof FSR !== 'undefined') {
      let user = this.model.toJSON();
      let client = this.client.toJSON();
      fsReady(function () {
        FSR.CPPS.set('email', user.email);
        FSR.CPPS.set('client', client.name);
      });
    }
    return {
      user: this.model.toJSON(),
      client: this.client.toJSON(),
    };
  }

  fetchClient(clientId, def) {
    this.client.set('clientId', clientId);
    this.client.fetch().done(
      _.bind(function () {
        this.client.set(this._toJSON().client);
        this.setClientStrings();
        this.publish('user:data', this._toJSON());
        //Broadcast that a new clientId has been set.
        if (this.selectClientFlag) {
          this.publish(
            'user:clients:setClient',
            this.setClientIdStorage.bind(this, clientId)
          );
        }
        def.resolve();
      }, this)
    );
    return def;
  }

  fetchFeatureFlags(clientId, def) {
    const ldId = '!!_launchdarklyId_!!';
    const userProps = {
      key: this.model.get('username'),
      email: this.model.get('email'),
      username: this.model.get('username'),
      custom: {
        clientId: clientId,
      },
    };
    const ldClient = initialize(ldId, userProps);
    const user = this.model;

    ldClient.on('ready', () => {
      user.set('hasFlags', true);
      this.publish('featureFlags:ready', this._toJSON());
      def.resolve();
    });

    user.set('featureFlags', ldClient);
    FeatureFlags.setFeatureFlags(ldClient);
    return def;
  }

  getFeatureFlags(cb) {
    cb(this.model.get('featureFlags'));
  }

  _getUserData(payload) {
    if (payload.callback) {
      payload.callback(this._toJSON());
    } else {
      this.publish('user:data', this._toJSON());
    }
  }

  getUserClientText() {
    return this.client.get('name');
  }

  getUserInitials() {
    var initials = '',
      first = this.model.get('firstName'),
      last = this.model.get('lastName');
    if (first) initials += first[0].toUpperCase();
    if (last) initials += last[0].toUpperCase();
    return initials;
  }

  getFullName() {
    return `${this.model.get('firstName')} ${this.model.get('lastName')}`;
  }

  getClientCollection() {
    this.clientList = new ClientCollection();
    return new Promise((resolve) => {
      this.clientList.fetch({ doNotAbort: true }).then(() => {
        resolve();
      });
    });
  }

  formatClientCollection(theList) {
    const clients = theList || [];
    return clients.map((client) => ({
      name: client.get('name'),
      clientId: client.get('clientId'),
      action: 'user:clients:update',
      payload: client.get('clientId'),
    }));
  }

  filterClientCollection(payload) {
    var list = [],
      clients = this.clientList,
      searchTerm = payload.search.toLowerCase() || '';

    var filteredClients = new Backbone.Collection(
      clients.filter((client) => {
        return client.get('name').toLowerCase().indexOf(searchTerm) > -1;
      })
    );

    list = this.formatClientCollection(filteredClients);

    this.publish('user:clients:data', { clients: list });
  }

  getClientsList(payload) {
    const { callback } = payload;

    const list = this.formatClientCollection(this.clientList);
    if (callback) {
      callback(list);
    }
    this.publish('user:clients:data', { clients: list });
  }

  setSelectedClient(payload) {
    var def = $.Deferred();

    this.selectClientFlag = payload.clientId === '' ? false : true;
    this.fetchClient(payload.clientId, def).then(() => {
      this.publish('route:data', { route: 'dashboards' });
      this.setClientStrings();
    });
  }

  setClientIdStorage(clientId = -1) {
    const key = 'clientId-' + this.model.get('username');
    this.publish('storage:set', { key: key, bare: true, data: clientId });
  }

  setClientStrings() {
    this.strings.client = this.client.toJSON();
    this.strings.user.user_client = this.getUserClientText();
    this.strings.user.user_initials = this.getUserInitials();
    this.strings.user.user_full_name = this.getFullName();
    this.strings.user.user_last_name = this.model.get('lastName');
  }

  setModelStrings() {
    this.strings.user = this.model.toJSON();
    this.strings.user.user_client = this.getUserClientText();
    this.strings.user.user_initials = this.getUserInitials();
    this.strings.user.user_full_name = this.getFullName();
    this.strings.user.user_last_name = this.model.get('lastName');
  }

  getClientsDetails(payload) {
    const { clientId = this.client.get('clientId'), callback } = payload;
    const model = new ClientDetailsModel({
      clientId: clientId,
    });
    model.fetch().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }
}

export default UserController;

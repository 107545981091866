import Backbone from 'backbone';
import Constants from 'core/constants';
import { supportsDateTime } from 'views/panels/dashboards/date-type';

const DashboardCardModel = Backbone.Model.extend({
  defaults: {
    cardId: null,
    clientId: 0,
    dashboardId: 0,
    lastUpdatedDate: null,
    metadata: {
      attributes: {},
    },
    name: '',
    parentId: null,
    sortOrder: 0,
    templateId: null,
    type: 'PERSONAL',
    userId: null,
  },

  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.dashId = options.dashboardId;
    this.cardId = options.cardId;
  },

  idAttribute: 'cardId',

  parse: function (data) {
    const { DASHBOARD, DATE_TIME, DATE_ONLY } = Constants;

    let metadata = data.metadata;
    if (metadata && metadata.attributes) {
      const { dataSources, metrics, dateRange, chartType, taQuestions } =
        metadata.attributes;
      if (dateRange && dataSources && dataSources.length) {
        metadata.attributes.dateRange.t = supportsDateTime(
          chartType,
          dataSources,
          taQuestions
        )
          ? DATE_TIME
          : DATE_ONLY;
      }

      const { trendLine } = DASHBOARD.chartTypes;

      if (
        chartType === trendLine &&
        (dataSources?.length > 1 || metrics?.length > 1)
      ) {
        delete metadata.attributes.benchmarkKeys;
        delete metadata.attributes.benchmarkType;
      }

      data.metadata = metadata;
    }
    return data;
  },

  url: function () {
    if (this.cardId) {
      return `clients/${this.client}/dashboards/${this.dashId}/cards/${this.cardId}`;
    }
    return `clients/${this.client}/dashboards/${this.dashId}/cards`;
  },
});

export default DashboardCardModel;

import Backbone from 'backbone';
import URL from 'core/utils/url';

const SuperLatentsModel = Backbone.Model.extend({
  initialize: function (model, opts) {},

  url: function () {
    return 'benchmark-service/category-mapping/superlatents';
  },
});

export default SuperLatentsModel;

import Backbone from 'backbone';

var HierarchyConfig = Backbone.Model.extend({
  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measurementKey;

    this.options = options;
  },

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/hierarchyconfig`;
  },
});

export default HierarchyConfig;

import FeatureFlags from 'core/featureFlags';
import Constants from 'core/constants';
const getDatasourcesRoutes = () => {
  return {
    icon: 'navSurveys',
    label: 'Data Sources',
    activeTab: Constants.TAB_LABELS.DATA_SOURCES,
    controller: 'dashboards',
    permissions: !FeatureFlags.hasFeature('datasources-list')
      ? 'portal_access'
      : 'login_as',
    layout: 'datasources-list',
    favorites: {
      layout: 'favorite-datasources',
      permissions: 'portal_access',
    },
    ':id': {
      layout: 'datasources',
      permissions: 'portal_access',
      ':id': {
        layout: 'datasources-detail',
        permissions: 'portal_access',
        ':id': {
          layout: 'datasources-detail',
          permissions: 'portal_access',
          ':id': {
            controller: 'dashboards',
            layout: 'dashboard',
            card: {
              new: {
                ':id': {
                  layout: 'dashboard-detail',
                },
              },
              ':id': {
                layout: 'dashboard-detail',
              },
            },
            schedule: {
              ':id': {
                layout: 'dashboard',
              },
            },
          },
          analytics: {
            ':id': {
              layout: 'datasources-detail',
              ':id': {
                detail: {
                  layout: 'datasources-detail',
                },
              },
            },
          },
        },
      },
    },
  };
};

export default getDatasourcesRoutes;

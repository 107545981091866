import Backbone from 'backbone';
import FilterModel from 'models/clients/filters/events-filter';

var FiltersCollection = Backbone.Collection.extend({
  model: FilterModel,

  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.appKey = options.appKey || 'funcxm';
  },

  url: function () {
    const preRoute = 'dcos-internal-gateway-service/internal/in/ui-endpoints';
    return `${preRoute}/filters/clients/${this.client}/filters/appId/${this.appKey}/events`;
  },
});

export default FiltersCollection;

import Backbone from 'backbone';
import Permissions from 'core/permissions';

const ClientRolesModel = Backbone.Model.extend({
  idAttribute: 'roleId',

  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.id = options.id;
    this.defaultRole = options.defaultRole;
  },

  getUserIds: function () {
    return this.get('updatedBy');
  },

  setUserNames: function (user) {
    if (!Permissions.userRole('admin_support')) {
      this.set('assignedByUser', `ForeSee Admin`);
    }

    if (user) {
      this.set('lastUpdatedByUser', `${user.firstName} ${user.lastName}`);
      return;
    }

    this.set('lastUpdatedByUser', this.get('updatedBy'));
  },

  url: function () {
    if (this.defaultRole) {
      return `authorization-service/clients/${this.client}/roles/?defaultRole=true`;
    }

    if (this.id) {
      return `authorization-service/clients/${this.client}/roles/${this.id}`;
    }
    return `authorization-service/clients/${this.client}/roles`;
  },
});

export default ClientRolesModel;

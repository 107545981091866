import Backbone from 'backbone';
import QuestionModel from 'models/clients/projects/question';

var QuestionsCollection = Backbone.Collection.extend({
  model: QuestionModel,
  initialize: function (models, opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.project = options.projectId;
  },

  parse: function (data) {
    if (!(data instanceof Array)) {
      data = [data];
    }

    return data;
  },

  comparator: 'questionOrder',

  serviceId: 'acs-services',

  url: function () {
    var client = this.client,
      project = this.projectId;

    return `clients/${client}/projects/${project}/questions`;
  },
});

export default QuestionsCollection;

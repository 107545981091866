import Backbone from 'backbone';

var PublishModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
  },

  setProjectId: function (projectId) {
    this.project = projectId;
  },

  setEnvironment: function (env) {
    this.environment = env;
  },

  url: function () {
    return `clients/${this.client}/projects/${this.project}/publish/${this.environment}`;
  },
});

export default PublishModel;

import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

var ImpactLatent = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    latentKey: 0,
    latentType: '',
    latentName: '',
    score: 0,
    impact: 0,
    respondents: 0,
    firstDate: '2016-01-01',
    lastDate: '2016-01-01',
  },

  idAttribute: 'latentKey',

  toJSON: function () {
    const start = new moment(this.get('firstDate')).format('M/D/YYYY');
    const end = new moment(this.get('lastDate')).format('M/D/YYYY');
    const score = this.get('score');
    const impact = this.get('impact');
    return _.extend(this.attributes, {
      score: score || score === 0 ? parseFloat(score.toFixed(1)) : undefined,
      impact:
        impact || impact === 0 ? parseFloat(impact.toFixed(1)) : undefined,
      dateRange: `${start} - ${end}`,
    });
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default ImpactLatent;

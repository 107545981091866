import moment from 'moment';
import constants from 'core/constants';
import { hasFeature } from './feature-flags';

export default (dateType, hasGDFOption = false) => {
  let datePickerButtons = hasFeature('additional-date-ranges')
    ? constants.datePickerAdditionalButtons
    : constants.datePickerButtons;
  if (hasGDFOption) {
    datePickerButtons = [
      datePickerButtons[0],
      constants.datePickerCardDefault,
      ...datePickerButtons.slice(1),
    ];
  }
  if (dateType === constants.DATE_ONLY) {
    return datePickerButtons;
  }
  if (dateType === constants.DATE_MIXED) {
    // use the date only buttons, but provide the mixed yesterday/24 hour label
    return datePickerButtons.map((option) =>
      option.id === 'yesterday'
        ? { ...option, label: 'datePicker.presetMenu.mixedYesterday' }
        : option
    );
  }
  return datePickerButtons.map((option) => {
    const optionClone = { ...option };
    switch (optionClone.id) {
      case 'yesterday':
        optionClone.label = 'datePicker.presetMenu.last24Hours';
        optionClone.today = null;
        break;
      case 'last7Days':
        optionClone.start_date = new moment()
          .startOf('day')
          .subtract(6, 'days');
        optionClone.end_date = new moment();
        break;
      case 'last30Days':
        optionClone.start_date = new moment()
          .startOf('day')
          .subtract(29, 'days');
        optionClone.end_date = new moment();
        break;
      case 'last60Days':
        optionClone.start_date = new moment()
          .startOf('day')
          .subtract(59, 'days');
        optionClone.end_date = new moment();
        break;
      case 'last90Days':
        optionClone.start_date = new moment()
          .startOf('day')
          .subtract(89, 'days');
        optionClone.end_date = new moment();
        break;
      case 'last120Days':
        optionClone.start_date = new moment()
          .startOf('day')
          .subtract(119, 'days');
        optionClone.end_date = new moment();
        break;
      case 'last12Months':
        optionClone.start_date = new moment()
          .startOf('day')
          .subtract(364, 'days');
        optionClone.end_date = new moment();
        break;
    }
    return optionClone;
  });
};

import i18n from 'core/i18n';
import _ from 'underscore';

var _strings = i18n.strings;
function getString(string, model) {
  var fn = [].constructor.constructor('return arguments[0].' + string),
    str = '';

  if (string) {
    try {
      str = fn(_strings);
      return model ? _.template(str)(model) : str;
    } catch (e) {
      return '**_' + string + '_**';
    }
  } else {
    return '';
  }
}
var I18nMixin = {
  statics: {
    getString: getString,
  },

  strings: _strings,
  /**
   * Given a string of form 'a.b.c' will return value from that location in strings.
   * @param string
   */
  getString: getString,
};

export default I18nMixin;

import Backbone from 'backbone';

const AggregrationEventsModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.source = options.source;
  },

  parse: function (data) {
    if (data) {
      return {
        counts: data.metrics[0].counts,
        rates: data.metrics[1].rates,
      };
    }
    return [];
  },

  serviceId: 'big-data-services',

  url: function () {
    return `internal/in/ui-endpoints/metadata/customer/${this.client}/app/funcxm/source/${this.source}/metrics/`;
  },
});

export default AggregrationEventsModel;

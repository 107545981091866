import BaseController from 'controllers/base';
import _ from 'underscore';
import { cloneDeep } from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import guid from 'core/utils/guid';
import SurveyModel from 'models/clients/projects/survey';
import OpenEndsCollection from 'collections/clients/projects/respondents';

class FeedbackSurveyController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'feedback-survey:get': 'getSurvey',
        'feedback-survey:refresh': 'refreshPublishedState',
        'feedback-survey:update': 'updateSurvey',
        'feedback-survey:topics:reorder': 'reorderTopics',
        'feedback-survey:questions:reorder': 'reorderQuestions',
        'feedback-survey:question:add': 'addQuestion',
        'feedback-survey:question:delete': 'deleteQuestion',
        'feedback-survey:question:update': 'updateQuestion',
        'feedback-survey:topics:question:transfer': 'transferTopicQuestion',
        'feedback-survey:topic:add': 'addTopic',
        'feedback-survey:topic:clip': 'clipTopic',
        'feedback-survey:topic:copy': 'copyTopic',
        'feedback-survey:topic:delete': 'deleteTopic',
        'feedback-survey:topic:question:add': 'addTopicQuestion',
        'feedback-survey:topic:question:copy': 'copyTopicQuestion',
        'feedback-survey:topic:update': 'updateTopic',
        'feedback-survey:topic:question:delete': 'deleteTopicQuestion',
        'feedback-survey:clipboard:get': 'publishClipBoard',
        'feedback-survey:answer:add': 'addAnswer',
        'feedback-survey:preview': 'handlePreview',
        'feedback-survey:clipboard:remove': 'clipBoardRemove',
        'location:change': 'getProject',
        'feedback-settings:data': 'refreshPublishedState',
        'feedback-survey:initialize': 'initializeSurvey',
        'feedback-survey:fetch': 'fetchSurveyData',
        'feedback-survey:openends:get': 'getOpenEnds',
        'feedback-survey:upload': 'handleFileUpload',
      },
      name: 'feedback-survey',
      dependsOn: ['user', 'projects'],
      _clipBoard: [],
    });
  }

  initialize(opts) {
    var options = opts || {};

    this.clientId = options.clientId = this.getClientId();
    this.options = options;
    this.survey = new SurveyModel(false, options);
    this.surveys = {};
    this.fetchUrl = this.survey.fetchUrl;
    this.postUrl = this.survey.url;

    // If we don't have a projectId, don't fetch the survey until we do.
    if (this.projectId) {
      this.survey.projectId = this.projectId;
      this.surveys[this.projectId] = this.survey;
      return this.fetchSurveyData();
    } else {
      // Ask for current path info so we can get projectId
      this.subscribe(
        'route:data',
        _.bind(function (payload) {
          this.changeProject(payload);
        }, this)
      );
      this.publish('route:get');

      // return $.Deferred();
    }
  }

  initializeSurvey() {
    this.publish('route:get');
  }

  /**
   * Returns survey object
   */
  getSurvey(opts) {
    var data = {},
      options = opts || {},
      surveydata = null,
      projectId = options.projectId,
      callback = options.callback,
      survey,
      surveyUrl,
      fetchData = options.fetchData;

    options.clientId = this.clientId;
    options.app = this.app;

    // If these are present, we're being asked for a specific survey, not necessarily the active one.
    // Grab it and pass it to the supplied callback, storing result.
    if (callback && projectId) {
      // if (projectId) {
      if (this.surveys[projectId] && fetchData !== true) {
        surveydata = this.surveys[projectId].toJSON({ toView: true });
        callback(surveydata);
      } else {
        survey = new SurveyModel(false, options);
        survey.projectId = projectId;
        survey.topics.projectId = projectId;
        survey.questions.projectId = projectId;
        // Url we retrieve survey at is not actually the right one for the resource. Long story.
        surveyUrl = survey.url;
        survey.url = survey.fetchUrl;
        survey
          .fetch(survey.options.error ? { error: survey.options.error } : null)
          .then(() => {
            survey.url = surveyUrl;
            // Data is exported differently for view vs sending update to services
            data = survey.toJSON({ toView: true });
            this.surveys[survey.projectId] = survey;
            if (typeof callback !== 'undefined') {
              callback(data);
            }

            this.publish('app:getData', {
              key: 'feedbackQuestions',
              callback: (feedbackQuestions) => {
                feedbackQuestions = feedbackQuestions || {};
                const view = survey.toJSON({ toView: true });
                const questions = [view.questions || []];
                view.topics.forEach((t) => {
                  questions.push(t.questions);
                });
                feedbackQuestions[survey.projectId] = _.flatten(
                  questions,
                  true
                );
                this.publish('app:updateData', { feedbackQuestions });
              },
            });
          });
      }
      return;
    }

    // Don't send out survey data if we're in the process of changing projects
    // It will be sent out when we're done, anyways.
    if (this.changing) {
      return;
    }
    this.initialized.done(
      _.bind(function () {
        data = this.survey.toJSON({ toView: true });
        this.publish('feedback-survey:data', data);
      }, this)
    );
  }

  /**
   * Retrieve info on current project from path
   */
  getProject() {
    // Set the flag to show that we're changing projects and we shouldn't send out any more data until we're done.
    this.changing = true;
    this.publish('route:get');
  }

  clearSurveyData(projectId) {
    if (this.surveys[projectId]) {
      this.survey = this.surveys[projectId];
    } else {
      this.survey = new SurveyModel(false, this.options);
    }

    this.survey.projectId = projectId;
    this.survey.topics.projectId = projectId;
    this.survey.questions.projectId = projectId;
  }

  /**
   * Retrieve the hierarchical survey data
   * @param cb
   * @returns {*}
   */
  fetchSurveyData(cb) {
    var def = $.Deferred(),
      surveyUrl = this.survey.url;

    cb = cb || function () {};

    this.survey.url = this.survey.fetchUrl;
    if (isNaN(this.survey.projectId)) {
      //It means the projectId is likely the modelInstanceId, which is alphanumeric
      return;
    }
    $.when(this.survey.fetch()).then(
      _.bind(function () {
        def.resolve();
        cb();
        this.survey.url = surveyUrl;
        this.getSurvey();
      }, this)
    );

    return def;
  }

  /**
   * Update projectId for current project, if available. This affects all data for this controller.
   * This will also finish initialization of the controller once the new data is retrieved if this
   * has not yet happened.
   */
  changeProject(payload) {
    var projectId = payload && payload.pathIds && payload.pathIds.projects;

    // Don't bother if we're already at the correct project
    if (projectId === this.projectId) {
      // Clear the changing flag if we're at the correct project.
      this.changing = false;
      this.getSurvey();
      return;
    }

    if (projectId && this.survey) {
      this.projectId = projectId;

      // Clear out the old survey
      this.clearSurveyData(projectId);

      // Get the survey
      this.fetchSurveyData(
        _.bind(function () {
          // Clear the flag to indicate that we've finished changing projects and we have all the necessary data.
          this.changing = false;
          // Resolve initialized, if needed.
          if (this.initialized && this.initialized.resolve) {
            this.initialized.resolve();
          }
        }, this)
      );
    }
  }

  /**
   * Reorder questions within a topic. Submits reorder to backend, but optimistically does it here first.
   */
  reorderQuestions(data) {
    var saved = false;

    // If it's a model, we don't need to save it.
    if (data instanceof Backbone.Model) {
      data = data.toJSON();
      saved = true;
    }

    if (data && data.questionOrder > -1 && data.customQuestionId) {
      var questionOrder = data.questionOrder,
        customQuestionId = data.customQuestionId,
        topic = this._getTopicByCustomQuestionId(customQuestionId),
        positions;

      topic.questions.sort();

      positions = topic.questions.pluck('customQuestionId');

      // Remove customQuestionId of topic we're moving
      positions.splice(positions.indexOf(customQuestionId), 1);
      // Insert topicId of topic we're moving in new location
      positions.splice(questionOrder, 0, customQuestionId);

      // Iterate through topics, updating the topicOrder to match new order.
      topic.questions.each(
        _.bind(function (question) {
          question.set('questionOrder', positions.indexOf(question.id) + 1);
        }, this)
      );

      topic.questions.sort();

      // Save updated question (which will cause the backend to update questionOrder on backend for all affected questions
      // Send the updated data to the survey when the save is done.
      if (saved) {
        this.getSurvey();
      } else {
        // Get question model
        //var model = this._getQuestionById(customQuestionId);
        const model = cloneDeep(this._getQuestionById(customQuestionId));
        model.attributes.rules = JSON.stringify(model.attributes.rules);
        // When done...
        $.when(model.save()).done(
          _.bind(
            function () {
              // Check if the save passes the validation
              if (model.validationError) {
                // If invalid look for the backend data
                this.fetchSurveyData();
              } else {
                // Otherwise just update from the models
                //this.refreshPublishedState();
                this.publish(
                  'feedback-survey:questions:update',
                  topic.toJSON()
                );
                if (data.callback) {
                  data.callback();
                }
              }
            },
            this,
            model,
            data
          )
        );
      }
    }
  }

  /**
   * Reorder topics within survey. Submits change to backend.
   * @param data
   */
  reorderTopics(data) {
    let saved = false,
      options = {};

    // If it's a model, we don't need to save it.
    if (data instanceof Backbone.Model) {
      data = data.toJSON();
      saved = true;
    } else {
      // It's come from a view request, so we need to fix the topicOrder to be 1 based, as it is on the server
      data.topicOrder += 1;
    }
    if (data && data.topicOrder > 0 && data.topicId) {
      let topicOrder = data.topicOrder,
        topicId = data.topicId,
        positions;

      this.survey.topics.sort();

      // Create an array of topics by id (now sorted by topicOrder)
      positions = this.survey.topics.pluck('topicId');

      // Remove topicId of topic we're moving
      positions.splice(positions.indexOf(topicId), 1);
      // Insert topicId of topic we're moving in new location
      positions.splice(topicOrder - 1, 0, topicId);

      // Iterate through topics, updating the topicOrder to match new order.
      this.survey.topics.each(
        _.bind(function (topic) {
          topic.set('topicOrder', positions.indexOf(topic.id) + 1);
        }, this)
      );

      this.survey.topics.sort();

      // Save updated topic (which will cause the backend to update topicOrder on backend for all affected topics
      // Send the updated data to the survey when the save is done.
      if (saved) {
        this.getSurvey();
      } else {
        if (typeof data.callback !== 'undefined') {
          options.success = data.callback;
        }
        this.survey.topics
          .get(topicId)
          .save(null, options)
          .done(
            _.bind(function () {
              //this.refreshPublishedState();
              let updatedTopics = this.survey.topics.toJSON(),
                fixedQuestions = this.survey.questions.toJSON(),
                topicChooserQuestion = fixedQuestions.filter((question) => {
                  return question.questionType === 'DROP_DOWN';
                }),
                reorderedTopicAnswers = [];

              updatedTopics.forEach((topic) => {
                topicChooserQuestion[0].answers.forEach((answer) => {
                  if (answer.customAnswerId === topic.topicAnswerId) {
                    answer.displayOrder = topic.topicOrder;
                    answer.label = topic.topicText;
                    reorderedTopicAnswers.push(answer);
                  }
                }, topic);
              });

              this.updateTopicSettings();

              topicChooserQuestion[0].answers = reorderedTopicAnswers;

              fixedQuestions.forEach((question) => {
                if (question.questionType === 'DROP_DOWN') {
                  question = topicChooserQuestion[0];
                }
              });

              this.publish(
                'feedback-survey:updateTopicChooserQuestion',
                topicChooserQuestion[0].answers
              );
            }, this)
          );
      }
    }
  }

  /**
   * Create a question in the survey, submitting request to backend. Will reorder survey.
   */
  addQuestion(payload) {
    var options = {},
      question;

    // Ensure question will be displayed.
    payload.displayStatus = 'DISPLAY';

    // Handle the success response
    options.success = _.bind(function (question) {
      this.reorderQuestions(question);
    }, this);

    // Create new question
    question = this.survey.questions.create(payload, options);

    // Add question to array for tracking
    this._allQuestions.push(question);
  }

  /**
   * Remove a question from the survey, telling the backend to remove it, as well.
   */
  deleteQuestion(payload, silent) {
    var questionId = payload.customQuestionId,
      question = this._getQuestionById(questionId);

    silent = silent || payload.silent || false;

    if (question) {
      return question.destroy({
        success: _.bind(function () {
          if (!silent) {
            this.refreshPublishedState();
          }
        }, this),
      });
    }
  }

  /**
   * Create a topic within the survey. Sends request to backend.
   */
  addTopic(payload) {
    var options = {};

    // Increment topicOrder to reflect 1 based ordering on server vs 0 based array ordering in client view
    payload.topicOrder += 1;

    // Handle the success response
    options.success = _.bind(function (topic) {
      this.updateTopicSettings();
      this.reorderTopics(topic);
      if (payload.callback) {
        payload.callback();
      }
    }, this);

    // Create new topic collection
    this.survey.topics.create(payload, options);
  }

  publishClipBoard() {
    this.publish('feedback-survey:clipboard:data', this._clipBoard);
  }

  clipTopic(payload) {
    var topicPayload = {};
    topicPayload.guid = guid();
    topicPayload.topicId = payload.topicId;
    topicPayload.topicText = payload.topicText;
    topicPayload.type = 'TT_TOPIC';
    //todo: check for dupes, only push uniques
    this._clipBoard.push(topicPayload);

    this.publishClipBoard();
  }

  handleFileUpload(payload) {
    if (payload && payload.meta) {
      payload.meta.storageId = this.survey.get('modelInstanceId');
      payload.meta = JSON.stringify(payload.meta);
      this.publish('files:post', payload);
    }
  }

  /**
   * Copy topic within the survey, copies all the questions as well.
   */
  copyTopic(payload) {
    var surveyOptions = this.options;
    surveyOptions.projectId = this.projectId;
    var topicCopier = Backbone.Model.extend({
      initialize: function (model, opts) {
        this.clientId = opts.clientId;
        this.projectId = opts.projectId;
      },
      url: function () {
        var url =
          'clients/' +
          this.clientId +
          '/projects/' +
          this.projectId +
          '/topics/copy';
        return url;
      },
    });
    var newTopic = new topicCopier(null, surveyOptions);
    newTopic.set({
      topicId: payload.topicId,
      topicText: payload.topicText,
      topicOrder: payload.topicOrder,
    });
    var options = {};
    options.success = _.bind(function (data) {
      this.survey.topics.add(newTopic.toJSON());
      this.fetchSurveyData();
      this.updateTopicSettings();
    }, this);
    options.failure = _.bind(function () {}, this);
    options.saved = true;
    newTopic.save(null, options);
  }

  /**
   * Remove a topic from the survey. Deletes it on the server.
   */
  deleteTopic(payload) {
    var topic = this.survey.topics.get(payload.topicId);

    if (topic) {
      var questions = topic.questions,
        defs = [],
        qcopy = questions.slice(0),
        options = {};
    }

    if (typeof payload.callback !== 'undefined') {
      options.success = payload.callback;
    }

    _.each(
      qcopy,
      _.bind(function (question) {
        defs.push(this.deleteQuestion({ customQuestionId: question.id }, true));
      }, this)
    );
    $.when.apply($, defs, payload).done(
      _.bind(function () {
        topic.destroy(options).done(
          _.bind(
            function (payload, o, response) {
              this.publish('feedback-survey:data', {
                topics: this.survey.topics.toJSON(),
              });
              this.publish('feedback-survey:deleteTopic', payload);
              this.updateTopicSettings();
            },
            this,
            payload
          )
        );
      }, this)
    );
  }

  /**
   * Add a question to a topic. Submits to server. Will reorder survey.
   */
  addTopicQuestion(payload) {
    var topicId =
      payload.topicId ||
      this._getTopicByCustomQuestionId(payload.customQuestionId).get('topicId');

    var options = {};

    // Grab topic
    var model = this.survey.topics.get(topicId);
    var answers,
      newAnswers = [],
      newPayload = _.extend({}, payload);

    // Remove TopicId, customQuestionId no longer needed
    delete newPayload.topicId;
    delete newPayload.customQuestionId;

    answers = newPayload.answers && newPayload.answers.slice();

    if (answers && answers.length) {
      _.each(
        answers,
        _.bind(function (answer) {
          var newAnswer = _.extend({}, answer);

          delete newAnswer.value;
          delete newAnswer.customAnswerId;
          delete newAnswer.customQuestionId;

          newAnswers.push(newAnswer);
        }, this)
      );
    }

    // Fix zero index for backend
    newPayload.questionOrder += 1;

    // Success callback
    options.success = _.bind(function () {
      // Add question to array for tracking
      if (!this._allQuestions) {
        this._getAllQuestions();
      } else {
        this._allQuestions.push(question);
      }

      // This is kind of weird but before we reorder all of
      // our questions we need to set the questionOrder to array
      // index as that is what reorderQuestions() is expecting.
      question.set('questionOrder', newPayload.questionOrder - 1);

      // Order all our questions with the new question as the insert
      this.reorderQuestions(question);

      var answerOptions = {};

      // Refresh Publish State only once the answers response comes back from the server.  Need the customAnswerIds.
      answerOptions.success = _.bind(function () {
        this.refreshPublishedState();
      }, this);

      _.each(
        newAnswers,
        _.bind(function (answer) {
          question.answers.create(answer, answerOptions);
        }, this)
      );

      //Refresh published state now if it's a quesiton that doesn't have answers. Ie. Star Rating, Comment.
      if (!newAnswers.length) {
        this.refreshPublishedState();
      }
    }, this);

    // Remove answers before sending.
    delete newPayload.answers;

    // Add the question to the collection
    var question = model.questions.add(newPayload);

    question.save(null, options);
  }

  /**
   * Copy a question in the survey to the clipboard (in memory only)
   */
  copyTopicQuestion(payload) {
    payload.guid = guid();
    // payload.type = "CLIP_QUESTION";
    //todo: check for dupes, only push uniques
    this._clipBoard.push(payload);

    this.publishClipBoard();
  }

  clipBoardRemove(guid) {
    var clipToRemove = _.findWhere(this._clipBoard, { guid: guid });
    var index = this._clipBoard.indexOf(clipToRemove);
    this._clipBoard.splice(index, 1);
    this.publishClipBoard();
  }

  addAnswer(payload) {}

  /**
   * Transfer a question from one topic to another
   **/
  transferTopicQuestion(payload) {
    // var destTopicId = payload.destTopicId,
    //     questionComponent = payload.component,
    //     origTopicId = questionComponent.props.parentId;
    // Grab topic
    // var destTopic = this.survey.topics.get(destTopicId),
    //     origTopic = this.survey.topics.get(origTopicId);
  }

  /**
   * Remove a question from a topic. Submits to server. Will reorder.
   */
  deleteTopicQuestion(payload) {
    var model = this.survey.topics.get(payload.topicId);
    model.questions
      .get(payload.customQuestionId)
      .destroy()
      .done(
        _.bind(function () {
          this.refreshPublishedState();
        }, this)
      );
  }

  handlePreview(payload) {
    var mid = this.survey.get('modelInstanceId');

    if (payload && payload.previewSurvey) {
      this.publish('app:showModal', { modal: payload.modal, mid: mid });
    }
  }

  /**
   * Updates an answer model and returns true if any values changed, false if all are the same.
   * @param answer
   */
  updateAnswer(attributes, answers) {
    var changed = false,
      id = attributes.customAnswerId,
      model = answers.get(id);
    _.each(
      attributes,
      _.bind(function (attr, key) {
        var oldVal = model.get(key);

        //don't compare these keys as they are constantly in flux depending on UI interaction
        if (key !== 'selected' && key !== 'disabled' && key !== 'value') {
          if (attr !== oldVal) {
            model.set(key, attr);
            changed = true;
          }
        }
      }, this)
    );

    return changed;
  }

  /**
   * Change a question. Submits to server.
   */
  updateQuestion(payload) {
    this._getAllQuestions();
    var questionId = payload.customQuestionId,
      question = this._getQuestionById(questionId),
      questionAnswers = question.answers || [],
      answers = payload.answers || [],
      deleted = payload.answers
        ? _.difference(
            questionAnswers.pluck('customAnswerId'),
            _.pluck(answers, 'customAnswerId')
          )
        : [],
      defs = [];
    // logic = payload.rules || '[]'; // Array of promises to track answers saving to server

    if (question) {
      if (answers.length) {
        // Check each answer to see if they are new or if they have an answerId.
        _.each(
          answers,
          _.bind(function (answer, index) {
            var answerId = answer.customAnswerId,
              answerModel = question.answers.get(answerId),
              changed = false;

            answer.displayOrder = index + 1;

            if (answerModel) {
              changed = this.updateAnswer(answer, question.answers);
              if (changed) {
                answerModel.unset('value'); //value not needed by backend and is interfering with their stuff when sent.
                defs.push(answerModel.save());
              }
            } else {
              answerModel = question.answers.add(answer);
              answerModel.unset('value'); //value not needed by backend and is interfering with their stuff when sent.

              defs.push(answerModel.save());
            }
          }, this)
        );
      }

      // Check for deleted answers
      if (deleted.length) {
        _.each(
          deleted,
          _.bind(function (answerId) {
            var model = question.answers.get(answerId);

            if (model && model.destroy) {
              defs.push(model.destroy());
            }
          }, this)
        );
      }

      question.set(payload);

      $.when.apply($, defs).done(
        _.bind(function (a, b, c, d) {
          if (defs[defs.length - 1] === false) {
            this.publish('app:messageFlash', {
              messageType: 'error',
              messageText: '* Question length is invalid *',
            });
          } else if (defs.length > 1) {
            if (
              _.some(defs, function (def) {
                return def === false;
              })
            ) {
              this.publish('app:messageFlash', {
                messageType: 'error',
                messageText: '* Answer length is invalid *',
              });
            }
          }
          question.save().then(
            _.bind(
              function () {
                //this.refreshPublishedState();
                if (payload.callback) {
                  payload.callback();
                }
              },
              this,
              payload
            )
          );
        }, this)
      );
    }
  }

  /**
   * Update a topic
   **/
  updateTopic(payload) {
    var topic = this.survey.topics.get(payload.topicId);

    topic.set(payload);

    /*      // On success broadcast the new data
      options.success = _.bind(function (topic) {
        this.publish("survey:get");
      }, this);

      // Just update data on error for now
      options.error = _.bind(function (topic) {
        this.publish("survey:get");
      }, this);*/

    topic.save().done(
      _.bind(
        function () {
          var data = this.survey.toJSON({ toView: true });
          payload.topics = data.topics;
          this.publish('feedback-survey:updateTopicText', payload);
          if (payload.callback) {
            payload.callback();
          }

          this.updateTopicSettings();
        },
        this,
        payload
      )
    );
  }

  /**
   * Update a survey with payload data
   */
  updateSurvey(payload) {
    var survey = this.survey;

    payload.surveyId = survey.get('surveyId');
    payload.refresh =
      typeof payload.refresh === 'undefined' ? true : payload.refresh;
    survey.url = this.postUrl;
    survey.set(payload);

    survey.save().done(
      _.bind(
        function () {
          if (payload.refresh) {
            this.getSurvey();
          }
          if (payload.callback) {
            payload.callback();
          }
        },
        this,
        payload
      )
    );
  }

  /**
   * Update project settings for topic changes
   */
  updateTopicSettings() {
    this.publish('feedback-settings:fetch', { projectId: this.projectId });
  }

  refreshPublishedState(x, y) {
    if (this.projectId) {
      this.survey.fetch().done(
        _.bind(function () {
          this.getSurvey();
        }, this)
      );
    }
  }

  _getAllQuestions() {
    var questions = [];

    questions.push(this.survey.questions.slice(0));

    this.survey.topics.forEach(
      _.bind(function (topic) {
        questions.push(topic.questions.slice(0));
      }, this)
    );
    this._allQuestions = _.flatten(questions);
  }

  _getQuestionById(customQuestionId) {
    if (!this._allQuestions) {
      this._getAllQuestions();
    }

    var questions = _.filter(
      this._allQuestions,
      _.bind(function (question) {
        return question.id === customQuestionId;
      }, this)
    );

    return questions[0];
  }

  _getTopicByCustomQuestionId(customQuestionId) {
    var foundTopic = null;
    this.survey.topics.forEach(
      _.bind(function (topic) {
        topic.questions.forEach(
          _.bind(function (question) {
            if (question.get('customQuestionId') === customQuestionId) {
              foundTopic = topic;
            }
          }, this)
        );
      }, this)
    );
    return foundTopic;
  }

  getOpenEnds(payload) {
    const { projectId, dateRange, callback, questionIds, metrics } = payload;
    let data = new OpenEndsCollection(false, {
      clientId: this.getClientId(),
      projectId: projectId,
    });
    let criteria = {
      dateRange: dateRange,
    };
    _.extend(data, {
      urlparams: {
        criteria: JSON.stringify(criteria),
      },
    });
    data.fetch().then((data) => {
      const { respondents } = data;

      let relevantResponses = [];
      respondents.forEach((r) => {
        let answers = _.filter(r.answers, (a) => {
          return _.contains(questionIds, a.questionId);
        });
        if (!_.isEmpty(answers)) {
          if (metrics && metrics[0] && metrics[0].key) {
            let metric = _.find(r.answers, (a) => {
              return a.questionId === metrics[0].key && a.answerValue;
            });
            r.metricScore = metric.answerValue;
          }
          let row = {
            comments: answers,
            responseDate: r.responseDate,
            rating: r.metricScore ? r.metricScore : r.rating,
            respondentKey: r.respondentId,
            topicName: r.topicName,
          };
          relevantResponses.push(row);
        }
      });
      data.validTotals = relevantResponses.length;
      data.relevantResponses = relevantResponses;
      if (callback) {
        callback(data);
      }
    });
  }
}

export default FeedbackSurveyController;

import Backbone from 'backbone';
import InsightsOpenEndModel from 'models/clients/projects/respondent';
import JsonParse from 'core/utils/json-parse';

var InsightsOpenEndsCollection = Backbone.Collection.extend({
  model: InsightsOpenEndModel,

  urlparams: {},

  initialize: function (models, opts) {
    var options = opts || {};

    this.clientId = options.clientId;
    if (options.projectId) {
      this.projectId = options.projectId;
    }
  },

  serviceId: 'acs-services',

  url: function () {
    var clientId = this.clientId,
      projectId = this.projectId,
      respondentId = this.respondentId || '', // Get individual respondent
      respondent = !!respondentId
        ? '/respondents/' + respondentId
        : '/respondents';

    // /clients/{clientId}/projects/{projectId}/respondents
    // /clients/{clientId}/projects/{projectId}/respondents
    // /clients/{clientId}/projects/{projectId}/respondents/{respondentId}
    return 'clients/' + clientId + '/projects/' + projectId + respondent;
  },

  parse: function (data) {
    var respondents = (data && data.respondents) || [];
    //TODO -- Add handling for pagination to interface so that it can be dealt with here
    this.totalRespondents = data.totalRespondents || 0;
    return JsonParse.stripHtml(respondents);
  },
});

export default InsightsOpenEndsCollection;

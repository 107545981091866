import Backbone from 'backbone';
import '@foresee/backbone-oauth-1.0a';
import store from './store';
import constants from './constants';

const options = {
  consumerSecret: constants.CONSUMER_SECRET,
  consumerKey: constants.CONSUMER_KEY,
};

/**
 * Manages Authentication state of application
 **/
class OAuth {
  constructor() {
    this.oauth = new Backbone.OAuth(options);
  }

  setTokens(accessToken, tokenSecret) {
    this.accessToken = encodeURIComponent(accessToken);
    this.tokenSecret = encodeURIComponent(tokenSecret);

    store.set('accessToken', this.accessToken);
    store.set('tokenSecret', this.tokenSecret);
  }

  clearTokens() {
    store.set('accessToken', '');
    store.set('tokenSecret', '');
  }

  authenticated() {
    return !!(store.get('accessToken') && store.get('tokenSecret'));
  }

  apiRequest(url, options) {
    const tokenSecret = store.get('tokenSecret');
    const accessToken = store.get('accessToken');

    return this.oauth.apiRequest(url, tokenSecret, accessToken, options);
  }

  createAuthorizationHeader({ url, reqType }) {
    const hg = this.oauth.headerGenerator();
    return this.oauth.authHeader(hg(reqType, url, ''));
  }
}

export default new OAuth();

import Backbone from 'backbone';

var FilterMetaDataModel = Backbone.Model.extend({
  initialize: function (attributes, opts) {
    this.addOptions(opts);
  },

  addOptions: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;

    this.options = options;
  },

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/modelfilters/metadata`;
  },

  serviceId: 'foresee-services',
});

export default FilterMetaDataModel;

import Backbone from 'backbone';

const DashboardShareModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.dashboardId = options.dashboardId;
    this.shareId = options.shareId;
    this.limit = options.limit || 25;
    this.offset = options.offset || 0;
  },

  idAttribute: 'shareId',

  url: function () {
    return `clients/${this.client}/dashboards/${this.dashboardId}/share/${
      this.shareId ? this.shareId : ''
    }?offset=${this.offset}&limit=${this.limit}`;
  },
});

export default DashboardShareModel;

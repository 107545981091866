import BaseController from 'controllers/base';
import _ from 'underscore';

/**
 * Controller for communicating via postMessage.
 */
class PostMessageController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'post-message',
      actions: {
        'app:authenticated': 'sendLoggedIn',
        'insights:respondentsCount': 'sendResponses',
        'location:change': 'sendLocationChange',
      },
    });
  }

  initialize(opts) {
    this.sendLoggedIn();

    // Set up listeners for incoming postMessages
    window.addEventListener('message', _.bind(this.onMessage, this), false);
  }

  onMessage(event) {
    // Block messages from inner iframes
    const iframes = document.getElementsByTagName('iframe');

    for (let i = 0; i < iframes.length; i++) {
      if (event.source === iframes[i].contentWindow) {
        return false;
      }
    }

    const data = event.data;
    // Send authentication state back to on-site code
    if (data.wIsLoggedIn) {
      this.sendLoggedIn();
    }

    // Send page location to insights controller
    if (data.wPageUrl) {
      this.lastPage = data.wPageUrl;
      this.publishPageUrl();
    }

    const origin = window.origin || window.location.origin;
    if (origin && event.origin !== origin && this.isOriginValid(event.origin)) {
      this.publish('postmessage:acknowledged', event);
    }
  }

  isOriginValid(origin) {
    return origin !== 'https://gateway.foresee.com';
  }

  // Sending route updates to the parent window.
  sendLocationChange() {
    parent.postMessage(
      {
        location: window.location.href,
      },
      '*'
    );
  }

  sendLoggedIn() {
    parent.postMessage({ isLoggedIn: true }, '*');
  }

  /**
   * Send response total to on-site code
   */
  sendResponses(payload) {
    var resCount = _.isNumber(payload.numResponses) ? payload.numResponses : 0;
    parent.postMessage(
      {
        feedbackResponses: resCount,
      },
      '*'
    );
  }

  // Sending route updates to the contained window.
  publishPageUrl() {
    this.publish('insights:page', {
      location: this.lastPage,
    });
  }
}

export default PostMessageController;

import $ from 'jquery';
import _ from 'underscore';
import BaseController from 'controllers/base';
import CodeVersionCollection from 'collections/clients/settings/integrate/code-versions';
import SitesCollection from 'collections/clients/settings/integrate/sites';
import GlobalConfigModal from 'models/clients/settings/integrate/global-config';
import PromoteGlobalConfigModal from 'models/clients/settings/integrate/promote-global-config';

class HostedCodeController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'hosted:get:versions': 'getCodeVersions',
        'hosted:get:sites': 'getSites',
        'hosted:get:latestsites': 'getLatestSites',
        'hosted:site:update': 'updateSiteKeyAlias',
        'hosted:get:configs': 'getGlobalConfigs',
        'hosted:get:config': 'getGlobalConfig',
        'hosted:post:config': 'postGlobalConfig',
        'hosted:promote:staging': 'promoteStagingToProduction',
      },
      name: 'hosted-code',
    });

    this.sites = false;
  }

  initialize() {
    this.client = this.getClientId();
    this.globalConfig = {};
  }

  promoteStagingToProduction(payload) {
    const { site, callback } = payload;

    const promote = new PromoteGlobalConfigModal(null, {
      clientId: this.client,
      siteId: site,
      containerId: 'staging',
      targetContainerId: 'production',
    });

    promote.save().then(() => {
      const data = promote.toJSON();

      if (data.status === 'failure') {
        this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*A server error occurred*',
        });
        callback(true);
        return;
      }

      callback();
    });
  }

  postGlobalConfig(payload) {
    const { container, config, site, callback } = payload;

    //If a config doesn't exist for this sitekey yet, we need to create a new one.
    if (!this.globalConfig[container]) {
      const attributes = {
        config: {},
      };
      this.globalConfig[container] = new GlobalConfigModal(attributes, {
        clientId: this.client,
        siteId: site,
        containerId: container,
      });
    }

    let updatedConfig = _.extend(
      this.globalConfig[container].get('config'),
      config
    );

    this.globalConfig[container].set({
      posting: true,
    });
    this.globalConfig[container]
      .save({
        config: JSON.stringify(updatedConfig),
      })
      .then(() => {
        const data = this.globalConfig[container].toJSON();

        if (data.status === 'failure') {
          this.publish('app:messageFlash', {
            messageType: 'error',
            messageText: '*A server error occurred*',
          });
          callback(true);
          return;
        }

        callback();
      });
  }

  getGlobalConfigs(payload) {
    const { callback, containers, site } = payload;
    const defs = [];

    for (let i = 0; i < containers.length; i++) {
      defs.push(
        this.getGlobalConfig({
          container: containers[i],
          site: site,
        })
      );
    }

    Promise.all(defs).then((response) => {
      const configs = [];

      for (let i = 0; i < response.length; i++) {
        configs.push(response[i]);
      }

      callback(configs);
    });
  }

  getGlobalConfig(payload) {
    const def = $.Deferred();
    const { callback, site, container } = payload;

    const globalConfig = new GlobalConfigModal(null, {
      clientId: this.client
        ? this.client
        : this.controllers['user'] && this.controllers['user'].client
        ? this.controllers['user'].client.get('clientId')
        : null,
      siteId: site,
      containerId: container,
    });
    globalConfig
      .fetch({
        error: () => {
          def.resolve();
          //Clear current model if config doesn't exist yet.
          this.globalConfig[container] = null;
          if (callback) {
            callback();
          }
        },
        container,
      })
      .then((response) => {
        const data = {
          container: container,
          data: response,
        };

        this.globalConfig[container] = globalConfig;

        def.resolve(data);
        if (callback) {
          callback(data);
        }
      });
    return def;
  }

  getSites(payload) {
    if (this.sites) {
      this._dispatchSites(payload);
    } else {
      this.client = this.getClientId();
      if (this.client === null) {
        return;
      }
      const { errorCallback } = payload;
      const sites = new SitesCollection({ clientId: this.client });
      sites
        .fetch({
          error: (err) => {
            if (err._ajaxRef.status === 404) {
              if (errorCallback) {
                errorCallback(err);
              }
            }
          },
        })
        .then(() => {
          this.sites = sites;
          this._dispatchSites(payload);
        });
    }
  }

  getLatestSites(payload) {
    this.client = this.getClientId();
    if (this.client === null) {
      return;
    }
    const { errorCallback } = payload;
    const sites = new SitesCollection({ clientId: this.client });
    sites
      .fetch({
        error: (err) => {
          if (errorCallback) {
            errorCallback(err);
          } else {
            this.publish('app:responseStatus', err);
          }
        },
      })
      .then(() => {
        this.sites = sites;
        this._dispatchSites(payload);
      });
  }

  _dispatchSites(options) {
    const { callback, errorCallback } = options;
    const data = this.sites.toJSON();
    if (data.length > 0) {
      callback(data);
    } else {
      if (errorCallback) {
        errorCallback();
      }
    }
  }

  getCodeVersions(payload) {
    const { callback } = payload;

    const versions = new CodeVersionCollection();
    versions.fetch().then(() => {
      callback(versions.toJSON());
    });
  }

  /**
   * Return the notes with new alias , old alias and userid
   * @param {*} oldValue
   * @param {*} newValue
   */

  getNotes(oldValue, newValue) {
    let siteNote = this.strings.globalSettings.hostedCode.SITE_NOTE;
    let userId =
      this.controllers && this.controllers.user
        ? this.controllers.user.getString('user').username
        : '';
    return (
      siteNote +
      ' ' +
      oldValue +
      ' to newvalue: ' +
      newValue +
      ' by userid: ' +
      userId
    );
  }

  /**
   * Checks whether the site key alias already exist or not
   * @param {*} newAlias
   * @param {*} sites
   */

  isDuplicateSiteKeysExist(newAlias, sites) {
    let existingSite;
    existingSite = _.filter(sites, (s) => {
      return newAlias === s.alias;
    });
    return existingSite.length > 0 ? true : false;
  }

  /**
   * Updates Alias value at backend
   * @param {*} payload
   */

  updateSiteKeyAlias(payload) {
    /* eslint no-unused-vars: 0 */
    //get site object from the site collection
    const { oldSite, newAlias, sites, callback } = payload;
    const siteKey = oldSite.name;

    if (!this.isDuplicateSiteKeysExist(newAlias, this.sites.toJSON())) {
      const site = this.sites.get(siteKey);

      site.set({
        alias: newAlias,
        notes: this.getNotes(oldSite.alias, newAlias),
      });

      site.save().then(
        () => {
          let newSites;
          // get sites with updated alias name
          this.publish('hosted:get:latestsites', {
            callback: (sites) => {
              newSites = sites;
              let selectedSite = _.find(newSites, (s) => {
                return s.name === site.id;
              });
              // call the callback to update the state
              if (callback) callback(selectedSite, newSites);
            },
            errorCallback: (err) => {
              this.publish('app:responseStatus', err);
            },
          });
        },
        () => {
          this.publish('app:messageFlash', {
            messageText:
              this.strings.globalSettings.hostedCode.INVALID_CHARACTERES,
            messageType: 'error',
          });
        }
      );
    } else {
      this.publish('app:messageFlash', {
        messageText:
          this.strings.globalSettings.hostedCode.DUPLICATE_SITE_ALIAS,
        messageType: 'error',
      });
    }
  }
}

export default HostedCodeController;

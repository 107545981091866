import Backbone from 'backbone';

var DetailModel = Backbone.Model.extend({
  idAttribute: 'clientId',
  url: function () {
    return 'clients/' + this.id + '/details/';
  },
});

export default DetailModel;

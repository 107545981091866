import Constants from 'core/constants';
import { hasFeature } from 'core/utils/feature-flags';

const DATE_TIME_DATA_SOURCES = [
  Constants.DASHBOARD.sourceType.feedback,
  Constants.DASHBOARD.sourceType.customFeed,
  Constants.DASHBOARD.sourceType.definition,
];

export const supportsDateTime = (chartType, dataSources, taQuestions) => {
  if (!dataSources?.length) return false;
  const isWordCloud = chartType === Constants.DASHBOARD.chartTypes.wordCloud;
  const hasTaTimezonesFeature = hasFeature('ta-timezones');
  if (isWordCloud) {
    return wordCloudSupportsDateTime(dataSources, taQuestions);
  } else {
    const { type, schemaId } = dataSources[0];
    return (
      type === Constants.DASHBOARD.sourceType.feedback ||
      (type === Constants.DASHBOARD.sourceType.customFeed &&
        hasTaTimezonesFeature) ||
      (type === Constants.DASHBOARD.sourceType.definition &&
        doesDefinitionSupportDateTime(schemaId))
    );
  }
};

//temporarly limit definitions to DATE_ONLY until we add further support (e.g. to handle DATE_TIME fiscal)
const doesDefinitionSupportDateTime = (schemaId) => {
  return schemaId === Constants.SCHEMAS.INTERACTION_ANALYTICS;
};

const wordCloudSupportsDateTime = (dataSources, taQuestions) => {
  const hasTaTimezonesFeature = hasFeature('ta-timezones');
  if (!hasTaTimezonesFeature) return false;

  if (dataSources.length === 1) {
    const { type } = dataSources[0];
    return isDateTime(type);
  } else if (taQuestions && taQuestions.length > 0) {
    const dataSourcesWithQuestions = taQuestions
      .map((q) => q.dataSourceKey)
      .filter((d) => d);

    if (dataSourcesWithQuestions.length > 0) {
      const filtered = dataSources.filter((d) =>
        dataSourcesWithQuestions.includes(d.key)
      );
      return areDateTime(filtered);
    }
  }
  return areDateTime(dataSources);
};

const isDateTime = (type) => DATE_TIME_DATA_SOURCES.includes(type);

const areDateTime = (dataSources) => {
  return dataSources.every((ds) => isDateTime(ds.type));
};

import Backbone from 'backbone';
import MasterDataModel from 'models/clients/masterdata';

const MasterDataCollection = Backbone.Collection.extend({
  model: MasterDataModel,
});
const MasterDataPageableModel = Backbone.Model.extend({
  defaults: {
    total: 0,
    results: [],
  },

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.typeId = options.typeId;
    this.offset = options.offset;
    this.limit = options.limit;
    this.sort = options.sort;
    this.search = options.search;
  },

  parse: function (data) {
    data.results = new MasterDataCollection(data.results, {
      parse: true,
    }).toJSON();
    return data;
  },

  url: function () {
    const { search, sort } = this;
    let query = {
      typeId: this.typeId,
      offset: this.offset,
      limit: this.limit,
    };

    if (search) {
      let searchTerm = search.value;
      if (search.type === 'LIKE') {
        searchTerm = `%${searchTerm}%`;
        query.filterType = 'LIKE';
      }
      query[search.key] = searchTerm;
    }

    if (sort) {
      const { field, direction } = sort;
      query.sort = `${field}:${direction}`;
    }

    return `clients/${this.client}/masterdata?${new URLSearchParams(query)}`;
  },
});

export default MasterDataPageableModel;

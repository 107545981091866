import Backbone from 'backbone';

const HierarchyModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.id = options.id;
  },

  url: function () {
    if (this.id) {
      return `clients/${this.client}/hierarchies/${this.id}`;
    }
    return `clients/${this.client}/hierarchies`;
  },
});

export default HierarchyModel;

import BaseController from 'controllers/base';
import ApiConsumerModel from 'models/clients/settings/integrate/consumers';
import ApiConsumerCollection from 'collections/clients/settings/integrate/consumers';

class ApiAdministrationController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'api-administration:consumers:get': '_getConsumers',
        'api-administration:consumers:create': '_createConsumer',
        'api-administration:consumers:update': '_updateConsumer',
        'api-administration:consumers:delete': '_deleteConsumer',
      },
      dependsOn: ['user'],
      name: 'api-administration',
    });
  }
  initialize(opts) {
    var options = opts || {},
      app = options.app,
      clientId = this.getClientId();
    this.app = app;
    this.client = clientId;
    this.user = this.strings.user;
  }
  _getConsumers(payload = {}) {
    let consumers = new ApiConsumerCollection({
      clientId: payload.id || '',
      clientKey: payload.clientKey || this.client,
    });
    return consumers.fetch().then(() => {
      let data = consumers.toJSON();
      this.publish('app:updateData', {
        apiCredentialData: data,
      });
    });
  }
  _createConsumer(payload = {}) {
    let callback = payload.callback;
    //Client Name, Client Type, Application Name and Consumer Type are mandatory fields.
    let criteria = {
      client_key: this.client,
      application_name: payload.name,
      client_type: 'confidential',
      consumer_type: 'PUBLIC_API_TIER_1',
      scopes: [payload.permissions],
      resource_ids: [payload.source],
      associated_application_user_key: this.user.userId,
    };
    let consumers = new ApiConsumerCollection(criteria);
    consumers.create(criteria, {
      success: (data) => {
        if (callback) {
          callback(data);
        } else {
          this.publish('api-administration:consumer:created', data);
        }
      },
    });
  }
  _updateConsumer(payload = {}) {
    let callback = payload.callback;
    let criteria = {
      clientId: payload.client_id,
      client_key: this.client,
      application_name: payload.name,
      client_type: 'confidential',
      consumer_type: 'PUBLIC_API_TIER_1',
      scope: [payload.permissions],
      resource_ids: [payload.source],
      associated_application_user_key: this.user.userId,
    };
    let consumer = new ApiConsumerModel(criteria);
    return consumer.save(criteria, {
      success: (data) => {
        if (callback) {
          callback(data);
        } else {
          this.publish('api-administration:consumer:updated', data);
        }
      },
    });
  }
  _deleteConsumer(payload) {
    let id = payload.id,
      callback = payload.callback,
      consumer = new ApiConsumerModel({
        clientId: id,
      });
    return consumer.destroy({
      success: (data) => {
        if (callback) {
          callback(data);
        } else {
          this.publish('api-administration:consumer:deleted', data);
        }
      },
    });
  }
}

export default ApiAdministrationController;

import Backbone from 'backbone';
import ProjectSummaryModel from 'models/clients/projectsummary';

var ProjectSummariesCollection = Backbone.Collection.extend({
  model: ProjectSummaryModel,
  initialize: function (models, opts) {
    var options = opts || {};

    this.client = options.clientId;
  },

  serviceId: 'acs-services',

  url: function () {
    return `clients/${this.client}/projectsummaries`;
  },
});

export default ProjectSummariesCollection;

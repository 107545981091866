import BaseController from 'controllers/base';

class ConfigController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'location:change': 'updateRoute',
        'config:get': 'getConfig',
        'config:set': 'setConfig',
        'config:clear': 'clearConfig',
      },
      name: 'config',
      dependsOn: ['user'],
    });
  }

  initialize() {
    this.publish('route:get', {
      callback: (payload) => {
        this.getConfig(payload);
      },
    });
  }

  setConfig() {}

  getConfig(payload) {
    console.log('getConfig: ', payload);
  }

  updateRoute() {}

  isExpired() {}

  clearConfig() {}
}

export default ConfigController;

import _ from 'underscore';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import Constants from 'core/constants';
import BaseController from 'controllers/base';
import DashboardDataModel from 'models/clients/carddata';
import JSTimezoneDetect from 'jstimezonedetect';
import { isOldModelInsights, hasFeature } from 'core/utils/feature-flags';
import { canFetchCardData } from 'core/utils/card';
import hasCxmeasureBDSource from 'core/utils/has-cxmeasurebd-source';
import addDimensionForKPI from 'core/utils/add-dimension-for-kpi';
import featureFlags from 'core/featureFlags';
import mixinCardDate from 'core/utils/mixin-card-date';
import { isCardDataEmpty } from 'views/panels/dashboards/utils/carddata';

const { ODI } = Constants;

class DashboardsDataController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'dashboards-data:dashboard:get': '_getDashboardData',
        'dashboards-data:card:get': '_getCardData',
        'dashboards-data:card:reset': '_resetCardData',
        'dashboards-data:card:reset:wordcloud': '_resetWordcloudData',
        'dashboards-data:card:rest:get': '_getRestData',
        'dashboards-data:card:rest:data:get': '_getCardRestData',
        'dashboards-data:card:rest:set': '_setRestData',
        'dashboards-data:card:rest:poll': '_pollCardRestData',
        'dashboards-data:odi:cancel': '_cancelOdi',
        'dashboards-data:odi:get': '_getOdi',
        'dashboards-data:card:rest:unset': '_unsetRestData',
        'dashboards-data:global-date-filter:set': '_setGlobalDateFilter',
      },
      name: 'dashboards-data',
      dependsOn: [
        'user',
        'authorization',
        'cxmeasure',
        'collection-events',
        'dates',
        'text-analytics-config',
      ],
    });

    this.offsetLoadGuard = null;
    this.currentCards = [];
    this.pendingUpdates = {};
    this.pendingCardRequests = {};
    this.globalDateFilter = {
      isEnabled: false,
      isCardFiltering: false,
    };
  }

  initialize(opts) {
    const options = opts || {};
    options.clientId = this.getClientId();
  }

  _getAttribute(card, key, fallback) {
    if (isOldModelInsights()) {
      //Until we get ODI, we need to restrict filters on Model Insights dashboards
      if (key === 'dateRange') {
        return {
          k: this.getClientId(),
          c: 'G', //this.baseDate.c,
          r: 'MONTHS',
          n: 1,
          p: 'DEFINED',
          a: new moment().startOf('month').format('YYYY-MM-DD'),
          f: null,
          l: null,
          vndType: 'application/vnd.answers.date.range',
        };
      } else if (key === 'selectedDateId') {
        return 'lastMonth';
      } else if (
        _.contains(['modelFilterId', 'pageFilterId', 'hierarchyFilterId'], key)
      ) {
        return false;
      }
    }

    if (card && card.metadata && card.metadata.attributes) {
      return card.metadata.attributes[key];
    }
    return fallback;
  }

  _getCurrentCardData(cardId, callback) {
    this.publish('app:getData', {
      key: 'cardDetailData',
      callback: (cardDetailData) => {
        cardDetailData =
          cardDetailData && cardDetailData.cardId === cardId
            ? cardDetailData
            : {};
        callback(cardDetailData);
      },
    });
  }

  _resetCardData(payload) {
    this._updateCardData(
      {
        ...payload,
        isOnDetail: true,
      },
      false
    );
  }

  _getDateDisplay(card, data) {
    const chartType = this._getAttribute(card, 'chartType');
    const dateRange = this._getAttribute(card, 'dateRange');
    const selectedDateId = this._getAttribute(card, 'selectedDateId');
    const datePickerButtons = hasFeature('additional-date-ranges')
      ? Constants.datePickerAdditionalButtons
      : Constants.datePickerButtons;
    const selectedButton = _.findWhere(datePickerButtons, {
      id: selectedDateId,
    });

    if (chartType === Constants.DASHBOARD.chartTypes.priorityIndex) {
      return this.getString('general.multipleDates');
    } else if (dateRange && dateRange.f && dateRange.l) {
      return this._parseRange(dateRange);
    } else if (selectedButton && selectedDateId !== 'custom') {
      if (dateRange.t === 'DATE_TIME' && selectedDateId === 'yesterday') {
        return this.getString('datePicker.presetMenu.last24Hours');
      }
      return this.getString(selectedButton.label);
    } else if (
      data &&
      data.series &&
      data.series.length &&
      data.series[0].parsedDateRange
    ) {
      return data.series[0].parsedDateRange;
    } else if (chartType === Constants.DASHBOARD.chartTypes.gauge) {
      return data.primaryScore ? data.primaryScore.parsedDateRange : '';
    } else if (
      chartType === Constants.DASHBOARD.chartTypes.voc &&
      data.dateRange
    ) {
      return this._parseRange(data.dateRange);
    }
    return '';
  }

  //Only update appData once all card data is loaded to prevent too much thrashing
  _updateCardData(card, data, callback) {
    if (card.isOnDetail) {
      this.publish('app:updateData', {
        cardDetailData: {
          ...data,
          cardId: card.cardId,
        },
      });
      return;
    }
    const d = data
      ? {
          ...data,
          dateRangeDisplay: this._getDateDisplay(card, data),
        }
      : d;
    const { cardId } = card;
    this.pendingUpdates[cardId] = d;
    if (this.currentCards) {
      const hasAllData = !_.find(this.currentCards, (c) => {
        return !this.pendingUpdates[c.cardId];
      });
      if (hasAllData) {
        this.publish('app:getData', {
          key: 'cardData',
          callback: (cardData) => {
            cardData = cardData || {};
            this.publish('app:updateData', {
              cardData: {
                ...cardData,
                ...this.pendingUpdates,
              },
            });
            this.currentCards = [];
            this.pendingUpdates = {};
          },
        });
      }
    }
  }

  _parseRange(range) {
    const start = new moment(range.f).format(Constants.dateDisplay);
    const end = new moment(range.l).format(Constants.dateDisplay);
    return `${start} to ${end}`;
  }

  _getAllCardData(done) {
    this.publish('app:getData', {
      key: 'cardData',
      callback: (cardData) => {
        this.publish('app:getData', {
          key: 'cardRestData',
          callback: (cardRestData) => {
            done({
              cardData: cardData || {},
              cardRestData: cardRestData || {},
            });
          },
        });
      },
    });
  }

  _getDashboardData({ cards, isReload = false, odiRefresh = false }) {
    this.currentCards = [];

    if (isReload || odiRefresh) {
      this._cancelPollCardRestData();
    }

    this._getAllCardData((allCardData) => {
      const { cardData, cardRestData } = allCardData;
      this.getCurrentDashboardType((dashboardType) => {
        const isCustomDashboards = dashboardType === 'CUSTOM';

        // reset rest cards as necessary
        cards.forEach((card) => {
          if (!isCustomDashboards || isReload || odiRefresh) {
            cardRestData[card.cardId] = false;
          }
        });

        // set the 'offsetLoadGuard' - this is so that if a different set of
        // cards is requested it will cause any previous loop that is still
        // running to exit.
        const guard = new Date();
        this.offsetLoadGuard = guard;
        const timeZone = JSTimezoneDetect.determine().name();
        const opts = {
          cardRestData,
          cards,
          isCustomDashboards,
          isReload,
          guard,
          timeZone,
        };
        if (isCustomDashboards && !isReload && cardRestData) {
          cards.forEach((card) => {
            const action =
              !card.isOnDetail && this.globalDateFilter?.isEnabled
                ? 'dashboards-data:card:rest:data:get'
                : this._getRestDataAction(card.isOnDetail);
            const currentCardData = cardRestData[card.cardId];
            if (currentCardData) {
              if (this.shouldResetCache(currentCardData)) {
                cardRestData[card.cardId] = false;
              } else {
                this.publish(action, currentCardData);
              }
            }
          });
        }
        this._offsetCardLoading(opts, 0);
      });
    });
  }

  // With HTTP/2, if a customer has a large dashboard, then all the cards would
  // be requested simultaneously, putting a lot pressure on downstream services.
  // This method allows cards to be loaded sequentially, after an offset period.
  // For custom dashboards, if the data for a given rest card is already
  // retrieved it is re-dispatched and the next card requested immediately.
  _offsetCardLoading(opts, cardIndex) {
    const {
      cards,
      cardRestData,
      isReload,
      isCustomDashboards,
      guard,
      timeZone,
    } = opts;
    const card = cards && cards[cardIndex];
    if (!card || guard !== this.offsetLoadGuard) {
      return;
    }
    const pollingReference = this._getPollingReference(card?.cardId);
    if (isReload && this.isPriorityMapOrScoreSummary(card)) {
      this._resetRestCard(card);
    } else if (
      !cardRestData ||
      !cardRestData[card.cardId] ||
      !isCustomDashboards ||
      isReload
    ) {
      this._getRestData({ card, timeZone });
    } else if (pollingReference) {
      this._resumePolling(pollingReference, card?.isOnDetail);
    }
    setTimeout(
      () => this._offsetCardLoading(opts, cardIndex + 1),
      Constants.DASHBOARD.CARD_LOAD_OFFSET_MILLIS
    );
  }

  getCurrentDashboardType(callback) {
    this.publish('route:get', {
      callback: (data) => {
        let dashboardType;
        if (data.route.startsWith('dashboards')) {
          dashboardType = 'CUSTOM';
        } else if (data.route.startsWith('analyze/text-analytics')) {
          dashboardType = 'TEXT_ANALYTICS';
        } else if (data.route.startsWith('measures/')) {
          dashboardType = 'SUMMARY';
        }
        callback(dashboardType);
      },
    });
  }

  isPriorityMapOrScoreSummary(card) {
    const chartType = this._getAttribute(card, 'chartType');
    return chartType === 'scoreSummary' || chartType === 'priorityMap';
  }

  _getCardData(card) {
    const newCard = cloneDeep({ isOnDetail: true, ...card });
    const chartType = this._getAttribute(card, 'chartType');

    if (newCard.isOnDetail) {
      const dataSources = this._getAttribute(card, 'dataSources') || [];
      const allDataSources = this._getAttribute(card, 'allDataSources');
      const cxmeasurebdSource = hasCxmeasureBDSource(card);

      if (chartType === 'priorityMap' && dataSources.length > 1) {
        newCard.metadata.attributes.dateRange = {
          k: this.getClientId(),
          c: 'G', //this.baseDate.c,
          r: 'MONTHS',
          n: 1,
          p: 'DEFINED',
          a: new moment().startOf('month').format('YYYY-MM-DD'), // "2012-12-01",
          f: null,
          l: null,
          vndType: 'application/vnd.answers.date.range',
        };
        this._cancelPollCardRestData(card.cardId);
      }

      if (newCard.autoFetch) {
        this._resetRestCard(newCard, { status: null });
        this._getRestData({ card: newCard });
      } else if (
        chartType === 'scoreSummary' ||
        (chartType === 'priorityMap' && dataSources.length === 1) ||
        (chartType === 'priorityIndex' && cxmeasurebdSource) ||
        (chartType === 'priorityMap' &&
          dataSources.length < 1 &&
          !allDataSources) ||
        (chartType === 'priorityMap' && cxmeasurebdSource)
      ) {
        if (!newCard.isInitialization) {
          this._resetRestCard(newCard);
        }
        this._updateCardData(newCard);
      } else {
        this._resetRestCard(card, { status: null });
        this._getRestData({ card: newCard });
      }
    } else {
      this.publish('app:getData', {
        key: 'cardData',
        callback: (cardData) => {
          cardData = cardData || {};
          cardData[newCard.cardId] = false;
          this.publish('app:updateData', {
            cardData,
            callback: () => {
              this._getCardRestData({ card: newCard });
            },
          });
        },
      });
    }
  }

  _getRestData(payload) {
    const { card, cardData } = payload;
    const chartType = this._getAttribute(card, 'chartType');
    const pollingReference = this._getPollingReference(card?.cardId);
    if (pollingReference) {
      this._resumePolling(pollingReference, card?.isOnDetail);
    } else if (!canFetchCardData(card)) {
      this._resetRestCard(card);
    } else if (this._isPollingCard(card)) {
      this._getOdi(payload);
    } else {
      const action = this._getRestDataAction(card.isOnDetail);
      if (chartType === 'voc' && card.getNextPage) {
        this.publish(action, { ...cardData, loading: true });
      } else {
        this.publish(action, { cardId: card.cardId });
      }
      if (chartType === 'voc') {
        this._getVoc(payload);
      } else {
        this._getCardRestData(payload);
      }
    }
  }

  _isPollingCard(card) {
    const chartType = this._getAttribute(card, 'chartType');
    return (
      chartType === 'scoreSummary' ||
      chartType === 'priorityMap' ||
      (chartType === 'priorityIndex' && hasCxmeasureBDSource(card))
    );
  }

  _resetRestCard(card, data = {}) {
    const action = this._getRestDataAction(card.isOnDetail);
    if (!this._isPollingCard(card)) {
      this.publish(action, { cardId: card.cardId, ...data });
    } else {
      this.publish(action, {
        cardId: card.cardId,
        status: 'GENERATE',
        ...data,
      });
      if (this.pollCardRestDataTimer) {
        this._cancelPollCardRestData(card.cardId);
      }
    }
    if (this.pendingCardRequests[card.cardId]) {
      this._cancelPendingCardRequests(card.cardId);
    }
  }

  _cancelPendingCardRequests(cardId) {
    (this.pendingCardRequests[cardId] || []).forEach((cardRequest) => {
      cardRequest._ajaxRef.abort();
    });
  }

  _getRestDataAction(isOnDetail) {
    if (isOnDetail) {
      return 'dashboards-data:card:rest:data';
    }
    return 'dashboards-data:cards:rest:data';
  }

  _getOdi(data) {
    const { options = {}, card, timeZone } = data;
    const dataSources = this._getAttribute(card, 'dataSources') || [];
    const allDataSources = this._getAttribute(card, 'allDataSources') || false;

    if (dataSources?.length === 0 && !allDataSources) {
      return;
    }
    this._getCardRestData({
      card: card,
      timeZone,
      overrideMaxRC: options.overrideMaxRC,
      callback: (cardData) => {
        const isOnDetail = card.isOnDetail;
        const action = this._getRestDataAction(isOnDetail);
        const { status, hasErrors } = cardData;
        if (cardData.ready) {
          this.publish(action, { ...cardData, status: ODI.COMPLETED });
        } else if (
          status !== ODI.TOO_FEW_RESPONDENTS &&
          status !== ODI.TOO_MANY_RESPONDENTS &&
          status !== ODI.PARTITION_THRESHOLD_NOT_MET &&
          status !== ODI.RC_LIMIT_EXCEEDED &&
          status !== ODI.ERROR &&
          status !== ODI.ENGINE_ERROR &&
          status !== ODI.NOT_ENOUGH_DATA_POINTS &&
          !hasErrors
        ) {
          this.publish(action, cardData);
          this._pollCardRestData({
            card: cardData,
            isOnDetail,
          });
        } else {
          this.publish(action, cardData);
        }
      },
    });
  }

  _getVoc(data) {
    const { card, timeZone, cardData = {} } = data;
    this._getCardRestData({
      card: card,
      timeZone,
      offset: cardData.offset,
      callback: (response) => {
        const isOnDetail = card.isOnDetail;
        const action = this._getRestDataAction(isOnDetail);
        const isPaginatedResponse =
          cardData.offset && cardData.offset !== response.offset;
        let updatedCardData = response;
        if (isPaginatedResponse) {
          updatedCardData = {
            ...response,
            respondents: [...cardData.respondents, ...response.respondents],
          };
        }
        this.publish(action, updatedCardData);
      },
    });
  }

  _setGlobalDateFilter({ globalDateFilter, callback = () => {} }) {
    this.globalDateFilter = globalDateFilter;
    callback();
  }

  _addGlobalDateFilter(card) {
    if (
      !this.globalDateFilter?.isEnabled ||
      this.globalDateFilter?.dateRange?.selectedDateId === undefined
    ) {
      return card;
    }
    return mixinCardDate(
      card,
      this.globalDateFilter.dateRange,
      this.globalDateFilter.dateRange.selectedDateId
    );
  }

  _getCardRestData(payload) {
    const card = payload.card || payload || {};
    const { timeZone } = payload;
    this.addTimezone(card, timeZone);
    let cardUpdated = {
      ...card,
      metadata: {
        ...card.metadata,
        attributes: { ...card.metadata.attributes },
      },
    };

    cardUpdated = this._addGlobalDateFilter(cardUpdated);
    cardUpdated = addDimensionForKPI(cardUpdated);

    const chartType = this._getAttribute(card, 'chartType');
    const { attributes } = cardUpdated.metadata;
    if (!attributes.hierarchyFilterId) {
      delete cardUpdated.metadata.attributes.hierarchyFilterId;
    }
    if (!attributes.modelFilterId) {
      delete cardUpdated.metadata.attributes.modelFilterId;
    }
    if (!attributes.pageFilterId) {
      delete cardUpdated.metadata.attributes.pageFilterId;
    }
    if (
      !featureFlags.hasFeature('enable-benchmarks') &&
      (attributes?.benchmarkKeys || attributes?.benchmarkType)
    ) {
      delete cardUpdated.metadata.attributes.benchmarkKeys;
      delete cardUpdated.metadata.attributes.benchmarkType;
    }
    if (
      chartType === Constants.DASHBOARD.chartTypes.scoreSummary &&
      this.hasFeature('new-score-summary')
    ) {
      cardUpdated.metadata.attributes.sortByImpact = false;
    }
    const useCommentDataService = chartType === 'voc';
    const useImpactDataService = this._isImpactChartType(chartType);
    const useMetricDataService =
      chartType !== 'voc' && !this._isImpactChartType(chartType);

    const cardModel = new DashboardDataModel(cardUpdated, {
      clientId: this.getClientId(),
      useCommentDataService,
      useImpactDataService,
      useMetricDataService,
      urlparams: {
        overrideMaxRC: payload.overrideMaxRC,
        offset: payload.offset,
      },
    });

    cardModel
      .save(cardUpdated, {
        doNotAbort: card.isOnDetail ? true : false,
        error: (m, r) => {
          this._onCardRestDataError(payload, r.status, r.responseJSON?.message);
        },
      })
      .then((response) => {
        const { hasErrors, cardErrorCode } = response;

        // FYI backbone 'save' represents updating a model - so cardModel
        // at this point contains the mix of both the request and the response
        // i.e. both the score data and the request attributes. This should be
        // clearned up in the future so that we only keep the response part.
        let cardResponse = cardModel.toJSON();

        let cardError = false;
        if (hasErrors && cardErrorCode) {
          cardError = this.getString(
            `dashboards.${this._getAttribute(
              card,
              'chartType'
            )}.errorCode.${cardErrorCode}`
          );
        }
        cardResponse = { ...cardResponse, cardError };
        if (this.cardHasHourlyData(cardResponse)) {
          const now = moment();
          if (this.isCardToDate(cardResponse, now)) {
            const newDate = now.add(1, 'hour');
            //properties set to 0 to round newDate to hour
            cardResponse.cacheExpirationTime = newDate.set({
              minute: 0,
              second: 0,
              millisecond: 0,
            });
          }
        }
        if (payload.callback) {
          payload.callback(cardResponse);
        } else {
          const action = this._getRestDataAction(cardResponse.isOnDetail);
          this.publish(action, cardResponse);
        }
        if (
          (!isCardDataEmpty(response) ||
            cardResponse.status === ODI.TOO_FEW_RESPONDENTS ||
            cardResponse.status === ODI.NOT_ENOUGH_DATA_POINTS ||
            cardResponse.status === ODI.TOO_MANY_RESPONDENTS ||
            cardResponse.status === ODI.PARTITION_THRESHOLD_NOT_MET ||
            cardResponse.status === ODI.RC_LIMIT_EXCEEDED) &&
          !cardResponse.isOnDetail
        ) {
          this._setRestData({ cardData: cardResponse });
        }
      });

    if (!this._isImpactChartType(chartType)) {
      if (!this.pendingCardRequests[card.cardId]) {
        this.pendingCardRequests[card.cardId] = [];
      }
      this.pendingCardRequests[card.cardId].push(cardModel);
    }
  }

  _isImpactChartType(chartType) {
    return [
      Constants.DASHBOARD.chartTypes.scoreSummary,
      Constants.DASHBOARD.chartTypes.priorityMap,
      Constants.DASHBOARD.chartTypes.priorityIndex,
    ].includes(chartType);
  }

  _pollCardRestData(payload, options = {}) {
    const timeout = (options && options.timeout) || 5000;

    const cardModel = new DashboardDataModel(payload.card, {
      clientId: this.getClientId(),
      requestId: payload.card.requestId,
      useImpactDataService: true,
    });
    cardModel
      .fetch({
        error: (m, r) => {
          this._onCardRestDataError(payload, r.status);
        },
      })
      .then((response) => {
        const cardData = cardModel.toJSON();
        const action = this._getRestDataAction(payload.isOnDetail);
        if (response.ready) {
          this._cancelPollCardRestData(cardData.cardId);
          const completedCardData = {
            ...cardData,
            status: ODI.COMPLETED,
          };
          this.dispatch(payload.callback, action, completedCardData);
          if (!payload.isOnDetail) {
            this._setRestData({ cardData: completedCardData });
          }
          return;
        }
        if (payload.callback) {
          payload.callback(cardData);
        } else {
          this.publish(action, cardData);
        }
        if (
          [
            ODI.TOO_FEW_RESPONDENTS,
            ODI.TOO_MANY_RESPONDENTS,
            ODI.RC_LIMIT_EXCEEDED,
            ODI.PARTITION_THRESHOLD_NOT_MET,
            ODI.NOT_ENOUGH_DATA_POINTS,
            ODI.ERROR,
            ODI.ENGINE_ERROR,
          ].includes(response.status)
        ) {
          this._cancelPollCardRestData(cardData.cardId);
          return;
        }
        this.pollCardRestDataTimer = {
          ...this.pollCardRestDataTimer,
          [cardData.cardId]: {
            timeout: setTimeout(() => {
              this._pollCardRestData(payload);
            }, timeout),
            requestRef: cardModel._ajaxRef,
          },
        };
      });
  }

  _cancelOdi({ card = {}, doNotAbort, deleteReference }) {
    const { cardId, isOnDetail } = card;
    if (isOnDetail) {
      this.publish('dashboards-data:card:rest:data:update', {
        status: 'GENERATE',
      });
    } else {
      this.publish('dashboards-data:cards:rest:data', {
        cardId: card.cardId,
        status: 'GENERATE',
      });
    }
    this._cancelPollCardRestData(cardId, doNotAbort, deleteReference);
  }

  /**
   * Cancel ODI Polling
   *
   * @param {Int} id id of specific polling request - maps to cardId
   * @param {Boolean} doNotAbort defaults to false causing ajax request to be aborted
   * @param {Boolean} deleteReference defaults to true causing references to polling request to be deleted from memory
   */
  _cancelPollCardRestData(
    id = false,
    doNotAbort = false,
    deleteReference = true
  ) {
    const timeouts = this.pollCardRestDataTimer || {};
    if (!id) {
      _.each(timeouts, (obj) => {
        clearTimeout(obj.timeout);
        if (!doNotAbort) {
          obj.requestRef.abort();
        }
      });
      if (deleteReference) {
        this.pollCardRestDataTimer = {};
      }
    } else if (timeouts[id]) {
      clearTimeout(timeouts[id].timeout);
      if (!doNotAbort) {
        timeouts[id].requestRef.abort();
      }
      if (deleteReference) {
        delete this.pollCardRestDataTimer[id];
      }
    }
  }

  _getPollingReference(cardId) {
    return this.pollCardRestDataTimer && this.pollCardRestDataTimer[cardId];
  }

  /**
   * Resume Polling Requests
   * If we have already begun an impacts calculation for the card and have a reference
   to the polling data, resume polling again using the reference request data
   *
   * @param {Object} pollingReference object for the a specific polling request
   * @param {Boolean} isOnDetail  flag for if on card detail page
   */
  _resumePolling(pollingReference, isOnDetail) {
    const action = this._getRestDataAction(isOnDetail);
    const data = pollingReference?.requestRef?.responseJSON;
    this.publish(action, data);
    this._pollCardRestData({
      card: data,
      isOnDetail,
    });
  }

  _onCardRestDataError(payload, status, message) {
    const { card = {} } = payload;
    const action = this._getRestDataAction(card.isOnDetail);
    const cardError = {
      cardId: card.cardId,
      hasErrors: true,
      unauthorized: status === 403,
      message: message,
    };
    if (payload.callback) {
      payload.callback(cardError);
    } else {
      this.publish(action, cardError);
    }
  }

  _setRestData(payload) {
    const { cardData, callback } = payload;
    this.publish('app:getData', {
      key: 'cardRestData',
      callback: (cardRestData) => {
        const { cardId } = cardData;
        cardRestData = cardRestData || {};
        cardRestData[cardId] = cardData;
        this.publish('app:updateData', { cardRestData, callback });
      },
    });
  }

  addTimezone(card, timeZone) {
    const dateRange = card.metadata.attributes.dateRange;
    if (dateRange && dateRange.t === 'DATE_TIME' && !dateRange.z) {
      card.metadata.attributes.dateRange.z =
        timeZone || JSTimezoneDetect.determine().name();
    }
    const isWordCloud =
      card.metadata.attributes.chartType ===
      Constants.DASHBOARD.chartTypes.wordCloud;
    const hasTaTimezonesFeature = hasFeature('ta-timezones');
    if (dateRange && isWordCloud) {
      if (hasTaTimezonesFeature) {
        if (dateRange.t === 'DATE_ONLY') {
          card.metadata.attributes.dateRange.z = Constants.DEFAULT_TIME_ZONE;
        } else if (dateRange.t === 'DATE_TIME') {
          card.metadata.attributes.dateRange.z =
            timeZone || JSTimezoneDetect.determine().name();
        }
      } else {
        delete card.metadata.attributes.dateRange.z;
      }
    }
  }

  shouldResetCache(currentCardData) {
    if (
      currentCardData.metadata?.attributes.chartType === 'voc' &&
      currentCardData.metadata?.attributes.dataSources[0].type === 'feedback'
    ) {
      return true;
    }
    if (!currentCardData.cacheExpirationTime) {
      return false;
    }
    const currentDate = new Date();
    const cardExpirationDate = moment(currentCardData.cacheExpirationTime);
    return cardExpirationDate.isBefore(currentDate);
  }

  _unsetRestData(payload) {
    const { cardId, callback } = payload;
    this.publish('app:getData', {
      key: 'cardRestData',
      callback: (cardRestData) => {
        cardRestData = cardRestData || {};
        cardRestData[cardId] = false;
        this.publish('app:updateData', { cardRestData, callback });
      },
    });
  }

  isCardToDate(card, currentDate) {
    const { dateRange, selectedDateId, dataSources } = card.metadata.attributes;
    const dataSourceType = dataSources && dataSources[0] && dataSources[0].type;
    //if it's a 'to date' date type and either it's event but not yesterday, it's not event or its a custom date range matching today
    return (
      (dateRange.n === 0 &&
        dateRange.r !== 'C' &&
        ((dataSourceType === 'event' && selectedDateId !== 'yesterday') ||
          dataSourceType !== 'event')) ||
      (dateRange.r === 'C' && currentDate.isSame(moment(dateRange.l), 'date'))
    );
  }

  cardHasHourlyData(card) {
    return (
      card.metadata.attributes.dimensions &&
      card.metadata.attributes.dimensions.length > 0 &&
      card.metadata.attributes.dimensions[0].key === 'HOURLY' &&
      card.series &&
      card.series.length > 0
    );
  }

  _resetWordcloudData(payload) {
    this.publish('app:getData', {
      key: 'cardRestData',
      callback: (cardRestData = {}) => {
        Object.keys(cardRestData)
          .filter((key) => cardRestData[key]?.metadata?.attributes)
          .forEach((key) => {
            const { attributes } = cardRestData[key].metadata;
            const { chartType, dataSources, metrics } = attributes;
            if (chartType === 'wordCloud') {
              if (
                payload &&
                this._hasCustomFeed(dataSources, payload.customFeedId)
              ) {
                delete cardRestData[key];
              } else if (!payload && this._hasMetric(metrics, 'KF')) {
                delete cardRestData[key];
              }
            }
          });

        this.publish('app:updateData', {
          cardRestData: cardRestData,
        });
      },
    });
  }

  _hasMetric(metrics = [], metricType) {
    return metrics.some((m) => m.type === metricType);
  }

  _hasCustomFeed(dataSources = [], customFeedId) {
    return dataSources.some(
      (x) => x.type === 'customFeed' && x.key === String(customFeedId)
    );
  }
}

export default DashboardsDataController;

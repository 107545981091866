import React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import ClassNames from 'classnames';
import BasePanel from 'views/panels/base';
import _ from 'underscore';
import store from 'core/store';
import Panels from 'views/panels';
import MessageBar from 'views/widgets/message-bar';
import Loading from 'views/widgets/loading';
import MessageSpinner from 'views/widgets/message-spinner';
import ConfirmModal from 'views/widgets/confirm-modal';
import Flyout from 'views/widgets/flyout';
import EmptyStateWrapper from 'views/widgets/empty-state-wrapper';
import UpdateAccess from 'views/widgets/authorization/update-access';
import {
  Portal,
  Card,
  Banner,
  Text,
  FormButton,
  DragDropContext,
} from 'cx-component-library';
import AppContext from 'core/appContext';
import { createSelector } from 'reselect';
import ContentWithDateFilterContext from './content-with-date-filter-context';
import { showLux } from './utils/lux-util';

class AppView extends BasePanel {
  constructor(props) {
    super(props);

    this.actions = {
      'app:messageFlash': 'handleMessageFlash',
      'app:hideMessageFlash': 'hideMessageFlash',
      'app:showModal': 'handleModal',
      'app:showTooltip': 'handleTooltip',
      'app:showPopover': 'handleAbsolutePopover',
      'user:data': 'handleUserData',
      'featureFlags:ready': 'handleFeatureFlags',
      'app:sessionexpired': 'handleExpiredSession',
      'app:tracking': '_sendTrackingEvent',
      'postmessage:acknowledged': 'onPostMessage',
      'whitelist:check:isValid': '_handleWhiteListCheck',

      'app:updateState': '_updateRouteState',
      'app:updateData': '_updateData',
      'app:clearData': '_clearData',
      'app:getData': '_getData',
      'app:navigation:minimized': '_handleNavSize',
      'app:messageBanner': '_handleMessageBanner',
      'app:impersonatedUserLogin': '_handleLoginAsBanner',
    };

    this.tooltip = null;

    this.state = {
      messageText: null,
      messageZIndex: null,
      messageType: 'hidden',
      showMessage: false,
      messageActionText: this.getString('general.dismiss'),
      messageCallback: false,
      showModal: false,
      showTooltip: false,
      showPopover: false,
      userSessionExpired: false,
      previousRoute: null,
      headerMinimized: false,
      iframe: {
        inPage: window.self !== window.top,
        timedOut: false,
        origin: null,
        isVerifying: false,
        isValid: false,
      },

      showPasswordExpirationWarning: true,

      routeState: {},
      // routeData: {}
      data: {},
      showBanner: false,
      showLoggedInAs: !!store.get('xImpersonatedUserId'),
    };

    this.messageTimer = false;
  }

  componentDidUpdate(prevProps) {
    // Props to app means the route has changed and we need to clear tooltips and modals
    if (!_.isEqual(this.props, prevProps)) {
      this.setState({
        showTooltip: false,
        showModal: false,
        showPopover: false,
      });
    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.storageListener);
    super.componentDidMount();
    this.handleIframe();
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.storageListener);
    super.componentWillUnmount();
    this.clearIframeTimeout();
  }

  storageListener(event) {
    if (event.key === 'sonomaBearerToken') {
      const bearerToken = localStorage.getItem('sonomaBearerToken');
      const sonomaToken = localStorage.getItem('sonomaAccessToken');

      if (bearerToken === null) {
        const access = sonomaToken?.split('.')[1];
        const decoded = JSON.parse(atob(access || ''));
        window.location = decoded?.origin + '/logout.aspx';
      }
    }
  }

  handleIframe() {
    const iframe = this.getState('iframe');
    if (!iframe.inPage) {
      return;
    }
    this.checkIframeOrigin();
    this.setIframeTimer();
  }

  setIframeTimer() {
    const iframe = this.getState('iframe');

    // if post message does not return origin within 30secs timeout
    this.iframeTimer = setTimeout(() => {
      iframe.timedOut = true;
      this.setState({ iframe });
    }, 7000);
  }

  clearIframeTimeout() {
    clearTimeout(this.iframeTimer);

    const iframe = this.getState('iframe');
    iframe.timedOut = true;

    this.setState({ iframe });
  }

  renderIframeMessage() {
    const { iframe } = this.state;

    return (
      <div className='iframe'>
        {iframe.timedOut && (
          <div className='iframe__empty'>
            <Card className='empty-case'>
              <EmptyStateWrapper
                heading={this.getString('iframe.empty.heading')}
                subheading={this.getString('iframe.empty.description')}
              />
            </Card>
          </div>
        )}
        {!iframe.timedOut && <Loading />}
      </div>
    );
  }

  _updateRouteState(payload) {
    const routeState = this.getState('routeState');
    const { route } = this.props.config;
    if (!routeState[route]) {
      routeState[route] = {};
    }
    routeState[route] = _.extend(routeState[route], payload);
    this.setState({ routeState }, payload.callback);
  }

  _updateData({ callback, ...payload }) {
    this.setState((state) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    }, callback);
  }

  _clearData() {
    this.setState({ data: {} });
  }

  _getData(payload) {
    const { key, callback } = payload;
    let data = this.getState('data');
    if (callback && key) {
      callback(data[key]);
    }
  }

  _showLux() {
    return showLux();
  }

  _handleMessageBanner(payload) {
    const {
      theme = 'warning',
      text,
      onClick = () => {},
      onClose = () => {},
    } = payload;
    this.setState({
      showBanner: true,
      bannerTheme: theme,
      bannerText: text,
      bannerOnClick: onClick,
      bannerOnClose: onClose,
    });
  }

  _handleLoginAsBanner(payload) {
    const {
      theme = 'error',

      onClick = () => {},
      onClose = () => {},
    } = payload;

    this.setState({
      showLoggedInAs: true,
      bannerTheme: theme,

      bannerOnClick: onClick,
      bannerOnClose: onClose,
    });
  }

  _renderBanner() {
    const {
      showBanner,
      bannerTheme,
      bannerText,
      bannerOnClick,
      bannerOnClose,
    } = this.state;
    return (
      showBanner && (
        <Banner
          className='frame__banner'
          theme={bannerTheme}
          center
          text={bannerText}
          bannerOnClick={bannerOnClick}
          bannerOnClose={bannerOnClose}
          textProps={{
            type: 'span',
            theme: 'bold-body',
          }}
        />
      )
    );
  }

  _renderLoggedInAsBanner() {
    return (
      <Banner
        className='frame__banner'
        theme={'error'}
        text={this._generateLoginAsText()}
        bannerOnClick={() => {}}
        bannerOnClose={() => {}}
        textProps={{
          type: 'span',
          theme: 'bold-body',
        }}
      />
    );
  }

  _generateLoginAsText() {
    const activeUserName = store.get('xImpersonatedUserId')
      ? store.get('xImpersonatedUserId').userName
      : '';
    const currentUserName = this.state.data.currentUser
      ? this.state.data.currentUser.username
      : '';

    return (
      <Text type={'span'} color={'white'}>
        {this.getTemplatedString('general.loginAsSignedIn', {
          activeUserName,
          currentUserName,
        })}

        <FormButton
          theme={'inversetertiary'}
          size={'small'}
          style={{ borderColor: '#ffffff', marginLeft: '2rem' }}
          onClick={this._handleLoginAsLogout.bind(this)}
        >
          {this.getString('general.loginAsLogOut')}
        </FormButton>
      </Text>
    );
  }

  _handleLoginAsLogout() {
    this.publish('app:impersonatedUserLogout', {
      callback: () => {
        this.setState({ showLoggedInAs: false });
      },
    });
  }

  _renderPasswordExpirationWarning() {
    return (
      <Banner
        className='frame__banner'
        theme='warning'
        center
        text={this.getString('app.changePassword')}
        onClick={this.handleGoToChangePassword}
        onClose={this.handlePasswordWarningDismiss}
        textProps={{
          type: 'span',
          theme: 'bold-body',
        }}
      />
    );
  }

  _handleNavSize(payload) {
    if (payload.data) {
      this.setState({ headerMinimized: payload.data });
    }
  }

  _renderHeader(Header) {
    const { headerMinimized, showBanner, showLoggedInAs } = this.state;

    const classes = ClassNames('frame__row frame__head', {
      'frame__head--minimized': headerMinimized,
      'frame__head--w-banner':
        this.showPasswordExpirationWarning() || showBanner || showLoggedInAs,
    });
    return (
      <div className={classes}>
        <Header
          minimized={headerMinimized}
          appConfig={this.props.config}
          onSizeToggle={() => {
            this.setState({ headerMinimized: !headerMinimized }, () => {
              //Transition time is 400ms - required to trigger highcharts redraws
              setTimeout(() => {
                this.publish('nav:resized', {});
                this._safeResize();
              }, 500);
            });
            this.publish('storage:set', {
              key: 'headerMinimized',
              data: !headerMinimized,
            });
          }}
        />
      </div>
    );
  }

  _renderSubnav(subnav) {
    const { headerMinimized, showBanner, showLoggedInAs } = this.state;
    const classes = ClassNames('frame__row frame__subnav', {
      'frame__subnav--minimized': headerMinimized,
      'frame__subnav--w-banner':
        this.showPasswordExpirationWarning() || showBanner || showLoggedInAs,
    });
    return <div className={classes}>{subnav}</div>;
  }

  _getLayout(config) {
    const {
      data: { embedAs },
    } = this.state;
    const { layout, layouts, layoutsLux } = config;

    const showLux = this._showLux();
    const theLayouts = showLux ? layoutsLux : layouts;

    // specific override of layout for embedded card mode
    return embedAs === 'card' && layout === 'dashboard-detail'
      ? theLayouts.dashboard
      : theLayouts[layout];
  }

  render() {
    if (this._showLux()) {
      return this._renderLux();
    }

    const { headerMinimized, showBanner, showLoggedInAs } = this.state;

    const contentClasses = ClassNames('frame__row frame__body', {
      'frame__body--minimized': headerMinimized,
      'frame__body--w-banner':
        this.showPasswordExpirationWarning() || showBanner || showLoggedInAs,
    });

    var config = this.props.config,
      isAuthRequired = config.isAuthRequired,
      Header = this._getPanel('header'),
      sidebar = this._getComponent('sidebar'),
      content = this._getComponent('content'),
      subnav = this._getComponent('subnav'),
      contentBodyClass = this._getLayout(config).appClass || contentClasses,
      permissions = this._getPermissions(),
      product = this._getProduct(),
      Tooltip = this.state.showTooltip ? this.tooltip : null,
      Modal = this.state.showModal ? this.modal : null,
      AbsolutePopover = this.state.showPopover ? this.popover : null,
      DefaultModal = (
        <ConfirmModal
          icon='lock'
          heading={this.getString('security.heading')}
          description={[{ content: this.getString('security.description') }]}
          confirm={this.getString('general.ok')}
          action='app:logout'
          actionOnCancel='app:logout'
          payload={config.previousRoute ? config.previousRoute : {}}
        />
      );

    const hasRole = product
      ? this.userRole(product) && this.userRole(permissions)
      : this.userRole(permissions);
    const hasRoutePermission = !isAuthRequired || hasRole;

    // Only valid iframes will be able to render cxsuite
    const { iframe } = this.state;
    if (iframe.inPage && !iframe.isValid) {
      return this.renderIframeMessage();
    }

    if (this.state.data.currentUser || !isAuthRequired || Modal) {
      return (
        <AppContext.Provider value={selectContextValue(this.state)}>
          {hasRoutePermission ? (
            <ContentWithDateFilterContext config={this.props.config}>
              <DragDropContext>
                <div>
                  {Modal}
                  {Tooltip}
                  {AbsolutePopover}
                  <Portal isOpened>
                    <MessageBar
                      zIndex={this.state.messageZIndex}
                      text={this.state.messageText}
                      type={this.state.messageType}
                      action={this.state.messageActionText}
                      isOpen={this.state.showMessage}
                      onClose={this._handleMessageBarClose.bind(this)}
                    />
                  </Portal>
                  {this.showPasswordExpirationWarning() &&
                    this._renderPasswordExpirationWarning()}
                  {showBanner && this._renderBanner()}
                  {showLoggedInAs && this._renderLoggedInAsBanner()}
                  {Header && this._renderHeader(Header)}
                  {subnav && this._renderSubnav(subnav)}
                  <div className={contentBodyClass}>
                    <ReactCSSTransitionGroup
                      transitionName='sidebar'
                      transitionEnterTimeout={500}
                      transitionLeaveTimeout={300}
                    >
                      {sidebar}
                    </ReactCSSTransitionGroup>
                    <div className='content'>{content}</div>
                  </div>
                  {this.props.state === 'STALE' && <UpdateAccess />}
                </div>
              </DragDropContext>
            </ContentWithDateFilterContext>
          ) : (
            <div>{Modal || DefaultModal}</div>
          )}
        </AppContext.Provider>
      );
    } else {
      return <Loading />;
    }
  }

  _renderLux() {
    const {
      showBanner,
      showLoggedInAs,
      data: { embedAs },
    } = this.state;
    const contentClasses = 'lux-content';
    const config = this.props.config;
    const isServerError = config?.layout === 'server-error';
    const isAuthRequired = config.isAuthRequired;
    const Header = this._getPanel('header');
    const sidebar = this._getComponent('sidebar');
    const content = this._getComponent('content');
    const subnav = this._getComponent('subnav');
    const contentBodyClass = this._getLayout(config).appClass || contentClasses;
    const permissions = this._getPermissions();
    const product = this._getProduct();
    const Tooltip = this.state.showTooltip ? this.tooltip : null;
    const Modal = this.state.showModal ? this.modal : null;
    const AbsolutePopover = this.state.showPopover ? this.popover : null;
    const DefaultModal = (
      <ConfirmModal
        icon='lock'
        heading={this.getString('security.heading')}
        description={[{ content: this.getString('security.description') }]}
        confirm={this.getString('general.ok')}
        action='app:logout'
        actionOnCancel='app:logout'
        payload={config.previousRoute ? config.previousRoute : {}}
      />
    );
    const hasRole = product
      ? this.userRole(product) && this.userRole(permissions)
      : this.userRole(permissions);
    const hasRoutePermission = !isAuthRequired || hasRole;

    // Only valid iframes will be able to render cxsuite
    const { iframe } = this.state;
    if (iframe.inPage && !iframe.isValid) {
      return this.renderIframeMessage();
    }

    if (this.state.data.currentUser || !isAuthRequired || Modal) {
      return (
        <AppContext.Provider value={selectContextValue(this.state)}>
          {hasRoutePermission ? (
            <ContentWithDateFilterContext config={this.props.config}>
              <DragDropContext>
                {Modal}
                {Tooltip}
                {AbsolutePopover}
                <Portal isOpened>
                  <MessageBar
                    zIndex={this.state.messageZIndex}
                    text={this.state.messageText}
                    type={this.state.messageType}
                    action={this.state.messageActionText}
                    isOpen={this.state.showMessage}
                    onClose={this._handleMessageBarClose.bind(this)}
                  />
                </Portal>
                {this.showPasswordExpirationWarning() &&
                  this._renderPasswordExpirationWarning()}
                {showBanner && this._renderBanner()}
                {showLoggedInAs && this._renderLoggedInAsBanner()}
                {Header && <Header />}
                {isServerError ? (
                  <div className={contentBodyClass}>
                    <div className='content'>{content}</div>
                  </div>
                ) : (
                  <div className={contentBodyClass}>
                    {!embedAs && <div className='lux-sidebar'>{sidebar}</div>}
                    <div className={!embedAs ? 'lux-main' : 'lux-main-embed'}>
                      {subnav && subnav}
                      {content}
                    </div>
                  </div>
                )}
                {this.props.state === 'STALE' && <UpdateAccess />}
              </DragDropContext>
            </ContentWithDateFilterContext>
          ) : (
            <div>{Modal || DefaultModal}</div>
          )}
        </AppContext.Provider>
      );
    } else {
      return <Loading />;
    }
  }

  onPostMessage(event) {
    const iframe = this.getState('iframe');
    if (iframe.isVerifying) {
      return;
    }

    iframe.origin = this.configureOrigin(event.origin); // should be set to the top level domain
    iframe.isVerifying = true;
    this.setState({ iframe }, this.checkIframeOrigin);
  }

  configureOrigin(origin) {
    // expected input: http://www.foresee.com
    // expected output: www.foresee.com
    return origin.replace(/(^\w+:|^)\/\//, '');
  }

  checkIframeOrigin() {
    const iframe = this.getState('iframe');

    this.publish('whitelist:check', {
      retryIfUninitialized: true,
      origin: iframe.origin || new URL(document.referrer).host,
    });
  }

  showPasswordExpirationWarning() {
    const { showPasswordExpirationWarning } = this.state;
    return (
      showPasswordExpirationWarning &&
      store.get('showPasswordExpirationWarning')
    );
  }

  _handleWhiteListCheck(isValid) {
    let iframe = this.getState('iframe');
    iframe.isValid = isValid;
    iframe.isVerifying = false;
    this.setState({ iframe }, this.clearIframeTimeout);
  }

  handleUserData(data) {
    this._updateData({
      currentUser: data.user,
      currentClient: data.client,
    });
  }

  handleFeatureFlags() {
    const embedAs =
      this.props.config.browserOptions &&
      this.props.config.browserOptions.embedAs;
    if (embedAs) {
      this._updateData({ embedAs });
    }
  }

  handleExpiredSession(payload) {
    if (payload.expiredSession) {
      this.setState({
        userSessionExpired: true,
        previousRoute: payload.previousRoute,
      });
      store.remove('xImpersonatedUserId');
    } else {
      this.setState({
        userSessionExpired: false,
        previousRoute: null,
      });
    }
  }

  _sendTrackingEvent(payload) {
    console.log('_sendTrackingEvent', payload);
  }

  _getComponent(selector) {
    var Panel = this._getPanel(selector),
      panelConfig = Panel ? this._getPanelConfig(selector) : null;

    const { routeState, data, headerMinimized, showLoggedInAs } = this.state;
    const { route } = this.props.config;
    const panelRouteState = routeState[route] || {};

    let panelProps = {
      config: panelConfig,
      apolloClient: this.props.apolloClient,
      userSessionExpired: this.state.userSessionExpired,
      appConfig: this.props.config,
      headerMinimized: headerMinimized,
      showLoggedInAs: showLoggedInAs,
      ...panelRouteState,
      ...data,
    };

    return Panel ? <Panel {...panelProps} /> : null;
  }

  _getPanel(selector) {
    var config = this.props.config,
      layout = this._getLayout(config),
      panels = layout.panels,
      panel;

    const {
      data: { embedAs },
    } = this.state;

    // specific adjustments to layout based on the embedded mode
    if (
      this._suppressHeader(embedAs, selector) ||
      this._suppressNav(embedAs, selector) ||
      this._suppressSubnav(embedAs, selector, config.layout)
    ) {
      return null;
    }

    _.each(panels, (value, key) => {
      var panelName = _.isObject(value) ? key : value;
      var panelContainer = panelName.split('-')[0];
      if (panelContainer === selector) {
        panel = Panels[panelName];
      }
    });
    return panel;
  }

  _suppressHeader(embedAs, selector) {
    return embedAs && selector === 'header';
  }
  _suppressNav(embedAs, selector) {
    return embedAs && selector === 'nav';
  }

  _suppressSubnav(embedAs, selector, layout) {
    return (
      embedAs &&
      selector === 'subnav' &&
      ((layout === 'dashboard' && embedAs === 'dashboard') ||
        (layout === 'dashboard-detail' && embedAs === 'card'))
    );
  }

  _getPanelConfig(selector) {
    var config = this.props.config,
      layout = this._getLayout(config),
      panels = layout.panels;

    config.inPage = this.state.iframe.inPage;

    _.each(panels, (value, key) => {
      if (_.isObject(value)) {
        config = value;
      }
    });
    return config;
  }

  _getProduct() {
    return (this.props.config && this.props.config.product) || false;
  }

  _getPermissions() {
    return (this.props.config && this.props.config.permissions) || '';
  }

  /**
   * Show a modal dialog when the appropriate action is received.
   * @param payload
   */
  handleModal(payload) {
    if (_.isString(payload.modal) && payload.modal === 'default') {
      var description = [
        {
          content: payload.description
            ? this.getString(payload.description)
            : this.getString('security.description'),
        },
      ];

      this.modal = (
        <ConfirmModal
          icon={payload.icon ? payload.icon : 'lock'}
          heading={
            payload.heading
              ? this.getString(payload.heading)
              : this.getString('security.heading')
          }
          description={description}
          confirm={
            payload.confirm
              ? this.getString(payload.confirm)
              : this.getString('general.ok')
          }
          cancel={
            payload.cancel
              ? this.getString(payload.cancel)
              : this.getString('general.cancel')
          }
          callback={payload.callback ? payload.callback : null}
          action={payload.action ? payload.action : null}
          payload={payload.payload ? payload.payload : null}
        />
      );
    } else {
      this.modal = payload.modal;
    }

    if (this.modal) {
      this.setState({ showModal: true });
    } else {
      this.setState({ showModal: false });
    }
  }

  /**
   * Show a tooltip dialog when the appropriate action is received.
   * @param payload
   */
  handleTooltip(tooltip) {
    this.tooltip = tooltip ? (
      <Flyout
        parentFrame={tooltip.position}
        className={tooltip.className}
        attachment={tooltip.attachment}
        maxWidth={tooltip.maxWidth}
      >
        {tooltip.title}
        {tooltip.message}
      </Flyout>
    ) : null;

    if (this.tooltip) {
      this.setState({ showTooltip: true });
    } else {
      this.setState({ showTooltip: false });
    }
  }

  /**
   * Show a tooltip dialog when the appropriate action is received.
   * @param payload
   */
  handleAbsolutePopover(payload) {
    this.popover = payload ? payload.popover : null;

    if (this.popover) {
      this.setState({ showPopover: true });
    } else {
      this.setState({ showPopover: false });
    }
  }

  /**
   *
   * Handle updating the state of messages displayed to user
   *
   **/
  handleMessageFlash(payload) {
    var state = {
      messageText: payload.spinner ? (
        <div className='loading-spinner-message'>
          <MessageSpinner />
          {payload.messageText}
        </div>
      ) : (
        payload.messageText
      ),
      messageType: payload.messageType,
      messageZIndex: payload.zIndex,
      messageActionText: payload.action
        ? payload.action
        : this.getString('general.dismiss'),
      messageCallback: payload.callback ? payload.callback : false,
      indefinite: payload.indefinite,
    };

    if (!payload.messageText) {
      state.showMessage = false;
      this.setState(state);
      return;
    }

    state.showMessage = true;

    if (this.state.showMessage) {
      // Already showing a message - close it before popping the next snackbar
      this.setState({ showMessage: false });
      var that = this;
      setTimeout(function () {
        that.setMessageTimer(state);
      }, 300);
    } else {
      this.setMessageTimer(state);
    }
  }

  hideMessageFlash() {
    this.setState({
      messageText: '',
      showMessage: false,
      indefinite: false,
    });
  }

  setMessageTimer(state) {
    clearTimeout(this.messageTimer);
    var that = this;
    this.setState(state);

    if (!state.indefinite) {
      this.messageTimer = setTimeout(function () {
        that.setState({ showMessage: false });
      }, 5000);
    }
  }

  _handleMessageBarClose() {
    if (this.state.messageCallback) {
      this.state.messageCallback();
    }
    this.setState({ showMessage: false, messageCallback: false });
  }

  handlePasswordWarningDismiss = () => {
    this.setState({ showPasswordExpirationWarning: false });
  };

  handleGoToChangePassword = () => {
    this.changeRoute('profile');
  };
}

export default AppView;

const selectCurrentClient = (state) => state.data.currentClient;
const selectCurrentUser = (state) => state.data.currentUser;
const selectVtaDisabled = (state) => state.data.vtaDisabled;
const selectEmbedAs = (state) => state.data.embedAs;
const selectContextValue = createSelector(
  selectCurrentClient,
  selectCurrentUser,
  selectVtaDisabled,
  selectEmbedAs,
  (client, user, vtaDisabled, embedAs) => ({
    client,
    user,
    vtaDisabled,
    embedAs,
  })
);

import Backbone from 'backbone';

const UploadProcessesTasksModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.uploadType = options.uploadType || 'combo';
    this.processId = options.processId;
    this.activityType = options.activityType;
  },

  url: function () {
    return `clients/${this.client}/upload/${this.uploadType}/processes/${this.processId}/tasks/${this.activityType}`;
  },
});

export default UploadProcessesTasksModel;

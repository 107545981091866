import Backbone from 'backbone';
import _ from 'underscore';
import { merge } from 'lodash';

var SettingsModel = Backbone.Model.extend({
  initialize: function () {},

  /**
   * Recursively work through config, following route to part
   * @param config
   * @param key (item whose value should be returned)
   * @param route (array representing segments of route)
   */
  getConfigPart: function (config, key, path) {
    function search(config, key, path) {
      var value = null,
        segment = path.shift(),
        subconfig;

      // If path segment is an id, switch it to :id as represented in config
      if (!config[segment] && config[':id']) {
        segment = ':id';
      }

      if (config[segment]) {
        subconfig = config[segment];
        value = subconfig[key];

        if (path.length) {
          value = search(subconfig, key, path) || value;
        }
      }
      return value;
    }

    return search(config, key, path);
  },

  getConfigParts: function (path, keys = []) {
    const config = {};
    const routes = this.get('routes');
    for (let i = 0; i < keys.length; i++) {
      config[keys[i]] = this.getConfigPart(routes, keys[i], path.split('/'));
    }
    return config;
  },
  /**
   * Builds an object form the ids in the path
   * Names each id after previous path segment
   * (e.g. /clients/324332/projects/24243 would return
   * { clients: 324332, projects: 24243 }
   * @param path
   * @returns {{}}
   */
  getPathIds: function (path) {
    var ids = {},
      config = this.get('routes'),
      route = path.split('/');

    _.each(
      route,
      _.bind(function (segment, index) {
        const segmentName = route[index - 1];

        // Check to see if current segment is an id
        if (!config[segment] && config[':id']) {
          ids[segmentName] = segment;
          segment = ':id';
        }

        if (config[segment]) {
          config = config[segment];
        }
      }, this)
    );

    return ids;
  },

  /**
   * Retrieve deepest product defined in path
   * @param path
   */
  getProduct: function (path) {
    var routes = this.get('routes');

    var route = path === '/' || !path ? ['projects'] : path.split('/');
    if (route[0] === '') {
      route = route.slice(1);
    }

    return this.getConfigPart(routes, 'product', route);
  },

  /**
   * Retrieve deepest permissions defined in path
   * @param path
   */
  getPermissions: function (path) {
    var routes = this.get('routes');

    var route = path === '/' || !path ? ['projects'] : path.split('/');
    if (route[0] === '') {
      route = route.slice(1);
    }

    var permissions = this.getConfigPart(routes, 'permissions', route);
    return permissions;
  },

  getPathConfig: function (path) {
    const config = this.get('routes');
    const route = path.split('/');
    let pathConfig = null;

    function search(config, route) {
      let value = null,
        segment = route.shift(),
        subconfig;

      // If path segment is an id, switch it to :id as represented in config
      if (!config[segment] && config[':id']) {
        segment = ':id';
      }

      if (config[segment]) {
        subconfig = config[segment];

        if (route.length) {
          subconfig = search(subconfig, route) || subconfig;
        }
      }
      return subconfig;
    }

    return search(config, route);
  },

  getActiveTabValue: function (path) {
    const config = this.get('routes');
    const route = path.split('/');
    const activeTab = this.getConfigPart(config, 'activeTab', route);
    return activeTab;
  },

  returnPermitted(config, checkPermission) {
    const permitted = merge({}, config);
    let item;

    /* eslint no-unused-vars: 0 */
    function search(config, parent) {
      var theParent = parent ? config : parent;
      var value = null;
      if (
        config.permissions === undefined ||
        checkPermission(config.permissions)
      ) {
        value = config;
        for (item in config) {
          if (item === 'tabs') {
          }
          if (config[item] instanceof Array) {
            value = search(config[item], theParent) || value;
          } else if (
            typeof config[item] === 'object' &&
            config[item] !== null
          ) {
            value = search(config[item], theParent) || value;
          }
        }
      } else {
        if (parent instanceof Array) {
          parent.splice(item, 1);
        } else {
          delete parent[item];
        }
      }
      return value;
    }
    return permitted;
  },
});

export default SettingsModel;

export const getDefaultBenchmarkKeys = (
  benchmarkType,
  dataSources,
  benchmarkKeys
) => {
  if (benchmarkKeys?.length) {
    return benchmarkKeys.reduce(
      (acc, benchmark) => ({
        [benchmark.surveyKey]: benchmark,
      }),
      {}
    );
  } else if (benchmarkType && dataSources && dataSources[0]?.hasBenchmarks) {
    return {
      [dataSources[0]?.key]: {
        bmCatKeys: 1,
        superLatentKey: 128,
        bmCatTag: 'fs_website_index__desktop',
        superLatentTag: 'fs_satisfaction_score',
      },
    };
  }
};

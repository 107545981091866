import Backbone from 'backbone';

const HierarchyTreeModel = Backbone.Model.extend({
  headers: {
    Accept: 'application/json',
  },

  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.hierarchyId = options.hierarchyId;
    this.capabilityName = options.capabilityName;
    this.startNodeId = options.startNodeId;
    this.accessType = options.accessType;
    this.depth = options.depth || 1;
  },

  url: function () {
    let query = {
      depth: this.depth,
      annotateAccess: true,
    };
    if (this.capabilityName) {
      query.capabilityName = this.capabilityName;
    }
    if (this.startNodeId) {
      query.startNodeId = this.startNodeId;
    }
    if (this.accessType) {
      query.accessType = this.accessType;
    }
    return `clients/${this.client}/hierarchies/${
      this.hierarchyId
    }/tree?${new URLSearchParams(query)}`;
  },
});

export default HierarchyTreeModel;

import Backbone from 'backbone';
import Constants from 'core/constants';

var CSVFileModel = Backbone.Model.extend({
  idAttribute: 'exportId',

  initialize: function (attributes, opts) {
    this.addOptions(opts);
  },

  addOptions: function (opts) {
    var options = opts || {};

    this.client = options.client;
    this.measure = options.measure;

    this.options = options;
  },

  fetch: function () {
    var oauth = Backbone.sync.oauth;
    return oauth.apiRequest(Constants.SERVER_ROOT + this.url(), {
      type: 'GET',
      responseType: 'arraybuffer',
      contentType: 'application/json; charset=utf-8',
      success: (data, status, request) => {
        var contentType = request.getResponseHeader('Content-Type'),
          blobType = contentType.substr(0, contentType.indexOf(';')),
          arrayBufferView = new Uint8Array(data);

        this.blob = new Blob([arrayBufferView], { type: blobType });
      },
    });
  },

  url: function () {
    return `clients/${this.client}/measures/${
      this.measure
    }/csv/export/${this.get('exportId')}/file`;
  },
});

export default CSVFileModel;

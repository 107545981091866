import textRoutes from 'core/routes/textAnalytics';

const analyzeRoutes = () => {
  let config = {
    icon: 'navAnalyze',
    label: 'Analyze',
    controller: 'measures',
    permissions: 'portal_access',
    layout: 'navigation',
  };

  let tabRoutes = [];

  config['text-analytics'] = textRoutes();
  tabRoutes.push('text-analytics');

  config.anomaly = {
    label: 'Anomaly Detection',
    layout: 'analyze',
    permissions: 'anomaly_detection_enabled',
    focus: {
      focusView: true,
      layout: 'anomaly-embed',
    },
    ':id': {
      layout: 'anomaly-detail',
      focus: {
        focusView: true,
        layout: 'anomaly-detail-embed',
      },
    },
  };
  tabRoutes.push('anomaly');

  config.tabs = [
    {
      routes: tabRoutes,
    },
  ];

  return config;
};

export default analyzeRoutes;

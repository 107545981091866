import Backbone from 'backbone';

const FilterRolesCollection = Backbone.Collection.extend({
  model: Backbone.Model,

  parse: function (data) {
    return data.items;
  },

  url: function () {
    return `authorization-service/roles/filter`;
  },
});

export default FilterRolesCollection;

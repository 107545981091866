import $ from 'jquery';
import BaseController from 'controllers/base';
import ClientSettingsModel from 'models/clients/settings/client-settings';
import ClientMetricsModel from 'models/clients/metrics';
import FeatureFlags from 'core/featureFlags';

const CUSTOM_DATA_SOURCES_SETTINGS_KEY = 'combined-source';

export default class CustomDataSourcesController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'custom-data-sources:get': '_get',
        'custom-data-sources:set': '_set',
        'custom-data-sources:app:get': '_getApp',
        'custom-data-sources:metrics:get': '_getMetrics',
      },
      name: 'custom-data-sources',
      dependsOn: ['user', 'measures'],
    });
  }

  /**
   * Retrieve custom data sources metrics
   */
  _getMetrics({ dataSource }) {
    if (!dataSource) {
      return;
    }
    const def = $.Deferred();

    let criteria = {
      dateRange: {},
      combinedSource: dataSource.uuid,
    };

    const model = new ClientMetricsModel(criteria, {
      clientId: this.getClientId(),
      combinedSourceName: dataSource.name,
    });

    model
      .save(criteria, {
        error: (m, r) => {
          def.resolve({
            hasError: true,
            unauthorized: r && r.status === 403,
          });
        },
      })
      .then(() => {
        this.publish('app:getData', {
          key: 'customDataSourcesMetrics',
          callback: (data) => {
            let customDataSourcesMetrics = data || {};
            customDataSourcesMetrics[model.attributes.combinedSource] = model;
            this.publish('app:updateData', { customDataSourcesMetrics });
          },
        });
      });

    return def;
  }

  /**
   * Retrieve custom data sources from API
   */
  _getApp() {
    if (!FeatureFlags.hasFeature('custom-data-sources')) {
      return false;
    }

    const settings = new ClientSettingsModel(
      {
        namespace: CUSTOM_DATA_SOURCES_SETTINGS_KEY,
      },
      {
        clientId: this.getClientId(),
      }
    );

    settings
      .fetch({
        error: () => {},
      })
      .then(() => {
        const customDataSources = settings.toJSON().data.combinedDataSources;
        this.publish('app:getData', {
          key: 'customDataSources',
          callback: () => {
            const menuItems = customDataSources.map((item) => {
              return {
                label: item.name,
                key: item.uuid,
                dataSources: item.dataSources,
              };
            });
            this.publish('app:updateData', { customDataSources: menuItems });
          },
        });
        customDataSources.forEach((dataSource) => {
          this._getMetrics({ dataSource });
        });
      });
  }

  /**
   * Retrieve custom data sources from API
   * @param payload
   */
  _get(payload) {
    const { callback } = payload;

    const settings = new ClientSettingsModel(
      {
        namespace: CUSTOM_DATA_SOURCES_SETTINGS_KEY,
      },
      {
        clientId: this.getClientId(),
      }
    );

    settings
      .fetch({
        error: () => {
          if (callback && callback instanceof Function) {
            callback([]);
          }
        },
      })
      .then(() => {
        if (callback && callback instanceof Function) {
          callback(settings.toJSON().data.combinedDataSources);
        }
      });
  }

  /**
   * Save custom data sources to API
   * @param payload
   */
  _set(payload) {
    const { callback, data } = payload;
    const clientId = this.getClientId();

    const settings = new ClientSettingsModel(
      {
        clientId: clientId,
        namespace: CUSTOM_DATA_SOURCES_SETTINGS_KEY,
        data: {
          combinedDataSources: data,
        },
        active: true,
        qualifier: null,
        lastUpdated: new Date().getTime(),
      },
      {
        clientId: clientId,
      }
    );

    settings.save().then(() => {
      this._getApp();
      if (callback && callback instanceof Function) {
        callback(data);
      }
    });
  }
}

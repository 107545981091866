import Backbone from 'backbone';

var UserModel = Backbone.Model.extend({
  initialize: function () {},

  parse: function (data) {
    var user = data || {};
    var clientId = user.clientId;

    data.name = data.firstName + ' ' + data.lastName;

    // If it's a foresee workbench user, add a synthetic granted authority so we can use permissions to manage that
    if (clientId === -1) {
      user.grantedAuthorities.push({
        a: 'FORESEE_WORKBENCH',
        authority: 'FORESEE_WORKBENCH',
      });
    }

    return user;
  },

  url: function () {
    return 'currentUser';
  },
});

export default UserModel;

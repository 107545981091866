import React from 'react';
import DateFilterContext, { initialState } from 'core/dateFilterContext';
import coreDispatcher from 'core/dispatcher';
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_GDF_DATA = gql`
  query savedGDF($input: SavedGDFInput!) {
    savedGDF(input: $input) {
      persistGDF
    }
  }
`;

export const SAVE_GDF = gql`
  mutation saveGDF($input: SaveGDFInput!) {
    saveGDF(input: $input)
  }
`;

export const IS_ENABLED = 'isEnabled';
export const IS_CARD_FILTERING = 'isCardFiltering';
export const DATE_RANGE = 'dateRange';
export const CARD_DATE_RANGE = 'cardDateRange';

const defaultObject = {
  [IS_ENABLED]: false,
  [IS_CARD_FILTERING]: false,
  [DATE_RANGE]: {},
  [CARD_DATE_RANGE]: {},
};

function getDashboard(dashboards, id) {
  if (dashboards.hasOwnProperty(id)) {
    return dashboards[id];
  }
  return defaultObject;
}

function toggleDashboardState(dashboards, dashboardId, key) {
  const dashboard = getDashboard(dashboards, dashboardId);
  return {
    ...dashboards,
    [dashboardId]: {
      ...dashboard,
      [key]: !dashboard?.[key],
    },
  };
}

function updateDashboardState(dashboards, dashboardId, key, value) {
  const dashboard = getDashboard(dashboards, dashboardId);
  return {
    ...dashboards,
    [dashboardId]: {
      ...dashboard,
      [key]: value,
    },
  };
}

const ContentWithDateFilterContext = (props) => {
  const { children, config } = props;
  const clientId = props.config?.client?.clientId;

  const [dashboards, setDashboards] = React.useState(initialState.dashboards);
  const [currentDashboard, setCurrentDashboard] = React.useState(null);

  const setCardDateRange = ({ dashboardId, cardDateRange }) => {
    setDashboards(
      updateDashboardState(
        dashboards,
        dashboardId,
        CARD_DATE_RANGE,
        cardDateRange
      )
    );
    setCurrentDashboard(dashboardId);
  };

  const setDateRange = ({ dashboardId, dateRange }) => {
    setDashboards(
      updateDashboardState(dashboards, dashboardId, DATE_RANGE, dateRange)
    );
    setCurrentDashboard(dashboardId);
  };

  const toggleCardFiltering = (dashboardId, isCardFiltering) => {
    setDashboards(
      updateDashboardState(
        dashboards,
        dashboardId,
        IS_CARD_FILTERING,
        isCardFiltering
      )
    );
  };

  const toggleDateFilter = (dashboardId) => {
    const newDashboards = toggleDashboardState(
      dashboards,
      dashboardId,
      IS_ENABLED
    );
    const dashboard = getDashboard(newDashboards, dashboardId);
    coreDispatcher.trigger('dashboards-data:global-date-filter:set', {
      globalDateFilter: dashboard,
    });
    setDashboards(newDashboards);
  };

  const globalDateSavePersist = (state) => {
    updateGDFProp({
      variables: {
        input: {
          clientId,
          persistGDF: JSON.stringify(state.persistGDF),
        },
      },
    });
  };

  const { isAuthRequired = false } = config;

  const {
    loading,
    error,
    data = {},
  } = clientId
    ? useQuery(GET_GDF_DATA, {
        variables: { input: { clientId } },
        skip: !config.isAuthRequired,
      })
    : {
        loading: true,
      };

  const [updateGDFProp] = useMutation(SAVE_GDF);

  React.useEffect(() => {
    if (Object.entries(dashboards).length > 0) {
      globalDateSavePersist({
        persistGDF: dashboards,
      });
    }
  }, [dashboards]);

  React.useEffect(() => {
    if (currentDashboard !== null) {
      const dashboard = getDashboard(dashboards, currentDashboard);
      if (dashboard?.[IS_ENABLED]) {
        coreDispatcher.trigger('dashboards-data:global-date-filter:set', {
          globalDateFilter: dashboard,
        });
      } else {
        coreDispatcher.trigger('dashboards-data:global-date-filter:set', {
          ...defaultObject,
        });
      }
    }
  }, [dashboards[currentDashboard], currentDashboard]);

  React.useEffect(() => {
    if (!loading && !error && isAuthRequired) {
      const dataToSave = data?.savedGDF?.persistGDF || '{}';
      setDashboards(JSON.parse(dataToSave));
    }
  }, [loading, error, isAuthRequired]);

  if (!clientId) {
    return children;
  }

  if (loading || error) {
    return null;
  }

  return (
    <DateFilterContext.Provider
      value={{
        dashboards,
        toggleDateFilter,
        toggleCardFiltering,
        setCurrentDashboard,
        setDateRange,
        setCardDateRange,
      }}
    >
      {children}
    </DateFilterContext.Provider>
  );
};

export default ContentWithDateFilterContext;

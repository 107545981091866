import Backbone from 'backbone';

const RestrictionTypesCollection = Backbone.Collection.extend({
  model: Backbone.Model,

  initialize: function (models, opts) {},

  parse: function (data) {
    return data.items;
  },

  url: function () {
    return `authorization-service/restrictions/types`;
  },
});

export default RestrictionTypesCollection;

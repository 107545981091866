import _ from 'underscore';
import { isValidNumber } from 'libphonenumber-js';

var Validator = (function () {
  var isEmpty = function (value) {
    if (value && value.trim().length > 0) {
      return false;
    }
    return true;
  };

  var isEmail = function (value) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    );
  };

  var isUrl = function (value) {
    return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i.test(
      value
    );
  };

  var isPhoneNumber = function (value) {
    return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value);
  };

  var isValidLocalePhoneNumber = function (value, locale) {
    return isValidNumber(value, locale);
  };

  return {
    /**
     * @param {Object} rules - Rules and value to validate
     * @return {Object} Results of validation checks and an error message
     */
    validate: function (rules) {
      /*
			        var rules = {
			          value: "Hello world",
			          required: false,
			          email: true,
			          url: false,
			          phoneNumber: false,
			          number: false,
			          int: false,
			          float: false
			        }
				*/

      const required = _.isFunction(rules.required)
        ? rules.required()
        : rules.required;

      if (required && isEmpty(rules.value)) {
        return {
          valid: false,
          message: 'This field is required.',
        };
      }

      var validateTypes = rules.value.length || required;

      if (validateTypes && rules.email && !isEmail(rules.value)) {
        return {
          valid: false,
          message: 'Please enter a valid email.',
        };
      }

      if (validateTypes && rules.url && !isUrl(rules.value)) {
        return {
          valid: false,
          message: 'Please enter a valid url (http://www.test.com)',
        };
      }

      if (
        validateTypes &&
        rules.phoneNumberLocale &&
        !isValidLocalePhoneNumber(rules.value, rules.phoneNumberLocale)
      ) {
        return {
          valid: false,
          message: 'Please enter a valid phone number.',
        };
      }

      if (validateTypes && rules.phoneNumber && !isPhoneNumber(rules.value)) {
        return {
          valid: false,
          message: 'Please enter a valid phone number.',
        };
      }

      if (
        validateTypes &&
        rules.minLength &&
        rules.value.length < rules.minLength
      ) {
        return {
          valid: false,
          message: `Please enter at least ${rules.minLength} characters.`,
        };
      }

      if (
        validateTypes &&
        rules.maxLength &&
        rules.value.length > rules.maxLength
      ) {
        return {
          valid: false,
          message: `Please enter at most ${rules.maxLength} characters.`,
        };
      }

      //TODO
      //is number

      //TODO
      //is int

      //TODO
      //is float

      return {
        valid: true,
        message: '',
      };
    },
  };
})();

export default Validator;

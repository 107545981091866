import $ from 'jquery';
import _ from 'underscore';
import Constants from 'core/constants';
import BaseController from 'controllers/base';
import DashboardPageableModel, {
  DashboardCollection,
} from 'collections/clients/dashboard';
import DashboardModel from 'models/clients/dashboard';
import DashboardCopyModel from 'models/clients/dashboards/copy';
import DashboardShareModel from 'models/clients/dashboards/share';
import DashboardCardPageableModel, {
  DashboardCardsCollection,
} from 'collections/clients/dashboards/cards';
import DashboardCardModel from 'models/clients/dashboards/card';
import DashboardCardCopyModel from 'models/clients/dashboards/card/copy';
import SuperLatentsModel from 'models/clients/superLatents';
import GenerateRoute from 'core/utils/generateRoute';
import { cloneDeep } from 'lodash';
import isBigDataMeasure from 'views/utils/is-big-data-measure';
import getDatePickerButtons from 'core/utils/get-date-picker-buttons';
import TAFilterModel from 'models/clients/text-analytics/ta-filter';
import { supportsDateTime } from 'views/panels/dashboards/date-type';
import featureFlags from 'core/featureFlags';
import addDimensionForKPI from 'core/utils/add-dimension-for-kpi';
import {
  DATA_SOURCE_DASHBOARDS_CARDS,
  DATA_SOURCE_DASHBOARDS,
} from 'views/panels/data-sources/queries';
import { getDefaultBenchmarkKeys } from 'views/panels/data-sources/utils/benchmarkKeys';
import moment from 'moment';

const { HOLLOW_DASHBOARD_TYPE, DASHBOARD } = Constants;
const shallowClone = (x) => Object.assign({}, x);

class DashboardsController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'dashboards:get': '_get',
        'dashboards:set': '_set',
        'dashboards:changeOwner': '_changeDashboardOwner',
        'dashboards:sortOrder:put': '_saveSortOrders',
        'dashboards:cards:sortOrder:put': '_saveCardSortOrders',
        'dashboards:hidden:put': '_toggleDashboardHidden',
        'dashboards:post': '_post',
        'dashboards:put': '_put',
        'dashboards:delete': '_delete',
        'dashboards:copy': '_copyDashboard',
        'dashboards:share': '_share',
        'dashboards:shares:get': '_getShares',
        'dashboards:shares:getMultiple': '_fetchDashboardsShares',
        'dashboards:shares:put': '_updateShare',
        'dashboards:shares:delete': '_deleteShare',
        'dashboards:cards:get': '_getCards',
        'dashboards:cards:set': '_setCards',
        'dashboards:cardDetail:action:set': '_setCardDetailAction',
        'dashboards:cardDetail:action:setRoute': '_updateCardDetailActionRoute',
        'dashboards:cardDetail:set': '_setCardDetails',
        'dashboards:cardDetail:get': '_getCardDetails',
        'dashboards:card:data:set': '_setCardData',
        'dashboards:card:setSize': '_setCardSize',
        'dashboards:cardDetail:lookupDate': '_setDateRange',
        'dashboards:cardDetail:setModelFilter': '_setModelFilter',
        'dashboards:cardDetail:setPageFilter': '_setPageFilter',
        'dashboards:cardDetail:setHierarchyFilter': '_setHierarchyFilter',
        'dashboards:cardDetail:setEventFilter': '_setEventFilter',
        'dashboards:cardDetail:setTAFilter': '_setTAFilter',
        'dashboards:cardDetail:setBDFilter': '_setBDFilter',
        'dashboards:drillIn': '_drillIn',
        'dashboards:card:new': '_initNewCard',
        'dashboards:card:post': '_postCard',
        'dashboards:card:copy': '_copyCard',
        'dashboards:card:delete': '_deleteCard',
        'dashboards:card:attributes:put': '_updateAttributes',
        'dashboards:measures:reload': '_reloadMeasureDashCards',
        'dashboards:reload': '_reloadDashboard',
        'dashboards:cards:setTAFilters': '_updateTAFilters',
        'dashboards:cards:setOEFilters': '_updateOEFilters',
        'dashboard:data:get': '_getDashboardData',
        'dashboard:cards:update': '_updateDashboardCards',
      },
      name: 'dashboards',
      dependsOn: [
        'user',
        'authorization',
        'cxmeasure',
        'dashboards-data',
        'export',
        'custom-data-sources',
        'tafilters',
      ],
    });
  }

  initialize(opts) {
    const options = opts || {};
    options.clientId = this.getClientId();
  }

  _fetchDashboardPage(payload, page) {
    const chunkSize = 25; // TODO increase this to 100 when we get closer to release. Leaving at 25 to make testing easier
    const { externalType, externalId, callback } = payload;

    this._getMeasures({
      callback: (cxmeasures) => {
        const measure = cxmeasures.find(
          (measure) =>
            `${measure.measurementKey}` === `${externalId}` ||
            `${measure.modelInstanceId}` === `${externalId}`
        );

        if (
          externalType === 'MEASURE' &&
          isBigDataMeasure(measure?.surveyType) &&
          !this.hasFeature('cxmeasurebd-survey-dashboard')
        ) {
          this.publish('app:getData', {
            key: 'measureDashboards',
            callback: (measureDashboards) => {
              measureDashboards = measureDashboards || {};
              measureDashboards[measure?.measurementKey] = [];
              this.publish('app:updateData', { measureDashboards });
            },
          });

          return false;
        }

        const model = new DashboardPageableModel(false, {
          clientId: this.getClientId(),
          externalType,
          externalId:
            externalType === 'MEASURE' && measure
              ? measure.measurementKey
              : externalId,
          limit: chunkSize,
          offset: chunkSize * page,
        });
        model
          .fetch({
            error: (...args) => {
              //TODO
            },
            doNotAbort: payload.doNotAbort,
          })
          .then(() => {
            if (page === 0) {
              this.dashboards = [];
            }
            this.dashboards = this.dashboards
              .concat(
                model.toJSON().results.map((d) => {
                  delete d.cards;
                  return d;
                })
              )
              .filter((dashboard) => {
                const ff = this._getAttribute(dashboard, 'featureFlag');
                return !ff || this.hasFeature(ff);
              });
            if (
              model.get('totalElements') > this.dashboards.length &&
              model.changed.hasMore
            ) {
              this._fetchDashboardPage(payload, page + 1);
            } else {
              this.publish('authorization:users:get', {
                internal: true,
                ids: _.compact(
                  this.dashboards.map((d) => {
                    return d.ownerId;
                  })
                ),
                callback: (users) => {
                  this.dashboards.forEach((d) => {
                    const user = _.findWhere(users, { userId: d.ownerId });
                    d.ownerUser = user;
                  });
                  if (externalType === 'MEASURE' && externalId) {
                    this.publish('app:getData', {
                      key: 'measureDashboards',
                      callback: (measureDashboards) => {
                        measureDashboards = measureDashboards || {};
                        measureDashboards[externalId] = this.dashboards;
                        this.publish('app:updateData', { measureDashboards });
                      },
                    });
                  } else if (
                    externalType === Constants.FILTER_TYPE_TEXT_ANALYTICS
                  ) {
                    this.publish('app:updateData', {
                      taDashboards: model.attributes.dashboards,
                      callback,
                    });
                  } else {
                    this.publish('app:updateData', {
                      dashboards: this.dashboards,
                      callback,
                    });
                  }
                },
              });
            }
          });
      },
    });
  }

  _getMeasures({ callback }) {
    this.publish('app:getData', {
      key: 'cxmeasures',
      callback: (cxmeasures = []) => {
        if (cxmeasures.length === 0) {
          this.publish('cxmeasure:get', {
            alwaysReturnCallback: true,
            callback,
          });
        } else {
          callback(cxmeasures);
        }
      },
    });
  }

  _get(payload) {
    if (!payload.externalType) {
      this.publish('app:updateData', {
        dashboards: false,
      });
    }
    this._fetchDashboardPage(payload, 0);
  }

  _getUsersAndRoles(userIds, roleIds) {
    const defs = [];
    const userDef = $.Deferred();
    if (userIds.length) {
      this.publish('authorization:users:get', {
        internal: true,
        ids: userIds,
        callback: (users) => {
          userDef.resolve(users);
        },
      });
    } else {
      userDef.resolve([]);
    }
    defs.push(userDef);
    const roleDef = $.Deferred();
    if (roleIds.length) {
      this.publish('authorization:roles:get', {
        callback: (roles) => {
          roleDef.resolve(
            roleIds.map((roleId) => {
              return _.findWhere(roles, { roleId });
            })
          );
        },
      });
    } else {
      roleDef.resolve([]);
    }
    defs.push(roleDef);
    return $.when.apply($, defs);
  }

  _fetchDashboardsShares(dashboards) {
    const getShare = async (dashboardId, clientId, offset) => {
      const model = new DashboardShareModel(false, {
        dashboardId,
        clientId: this.getClientId(),
        offset,
      });

      const modelResult = await model.fetch({ error: () => {} });

      const { next } = modelResult;
      if (next) {
        const recResult = await getShare(dashboardId, clientId, offset + 1);
        return {
          dashboardId,
          ...modelResult,
          shares: [...modelResult.shares, ...recResult.shares],
        };
      }

      return {
        dashboardId,
        ...modelResult,
      };
    };

    const promises = dashboards.map(async (d) =>
      getShare(d.dashboardId, this.getClientId(), 0)
    );

    const that = this;
    Promise.all(promises).then((newShares) => {
      that.publish('app:getData', {
        key: 'dashboardShares',
        callback: (dashboardShares) => {
          const shares = dashboardShares || {};

          const userIds = [];
          const roleIds = [];
          newShares.forEach((d) => {
            d.shares.forEach((s) => {
              if (s.shareType === 'USER') {
                userIds.push(s.userId);
              } else if (s.shareType === 'ROLE') {
                roleIds.push(s.roleId);
              }
            });
          });

          that
            ._getUsersAndRoles(_.uniq(userIds), _.uniq(roleIds))
            .done((users, roles) => {
              newShares.forEach((d) => {
                const dShares = [];
                d.shares.forEach((s) => {
                  if (s.shareType === 'USER') {
                    s.metadata = _.findWhere(users, { userId: s.userId });
                  } else if (s.shareType === 'ROLE') {
                    s.metadata = _.findWhere(roles, { roleId: s.roleId });
                  } else if (s.shareType === 'CLIENT') {
                    s.metadata = {
                      name: that.getString('general.public'),
                      description: that.getString(
                        'dashboards.sharing.access.public'
                      ),
                    };
                  }
                  if (s.metadata) {
                    dShares.push(s);
                  }
                });
                shares[d.dashboardId] = dShares;
              });
              that.publish('app:updateData', { dashboardShares: shares });
            });
        },
      });
    });
  }

  _saveDashboards(dashboards) {
    const model = new DashboardCollection(dashboards, {
      clientId: this.getClientId(),
    });
    model.save({
      error: () => {},
    });
  }

  _put(payload, doNotRefetch) {
    const { dashboardId, ownerChanged } = payload;
    const model = new DashboardModel(payload, {
      clientId: this.getClientId(),
      id: dashboardId,
    });
    model.save().then(() => {
      const options = {};
      if (ownerChanged) {
        options.callback = () => {
          this.changeRoute('dashboards.base');
        };
      }
      if (!doNotRefetch) {
        options.doNotAbort = true;
        this._get(options);
      }
      if (payload.callback) {
        payload.callback();
      }
    });
  }

  _delete(payload) {
    const { dashboardId } = payload;
    this.publish('app:updateState', { deletingDashboard: true });
    const model = new DashboardModel(
      {
        dashboardId: dashboardId,
      },
      {
        clientId: this.getClientId(),
        id: dashboardId,
      }
    );
    model.destroy({
      success: () => {
        this.publish('app:updateState', {
          deleteDashboard: false,
          deletingDashboard: false,
        });
        this._get({ doNotAbort: true });
        this.changeRoute('dashboards.base', {});
        this.publish('app:messageFlash', {
          messageText: this.getString('dashboards.snackbar.deleteDashboard'),
          messageType: 'info',
        });
        window.dispatchEvent(new CustomEvent('xm-navigator:fetch-trigger'));
      },
    });
  }

  _share(payload, isNew) {
    const {
      dashboardId,
      accessType,
      shareType,
      userId,
      roleId,
      notification,
      notificationMessage,
      callback,
    } = payload;
    const model = new DashboardShareModel(
      {
        accessType: accessType || 'READ',
        clientId: this.getClientId(),
        shareType: shareType || '',
        hidden: false,
        notification: notification,
        notificationMessage: notificationMessage || '',
        userId: userId,
        roleId: roleId,
      },
      {
        dashboardId,
        clientId: this.getClientId(),
      }
    );
    model
      .save({
        error: () => {},
      })
      .then((data) => {
        if (isNew) {
          this._addDashboard(payload);
        }
        if (callback) {
          callback(data);
        } else {
          this._get({});
          this._fetchDashboardsShares([{ dashboardId }]);
        }
      });
  }

  //TODO see where this gets called...can this be deprecated? in favour of _fetchDashboardsShares
  _getShares(payload) {
    const { dashboardId } = payload;
    const model = new DashboardShareModel(false, {
      dashboardId,
      clientId: this.getClientId(),
    });
    model
      .fetch({
        error: () => {},
      })
      .then(() => {
        this.publish('app:getData', {
          key: 'dashboardShares',
          callback: (dashboardShares) => {
            const shares = dashboardShares || {};
            shares[dashboardId] = model.toJSON().shares;
            this.publish('app:updateData', { dashboardShares: shares });
          },
        });
      });
  }

  _updateShare(share) {
    const { shareId, dashboardId } = share;
    const model = new DashboardShareModel(share, {
      dashboardId,
      shareId,
      clientId: this.getClientId(),
    });

    //No loading state here, immediately update app data with new share
    this.publish('app:getData', {
      key: 'dashboardShares',
      callback: (dashboardShares) => {
        const shares = dashboardShares || {};
        shares[dashboardId] = shares[dashboardId].map((s) => {
          if (s.shareId === shareId) {
            return share;
          }
          return s;
        });
        this.publish('app:updateData', { dashboardShares: shares });
      },
    });

    model.save().then(() => {
      this._fetchDashboardsShares([{ dashboardId }]);
    });
  }

  _deleteShare(payload) {
    const { shareId, dashboardId, callback } = payload;
    const model = new DashboardShareModel(
      {
        shareId,
      },
      {
        shareId,
        dashboardId,
        clientId: this.getClientId(),
      }
    );
    model.destroy({
      success: () => {
        this._fetchDashboardsShares([{ dashboardId }]);
        if (callback) {
          callback({ shareId, dashboardId });
        }
      },
      // error: ,
      // doNotAbort: true
    });
  }

  _updateDashboardCard(dashboardId, card, callback) {
    if (card.dataSourceId) {
      this.publish('dashboards-data:card:rest:set', {
        cardData: card,
      });
    }
    this.publish('app:getData', {
      key: 'dashboardCards',
      callback: (allDashboardCards) => {
        const dashboardCards = allDashboardCards || {};
        const existingCards = dashboardCards[dashboardId];
        if (existingCards) {
          const index = _.findIndex(existingCards, { cardId: card.cardId });
          if (index >= 0) {
            existingCards[index] = card;
          } else {
            console.log(`Card ${card.cardId} not found`);
          }
        }
        this.publish('app:updateData', { dashboardCards, callback });
      },
    });
  }

  _updateDashboardCards(dashboardId, cards, callback) {
    this.publish('app:getData', {
      key: 'dashboardCards',
      callback: (dashboardCards) => {
        dashboardCards = dashboardCards || {};
        dashboardCards[dashboardId] = cards;
        this.publish('app:updateData', { dashboardCards, callback });
      },
    });
  }

  _getDashboard(dashboardId, callback) {
    this.publish('app:getData', {
      key: 'dashboards',
      callback: (dashboards) => {
        const dashboard = _.find(dashboards, (d) => {
          return d.dashboardId.toString() === dashboardId.toString();
        });
        callback(dashboard);
      },
    });
  }

  _getCards(payload) {
    const {
      dashboardId,
      dateRange,
      externalType,
      externalId,
      dashboardType,
      taQuestions,
      shouldFetchDashboardData = true,
      filters,
      callback,
    } = payload;
    this._updateDashboardCards(dashboardId, false);
    const model = new DashboardCardPageableModel(false, {
      clientId: this.getClientId(),
      dashboardId,
    });

    model
      .fetch({
        error: () => {
          //TODO
        },
        doNotAbort: true,
      })
      .then(() => {
        const { cards } = model.toJSON();
        if (externalType === 'MEASURE') {
          this._isMeasureDashboard(
            dashboardId,
            cards,
            externalId,
            shouldFetchDashboardData,
            callback,
            filters
          );
        } else if (externalType === Constants.FILTER_TYPE_TEXT_ANALYTICS) {
          // Apply selected questionKeys/questionIds here
          let questionPropName;
          if (
            externalId.type ===
            Constants.DASHBOARD.textAnalytics.source.cxmeasure
          ) {
            questionPropName = 'taQuestionTags';
          } else {
            questionPropName = 'taQuestionIds';
          }

          const cardsWithUpdatedAttributes = cards.map((card) => ({
            ...card,
            metadata: {
              ...card.metadata,
              attributes: {
                ...card.metadata.attributes,
                dateRange,
                taQuestionKeys: [],
                [questionPropName]: taQuestions.map(({ id }) => id),
                taQuestions,
              },
            },
          }));

          this._setTemplateMetaData({
            ...payload,
            cards: cardsWithUpdatedAttributes,
          });

          if (callback) {
            callback(cardsWithUpdatedAttributes);
          }
        } else if (dashboardType === HOLLOW_DASHBOARD_TYPE) {
          this._populateTemplate(
            dashboardId,
            cards,
            false,
            shouldFetchDashboardData,
            callback
          );
        } else {
          this._updateDashboardCards(dashboardId, cards, () => {
            if (callback) {
              callback(cards);
            }
            this._fetchDashboardsShares([{ dashboardId }]);
          });
        }
      });
  }

  _getDashboardData(cards) {
    this.publish('dashboards-data:dashboard:get', { cards });
  }

  _isMeasureDashboard(
    dashboardId,
    cards,
    externalId,
    shouldFetchDashboardData = true,
    callback,
    filters
  ) {
    this.publish('app:getData', {
      key: 'measureDashboards',
      callback: (measureDashboards) => {
        if (measureDashboards && measureDashboards[externalId]) {
          const measureDashboard = _.findWhere(measureDashboards[externalId], {
            dashboardId,
          });
          if (measureDashboard && measureDashboard.externalType === 'MEASURE') {
            this._setMeasureDashboardMetaData(
              measureDashboard.externalId,
              cards,
              dashboardId,
              false,
              false,
              shouldFetchDashboardData,
              callback,
              filters
            );
          } else {
            this._updateDashboardCards(dashboardId, cards, () => {
              if (callback) {
                callback(cards);
              }
              if (shouldFetchDashboardData) {
                this._getDashboardData(cards);
              }
            });
          }
        }
      },
    });
  }

  _checkAllDataSources = (card) => {
    const allDataSources = this._getAttribute(card, 'allDataSources');
    const dataSources = this._getAttribute(card, 'dataSources');
    return allDataSources && (!dataSources || dataSources.length === 0);
  };

  _populateTemplate(
    dashboardId,
    cards,
    isCardDetail,
    shouldFetchDashboardData = true,
    callback
  ) {
    const updateData = (cards) => {
      if (isCardDetail) {
        this.publish('app:updateData', { cardDetail: cards[0] });
        this.publish('dashboards-data:card:get', {
          ...cards[0],
          isOnDetail: true,
        });
      } else {
        this._updateDashboardCards(dashboardId, cards, () => {
          if (callback) {
            callback(cards);
          }
          if (shouldFetchDashboardData) {
            this._getDashboardData(cards);
          }
        });
      }
    };

    const fetchMeasures = cards.some(this._checkAllDataSources);

    if (fetchMeasures) {
      this._getMeasures({
        callback: (cxmeasures) => {
          const activeMeasures = _.where(cxmeasures, { currentlyActive: 1 });
          cards.forEach((card) => {
            if (this._checkAllDataSources(card)) {
              let cardMeasures = activeMeasures;
              const metrics = this._getAttribute(card, 'metrics') || [];

              if (
                metrics.length &&
                [
                  Constants.METRIC_TYPES.SAT,
                  Constants.METRIC_TYPES.NPS,
                ].includes(metrics[0].type)
              ) {
                cardMeasures = cardMeasures.filter((m) =>
                  Constants.SURVEY_METRICS[metrics[0].type].includes(
                    m.surveyType
                  )
                );
              }

              switch (this._getAttribute(card, 'surveyType')) {
                case Constants.DASHBOARD.sourceType.cxmeasurebd:
                  card.metadata.attributes.dataSources = cardMeasures.map(
                    (m) => ({
                      type: Constants.DASHBOARD.sourceType.cxmeasurebd,
                      key: m.modelInstanceId,
                      name: m.name,
                    })
                  );

                  break;

                case Constants.DASHBOARD.sourceType.cxmeasure:
                default:
                  card.metadata.attributes.dataSources = cardMeasures.map(
                    (m) => ({
                      type: Constants.DASHBOARD.sourceType.cxmeasure,
                      key: m.measurementKey,
                      name: m.name,
                    })
                  );
                  break;
              }
            }
          });
          updateData(cards);
        },
      });
    } else {
      updateData(cards);
    }
  }

  _getSelectedDateId(key) {
    const datePickerButtons = this.hasFeature('additional-date-ranges')
      ? Constants.datePickerAdditionalButtons
      : Constants.datePickerButtons;
    return (datePickerButtons[key] && datePickerButtons[key].id) || 'custom';
  }

  _reloadMeasureDashCards({ cards, filters = [], isReload = true }) {
    const { baseDate, modelFilter, pageFilter, hierarchyFilter, selected } =
      this.controllers.measures;
    cards.forEach((card) => {
      card.metadata.attributes.filters = filters;
      card.metadata.attributes.selectedDateId =
        this._getSelectedDateId(selected);
      card.metadata.attributes.dateRange = baseDate;

      card.metadata.attributes.modelFilterId = modelFilter?.modelFilterId
        ? modelFilter.modelFilterId
        : false;
      if (pageFilter && pageFilter.pageFilterId) {
        card.metadata.attributes.pageFilterId = pageFilter.pageFilterId;
      } else {
        card.metadata.attributes.pageFilterId = false;
      }
      if (hierarchyFilter && hierarchyFilter.filterId) {
        card.metadata.attributes.hierarchyFilterId = hierarchyFilter.filterId;
      } else {
        card.metadata.attributes.hierarchyFilterId = false;
      }
    });
    this.publish('dashboards-data:dashboard:get', { cards, isReload });
  }

  _reloadDashboard(payload) {
    const { externalType, cards, modelFilterId, callback } = payload;
    if (externalType === Constants.FILTER_TYPE_TEXT_ANALYTICS) {
      this._setTemplateMetaData(payload);
      const dataSources = this._getAttribute(cards[0], 'dataSources');
      if (
        modelFilterId &&
        dataSources[0].type === Constants.DASHBOARD.sourceType.cxmeasure
      ) {
        this.publish('filters:set', {
          retryIfUninitialized: true,
          modelFilterId: modelFilterId,
          measurementKey: dataSources[0].key,
          isInitialization: true,
        });
      }
      if (callback) {
        callback(cards);
      }
    }
  }

  _setMeasureDashboardMetaData(
    measurementKey,
    cards,
    dashboardId,
    isCardDetail,
    autoFetch,
    shouldFetchDashboardData = true,
    callback = () => {},
    filters = []
  ) {
    const defs = [];
    measurementKey = parseInt(measurementKey, 10);

    this._getMeasures({
      callback: (cxmeasures) => {
        const measure = cxmeasures.find(
          (thisMeasure) => thisMeasure.measurementKey === measurementKey
        );
        const isBigData = isBigDataMeasure(measure.surveyType);

        cards.forEach((card) => {
          card.metadata.attributes.dataSources = [
            isBigData
              ? {
                  type: Constants.DASHBOARD.sourceType.cxmeasurebd,
                  key: measure.modelInstanceId,
                }
              : {
                  type: Constants.DASHBOARD.sourceType.cxmeasure,
                  key: measurementKey,
                },
          ];
          card.metadata.attributes.datasourceLocked = true;

          const {
            baseDate,
            modelFilter,
            pageFilter,
            hierarchyFilter,
            selected,
          } = this.controllers.measures;

          card.metadata.attributes.filters = filters;
          card.metadata.attributes.selectedDateId =
            this._getSelectedDateId(selected);
          card.metadata.attributes.dateRange = baseDate;

          if (modelFilter && modelFilter.modelFilterId) {
            card.metadata.attributes.modelFilterId = modelFilter.modelFilterId;
          } else {
            card.metadata.attributes.modelFilterId = false;
          }
          if (pageFilter && pageFilter.pageFilterId) {
            card.metadata.attributes.pageFilterId = pageFilter.pageFilterId;
          } else {
            card.metadata.attributes.pageFilterId = false;
          }
          if (hierarchyFilter && hierarchyFilter.filterId) {
            card.metadata.attributes.hierarchyFilterId =
              hierarchyFilter.filterId;
          } else {
            card.metadata.attributes.hierarchyFilterId = false;
          }

          if (
            card.metadata.attributes.benchmarkType &&
            !card.metadata.attributes.benchmarkKeys
          ) {
            const benchmarkKeys = {};
            benchmarkKeys[measurementKey] = {
              bmCatKeys: 1,
              superLatentKey: 128,
              bmCatTag: 'fs_website_index__desktop',
              superLatentTag: 'fs_satisfaction_score',
            };
            card.metadata.attributes.benchmarkKeys = benchmarkKeys;
          }
          const allElementsAttr =
            card.metadata.attributes?.allElements || false;

          if (isBigData && allElementsAttr) {
            delete card.metadata.attributes.benchmarkType;
            delete card.metadata.attributes.benchmarkKeys;
          }

          const {
            metrics,
            allElements,
            allFutureBehaviors,
            questionKeys,
            chartType,
            centerMetricDisplay,
          } = card.metadata.attributes;
          const hasMetrics = metrics && metrics.length;
          const needLatents = allElements || allFutureBehaviors;
          if (chartType === 'donut' && centerMetricDisplay === undefined) {
            card.metadata.attributes.centerMetricDisplay = true;
          }
          if (
            needLatents &&
            (!hasMetrics ||
              (card.metadata.attributes.dataSources.length === 1 &&
                chartType === Constants.DASHBOARD.chartTypes.verticalBar))
          ) {
            const def = $.Deferred();
            if (isBigData) {
              this._superLatentsForCards((superLatents) => {
                this.publish('cxmeasurebd:latents:get', {
                  modelInstanceId: measure.modelInstanceId,
                  callback: (latents) => {
                    card.metadata.attributes.metrics = latents
                      .filter(
                        (latent) =>
                          latent.type !== Constants.CX_FILTER_TYPES.SATISFACTION
                      )
                      .map((latent) => {
                        return {
                          type: Constants.METRIC_TYPES.LS,
                          key: latent.tag,
                          superLatentKey: this._matchSuperLatent(
                            superLatents,
                            null,
                            latent.tag
                          ),
                        };
                      });
                    def.resolve();
                  },
                });
              });
            } else {
              this._superLatentsForCards((superLatents) => {
                this.publish('cxmeasure:latents:get', {
                  measurementKey,
                  callback: (latents) => {
                    const latentType = allElements
                      ? Constants.LATENT_TYPE.ELEMENT
                      : Constants.LATENT_TYPE.FUTURE_BEHAVIOR;
                    card.metadata.attributes.metrics = _.where(latents, {
                      latentType,
                    }).map((e) => {
                      return {
                        type: Constants.METRIC_TYPES.LS,
                        key: e.latentKey,
                        superLatentKey: this._matchSuperLatent(
                          superLatents,
                          e.superLatentKey,
                          null
                        ),
                      };
                    });
                    def.resolve();
                  },
                });
              });
            }
            defs.push(def);
          }

          const hasQuestions = questionKeys && questionKeys.length;
          if (chartType === Constants.DASHBOARD.chartTypes.voc) {
            if (!hasQuestions || !hasMetrics) {
              const def = $.Deferred();

              if (isBigData) {
                this.publish('cxmeasurebd:latents:get', {
                  modelInstanceId: measure.modelInstanceId,
                  callback: (latents) => {
                    if (!hasMetrics) {
                      card.metadata.attributes.metrics = latents
                        .filter(
                          (latent) =>
                            latent.type ===
                            Constants.CX_FILTER_TYPES.SATISFACTION
                        )
                        .map((latent) => {
                          return {
                            type: Constants.METRIC_TYPES.LS,
                            key: latent.tag,
                          };
                        });
                    }
                    if (!hasQuestions) {
                      this.publish('cxmeasurebd:commentquestions:get', {
                        modelInstanceId: measure.modelInstanceId,
                        callback: (questions = []) => {
                          card.metadata.attributes.questionKeys = questions.map(
                            (q) => q.tag
                          );
                          def.resolve();
                        },
                      });
                    } else {
                      def.resolve();
                    }
                  },
                });
              } else {
                this.publish('cxmeasure:latents:get', {
                  measurementKey,
                  callback: (latents) => {
                    if (!hasMetrics) {
                      card.metadata.attributes.metrics = _.where(latents, {
                        latentType: Constants.LATENT_TYPE.SATISFACTION,
                      }).map((e) => {
                        return {
                          type: Constants.METRIC_TYPES.LS,
                          key: e.latentKey,
                        };
                      });
                    }
                    if (!hasQuestions) {
                      this.publish('cxmeasure:commentquestions:get', {
                        measurementKey,
                        callback: (commentquestions) => {
                          if (commentquestions?.length) {
                            card.metadata.attributes.questionKeys = [
                              commentquestions[0].qKey,
                            ];
                          }
                          def.resolve();
                        },
                      });
                    } else {
                      def.resolve();
                    }
                  },
                });
              }
              defs.push(def);
            }
          }
        });

        const that = this;
        $.when.apply($, defs).done(function () {
          if (isCardDetail) {
            that.publish('app:updateData', { cardDetail: cards[0] });
            that.publish('dashboards-data:card:get', {
              ...cards[0],
              isOnDetail: true,
              autoFetch,
            });
          } else {
            that._updateDashboardCards(dashboardId, cards, () => {
              if (callback) {
                callback(cards);
              }
              if (shouldFetchDashboardData) {
                that._getDashboardData(cards);
              }
            });
          }
        });
      },
    });
  }

  _setTemplateMetaData(payload) {
    const {
      cards,
      taQuestions,
      externalId,
      dashboardId,
      modelFilterId,
      hierarchyFilterId,
      taFilterIds,
      taQuestionIds,
      taQuestionTags,
      shouldFetchDashboardData = true,
    } = payload;
    const { baseDate, pageFilter, selected } = this.controllers.measures;

    // Massage card metadata to account for filters, datasources, and other selections
    const cardsWithUpdatedMetadata = cards.map((card) => {
      const attributes = { ...card.metadata.attributes };

      switch (externalId.type) {
        case Constants.TEXT_ANALYTICS.SOURCE_TYPES.CX_MEASURE:
          attributes.dataSources = [
            {
              key: externalId.id,
              type: Constants.DASHBOARD.sourceType.cxmeasure,
            },
          ];
          break;
        case Constants.TEXT_ANALYTICS.SOURCE_TYPES.FEEDBACK:
          attributes.dataSources = [
            {
              key: externalId.id,
              type: Constants.DASHBOARD.sourceType.feedback,
            },
          ];
          break;
        case Constants.TEXT_ANALYTICS.SOURCE_TYPES.CUSTOM_FEED:
          attributes.dataSources = [
            {
              key: externalId.id,
              type: Constants.DASHBOARD.sourceType.customFeed,
            },
          ];
          break;
      }
      attributes.selectedDateId = this._getSelectedDateId(
        (this._getAttribute(cards[0], 'dateRange') || {}).selected
      );

      if (modelFilterId) {
        attributes.modelFilterId = modelFilterId;
      } else {
        attributes.modelFilterId = false;
      }
      if (pageFilter && pageFilter.pageFilterId) {
        attributes.pageFilterId = pageFilter.pageFilterId;
      } else {
        attributes.pageFilterId = false;
      }
      if (hierarchyFilterId) {
        attributes.hierarchyFilterId = hierarchyFilterId;
      } else {
        attributes.hierarchyFilterId = false;
      }
      if (taFilterIds) {
        attributes.taFilterIds = taFilterIds;
      } else {
        attributes.taFilterIds = null;
      }
      if (taQuestionIds && taQuestionIds.length) {
        attributes.taQuestionIds = taQuestionIds;
      } else if (taQuestionTags?.length) {
        attributes.taQuestionTags = taQuestionTags;
      }

      return {
        ...card,
        metadata: {
          ...card.metadata,
          attributes,
        },
      };
    });

    if (taQuestions) {
      this._addOpenEnds({ cards: cardsWithUpdatedMetadata, taQuestions });
    }

    // measurementKey applies only when dataSource is cxmeasure type
    if (externalId.type === Constants.TEXT_ANALYTICS.SOURCE_TYPES.CX_MEASURE) {
      this.publish('hierarchyfilters:measurementKey:filterId:set', {
        retryIfUninitialized: true,
        filterId: hierarchyFilterId,
        measurementKey:
          cardsWithUpdatedMetadata[0].metadata.attributes.dataSources[0].key,
      });
    }

    if (!hierarchyFilterId) {
      this.publish('hierarchyfilters:reset', {
        retryIfUninitialized: true,
        isInitialization: true,
      });
    }

    this._updateDashboardCards(dashboardId, cardsWithUpdatedMetadata, () => {
      if (shouldFetchDashboardData) {
        this._getDashboardData(cardsWithUpdatedMetadata);
      }
    });
  }

  _set(payload) {
    this.publish('app:updateData', {
      dashboards: payload.dashboards,
      callback: payload.callback,
    });
  }

  _toggleDashboardHidden(payload) {
    this.publish('app:getData', {
      key: 'dashboards',
      callback: (dashboards) => {
        dashboards.forEach((d, index) => {
          if (d.dashboardId === payload.dashboardId) {
            d.hidden = !d.hidden;
            this._put(d, true);
          }
        });
        this.publish('app:updateData', { dashboards });

        window.dispatchEvent(new CustomEvent('xm-navigator:fetch-trigger'));
      },
    });
  }

  _saveSortOrders(payload) {
    this.publish('app:getData', {
      key: 'dashboards',
      callback: (dashboards) => {
        if (dashboards) {
          const sortedDashboards = dashboards.map((dashboard, index) => {
            return {
              ...dashboard,
              sortOrder: index,
            };
          });
          this._saveDashboards(sortedDashboards);
          window.dispatchEvent(new CustomEvent('xm-navigator:fetch-trigger'));
        }
      },
    });
  }

  _saveCardSortOrders(payload) {
    const { dashboardId } = payload;
    this.publish('app:getData', {
      key: 'dashboardCards',
      callback: (allDashboardsCards) => {
        const dashboardCards = allDashboardsCards[dashboardId] || [];
        if (dashboardCards) {
          const sortedCards = dashboardCards.map((card, index) => {
            return {
              ...card,
              sortOrder: index,
            };
          });
          this._saveCards({ cards: sortedCards, dashboardId });
        }
      },
    });
  }

  _setCards(payload) {
    const { dashboardId, cards, callback } = payload;
    this._updateDashboardCards(dashboardId, cards, callback);
  }

  _getAttribute(card, key) {
    if (card && card.metadata && card.metadata.attributes) {
      return card.metadata.attributes[key];
    }
    return null;
  }

  _getCardMetaData(card, prevCard) {
    const dataSources = this._getAttribute(card, 'dataSources');
    const modelFilterId = this._getAttribute(card, 'modelFilterId');
    const pageFilterId = this._getAttribute(card, 'pageFilterId');
    const hierarchyFilterId = this._getAttribute(card, 'hierarchyFilterId');
    const eventFilterId = this._getAttribute(card, 'eventFilterId');
    const taFilterIds = this._getAttribute(card, 'taFilterIds');
    if (dataSources) {
      const uniqueSourceTypes = _.uniq(dataSources.map((d) => d.type));
      const hasModelFilterChanged =
        prevCard &&
        this._getAttribute(prevCard, 'modelFilterId') !== modelFilterId;
      const hasPageFilterChanged =
        prevCard &&
        this._getAttribute(prevCard, 'pageFilterId') !== pageFilterId;
      const hasEventFilterChanged =
        prevCard &&
        this._getAttribute(prevCard, 'eventFilterId') !== eventFilterId;
      const hasHierarchyFilterChanged =
        prevCard &&
        this._getAttribute(prevCard, 'hierarchyFilterId') !== hierarchyFilterId;
      const publishFilterChange = (event) => (filter) => {
        this.publish(event, {
          noReload: true,
          ...filter,
        });
      };

      uniqueSourceTypes.forEach((type) => {
        if (type === 'cxmeasure') {
          const measureSource = _.find(dataSources, { type: 'cxmeasure' });
          if (modelFilterId) {
            this.publish('filters:details:get', {
              measurementKey: measureSource.key,
              modelFilterId: modelFilterId,
              callback: hasModelFilterChanged
                ? publishFilterChange('filters:set')
                : null,
            });
          } else if (hasModelFilterChanged) {
            this.publish('filters:reset');
          }

          if (pageFilterId) {
            this.publish('pagefilters:details:get', {
              measurementKey: measureSource.key,
              pageFilterId: pageFilterId,
              callback: hasPageFilterChanged
                ? publishFilterChange('pagefilters:set')
                : null,
            });
          } else if (hasPageFilterChanged) {
            this.publish('pagefilters:reset');
          }

          if (hierarchyFilterId) {
            this.publish('hierarchyfilters:details:get', {
              measurementKey: measureSource.key,
              filterId: hierarchyFilterId,
              callback: hasHierarchyFilterChanged
                ? () => {
                    this.publish(
                      'hierarchyfilters:measurementKey:filterId:set',
                      {
                        measurementKey: measureSource.key,
                        filterId: hierarchyFilterId,
                      }
                    );
                  }
                : null,
            });
          } else if (hasHierarchyFilterChanged) {
            this.publish('hierarchyfilters:reset');
          } else if (prevCard) {
            this.publish('hierarchyfilters:measurementKey:filterId:set', {
              measurementKey: measureSource.key,
            });
          }
        } else if (type === 'event') {
          if (eventFilterId) {
            this.publish('event-filters:details:get', {
              filterId: eventFilterId,
              callback: hasEventFilterChanged
                ? publishFilterChange('event-filters:set')
                : null,
            });
          } else if (hasEventFilterChanged) {
            this.publish('event-filters:reset');
          }
          this.publish('hierarchyfilters:currentMeasure', false);
        } else {
          this.publish('hierarchyfilters:currentMeasure', false);
        }
        if (taFilterIds && taFilterIds.length && taFilterIds[0]) {
          const dataSource = _.first(dataSources);
          const payload = { filterId: _.first(taFilterIds) };
          if (dataSource.type === Constants.DASHBOARD.sourceType.cxmeasure) {
            payload.measureId = dataSource.key;
          } else if (
            dataSource.type === Constants.DASHBOARD.sourceType.feedback
          ) {
            payload.projectId = dataSource.key;
          } else if (
            dataSource.type === Constants.DASHBOARD.sourceType.customFeed
          ) {
            payload.customFeedId = dataSource.key;
          }
          this.publish('tafilters:filter:get', {
            ...payload,
          });
        }
      });
      if (dataSources.length > 1) {
        this.publish('hierarchyfilters:currentMeasure', false);
      }
    }
  }

  _setCardData(payload) {
    const { dashboardId, cardId, data, callback } = payload;
    this.publish('app:getData', {
      key: 'dashboards',
      callback: (dashboards) => {
        const d = _.findWhere(dashboards, { dashboardId });
        const c = _.findWhere(d.cards, { cardId });
        c.data = data;
        this.publish('app:updateData', { dashboards, callback });
      },
    });
  }

  _reloadData(isInitialization) {
    if (isInitialization) {
      return;
    }
    this.publish('app:getData', {
      key: 'cardDetail',
      callback: (cardDetail) => {
        this.publish('dashboards-data:card:get', cardDetail);
      },
    });
  }

  async _adjustDateRangeForDataSource(payload, dataSources) {
    // if the date source has changed from one that used dateTime to one that
    // uses date, make sure the time parts are removed
    const adjustedPayload = _.clone(payload);
    const chartType = await this._getCurrentCardAttribute('chartType');
    const taQuestions = await this._getCurrentCardAttribute('taQuestions');
    const hasTaTimezonesFeature = featureFlags.hasFeature('ta-timezones');
    if (supportsDateTime(chartType, dataSources, taQuestions)) {
      adjustedPayload.dateRangeType = 'DATE_TIME';
    } else if (payload.dateRangeType !== 'DATE_ONLY') {
      if (payload.startDate) {
        adjustedPayload.startDate = payload.startDate.format('YYYY-MM-DD');
      }
      if (payload.endDate) {
        adjustedPayload.endDate = payload.endDate.format('YYYY-MM-DD');
      }
      adjustedPayload.dateRangeType = 'DATE_ONLY';
    }
    adjustedPayload.includeTimeZone = hasTaTimezonesFeature;
    return adjustedPayload;
  }

  async _setDateRange(payload) {
    const dataSources = await this._getCurrentCardAttribute('dataSources');
    const adjustedPayload = await this._adjustDateRangeForDataSource(
      payload,
      dataSources
    );
    const range = await this._asyncPublishAction('dates:lookup', {
      startDate: adjustedPayload.first,
      endDate: adjustedPayload.last,
      ...adjustedPayload,
    });
    if (range.c !== adjustedPayload.calendarType) {
      range.c = adjustedPayload.calendarType;
    }
    delete range.a;
    if (range.r !== 'C') {
      delete range.f;
      delete range.l;
    }
    this._updateAttributes({
      key: 'dateRange',
      value: range,
      callback: () => {
        this._updateAttributes({
          key: 'selectedDateId',
          value: payload.id || 'custom',
          callback: this._reloadData.bind(this, payload.isInitialization),
        });
      },
    });
  }

  _setModelFilter({
    noReload = false,
    modelFilterId,
    isInitialization,
    gdfContext = undefined,
  }) {
    this._updateAttributes({
      key: 'modelFilterId',
      value: modelFilterId,
      callback: !noReload
        ? this._reloadData.bind(this, isInitialization, gdfContext)
        : () => {},
    });
  }

  _setPageFilter({
    noReload = false,
    pageFilterId,
    isInitialization,
    gdfContext = undefined,
  }) {
    this._updateAttributes({
      key: 'pageFilterId',
      value: pageFilterId,
      callback: !noReload
        ? this._reloadData.bind(this, isInitialization, gdfContext)
        : () => {},
    });
  }

  _setHierarchyFilter({
    noReload = false,
    filterId,
    isInitialization,
    gdfContext = undefined,
  }) {
    this._updateAttributes({
      key: 'hierarchyFilterId',
      value: filterId,
      callback: !noReload
        ? this._reloadData.bind(this, isInitialization, gdfContext)
        : () => {},
    });
  }

  _setEventFilter({
    noReload = false,
    filterId,
    isInitialization,
    gdfContext = undefined,
  }) {
    this._updateAttributes({
      key: 'eventFilterId',
      value: filterId,
      callback: !noReload
        ? this._reloadData.bind(this, isInitialization, gdfContext)
        : () => {},
    });
  }

  _setTAFilter({
    noReload = false,
    filterId,
    isInitialization,
    gdfContext = undefined,
  }) {
    const taFilter = filterId ? [filterId] : null;
    const taFilterKey = 'taFilterIds';
    this.publish('app:getData', {
      key: 'cardDetail',
      callback: (cardDetail) => {
        if (!cardDetail) {
          return;
        }
        this._updateAttributes({
          key: taFilterKey,
          value: taFilter,
          callback: !noReload
            ? this._reloadData.bind(this, isInitialization, gdfContext)
            : () => {},
        });
      },
    });
  }

  _setBDFilter({ filters, gdfContext, noReload = false }) {
    this._updateAttributes({
      key: 'filters',
      value:
        filters &&
        filters.map((filter) => {
          return {
            type: filter.filterMetaData.type,
            key: filter.filterId,
          };
        }),
      callback: !noReload
        ? this._reloadData.bind(this, false, gdfContext)
        : () => {},
    });
  }

  _setCardDetails(payload) {
    const { card, cardDetailData, externalType, externalId, externalRouteKey } =
      payload;
    const { dashboardId, cardId } = card;

    this.publish('app:updateData', {
      cardDetail: card,
      cardDetailData: {
        ...cardDetailData,
        cardId,
      },
      callback: () => {
        if (externalType === 'MEASURE' && externalId && externalRouteKey) {
          this.changeRoute('measures.card', {
            measurementKey: externalId,
            type: externalRouteKey,
            dashboardId,
            cardId,
          });
        } else if (externalType === Constants.FILTER_TYPE_TEXT_ANALYTICS) {
          this.changeRoute('textAnalytics.card', {
            cardId,
            dashboardId,
            ...this._getTaRoutePayload(cardDetailData),
          });
        } else if (externalType === 'DATASOURCES') {
          this.changeRoute('datasources.card', {
            dataSourceType: card.dataSourceType,
            dataSourceKey: externalId,
            dashboardId,
            cardId,
            schemaId: card?.metadata?.attributes?.dataSources[0]?.schemaId,
          });
        } else {
          this.changeRoute('dashboards.card', { dashboardId, cardId });
        }
      },
    });
  }

  _setCardDetailAction(payload) {
    const { card, cardDetailData, action } = payload;
    this.publish('app:updateData', {
      cardDetail: card,
      cardDetailData: {
        ...cardDetailData,
        cardId: card.cardId,
      },
      callback: () => {
        const { cardId, dashboardId } = card;
        this._updateCardDetailActionRoute({
          cardId,
          dashboardId,
          action,
          card,
        });
      },
    });
  }

  _updateCardDetailActionRoute(payload) {
    const { cardId, dashboardId, card } = payload;
    const routeMap = {
      card: 'card',
      edit: 'editCard',
      move: 'moveCard',
      copy: 'copyCard',
      delete: 'deleteCard',
    };
    const action = payload.action || 'card';
    this.publish('route:get', {
      callback: (payload) => {
        const { route, pathIds } = payload;
        const { measures, analytics } = pathIds;
        let routeType = 'dashboards';
        let routeChangePayload = {
          dashboardId,
          cardId,
        };
        const routeParts = route.split('/');

        if (routeParts && routeParts[1] === 'text-analytics') {
          routeType = 'textAnalytics';
          routeChangePayload = Object.assign(
            routeChangePayload,
            this._getTaRoutePayload(card)
          );
        }
        if (measures && analytics) {
          routeType = 'measures';
          routeChangePayload.measurementKey = measures;
          routeChangePayload.type = analytics;
        }
        if (pathIds.datasources && card) {
          routeType = 'datasources';
          routeChangePayload.dataSourceType = card.dataSourceType;
          routeChangePayload.dataSourceKey = card.dataSourceId;
          routeChangePayload.dashboardId = card.dashboardId;
          routeChangePayload.cardId = card.cardId;
          routeChangePayload.schemaId =
            card?.metadata?.attributes?.dataSources[0]?.schemaId;
        }
        this.changeRoute(
          `${routeType}.${routeMap[action]}`,
          routeChangePayload
        );
      },
    });
  }

  _getTaRoutePayload({ metadata }) {
    const { attributes } = metadata;
    const dataSourceType = attributes.dataSources[0].type;
    let taQuestionIds;

    if (dataSourceType === DASHBOARD.sourceType.cxmeasure) {
      // HACK: attributes.taQuestions is non-standard part of the payload
      // dispatched by TA overview when navigating to the card detail page
      taQuestionIds = (attributes.taQuestions || [])
        .filter((question) => {
          return attributes?.taQuestionTags?.some(
            (tag) => tag === question.tag
          );
        })
        .map(({ id }) => id);
    } else if (dataSourceType === DASHBOARD.sourceType.feedback) {
      taQuestionIds = attributes.taQuestionIds;
    }

    return {
      dataSourceKey: attributes.dataSources[0].key,
      dataSourceType,
      dateRange: attributes.dateRange
        ? JSON.stringify(attributes.dateRange)
        : '',
      hierarchyFilterId: attributes.hierarchyFilterId || '',
      modelFilterId: attributes.modelFilterId || '',
      pageFilterId: attributes.pageFilterId || '',
      taFilterId:
        attributes.taFilterIds && attributes.taFilterIds.length
          ? attributes.taFilterIds
          : '',
      taQuestionIds,
    };
  }

  _getCardDetails(payload) {
    const {
      dashboardId,
      cardId,
      externalType,
      externalId,
      autoFetch,
      metadata,
      overrideDateRange,
      overrideDateRangeCallback,
      previousDateRange,
      previousSelectedDateId,
      dataSourceType,
      currentDashboard,
      dataSourceId,
      schemaId,
    } = payload;
    const filters = metadata?.attributes?.filters || [];
    if (isNaN(parseInt(cardId, 10))) {
      this._get({
        callback: () => {
          this._getCards({
            dashboardId,
            callback: this._initNewCard.bind(this, {
              dashboardId,
              chartType: cardId,
            }),
          });
        },
      });
      return;
    }

    const model = new DashboardCardModel(false, {
      clientId: this.getClientId(),
      dashboardId,
      cardId,
    });

    model
      .fetch({
        error: () => {
          //TODO
        },
      })
      .then(async () => {
        const card = model.toJSON();
        const chartType = this._getAttribute(card, 'chartType');
        if (
          chartType === Constants.DASHBOARD.chartTypes.external &&
          !this.hasFeature('has-iframe-card')
        ) {
          return false;
        }

        const fetchMeasures = this._checkAllDataSources(card);

        if (externalType === 'MEASURE') {
          this.publish('app:updateData', { cardDetail: card });
          this._setMeasureDashboardMetaData(
            externalId,
            [card],
            dashboardId,
            true,
            true,
            undefined,
            undefined,
            filters
          );
        } else if (fetchMeasures) {
          this.publish('app:updateData', { cardDetail: card });
          this._populateTemplate(dashboardId, [card], true);
        } else if (dataSourceType) {
          const { data } = await this.apolloClient.query({
            query: DATA_SOURCE_DASHBOARDS_CARDS,
            variables: {
              clientId: this.getClientId(),
              dashboardId,
              schemaId,
              dataSourceType,
              dataSourceId,
              cardId,
            },
          });
          let preparedCurrentDashboard;
          if (!currentDashboard) {
            const { data: dataSourceDashboards } =
              await this.apolloClient.query({
                query: DATA_SOURCE_DASHBOARDS,
                variables: {
                  clientId: this.getClientId(),
                  dataSourceType,
                  dataSourceId,
                  schemaId,
                },
              });

            preparedCurrentDashboard =
              dataSourceDashboards.dataSourceDashboards.find(
                (d) => d.dashboardId === dashboardId
              );
          }

          const gqlCardData = data.getPreparedDashboardCards[0];
          const filledCardTemplate = {
            ...gqlCardData,
            cardId,
            dataSourceType,
            dataSourceId,
            currentDashboard: currentDashboard || preparedCurrentDashboard,
            metadata: {
              ...gqlCardData.metadata,
              attributes: {
                ...gqlCardData.metadata.attributes,
                dateRange:
                  previousDateRange ||
                  card.metadata.attributes.dateRange ||
                  this.controllers.measures.baseDate,
                selectedDateId: previousSelectedDateId,
                benchmarkKeys: getDefaultBenchmarkKeys(
                  gqlCardData?.metadata?.attributes?.benchmarkType,
                  gqlCardData?.metadata?.attributes?.dataSources
                ),
                filters,
              },
            },
          };

          const datePayload = this._getDatePayloadForMeasureController(
            previousDateRange ||
              card.metadata.attributes.dateRange ||
              this.controllers.measures.baseDate
          );
          this.publish('measures:daterange:set', {
            ...datePayload,
            id: previousSelectedDateId,
          });
          this.publish('app:updateData', {
            cardDetail: filledCardTemplate,
          });
          this.publish('dashboards-data:card:get', {
            ...filledCardTemplate,
            autoFetch,
            isOnDetail: true,
          });
        } else {
          let updatedCard = card;

          // Apply dataSources from current card when it's not available in card model
          if (
            this._getAttribute(card, 'dashboardType') ===
            Constants.DASHBOARD.TYPES.TEXT_ANALYTICS
          ) {
            const currentDataSources = this._getAttribute(
              payload,
              'dataSources'
            );
            const dateRange = this._getAttribute(payload, 'dateRange');
            const selectedDateId = this._getAttribute(
              payload,
              'selectedDateId'
            );
            const hierarchyFilterId = this._getAttribute(
              payload,
              'hierarchyFilterId'
            );
            const modelFilterId = this._getAttribute(payload, 'modelFilterId');
            const taFilterIds = this._getAttribute(payload, 'taFilterIds');
            const taQuestionIds = this._getAttribute(payload, 'taQuestionIds');
            const taQuestionTags = this._getAttribute(
              payload,
              'taQuestionTags'
            );

            updatedCard = {
              ...updatedCard,
              metadata: {
                ...updatedCard.metadata,
                attributes: {
                  ...updatedCard.metadata.attributes,
                  dataSources: currentDataSources,
                  dateRange,
                  selectedDateId,
                  hierarchyFilterId,
                  modelFilterId,
                  taFilterIds,
                  taQuestionIds,
                  taQuestionTags,
                },
              },
            };

            if (
              currentDataSources[0].type ===
              Constants.DASHBOARD.sourceType.customFeed
            ) {
              updatedCard.metadata.attributes.customFeedCriteriaList = [
                // customFeedId must be a number
                { customFeedId: parseInt(currentDataSources[0].key, 10) },
              ];
            }
          } else if (overrideDateRange) {
            const { dateRange, selectedDateId } = card.metadata.attributes;

            // when overriding the date range send back what the original date
            // range was; this is so global date state can have the right info
            if (overrideDateRangeCallback) {
              overrideDateRangeCallback(dateRange, selectedDateId);
            }

            updatedCard = {
              ...updatedCard,
              metadata: {
                ...updatedCard.metadata,
                attributes: {
                  ...updatedCard.metadata.attributes,
                  dateRange: overrideDateRange,
                },
              },
            };
          }

          this.publish('app:updateData', { cardDetail: updatedCard });
          this.publish('dashboards-data:card:get', {
            ...updatedCard,
            autoFetch,
            isOnDetail: true,
          });

          // _getCardMetaData is intended to reset filters to the saved state of
          // the card but TA cards should use the filter state applied on the
          // dashboard.
          // We check for externalType here to make sure saved filters are not
          // applied for TA cards.
          if (externalType !== DASHBOARD.EXTERNAL_TYPES.TEXT_ANALYTICS) {
            this._getCardMetaData(card, payload);
          }
          this.publish('cardfilters-subnav:filter:reload');
        }
      });
  }

  _getDatePayloadForMeasureController(previousDateRange) {
    const start_date = new moment(previousDateRange.f),
      end_date = new moment(previousDateRange.l),
      number = previousDateRange.n,
      dateRangeType = previousDateRange.t,
      calendarType = previousDateRange.c,
      rangeType = previousDateRange.r;

    const selected = this._getSelectedDateIndex(
      dateRangeType,
      rangeType,
      number
    );
    return {
      start_date,
      end_date,
      number,
      dateRangeType,
      calendarType,
      rangeType,
      selected,
    };
  }

  _addDashboard(newDash) {
    this._get({
      callback: () => {
        window.dispatchEvent(new CustomEvent('xm-navigator:fetch-trigger'));
        this.changeRoute('dashboards.summary', {
          dashboardId: newDash.dashboardId,
        });
      },
    });
  }

  _saveDashboard(payload, sortOrder) {
    const { name, callback, onError } = payload;
    const cards = payload.cards || [];
    const record = {
      cards,
      name,
      type: 'PERSONAL',
      hidden: false,
      sortOrder: sortOrder,
      metadata: {
        attributes: {},
      },
    };
    const options = {
      clientId: this.getClientId(),
    };
    const model = new DashboardModel(record, options);
    model
      .save(null, {
        error: onError,
      })
      .then(() => {
        const data = model.toJSON();
        if (callback) {
          callback(data);
        }
        this._addDashboard(data);
      });
  }

  _post(payload) {
    this.publish('app:getData', {
      key: 'dashboards',
      callback: (dashboards) => {
        this._saveDashboard(
          {
            ...payload,
            callback: (newDash) => {
              if (payload.callback) {
                payload.callback(newDash);
              }
            },
          },
          dashboards.length + 1
        );
      },
    });
  }

  _copyDashboard(payload) {
    this.publish('app:getData', {
      key: 'dashboards',
      callback: (dashboards) => {
        const { dashboardId, name, callback } = payload;
        const model = new DashboardCopyModel(false, {
          dashboardId,
          clientId: this.getClientId(),
        });
        model
          .save({
            error: () => {},
          })
          .then(() => {
            const newDash = {
              ...model.toJSON(),
              name: name,
              sortOrder: dashboards.length + 1,
            };
            this._put(
              {
                ...newDash,
                callback: () => {
                  callback(newDash);
                  this._addDashboard(newDash);
                },
              },
              true
            );
          });
      },
    });
  }

  _getDefaultAttributes(chartType) {
    if (chartType === Constants.DASHBOARD.chartTypes.priorityIndex) {
      return {
        dataSources: [],
        surveyTypeIcons: true,
      };
    } else if (chartType === Constants.DASHBOARD.chartTypes.priorityMap) {
      if (this.hasFeature('big-data-reporting-priority-map-multi')) {
        return { dataSources: [] };
      }
    } else if (
      chartType === Constants.DASHBOARD.chartTypes.verticalBar ||
      chartType === Constants.DASHBOARD.chartTypes.horizontalBar
    ) {
      return {
        showDataLabels: true,
        stackingType: 'normal',
        sortOrder: 'default',
      };
    } else if (chartType === Constants.DASHBOARD.chartTypes.trendLine) {
      return {
        calloutLimits: 'values',
        stackingType: 'normal',
      };
    } else if (chartType === Constants.DASHBOARD.chartTypes.donut) {
      return {
        showDataLabels: 'both',
        sortOrder: 'hl',
        centerMetricDisplay: true,
      };
    } else if (chartType === Constants.DASHBOARD.chartTypes.voc) {
      return {
        textAnalyticsInfo: 'sentiment',
        replayIndicator: true,
      };
    } else if (chartType === Constants.DASHBOARD.chartTypes.external) {
      return {
        showDataSources: false,
        contentType: 'iframe',
        height: 500,
      };
    } else if (chartType === Constants.DASHBOARD.chartTypes.kpi) {
      return {
        delta: 'true',
        changePeriod: ['lastperiod'],
        sparkline: Constants.DASHBOARD.kpi.sparkline.bar,
      };
    } else if (chartType === Constants.DASHBOARD.chartTypes.scatterPlot) {
      return {
        metricDisplay: 'minMax',
      };
    }
    return {};
  }

  _initNewCard(payload) {
    const { dashboardId, chartType, cardSize, sortOrder, callback } = payload;
    const { external, priorityIndex } = Constants.DASHBOARD.chartTypes;
    this.publish('app:getData', {
      key: 'dashboardCards',
      callback: async (dashboardCards) => {
        dashboardCards = dashboardCards || {};
        const notExternalChart = chartType !== external;
        const defaultDateId =
          chartType === priorityIndex ? 'lastMonth' : 'monthToDate';
        const selectedDateId = notExternalChart
          ? { selectedDateId: defaultDateId }
          : {};
        const initialChartName =
          this.getString('general.untitled') +
          ' ' +
          this.getString(`dashboards.chartType.${chartType}`);
        const card = new DashboardCardModel({
          cardId: 0,
          sortOrder,
          clientId: this.getClientId(),
          dashboardId: dashboardId,
          name: initialChartName,
          metadata: {
            attributes: {
              chartType: chartType,
              cardSize: cardSize || 'medium',
              legendPosition: 'top',
              ...selectedDateId,
              ...this._getDefaultAttributes(chartType),
            },
          },
        });
        await this._asyncPublishAction('app:updateData', {
          cardDetail: card.toJSON(),
          cardDetailData: false,
        });
        await this._unsetAddCard();
        await this._resetHierarchyFilters();
        const number = chartType === 'priorityIndex' ? 1 : 0;
        if (notExternalChart) {
          this._setDateRange({
            calendarType: 'G',
            number: number,
            rangeType: 'M',
            selected: 3,
            id: defaultDateId,
            isInitialization: true,
          });
        }
        if (callback) {
          callback({ selectedDateId });
        }
      },
    });
  }

  async _unsetAddCard() {
    return this._asyncPublishAction('app:updateState', { addCard: false });
  }

  async _resetHierarchyFilters() {
    return this._asyncPublishAction('hierarchyfilters:reset');
  }

  async _asyncPublishAction(action, payload = {}) {
    return new Promise((resolve) => {
      this.publish(action, { ...payload, callback: resolve });
    });
  }

  async _getCurrentCardAttribute(key, callback) {
    return new Promise((resolve) => {
      this.publish('app:getData', {
        key: 'cardDetail',
        callback: (card) => {
          const { metadata } = card || {};
          const { attributes } = metadata || {};
          resolve(attributes && attributes[key]);
        },
      });
    });
  }

  _updateAttributes(payload) {
    const { key, value, changedAttributes, callback } = payload;
    const toUpdate = changedAttributes || { [key]: value };

    this.publish('app:getData', {
      key: 'cardDetail',
      callback: (card) => {
        const { metadata } = card;
        const updatedCard = shallowClone(card);
        updatedCard.metadata = shallowClone(metadata);
        updatedCard.metadata.attributes = shallowClone(metadata.attributes);
        let hasUpdates = false;
        Object.keys(toUpdate).forEach((key) => {
          if (metadata.attributes[key] !== toUpdate[key]) {
            if (toUpdate[key] === undefined) {
              delete updatedCard.metadata.attributes[key];
            } else {
              updatedCard.metadata.attributes[key] = toUpdate[key];
            }
            hasUpdates = true;
          }
        });

        if (hasUpdates) {
          this.publish('app:updateData', {
            cardDetail: updatedCard,
            callback,
          });
        } else if (callback) {
          callback();
        }
      },
    });
  }

  async _updateOEFilters(payload) {
    const taQuestions = payload?.taQuestions
      ? payload.taQuestions.map((q) => ({
          id: q.value || q.id,
          tag: q.tag,
          dataSourceKey: q.dataSourceKey,
        }))
      : [];
    this._updateAttributes({
      key: 'taQuestionIds',
      value: payload?.taQuestions
        ? payload.taQuestions.map((q) => q.value || q.id)
        : [],
      callback: () => {
        this._updateAttributes({
          key: 'taQuestions',
          value: taQuestions,
          callback: () => {
            this._updateAttributes({
              key: 'taQuestionTags',
              callback: this._reloadData.bind(this, false, payload?.gdfContext),
            });
          },
        });
      },
    });
  }

  _updateTAFilters(payload) {
    this.publish('app:getData', {
      key: 'taDashboards',
      callback: (taDashboards) => {
        this.publish('app:getData', {
          key: 'dashboardCards',
          callback: (dashboardCards) => {
            if (!dashboardCards) {
              return;
            }

            if (taDashboards && taDashboards.length > 0) {
              const currentDashboardId = taDashboards[0].dashboardId;
              const taDashboardCards =
                dashboardCards && dashboardCards[currentDashboardId];

              if (taDashboardCards) {
                const newCards = taDashboardCards.map((card) => ({
                  ...card,
                  metadata: {
                    ...card.metadata,
                    attributes: {
                      ...card.metadata.attributes,
                      ...payload,
                    },
                  },
                }));

                this._updateDashboardCards(currentDashboardId, newCards, () => {
                  // Need to fetch data for newCards without fetching metadata
                  this.publish('dashboards-data:dashboard:get', {
                    cards: newCards,
                  });
                });
              }
            }
          },
        });
      },
    });
  }

  _getSelectedDateIndex(dateType, rangeType, number) {
    const datePickerButtons = getDatePickerButtons(dateType);
    return (
      datePickerButtons.findIndex(
        (btn) => btn.rangeType === rangeType && btn.number === number
      ) || 0
    );
  }

  _selectDataSourceTypeBySchema(schema) {
    switch (schema) {
      case Constants.SCHEMAS.INTERACTION_ANALYTICS:
        return Constants.DATASOURCE_TYPES.INTERACTION_ANALYTICS;
      case Constants.SCHEMAS.ADHOC:
        return Constants.DATASOURCE_TYPES.ADHOC;
      default:
        return Constants.DATASOURCE_TYPES.PREDICTIVE;
    }
  }

  _drillIn(payload) {
    let additionalArgs = {};
    const dateRangeType = payload.dateRangeType
      ? payload.dateRangeType
      : 'DATE_ONLY';
    if (payload.dateRange) {
      // If we pass in a dateRange object this will override the custom range that is otherwise set
      const rangeType = Constants.dateRangeTypes[payload.dateRange.r];
      additionalArgs = {
        calendarType: payload.dateRange.c,
        dateRangeType: payload.dateRange.t,
        start_date: payload.dateRange.f,
        end_date: payload.dateRange.l,
        number: payload.dateRange.n,
        rangeType,
        selected: this._getSelectedDateIndex(
          payload.dateRange.t,
          rangeType,
          payload.dateRange.n
        ),
      };
    }
    const drillSource = payload.isOnCard ? 'dashboard' : 'dashboard:detail';
    this.logEvent(`${drillSource}:drill:${payload.route}`, payload.chartType);

    this.publish('measures:daterange:set', {
      calendarType: payload.calendarType,
      start_date: payload.startDate,
      end_date: payload.endDate,
      dateRangeType: dateRangeType,
      ...additionalArgs,
      callback: () => {
        const {
          questionKey,
          answerKey,
          answers,
          answerType,
          openInNewWindow,
          callback = () => {},
          modelFilterId,
          pageFilterId,
          hierarchyFilterId,
          taFilter,
          filterId,
          questionIds,
          chartType,
        } = payload;

        const isTADrillDown =
          chartType === DASHBOARD.chartTypes.wordCloud &&
          ['KF', 'CC'].includes(payload.metricType);

        let drillInRoute;
        let measureIds;
        let feedbackIds;
        let customFeedIds;
        if (isTADrillDown) {
          switch (payload.dataSourceType) {
            case 'cxmeasure':
              measureIds = payload.measurementKey;
              break;
            case 'feedback':
              feedbackIds = payload.measurementKey;
              break;
            case 'customFeed':
              customFeedIds = payload.measurementKey;
              break;
            default:
              break;
          }
          const stringQuestionIds = questionIds
            ?.map((id) => `"${id}"`)
            .join(',');
          drillInRoute = GenerateRoute('textAnalytics.overviewDashboard', {
            clientId: this.getClientId(),
            measureIds,
            feedbackIds,
            customFeedIds,
            questionIds: stringQuestionIds || '',
          });
        } else if (
          payload.dataSourceType === Constants.DASHBOARD.sourceType.definition
        ) {
          drillInRoute = GenerateRoute('datasources.view', {
            dataSourceType: this._selectDataSourceTypeBySchema(payload.schema),
            dataSourceKey: payload.measurementKey,
            dataSourceView: payload.route,
            backTo: '',
          });
        } else {
          drillInRoute = GenerateRoute('measures.dashboard', {
            measurementKey: payload.measurementKey,
            type: payload.route,
          });
        }
        if (openInNewWindow) {
          window.open('/client' + drillInRoute, '_blank');
          callback();
        } else {
          const params = new URLSearchParams();
          if (filterId) {
            // unset any previous Data Sources screen filters before drilldown
            localStorage.setItem('dataSourcesSavedFilters', null);
            params.set('filterId', filterId);
          }
          params.set(
            'backTo',
            `${window.location.pathname.replace('/client', '')}`
          );
          const drillDownFromDatasources = drillInRoute.includes('datasources');
          const operator =
            isTADrillDown || drillDownFromDatasources ? '&' : '?';
          this.publish('route:change', {
            route: `${drillInRoute}${operator}${params.toString()}`,
          });
        }

        let newTerms = false;
        if (questionKey && (answerKey || answers)) {
          const filterAnswer =
            answerType === 'PVG'
              ? this._buildTextFilter(payload)
              : this._buildAnswerFilter(payload);
          newTerms = {
            question: {
              label: payload.questionLabel,
              questionKey,
            },
            ...filterAnswer,
          };
        }
        if (modelFilterId) {
          if (newTerms === false) {
            this.publish('filters:set', {
              retryIfUninitialized: true,
              modelFilterId: modelFilterId,
              measurementKey: payload.measurementKey,
              isInitialization: true,
            });
          } else {
            this.publish('filters:extend', {
              measurementKey: payload.measurementKey,
              modelFilterId: modelFilterId,
              newTerms: [newTerms],
            });
          }
        } else if (newTerms !== false && !isNaN(payload.measurementKey)) {
          this.publish('filters:chip:add', {
            measurementKey: payload.measurementKey,
            ...newTerms,
          });
        } else {
          this.publish('filters:reset');
        }

        if (pageFilterId) {
          this.publish('pagefilters:set', {
            retryIfUninitialized: true,
            pageFilterId: pageFilterId,
            measurementKey: payload.measurementKey,
            isInitialization: true,
          });
        } else {
          this.publish('pagefilters:reset');
        }

        if (isTADrillDown && hierarchyFilterId) {
          this.publish('taoverview:hierarchyid:set', hierarchyFilterId);
        } else if (hierarchyFilterId) {
          this.publish('hierarchyfilters:measurementKey:filterId:set', {
            measurementKey: payload.measurementKey,
            filterId: hierarchyFilterId,
          });
        } else {
          this.publish('hierarchyfilters:reset');
        }

        if (taFilter) {
          taFilter.forEach((f) => {
            const clonedFilter = cloneDeep(f);
            clonedFilter.deleted = false;
            clonedFilter.filterId = null;
            clonedFilter.measureId = measureIds;
            clonedFilter.projectId = feedbackIds;
            clonedFilter.customFeedId = customFeedIds;
            const filter = new TAFilterModel(clonedFilter);
            filter.save().then(() => {
              const filterData = filter.toJSON();
              this.publish('tafilters:filter:data', filterData);
              this.publish('tafilters:filter:change', filterData);
              this.publish('taFilters:filter:update', filterData);
            });
          });
        }
        callback();
      },
    });
  }

  _buildAnswerFilter(payload) {
    const { questionKey, answerKey, answers, answerLabel } = payload;
    return {
      filterType: Constants.FILTER_TYPE_QUESTION,
      respType: Constants.QT_DROPDOWN, //TODO - This is unknown at this point
      selectedItems: [
        {
          label: answerLabel,
          value: answers
            ? answers.map((answer) => ({
                answerKey: answer.key,
                questionKey,
              }))
            : {
                answerKey,
                questionKey,
              },
        },
      ],
    };
  }

  _buildTextFilter(payload) {
    return {
      filterType: Constants.FILTER_TYPE_TEXT,
      respType: Constants.QT_TEXT,
      operator: Constants.OPERATOR_TEXT_EQUALS,
      value: payload.answerLabel,
    };
  }

  _setCardSize(payload) {
    const { card, size } = payload;

    const alteredCardPayload = {
      ...card,
      metadata: {
        ...card.metadata,
        attributes: { ...card.metadata.attributes, cardSize: size },
      },
    };

    alteredCardPayload.callback = (updatedCard) => {
      this._updateDashboardCard(updatedCard.dashboardId, alteredCardPayload);
    };

    this._saveCard(alteredCardPayload, true);
  }

  _saveCards(payload) {
    const { cards, dashboardId } = payload;
    const model = new DashboardCardsCollection(cards, {
      clientId: this.getClientId(),
      dashboardId,
    });
    model.save({
      success: (data) => {
        const sortedCards = _.sortBy(data, ['sortOrder']);
        this._updateDashboardCards(dashboardId, sortedCards);
      },
      error: () => {
        // Todo
      },
    });
  }

  _saveCard(payload, doNotRefetch) {
    const { cardId, dashboardId, callback, onError, name, lastUpdatedDate } =
      payload;

    if (cardId === 0) {
      delete payload.cardId;
    }

    const record = addDimensionForKPI(payload);
    const options = {
      clientId: this.getClientId(),
      dashboardId,
      cardId: payload.cardId,
    };

    const metric = record?.metadata?.attributes?.metrics?.[0];
    const metricsName = metric?.label || Constants.METRICS_LABELS[metric?.type];
    const chartType = record?.metadata?.attributes.chartType;
    const isExistingCard = lastUpdatedDate !== null;
    const metricsNames = Object.values(Constants.METRICS_LABELS);

    if (chartType === 'kpi') {
      const initialChartName =
        this.getString('general.untitled') +
        ' ' +
        this.getString(`dashboards.chartType.kpi`);
      if (
        name === initialChartName ||
        (isExistingCard && name !== metricsName && metricsNames.includes(name))
      ) {
        record.name = metricsName;
      }
    } else if (!name || name.length === 0) {
      record.name = this.getString('general.untitled') + ' Card';
    }

    const model = new DashboardCardModel(record, options);
    model
      .save(null, {
        error: onError,
        doNotAbort: true,
      })
      .then(() => {
        if (!doNotRefetch) {
          if (!record.cardId) {
            this._getCards({
              dashboardId,
              callback: (cards) => {
                this._saveCardSortOrders({ dashboardId });
                this._updateDashboardCards(
                  dashboardId,
                  cards.map((card, index) => {
                    return {
                      ...card,
                      sortOrder: index,
                    };
                  })
                );
                if (callback) {
                  callback(model.toJSON());
                }
              },
            });
          } else {
            this._updateDashboardCards(dashboardId, false, () => {
              if (callback) {
                callback(model.toJSON());
              }
            });
          }
        } else if (callback) {
          callback(model.toJSON());
        }
      });
  }

  _deleteCard(payload) {
    const { cardId, dashboardId, doNotAbort, redirectRoute } = payload;
    const model = new DashboardCardModel(
      {
        cardId: cardId,
      },
      {
        clientId: this.getClientId(),
        dashboardId,
        cardId,
      }
    );

    model.destroy({
      success: (r) => {
        this._getCards({ dashboardId });
        if (payload.callback) {
          payload.callback();
        }
        if (redirectRoute) {
          this.changeRoute('dashboards.summary', { dashboardId });
          this.publish('app:messageFlash', {
            messageText: this.getString('dashboards.snackbar.deleteCard'),
            messageType: 'info',
          });
        }
      },
      // TODO
      // error: (r) => {},
      doNotAbort,
    });
  }

  _postCard(payload) {
    this._saveCard(payload);
  }

  _copyCard(payload) {
    const {
      dashboardId,
      cardId,
      name,
      metadata,
      action,
      destinationDashboard,
      globalDateContext,
      callback,
    } = payload;
    const model = new DashboardCardCopyModel(false, {
      dashboardId,
      cardId,
      destinationDashboard,
      clientId: this.getClientId(),
    });
    model
      .save(null, {
        error: (err, response) => {
          // no cleaner way of doing this at present
          const isAtLimit = response?.responseJSON?.message?.startsWith(
            'Could not copy card as there is 50 or more cards'
          );
          const messageText = isAtLimit
            ? this.getString('dashboards.copyLimit')
            : this.getString('general.errors.default');
          const messageType = isAtLimit ? 'info' : 'error';
          this.publish('app:messageFlash', {
            messageText,
            messageType,
          });
          if (callback) {
            callback(err);
          }
        },
      })
      .then((card) => {
        card.name = name;
        if (metadata && metadata.attributes) {
          const clonedMetaData = cloneDeep(metadata);
          const { hierarchyFilterId, modelFilterId, pageFilterId, filters } =
            card.metadata.attributes;
          if (hierarchyFilterId) {
            clonedMetaData.attributes.hierarchyFilterId = hierarchyFilterId;
          }
          if (modelFilterId) {
            clonedMetaData.attributes.modelFilterId = modelFilterId;
          }
          if (pageFilterId) {
            clonedMetaData.attributes.pageFilterId = pageFilterId;
          }
          if (filters && filters.length > 0) {
            clonedMetaData.attributes.filters = filters;
          }
          card.metadata = clonedMetaData;
        }

        if (
          globalDateContext?.isEnabled &&
          globalDateContext.dateRange &&
          card.metadata.attributes.dateRange
        ) {
          card.metadata.attributes.dateRange = {
            ...globalDateContext.dateRange,
          };
          card.metadata.attributes.selectedDateId =
            globalDateContext.dateRange.selectedDateId;
        }

        if (card.metadata && card.metadata.attributes) {
          //Copied cards should not have any data source lock restrictions.
          card.metadata.attributes.datasourceLocked = false;
        }
        card.callback = (c) => {
          if (action === 'move') {
            this._deleteCard({
              dashboardId,
              cardId,
              doNotAbort: true,
              redirectRoute: false,
            });
          }

          this.changeRoute('dashboards.summary', {
            dashboardId: destinationDashboard,
          });

          const string =
            action === 'move'
              ? 'dashboards.snackbar.moveCard'
              : 'dashboards.snackbar.copyCard';
          this.publish('app:messageFlash', {
            messageText: this.getString(string),
            messageType: 'info',
          });
        };
        this._saveCard(card);
      });
  }

  _changeDashboardOwner(payload) {
    const { dashboardId, currentOwnerId, changeOwnerId, callback } = payload;
    const model = new DashboardModel(false, {
      clientId: this.getClientId(),
      id: dashboardId,
      changeOwnerId: changeOwnerId,
    });

    // Make current owner a Write Share
    const sharePayload = {
      accessType: 'WRITE',
      shareType: 'USER',
      userId: currentOwnerId,
      dashboardId,
      callback: (writeShare) => {
        model.save({}).then((changedOwner) => {
          if (callback) {
            callback();
          }
          this._get({});
          this._fetchDashboardsShares([{ dashboardId }]);
        });
      },
    };
    this._share(sharePayload);
  }

  _addOpenEnds(payload) {
    const { taQuestions, cards } = payload;
    const questionIds =
      taQuestions && taQuestions.length ? taQuestions.map((q) => q.id) : [];
    if (cards && cards.length) {
      cards.forEach((card) => {
        const attributes = card.metadata.attributes;
        if (attributes.dataSources && attributes.dataSources.length) {
          switch (attributes.dataSources[0].type) {
            case Constants.DASHBOARD.sourceType.cxmeasure:
              attributes.taQuestionTags =
                !attributes.taQuestionTags ||
                attributes.taQuestionTags.length === 0
                  ? questionIds
                  : attributes.taQuestionTags;
              break;
            case Constants.DASHBOARD.sourceType.feedback:
              attributes.taQuestionIds =
                !attributes.taQuestionIds ||
                attributes.taQuestionIds.length === 0
                  ? questionIds
                  : attributes.taQuestionIds;
              break;

            case Constants.DASHBOARD.sourceType.customFeed:
              attributes.customFeedCriteriaList = [
                { customFeedId: attributes.dataSources[0].key },
              ];
          }
        }
      });
    }
    return cards;
  }

  _superLatentsForCards(callback) {
    this.publish('app:getData', {
      key: 'superLatents',
      callback: (superLatents) => {
        if (superLatents) {
          callback(superLatents);
        } else {
          const benchmarkSuperLatentModel = new SuperLatentsModel();
          benchmarkSuperLatentModel
            .fetch({
              error: () => {},
            })
            .then(() => {
              superLatents = benchmarkSuperLatentModel.toJSON();
              this.publish('app:updateData', { superLatents });
              callback(superLatents);
            });
        }
      },
    });
  }

  _matchSuperLatent(superLatents, legacyId, tag) {
    if (!superLatents) {
      return legacyId;
    }
    if (legacyId) {
      const match = Object.values(superLatents).find((sl) => {
        return sl.legacyId === legacyId;
      });
      if (match) {
        return match.tag;
      }
    }
    if (tag) {
      const match = Object.values(superLatents).find((sl) =>
        sl.associatedLatents?.includes(tag)
      );
      if (match) return match.tag;
    }
    return legacyId;
  }
}

export default DashboardsController;

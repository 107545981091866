import Backbone from 'backbone';
import _ from 'underscore';
import { merge } from 'lodash';
import store from '../store';
import $ from 'jquery';
import constants from '../constants';
import dispatcher from '../dispatcher';

/**
 * Changes the default sync behavior of backbone.
 */
//  Extend backbone sync to use our methods
Backbone.sync = function (method, model, options) {
  // see if this model is only being saved to local storage
  var local = _.result(this, 'url') === 'local',
    storagekey = _.result(this, 'storageKey'),
    cacheMS = this.cacheMS,
    result = null,
    deferred = new $.Deferred();
  const loggedInAs = store.get('xImpersonatedUserId');
  // Forcibly expire the session cookie before making a request. This interferes with the auth stuff and
  // if we don't do this, we sometimes get 500's.
  document.cookie =
    'JSESSIONID=;secure=true;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/services';

  if (model.headers) {
    _.extend(options, {
      headers: model.headers,
    });
  }
  if (!!loggedInAs && !model.skipLoggedInAs) {
    _.extend(options, {
      headers: { 'x-impersonated-userid': loggedInAs.userId },
    });
  }
  // This function performs either the oAuth request (if its present), or the regular unsecured equivalent.
  var apiRequest = function (ctx, options) {
    var url = typeof ctx.url === 'function' ? ctx.url(method) : ctx.url,
      oauth = Backbone.sync.oauth,
      noOauth =
        model.noOauth || (options.no_oauth && options.no_oauth === true),
      gateways = constants.SERVICE_GATEWAY,
      urlRoot =
        model.noUrlRoot || options.no_urlRoot === true ? '' : gateways.default,
      serviceId = ctx.serviceId,
      ref;

    // If there's a serviceId defined on the model, with a gateway
    // defined in constants, use that as the urlRoot
    if (!options.no_urlRoot && serviceId && gateways[serviceId]) {
      urlRoot = gateways[serviceId];
    }

    // Is there an existing request?
    if (model._ajaxRef) {
      model._ajaxRef.abort();
    }

    if (oauth && !noOauth) {
      ref = oauth.apiRequest(urlRoot + url, options);
    } else {
      ref = $.ajax(urlRoot + url, options);
    }

    // Put a reference on the model itself
    model._ajaxRef = ref;

    return ref;
  };

  var fetchFromStore = function (storagekey) {
    // Cacheing is enabled here

    // Check to see if one exists
    var oldcache = store.get(storagekey);
    if (oldcache) {
      oldcache = JSON.parse(oldcache);
      if (new Date().getTime() < oldcache.expires) {
        return oldcache.data;
      } else {
        store.remove(storagekey);
      }
    }
    return null;
  };

  if (!local && !options.error) {
    options.error = _.bind(function (res) {
      // Dispatch and event to the app to handle this response
      dispatcher.trigger('app:responseStatus', res);
    }, this);
  }

  // Handle the different methods
  switch (method) {
    case 'create':
      model.trigger('create');

      options.type = 'POST';
      options.data = JSON.stringify(options.attrs || model.toJSON());

      if (local) {
        // create locally and invoke success/error callbacks as appropriate
        if (cacheMS > 0 && storagekey) {
          var date = new Date();
          if (this.dailyCache) {
            date.setHours(0, 0, 0, 0);
          }
          result = store.set(
            storagekey,
            JSON.stringify({
              data: model.toJSON(),
              expires: date.getTime() + this.cacheMS,
            })
          );
        }

        // will be model if worked, need to work up viable test for error
        if (result) {
          options.success.call(this, JSON.parse(result).data);
          deferred.resolve();
          return deferred;
        }
      } else {
        return apiRequest(this, options);
      }
      break;
    case 'update':
      model.trigger('update');
      options.type = 'PUT';
      options.data = JSON.stringify(options.attrs || model.toJSON());

      if (local) {
        // create locally and invoke success/error callbacks as appropriate
        if (cacheMS > 0 && storagekey) {
          result = store.set(
            storagekey,
            JSON.stringify({
              data: model.toJSON(),
              expires: new Date().getTime() + this.cacheMS,
            })
          );
        }

        // will be model if worked, need to work up viable test for error
        if (result) {
          options.success.call(this, JSON.parse(result).data);
          deferred.resolve();
          return deferred;
        }
      } else {
        return apiRequest(this, options);
      }
      break;
    case 'delete':
      model.trigger('delete');

      // Set the method to DELETE
      options.type = 'DELETE';

      if (local) {
        // create locally and invoke success/error callbacks as appropriate
        if (cacheMS > 0 && storagekey) {
          result = store.remove(storagekey);

          // will be undefined, regardless of whether storagekey was there, or not, it seems
          options.success.call(this, result);
          deferred.resolve();
          return deferred;
        }
      } else if (!window.JSTestHost) {
        // Don't do it for test enviroment
        return apiRequest(this, options);
      }

      break;

    case 'patch':
      model.trigger('patch');
      options.type = 'PATCH';
      options.data = JSON.stringify(options.attrs || model.toJSON());

      if (local) {
        // create locally and invoke success/error callbacks as appropriate
        if (cacheMS > 0 && storagekey) {
          result = store.set(
            storagekey,
            JSON.stringify({
              data: model.toJSON(),
              expires: new Date().getTime() + this.cacheMS,
            })
          );
        }

        // will be model if worked, need to work up viable test for error
        if (result) {
          options.success.call(this, JSON.parse(result).data);
          deferred.resolve();
          return deferred;
        }
      } else {
        return apiRequest(this, options);
      }
      break;

    case 'read':
      model.trigger('read');

      if (local) {
        // create locally and invoke success/error callbacks as appropriate
        if (cacheMS > 0 && storagekey) {
          result = fetchFromStore(storagekey);
          if (result) {
            options.success.call(this, result);
            deferred.resolve();
            return deferred;
          }
        }
      } else {
        // Model#url is *supposed* to be a function (as defined in the docs), but
        // we sometimes define it as a property. Account for both cases.
        var url =
          typeof this.url === 'function'
            ? this.url(method)
            : this.url.toString();
        // The storage key
        storagekey = 'data_' + url.replace(/[^0-9a-zA-Z]/gi, '');
        if (typeof this.urlparams !== 'undefined') {
          storagekey += JSON.stringify(this.urlparams).replace(
            /[^0-9a-zA-Z]/gi,
            ''
          );
          if (typeof options.data === 'undefined') {
            options.data = {};
          }
          options.data = merge({}, options.data, this.urlparams);
        }

        if (typeof model.cacheMS !== 'undefined') {
          // Cacheing is enabled here

          // Check to see if one exists
          var oldcache = fetchFromStore(storagekey);

          // if we've got a valid cached object, fire success
          if (oldcache) {
            if (options.success) {
              setTimeout(
                _.bind(function () {
                  options.success.call(this, oldcache);
                  deferred.resolve();
                }, this),
                1
              );
            }
            return deferred;
          }

          // Nothing in cache, start working on getting the data for realz
          var cachems = model.cacheMS;

          // Overwrite the options success callback
          options.success = _.bind(
            function (res) {
              var savedata = {
                data: res,
                expires: new Date().getTime() + this.cacheMS,
              };
              if (cachems > 0) {
                store.set(storagekey, JSON.stringify(savedata));
              }
              if (this.oldsuccess) {
                this.oldsuccess.call(this, res);
              }
            },
            {
              oldsuccess: options.success,
              cacheMS: cachems,
            }
          );
        }

        model.trigger('request:start');

        // Don't do it for test enviroment
        if (!window.JSTestHost) {
          // We're making an actual request

          // Fire the global event
          $(window).trigger('ajaxRequestBegin');

          // Overwrite the options success callback
          options.complete = _.bind(
            function (res) {
              // If we were already binding to success, preserve that behavior
              if (this.oldsuccess) {
                this.oldsuccess.call(this, res);
              }

              // Fire the global event
              $(window).trigger('ajaxRequestEnd');
            },
            {
              oldsuccess: options.complete,
            }
          );

          // Initiate the actual AJAX request using the oauth lib
          return apiRequest(this, options);
        }
      }
      break;

    default:
      break;
  }
};

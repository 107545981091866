import Backbone from 'backbone';
import { merge } from 'lodash';
import deparam from 'core/utils/deparam';
import store from 'core/store';

//  Define Async Router
var Router = Backbone.Router.extend({
  routes: {
    '': 'triggerDefaultRoute',
    '*stuff': 'triggerRouteChange',
  },

  previousRoute: [],

  triggerDefaultRoute: function () {
    var tokenSecret = store.get('tokenSecret');
    //this.navigate("dashboards", {trigger: true});
    if (tokenSecret) {
      const inInPageReporting = self === top;
      const defaultRoute = inInPageReporting
        ? 'dashboards'
        : 'feedback-admin/summary';

      this.navigate(defaultRoute, {
        trigger: true,
      });
    } else {
      this.navigate('login', {
        trigger: true,
      });
    }
  },

  /**
   * Notify page components of a route change
   * @param a
   * @param b
   */
  triggerRouteChange: function (module, args) {
    var previousRoute = this.previousRoute.pop();

    this.previousRoute.push(module);

    this.trigger('route:change', {
      route: module,
      params: deparam(args),
      previousRoute:
        this.previousRoute.length > 1 ? previousRoute : this.previousRoute[0],
    });

    this.previousRoute.push(module);
  },

  // Store original base routes
  setBaseRoutes: function () {
    this._baseRoutes = merge([], Backbone.history.handlers);
  },

  // Restore original base routes
  deroutify: function () {
    Backbone.history.handlers = merge([], this._baseRoutes);
  },

  navigate: function (fragment, opts) {
    var options = opts || {};

    Backbone.Router.prototype.navigate.call(this, fragment, options);
    if (options.notracking === undefined && fragment !== 'dashboards') {
      this.trigger('track:pageview', Backbone.history.root + fragment);
    }
  },
});

export default Router;

import _ from 'underscore';
import Constants from 'core/constants';
import Permissions from 'core/permissions';
import BaseController from 'controllers/base';
import MeasuresCollection from 'collections/clients/measures';
import MeasuresModel from 'models/clients/measure';
import LatentsCollection from 'collections/clients/measures/latents';
import CommentQuestionsCollection from 'collections/clients/measures/commentquestions';
import OpenEndsCollection from 'collections/clients/measures/openends';
import QuestionsCollection from 'collections/clients/measures/questions';

import DashboardDataModel from 'models/clients/carddata';
import DefinitionCollection from 'collections/clients/surveys/definition';
import isBigDataMeasure from 'views/utils/is-big-data-measure';
import featureFlags from 'core/featureFlags';

class CXMeasureController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'cxmeasure:get': '_get',
        'cxmeasure:projectCenter:metrics:get': '_getProjectCenterMetrics',
        'cxmeasure:key:get': '_getMeasureByKey',
        'cxmeasure:scores:get': '_getScores',
        'cxmeasure:impacts:get': '_getImpacts',
        'cxmeasure:comments:get': '_getComments',
        'cxmeasure:latents:get': '_getLatents',
        'cxmeasurebd:latents:get': '_getLatentsBD',
        'cxmeasure:questions:get': '_getQuestions',
        'cxmeasure:commentquestions:get': '_getCommentQuestions',
        'cxmeasurebd:commentquestions:get': '_getBDCommentQuestions',
      },
      name: 'cxmeasure',
      dependsOn: ['user', 'dates', 'measures'],
    });
  }

  initialize() {
    this._get();
  }

  _get(payload) {
    this.publish('app:updateData', {
      cxmeasures: [],
    });
    const model = new MeasuresCollection(false, {
      client: this.getClientId(),
    });

    if (
      !Permissions.userRole('cx_measure_view') ||
      this.getCurrentUserMigrated()
    ) {
      this.publish('app:updateData', {
        cxmeasures: [],
      });
      // The callback here was added after this function had already been referenced in several places.
      // To avoid causing unknown bugs an additional flag was added: `alwaysReturnCallback`
      if (payload && payload.callback && payload.alwaysReturnCallback) {
        payload.callback([]);
      }
      return;
    }

    model
      .fetch({
        doNotAbort: true,
        error: () => {
          //TODO
        },
      })
      .then(() => {
        this.publish('app:updateData', {
          cxmeasures: model.toJSON(),
        });
        if (payload && payload.callback) {
          payload.callback(model.toJSON());
        }
      });
  }

  _canShowSurveyMetric(surveyType, metric) {
    return _.contains(Constants.SURVEY_METRICS[metric], surveyType);
  }

  _getProjectCenterMetrics(payload) {
    this.publish('app:updateData', {
      projectCenterMetrics: false,
    });
    const promises = payload.measures.map((m) => {
      return this._getProjectCenterMeasureMetricsFromDDS({
        measurementKey: m.measurementKey,
        modelInstanceId: m.modelInstanceId,
        name: m.name,
        surveyType: m.surveyType,
        dateRange: payload.dateRange,
      });
    });
    const that = this;
    Promise.all(promises).then((data) => {
      const projectCenterMetrics = {};
      data.forEach((a) => {
        projectCenterMetrics[a.measurementKey] = a;
      });
      that.publish('app:updateData', {
        projectCenterMetrics,
      });
    });
  }

  /**
   * Get project metric
   *
   * This method does a call to DDS with a fake card to fetch the information
   * ready to be displayed by the UI
   *
   * @param {Object} payload  Measure information
   */
  _getProjectCenterMeasureMetricsFromDDS(payload) {
    const { measurementKey, modelInstanceId, name, surveyType, dateRange } =
      payload;

    const isBigData = isBigDataMeasure(surveyType);

    const metrics = [];
    let scoreMetric = false;
    if (this._canShowSurveyMetric(surveyType, 'SAT')) {
      scoreMetric = 'SAT';
      metrics.push({
        type: 'SAT',
        key: isBigData ? modelInstanceId : measurementKey,
      });
    } else if (this._canShowSurveyMetric(surveyType, 'NPS')) {
      scoreMetric = 'NPS';
      metrics.push({
        type: 'NPS',
        key: isBigData ? modelInstanceId : measurementKey,
      });
    } else {
      metrics.push({ type: 'RC', key: null });
    }

    const datasource = {
      type: isBigData
        ? Constants.DASHBOARD.sourceType.cxmeasurebd
        : Constants.DASHBOARD.sourceType.cxmeasure,
      key: isBigData ? modelInstanceId : measurementKey,
      name,
      dataSources: null,
    };
    const dummyCard = {
      isOnDetail: true,
      cardId: 0,
      clientId: this.getClientId(),
      metadata: {
        attributes: {
          chartType: Constants.DASHBOARD.chartTypes.summary,
          dateRange: dateRange,
          dataSources: [datasource],
          metrics,
          thresholds: [],
        },
      },
    };

    const model = new DashboardDataModel(dummyCard, {
      clientId: this.getClientId(),
      useMetricDataService: true,
    });
    return new Promise((resolve) => {
      model
        .save(
          {},
          {
            error: () => {
              resolve({
                measurementKey: measurementKey,
                responses: 0,
                score: null,
              });
            },
          }
        )
        .then(() => {
          const responses = model.get('totalCount');
          const score = model.get('scoreLabel');
          resolve({
            modelInstanceId,
            measurementKey,
            responses,
            score,
          });
        });
    });
  }

  _getMeasureByKey(payload) {
    const model = new MeasuresModel(false, {
      client: this.getClientId(),
      measurementKey: payload.measurementKey,
    });
    const fetchOptions = {};
    if (payload.errorCallback) {
      fetchOptions.error = payload.errorCallback;
    }
    model.fetch(fetchOptions).then(() => {
      if (payload.callback) {
        payload.callback(model.toJSON());
      }
    });
  }

  _getLatentsBD(payload) {
    const model = new LatentsCollection({
      clientId: this.getClientId(),
      measure: payload.modelInstanceId,
      isBigData: true,
    });
    model.fetch().then(() => {
      if (payload.callback) {
        payload.callback(model.toJSON());
      }
    });
  }

  _getLatents(payload) {
    const model = new LatentsCollection({
      clientId: this.getClientId(),
      measure: payload.measurementKey,
    });
    model
      .fetch({
        error: () => {
          //TODO
        },
      })
      .then(() => {
        this.publish('app:getData', {
          key: 'latents',
          callback: (latents) => {
            latents = latents || {};
            latents[payload.measurementKey] = model.toJSON();
            this.publish('app:updateData', { latents });
            if (payload.callback) {
              payload.callback(model.toJSON());
            }
          },
        });
      });
  }

  _getQuestions(payload) {
    if (!payload.measurementKey) {
      return;
    }
    const model = new QuestionsCollection({
      clientId: this.getClientId(),
      measure: payload.measurementKey,
    });
    model
      .fetch({
        error: () => {
          //TODO
        },
      })
      .then(() => {
        if (payload.callback) {
          payload.callback(model.toJSON());
        }

        this.publish('app:getData', {
          key: 'questions',
          callback: (questions) => {
            questions = questions || {};
            questions[payload.measurementKey] = model.toJSON();
            this.publish('app:updateData', { questions });
          },
        });
      });
  }

  _getBDCommentQuestions(payload) {
    const { callback } = payload;
    if (callback) {
      const model = new DefinitionCollection({
        clientId: this.getClientId(),
        modelInstanceId: payload.modelInstanceId,
      });

      model
        .fetch({
          error: (m, r) => {
            if (payload.onError) {
              payload.onError(m, r);
            }
          },
        })
        .then(() => {
          const definition = model.toJSON().pop();
          const questions = definition?.definition?.questions.filter(
            (q) =>
              q.type === 'CUSTOM' &&
              [
                'OPENEND',
                'OPENEND_NUMERIC',
                'TEXT_FIELD',
                'TEXT_AREA',
                'LONG_TEXT',
                'SHORT_TEXT',
              ].includes(q.responseType)
          );

          callback(questions);
        });
    }
  }

  _getCommentQuestions(payload) {
    const model = new CommentQuestionsCollection({
      clientId: this.getClientId(),
      measure: payload.measurementKey,
    });
    const { callback } = payload;
    model
      .fetch({
        error: (m, r) => {
          if (payload.onError) {
            payload.onError(m, r);
          }
        },
      })
      .then(() => {
        if (callback) {
          callback(model.toJSON());
        }
        this.publish('app:getData', {
          key: 'commentQuestions',
          callback: (commentQuestions) => {
            commentQuestions = commentQuestions || {};
            commentQuestions[payload.measurementKey] = model.toJSON();
            this.publish('app:updateData', { commentQuestions });
            if (payload.callback) {
              payload.callback(model.toJSON());
            }
          },
        });
      });
  }

  _getComments(payload) {
    const options = payload || {},
      commentKeys = options.commentKeys || [],
      questionKeys = options.qKeys || [],
      latentKeys = options.latentKeys || [],
      maxPerPage = options.maxPerPage || 10,
      callback = options.callback || null,
      nextPage = options.nextPage || null,
      ascending = options.ascending || false,
      sortLatent = options.sortLatent || null,
      comments = new OpenEndsCollection({
        clientId: this.getClientId(),
        measure: payload.measurementKey,
        nextPage: nextPage || false,
      });
    const criteria = {
      dateRange: payload.dateRange,
      //COMMENT QUESTION KEYS
      commentKeys: commentKeys,
      questionKeys: questionKeys.length ? questionKeys : null,
      latentKeys: latentKeys,
      pageRequest: {
        maxPerPage: maxPerPage,
      },
      ascending: ascending,
      sortLatentKey: sortLatent,
    };

    if (payload.modelFilterId) {
      criteria.modelFilter = {
        modelFilterId: payload.modelFilterId,
      };
    }

    if (payload.hierarchyFilterId) {
      criteria.hierarchyFilter = {
        hierarchyFilterId: payload.hierarchyFilterId,
      };
    }

    _.extend(comments, {
      urlparams: {
        criteria: JSON.stringify(criteria),
      },
    });

    comments
      .fetch({
        error: (m, r) => {
          if (payload.onError) {
            payload.onError(m, r);
          }
        },
      })
      .then(() => {
        const data = {};
        data.respondents = comments.toJSON();
        data.links = comments.links;
        data.nextPage = comments.nextPage;
        data.totalRespondents = comments.totalRespondents;
        data.criteria = comments.criteria;
        if (callback) {
          callback(data);
        }
      });
  }
}

export default CXMeasureController;

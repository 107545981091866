import $ from 'jquery';
import _ from 'underscore';
import BaseController from 'controllers/base';
import AlertModel from 'models/clients/projects/alerts/alert';
import AlertsCollection from 'collections/clients/alerts';

class AlertController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'alerts:copy': 'copy',
        'alerts:delete': 'delete',
        'alerts:get': 'getAlerts',
        'alerts:fetch': 'fetchAlerts',
        'alert:update': 'updateAlert',
        'location:change': 'getRoute',
      },
      dependsOn: ['user', 'projects', 'feedback-survey'],
      name: 'alert',
    });
  }

  initialize(opts) {
    var options = opts || {};
    this.clientId = options.clientId = this.getClientId();
    this.options = options;
    this.alert = new AlertModel(false, options);

    this.haveAlertId = $.Deferred();
    this.haveAlerts = $.Deferred();

    this.collection = new AlertsCollection(false, options);

    //get alertId from route or if no alertId, assume new alert creation instead of edit
    this.subscribe(
      'route:data',
      _.bind(function (payload) {
        if (payload && payload.pathIds) {
          if (payload.pathIds.alerts) {
            this.alertId = payload.pathIds.alerts;
            if (this.haveAlertId && this.haveAlertId.resolve) {
              this.haveAlertId.resolve();
            }
          } else {
            this.createAlert();
          }
        }
      }, this)
    );

    //get alerts list which will assist with obtaining projectId
    this.subscribe(
      'alerts:data',
      _.bind(function (alerts) {
        if (alerts) {
          this.alerts = alerts;
          if (this.haveAlerts && this.haveAlerts.resolve) {
            this.haveAlerts.resolve();
          }
        }
      }, this)
    );

    this.publish('route:get');
    this.fetchAlerts();
    this.changeRoute();
  }

  createAlert() {
    this.alerts = null;
    this.alertId = null;
    this.clearAlertData();
    this.changing = false;
  }

  /**
   * Update alertId and projectId for current alert. This affects all data for this controller.
   * This will also finish initialization of the controller once the new data is retrieved if this
   * has not yet happened.
   */
  changeRoute() {
    $.when(this.haveAlerts, this.haveAlertId).then(
      _.bind(function () {
        if (this.alertId && this.alert) {
          var projectId = _.find(
            this.alerts,
            function (alert) {
              return '' + alert.alertId === '' + this.alertId;
            },
            this
          ).projectId;

          // Clear out the old alert
          this.clearAlertData();

          this.alert.alertId = this.alertId;
          this.alert.projectId = projectId;

          // Get the alert
          this.fetchAlertData(
            _.bind(function () {
              // Clear the flag to indicate that we've finished changing routes and we have all the necessary data.
              this.changing = false;
              // Resolve initialized, if needed.
              if (this.initialized && this.initialized.resolve) {
                this.initialized.resolve();
              }
            }, this)
          );
        }
      }, this)
    );
  }

  /**
   * Returns alert object
   */
  getAlert() {
    // Don't send out alert data if we're in the process of changing alerts
    // It will be sent out when we're done, anyways.
    if (this.changing) {
      return;
    }
    this.initialized.done(
      _.bind(function () {
        this.publish('alert:data', this.alert.toJSON());
      }, this)
    );
  }

  /**
   * Retrieve info on current route/path
   */
  getRoute() {
    if (!this.changing) {
      // Set the flag to show that we're changing routes and we shouldn't send out any more data until we're done.
      this.changing = true;

      this.haveAlertId = $.Deferred();
      this.haveAlerts = $.Deferred();

      this.publish('route:get');
      this.publish('alerts:get');

      this.changeRoute();
    }
  }

  clearAlertData() {
    this.alert = new AlertModel(false, this.options);
  }

  /**
   * Retrieve the hierarchical alert data
   * @param cb
   * @returns {*}
   */
  fetchAlertData(cb) {
    var def = $.Deferred(),
      alertUrl = this.alert.url;

    cb = cb || function () {};

    this.alert.url = this.alert.fetchUrl;

    $.when(this.alert.fetch()).then(
      _.bind(function () {
        def.resolve();
        cb();
        this.alert.url = alertUrl;
        this.getAlert();
      }, this)
    );

    return def;
  }

  /**
   * Update an alert with payload data
   */
  updateAlert(payload) {
    var alert = this.alert || new AlertModel(false, this.options);

    alert.projectId = payload.projectId;

    alert.set(payload);
    if (payload.alertId) {
      alert.url =
        'clients/' +
        this.clientId +
        '/projects/' +
        alert.projectId +
        '/alerts/' +
        payload.alertId;
    } else {
      alert.url =
        'clients/' +
        this.clientId +
        '/projects/' +
        alert.projectId +
        '/alerts/';
    }

    alert.save().done(
      _.bind(function () {
        this.getAlert();
        this.publish('alert:saved');
      }, this)
    );
  }

  /**
   * Removes an alert from the backend
   */
  delete(payload) {
    var options = {},
      model = this.collection.get(payload.alertId);
    options.wait = true;
    model.url =
      'clients/' +
      this.clientId +
      '/projects/' +
      payload.project +
      '/alerts/' +
      payload.alertId;

    // On success broadcast the new data
    options.success = _.bind(function (model) {
      this.publish('alerts:data', this.getAlerts());
    }, this);
    // Just update data on error for now
    options.error = _.bind(function (model) {
      this.publish('alerts:data', this.getAlerts());
    }, this);

    model.destroy(options);
  }

  fetchAlerts() {
    var def = $.Deferred();

    if (this.collection) {
      $.when(this.collection.fetch()).done(
        _.bind(function () {
          def.resolve();
          this.getAlerts();
        }, this)
      );
    } else {
      def.resolve();
    }

    return def;
  }

  /**
   * Returns an object with alerts
   */
  getAlerts(payload) {
    var data = {};
    // Get single
    if (payload && payload.alertId) {
      var alert = this.collection.get(payload.alertId);
      data = {
        alert: (alert && alert.toJSON()) || null,
      };
      if (!data.alert) {
        return {};
      }
    } else {
      // Get all
      this.collection.sort();
      data = this.collection.toJSON();
    }

    this.publish('alerts:data', data);

    return data;
  }
}

export default AlertController;

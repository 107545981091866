import Backbone from 'backbone';
import _ from 'underscore';
import ImpactLatentsCollection from 'collections/clients/measures/impacts/latents';

var ImpactPeriod = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    firstDate: '2016-01-01',
    lastDate: '2016-01-01',
    respondents: 0,
  },

  parse: function (data) {
    this.latents = new ImpactLatentsCollection();
    if (data && data.impacts && data.impacts.length) {
      this.latents.add(data.impacts);
    }
    delete data.impacts;
    return data;
  },

  toJSON: function () {
    return _.extend(
      {
        latents: this.latents ? this.latents.toJSON() : [],
      },
      this.attributes
    );
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default ImpactPeriod;

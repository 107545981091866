import Backbone from 'backbone';

const UserEntitlementsModel = Backbone.Model.extend({
  initialize: function (opts) {
    const options = opts || {};

    this.userId = options.userId;
  },
  idAttribute: 'userId',
  parse: function (data) {
    const capabilities = data.entitlements.capabilities
      ? data.entitlements.capabilities
      : [];
    const restrictions = data.entitlements.restrictions
      ? data.entitlements.restrictions
      : [];
    return { capabilities, restrictions };
  },
  url: function () {
    return `authorization-service/users/${this.userId}/entitlements`;
  },
});

export default UserEntitlementsModel;

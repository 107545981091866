import Backbone from 'backbone';
import TemplateModel from 'models/clients/template';

var TemplatesCollection = Backbone.Collection.extend({
  model: TemplateModel,

  initialize: function (models, opts) {
    var options = opts || {};

    this.client = options.client;
  },

  parse: function (data) {
    return data.templates;
  },

  serviceId: 'acs-services',

  url: function () {
    return `clients/${this.client}/templates`;
  },
});

export default TemplatesCollection;

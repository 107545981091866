import Backbone from 'backbone';
import _ from 'underscore';
import i18n from 'core/i18n';

var ProjectModel = Backbone.Model.extend({
  feedbackDefaults: {
    topicQuestionText: 'projects.edit.question.defaultTopicQuestion',
    ratingQuestionText: 'projects.edit.question.defaultRatingQuestion',
    defaultTopicText: 'projects.edit.topic.title',
    prologueText: 'projects.edit.question.emptyPrologue',
    epilogueText: 'projects.edit.question.emptyEpilogue',
  },

  idAttribute: 'projectId',

  initialize: function (model, options) {
    if (this.isNew() && model.type === 'FEEDBACK') {
      _.each(
        this.feedbackDefaults,
        _.bind(function (key, attribute) {
          var string = i18n.getString(key);

          if (string) {
            this.set(attribute, string);
          }
        }, this)
      );
    }
  },

  serviceId: 'acs-services',

  parse: function (data) {
    return data;
  },
});

export default ProjectModel;

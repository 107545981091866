import React from 'react';
import classNames from 'classnames';
import BaseView from 'views/base';
import { Text } from 'cx-component-library';

class MessageBar extends BaseView {
  constructor(props) {
    super(props);
    this.state = {};
    this.actions = {};
  }

  render() {
    const messageTextClass = classNames({
      'message__text message__text--hidden': this.props.type === 'hidden',
      'message__text message__text--error': this.props.type === 'error',
    });

    const messageClass = classNames({
      message: true,
      'message--error': this.props.type === 'error',
      'message--confirmation': this.props.type === 'confirmation',
    });

    var styles = this.props.isOpen
      ? {
          opacity: 1,
          bottom: 0,
          zIndex: this.props.zIndex,
          pointerEvents: 'none',
        }
      : {};

    var messageStyles = this.props.isOpen ? { pointerEvents: 'auto' } : {};

    return (
      <div className='message_container' style={styles}>
        <div className={messageClass} style={messageStyles}>
          <Text className={messageTextClass} type='span' theme='inherit'>
            {this.props.text}
          </Text>
          <button className='message__close' onClick={this.props.onClose}>
            <Text theme='inherit'>{this.props.action}</Text>
          </button>
        </div>
      </div>
    );
  }
}
MessageBar.defaultProps = {
  text: null,
  type: 'hidden',
  action: null,
  isOpen: false,
  onClose: () => {},
};

export default MessageBar;

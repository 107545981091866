import Backbone from 'backbone';
import TopicsCollection from 'collections/clients/projects/topics';
import QuestionsCollection from 'collections/clients/projects/questions';
import _ from 'underscore';

var SurveyModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.clientId = options.clientId;
    this.topics = new TopicsCollection(false, options);
    this.questions = new QuestionsCollection(false, options);
    this.options = options;
    this.options.projectId = this.projectId || options.projectId;
    this.options.parse = true;
  },

  idAttribute: 'surveyId',

  parse: function (data) {
    if (data && data.questions && data.questions.length) {
      this.questions.reset(data.questions, this.options);
    }

    if (data && data.topics && data.topics.length) {
      this.topics.reset(data.topics, this.options);
    }

    delete data.questions;
    delete data.topics;

    return data;
  },

  toJSON: function (opts) {
    var options = opts || {};

    if (options.toView) {
      return {
        topics: this.topics.toJSON(options),
        questions: this.questions.toJSON(options),
        survey: _.extend({}, this.attributes),
        projectId: this.projectId,
        publishedDateTime: this.get('publishedDateTime'),
        stagedDateTime: this.get('stagedDateTime'),
      };
    }
    //service expects just the survey object for PUT requests
    return _.extend({}, this.attributes);
  },

  serviceId: 'acs-services',

  fetchUrl: function () {
    return (
      'clients/' +
      this.clientId +
      '/projects/' +
      (this.projectId || this.options.projectId) +
      '/survey'
    );
  },

  url: function () {
    return (
      'clients/' +
      this.clientId +
      '/projects/' +
      (this.projectId || this.options.projectId) +
      '/surveys/' +
      this.id
    );
  },
});

export default SurveyModel;

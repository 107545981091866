import Backbone from 'backbone';
import UserModel from 'models/platform-scim-service/user';

const UserSearchCollection = Backbone.Collection.extend({
  headers: {
    'Content-Type': 'application/scim+json',
  },

  model: UserModel,

  initialize: function (models, opts) {},

  parse: function (data) {
    return data.Resources;
  },

  url: function () {
    return `platform-scim-service/Users/.search`;
  },
});

export default UserSearchCollection;

import Backbone from 'backbone';
import { merge } from 'lodash';

var ProjectSettingsModel = Backbone.Model.extend({
  feedbackDefaults: {
    devices: {
      overridesEnabled: true,
      desktop: {
        icon: 'aspark100.png',
        fbtype: 'none',
        surveytype: 'modal',
      },
      mobile: {
        icon: 'aspark100.png',
        fbtype: 'none',
        surveytype: 'popup',
      },
      tablet: {
        icon: 'aspark100.png',
        fbtype: 'none',
        fbdirection: 'horizontal',
        fblocation: 'middleleft',
        label: 'Feedback',
        popup: true,
        surveytype: 'popup',
      },
    },
    icon: 'aspark100.png',
    delay: 0,
    template: 'default',
    label: 'Feedback',
    fblocation: 'middleright',
    fbtype: 'none',
    disabled: true,
    fbanimate: false,
    fbfixed: false,
    fbdirection: 'vertical',
    topics: [],
    surveytype: 'modal',
    replay: false,
    fbcolor: '#F24554',
  },
  replayDefaults: {
    blacklist: {
      active: false,
      text: [],
    },
    advancedSettings: {
      layout: 'CENTERFIXED',
      replay_pools: [
        {
          path: '.',
          sp: 100,
        },
      ],
      replay_repools: [],
      exclude: {
        urls: [],
        referrers: [],
        userAgents: [],
        browsers: [],
        cookies: [],
        variables: [],
      },
      browser_cutoff: {
        IE: 10,
        Safari: 5.1,
        Firefox: 11,
        Chrome: 20,
        'Chrome Mobile': 20,
        Opera: 1000,
      },
      platform_cutoff: {
        Android: 4.1,
        Winphone: 8,
        iPod: 7,
        iPhone: 7,
        iPad: 7,
      },
      device_type_support: {
        desktop: true,
        phone: true,
        tablet: true,
      },
      device_blacklist: ['HTC_Rezound', 'blackberry'],
      pii: {
        staticBlockEls: {},
        dynamicBlockEls: {},
        staticVisibleEls: {},
        dynamicVisibleEls: {},
        assetBlockEls: {},
        removeVisibilityEls: {},
        obscureEls: {},
      },
      svgCaptureEnabled: false,
      scrollEls: null,
      regexScrub: [],
      lowFidelity: [],
      watchNodeList: '',
      shortenedDomRewrites: false,
      keepComments: false,
      useEleMutation: true,
    },
  },

  idAttribute: 'saved',

  initialize: function (model, opts) {
    var options = opts || {};

    this.clientId = options.clientId;
  },

  parse: function (data) {
    var defaults = {};
    var settings = data || {};
    delete settings.settingsData;

    if (this.type === 'FEEDBACK') {
      // set fbtype: 'badge' for all the device if _checkIsExistingSurveyShowBadge returns true
      if (this._checkIsExistingSurveyShowBadge(data)) {
        this.feedbackDefaults.devices.desktop.fbtype =
          this.feedbackDefaults.devices.tablet.fbtype =
          this.feedbackDefaults.devices.mobile.fbtype =
            'badge';
      }
      defaults = this.feedbackDefaults;
      delete settings.blacklist;
      delete settings.advancedSettings;

      if (settings.hasOwnProperty('popup')) {
        settings.surveytype =
          settings.surveytype || (settings.popup ? 'popup' : 'modal');
        delete settings.popup;
      }
    } else if (this.type === 'REPLAY') {
      defaults = this.replayDefaults;
    }

    return merge({}, defaults, settings);
  },
  /**
   * _checkIsExistingSurveyDisabled
   * @params: data
   * Desc: checks if survey is existing survey and disabled flag is true or false
   */
  _checkIsExistingSurveyShowBadge: function (data) {
    return data &&
      !data.devices &&
      data.fbtype !== undefined &&
      data.fbtype === 'badge' &&
      data.fbtype !== 'none'
      ? true
      : false;
  },
  toJSON: function () {
    let data = merge({}, this.attributes);

    if (this.type === 'FEEDBACK') {
      delete data.advancedSettings;
      delete data.blacklist;
      delete data.settingsData;
    }

    data.saved = true;

    return data;
  },

  setProjectId: function (projectId) {
    this.projectId = projectId;
  },

  serviceId: 'acs-services',

  url: function () {
    return `clients/${this.clientId}/projects/${this.projectId}/settings`;
  },
});

export default ProjectSettingsModel;

import FeatureFlags from 'core/featureFlags';
import { getAccessToken } from 'views/utils/lux-util';

const getPrimaryRoutes = () => {
  const routes = ['dashboards', 'analyze', 'projects', 'leaderboard', 'alerts'];

  if (FeatureFlags.hasFeature('navigate-to-survey-management')) {
    const decoded = getAccessToken();

    if (decoded?.migrated === 'false') {
      routes.unshift('survey-management');
    }
  }

  if (FeatureFlags.hasFeature('datasources-list')) {
    routes.splice(3, 0, 'datasources');
  }

  if (FeatureFlags.hasFeature('benchmark-reports')) {
    routes.push('benchmarks');
  }
  return routes;
};

export default getPrimaryRoutes;

export function hasBenchmarkDimension(dimensions) {
  return (dimensions || []).some(({ type }) => type === 'BENCHMARK');
}

export function hasBenchmarkCategory(dimensions) {
  return (dimensions || []).some(
    ({ parentKey }) => parentKey === 'BENCHMARKCATEGORY'
  );
}

export function hasBenchmarkType(dimensions) {
  return (dimensions || []).some(
    ({ parentKey }) => parentKey === 'BENCHMARKTYPE'
  );
}

export function hasBenchmarkTypeOnly(dimensions) {
  return hasBenchmarkType(dimensions) && !hasBenchmarkCategory(dimensions);
}

import Backbone from 'backbone';

const HierarchyVerticesModel = Backbone.Model.extend({
  initialize: function (models, opts) {
    const options = opts || {};

    this.client = options.clientId;
    this.hierarchy = options.hierarchyId;
    this.limit = options.limit;
    this.offset = options.offset;
  },

  url: function () {
    if (!this.limit || !this.offset) {
      return `clients/${this.client}/hierarchies/${this.hierarchy}/vertices`;
    }

    return `clients/${this.client}/hierarchies/${this.hierarchy}/vertices?limit=${this.limit}&offset=${this.offset}`;
  },
});

export default HierarchyVerticesModel;

export default {
  layout: 'cx-projects',
  controller: 'projects', // to load feedback projects for project selection
  permissions: 'portal_access',
  parentNav: 'projects',
  ':id': {
    layout: 'cx-insights-summary',
    analytics: {
      ':id': {
        layout: 'cx-survey',
        ':id': {
          detail: {
            layout: 'cx-survey',
          },
        },
        dashboards: {
          ':id': {
            controller: 'dashboards',
            card: {
              ':id': {
                layout: 'cx-survey',
              },
            },
          },
        },
      },
    },
  },
};

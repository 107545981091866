const browserOptions = {};

function getBrowserOptions() {
  const embedMatch = /embedAs=(dashboards?|card|all-dashboards)/.exec(
    window.location.search
  );
  if (embedMatch) {
    const embedAs = embedMatch[1];
    browserOptions.embedAs = embedAs;
  }
  return browserOptions;
}

export default getBrowserOptions;

import Backbone from 'backbone';
import _ from 'underscore';
import HierarchyModel from 'models/clients/hierarchies';

const HierarchyCollection = Backbone.Collection.extend({
  model: HierarchyModel,
});
const HierarchyPageableModel = Backbone.Model.extend({
  defaults: {
    hasMore: false,
  },

  initialize: function (models, opts) {
    var options = opts || {
      offset: 0,
    };
    this.client = options.clientId;
    this.offset = options.offset;
  },

  parse: function (data) {
    return {
      results: new HierarchyCollection(data.results, { parse: true }),
      hasMore: data.hasMore,
    };
  },

  toJSON: function () {
    return _.extend(this.attributes, {
      results: this.attributes.results ? this.attributes.results.toJSON() : [],
    });
  },

  getUserIds: function () {
    return _.compact(
      this.get('results').map((result) => {
        return result.get('updatedBy');
      })
    );
  },

  setUsers: function (users) {
    this.get('results').forEach((result) => {
      const user = _.findWhere(users, { userId: result.get('updatedBy') });
      result.set('updatedByUser', user);
    });
  },

  url: function () {
    return `clients/${this.client}/hierarchies?offset=${this.offset}`;
  },
});

export default HierarchyPageableModel;

import Backbone from 'backbone';

const CredentialsModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.user = options.userId;
  },

  url: function () {
    return `platform-scim-service/clients/${this.client}/users/${this.user}/credentials`;
  },
});

export default CredentialsModel;

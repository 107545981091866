import Backbone from 'backbone';

const HierarchyAncestriesModel = Backbone.Model.extend({
  headers: {
    Accept: 'application/json',
  },

  initialize: function (model, opts = {}) {
    this.client = opts.clientId;
    this.hierarchyId = opts.hierarchyId;
  },

  parse: function (data) {
    return data.hierarchyNodes;
  },

  url: function () {
    return `clients/${this.client}/hierarchies/${this.hierarchyId}/ancestries`;
  },
});

export default HierarchyAncestriesModel;

import BaseController from 'controllers/base';
import _ from 'underscore';
import ClientSettingsModel from 'models/clients/settings/client-settings';
import UserSettingsModel from 'models/clients/settings/user-settings';
import store from 'core/store';

class StorageController extends BaseController {
  /**
   * Payload for storage respects the following properties:
   * - callback Function : fired after completing action
   * - bare Boolean : provided key string isn't ornamented for client scoping
   * - update Boolean : PUT instead of POST
   * - key String : base string to be operated on
   * - qualifier String : string to be filtered on
   * - data ? : data to be stored at key
   * - expires Date : timestamp for testing key expiry
   */
  constructor() {
    super(arguments, {
      actions: {
        // Save to user settings
        'storage:user:set': 'setUserKey',
        'storage:user:get': 'getUserKey',
        'storage:user:delete': 'deleteUserKey',

        // Save to client settings
        'storage:client:set': 'setClientKey',
        'storage:client:get': 'getClientKey',
        'storage:client:delete': 'deleteClientKey',

        // Save to local storage
        'storage:set': 'setKey',
        'storage:get': 'getKey',
        'storage:delete': 'deleteKey',
        'storage:clear': 'clearStorage',
      },
      name: 'storage',
    });
  }

  initialize() {
    // Do a test write to localStorage to make sure it's working
    this.verifyStorage();

    // Run a purge of expired keys to clean up localStorage
    this.purgeExpired();
  }

  buildData(payload) {
    const timestamp = new Date().getTime();
    const { expires, data } = payload;

    var value = {
      timestamp: timestamp,
      data: data,
    };

    if (expires) {
      value.expires = expires;
    }

    return value;
  }

  saveSettingKey(settingsModel, payload) {
    const { callback, update } = payload;

    if (!update) {
      settingsModel.idAttribute = null;
    }

    settingsModel.save().then(() => {
      // Should pass an error in if it doesn't save
      if (callback && callback instanceof Function) {
        callback(payload);
      } else {
        this.publish('storage:updated', payload);
      }
    });
  }
  fetchSettingKey(settingsModel, payload) {
    const { callback } = payload;

    settingsModel
      .fetch({
        error: () => {
          if (callback && callback instanceof Function) {
            callback();
          }
        },
      })
      .then(() => {
        if (callback && callback instanceof Function) {
          callback(settingsModel.toJSON());
        }
      });
  }
  destroySettingKey(settingsModel, payload) {
    const { callback } = payload;

    settingsModel.destroy({
      success: () => {
        if (callback && callback instanceof Function) {
          callback();
        }
      },
    });
  }

  /**
   * Save data to a client
   * @param payload
   */
  setClientKey(payload) {
    const { key, qualifier, customData } = payload;
    const clientId = this.getClientId();

    const settingsClientModel = new ClientSettingsModel(
      {
        clientId,
        namespace: key,
        qualifier: qualifier,
        data: customData || this.buildData(payload),
      },
      {
        clientId,
      }
    );

    this.saveSettingKey(settingsClientModel, payload);
  }
  /**
   * Retrieve data from a client
   * @param payload
   */
  getClientKey(payload) {
    const { key, qualifier } = payload;

    const settingsClientModel = new ClientSettingsModel(
      {
        namespace: key,
      },
      {
        clientId: this.getClientId(),
      }
    );

    _.extend(settingsClientModel, {
      urlparams: {
        qualifier: qualifier,
      },
    });

    this.fetchSettingKey(settingsClientModel, payload);
  }
  /**
   * Delete data from a client
   * @param payload
   */
  deleteClientKey(payload) {
    const { key, qualifier } = payload;

    const settingsClientModel = new ClientSettingsModel(
      {
        namespace: key,
        qualifier: qualifier,
      },
      {
        clientId: this.getClientId(),
      }
    );

    this.destroySettingKey(settingsClientModel, payload);
  }

  /**
   * Save data to a client
   * @param payload
   */
  setUserKey(payload) {
    const { key, qualifier } = payload;

    const settingsUserModel = new UserSettingsModel({
      namespace: key,
      qualifier: qualifier,
      data: this.buildData(payload),
    });

    this.saveSettingKey(settingsUserModel, { ...payload, isUserKey: true });
  }
  /**
   * Retrieve data from a client
   * @param payload
   */
  getUserKey(payload) {
    const { key, qualifier } = payload;

    const settingsUserModel = new UserSettingsModel({
      namespace: key,
    });

    _.extend(settingsUserModel, {
      urlparams: {
        qualifier: qualifier,
      },
    });

    this.fetchSettingKey(settingsUserModel, payload);
  }
  /**
   * Delete data from a client
   * @param payload
   */
  deleteUserKey(payload) {
    const { key, qualifier } = payload;

    const settingsUserModel = new UserSettingsModel({
      namespace: key,
      qualifier: qualifier,
    });

    this.destroySettingKey(settingsUserModel, payload);
  }

  /**
   * Save data to a key
   * @param payload
   */
  setKey(payload) {
    const { callback, key } = payload;
    if (key) {
      store.set(this.keyString(payload), this.buildData(payload));
    }

    // Should pass an error in if it doesn't save
    if (callback && callback instanceof Function) {
      callback();
    }
  }
  /**
   * Retrieve data from a key, unless it has expired
   * @param payload
   */
  getKey(payload) {
    var options = payload || {};
    var callback = options.callback;
    var key = options.key;
    var value = store.get(this.keyString(payload));
    var expires = options.expires;
    var expired = this.isExpired(key, expires);
    var data = {
      key: key,
      data: value,
      expired: expired,
    };

    if (this.isExpired(key, expires)) {
      store.remove(key);
      delete data.data;
    }

    if (callback instanceof Function) {
      callback(data);
    } else {
      // Not going to publish. Callback should have been provided.
      // this.publish("storage:data", data);
    }
  }
  /**
   * Generate the key from the supplied key and the clientId
   * @param payload
   * @returns {*}
   */
  keyString(payload) {
    var options = payload || {};
    var clientId = this.getClientId();
    var bare = options.bare || false;
    var key = payload.key;

    return `${bare ? '' : clientId}__${key}`;
  }

  /**
   * Convert a date to unix epoch time
   * @param time
   * @returns {*}
   */
  toTimestamp(time) {
    var timestamp = time;

    if (time instanceof Date) {
      timestamp = time.getTime();
    }

    return timestamp;
  }

  /**
   * Text expiry of key, either from key itself, or against supplied timestamp
   */
  isExpired(key, expires) {
    var now = new Date().getTime();
    var keyExpires = key ? key.expires : false;

    return (this.toTimestamp(expires || keyExpires) || 0) > now;
  }

  /**
   * Remove a key from localStorage
   */
  deleteKey(payload) {
    store.remove(this.keyString(payload));
  }

  /**
   * Check that storage is working and has space
   */
  verifyStorage() {
    //TODO
  }

  /**
   *  Clear localStorage of keys
   */
  clearStorage() {
    this.store.clearAll();
  }

  /**
   * Remove keys that have an expires timestamp that has passed
   */
  purgeExpired() {
    var storage = window.localStorage;

    for (let key in storage) {
      if (this.isExpired(storage.getItem(key))) {
        store.remove(key);
      }
    }
  }
}

export default StorageController;

import Backbone from 'backbone';
import URL from 'core/utils/url';

const DashboardDataModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    const options = opts || {};
    const urlparams = opts.urlparams || {};
    this.client = options.clientId;
    this.requestId = options.requestId;
    this.useCommentDataService = options.useCommentDataService;
    this.useImpactDataService = options.useImpactDataService;
    this.useMetricDataService = options.useMetricDataService;
    this.overrideMaxRC = urlparams.overrideMaxRC;
    this.offset = urlparams.offset;
    this.chartType = urlparams.chartType;
  },

  url: function () {
    let url;
    if (this.useCommentDataService) {
      url = `clients/${this.client}/commentdata`;
    } else if (this.useImpactDataService) {
      url = `clients/${this.client}/impactdata`;
    } else if (this.useMetricDataService) {
      url = `clients/${this.client}/metricdata`;
    }

    if (this.requestId) {
      url = `${url}/${this.requestId}`;
    }
    if (this.overrideMaxRC) {
      url = URL.insertParam(url, 'overrideMaxRC', this.overrideMaxRC);
    } else if (this.offset) {
      url = URL.insertParam(url, 'offset', this.offset);
    }
    return url;
  },
});

export default DashboardDataModel;

import BaseController from 'controllers/base';
import FileModel from 'models/clients/file';
import { saveAs } from 'file-saver';

// Class that deals with CMS Files Microservice
class FileController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'files:post': '_postFile',
        'files:get': '_getFile',
        'files:download': '_downloadFile',
        'files:put': '_putFile',
      },
      name: 'files',
      dependsOn: ['user'],
    });
  }

  initialize() {}

  _postFile(payload) {
    var model = new FileModel(false, {
      file: payload.file,
      meta: payload.meta,
    });

    model.external = payload.external || false;

    model.save().then(() => {
      if (payload.callback) {
        payload.callback(model.toJSON());
      }
    });
  }

  _fetchFile(fileKey, callback) {
    var model = new FileModel(false, {
      fileKey: fileKey,
    });

    model.fetch().done(callback.bind(this, model));
  }

  _getFile(payload) {
    this._fetchFile(payload.fileKey, (file) => {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(file.blob);

      if (payload.callback) {
        payload.callback(imageUrl);
      }
    });
  }

  _downloadFile(payload) {
    this._fetchFile(payload.fileKey, (file) => {
      saveAs(
        file.blob,
        payload.fileKey.substr(payload.fileKey.lastIndexOf('/') + 1)
      );
    });
  }

  _putFile() {}
}

export default FileController;

import Backbone from 'backbone';
import _ from 'underscore';
import ApiConsumerModel from 'models/clients/settings/integrate/consumers';

const ApiConsumerCollections = Backbone.Collection.extend({
  model: ApiConsumerModel,
  comparator: 'application_name',
  initialize: function (opts) {
    this.clientKey = opts.clientKey;
    this.clientId = opts.clientId;
    this.activeOnly = true;
  },
  parse: function (data) {
    if (this.activeOnly) {
      data = _.filter(data, { status: 'active' });
    }
    // CURRENTLY ONLY SHOWING RESULTS FOR CX MEASURES
    // AS THAT IS THE ONLY ONE SUPPORTED IN 18.1
    return _.filter(data, (d) => {
      return _.contains(d.resource_ids, 'cx-measure-api');
    });
  },

  url: function () {
    if (this.clientId) {
      return `api-administration/consumers/${this.clientId}`;
    } else {
      return `api-administration/consumers?clientKey=${this.clientKey}`;
    }
  },
});

export default ApiConsumerCollections;

import OktaAuth from '@okta/okta-auth-js';
const defaultResponseMode = 'fragment';
const defaultPkce = false;
const defaultScopes = 'openid profile email groups';

export const msalConfig = {
  auth: {
    clientId: `!!_azureClientId_!!`,
    authority: `!!_azureTenantUrl_!!`,
    redirectUri: `${document.location.origin}/client/login`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true, // Set this to "true" for issues on IE11 or Edge
  },
};

export const msalLoginConfig = {
  scopes: ['user.Read', 'openid', 'profile', 'email'], // optional Array<string>
  responseMode: 'form_post',
  navigateToLoginRequestUrl: false,
};
const getOktaAuth = ({
  clientId,
  issuer,
  redirectUri,
  authorizeUrl,
  responseMode = defaultResponseMode,
}) =>
  OktaAuth({
    clientId,
    issuer,
    redirectUri,
    authorizeUrl,
    responseMode,
    pkce: defaultPkce /*pkce mode is not implemented by verint*/,
  });
export const SsoWithoutPrompt = async ({
  clientId,
  authorizeUrl,
  redirectUri,
  responseType,
  scopes = defaultScopes,
  prompt = false,
}) => {
  const oktaAuth = getOktaAuth({
    clientId,
    issuer: `!!_oktaURL_!!`,
    redirectUri,
    authorizeUrl,
  });

  return oktaAuth.token
    .getWithoutPrompt({
      responseType: [responseType],
      scopes: scopes.split(' '),
      prompt,
    })
    .catch(() => {});
};

export const SsoWithRedirect = ({
  authorizeUrl,
  clientId,
  identityProviderId,
  loginHint = '',
  redirectUri,
  responseMode,
  responseType,
  scopes = defaultScopes,
}) => {
  const oktaAuth = getOktaAuth({
    clientId,
    issuer: `!!_oktaURL_!!`,
    redirectUri,
    authorizeUrl,
    responseMode,
  });

  oktaAuth.token.getWithRedirect({
    idp: identityProviderId,
    responseType: [responseType],
    scopes: scopes.split(' '),
    loginHint: loginHint,
  });
};

import $ from 'jquery';
import _ from 'underscore';
import { cloneDeep } from 'lodash';
import BaseController from 'controllers/base';
import FilterModel from 'models/clients/filters/events-filter';
import FilterDetailsModel from 'models/clients/filters/events-filter-details';
import FiltersCollection from 'collections/clients/filters/events-filters';

/**
 * Controller for manipulating filters
 */
class FiltersController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'event-filters',
      actions: {
        'measures:currentMeasure': 'setCurrentMeasure',
        'event-filters:save': 'saveFilter',
        'event-filters:update': 'updateFilter',
        'event-filters:delete': 'deleteFilter',
        'event-filters:set': 'setFilter',
        'event-filters:get': 'getFilters',
        'event-filters:details:get': 'getFilterById',
        'event-filters:reset': 'resetFilter',
        'event-filters:reset:noaction': 'resetFilterNoAction',
        'event-filters:chip:add': 'addFilterChip',
        'event-filters:chip:edit': 'editFilterChip',
        'event-filters:chip:remove': 'removeFilterChip',
        'event-filters:operator:set': 'setFilterOperator',
      },
      dependsOn: ['user'],
    });
  }

  initialize(opts) {
    // global filterm model
    this.client = this.getClientId();
    this.filter = new FilterDetailsModel();
    this.filter.set('clientId', this.getClientId());
    this.filter.set('userId', this.getCurrentUserId());
  }

  cloneFilter(filter) {
    const filterDetailsModel = new FilterDetailsModel(
      _.clone(filter.attributes)
    );

    // if(filter.measure) {
    // filterDetailsModel.measure = filter.measure;
    // }
    if (!filterDetailsModel.get('temp')) {
      filterDetailsModel.id = null;
      filterDetailsModel.set('filterId', null);
      filterDetailsModel.set('externalId', null);
    }
    // strip ids
    filterDetailsModel.unset('modelFilterId');
    filterDetailsModel.unset('createDateTime');
    filterDetailsModel.unset('vndType');
    filterDetailsModel.unset('links');
    // filterDetailsModel.set('filterType', 'ADHOC');
    filterDetailsModel.set('temp', true);

    return filterDetailsModel;
  }

  deleteFilter(payload) {
    const { filter, callback } = payload;
    const options = {
      clientId: this.client,
      // measure: measurementKey || this.currentMeasure.measurementKey
    };

    const filterModel = new FilterModel(filter, options);
    filterModel.destroy().then(() => {
      if (callback) {
        callback(filter);
      }
      this.publish('event-filters:filter:deleted', filter);
    });
  }

  updateFilter(replacementFilter) {
    const options = {
      clientId: this.client,
      measure: this.filter.get('measurementKey'),
    };

    this.filter.set('filterId', replacementFilter.filterId);
    this.filter.set('externalId', replacementFilter.externalId);
    // this.filter.set('modelFilterId', replacementFilter.modelFilterId);
    this.filter.set('name', replacementFilter.name);
    // this.filter.set('filterType', 'ACTIVE');
    this.filter.set('temp', false);
    // this.filter.set('version', replacementFilter.version);

    this.filter = new FilterDetailsModel(this.filter.toJSON(), options);
    this.filter.save().then(() => {
      this.publish('event-filters:filter:update', this.filter.toJSON());
      this.publish('event-filters:filter:new');
    });
  }

  saveFilter(filterName) {
    const options = {
      clientId: this.client,
      measure: this.filter.get('measurementKey'),
    };

    this.filter.set('name', filterName);
    this.filter.set('filterType', 'ACTIVE');
    this.filter.set('temp', false);
    const filterModel = new FilterModel(this.filter.toJSON(), options);
    filterModel.save().then(() => {
      this.filter.fetch().then(() => {
        this.publish('event-filters:filter:changed', this.filter.toJSON());
        this.publish('event-filters:filter:new');
      });
    });
  }

  saveAdhocFilter(filter) {
    const options = {
      clientId: this.client,
      // measure: filter.measure // || this.currentMeasure.measurementKey
    };
    this.filter.set('clientId', this.getClientId());
    this.filter.set('userId', this.getCurrentUserId());
    const filterModel = new FilterModel(filter.toJSON(), options);
    filterModel.save().then(() => {
      this.filter = new FilterDetailsModel(filterModel.toJSON(), options);
      this.filter.fetch().then(() => {
        this.publish('event-filters:filter:changed', this.filter.toJSON());
      });
    });
  }

  setCurrentMeasure(measure) {
    this.currentMeasure = measure;
  }

  setFilter(payload) {
    const { isInitialization, noReload, ...filter } = payload;
    const options = {
      clientId: this.client,
      // measure: filter.measurementKey || this.currentMeasure.measurementKey
    };
    this.filter = new FilterDetailsModel(filter, options);
    this.filter.fetch().then(() => {
      this.publish('event-filters:filter:changed', {
        ...this.filter.toJSON(),
        noReload,
        isInitialization,
      });
    });
  }

  getFilterById(filter) {
    const options = {
      clientId: this.client,
      measure: filter.measurementKey,
    };
    const model = new FilterDetailsModel(filter, options);
    const errorHandler = {
      error: () => {},
    };
    model.fetch(errorHandler).then(() => {
      this.publish('app:getData', {
        key: 'modelFilterDetails',
        callback: (currentModelFilterDetails) => {
          const modelFilterDetail = model.toJSON();
          const modelFilterDetails = cloneDeep(currentModelFilterDetails) || {};
          modelFilterDetails[model.get('filterId')] = modelFilterDetail;
          this.publish('event-filters:filter:changed', modelFilterDetail);
          this.publish('app:updateData', { modelFilterDetails });
          if (filter.callback) {
            filter.callback(modelFilterDetail);
          }
        },
      });
    });
  }

  getFilters(payload) {
    const { callback } = payload;
    const options = {
      clientId: this.client,
      // measure: measurementKey || this.filter.get("measurementKey") || this.currentMeasure.measurementKey
    };

    const filters = new FiltersCollection(options);

    filters.fetch().then(() => {
      const defs = [];

      filters.each((model, index) => {
        const def = new $.Deferred();
        defs.push(def);

        model.addOptions(options);
        model.fetch().then(() => {
          def.resolve(model);
        });
      });

      $.when.apply(null, defs).then(() => {
        if (callback) {
          callback(filters.toJSON());
        } else {
          this.publish('event-filters:get:data', filters.toJSON());
        }
      });
    });
  }

  buildChipTerm(payload) {
    const { property, selectedItems, description } = payload;
    return {
      args: selectedItems.map((i) => i.value),
      description: description,
      name: property,
      operator: selectedItems.length === 1 ? 'EQ' : 'IN',
      subject: {
        id: property,
        type: 'dimension',
      },
      valueType: 'TEXT',
    };
  }

  setFilterOperator(operator) {
    this.filter.set('operator', operator);
    this.filter = this.cloneFilter(this.filter);
    this.filter.set(
      'name',
      `Untitled (${this.filter.get('conditionGroup').conditions.length})`
    );

    this.saveAdhocFilter(this.filter);
  }

  addFilterChip(payload) {
    // this.filter.measure = payload.measurementKey;
    const term = this.buildChipTerm(payload);
    console.log('addFilterChip', this.filter);
    this.publish('event-filters:filter:term:added', term);
    this.addTermToFilter(term);
  }

  addTermToFilter(term) {
    this.filter = this.cloneFilter(this.filter);
    const terms = this.filter.get('conditionGroup').conditions;
    terms.push(term);

    this.filter.set('name', `Untitled (${terms.length})`);
    this.saveAdhocFilter(this.filter);
  }

  editFilterChip(payload) {
    const term = this.buildChipTerm(payload);

    this.publish('event-filters:filter:term:edited', term);
    this.editTermFilter(term);
  }

  editTermFilter(term) {
    const terms = this.filter.get('conditionGroup').conditions;
    const index = _.findIndex(terms, (obj) => {
      return obj.subject.id === term.subject.id;
    });

    if (index > -1) {
      this.filter = this.cloneFilter(this.filter);
      // delete term.id;
      terms[index] = term;

      this.filter.set('name', `Untitled (${terms.length})`);
      this.saveAdhocFilter(this.filter);
    }
  }

  removeFilterChip(property) {
    this.publish('event-filters:filter:term:removed', property);
    this.removeTermFilter(property);
  }

  removeTermFilter(property) {
    const terms = this.filter.get('conditionGroup').conditions;
    const index = _.findIndex(terms, (obj) => {
      return obj.subject.id === property;
    });

    if (index > -1) {
      this.filter = this.cloneFilter(this.filter);
      terms.splice(index, 1);

      if (terms.length > 0) {
        this.filter.set('name', `Untitled (${terms.length})`);
        this.saveAdhocFilter(this.filter);
      } else {
        this.filter.reset();
        this.publish('event-filters:filter:changed', this.filter.toJSON());
      }
    }
  }

  resetFilterNoAction() {
    this.filter.reset();
  }

  resetFilter(payload = {}) {
    const { callback, isInitialization } = payload;
    const reset = () => {
      return new Promise((resolve) => {
        this.filter.reset();
        resolve();
      });
    };
    reset().then(() => {
      if (callback) {
        callback();
      }
    });
    this.publish('event-filters:filter:changed', {
      ...this.filter.toJSON(),
      isInitialization,
    });
  }
}

export default FiltersController;

import Backbone from 'backbone';
import _ from 'underscore';
import DashboardModel from 'models/clients/dashboard';
import URL from 'core/utils/url';

export const DashboardCollection = Backbone.Collection.extend({
  model: DashboardModel,

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
  },

  save: function (options) {
    this.sync('update', this, options);
  },

  url: function () {
    return `clients/${this.client}/dashboards`;
  },
});

const DashboardPageableModel = Backbone.Model.extend({
  defaults: {
    totalElements: 0,
    totalPages: 0,
    dashboards: [],
  },

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.externalType = options.externalType;
    this.externalId = options.externalId;
    this.limit = options.limit || 10;
    this.offset = options.offset || 0;
  },

  parse: function (data) {
    data.results = new DashboardCollection(data.dashboards, { parse: true });
    return data;
  },

  toJSON: function () {
    return _.extend(this.attributes, {
      results: this.attributes.results ? this.attributes.results.toJSON() : [],
    });
  },

  getUserIds: function () {
    return _.compact(
      this.get('results').map((result) => {
        return result.get('ownerId');
      })
    );
  },

  setUsers: function (users) {
    this.get('results').forEach((result) => {
      const user = _.findWhere(users, { userId: result.get('ownerId') });
      result.set('ownerUser', user);
    });
  },

  url: function () {
    let url = `clients/${this.client}/dashboards`;
    url = URL.insertParam(url, 'includeHidden', true);
    url = URL.insertParam(url, 'limit', this.limit);
    url = URL.insertParam(url, 'offset', this.offset);
    if (this.externalType) {
      url = URL.insertParam(url, 'externalType', this.externalType);
    }
    if (this.externalId) {
      url = URL.insertParam(url, 'externalId', this.externalId);
    }
    return url;
  },
});

export default DashboardPageableModel;

import Backbone from 'backbone';
import JsonParse from 'core/utils/json-parse';

var InsightSummaryModel = Backbone.Model.extend({
  idAttribute: 'summaryId',

  urlparams: {},

  initialize: function (model, opts) {
    var options = opts || {};

    this.clientId = options.clientId;
    this.urlparams = options.urlparams;
    this.projectId = options.projectId;
  },

  serviceId: 'acs-services',

  url: function () {
    var client = this.clientId;
    var project = this.projectId;

    // If topic provided, get responses for topic, else get all topics
    var topicId = this.topicId || '';
    var topic = topicId ? `/topics/${topicId}` : '/topics';

    // Endpoints -
    // /clients/{clientId}/projects/{projectId}/topics/summary
    // /clients/{clientId}/projects/{projectId}/topics/{topicId}/summary
    return `clients/${client}/projects/${project}${topic}/summary`;
  },

  parse: function (data) {
    var topicQuestions = data.topicQuestions;

    if (topicQuestions && !(topicQuestions instanceof Array)) {
      data.topicQuestions = [topicQuestions];
    } else if (!topicQuestions) {
      data.topicQuestions = [];
    }

    if (data.topicQuestion && data.topicQuestion.feedbackAnswers) {
      var feedback = data.topicQuestion.feedbackAnswers;

      if (!(feedback instanceof Array)) {
        data.topicQuestion.feedbackAnswers = [feedback];
      }
    }
    return JsonParse.stripHtml(data);
  },
});

export default InsightSummaryModel;

import Backbone from 'backbone';

const MasterDataTypeVersionsModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.typeId = options.typeId;
    this.id = options.id;
  },

  url: function () {
    if (this.id) {
      return `clients/${this.client}/masterdatatypes/${this.typeId}/versions/${this.id}`;
    }
    return `clients/${this.client}/masterdatatypes/${this.typeId}/versions`;
  },
});

export default MasterDataTypeVersionsModel;

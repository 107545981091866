const getQueryObject = (uri) => {
  const search = uri || document.location.search;

  if (window.URLSearchParams) {
    const params = new URLSearchParams(search);

    return Array.from(params.entries()).reduce((acc, [key, val]) => {
      acc[key] = val;
      return acc;
    }, {});
  } else {
    return search
      .replace(/(^\?)/, '')
      .split('&')
      .reduce((acc, keyValuePair) => {
        const [key, value] = keyValuePair.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
  }
};

export default getQueryObject;

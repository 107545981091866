import Backbone from 'backbone';
import LatentModel from 'models/clients/measures/latent';

var LatentsCollection = Backbone.Collection.extend({
  model: LatentModel,
  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
    this.isBigData = options.isBigData || false;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/${this.isBigData ? 'surveys' : 'measures'}/${
      this.measure
    }/latents`;
  },
});

export default LatentsCollection;

import BaseController from 'controllers/base';

class RoutesController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'routes:settings:hierarchy:new': '_newHierarchy',
        'routes:settings:hierarchy': '_goToHierarchy',
        'routes:settings:masterdata': '_goToMasterDataType',
      },
      name: 'routes',
      dependsOn: [],
    });
  }

  initialize() {}

  _goToRoute(route) {
    this.publish('route:change', { route: route });
  }

  _newHierarchy(payload) {
    let route = '/settings/setup/hierarchy/new';
    if (payload.id) {
      route = `${route}/${payload.id}`;
    }
    this._goToRoute(route);
  }

  _goToHierarchy(payload) {
    let route = '/settings/setup/hierarchy';
    if (payload.type) {
      route = `${route}/${payload.type}/details`;
    }
    this._goToRoute(route);
  }

  _goToMasterDataType(payload) {
    this._goToRoute(`/settings/setup/masterdata/${payload.type}/records`);
  }
}

export default RoutesController;

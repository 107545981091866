import Backbone from 'backbone';
import _ from 'underscore';
import MeasureModel from 'models/clients/measure';

var MeasuresCollection = Backbone.Collection.extend({
  model: MeasureModel,
  initialize: function (models, opts) {
    var options = opts || {};

    this.client = options.client;
    this.query = '';
  },

  parse: function (data) {
    if (data.length) {
      return _.filter(data, (measure) => {
        return measure.activeStatus !== 'OFF';
      });
    }

    return data;
  },

  getImpacts: function (options, data) {
    var id = options.measure,
      measure = this.get(id);

    if (measure) {
      return measure.getImpacts(options, data);
    }
  },

  getQuestions: function (options, data) {
    var id = options.measure,
      measure = this.findWhere({ measurementKey: id });

    if (measure) {
      return measure.getQuestions(options, data);
    }
  },

  getAnswers: function (options, data) {},

  getLatents: function (options, data) {
    var id = options.measure,
      measure = this.findWhere({ measurementKey: id });

    if (measure) {
      return measure.getLatents(options, data);
    }
  },

  getSLatents: function (options, data) {
    var id = options.measure,
      measure = this.findWhere({ measurementKey: id });

    if (measure) {
      return measure.getSLatents(options, data);
    }
  },

  getBMCats: function (options, data) {
    var id = options.measure,
      measure = this.findWhere({ measurementKey: id });

    if (measure) {
      return measure.getBMCats(options, data);
    }
  },

  getBMCatGroups: function (options, data) {},

  setQuery(query) {
    this.query = query;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures${this.query}`;
  },
});

export default MeasuresCollection;

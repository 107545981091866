import BaseController from 'controllers/base';
import Backbone from 'backbone';
import moment from 'moment';
import constants from 'core/constants';
import Permissions from 'core/permissions';
import TAConfigModel from 'models/clients/text-analytics/config';
import TAKeywordManagerModel from 'models/clients/text-analytics/exclude-keywords';
import TAQuestionTotalsModel from 'models/clients/text-analytics/question-totals';
import CustomFeedModel from 'models/clients/text-analytics/custom-feed';
import CustomFeedsCollection from 'collections/clients/text-analytics/custom-feeds';
import ValidateFeedNameModel from 'models/clients/text-analytics/validate-feed-name';
import JSTimezoneDetect from 'jstimezonedetect';
import featureFlags from 'core/featureFlags';

class TextAnalyticsConfigController extends BaseController {
  constructor() {
    super(arguments, {
      serverRoot: constants.SERVER_ROOT,
      actions: {
        'text-analytics-config:get': 'getConfig',
        'text-analytics-config:set': 'setConfig',
        'text-analytics-config:customFeeds:get': 'getCustomFeeds',
        'text-analytics-config:customFeed:get': 'getCustomFeed',
        'text-analytics-config:customFeedHistory:get': 'getCustomFeedHistory',
        'text-analytics-config:customFeed:post': 'createCustomFeed',
        'text-analytics-config:customFeed:put': 'updateCustomFeed',
        'text-analytics-config:validateFile': 'validateFile',
        'text-analytics-config:uploadFile': 'uploadFile',
        'text-analytics-config:validateFeedName': 'validateFeedName',
        'text-analytics-config:excludeKeywords:get': 'getExcludeKeywords',
        'text-analytics-config:excludeKeywords:create': 'addExcludeKeywords',
        'text-analytics-config:excludeKeywords:delete': 'deleteExcludeKeywords',
      },
      dependsOn: ['user', 'measures'],
      name: 'text-analytics-config',
    });
  }

  initialize(opts) {
    var options = opts || {},
      app = options.app,
      client = this.getClientId();
    this.app = app;
    this.client = client;
    this.options = options;

    this.publish('app:updateData', {
      taEnabled: Permissions.userRole('text_analytics'),
    });
  }

  getConfig(payload = {}) {
    var clientConfig = new TAConfigModel(
      {
        //Model
        clientId: this.client,
      },
      {
        //Options
        showDeleted: payload.showDeleted ? true : false,
      }
    );
    clientConfig.fetch().then(
      () => {
        var data = clientConfig.toJSON();
        this.publish('ta-config:data', data);
      },
      (error) => {
        this.publish('ta-config:error', error);
      }
    );
  }

  setConfig(payload) {
    var clientConfig = new TAConfigModel(
      {
        //Model
        clientId: this.client,
      },
      {
        configId: payload.config.configId,
      }
    );
    payload.clientId = payload.config.clientId = this.client;
    clientConfig.save(
      {
        configs: payload.config,
      },
      {
        doNotAbort: true,
        error: () => {
          if (payload.onError) {
            payload.onError();
          }
          this.publish('app:messageFlash', {
            messageType: 'error',
            messageText: '*A server error occurred*',
          });
        },
      }
    );
  }

  getTypeValues(type = 'measures') {
    let typeValues = {
      projectType: 'CX_MEASURE',
      dateRangeType: 'DATE_ONLY',
    };
    if (type === 'projects') {
      typeValues.projectType = 'FEEDBACK';
      typeValues.dateRangeType = 'DATE_TIME';
    }
    return typeValues;
  }

  getQuestionTotals(options = {}) {
    const { callback, type, questionKey, questionId, measurementKey } = options;
    const typeValues = this.getTypeValues(type);
    const totals = new TAQuestionTotalsModel(
      {
        clientId: this.client,
      },
      {
        projectType: typeValues.projectType,
      }
    );
    const date = {
      c: 'G',
      r: 'M',
      p: 'D',
      n: 0,
      a: new moment().format('YYYY-MM-DD'),
      k: this.client,
      o: true,
      t: typeValues.dateRangeType,
    };
    let criteria = {
      dateRange: date,
      timezone: JSTimezoneDetect.determine().name(),
      measureIds: [measurementKey],
      projectTypes: [typeValues.projectType],
    };

    if (questionId) {
      criteria.questionIds = [questionId];
    }
    if (questionKey) {
      criteria.questionKeys = [questionKey];
    }

    totals.fetch({ data: { criteria: JSON.stringify(criteria) } }).then(
      () => {
        var data = totals.toJSON();
        data.measurementKey = measurementKey;
        data.questionKey = questionKey;
        if (callback) {
          callback(data);
        } else {
          this.publish('text-analytics-config:question:totals:data', data);
        }
      },
      (error) => {
        this.publish('text-analytics-config:question:totals:error', error);
      }
    );
  }

  getCustomFeeds(payload) {
    const options = payload || {};
    const callback = options.callback;
    const customFeeds = new CustomFeedsCollection(null, {
      clientId: this.client,
    });
    customFeeds.urlparams = { showAll: true };
    customFeeds.fetch().then(() => {
      if (callback) {
        callback(customFeeds.toJSON());
      }
    });
  }

  getCustomFeed(payload) {
    var options = payload || {},
      callback = options.callback,
      customFeed = new CustomFeedModel({
        clientId: this.client,
        customFeedId: options.customFeedId,
      });
    customFeed.fetch().then(() => {
      if (callback) {
        callback(customFeed.toJSON());
      }
    });
  }

  getCustomFeedHistory(payload) {
    let options = payload || {},
      callback = options.callback,
      customFeedId = options.customFeedId;

    if (featureFlags.hasFeature('new_ta-admin')) {
      Backbone.sync.oauth.apiRequest(
        `${constants.SERVER_ROOT}ta-admin/customFeedHistory/${customFeedId}`,
        {
          type: 'GET',
          success: (response) => {
            callback(response);
          },
          error: (response) => {
            callback(response);
          },
        }
      );
    } else {
      Backbone.sync.oauth.apiRequest(
        `${constants.SERVER_ROOT}ta-config-beta/customFeedHistory/${customFeedId}`,
        {
          type: 'GET',
          success: (response) => {
            callback(response);
          },
          error: (response) => {
            callback(response);
          },
        }
      );
    }
  }

  createCustomFeed({ callback, description, languageId, name } = {}) {
    const customFeed = new CustomFeedModel({
      clientId: this.client,
      customFeedName: name,
      customFeedDescription: description,
      createdByUser: this.strings.user.name,
      languageId,
    });

    customFeed.save().then(() => {
      if (callback) {
        callback(customFeed.toJSON());
      }
    });
  }

  updateCustomFeed(payload) {
    const options = payload || {};
    const { customFeed, callback } = options;
    const cf = new CustomFeedModel({
      customFeedId: customFeed.customFeedId,
      clientId: this.client,
      customFeedName: customFeed.customFeedName,
      customFeedDescription: customFeed.customFeedDescription,
      processingOffFlag: customFeed.processingOffFlag,
      isVisible: customFeed.isVisible,
      updatedByUser: this.strings.user.name,
    });

    cf.save().then(() => {
      if (callback) {
        callback(cf.toJSON());
      }
    });

    this.publish('dashboards-data:card:reset:wordcloud', {
      customFeedId: customFeed.customFeedId,
    });
  }

  validateFile(payload) {
    let options = payload || {},
      callback = options.callback,
      file = options.file,
      formData = new FormData();
    formData.append('multipartFile', file);

    const app = featureFlags.hasFeature('new_ta-admin')
      ? 'ta-admin'
      : 'ta-config-beta';

    Backbone.sync.oauth.apiRequest(
      `${constants.SERVER_ROOT}${app}/customFeed/validateFile`,
      {
        data: formData,
        multipart: true,
        type: 'POST',
        headers: {
          Accept: 'application/json',
        },
        success: (response) => {
          callback(response);
        },
        error: (response) => {
          callback(response);
        },
      }
    );
  }

  validateFeedName(payload) {
    let options = payload || {},
      callback = options.callback,
      name = options.customFeedName,
      clientId = this.client,
      validateFeedName = new ValidateFeedNameModel();
    validateFeedName.urlparams = { name, clientId };

    validateFeedName.fetch().then((response) => {
      if (callback) {
        callback(response);
      }
    });
  }

  uploadFile(payload) {
    let options = payload || {},
      callback = options.callback,
      file = options.file,
      customFeedId = options.customFeedId,
      formData = new FormData();
    formData.append('multipartFile', file);

    const app = featureFlags.hasFeature('new_ta-admin')
      ? 'ta-admin'
      : 'ta-config-beta';

    Backbone.sync.oauth.apiRequest(
      `${constants.SERVER_ROOT}${app}/uploadCustomFeed/${customFeedId}`,
      {
        data: formData,
        multipart: true,
        type: 'POST',
        headers: {
          Accept: 'application/json',
        },
        success: (response) => {
          callback(response);
        },
        error: (response) => {
          callback(response);
        },
      }
    );
  }

  getExcludeKeywords(payload) {
    const model = new TAKeywordManagerModel({
      clientId: this.client,
    });
    return model.fetch().then(
      (data) => {
        this.publish('text-analytics-config:excludeKeywords:set', data);
      },
      (error) => {
        this.publish('text-analytics-config:totals:error', error);
      }
    );
  }

  addExcludeKeywords(payload) {
    const options = payload || {},
      callback = options.callback,
      model = new TAKeywordManagerModel({
        clientId: this.client,
        excludeKeywords: options.excludeKeywords,
      });

    model.save().then((data) => {
      callback && callback(data);
    });
    this.publish('dashboards-data:card:reset:wordcloud');
    this.publish('keywords:excluded:changed', { filterChanged: true });
  }

  deleteExcludeKeywords(payload) {
    const options = payload || {},
      callback = options.callback,
      model = new TAKeywordManagerModel({
        clientId: this.client,
      });

    model.destroy({
      headers: { Accept: 'application/json' },
      data: JSON.stringify({
        clientId: this.client,
        excludeKeywords: options.excludeKeywords,
        links: [],
      }),
      success: () => {
        callback && callback();
      },
    });
    this.publish('dashboards-data:card:reset:wordcloud');
    this.publish('keywords:excluded:changed', { filterChanged: true });
  }
}

export default TextAnalyticsConfigController;

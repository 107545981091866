import React from 'react';
import ClassNames from 'classnames';
import { EmptyState } from 'cx-component-library';

const EmptyStateWrapper = ({ image = 'table', imageStyle, ...props }) => {
  const imageClass = ClassNames({
    'empty-state__image': image,
    'empty-state__image--table': image === 'table',
    'empty-state__image--survey': image === 'survey',
    'empty-state__image--avb': image === 'avb',
    'empty-state__image--filter': image === 'filter',
    'empty-state__image--table-card': image === 'table-card',
  });

  return (
    <div className='empty-state'>
      <span className={imageClass} style={{ ...imageStyle }} />
      <EmptyState {...props} />
    </div>
  );
};

export default EmptyStateWrapper;

import Backbone from 'backbone';
import constants from 'core/constants';

const UploadProcessesModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.uploadType = options.uploadType || 'combo';
    this.id = options.id;
  },

  _getData: function () {
    var data = new FormData();
    const file = this.get('file');
    data.append('name', file.name);
    data.append('filedata', file);
    data.append('typeName', this.get('typeName'));

    const typeId = this.get('typeId');
    const hierarchyId = this.get('hierarchyId');
    const hierarchyName = this.get('hierarchyName');
    const masterDataId = this.get('masterDataId');
    const icon = this.get('icon');
    const legacy = this.get('legacy');

    if (typeId) {
      data.append('typeId', typeId);
    }
    if (hierarchyId) {
      data.append('hierarchyId', hierarchyId);
    }
    if (hierarchyName) {
      data.append('hierarchyName', hierarchyName);
    }
    if (legacy !== undefined) {
      data.append('legacy', legacy);
    }
    if (masterDataId) {
      data.append('masterDataId', masterDataId);
    }
    if (icon) {
      data.append('icon', icon);
    }
    return data;
  },

  save: function () {
    return Backbone.sync.oauth.apiRequest(constants.SERVER_ROOT + this.url(), {
      data: this._getData(),
      multipart: true,
      type: 'POST',
      headers: {
        Accept: 'application/json',
      },
      success: (data) => {
        this.attributes = data;
      },
    });
  },

  url: function () {
    if (this.id) {
      return `clients/${this.client}/upload/${this.uploadType}/processes/${this.id}`;
    }
    return `clients/${this.client}/upload/${this.uploadType}/processes`;
  },
});

export default UploadProcessesModel;

import Backbone from 'backbone';
import ClientModel from 'models/client';

var ClientCollection = Backbone.Collection.extend({
  model: ClientModel,

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
  },

  /**
   * Uses lastUpdateDateTime to sort collection
   * @param a
   * @param b
   */
  comparator: 'name',

  url: function () {
    return 'clients';
  },
});

export default ClientCollection;

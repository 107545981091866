import BaseController from 'controllers/base';
import _ from 'underscore';
import moment from 'moment';
import JSTimezoneDetect from 'jstimezonedetect';
import Constants from 'core/constants';
import { GET_TEXT_DATA_SOURCES, GET_TEXT_QUESTIONS } from './queries';

const { CX_MEASURE, FEEDBACK, CUSTOM_FEED } =
  Constants.TEXT_ANALYTICS.SOURCE_TYPES;
const { QUESTION_ID, QUESTION_TAG } = Constants.TEXT_ANALYTICS;

class TextAnalyticsController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'user:clients:update': 'initialize',
        'text-analytics:sources:get': 'getTASources',
        'text-analytics:source:set': '_setTASource',
        'text-analytics:source:changed': 'setTASources',
        'tafilters:filter:change': 'setTAFilter',
        'location:change': 'getRoute',
        'hierarchyfilters:filter:changed': 'handleHierarchyFilterChanged',
        'filters:filter:changed': '_handleModelFilterChanged',
        'text-analytics:dashboard:get': 'getDashboard',
        'text-analytics:dateRange:set': '_setDateRange',
      },
      dependsOn: ['measures', 'tafilters', 'dashboards'],
      name: 'text-analytics',
    });

    this.taSelectedSources = [];
    this.currentTASource = null;
    this.isOverviewDashboard = false;
    this.dateFilter = false;
    this.modelFilter = {};
    this.hierarchyFilter = {};
    this.taFilter = {};
    this.configs = [];
  }

  initialize(options = {}) {
    this.client = this.getClientId();
    this.app = options.app;
    this.measure = options.measure;
    this.options = options;
    this.timezone = JSTimezoneDetect.determine().name();
    this.getRoute();

    this._loadFilters();
  }

  async getTASources() {
    this.publish('app:updateData', {
      loadingTASources: true,
      taSources: false,
    });

    this.currentTASource = null;

    const response = await this.apolloClient
      .query({
        errorPolicy: 'all',
        query: GET_TEXT_DATA_SOURCES,
        variables: {
          input: { clientId: this.client },
        },
      })
      .catch(() => {});

    if (!response?.data?.textDataSources?.length) {
      this.publish('app:updateData', {
        loadingTASources: false,
        taSources: false,
      });
      return;
    }

    const {
      taMeasures,
      taFeedbackProjects,
      taCustomFeeds,
      taMeasureQuestions,
      taFeedbackQuestions,
    } = response.data.textDataSources.reduce(
      (acc, dataSource) => {
        switch (dataSource.type) {
          case CX_MEASURE:
            acc.taMeasures.push(dataSource);
            if (dataSource.questions && dataSource.questions.length) {
              acc.taMeasureQuestions.push(
                ...dataSource.questions.map((question) => ({
                  ...question,
                  type: 'CQ',
                }))
              );
            }
            break;

          case FEEDBACK:
            acc.taFeedbackProjects.push(dataSource);
            if (dataSource.questions) {
              acc.taFeedbackQuestions.push(
                ...dataSource.questions.map((question) => ({
                  ...question,
                  type: 'CQ',
                }))
              );
            }
            break;

          case CUSTOM_FEED:
            acc.taCustomFeeds.push(dataSource);
            break;
        }

        return acc;
      },
      {
        taMeasures: [],
        taFeedbackProjects: [],
        taCustomFeeds: [],
        taMeasureQuestions: [],
        taFeedbackQuestions: [],
      }
    );

    let taSources;
    if (
      !taMeasures.length &&
      !taFeedbackProjects.length &&
      !taMeasureQuestions.length
    ) {
      taSources = false;
    } else {
      taSources = {
        taMeasures,
        taFeedbackProjects,
        taCustomFeeds,
        taMeasureQuestions,
        taFeedbackQuestions,
      };

      // Check current URL params and update if necessary
      const selected = this._reconcileParamsWithSources(taSources);

      // Update URL query params with data source ID and questions
      this.updateQuerySelectSources(selected);
      this.setTASources(selected);

      // selected question keys/ids are part of TA filters
      this.publish('dashboards:cards:setTAFilters', {
        taQuestionIds: selected.selectedQuestionIds,
      });
    }

    this.publish('app:updateData', {
      loadingTASources: false,
      taSources,
    });
  }

  async _getQuestionsBySource(source) {
    if (!source) {
      return [];
    }

    if (source.questions) {
      return source.questions;
    }

    const response = await this.apolloClient.query({
      query: GET_TEXT_QUESTIONS,
      variables: {
        input: {
          clientId: this.client,
          dataSourceId: source.id,
          dataSourceType: source.type,
        },
      },
    });

    if (!response.data) {
      return [];
    }

    return response.data.textMeasureQuestions;
  }

  updateQuerySelectSources(payload) {
    if (this.taSelectedSources.length || !payload) {
      this.updateUrlParamsFromSources(this.taSelectedSources);
      return;
    }

    return this._updateURLParams(payload);
  }

  updateUrlParamsFromSources(selectedSources) {
    const filterByType = (type, parentType) => {
      return [...selectedSources]
        .filter(
          (source) => source.type === type && source.parentType === parentType
        )
        .map((source) => source.id);
    };

    const selectedMeasureIds = filterByType('CX_MEASURE');
    const selectedFeedbackIds = filterByType('FEEDBACK');
    const selectedCustomFeedbackIds = filterByType('CUSTOM_FEED');
    const selectedQuestionIds = filterByType('CQ', 'FEEDBACK');

    this._updateURLParams({
      selectedMeasureIds,
      selectedFeedbackIds,
      selectedCustomFeedbackIds,
      selectedQuestionIds,
    });
  }

  _updateURLParams(options) {
    const {
      selectedMeasureIds = [],
      selectedFeedbackIds = [],
      selectedCustomFeedbackIds = [],
    } = options;

    this.publish('route:get', {
      callback: ({ route }) => {
        const payload = {
          clientId: this.client,
          measureIds: JSON.stringify(selectedMeasureIds),
          feedbackIds: JSON.stringify(selectedFeedbackIds),
          customFeedIds: JSON.stringify(selectedCustomFeedbackIds),
        };

        if (/topics/.test(route)) {
          this.changeRoute('textAnalytics.topics', payload);
        } else if (/keywords/.test(route)) {
          this.changeRoute('textAnalytics.keywords', payload);
        }
      },
    });
  }

  setTASources(payload) {
    const {
      selectedMeasureIds,
      selectedFeedbackIds,
      selectedCustomFeedbackIds,
      selectedQuestionIds,
    } = payload;
    const filterUpdatePayload = {};

    if (selectedQuestionIds?.length) {
      filterUpdatePayload.taQuestionIds = selectedQuestionIds;
    }

    this.publish('dashboards:cards:setTAFilters', filterUpdatePayload);

    // Create new taSelectedSources object
    const selectedSources = [];

    if (selectedMeasureIds?.length) {
      selectedSources.push(
        ...selectedMeasureIds.map((id) => ({
          id,
          type: CX_MEASURE,
        }))
      );
      if (selectedQuestionIds?.length) {
        selectedSources.push(
          ...selectedQuestionIds.map((id) => ({
            id,
            type: Constants.TEXT_ANALYTICS.SOURCE_TYPES.CQ,
            parentType: CX_MEASURE,
          }))
        );
      }
    }
    if (selectedFeedbackIds?.length) {
      selectedSources.push(
        ...selectedFeedbackIds.map((id) => ({
          id,
          type: FEEDBACK,
        }))
      );
      if (selectedQuestionIds?.length) {
        selectedSources.push(
          ...selectedQuestionIds.map((id) => ({
            id,
            type: Constants.TEXT_ANALYTICS.SOURCE_TYPES.CQ,
            parentType: FEEDBACK,
          }))
        );
      }
    }
    if (selectedCustomFeedbackIds?.length) {
      selectedSources.push(
        ...selectedCustomFeedbackIds.map((id) => ({
          id,
          type: CUSTOM_FEED,
        }))
      );
    }

    this.publish('app:updateData', {
      taSelectedSources: selectedSources,
    });

    this._updateURLParams({
      selectedMeasureIds,
      selectedFeedbackIds,
      selectedCustomFeedbackIds,
      selectedQuestionIds,
    });
  }

  _setTASource(payload) {
    const { selectedTASource } = payload;
    this.taSelectedSources = [selectedTASource];
  }

  getSelectedSourcesFromQuery() {
    const urlParams = this._getQueryObject();
    const {
      dataSourceKey = '',
      dataSourceType = '',
      measureIds = '[]',
      feedbackIds = '[]',
      customFeedIds = '[]',
      questionIds = '[]',
    } = urlParams;

    let selectedSources;
    if (dataSourceKey && dataSourceType) {
      selectedSources = {
        measureIds: [],
        feedbackIds: [],
        customFeedIds: [],
        questionIds: [],
      };
      switch (dataSourceType) {
        case 'cxmeasure':
          selectedSources.measureIds = [dataSourceKey];
          break;
        case 'feedback':
          selectedSources.feedbackIds = [dataSourceKey];
          break;
        case 'custom':
          selectedSources.customFeedIds = [dataSourceKey];
          break;
      }
    } else {
      try {
        selectedSources = {
          measureIds: JSON.parse(measureIds),
          feedbackIds: JSON.parse(feedbackIds),
          customFeedIds: JSON.parse(customFeedIds),
          questionIds: JSON.parse(decodeURIComponent(questionIds)),
        };
      } catch (e) {
        selectedSources = {
          measureIds: [],
          feedbackIds: [],
          customFeedIds: [],
          questionIds: [],
        };
      }
    }

    if (!this.hasSourcesSelected(selectedSources)) {
      return false;
    }

    return selectedSources;
  }

  hasSourcesSelected(selectedSources) {
    const { measureIds, feedbackIds, customFeedIds, questionIds } =
      selectedSources;

    return (
      measureIds.length ||
      feedbackIds.length ||
      customFeedIds.length ||
      questionIds.length
    );
  }

  _getURLString(type, idsArray) {
    let url = '';
    if (idsArray && idsArray.length > 0) {
      switch (type) {
        case 'CX_MEASURE':
          url = `&measureIds=${idsArray}`;
          break;
        case 'FEEDBACK':
          url = `&feedbackIds=${idsArray}`;
          break;
        case 'CUSTOM':
          url = `&customFeedIds=${idsArray}`;
          break;
        case 'QID':
          url = `&questionIds=${idsArray}`;
          break;
      }
    }
    return url;
  }

  getRoute(payload) {
    this.publish('route:get', {
      callback: (data) => {
        data = data || {};
        const route = data.route || '';
        data = data.pathIds || {};
        this.isOverviewDashboard = /text-analytics\/overview$/.test(route);
        if (data.hasOwnProperty('custom')) {
          let projectID = Number(data.custom);
          if (_.isNumber(projectID) && !isNaN(projectID)) {
            this.currentCustomFeedId = projectID;
          } else {
            this.publish('pagefilters:reset:noaction');
            this.publish('filters:reset:noaction');
            this.modelFilter = {};
            this.hierarchyFilter = {};
            this.pageFilter = {};
          }
        } else if (data.hasOwnProperty('measure')) {
          let projectID = Number(data.measure);
          if (_.isNumber(projectID) && !isNaN(projectID)) {
            this.currentMeasurementKey = projectID;
          } else {
            this.publish('pagefilters:reset:noaction');
            this.publish('filters:reset:noaction');
            this.modelFilter = {};
            this.hierarchyFilter = {};
            this.pageFilter = {};
          }
        }
      },
    });
  }

  setTAFilter(taFilter) {
    this.taFilter = taFilter;

    const taFilterIds = taFilter.filterId ? [taFilter.filterId] : null;
    this._setTAFilterOnDashboard({
      taFilterIds,
    });
  }

  handleHierarchyFilterChanged(filter) {
    this._setTAFilterOnDashboard({
      hierarchyFilterId: filter?.filterId || null,
    });
  }

  _handleModelFilterChanged(filter) {
    this.modelFilter = filter;

    this._setTAFilterOnDashboard({
      modelFilterId: filter.modelFilterId || false,
    });
  }

  _setTAFilterOnDashboard(payload) {
    this.publish('app:getData', {
      key: 'taDashboards',
      callback: (dashboard) => {
        if (dashboard && dashboard.length) {
          this.publish('dashboards:cards:setTAFilters', payload);
        }
      },
    });
  }

  _formatCustomFeedIds(customFeedIds) {
    return customFeedIds.map((customFeedId) => ({
      customFeedId,
    }));
  }

  isDateDurationValid() {
    const { start_date: start, end_date: end } =
      this.controllers.measures.dates;
    return (
      Math.ceil(
        moment(new Date(end)).diff(moment(new Date(start)), 'day', true)
      ) +
        1 <=
      366
    );
  }

  getDashboard(payload) {
    const {
      dataSource,
      dateRange,
      selectedQuestionIds,
      withCards = true,
    } = payload;

    if (!this.currentTASource || this.currentTASource.id !== dataSource.id) {
      this.currentTASource = dataSource;
    }

    // Find specified taDashboard in app data. If not found, getAllDashboards
    // Call getCards once dashboard is loaded
    new Promise((resolve) => {
      const getAllDashboards = () => {
        this.publish('dashboards:get', {
          doNotAbort: true,
          retryIfUninitialized: true,
          externalType: Constants.FILTER_TYPE_TEXT_ANALYTICS,
          externalId: dataSource.id,
          callback: () => {
            resolve();
          },
        });
      };

      this.publish('app:getData', {
        key: 'taDashboards',
        callback: (data) => {
          if (data && data.length) {
            const dashboardFound = data.some(
              (dashboard) =>
                dashboard.externalId.toString() === dataSource.id.toString()
            );

            if (dashboardFound) {
              return resolve();
            }
          }

          getAllDashboards();
        },
      });
    }).then(() => {
      if (withCards) {
        this.getCards({
          dateRange,
          taQuestionIds: selectedQuestionIds,
        });
      }
    });
  }

  /**
   * Use taQuestionIds to fetch card data
   */
  getCards({ dateRange, taQuestionIds }) {
    this.publish('app:getData', {
      key: 'taDashboards',
      callback: async (taDashboards) => {
        const questions = await this._getQuestionsBySource(
          this.currentTASource
        );

        const taQuestions = _.filter(
          questions,
          (q) => q.parentId === this.currentTASource.id
        );
        const payload = {
          dashboardId: taDashboards[0].dashboardId,
          dateRange,
          externalType: Constants.FILTER_TYPE_TEXT_ANALYTICS,
          externalId: this.currentTASource,
          dateFilter: this.dateFilter,
          modelFilterId: this.modelFilter.modelFilterId,
          hierarchyFilterId: this.hierarchyFilter.filterId,
          taFilterIds: this.taFilter.filterId ? [this.taFilter.filterId] : null,
          taQuestions,
          taQuestionIds,
        };

        this.publish('app:getData', {
          key: 'dashboardCards',
          callback: (dashboardCards) => {
            dashboardCards = dashboardCards || {};
            if (dashboardCards[taDashboards[0].dashboardId]) {
              this.publish('dashboards:reload', {
                ...payload,
                cards: dashboardCards[taDashboards[0].dashboardId],
              });
            } else {
              this.publish('dashboards:cards:get', {
                ...payload,
                callback: (cards) => {
                  this.loadingHierarchy = true;
                  this.dateFilter = {
                    dateRange: cards[0].metadata.attributes.dateRange,
                    selectedDateIdId:
                      cards[0].metadata.attributes.selectedDateId,
                  };

                  const dataSourceType =
                    cards[0].metadata.attributes.dataSources[0].type;
                  let taQuestionIds;

                  if (
                    dataSourceType ===
                      Constants.DASHBOARD.textAnalytics.source.cxmeasure ||
                    dataSourceType ===
                      Constants.DASHBOARD.textAnalytics.source.feedback
                  ) {
                    taQuestionIds = payload.taQuestionIds;
                  }

                  this.publish(
                    'text-analytics:loadDashboardFilters',
                    cards.map((card) => ({
                      ...card,
                      metadata: {
                        ...card.metadata,
                        attributes: {
                          ...card.metadata.attributes,
                          taQuestionIds,
                        },
                      },
                    }))
                  );
                },
              });
            }
          },
        });
      },
    });
  }

  _setDateRange(payload) {
    this.publish('dates:lookup', {
      ...payload,
      callback: (range) => {
        delete range.a;
        if (range.r !== 'C') {
          delete range.f;
          delete range.l;
        }
        this.publish('dashboards:cards:setTAFilters', {
          dateRange: range,
          selectedDateId: payload.id || 'custom',
        });
      },
    });
  }

  _loadFilters() {
    this.publish('storage:get', {
      key: 'filter-data',
      callback: (payload) => {
        if (!payload.expired && payload.data && payload.data.data) {
          this.modelFilter = payload.data.data.filter;
        }
      },
    });
  }

  /*
   * Compare URL params with loaded taSources. If sources exist, then set them
   * as selected sources. Otherwise, fall back to picking the first available
   *  CX_MEASURE then FEEDBACK then CUSTOM_FEED.
   */
  _reconcileParamsWithSources(taSources) {
    const { measureIds, feedbackIds, customFeedIds, questionIds } =
      this.getSelectedSourcesFromQuery();

    const [
      validMeasures,
      validFeedbacks,
      validCustomFeeds,
      partialValidQuestionIds,
    ] = [
      [measureIds, CX_MEASURE],
      [feedbackIds, FEEDBACK],
      [customFeedIds, CUSTOM_FEED],
      [questionIds, QUESTION_ID],
    ].map(([ids = [], type]) => {
      let dataSources;
      switch (type) {
        case CX_MEASURE:
          dataSources = taSources.taMeasures;
          break;
        case FEEDBACK:
          dataSources = taSources.taFeedbackProjects;
          break;
        case CUSTOM_FEED:
          dataSources = taSources.taCustomFeeds;
          break;
        case QUESTION_ID:
        case QUESTION_TAG:
          dataSources = [
            ...taSources.taMeasureQuestions,
            ...taSources.taFeedbackQuestions,
          ];
      }

      return ids.filter((id) =>
        dataSources.some((ds) => String(ds.id) === String(id))
      );
    });

    // Valid data source found from URL params
    if (
      validMeasures?.length ||
      validFeedbacks?.length ||
      validCustomFeeds?.length
    ) {
      // if there were no question ids passed, then work out which ones to use
      // based on the datasources.
      let validQuestionIds = partialValidQuestionIds;
      if (!validQuestionIds?.length) {
        const validSourceIds = [...validMeasures, ...validFeedbacks].map((x) =>
          String(x)
        );
        validQuestionIds = [
          ...taSources.taMeasureQuestions,
          ...taSources.taFeedbackQuestions,
        ]
          .filter((q) => validSourceIds.includes(q.parentId))
          .map((q) => q.id);
      }

      return {
        selectedMeasureIds: validMeasures,
        selectedFeedbackIds: validFeedbacks,
        selectedCustomFeedbackIds: validCustomFeeds,
        selectedQuestionIds: validQuestionIds,
      };
    }

    // No valid data sources found in URL so fall back to default data source
    let selected;
    if (taSources.taMeasures.length) {
      const selectedDataSource = taSources.taMeasures.find(
        (source) => source.id && source.questions?.length
      );
      if (selectedDataSource) {
        const { id, questions } = selectedDataSource;
        selected = {
          selectedMeasureIds: [id],
          selectedQuestionIds: questions.map(({ questionId }) => questionId),
        };
      }
    }

    if (!selected && taSources.taFeedbackProjects.length) {
      const selectedDataSource = taSources.taFeedbackProjects.find(
        (source) => source.id && source.questions?.length
      );
      if (selectedDataSource) {
        const { id, questions } = selectedDataSource;
        selected = {
          selectedFeedbacksIds: [id],
          selectedQuestionIds: questions.map(({ questionId }) => questionId),
        };
      }
    }

    if (!selected && taSources.taCustomFeeds.length) {
      selected = {
        selectedCustomFeedbackIds: [taSources.taCustomFeeds[0].id],
      };
    }

    return selected;
  }
}

export default TextAnalyticsController;

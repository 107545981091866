export default {
  layout: 'dashboards',
  controller: 'feedback-survey',
  permissions: 'portal_access',
  icon: 'navDashboards',
  label: 'Dashboards',
  cx: {
    label: 'CX 360',
    layout: 'cx-dashboard',
    controller: 'measures',
  },
  leaderboard: {
    label: 'Leaderboard',
    layout: 'leaderboard-dashboard',
    controller: 'leaderboards',
  },
  ':id': {
    controller: 'feedback-survey',
    layout: 'dashboard',
    card: {
      new: {
        ':id': {
          layout: 'dashboard-detail',
        },
      },
      ':id': {
        layout: 'dashboard-detail',
      },
    },
    schedule: {
      ':id': {
        layout: 'dashboard',
      },
    },
  },
  new: {
    label: 'New Dashboard',
    layout: 'create-new-dashboard',
    controller: 'dashboards',
  },
};

import Backbone from 'backbone';
import DashboardCardModel from 'models/clients/dashboards/card';

export const DashboardCardsCollection = Backbone.Collection.extend({
  model: DashboardCardModel,

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.dashId = options.dashboardId;
  },

  save: function (options) {
    this.sync('update', this, options);
  },

  url: function () {
    return `clients/${this.client}/dashboards/${this.dashId}/cards`;
  },
});

const DashboardCardPageableModel = Backbone.Model.extend({
  defaults: {
    totalElements: 0,
    totalPages: 0,
    cards: [],
  },

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.dashId = options.dashboardId;
  },

  parse: function (data) {
    data.results = new DashboardCardsCollection(data.cards, {
      parse: true,
    }).toJSON();
    return data;
  },

  url: function () {
    return `clients/${this.client}/dashboards/${this.dashId}/cards?limit=50`;
  },
});

export default DashboardCardPageableModel;

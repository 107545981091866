import BaseController from 'controllers/base';
import TemplatesCollection from 'collections/clients/notifications/templates';
import TemplateModel from 'models/clients/notifications/template';

// Class that deals with CMS Notifications Microservice
class NotificationsController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'notifications:templates:get': '_getTemplates',
        'notifications:template:get': '_getTemplate',
        'notifications:template:delete': '_deleteTemplate',
        'notifications:post': '_post',
      },
      name: 'notifications',
      dependsOn: ['user'],
    });
  }

  initialize() {}

  _getTemplates(payload) {
    var options = payload || {},
      callback = options.callback || null,
      templateType = options.templateType || null,
      data = false;

    var model = new TemplatesCollection(false, {
      tenantId: this.getClientId(),
      templateType: templateType,
    });

    model.fetch().then(() => {
      data = model.toJSON();
      if (callback) {
        callback(data);
      } else {
        this.publish('notifications:templates:data', data);
      }
    });
  }

  _getTemplate(payload) {
    var options = payload || {},
      id = options.id || null,
      callback = options.callback || null;

    var model = new TemplateModel(false, {
      tenantId: this.getClientId(),
      id: id,
    });

    model.fetch().then(() => {
      if (callback) {
        callback(model.toJSON());
      }
    });
  }

  _deleteTemplate(payload) {
    var options = payload || {},
      id = options.id || null,
      callback = options.callback || null;

    var model = new TemplateModel(
      {
        id: id,
      },
      {
        tenantId: this.getClientId(),
        id: id,
      }
    );

    model.destroy({
      success: () => {
        console.log('model deleted', model);
        if (callback) {
          callback(model.toJSON());
        }
      },
    });
  }

  _post(payload) {
    this.publish('user:get', {
      callback: (data) => {
        const options = payload || {};
        const template = {
          active: true,
          content: window.btoa(options.content),
          customAttributes: {},
          eventName: options.eventName,
          userId: data.user.username,
          name: window.btoa(options.name),
          templateType: options.templateType,
          tenantId: this.getClientId(),
        };

        if (options.id) {
          template.id = options.id;
        }
        const model = new TemplateModel(template, {
          tenantId: this.getClientId(),
        });

        model.save().then(() => {
          if (options.callback) {
            options.callback(model.toJSON());
          }
        });
      },
    });
  }
}

export default NotificationsController;

import Backbone from 'backbone';

const HierarchyAttributeModel = Backbone.Model.extend({
  headers: {
    Accept: 'application/json',
  },

  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.hierarchyId = options.hierarchyId;
    this.attributeName = options.attributeName;
  },

  url: function () {
    return `clients/${this.client}/hierarchies/${this.hierarchyId}/attributes/${this.attributeName}`;
  },
});

export default HierarchyAttributeModel;

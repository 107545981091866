import Backbone from 'backbone';

const DashboardCardCopyModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.dashId = options.dashboardId;
    this.cardId = options.cardId;
    this.destinationDashboard = options.destinationDashboard;
  },

  url: function () {
    return `clients/${this.client}/dashboards/${this.dashId}/cards/${this.cardId}/copy?destinationDashboard=${this.destinationDashboard}`;
  },
});

export default DashboardCardCopyModel;

import React from 'react';
import classNames from 'classnames';
import Clipboard from 'clipboard';
import BaseView from 'views/base';
import dispatcher from 'core/dispatcher';
import { Text } from 'cx-component-library';

class FormButtonView extends BaseView {
  constructor(props) {
    super(props);
    this.clipboard = false;
    this.buttonRef = false;
    this.state = {};
    this._dispatcher = dispatcher;
  }

  publish(action, payload) {
    this._dispatcher.trigger(action, payload);
  }

  componentDidMount() {
    if (this.props.clipboardText) {
      this.clipboard = new Clipboard(this.buttonRef);

      this.clipboard.on(
        'success',
        function (e) {
          e.clearSelection();
          if (this.props.clipboardCallback) {
            this.props.clipboardCallback(true);
          }
        },
        this
      );

      this.clipboard.on('error', function (e) {
        //not supported on Safari so fallback to be used here
        if (this.props.clipboardCallback) {
          this.props.clipboardCallback(false);
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.props.clipboardText) {
      this.clipboard.destroy();
    }
  }

  render() {
    var children = this.props.label ? this.props.label : this.props.children,
      classes = classNames({
        'ui-icon--small': this.props.ui_icon_small,
        'ui-icon--large': this.props.ui_icon_large,
        'ui-icon': this.props.ui_icon,
        'voc-icon--small': this.props.icon_small,
        'voc-icon--large': this.props.icon_large,
        'voc-icon': this.props.icon,
        button:
          (!this.props.ui_icon && !this.props.icon) ||
          !this.props.style === 'transparent',
        'button--disabled': this.props.disabled,
        'button--discouraged': this.props.discouraged,
        'button--small': this.props.size === 'small',
        'button--medium': this.props.size === 'medium',
        'button--large': this.props.size === 'large',
        'button--wide': this.props.size === 'wide',
        'button--primary': this.props.style === 'primary',
        'button--secondary': this.props.style === 'secondary',
        'button--tertiary': this.props.style === 'tertiary',
        'button--inverse': this.props.style === 'inverse',
        'button--transparent': this.props.style === 'transparent',
        'button--warning': this.props.style === 'warning',
        'button--warningtertiary': this.props.style === 'warningtertiary',
        'button--back': this.props.style === 'back',
        'button--clear': this.props.style === 'clear',
        'button--saved': this.props.style === 'saved',
        active: this.props.selected === true,
      });

    if (this.props.ui_icon) classes += ' ui-icon--' + this.props.ui_icon;
    if (this.props.icon) classes += ' voc-icon--' + this.props.icon;

    classes += ' ' + this.props.className;

    return (
      <button
        className={classes}
        id={this.props.id}
        name={this.props.name}
        type={this.props.type}
        value={this.props.value}
        disabled={this.props.disabled}
        onClick={this._handleClick.bind(this)}
        tabIndex={this.props.tabIndex}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onMouseOver={super.handleMouseOver.bind(this)}
        onMouseOut={super.handleMouseOut.bind(this)}
        data-clipboard-text={this.props.clipboardText}
        ref={(ref) => (this.buttonRef = ref)}
        style={this.props.styles}
      >
        <Text theme='inherit'>{children}</Text>
      </button>
    );
  }

  _handleClick(e) {
    // If we have an action, dispatch it and prevent default nav
    if (this.props.action) {
      e.preventDefault();
      this.publish(this.props.action, this.props.payload);
    }
    super.handleClick(e);
  }
}
FormButtonView.defaultProps = {
  name: null,
  type: 'button',
  style: '',
  styles: {},
  value: null,
  label: null,
  tabIndex: null,
  disabled: null,
  id: null,
  className: '',
  onClick: null,
  selected: null,
  clipboardText: null,
  clipboardCallback: () => {},
};

export default FormButtonView;

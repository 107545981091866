import layouts from 'core/layouts';
import layoutsLux from 'core/layouts-lux';
import tabConfig from 'core/tabConfig';
import getSettingsRoutes from 'core/routes/settings';
import getBenchmarksRoutes from 'core/routes/benchmarks';
import dashboardRoutes from 'core/routes/dashboards';
import createRoutes from 'core/routes/create';
import passwordRoutes from 'core/routes/password';
import projectsRoutes from 'core/routes/projects';
import measuresRoutes from 'core/routes/measures';
import hierarchyRoutes from 'core/routes/hierarchy';
import alertsRoutes from 'core/routes/alerts';
import analyzeRoutes from 'core/routes/analyze';
import getPrimaryRoutes from 'core/routes/primary';
import getBrowserOptions from 'core/browserOptions';
import getDatasourcesRoutes from 'core/routes/datasources';
import dbaRoutes from 'core/routes/dba';
import i18n from 'core/i18n';

const getConfig = () => {
  let configuration = {
    maintenance: {
      dates: [
        new Date(2016, 6, 23), // July 23, 2016
        new Date(2016, 6, 30), // July 30, 2016
      ],
    },
    // primary navigation tabs
    primaryTabs: [
      {
        routes: getPrimaryRoutes(),
      },
    ],
    routes: {
      'survey-management': {
        isAuthRequired: false,
        label: i18n.getString('siteNav.surveyManagement'),
        icon: 'editReport',
      },
      dba: dbaRoutes,
      login: {
        isAuthRequired: false,
        layout: 'authenticate',
        controller: 'authenticate',
      },
      password: passwordRoutes,
      chart: {
        isAuthRequired: false,
        layout: 'chart',
        controller: 'authenticate',
      },
      profile: {
        layout: 'user-profile',
        controller: 'authorized-measure',
        permissions: 'portal_access',
      },
      dashboards: dashboardRoutes,
      settings: getSettingsRoutes(),
      analyze: analyzeRoutes(),
      leaderboard: {
        layout: 'leaderboard-dashboard',
        controller: 'leaderboards',
        permissions: 'portal_access',
        icon: 'navLeaderboard',
        label: 'Leaderboard',
      },
      benchmarks: getBenchmarksRoutes(),
      create: createRoutes,
      projects: projectsRoutes,
      measures: measuresRoutes,
      hierarchy: hierarchyRoutes,
      alerts: alertsRoutes,
      error: {
        layout: 'server-error',
        controller: 'authenticate',
        isAuthRequired: false,
        permissions: 'error',
      },
    },
    defaultLayout: 'dashboards',
    defaultRoute: 'dashboards',
    layouts: layouts,
    layoutsLux: layoutsLux,
    tabs: tabConfig,
    browserOptions: getBrowserOptions(),
  };

  const datasourcesRoutes = getDatasourcesRoutes();
  if (datasourcesRoutes) {
    configuration.routes.datasources = datasourcesRoutes;
  }

  return configuration;
};

export default getConfig;

import i18n from 'core/i18n';
import _ from 'underscore';

const _strings = i18n.strings;

export default {
  /**
   *  Given a string defined with interpolation tokens of the form ${token},
   *  resolve those tokens from the properties of a provided object.
   *
   *
   */
  getTemplatedString(string, tokens) {
    var template = this.getString(string);

    _.templateSettings = {
      interpolate: /\$\{(.+?)\}/g,
    };

    if (string && tokens) {
      return _.template(template)(tokens);
    } else {
      return '';
    }
  },

  /**
   * Given a string of form 'a.b.c' will return value from that location in strings.
   * @param string
   */
  getString(string, model) {
    let fn = [].constructor.constructor('return arguments[0].' + string);
    let str = '';

    if (string) {
      try {
        str = fn(_strings);
        _.templateSettings = {
          interpolate: /\$\{(.+?)\}/g,
        };
        return model ? _.template(str)(model) : str;
      } catch (e) {
        return '**_' + string + '_**';
      }
    } else {
      return '';
    }
  },
};

export default {
  controller: 'dashboards',
  layout: 'dba',
  replay: {
    ':id': {
      session: {
        ':id': {
          layout: 'dba-replay',
        },
      },
    },
  },
};

import BaseController from 'controllers/base';
import _ from 'underscore';
import Constants from 'core/constants';
import TreeModel from 'models/tree';
import HierarchyCollection from 'collections/clients/hierarchies';
import HierarchyModel from 'models/clients/hierarchies';
import HierarchyVersionsCollection from 'collections/clients/hierarchies/versions';
import HierarchyVersionsModel from 'models/clients/hierarchies/versions';
import HierarchyDraftsCollection from 'collections/clients/hierarchies/drafts';
import HierarchyDraftsModel from 'models/clients/hierarchies/drafts';
import HierarchyAttributeModel from 'models/clients/hierarchies/attribute';
import HierarchyTreeModel from 'models/clients/hierarchies/tree';
import HierarchyAncestryModel from 'models/clients/hierarchies/ancestry';
import HierarchyAncestriesModel from 'models/clients/hierarchies/ancestries';
import HierarchyAssociationsCollection from 'collections/clients/hierarchies/associations';
import HierarchyAssociationModel from 'models/clients/hierarchies/association';
import HierarchyVerticesModel from 'models/clients/hierarchies/vertices';

class HierarchyController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'hierarchy:get': '_get',
        'hierarchy:post': '_post',
        'hierarchy:versions:get': '_getVersions',
        'hierarchy:versions:post': '_postVersion',
        'hierarchy:drafts:get': '_getDrafts',
        'hierarchy:drafts:post': '_postDrafts',
        'hierarchy:drafts:delete': '_deleteDraft',
        'hierarchy:levels:get': '_getLevels',
        'hierarchy:tree:get': '_getTree',
        'hierarchy:ancestry:get': '_getAncestry',
        'hierarchy:ancestries:get': '_getAncestries',
        'hierarchy:associations:get': '_getAssociations',
        'hierarchy:associations:post': '_postAssociations',
        'hierarchy:vertices:get': '_getHierarchyVertices',
        'hierarchy:vertices:filter': '_filterHierarchyVertices',
        'hierarchy:title:update': '_updateHierarchyTitle',
      },
      name: 'hierarchy',
      dependsOn: ['user', 'master-data', 'uploads', 'files'],
    });
  }

  initialize(opts) {
    let options = opts || {};
    options.clientId = this.getClientId();
    this.fetchedTrees = {};
  }

  _get(payload) {
    const { id, error, callback, populateUsers } = payload;

    let options = {
      clientId: this.getClientId(),
      offset: 0,
    };

    const pages = 25;

    let model = new HierarchyCollection(false, options);
    if (id) {
      options.id = id;
      model = new HierarchyModel(false, options);
    }

    const handler = () => {
      if (populateUsers) {
        this.publish('authorization:users:get', {
          ids: model.getUserIds(),
          callback: (users) => {
            model.setUsers(users);
            this.dispatch(callback, 'hierarchy:data', model.toJSON());
          },
        });
      } else {
        this.dispatch(callback, 'hierarchy:data', model.toJSON());
      }
    };

    const errorHandler = (r, res) => {
      this.dispatch(callback, 'hierarchy:data', model.toJSON());

      if (error) {
        error(r, res);
      }
    };

    const pager = () => {
      if (model.get('hasMore')) {
        const results = model.get('results');
        model.offset = model.offset + pages;
        model
          .fetch({
            doNotAbort: true,
            error: errorHandler,
            headers: { Accept: 'application/json' },
          })
          .then(() => {
            const newResults = model.get('results');

            if (newResults.length > 0) {
              newResults.forEach((element) => {
                results.push(element, { merge: true });
              });
            }

            model.set('results', results);
            pager();
          });
      } else {
        handler();
      }
    };

    model
      .fetch({
        doNotAbort: true,
        error: errorHandler,
        headers: { Accept: 'application/json' },
      })
      .then(pager);
  }

  _getVersions(payload) {
    const { id, hierarchyId, callback, populateUsers } = payload;

    let options = {
      clientId: this.getClientId(),
      hierarchyId: hierarchyId,
    };

    let model = false;
    if (id) {
      options.id = id;
      model = new HierarchyVersionsModel(false, options);
    } else {
      options.limit = payload.limit;
      options.offset = payload.offset;
      model = new HierarchyVersionsCollection(false, options);
    }

    const handler = () => {
      if (populateUsers) {
        this.publish('authorization:users:get', {
          ids: model.getUserIds(),
          callback: (users) => {
            model.setUsers(users);
            this._dispatch(callback, 'hierarchy:versions:data', model.toJSON());
          },
        });
      } else {
        this._dispatch(callback, 'hierarchy:versions:data', model.toJSON());
      }
    };
    model
      .fetch({
        error: () => {
          this._dispatch(callback, 'hierarchy:versions:data', model.toJSON());
        },
      })
      .then(handler);
  }

  _dispatch(callback, action, payload) {
    if (callback) {
      callback(payload);
    } else {
      this.publish(action, payload);
    }
  }

  _getDrafts(payload) {
    const { id, hierarchyId, callback, populateUsers } = payload;

    let options = {
      clientId: this.getClientId(),
      hierarchyId: hierarchyId,
    };

    let model = false;
    if (id) {
      options.id = id;
      model = new HierarchyDraftsModel(false, options);
    } else {
      options.limit = payload.limit;
      options.offset = payload.offset;
      model = new HierarchyDraftsCollection(false, options);
    }

    const handler = () => {
      if (populateUsers) {
        this.publish('authorization:users:get', {
          ids: model.getUserIds(),
          callback: (users) => {
            model.setUsers(users);
            this._dispatch(callback, 'hierarchy:drafts:data', model.toJSON());
          },
        });
      } else {
        this._dispatch(callback, 'hierarchy:drafts:data', model.toJSON());
      }
    };

    model
      .fetch({
        error: () => {
          this._dispatch(callback, 'hierarchy:drafts:data', model.toJSON());
        },
      })
      .then(handler);
  }

  _deleteDraft(payload) {
    console.log('delete draft', payload);
    const model = new HierarchyDraftsModel(
      {
        draftId: payload.draftId,
      },
      {
        clientId: this.getClientId(),
        hierarchyId: payload.hierarchyId,
        id: payload.draftId,
      }
    );
    model.destroy({
      headers: { Accept: 'application/json' },
    });
  }

  _post(payload) {
    console.log('_post MasterDataController', payload);
    this._saveMasterDataRecord(true, payload, {});
  }

  _postVersion(payload) {
    console.log('_postVersion', payload);
    let { version, hierarchyId, callback } = payload;
    version.id = null;

    const model = new HierarchyVersionsModel(false, {
      clientId: this.getClientId(),
      hierarchyId: hierarchyId,
    });

    model
      .save(version, {
        headers: { Accept: 'application/json' },
      })
      .then(() => {
        if (callback) {
          callback(model.toJSON());
        }
      });
  }

  _getAttribute(payload) {
    const { capabilityName, attributeName, hierarchyId, callback, action } =
      payload;

    const model = new HierarchyAttributeModel(false, {
      clientId: this.getClientId(),
      hierarchyId,
      attributeName,
    });

    const actionText = action || 'hierarchy:attribute:data';
    const handler = () => {
      this._dispatch(callback, actionText, model.toJSON());
    };

    _.extend(model, {
      urlparams: {
        capabilityName: capabilityName,
      },
    });

    model.fetch({ error: handler }).then(handler);
  }

  _getLevels(payload) {
    const { callback, capabilityName, hierarchyId } = payload;

    this._getAttribute({
      action: 'hierarchy:attribute:levels:data',
      capabilityName: capabilityName,
      attributeName: Constants.ATTRIBUTES.LEVEL,
      hierarchyId: hierarchyId,
      callback,
    });
  }

  /**
   * Fetches a hierarchy tree
   *
   * A in memory array of fetched trees is saved for improved performance, while
   * a tree is being fetched, we set it to `true` and after to the tree itself
   *
   * @param {Object} payload Properties for hierarchy tree call
   * @param {Functon} payload.accessType Access Type
   * @param {Functon} payload.callback The callback that handles the response
   * @param {Functon} payload.capabilityName Capability Name
   * @param {Functon} payload.depth How many levels deep are we fetching, max: 4
   * @param {Functon} payload.filterPredicate
   * @param {Functon} payload.hierarchyId The hierarchy to fetch the tree
   * @param {Functon} payload.startNodeId Node to start fetching from instead of the trunk of the tree
   */
  _getTree(payload) {
    const {
      callback,
      error,
      hierarchyId,
      capabilityName,
      filterPredicate,
      startNodeId,
      accessType,
      depth,
    } = payload;
    const nodeKey = startNodeId ? startNodeId : 'root';
    const hierarchyKey = accessType
      ? `${hierarchyId}_${accessType}`
      : hierarchyId;
    if (!this.fetchedTrees[hierarchyKey]) {
      this.fetchedTrees[hierarchyKey] = { [nodeKey]: true };
    } else if (!this.fetchedTrees[hierarchyKey][nodeKey]) {
      this.fetchedTrees[hierarchyKey][nodeKey] = true;
    } else if (
      // This request was started but it hasn't finished yet, let's wait
      this.fetchedTrees[hierarchyKey][nodeKey] === true
    ) {
      return;
    } else {
      //Don't fetch a tree that's already been fetched.
      this._hierarchyHandler({
        model: this.fetchedTrees[hierarchyKey][nodeKey],
        callback,
      });
      return;
    }

    const model = new HierarchyTreeModel(
      { filterPredicate },
      {
        clientId: this.getClientId(),
        hierarchyId,
        capabilityName,
        startNodeId,
        accessType,
        depth,
      }
    );

    model
      .save(
        {
          error: this._hierarchyHandler.bind(this, { model, callback }),
          headers: { 'Content-Type': 'application/json' },
        },
        {
          doNotAbort: payload.doNotAbort,
          error,
        }
      )
      .then(() => {
        this.fetchedTrees[hierarchyKey][nodeKey] = model;
        return this._hierarchyHandler({ model, callback });
      });
  }

  _hierarchyHandler({
    model = { get: () => false },
    callback = () => {},
    onError = () => {},
  }) {
    if (!model.get(0)) {
      onError({
        sorryText: this.getString('general.searchableMenu.sorry'),
        tryText: this.getString('general.searchableMenu.try'),
      });
      return;
    }

    model.unset('filterPredicate');
    model.unset('annotateAccess');
    model.unset('error');
    model.unset('headers');
    let tree = model.get(0);
    if (model.get(1)) {
      const children = model.toJSON();
      tree = {
        value: '-1',
        label: 'Root',
        isDummyNode: true,
        attributes: {},
        children: Object.values(children),
      };
    }
    const getLevelName = (node) => {
      const attributes = node.get('attributes');
      return attributes ? attributes.__level__ : '';
    };
    this._dispatch(
      callback,
      'hierarchy:tree:data',
      _.extend(
        {
          tree: new TreeModel(tree, {
            callback: (node) => {
              //a lot of hierarchy code already written in UI uses id and name instead of value and label
              node.set({
                id: node.value ? node.value : node.get('value'),
                name: node.label ? node.label : node.get('label'),
                levelName: getLevelName(node),
              });
            },
          }),
        },
        model.toJSON()
      )
    );
  }

  _getAncestry(payload) {
    const { hierarchyId, nodeId, capabilityGroup, callback } = payload;

    let options = {
      clientId: this.getClientId(),
      hierarchyId,
      nodeId,
      capabilityGroup,
    };

    let model = new HierarchyAncestryModel(false, options);
    model.fetch().then((ancestry) => {
      if (callback) {
        callback(ancestry);
      }
    });
  }

  _getAncestries(payload) {
    const { hierarchyId, onError, callback, ...rest } = payload;

    let options = {
      clientId: this.getClientId(),
      hierarchyId,
    };

    let model = new HierarchyAncestriesModel(false, options);

    model
      .save(rest, {
        error: (error) => {
          if (error._ajaxRef.status === 500) {
            onError({ sorryText: this.getString('general.errors.default') });
          } else {
            this.publish('app:responseStatus', error._ajaxRef);
          }
        },
      })
      .then(() => this._hierarchyHandler({ model, callback, onError }));
  }

  _getAssociations(payload) {
    const { hierarchyId, capabilityGroup, callback } = payload;

    let options = {
      clientId: this.getClientId(),
      capabilityGroup,
      hierarchyId,
    };

    let model = new HierarchyAssociationsCollection(false, options);
    model
      .fetch({
        error: (payload, response) => {
          if (
            response.responseJSON &&
            response.responseJSON.errorCode !== 'MISSING_PRODUCT_ASSOCIATION' &&
            response.responseJSON.errorCode !== 'MISSING_ASSOCIATIONS_FOR_TYPE'
          ) {
            //This error code means there are no associations yet, so we shouldn't display a server error.
            this.publish('app:messageFlash', {
              messageType: 'error',
              messageText: '*A server error occurred*',
            });
          }
          this._dispatch(callback, 'hierarchy:associations:data', {
            hierarchy: [],
          });
        },
      })
      .then(() => {
        this._dispatch(callback, 'hierarchy:associations:data', model.toJSON());
      });
  }

  _postAssociations(payload) {
    let { capabilityGroup, hierarchyId, callback, config } = payload;

    const model = new HierarchyAssociationModel(
      {
        id: hierarchyId,
        config: config,
      },
      {
        product: capabilityGroup,
        clientId: this.getClientId(),
      }
    );

    model
      .save(null, {
        type: 'POST',
        error: () => {
          this.publish('app:messageFlash', {
            messageType: 'error',
            messageText: '*A server error occurred*',
          });
          this._dispatch(callback, '', {});
        },
      })
      .then(() => {
        if (callback) {
          callback(model.toJSON());
        }
      });
  }

  _filterHierarchyVertices(payload) {
    const { hierarchyId, nodeIds, limit, offset, callback } = payload;

    const model = new HierarchyVerticesModel(false, {
      clientId: this.getClientId(),
      hierarchyId,
      limit: limit || 25,
      offset: offset || 0,
    });

    const filterPredicate = {
      conditionGroup: {
        conditionGroupType: 'HIERARCHY_FILTER',
        operator: 'AND',
        conditions: [
          {
            subject: {
              type: 'HIERARCHY_ATTRIBUTE',
              id: '__value__',
            },
            operator: 'IN',
            valueType: 'TEXT',
            args: nodeIds,
          },
        ],
      },
    };

    model.save(filterPredicate).then(() => {
      this._dispatch(callback, 'hierarchy:vertices:data', model.toJSON());
    });
  }

  _getHierarchyVertices(payload) {
    const { limit, offset, hierarchyId, hierarchyLevel, searchTerm, callback } =
      payload;

    const model = new HierarchyVerticesModel(false, {
      clientId: this.getClientId(),
      hierarchyId,
      limit: limit || 25,
      offset: offset || 0,
    });

    const conditions = [];

    if (hierarchyLevel) {
      conditions.push({
        subject: {
          type: 'HIERARCHY_LEVEL',
          id: hierarchyLevel,
        },
        operator: 'ANY',
        valueType: 'TEXT',
      });
    }

    if (searchTerm) {
      conditions.push({
        subject: {
          type: 'HIERARCHY_ATTRIBUTE',
          id: '__label__',
        },
        operator: 'LIKE',
        valueType: 'TEXT',
        args: [searchTerm],
      });
    }

    const filterPredicate = conditions.length
      ? {
          conditionGroup: {
            conditionGroupType: 'HIERARCHY_FILTER',
            operator: 'AND',
            conditions: conditions,
          },
        }
      : {};

    model.save(filterPredicate).then(() => {
      this._dispatch(callback, 'hierarchy:vertices:data', model.toJSON());
    });
  }

  _updateHierarchyTitle(payload) {
    const { id, callback, name } = payload;

    let options = {
      clientId: this.getClientId(),
      name: name,
      id: id,
    };

    let model = new HierarchyCollection(false, options);
    if (id) {
      options.id = id;
      model = new HierarchyModel(false, options);
    }
    model.save(options, {
      patch: true,
      error: () => {
        this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*A server error occurred*',
        });
        this._dispatch(callback, '', {});
      },
    });
  }
}

export default HierarchyController;

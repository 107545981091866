import Constants from 'core/constants';
import BaseController from 'controllers/base';
import TAFilterModel from 'models/clients/text-analytics/ta-filter';

const { SENTIMENT_FILTER, TOPIC_FILTER } = Constants.TEXT_ANALYTICS;
const { CX_MEASURE, FEEDBACK, CUSTOM_FEED } =
  Constants.TEXT_ANALYTICS.SOURCE_TYPES;

/**
 * Controller for manipulating TA filters
 */
class TAFiltersController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'tafilters',
      actions: {
        'tafilters:filter:create': 'createFilter',
        'tafilters:filter:get': 'getFilter',
        'tafilters:filter:update': 'updateFilter',
        'tafilters:filter:add': 'addFilter',
        'tafilters:filter:delete': 'deleteFilter',
        'tafilters:filter:data': 'setFilterData',
        'tafilters:filter:reset': 'resetFilter',
      },
      dependsOn: ['user'],
    });
  }

  initialize(opts) {
    this.client = this.getClientId();
  }

  async getFilter(payload) {
    const { filterId, measureId, customFeedId, projectId, callback } = payload;
    const options = {
      clientId: this.client,
      measureId: measureId,
      customFeedId: customFeedId,
      projectId: projectId,
      filterId: filterId,
    };
    const filter = new TAFilterModel(options);

    try {
      await filter.fetch({
        doNotAbort: true,
        error: () => false,
      });

      if (callback) {
        callback(filter.toJSON());
      } else {
        this.publish('taFilters:filter:update', filter.toJSON());
        this.publish('tafilters:filter:data', filter.toJSON());
      }
    } catch (e) {
      this.publish('tafilters:filter:change', {});
      this.publish('taFilters:filter:update', {});
      this.publish('tafilters:filter:data', {});
    }
  }

  createFilter(payload) {
    const filterParams = this.buildFilter(payload);
    const filter = new TAFilterModel(filterParams);
    filter.save().then(() => {
      const filterData = filter.toJSON();
      this.publish('tafilters:filter:data', filterData);
      this.publish('tafilters:filter:change', filterData);
      this.publish('taFilters:filter:update', filterData);
    });
  }

  async addFilter(payload) {
    const currentFilterParams = this.buildFilter(payload);
    let filter = new TAFilterModel(currentFilterParams);

    if (payload?.filterParams) {
      payload.filterParams.filterId = null;
      const { created, lastUpdated, ...filterParams } = payload.filterParams;
      const updatedFilterParams = { ...currentFilterParams, ...filterParams };
      filter = new TAFilterModel(updatedFilterParams);
    }

    filter.save().then(() => {
      const filterData = filter.toJSON();
      this.publish('tafilters:filter:data', filterData);
      this.publish('tafilters:filter:change', filterData);
      this.publish('taFilters:filter:update', filterData);
    });
  }

  async updateFilter(payload) {
    let filterData = {};

    if (payload?.filterParams) {
      const { created, lastUpdated, ...filterParams } = payload.filterParams;
      const filter = new TAFilterModel(filterParams);

      await filter.save();

      filterData = filter.toJSON();
    }

    this.publish('tafilters:filter:change', filterData);
    this.publish('taFilters:filter:update', filterData);
    this.publish('tafilters:filter:data', filterData);
  }

  async deleteFilter(payload) {
    const { callback, filterParams } = payload;
    const filter = new TAFilterModel(filterParams);

    await filter.destroy({ doNotAbort: true });

    this.publish('tafilters:filter:change', {});
    this.publish('taFilters:filter:update', {});
    this.publish('tafilters:filter:data', {});

    if (callback) {
      callback();
    }
  }

  buildFilter(payload) {
    const { itemType, selectedItems, measureId, customFeedId, projectId } =
      payload;
    const selectedValues = selectedItems.map((item) => item.value);
    const selectedLabels = selectedItems.map((item) => item.label);
    const subjectIdByType = {
      Sentiment: SENTIMENT_FILTER,
      Topics: TOPIC_FILTER,
    };

    const filterParams = {
      clientId: this.client,
      measureId,
      customFeedId,
      projectId,
      global: false,
      shared: false,
      temp: false,
      deleted: false,
      conditionGroup: {
        name: 'TEXT ANALYTICS FILTER',
        conditionGroupType: Constants.FILTER_TYPE_TEXT_ANALYTICS,
        operator: Constants.OPERATOR_AND,
        conditions: [
          {
            name: selectedLabels.toString(),
            description: itemType,
            subject: {
              type: itemType,
              id: subjectIdByType[itemType],
            },
            operator: 'IN',
            valueType: 'LIST',
            args: selectedValues,
          },
        ],
      },
    };

    return filterParams;
  }

  setFilterData(filterData = {}) {
    const { filterId, customFeedId, measureId, projectId } = filterData;
    const storeData = { filterId };

    if (measureId || projectId || customFeedId) {
      if (measureId) {
        storeData.dataSourceId = measureId;
        storeData.dataSourceType = CX_MEASURE;
      } else if (projectId) {
        storeData.dataSourceId = projectId;
        storeData.dataSourceType = FEEDBACK;
      } else if (customFeedId) {
        storeData.dataSourceId = customFeedId;
        storeData.dataSourceType = CUSTOM_FEED;
      }

      this.publish('route:get', {
        callback: (data) => {
          if (data.route.startsWith('analyze')) {
            this.publish('app:updateData', {
              taFilterId: filterData.filterId,
              callback: () => {
                this.publish('storage:set', {
                  key: 'taFilter',
                  data: storeData,
                });
              },
            });
          } else if (data.route.startsWith('dashboards')) {
            // remove stored taFilter if in custom dashboards
            // otherwise TA filter will remain on TA screen
            this.publish('app:updateData', {
              taFilterId: null,
              callback: () => {
                this.publish('storage:delete', { key: 'taFilter' });
              },
            });
          }
        },
      });
    } else {
      this.publish('app:updateData', {
        taFilterId: null,
        callback: () => {
          this.publish('storage:delete', { key: 'taFilter' });
        },
      });
    }
  }

  resetFilter() {
    this.publish('storage:get', {
      key: 'taFilter',
      callback: ({ data: { data: taFilter } = {}, expired }) => {
        if (expired || !taFilter) {
          return;
        }

        const filterParams = {
          clientId: this.client,
          filterId: taFilter.filterId,
        };

        switch (taFilter.dataSourceType) {
          case CX_MEASURE:
            filterParams.measureId = taFilter.dataSourceId;
            break;

          case FEEDBACK:
            filterParams.projectId = taFilter.dataSourceId;
            break;

          case CUSTOM_FEED:
            filterParams.customFeedId = taFilter.dataSourceId;
            break;
        }

        this.deleteFilter({ filterParams });
      },
    });
  }
}

export default TAFiltersController;

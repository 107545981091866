import store from 'storejs';
import { includes, each, keys } from 'lodash';

const getSessionTokens = () => ({
  tokenSecret: store.get('tokenSecret'),
  accessToken: store.get('accessToken'),
  sessionToken: store.get('sessionToken'),
  idToken: store.get('idToken'),
  lang: store.get('lang'),
  sonomaBearerToken: store.get('sonomaBearerToken'),
  sonomaAccessToken: store.get('sonomaAccessToken'),
});

store.safeClear = () => {
  // save store of all but necessary keys
  const sessionKeys = {
    ...getSessionTokens(),
    loggedInAs: store.get('loggedInAs'),
    xImpersonatedUserId: store.get('xImpersonatedUserId'),
  };
  store.each((value, key) => {
    if (key.match(/^backup_/)) {
      sessionKeys[key] = value;
    }
  });
  store.clearAll();

  each(keys(sessionKeys), function (key) {
    store.set(key, sessionKeys[key]);
  });
};

store.switchUser = () => {
  const sessionKeys = {
    ...getSessionTokens(),
    xImpersonatedUserId: store.get('xImpersonatedUserId'),
  };

  const storeData = {};
  store.each((value, key) => {
    storeData[key] = value;
  });
  each(keys(storeData), function (key) {
    if (!includes(Object.keys(sessionKeys), key) && !key.match(/^backup_/)) {
      store.set(`backup_${key}`, storeData[key]);
      store.remove(key);
    }
  });
};

store.restoreUser = function () {
  const storeData = {};
  store.each((value, key) => {
    storeData[key] = value;
  });
  each(keys(storeData), function (key) {
    if (key.match(/^backup_/)) {
      store.set(key.replace(/^backup_/, ''), storeData[key]);
      store.remove(key);
    }
  });
};
export default store;

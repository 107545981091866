import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import 'whatwg-fetch';
import store from './store';
import oauth from './oauth';
import { v4 as uuidv4 } from 'uuid';
import { graphqlErrorHandler } from './utils/graphqlErrorHandler';

const FS_GRAPHQL_URI = '!!_graphqlUrl_!!';

const link = from([
  onError(graphqlErrorHandler),
  new ApolloLink((operation, forward) => {
    const impersonated = store.get('xImpersonatedUserId');
    const impersonatedUserIdHeader = impersonated
      ? {
          'x-impersonated-userid': impersonated.userId,
        }
      : null;

    operation.setContext({
      headers: {
        token: decodeURIComponent(store.get('accessToken')) || null,
        secret: decodeURIComponent(store.get('tokenSecret')) || null,
        Authorization: oauth.createAuthorizationHeader({
          url: FS_GRAPHQL_URI,
          reqType: 'POST',
        }),
        'X-Request-ID': uuidv4(),
        ...impersonatedUserIdHeader,
      },
    });

    return forward(operation);
  }),
  new HttpLink({ uri: FS_GRAPHQL_URI }),
]);

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default apolloClient;

import Backbone from 'backbone';

var TAQuestionTotalsModel = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {},

  /**
   * Constructor
   */
  initialize: function (model, options = {}) {
    this.clientId = model.clientId;
    this.projectType = options.projectType ? options.projectType : 'CX_MEASURE';
  },

  url: function () {
    let url = `ta-reporting/clients/${this.clientId}/`;
    if (this.projectType === 'CX_MEASURE') {
      url += `comment/total`;
    } else {
      url += `projects/questions/total`;
    }
    return url;
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default TAQuestionTotalsModel;

import Backbone from 'backbone';

var CommentQuestions = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    qKey: 0,
    type: '',
    label: '',
    text: '',
  },
  parse: function (data) {
    return {
      qKey: data.qKey,
      type: data.type,
      label: data.label,
      text: data.text,
      tag: data.tag,
    };
  },
  idAttribute: 'qKey',

  /*toJSON: function () {
      return _.extend(this.attributes, {
        openends: parseFloat(this.get('score').toFixed(1))
      });
    }, */

  /**
   * Constructor
   */
  initialize: function (opts) {},

  serviceId: 'foresee-services',

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default CommentQuestions;

import Backbone from 'backbone';
import CommentQuestions from 'models/clients/measures/commentquestions';

const CommentQuestionsCollection = Backbone.Collection.extend({
  model: CommentQuestions,
  initialize: function (opts) {
    const options = opts || {};
    this.client = options.clientId;
    this.measure = options.measure;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/commentquestions`;
  },
});

export default CommentQuestionsCollection;

import Backbone from 'backbone';
import AuditModel from 'models/audit';

var AuditCollection = Backbone.Model.extend({
  model: AuditModel,

  initialize: function (model, opts) {
    this.criteria = opts.criteria;
    this.eventClassifiers = opts.eventClassifiers || [];
  },

  setEventClassifiers() {
    return `&eventClassifier=${this.eventClassifiers.join('~')}`;
  },

  url: function () {
    let route = 'platform-auditlog-service/audit-events?';

    function serialize(obj) {
      return Object.keys(obj)
        .reduce(function (a, k) {
          a.push(k + '=' + encodeURIComponent(obj[k]));
          return a;
        }, [])
        .join('&');
    }

    if (this.criteria) {
      route += serialize(this.criteria);
      route += this.setEventClassifiers();
    }

    return route;
  },
});

export default AuditCollection;

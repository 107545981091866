export default {
  profile: '/profile',

  login: '/login',

  forgot: {
    index: '/password/forgot',
    expired: '/password/forgot#expired',
    email: '/password/forgot/email',
    sms: '/password/forgot/sms?stateToken=${stateToken}',
  },

  measures: {
    dashboard: '/measures/${measurementKey}/analytics/${type}',
    card: '/measures/${measurementKey}/analytics/${type}/dashboards/${dashboardId}/card/${cardId}',
    editCard:
      '/measures/${measurementKey}/analytics/${type}/dashboards/${dashboardId}/card/${cardId}?action=edit',
    moveCard:
      '/measures/${measurementKey}/analytics/${type}/dashboards/${dashboardId}/card/${cardId}?action=move',
    copyCard:
      '/measures/${measurementKey}/analytics/${type}/dashboards/${dashboardId}/card/${cardId}?action=copy',

    respondents: '/measures/${measurementKey}/analytics/respondents',
    summary: '/measures/${measurementKey}/analytics/summary',
    model: '/measures/${measurementKey}/analytics/model',
    survey: '/measures/${measurementKey}/analytics/survey',
    respondentDetail:
      '/measures/${measurementKey}/analytics/respondents/${respondentKey}/detail?responseDate=${responseDate}',
    respondentDetailParam:
      '/measures/${measurementKey}/analytics/respondents/${respondentKey}/detail?responseDate=${responseDate}&backTo=${backTo}',
  },
  surveys: {
    summary: '/projects',
  },
  datasources: {
    list: '/datasources',
    summary: '/datasources/${dataSourceType}',
    detail: '/datasources/${dataSourceType}/${dataSourceKey}',
    view: '/datasources/${dataSourceType}/${dataSourceKey}/${dataSourceView}?backTo=${backTo}',
    card: '/datasources/${dataSourceType}/${dataSourceKey}/dashboards/${dashboardId}/card/${cardId}?schemaId=${schemaId}',
    editCard:
      '/datasources/${dataSourceType}/${dataSourceKey}/dashboards/${dashboardId}/card/${cardId}?action=edit&schemaId=${schemaId}',
    copyCard:
      '/datasources/${dataSourceType}/${dataSourceKey}/dashboards/${dashboardId}/card/${cardId}?action=copy&schemaId=${schemaId}',
    respondentDetail:
      'datasources/${dataSourceType}/${dataSourceKey}/${dataSourceView}/analytics/respondents/${respondentKey}/detail?responseDate=${responseDate}&schemaId=${schemaId}',
    respondentDetailParam:
      'datasources/${dataSourceType}/${dataSourceKey}/${dataSourceView}/analytics/respondents/${respondentKey}/detail?responseDate=${responseDate}&schemaId=${schemaId}&backTo=${backTo}',
  },
  feedback: {
    edit: '/projects/${projectId}/edit',
    respondents: '/projects/${projectId}/analytics/respondents/',
    respondentDetail:
      '/projects/${projectId}/analytics/respondents/${respondentKey}/detail',
    respondentDetailParam:
      '/projects/${projectId}/analytics/respondents/${respondentKey}/detail?backTo=${backTo}',
  },

  settings: {
    masterData: '/settings/setup/masterdata',
    masterDataSetup: '/settings/setup/masterdata/new',
    masterDataRecords: '/settings/setup/masterdata/${id}/records',
    masterDataRecordsNew: '/settings/setup/masterdata/${id}/records?new=true',
    hierarchy: '/settings/setup/hierarchy',
    hierarchyDetail: '/settings/setup/hierarchy/${id}/',
    hierarchySetup: '/settings/setup/hierarchy/new',
    hierarchySetupDetail: '/settings/setup/hierarchy/new/${id}',
    uploadSetupDetail: '/settings/setup/hierarchy/new/${id}?type=${type}',
    hierarchyDraftList: '/settings/setup/hierarchy/${hierarchyId}/drafts',
    hierarchyDraftDetail:
      '/settings/setup/hierarchy/${hierarchyId}/drafts/${draftId}',
    hierarchyVersionList: '/settings/setup/hierarchy/${hierarchyId}/versions',
    hierarchyVersionDetail:
      '/settings/setup/hierarchy/${hierarchyId}/versions/${versionId}/',

    setup: {
      roles: '/settings/setup/roles',
      rules: '/settings/setup/assignments',
    },

    people: '/settings/setup/people/',
    users: '/settings/setup/users',

    rolesNew: '/settings/roles/new',
    rolesPeople: '/settings/roles/${id}/people',

    user: {
      details: '/settings/users/${id}/details',
    },

    roles: {
      index: '/settings/roles/${id}',
      new: '/settings/roles/new',
    },

    assignments: {
      index: '/settings/rules/${id}',
      new: '/settings/rules/new',
    },

    webSDK: '/settings/integrate/hosted-code',
  },

  leaderboards: {
    dashboard: '/leaderboard',
    leaderboard:
      '/hierarchy/${hierarchyId}/measures/${measurementKey}/leaderboard',
    leaderboardQuery:
      '/hierarchy/${hierarchyId}/measures/${measurementKey}/leaderboard${query}',
  },

  dashboards: {
    cx: 'dashboards/cx',
    base: '/dashboards',
    summary: '/dashboards/${dashboardId}',
    card: '/dashboards/${dashboardId}/card/${cardId}',

    editDashboard: '/dashboards/${dashboardId}?action=edit',
    newDashboard: '/dashboards/${dashboardId}?action=new',
    copyDashboard: '/dashboards/${dashboardId}?action=copy',
    createNewDashboard: '/dashboards/new',

    editCard: '/dashboards/${dashboardId}/card/${cardId}?action=edit',
    moveCard: '/dashboards/${dashboardId}/card/${cardId}?action=move',
    copyCard: '/dashboards/${dashboardId}/card/${cardId}?action=copy',
    deleteCard: '/dashboards/${dashboardId}/card/${cardId}?action=delete',
    newCard: '/dashboards/${dashboardId}/card/new/${cardType}',
    schedule: '/dashboards/${dashboardId}/schedule/${scheduleId}',
  },
  textAnalytics: {
    base: '/analyze/text-analytics',
    overview: '/analyze/text-analytics/overview',
    overviewDashboard:
      '/analyze/text-analytics/overview' +
      '?clientId=${clientId}' +
      '&measureIds=[${measureIds}]' +
      '&feedbackIds=[${feedbackIds}]' +
      '&customFeedIds=[${customFeedIds}]' +
      '&questionIds=[${questionIds}]',
    topics:
      '/analyze/text-analytics/topics?clientId=${clientId}' +
      '&measureIds=${measureIds}' +
      '&feedbackIds=${feedbackIds}' +
      '&customFeedIds=${customFeedIds}',
    keywords:
      '/analyze/text-analytics/keywords?clientId=${clientId}' +
      '&measureIds=${measureIds}' +
      '&feedbackIds=${feedbackIds}' +
      '&customFeedIds=${customFeedIds}',
    editCard:
      '/analyze/text-analytics/overview/dashboards/${dashboardId}/card/${cardId}/?action=edit' +
      '&dataSourceKey=${dataSourceKey}' +
      '&dataSourceType=${dataSourceType}' +
      '&dateRange=${dateRange}' +
      '&hierarchyFilterId=${hierarchyFilterId}' +
      '&modelFilterId=${modelFilterId}' +
      '&taQuestionIds=${taQuestionIds}' +
      '&taFilterId=${taFilterId}',
    card:
      '/analyze/text-analytics/overview/dashboards/${dashboardId}/card/${cardId}' +
      '?dataSourceKey=${dataSourceKey}' +
      '&dataSourceType=${dataSourceType}' +
      '&dateRange=${dateRange}' +
      '&hierarchyFilterId=${hierarchyFilterId}' +
      '&modelFilterId=${modelFilterId}' +
      '&taQuestionIds=${taQuestionIds}' +
      '&taFilterId=${taFilterId}',
  },

  behavioral: {
    page: 'behavioral/page?pageA=${pageId}',
    compare: 'behavioral/page/compare?pageA=${pageId}',
  },

  alerts: {
    summary: '/alerts',
  },

  primary: {
    dashboards: '/dashboards/cx',
    surveys: '/projects',
    textAnalytics: '/text-analytics/topics?clientId=${id}',
    alerts: '/alerts',
    settings: '/settings',
  },

  analyze: {
    anomalySegments: '/analyze/anomaly',
    anomalyDetail: '/analyze/anomaly/${id}/?isArchived=${isArchived}',
    anomalySegmentsEmbedded: '/analyze/anomaly/focus',
    anomalyDetailsEmbedded:
      '/analyze/anomaly/${id}/focus/?isArchived=${isArchived}',
    behavioral: {
      home: `/analyze/behavioral`,
      page: '/analyze/behavioral/page?pageA=${pageId}',
      compare: '/analyze/behavioral/page/compare?pageA=${pageId}',
    },
  },
};

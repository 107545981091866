import Backbone from 'backbone';
import URL from 'core/utils/url';
const UserRolesModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    const options = opts || {};
    this.clientId = options.clientId ? options.clientId : -1;
    this.userIds = options.userIds;
    this.roleIds = options.roleIds;
    this.limit = options.limit;
    this.pageOffset = options.pageOffset;
    this.defaultRole = options.defaultRole ? options.defaultRole : false;
  },

  url: function () {
    let url = `authorization-service/clients/${this.clientId}/userRoles/filter`;
    if (this.userIds) {
      url = URL.insertParam(url, 'userIds', this.userIds);
    }
    if (this.roleIds) {
      url = URL.insertParam(url, 'roleIds', this.roleIds);
    }
    if (this.limit) {
      url = URL.insertParam(url, 'limit', this.limit);
    }
    if (this.offset) {
      url = URL.insertParam(url, 'offset', this.offset);
    }
    if (this.defaultRole) {
      url = URL.insertParam(url, 'defaultRole', this.defaultRole);
    }
    return url;
  },
});

export default UserRolesModel;

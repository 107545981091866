import BaseController from 'controllers/base';
import WhiteListModel from 'models/whitelist';

class WhitelistController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'whitelist:check': 'checkOrigin',
        'whitelist:set': 'setWhitelist',
        'whitelist:remove': 'removeOrigin',
        'whitelist:get': 'getWhitelist',
      },
      name: 'whitelist',
      dependsOn: [],
    });
  }

  initialize() {
    this.publish('route:get', {
      callback: (payload) => {
        this.getConfig(payload);
      },
    });
  }

  checkOrigin(payload) {
    const { origin, callback } = payload;

    const whiteListModel = new WhiteListModel();
    whiteListModel.setParams({
      url: origin,
      namespace: this.getNamespace(),
    });

    whiteListModel
      .fetch({
        doNotAbort: true,
        error: () => {
          this.dispatch(callback, 'whitelist:check:isValid', false);
        },
      })
      .then(() => {
        this.dispatch(callback, 'whitelist:check:isValid', true);
      });
  }

  setWhitelist(payload) {
    const { callback, data } = payload;

    this.publish('storage:client:set', {
      key: this.getNamespace(),
      customData: data,
      update: true,
      callback: callback,
    });
  }

  getWhitelist(payload) {
    const { callback } = payload;

    this.publish('storage:client:get', {
      key: this.getNamespace(),
      callback: callback,
    });
  }

  getNamespace() {
    return 'fbwl';
  }
}

export default WhitelistController;

import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import routes from 'core/routes';
import moment from 'moment';
import permissions from 'core/permissions';
import featureFlags from 'core/featureFlags';
import validator from 'core/utils/validate';
import Tooltip from 'views/mixins/tooltip-manager';
import Strings from 'core/utils/getstring';

class BaseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this._routes = routes;

    /**
     * References to permissions singleton
     */
    this.permissions = permissions;
    this.options = permissions.options;
    this.featureFlags = featureFlags;
    this.validator = validator;
  }

  /**
   *  Given a string defined with interpolation tokens of the form ${token},
   *  resolve those tokens from the properties of a provided object.
   *
   *
   */
  getTemplatedString(string, tokens) {
    return Strings.getTemplatedString(string, tokens);
  }

  /**
      Set the base path for your strings
    */
  setBaseStringPath(string) {
    this.setState(() => {
      return { baseStringPath: string };
    });
  }

  getBasedString(string, model) {
    const { baseStringPath } = this.state;
    let newString = string;
    if (baseStringPath) {
      newString = baseStringPath ? baseStringPath + '.' + string : string;
    }

    return Strings.getString(newString, model);
  }

  /**
   * Given a string of form 'a.b.c' will return value from that location in strings.
   * @param string
   */
  getString(string, model) {
    return Strings.getString(string, model);
  }

  /**
   *  Given a string defined with interpolation tokens of the form ${token},
   *  resolve those tokens from the properties of a provided object.
   */
  generateRoute(key, tokens) {
    const fn = [].constructor.constructor('return arguments[0].' + key);
    const str = fn(this._routes);
    _.templateSettings = {
      interpolate: /\$\{(.+?)\}/g,
    };
    return tokens ? _.template(str)(tokens) : str;
  }

  /**
   * Retrieves deeply copied property from state so it is safe to use without mutating state.
   * @param string
   */
  getState(prop) {
    var item = this.state[prop];
    return this.clone(item);
  }

  /**
   * Returns deeply copied version of item
   * @param string
   */
  clone(item) {
    if (typeof item !== 'object') {
      return item;
    } else {
      if (item === null) {
        return item;
      } else if (item instanceof Array) {
        return $.extend(true, [], item);
      } else {
        return $.extend(true, {}, item);
      }
    }
  }
  getTypefromURL() {
    let type;
    const urlParams = new URLSearchParams(window.location.search);
    const measureIds =
      urlParams.get('measureIds') !== null
        ? JSON.parse(urlParams.get('measureIds'))
        : [];
    const feebackIds =
      urlParams.get('feedbackIds') !== null
        ? JSON.parse(urlParams.get('feedbackIds'))
        : [];
    const customFeedIds =
      urlParams.get('customFeedIds') !== null
        ? JSON.parse(urlParams.get('customFeedIds'))
        : [];

    if (
      measureIds.length === 0 &&
      (feebackIds.length > 0 || customFeedIds.length > 0)
    ) {
      type = 'DATE_TIME';
    }
    return type;
  }

  localizeUtc(utc) {
    var localTime = moment.utc(utc).toDate();
    return moment(localTime);
  }

  getParamString(params) {
    var paramString = '',
      amp = '';

    _.each(
      params,
      _.bind(function (value, key) {
        if (paramString.length) {
          amp = '&';
        }
        paramString =
          paramString +
          amp +
          encodeURIComponent(key) +
          '=' +
          encodeURIComponent(value);
      }, this)
    );

    return paramString;
  }

  /*
      Used with .filter to filter out unique values within an array
    */
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  handleClick(e) {
    if (this.props.tooltip) Tooltip.handleTooltip(e, this.props.tooltip);
    // Handle parent onClick events passed in props
    if (typeof this.props.onClick === 'function') this.props.onClick(e);
  }

  handleMouseOver(e) {
    if (typeof this.props.onMouseOver === 'function') this.props.onMouseOver(e);
    if (this.props.tooltip) this._showTooltip(e);
  }

  handleMouseOut(e) {
    if (typeof this.props.onMouseOut === 'function') this.props.onMouseOut(e);
    if (this.props.tooltip) Tooltip.handleTooltip(e);
  }

  hasFeature(flag) {
    return this.featureFlags.hasFeature(flag);
  }

  _showTooltip(e, props) {
    var tooltipProps = props || this.props.tooltip;
    if (tooltipProps) Tooltip.handleTooltip(e, tooltipProps);
  }

  /**
   * Permissions Methods
   */

  /**
   * takes a string corresponding to the users human readable role defined in the app/constants PERMISSIONS_MAP object, eg. { 'builder': [ROLES] }
   * and compares the [ROLES] with the roles a user has in their user object.
   * returns true if ANY match, or ALL if requireAll bool is true
   */
  userRole(request, requireAll) {
    return this.permissions.userRole(request, requireAll);
  }

  //Filters out config settings where the user doesn't meet permissions criteria
  checkPermission(request) {
    return this.permissions.checkPermission(request);
  }

  getInsightsUrl() {
    return this.permissions.getInsightsUrl();
  }

  /**
   * End of Permissions Methods
   */

  validate(obj) {
    return this.validator.validate(obj);
  }
}

export default BaseView;

import _ from 'underscore';
import routes from 'core/routes';

/**
 *  Given a string defined with interpolation tokens of the form ${token},
 *  resolve those tokens from the properties of a provided object.
 */
const generateRoute = (key, tokens) => {
  const fn = [].constructor.constructor('return arguments[0].' + key);
  const str = fn(routes);
  _.templateSettings = {
    interpolate: /\$\{(.+?)\}/g,
  };
  return tokens ? _.template(str)(tokens) : str;
};
export default generateRoute;

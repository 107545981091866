import Constants from 'core/constants';
import { hasFeature } from 'core/utils/feature-flags';
import { hasBenchmarkTypeOnly } from 'core/utils/benchmark-checks';

export const getAttribute = (card, key) => {
  if (card && card.metadata && card.metadata.attributes) {
    return card.metadata.attributes[key];
  }
  return null;
};

export const canFetchCardData = (card) => {
  const chartType = getAttribute(card, 'chartType');
  const dataSources = getAttribute(card, 'dataSources') || [];
  const allDataSources = getAttribute(card, 'allDataSources');
  const metrics = getAttribute(card, 'metrics') || [];
  const dimensions = getAttribute(card, 'dimensions') || [];
  const dimensionRows = getAttribute(card, 'dimensionRows') || [];
  const dimensionColumns = getAttribute(card, 'dimensionColumns') || [];
  const dataSourceType = getAttribute(card, 'dataSourceType');
  const questionKeys = getAttribute(card, 'questionKeys') || [];
  const taQuestionKeys = getAttribute(card, 'taQuestionKeys') || [];
  const taQuestionTags = getAttribute(card, 'taQuestionTags') || [];
  const taQuestionIds = getAttribute(card, 'taQuestionIds') || [];
  const customFeedCriteriaList =
    getAttribute(card, 'customFeedCriteriaList') || [];

  switch (chartType) {
    case Constants.DASHBOARD.chartTypes.donut:
      const hasDimension = dimensions.length || dataSources.length > 1;
      return !!(dataSources.length && metrics.length && hasDimension);
    case Constants.DASHBOARD.chartTypes.gauge:
      return !!(dataSources.length && metrics.length);
    case Constants.DASHBOARD.chartTypes.trendLine:
      return !!(dataSources.length && metrics.length && dimensions.length);
    case Constants.DASHBOARD.chartTypes.priorityIndex:
      return !!(
        allDataSources ||
        dataSources.length ||
        (allDataSources === undefined &&
          getAttribute(card, 'dataSources') === undefined &&
          dataSourceType)
      );
    case Constants.DASHBOARD.chartTypes.priorityMap:
      return hasFeature('big-data-reporting-priority-map-multi')
        ? !!(
            allDataSources ||
            dataSources.length ||
            (allDataSources === undefined &&
              getAttribute(card, 'dataSources') === undefined &&
              dataSourceType)
          )
        : !!((allDataSources || dataSources.length) && dataSourceType);
    case Constants.DASHBOARD.chartTypes.scoreSummary:
      return !!(dataSources.length && dataSourceType);
    case Constants.DASHBOARD.chartTypes.verticalBar:
    case Constants.DASHBOARD.chartTypes.horizontalBar:
      return !!(
        (allDataSources || dataSources.length) &&
        metrics &&
        metrics.length &&
        !hasBenchmarkTypeOnly(dimensions)
      );
    case Constants.DASHBOARD.chartTypes.tableCard:
      return !!(
        dataSources.length &&
        metrics.length &&
        (dimensionRows.length || dimensionColumns.length)
      );
    case Constants.DASHBOARD.chartTypes.voc:
      const validKeys =
        questionKeys.length ||
        taQuestionKeys.length ||
        taQuestionIds.length ||
        taQuestionTags.length ||
        customFeedCriteriaList.length;
      return !!(dataSources.length && validKeys);
    case Constants.DASHBOARD.chartTypes.wordCloud:
      return !!(dataSources.length && metrics.length && dimensions.length);
    case Constants.DASHBOARD.chartTypes.kpi:
      return !!(dataSources.length && metrics.length);
    case Constants.DASHBOARD.chartTypes.scatterPlot:
      return !!(metrics.length > 1 && dimensions.length);
  }
};

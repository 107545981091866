import React from 'react';
import BaseView from 'views/base';
import guid from 'core/utils/guid';

class TemplatedComponent extends BaseView {
  render() {
    const components = [];

    let string = this.getString(this.props.string);
    for (const key in this.props.tokens) {
      const strings = string.split('${' + key + '}');
      if (strings.length > 1) {
        components.push(strings[0]);
        components.push(<span key={guid()}>{this.props.tokens[key]}</span>);

        string = strings[1];
      }
    }
    components.push(string);

    return <span>{components}</span>;
  }
}
TemplatedComponent.defaultProps = {
  string: '',
  tokens: {},
};

export default TemplatedComponent;

import Backbone from 'backbone';

const HierarchyVersionsModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.hierarchyId = options.hierarchyId;
    this.id = options.id;
  },

  idAttribute: 'version',

  url: function () {
    console.log('get url ', this);
    if (this.id) {
      return `clients/${this.client}/hierarchies/${this.hierarchyId}/versions/${this.id}`;
    }
    return `clients/${this.client}/hierarchies/${this.hierarchyId}/versions`;
  },
});

export default HierarchyVersionsModel;

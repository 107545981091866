import Backbone from 'backbone';
import featureFlags from 'core/featureFlags';

var CustomFeedModel = Backbone.Model.extend({
  idAttribute: 'customFeedId',
  initialize: function (opts) {
    this.id = opts.customFeedId;
  },

  parse: function (data) {
    data.name = data.customFeedName;
    return data;
  },

  url: function () {
    if (featureFlags.hasFeature('new_ta-admin')) {
      if (this.id) {
        return 'ta-admin/customFeed/' + this.id;
      } else {
        return 'ta-admin/customFeed';
      }
    } else {
      if (this.id) {
        return 'ta-config-beta/customFeed/' + this.id;
      } else {
        return 'ta-config-beta/customFeed';
      }
    }
  },
});

export default CustomFeedModel;

import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';
import JSTimezoneDetect from 'jstimezonedetect';
/**
 * Defines a date range
 * This is the base class for the different types of date ranges.
 * @see CustomRange
 * @see YearRange
 * @see QuarterRange
 * @see MonthRange
 * @see WeekRange
 * @see DayRange
 */
var DateRange = Backbone.Model.extend(
  {
    /**
     * Defaults for a date object
     */
    defaults: {
      calendarType: 'G',
      rangeType: 'C',
      periodType: 'D',
      number: 0,
      asOf: new Date(),
      first: null,
      last: null,
      customerKey: -1,
      verbose: false,
      rolling: true,
      dateRangeType: 'DATE_ONLY',
    },

    /**
     * If First and last are strings, make them into dates)
     */
    initialize: function () {
      var first = this.get('first'),
        last = this.get('last'),
        dateRangeType = this.get('dateRangeType');

      if (typeof first === 'string') {
        if (first && dateRangeType === 'DATE_TIME') {
          this.set('first', moment(first).local().startOf('day'));
        } else {
          this.set('first', moment.utc(first));
        }
      }

      if (typeof last === 'string') {
        this.set('last', moment.utc(last));
      }
      if (last && dateRangeType === 'DATE_TIME') {
        this.set('last', moment(last).local().endOf('day'));
      }
      this.timezone = JSTimezoneDetect.determine().name();
    },

    /**
     * asOf field for EXACT ranges changes depending on whether it is a
     * fiscal or gregorian calendar. For fiscal calendars, the asOf field should
     * be the start date for that particular fiscal year whereas for gregorian
     * calendars, the asOf field should always be Jan 1 of that year. Yes, if you
     * are confused why it should matter, you are not alone. This is dumb.
     */
    updateAsOf: function () {
      var asOf = this.get('asOf'),
        wasFiscal =
          this.previousAttributes().calendarType ===
          DateRange.calendarTypes.FISCAL,
        year;

      if (asOf) {
        year = asOf.getFullYear();
        if (wasFiscal) {
          var fiscalYear = moment._4c.fiscal.year(asOf);
          if (fiscalYear) {
            year = fiscalYear.year;
          }
        }
        this.set('asOf', moment._4c.yearStart(year).toDate(), { silent: true });
      }
    },

    /**
     * Parses the asOf attribute and returns the correct year.
     * For Gregorian ranges, just returns the year of the date.
     * But for Fiscal ranges, need to query the fiscal info in moment
     * to figure out the actual year since the asOf attribute is not
     * necessarily Jan 1 in this case.
     *
     * @type Number
     */
    asOfYear: function () {
      var asOf = this.get('asOf');
      if (this.get('calendarType') === DateRange.calendarTypes.FISCAL) {
        var fiscalYear = moment._4c.fiscal.year(asOf);
        if (fiscalYear) return fiscalYear.year;
      }

      return asOf.getFullYear();
    },

    /**
     * Get/sets the mode of the DateRange
     * Some DateRange types have a strict set of semantics so
     * this function manages the model's data based on the mode.
     */
    mode: function () {},

    /**
     * Pulls resolved dateRange info from backend and update object asynchronously.
     * Wraps Backbone.Model.fetch to set urlparams before invoking.
     */
    // fetch: _.wrap(Backbone.Model.prototype.fetch, function (fn, options) {

    //     var pojo = this.toJSON({resolve: true});

    //     this.urlparams = { dateRange: JSON.stringify(pojo) };

    //     if (this.get('shareKey')) {
    //         this.urlparams.shareKey = this.get('shareKey');
    //         this.urlparams.tileId = this.get('tileId');
    //     }

    //     fn.call(this, options);
    // }),

    /**
     * Returns a formatted date string
     *
     * @param date {Date} The date to format
     * @param format {String} The date format definition.
     * @type String
     */
    formatDate: function (date, format) {
      format = format || 'YYYY-MM-DDTHH:mm:ssZZ';

      if (!_.isObject(date)) return '';

      return moment(date).format(format);
    },

    /**
     * Parse a date string
     *
     * @param {String} date The date string to parse.
     * @param {String} format The expected format of the date string.
     */
    parseDate: function (date, format) {
      format = format || 'YYYY-MM-DD';

      if (!_.isString(date) || date === '') return '';

      return moment(date, format).toDate();
    },

    formatFirstDate(options) {
      let start = this.get('startDate') || this.get('start_date');
      const rangeType = this.get('r') || this.get('rangeType');
      const dateRangeType = this.get('t') || this.get('dateRangeType');
      if (
        dateRangeType === 'DATE_TIME' &&
        (rangeType === 'C' || rangeType === 'CUSTOM')
      ) {
        options.format = 'YYYY-MM-DDTHH:mm:ssZZ';
      }
      if (start) {
        return this.formatDate(start, options.format);
      }

      return this.get('first')
        ? this.formatDate(this.get('first'), options.format)
        : '';
    },

    formatLastDate(options) {
      let end = this.get('endDate') || this.get('end_date');
      const rangeType = this.get('r') || this.get('rangeType');
      const dateRangeType = this.get('t') || this.get('dateRangeType');
      if (
        dateRangeType === 'DATE_TIME' &&
        (rangeType === 'C' || rangeType === 'CUSTOM')
      ) {
        options.format = 'YYYY-MM-DDTHH:mm:ssZZ';
      }
      if (end) {
        return this.formatDate(end, options.format);
      }

      return this.get('last')
        ? this.formatDate(this.get('last'), options.format)
        : '';
    },

    /**
     * Returns a serializable object
     * @type Object
     */
    toJSON: function (opts) {
      const dateRangeType = this.get('t') || this.get('dateRangeType');
      let options = opts || {};
      let timezone = '';
      if (dateRangeType === 'DATE_TIME') {
        timezone = this.get('z') || this.timezone;
      }
      return {
        c: this.get('c') || this.get('calendarType'),
        r: this.get('r') || this.get('rangeType'),
        p:
          this.get('p') ||
          (options.resolve || this.get('rangeType') !== 'C'
            ? this.get('periodType')
            : 'D'),
        n: this.get('n') || this.get('number'),
        a:
          this.get('a') || this.get('asOf')
            ? this.formatDate(this.get('asOf'), options.format)
            : '',
        f: this.get('f') || this.formatFirstDate(options),
        l: this.get('l') || this.formatLastDate(options),
        k: (this.get('k') || this.get('customerKey')).toString(),
        // until issues with oauth signature generation are sorted out
        // serialization of verbose is disabled.
        v: this.get('v') || this.get('verbose'),
        o: this.get('o') || this.get('rolling'),
        t: dateRangeType,
        z: timezone,
      };
    },

    /**
     * Override Model#parse
     *
     * @param {Object} response The serialized DateRange to parse.
     * @type Object
     */
    parse: function (response) {
      const timezone = response.t === 'DATE_TIME' ? this.timezone : '';
      var parsed = {
          calendarType: this.parseCalendar(response.c),
          rangeType: this.parseRange(response.r),
          periodType: this.parsePeriod(response.p),
          number: response.n,
          asOf: this.parseDate(response.a),
          first: this.parseDate(response.f),
          last: this.parseDate(response.l),
          customerKey: response.k,
          verbose: response.v,
          dateRangeType: response.t,
          timezone: timezone,
        },
        type = parsed.rangeType,
        number = parsed.number;
      parsed.mode = this.parseMode(type, number);
      //return _.extend(response, parsed);
      return parsed;
    },

    /**
     * Parse the calendar type
     *
     * On the client we use terse definitions for enumerable properties,
     * but the server returns verbose definitions of those same properties.
     *
     * e.g. We use 'G' to define a calendar type of gregorian, but the server
     * will return 'GREGORIAN'. This functions normalizes calendarTypes to their
     * terse versions.
     *
     * @param {String} t The calendar type to parse.
     * @type String
     */
    parseCalendar: function (t) {
      var types = DateRange.calendarTypes;
      if (_.contains(_.keys(types), t)) {
        t = types[t];
      }
      return t;
    },

    /**
     * Parse the range type
     * @see parseCalendar
     *
     * @param {String} t The range type to parse.
     * @type String
     */
    parseRange: function (t) {
      var types = DateRange.rangeTypes;
      if (_.contains(_.keys(types), t)) {
        t = types[t];
      }
      return t;
    },

    /**
     * Parse the period type.
     * @see parseCalendar
     *
     * @param {String} t The period type to parse.
     * @type String
     */
    parsePeriod: function (t) {
      var types = DateRange.periodTypes;
      if (_.contains(_.keys(types), t)) {
        t = types[t];
      }
      return t;
    },

    /**
     * Parse the mode from the rangeType and number fields.
     *
     * DateRanges have strict semantics and the current mode (which
     * is not part of the backend specification) can be determined
     * from the rangeType and number fields.
     *
     * @param {String} t The range type
     * @param {Number} n The number field
     * @type String
     */
    parseMode: function (t, n) {
      if (t.length === 2) {
        return DateRange.modes.EXACT;
      } else {
        if (n === 0) {
          return DateRange.modes.RTD;
        } else if (n === 1) {
          return DateRange.modes.LAST;
        } else {
          return DateRange.modes.LASTN;
        }
      }
    },

    /**
     * Returns relative piece of endpoint to resolve dateRange on backend
     * @returns {string}
     */
    url: function () {
      return 'calendar/lookup';
    },
  },
  {
    calendarTypes: {
      GREGORIAN: 'G',
      FISCAL: 'F',
    },
    rangeTypes: {
      DAY: 'DY',
      WEEK: 'WK',
      MONTH: 'MO',
      QUARTER: 'QR',
      YEAR: 'YR',
      DAYS: 'D',
      WEEKS: 'W',
      MONTHS: 'M',
      QUARTERS: 'Q',
      YEARS: 'Y',
      CUSTOM: 'C',
    },
    periodTypes: {
      DEFINED: 'D',
      PRIOR: 'P',
      PRIOR_YEAR: 'Y',
      YEAR: 'Y',
    },
    modes: {
      RTD: 'rtd',
      LAST: 'last',
      LASTN: 'lastn',
      EXACT: 'exact',
    },

    /**
     * Returns a date string formatted for appending to labels
     * @param dateRange
     * @param format
     * @returns string
     */
    formatLabelDate: function (dateRange, opts) {
      var range = dateRange || {},
        options = opts || {},
        first =
          range.f && range.f.length > 0 ? moment.utc(range.f).toDate() : false,
        last =
          range.l && range.l.length > 0 ? moment.utc(range.l).toDate() : false,
        format = options.format || 'MMM DD, YYYY',
        prefix = _.isString(options.prefix) ? options.prefix : ': ',
        delimiter = options.delimiter || ' - ',
        trend = options.trend || false,
        formattedDate = '';

      // If the date range has not been resolved, we could fall back to the verbose attribute. This is not appropriate in most
      // cases, though. We will just return an empty string for now.
      // Check to see if first and last were created. If not, return an empty string.
      if (!first || !last) {
        return '';
      }

      if (
        first.toString() !== 'Invalid Date' &&
        last.toString !== 'Invalid Date' &&
        ((range.r !== 'C' &&
          range.r !== 'CUSTOM' &&
          (((range.r === 'DAY' || range.r === 'DY') &&
            range.p.indexOf('PRIOR') !== -1) ||
            (range.r !== 'DAY' && range.r !== 'DY'))) ||
          trend)
      ) {
        formattedDate += prefix + moment.utc(first).format(format);

        if (first.toString() !== last.toString()) {
          formattedDate += delimiter + moment.utc(last).format(format);
        }
      }
      return formattedDate;
    },
  }
);

export default DateRange;

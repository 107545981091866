import Backbone from 'backbone';

const ClientRolesModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.id = options.id;
  },

  url: function () {
    if (this.id) {
      return `authorization-service/clients/${this.client}/assignment-rules/${this.id}`;
    }

    return `authorization-service/clients/${this.client}/assignment-rules`;
  },
});

export default ClientRolesModel;

import Backbone from 'backbone';
import PageFilterModel from 'models/clients/measures/page-filter';

const PageFiltersCollection = Backbone.Collection.extend({
  model: PageFilterModel,

  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/pagefilters`;
  },
});

export default PageFiltersCollection;

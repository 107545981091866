import Backbone from 'backbone';

const UserSettingsModel = Backbone.Model.extend({
  idAttribute: 'namespace',

  initialize: function (attributes, opts) {},

  url: function (method) {
    if (this.get('namespace')) {
      switch (method) {
        case 'read':
        case 'delete':
          return `currentUser/settings/${this.get('namespace')}`;
        default:
          break;
      }
    }

    return `currentUser/settings`;
  },
});

export default UserSettingsModel;

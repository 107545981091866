import Backbone from 'backbone';

var RecommendedPriorityModel = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    measurementKey: 0,
    name: '',
    channelIdentifier: '',
    createDate: '',
    futureBehavior: {
      key: 0,
      name: '',
    },
    data: {
      ncount: 0,
      score: 0,
      latentImpact: 0,
      rankRatio: 0,
      fromDate: '',
      toDate: '',
      contributors: [],
    },
  },

  idAttribute: 'measurementKey',

  /**
   * Constructor
   */
  initialize: function (opts) {},

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default RecommendedPriorityModel;

import BaseController from 'controllers/base';

/**
 * Response Status Controller
 * */
class ResponseController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'response-status',
      actions: {
        'app:responseStatus': '_responseHandler',
        'location:change': 'handleLocationChange',
      },
    });
  }

  initialize() {}

  handleLocationChange(payload) {
    this.route = payload.route;
    this.previousRoute = payload.previousRoute;
  }

  handleRouteChange(payload) {
    this.route = payload.route || this.route;
    this.previousRoute = payload.previousRoute || this.previousRoute;
  }

  _responseHandler(payload) {
    var status = payload.status;

    switch (status) {
      case 404:
        this.publish('route:change', {
          route: 'error',
          response: payload,
        });
        break;

      case 500:
        this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*A server error occurred*',
        });
        break;

      case 503:
        this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*A server error occurred*',
        });
        break;

      case 403:
        this.publish('user:clients:details:get', {
          callback: (data) => {
            if (data.ssoEnabled) {
              this.publish('app:messageBanner', {
                theme: 'error',
                text: this.getString('general.errors.unauthorized'),
              });
            } else {
              this.handleForbidden();
            }
          },
        });

        break;

      case 401:
        this.handleForbidden();
        break;

      case 0:
        break;

      default:
        this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*An unknown error occurred*',
        });
        break;
    }
  }

  handleForbidden() {
    // var action, payload = {};
    //
    // if (!this.previousRoute){
    //   action = "app:logout";
    // }else{
    //   action = "route:change";
    //   payload = this.previousRoute;
    // }

    // this.publish("app:showModal", {
    //   modal: "default",
    //   action: action,
    //   payload: payload
    // });

    this.publish('app:logout', {
      previousRoute: this.previousRoute,
      expiredSession: true,
    });
  }
}

export default ResponseController;

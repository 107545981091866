import Backbone from 'backbone';
import featureFlags from 'core/featureFlags';

var ValidateFeedName = Backbone.Model.extend({
  initialize: function (opts) {},

  url: function () {
    if (featureFlags.hasFeature('new_ta-admin')) {
      return 'ta-admin/customFeed/validate_file_name';
    } else {
      return 'ta-config-beta/customFeed/validate_file_name';
    }
  },
});

export default ValidateFeedName;

export default {
  isAuthRequired: false,
  controller: 'authenticate',
  reset: {
    layout: 'reset_password',
    controller: 'authenticate',
  },
  forgot: {
    layout: 'forgot_password',
    controller: 'authenticate',
    email: {
      layout: 'forgot_password_email',
    },
    sms: {
      layout: 'forgot_password_sms',
    },
  },
};

import BaseController from 'controllers/base';
import ProjectsCollection from 'collections/clients/projects';
import ProjectSummariesCollection from 'collections/clients/projectsummaries';
import ProjectSummaryDataModel from 'models/clients/projects/topics/summary';
import permissions from 'core/permissions';
import $ from 'jquery';
import constants from 'core/constants';
import Permissions from 'core/permissions';
import _ from 'underscore';
import Backbone from 'backbone';

class ProjectsController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'projects:create': 'createProject',
        'projects:get': 'getProjects',
        'projects:fetch': 'fetchProjects',
        'projects:delete': 'delete',
        'projects:update': 'update',
        'projects:copy': 'copy',
        'products:get': 'getProducts',
        'projects:getReplayProjectId': 'getReplayProjectId',
        'projects:getSummary': 'getProjectSummary',
      },
      name: 'projects',
      dependsOn: ['user', 'measures', 'dashboards'],
    });
  }

  initialize(opts) {
    var options = opts || {};
    options.clientId = this.getClientId();

    this.collection = new ProjectsCollection(false, options);
    this.summaries = new ProjectSummariesCollection(false, options);

    if (this.authenticated()) {
      return this.fetchProjects();
    } else {
      return true;
    }
  }

  /**
   * Takes request to create project and routes to edit created project when done.
   * @param payload
   */
  createProject(payload) {
    var cb = _.bind(function (model, project) {
      // Redirect
      var projectId = project.projectId,
        route,
        routeType;

      switch (project.type) {
        case 'FEEDBACK':
          routeType = 'projects/';
          break;
        case 'REPLAY':
          routeType = 'replay/';
          break;
        case 'cxMEASURE':
          routeType = 'measure/';
          break;
        default:
          routeType = 'projects/';
          break;
      }

      route = `${routeType}${projectId}/edit`;

      this.summaries.add({
        projectId: projectId,
      });
      this.getProjects();
      this.publish('route:change', {
        route: route,
      });
    }, this);

    this.create(payload, cb);
  }

  /**
   * Create a new project on the server and publish the results.
   * @param payload
   * @param cb
   */
  create(payload, cb) {
    var options = {};

    // Wait for server response before adding
    options.wait = true;
    // Handle the success response
    options.success = cb;
    options.error = function (a, b, c) {
      console.log('Create Error', a, b, c);
    };
    // Create the project
    return this.collection.create(payload, options);
  }

  copy(payload) {
    var toCopy = this.collection.get(payload.projectId),
      name = 'Copy of ' + toCopy.get('name'),
      desc = toCopy.get('description'),
      sKey = payload.siteKey ? payload.siteKey : toCopy.get('siteKey'),
      copyModel = new Backbone.Model({
        name: name,
        description: desc,
        siteKey: sKey,
      }),
      options = {};

    copyModel.url = toCopy.url() + '/copy';

    options.success = _.bind(function (model, project) {
      var projectId = project.projectId,
        route = `projects/${projectId}/edit`;

      //Metrics is not automatically added by 'copy' endpoint, so we need to add it manually.
      project.metrics = {};

      this.collection.add(project);
      this.summaries.add({
        projectId: projectId,
        metrics: {},
      });
      if (payload.callback) {
        payload.callback({
          project: project,
          status: true,
        });
      }
      this.getProjects();
      if (payload.route) {
        this.publish('route:change', route);
      }

      this.publishToStaging(projectId, true);
    }, this);

    options.error = _.bind(function (model, project) {
      if (payload.callback) {
        payload.callback({
          project: project,
          status: false,
        });
      }
    }, this);

    copyModel.save(null, options);
  }

  /**
   * Automatically publish to staging after changes
   */
  publishToStaging(projectId, enabled, callback) {
    let cb = () => {};
    if (typeof callback === 'function') {
      cb = callback;
    }
    this.publish('publishing:publish', {
      environment: 'staging',
      projectId: projectId,
      configOnly: true,
      enabled: enabled,
      callback: cb,
    });
  }

  fetchProjects() {
    var def = $.Deferred();

    if (
      !Permissions.userRole('feedback_access') ||
      this.getCurrentUserMigrated()
    ) {
      this.getProjects();
      return true;
    }

    if (this.collection && this.summaries) {
      $.when(
        this.collection.fetch({ doNotAbort: true }),
        this.summaries.fetch({ doNotAbort: true })
      ).done(
        _.bind(function () {
          def.resolve();
          this.getProjects();
        }, this)
      );
    } else {
      def.resolve();
    }

    return def;
  }

  /**
   * Returns an object with projects, projectsummaries, and a list of them.
   */
  getProjects(payload = {}) {
    const { callback } = payload;
    let data = {};
    // Get single
    if (payload && payload.projectId) {
      const project = this.collection.get(payload.projectId);
      data = {
        project: (project && project.toJSON()) || null,
      };
      if (!data.project) {
        return {};
      } else if (callback) {
        callback(data.project);
      }
    } else {
      // Get all
      this.collection.sort();
      data = {
        projects: this.collection.toJSON(),
        summaries: this.summaries.toJSON(),
        list: this.getProjectList(),
      };
      this.publish('app:updateData', {
        projects: this.collection.toJSON(),
      });
      if (callback) {
        callback(data);
      }
    }
    this.publish('projects:data', data);
    return data;
  }

  /**
   * Removes a project from the backend
   */
  delete(payload) {
    var options = {},
      model = this.collection.get(payload.projectId);

    payload.refresh =
      typeof payload.refresh === 'undefined' ? true : payload.refresh;

    //If staged, disable on staging before trying to delete.
    if (payload.staged) {
      this.publishToStaging(
        payload.projectId,
        false,
        _.bind(function (status) {
          if (status.error) {
            payload.callback(false);
          } else {
            payload.staged = false;
            this.delete(payload);
          }
        }, this)
      );
    } else {
      options.wait = true;
      // On success broadcast the new data
      options.success = _.bind(function (model) {
        if (payload.refresh) {
          this.publish('projects:data', this.getProjects());
        }
        if (payload.callback) {
          payload.callback(true);
        }
      }, this);
      // Just update data on error for now
      options.error = _.bind(function (model, response) {
        if (payload.refresh) {
          this.publish('projects:data', this.getProjects());
        }
        if (payload.callback) {
          payload.callback(false);
        }
      }, this);

      model.destroy(options);
    }
  }

  /**
   * Updates a project from the backend
   */
  update(payload) {
    var options = {},
      model = this.collection.get(payload.projectId);

    // On success broadcast the new data
    options.success = _.bind(function (model) {
      this.publish('projects:data', this.getProjects());
    }, this);
    // Just update data on error for now
    options.error = _.bind(function (model) {
      this.publish('projects:data', this.getProjects());
    }, this);

    model.save(payload, options);
  }

  /**
   * Retrieve a list of projects for use in menus, dropdowns, etc.
   */
  getProjectList() {
    var list = [],
      action,
      projectType;

    if (permissions.userRole('feedback_create')) {
      action = 'edit';
    } else if (permissions.userRole('feedback_access')) {
      action = `analytics/${permissions.getInsightsUrl()}`;
    }

    this.collection.each(
      _.bind(function (project) {
        switch (project.get('type')) {
          case 'FEEDBACK':
            projectType = 'projects';
            break;
          case 'REPLAY':
            projectType = 'replay';
            break;
          default:
            projectType = 'projects';
            break;
        }

        list.push({
          name: project.get('name'),
          description: project.get('description'),

          url: `${constants.CLIENT_ROOT}${projectType}/${project.get(
            'projectId'
          )}/${action}`,
          action: 'route:change',
          payload: {
            route: `${projectType}/${project.get('projectId')}/${action}`,
          },
        });
      }, this)
    );

    return list;
  }

  getReplayProjectId() {
    var replayId = null;
    this.collection.each(function (project) {
      if (project.get('type') === 'REPLAY') {
        replayId = project.get('projectId');
      }
    });
    this.publish('projects:replayProjectId', replayId);
  }

  /**
   * get project summary data -- respondents, avgRating, etc.
   **/

  getProjectSummary(payload) {
    const { clientId, projectId, callback, dateRange } = payload;

    let options = {
      clientId: clientId,
      projectId: projectId,
      urlparams: {
        criteria: JSON.stringify({
          dateRange: dateRange,
        }),
      },
    };

    let model = new ProjectSummaryDataModel(false, options);
    const handler = () => {
      this.dispatch(callback, 'projects:summary:data', model.toJSON());
    };
    model.fetch({ error: handler }).then(handler);
  }
}

export default ProjectsController;

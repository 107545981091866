import md5 from 'crypto-js/md5';
var cxRAPIRequest = function (url, returnTokenAndSigOnly) {
  var token = new Date().getTime(),
    hpart = url.substr(url.indexOf('/services/')),
    sig = encodeURIComponent(md5(hpart + token)),
    noSig = url.indexOf('token=') === -1 && url.indexOf('sig=') === -1;

  if (returnTokenAndSigOnly) {
    return { token: token, sig: sig };
  }

  if (noSig) {
    //add token and sig query params to url
    url += url.indexOf('?') === -1 ? '?' : '&';
    url += 'token=' + token + '&sig=' + sig;
  }

  return url;
};

export default cxRAPIRequest;

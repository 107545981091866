import React from 'react';
import BasePanel from 'views/panels/base';

import { Text, FormInput, FormButton } from 'cx-component-library';

class ContentForgot extends BasePanel {
  constructor(props) {
    super(props);

    this.state = {
      userIdentifier: '',
      resetEmailLoading: false,
      resetSmsLoading: false,
      expired: location.hash.indexOf('expired') > -1,
    };
  }

  renderExpired() {
    return (
      <div>
        <Text theme='h2' type='div' className='gutter--bottom'>
          {this.getString('password.forgot.passwordExpired')}
        </Text>
        <Text theme='body' type='div' className='gutter--bottom'>
          {this.getString('password.forgot.passwordExpiredDesc')}
        </Text>
      </div>
    );
  }

  renderContent() {
    const { userIdentifier, resetEmailLoading, resetSmsLoading } = this.state;

    return (
      <div>
        <Text theme='h1' type='div' className='gutter--bottom' center>
          {this.getString('password.forgot.resetPassword')}
        </Text>
        <Text theme='body' type='div' className='gutter--bottom'>
          {this.getString('password.forgot.resetDescription')}
        </Text>
        <div className='gutter--bottom'>
          <FormInput
            placeholder={this.getString('password.forgot.placeholder')}
            value={userIdentifier}
            onChange={this.handleInputChange}
            size='xlarge'
            autoFocus
          />

          <div className='grid'>
            <div className='grid__item one-half gutter--top'>
              <FormButton
                className='gutter-p--left gutter-p--right'
                size='xlarge'
                disabled={!userIdentifier}
                onClick={this.handleResetEmail}
                icon={resetEmailLoading ? 'loader' : ''}
                iconSpin={resetEmailLoading}
                label={
                  !resetEmailLoading &&
                  this.getString('password.forgot.resetViaEmail')
                }
              />
            </div>
            <div className='grid__item one-half gutter--top'>
              <FormButton
                className='gutter-p--left gutter-p--right'
                size='xlarge'
                disabled={!userIdentifier}
                icon={resetSmsLoading ? 'loader' : ''}
                iconSpin={resetSmsLoading}
                onClick={this.handleResetSMS}
                label={
                  !resetSmsLoading &&
                  this.getString('password.forgot.resetViaSms')
                }
              />
            </div>
          </div>
        </div>
        <Text theme='body' type='div' className='gutter-half--bottom'>
          {this.getString('password.forgot.smsDescription')}
        </Text>
      </div>
    );
  }

  render() {
    const { expired } = this.state;

    return (
      <div className='content'>
        <div className='login login--desktop'>
          <div className='login__header'>
            <div className='logo-image' />
          </div>
          <div className='login__content gutter--bottom inline-block'>
            {expired && this.renderExpired()}
            {!expired && this.renderContent()}

            <div className='clearfix gutter--bottom'>
              <Text
                theme='body'
                type='div'
                className='left'
                linkTo={this.handleReturnToLoginClick}
              >
                {this.getString('password.forgot.returnToLogin')}
              </Text>
              {expired && (
                <Text
                  theme='body'
                  type='div'
                  className='right'
                  linkTo={this.handleResetPasswordClick}
                >
                  {this.getString('password.forgot.resetPassword')}
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleResetSMS = () => {
    const { userIdentifier } = this.state;

    this.setState({ resetSmsLoading: true });
    this.publish('authenticate:password:recover', {
      userIdentifier: userIdentifier,
      factorType: 'SMS',
      callback: (data) => {
        const { stateToken } = data;
        this.setState({ resetSmsLoading: false });
        this.changeRoute('forgot.sms', { stateToken });
      },
    });
  };

  handleResetEmail = () => {
    const { userIdentifier } = this.state;

    this.setState({ resetEmailLoading: true });
    this.publish('authenticate:password:recover', {
      userIdentifier: userIdentifier,
      factorType: 'EMAIL',
      callback: () => {
        this.setState({ resetEmailLoading: false });
        this.changeRoute('forgot.email');
      },
    });
  };

  handleInputChange = (e, userIdentifier) => {
    this.setState({ userIdentifier });
  };

  handleReturnToLoginClick = () => {
    this.changeRoute('login');
  };

  handleResetPasswordClick = () => {
    this.setState({ expired: false });
  };
}

export default ContentForgot;

import BaseController from 'controllers/base';
import _ from 'underscore';
import ProjectSettingsModel from 'models/clients/projects/project-settings';
import TemplatesCollection from 'collections/clients/templates';
import Constants from 'core/constants';
import Permissions from 'core/permissions';
/**
 * Manages data for feedback settings.
 */
class FeedbackSettingsController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'feedback-survey:data': 'updateSurveyData',
        'feedback-settings:fetch': 'updateSettingsData',
        'feedback-settings:save': 'saveSettingsData',
        'feedback-settings:disabled:fetch': 'isDisabled',
        'feedback-settings:templates:get': 'getTemplates',
        'feedback-settings:isStagingPublishing': 'checkStagingPublishStatus',
        'feedback-settings:publishedToStaging': 'publishedToStaging',
        'location:change': 'clearSettings',
      },
      name: 'feedback-settings',
      dependsOn: ['feedback-survey'],
    });
  }

  initialize(opts) {
    var options = opts || {};

    this.clientId = options.clientId = this.getClientId();
    this.options = options;
    options.clientId = this.clientId;
    this.surveySettings = new ProjectSettingsModel(false, options);
    this.surveySettings.type = 'FEEDBACK';
    this.publishingToStaging = false;
  }

  checkStagingPublishStatus(callback) {
    if (callback) {
      callback(this.publishingToStaging);
    }
  }

  /**
   *  Clears settings whenever we change routes. They should get loaded fresh.
   */
  clearSettings() {
    this.surveySettings.clear();
  }

  /**
   * settingsData object has an array of topic level configs.
   * survey level object and the topic level objects all have same property names: whitelistActive, whitelistData, blacklistActive, blacklistData
   * Recursively apply same logic to survey level and topic level configs, to update whitelistData / blacklistData
   * Primarily used to encodeURI or decodeURI the array of urls
   */
  _parseWhiteBlackLists(callback, settingsData) {
    if (settingsData.topics) {
      settingsData.topics.map(this._parseWhiteBlackLists.bind(this, callback));
    }
    if (settingsData.whitelistActive) {
      const list = settingsData.whitelistData || [];
      settingsData.whitelistData = list.map(callback);
    }
    if (settingsData.blacklistActive) {
      const list = settingsData.blacklistData || [];
      settingsData.blacklistData = list.map(callback);
    }
    return settingsData;
  }

  /**
   * Saves settings object, Returns successfully saved settings object
   */
  saveSettingsData(payload) {
    if (payload.projectId) {
      this.surveySettings.setProjectId(payload.projectId);
    }

    const reporturi = `${window.location.origin}${Constants.CLIENT_ROOT}feedback-admin/projects/${payload.projectId}/analytics/summary`;
    this.surveySettings.set(
      this._parseWhiteBlackLists(encodeURI, payload.settingsData)
    );
    this.surveySettings.set('datauri', '!!_surveyServer_!!');
    this.surveySettings.set('posturi', '!!_surveyPost_!!');
    this.surveySettings.set('reporturi', reporturi);
    this.surveySettings.save().then((response) => {
      response.saved = 'true';
      //Check if it's already in the middle of a previous publish.
      if (!this.publishingToStaging) {
        this.publishToStaging(payload.projectId);
      }

      if (payload.callback) {
        payload.callback();
      }
    });
  }

  publishedToStaging() {
    this.publishingToStaging = false;
  }

  /**
   * Automatically publish to staging after changes
   */
  publishToStaging(projectId) {
    this.publishingToStaging = true;
    let callback = (e) => {
      this.publishingToStaging = false;
      if (typeof e.status !== 'undefined' && e.status === 'success') {
        this.publish('published:staging', e);
      }
    };

    this.publish('publishing:publish', {
      environment: 'staging',
      projectId: projectId,
      configOnly: true,
      enabled: true,
      callback: callback,
    });
  }

  hasReplay() {
    return Permissions.userRole('replay');
  }

  /*
   ** compare current topics (settings topics) with incoming topics (project topics).
   ** update topic names, order if changed in survey builder and save automatically.
   ** remove topics from the settings object that are no longer in the project.
   ** add any new incoming topics to the settings object with default settings.
   */
  updateSettingsDataTopics(projectTopics, settingsDataTopics) {
    //sort topics by id to make sure comparisons are accurate
    projectTopics = _.sortBy(projectTopics, function (projectTopic) {
      return projectTopic.topicOrder;
    });
    settingsDataTopics = _.sortBy(
      settingsDataTopics,
      function (settingsDataTopic) {
        return settingsDataTopic.order;
      }
    );

    //compare topics for any changes (this will detect topic text or order changes and if there are any incoming or outgoing topics)
    const projectTopicsObj = projectTopics.map((t) => {
      return {
        id: t.topicId.toString(),
        order: t.topicOrder,
        text: t.topicText,
      };
    });
    const settingsDataTopicsObj = settingsDataTopics.map((t) => {
      return {
        id: t.id.toString(),
        order: t.order,
        text: t.topicText,
      };
    });

    let updated = !_.isEqual(projectTopicsObj, settingsDataTopicsObj);
    if (!projectTopics.length || !settingsDataTopics.length) {
      updated = false;
    }

    var currentIds = [];
    var incomingIds = [];
    var newIds = [];
    var outgoingIds = [];
    var sameIds = [];

    //get current settingsData topic ids
    _.each(settingsDataTopics, function (topic) {
      currentIds.push('' + topic.id);
    });

    //get incoming project topic ids
    _.each(projectTopics, function (topic) {
      incomingIds.push('' + topic.topicId);
    });

    //update topic name and topic order for same ids
    sameIds = _.intersection(incomingIds, currentIds);
    _.each(sameIds, function (sameId) {
      var projectTopic = _.find(projectTopics, function (topic) {
        return '' + topic.topicId === sameId;
      });
      settingsDataTopics = _.map(
        settingsDataTopics,
        function (settingsDataTopic) {
          settingsDataTopic.topicText =
            settingsDataTopic.id === sameId
              ? projectTopic.topicText
              : settingsDataTopic.topicText;
          settingsDataTopic.order =
            settingsDataTopic.id === sameId
              ? projectTopic.topicOrder
              : settingsDataTopic.order;
          return settingsDataTopic;
        }
      );
    });

    //determine new topic ids. ie. ids that are not in currentIds
    newIds = _.difference(incomingIds, currentIds);

    //determine topic ids we should remove from settings object because they are no longer in the project
    outgoingIds = _.difference(currentIds, incomingIds);

    // this will be the updated settings object we return
    var updatedSettingsDataTopics = settingsDataTopics || [];

    //remove outgoing topic settings from settings object
    _.each(outgoingIds, function (outgoingId) {
      updatedSettingsDataTopics = _.filter(
        updatedSettingsDataTopics,
        function (topic) {
          return topic.id !== outgoingId;
        }
      );
    });

    //add new topic settings to settings object with default whitelist settings
    _.each(newIds, function (newId) {
      _.each(projectTopics, function (projectTopic) {
        if ('' + projectTopic.topicId === newId) {
          updatedSettingsDataTopics.push({
            order: projectTopic.topicOrder,
            id: '' + projectTopic.topicId,
            answerId: '' + projectTopic.topicAnswerId,
            whitelistActive: false,
            whitelistData: [],
            topicText: projectTopic.topicText,
          });
        }
      });
    });
    return { topics: updatedSettingsDataTopics, updated: updated };
  }

  updateSurveyData(payload) {
    this.oldTopics = this.surveyTopics;
    this.surveyTopics = payload.topics;
  }

  /**
   * Returns stored settings object or saves default settings
   * Automatically saves topic settings every time there is a change in survey builder
   */
  updateSettingsData(payload) {
    if (payload && payload.projectId) {
      this.surveySettings.setProjectId(payload.projectId);

      this.surveySettings.fetch().done(
        _.bind(function (response) {
          var emptyResponse = Object.keys(response).length === 0;
          let settingsData = this._parseWhiteBlackLists(
            decodeURI,
            this.surveySettings.toJSON()
          );
          var updateTopics = this.oldTopics
            ? this.updateSettingsDataTopics(this.oldTopics, settingsData.topics)
            : {};
          settingsData.topics = updateTopics.updated
            ? updateTopics.topics
            : settingsData.topics;
          settingsData.saved = emptyResponse ? null : 'true'; //this is the idAttribute of the model

          var hasReplay = this.hasReplay();

          if (emptyResponse || updateTopics.updated) {
            this.saveSettingsData({
              settingsData: settingsData,
              projectId: payload.projectId,
            });
          } else {
            this.publish('feedback-settings:data', {
              settingsData: settingsData,
              hasReplay: hasReplay,
            });
          }
        }, this)
      );
    }
  }

  isDisabled(payload) {
    if (payload && payload.projectId) {
      this.surveySettings.setProjectId(payload.projectId);
      this.surveySettings.fetch().done(
        _.bind(function (response) {
          var disabled = response.disabled;
          var emptyResponse = Object.keys(response).length === 0;
          if (emptyResponse) {
            disabled = true;
          }
          this.publish('feedback-settings:disabled:data', {
            disabled: disabled,
            project: payload,
          });
        }, this)
      );
    }
  }

  getTemplates(payload) {
    var options = payload || {},
      callback = options.callback || null;

    var templates = new TemplatesCollection(false, {
      client: this.clientId,
    });

    _.extend(templates, {
      urlparams: {
        projectType: 'FEEDBACK',
      },
    });

    templates.fetch().then(() => {
      var data = {
        templates: templates.toJSON(),
      };
      if (callback) {
        callback(data);
      } else {
        this.publish('feedback-settings:templates:data', data);
      }
    });
  }
}

export default FeedbackSettingsController;

import Backbone from 'backbone';

var HierarchyFilterDetailsModel = Backbone.Model.extend({
  idAttribute: 'filterId',

  defaults: {
    userId: -1,
    clientId: -1,
    conditionGroup: {},
  },

  initialize: function (attributes, opts) {
    this.addOptions(opts);
  },

  addOptions: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;

    this.options = options;
  },

  url: function () {
    return `clients/${this.client}/measures/${
      this.measure
    }/hierarchyfilters/${this.get('filterId')}/filter`;
  },

  reset: function () {
    this.clear().set({
      userId: -1,
      clientId: -1,
      conditionGroup: {},
    });
  },

  serviceId: 'foresee-services',
});

export default HierarchyFilterDetailsModel;

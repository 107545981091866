import Backbone from 'backbone';
import _ from 'underscore';
import ImpactPeriodsCollection from 'collections/clients/measures/impacts/periods';

var ImpactsModel = Backbone.Model.extend({
  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measurementKey;
    this.options = options;
    this.options.parse = true;
    this.nps = options.nps;

    this.periods = new ImpactPeriodsCollection(options);
  },

  parse: function (data) {
    if (data && data.results && data.results.length) {
      this.periods.reset(data.results, this.options);
    }
    delete data.results;
    return data;
  },

  toJSON: function () {
    return _.extend(
      {
        periods: this.periods.toJSON(),
      },
      this.attributes
    );
  },

  serviceId: 'foresee-services',

  url: function () {
    if (this.nps) {
      return `clients/${this.client}/measures/${this.measure}/impacts/nps`;
    }
    return `clients/${this.client}/measures/${this.measure}/impacts`;
  },

  getCoordinates: function () {
    this.impactGroups = [];
    this.mapPaddings = [];
    this.chartWidth = 100;
    this.chartHeight = 100;

    this.createImpactGroups();
    this.createMapPadding();
    this.impactGroups.forEach((impactGroup, groupIndex) => {
      impactGroup.forEach((model) => {
        var score = model.get('score') || 0,
          impact = model.get('impact') || 0,
          impactRatio =
            (impact - this.mapPaddings[groupIndex].minImpact) /
            this.mapPaddings[groupIndex].impactRange,
          scoreRatio =
            (score - this.mapPaddings[groupIndex].minScore) /
            this.mapPaddings[groupIndex].scoreRange,
          xPos = impactRatio * this.chartWidth,
          yPos = scoreRatio * this.chartHeight;

        model.set('ratioState', 'IMPACT');
        model.set('impactRatio', impactRatio);
        model.set('scoreRatio', scoreRatio);
        model.set('x', xPos);
        model.set('y', yPos);
        model.set('isTopPriority', xPos > 50 && yPos < 50);
      });
    });
  },

  /**
   * Separate the collection into impact groups
   * in order to calculate the min and max boundaries for the impacts
   * at a given time.
   */
  createImpactGroups: function () {
    this.periods.forEach((period) => {
      if (period.latents) {
        this.impactGroups.push(period.latents.where({ latentType: 'Element' }));
      }
    });
  },

  /**
   *  Creates the map padding for each impact group
   */
  createMapPadding: function () {
    this.impactGroups.forEach((group, index) => {
      this.mapPaddings[index] = this.setMaxMin(group);
    });
  },

  /**
   * Create the boundary of the priority map by using the minimum
   * and maximum scores and impacts.
   *
   * @param impacts are the collection of models
   */
  setMaxMin: function (impacts) {
    var minScore = parseFloat(_.min(impacts.map((i) => i.get('score')))),
      maxScore = parseFloat(_.max(impacts.map((i) => i.get('score')))),
      minImpact = parseFloat(_.min(impacts.map((i) => i.get('impact')))),
      maxImpact = parseFloat(_.max(impacts.map((i) => i.get('impact')))),
      scoreRange = maxScore - minScore,
      impactRange = maxImpact - minImpact;

    // by subtracting the min score by the score range and increasing the score range by 10
    // we create a range where the data will be buffered by 5 points on each side
    minScore -= 5;
    scoreRange += 10;

    // Add left and right padding to the map by increasing the range and decreasing the minimum
    // impact by a percentage
    impactRange += 0.5;
    minImpact -= 0.25;
    return {
      minImpact: minImpact,
      impactRange: impactRange,
      minScore: minScore,
      scoreRange: scoreRange,
    };
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default ImpactsModel;

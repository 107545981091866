import Backbone from 'backbone';
import moment from 'moment';
import _ from 'underscore';
import Constants from 'core/constants';
import i18n from 'core/i18n';

const BaseSeriesModel = Backbone.Model.extend({
  /**
   * Given a string of form 'a.b.c' will return value from that location in strings.
   * @param string
   */
  getString(string, model) {
    var fn = [].constructor.constructor('return arguments[0].' + string),
      str = '';
    if (string) {
      try {
        str = fn(i18n.strings);
        return model ? _.template(str)(model) : str;
      } catch (e) {
        return '**_' + string + '_**';
      }
    } else {
      return '';
    }
  },

  _parseRange(range) {
    const start = new moment(range.f).format(Constants.dateDisplay);
    const end = new moment(range.l).format(Constants.dateDisplay);
    return `${start} to ${end}`;
  },

  _getNodeTimestamp(node) {
    return node.name;
  },

  _getEndDate(currentIndex, nodes, seriesDateRange) {
    const isLast = currentIndex === nodes.length - 1;
    if (isLast) {
      return seriesDateRange.l;
    } else {
      const nextNode = nodes[currentIndex + 1];
      const endDate = new moment(this._getNodeTimestamp(nextNode));
      return endDate.subtract(1, 'day');
    }
  },

  _getDates(date, dimension, endDate, rangeStart) {
    let categoryName = date;
    const startDate = new moment(date);
    endDate = new moment(endDate);
    if (dimension === 'HOURLY') {
      const startTime = startDate.format('h A');
      const endTime = startDate.clone().add(1, 'hours').format('h A');
      categoryName = `${startDate.format(
        Constants.dateDisplay
      )} ${startTime} - ${endTime}`;
    } else if (dimension === 'DAILY') {
      categoryName = startDate.format(Constants.dateDisplay);
    } else if (dimension === 'WEEKLY') {
      categoryName = this._parseRange({
        f: startDate,
        l: endDate,
      });
    } else if (dimension === 'MONTHLY') {
      categoryName = startDate.format("MMM 'YY");
    } else if (dimension === 'QUARTERLY') {
      categoryName = 'Q' + new moment(date).format('Q, YYYY');
    } else if (dimension === 'YEARLY') {
      categoryName = startDate.format('YYYY');
    }

    return {
      categoryName: categoryName,
      startDate,
      endDate,
      range:
        dimension === 'DAILY' || dimension === 'HOURLY'
          ? categoryName
          : this._parseRange({
              f: rangeStart ? new moment(rangeStart) : startDate,
              l: endDate,
            }),
    };
  },

  _getBenchmarkMetrics: function () {
    return ['BMK', 'BCA', 'BCM', 'BCX'];
  },

  _getResponseKey: function (measurementKey) {
    return `${measurementKey}RC`;
  },

  _getMeasuresSeriesMeta: function (metadata, scoreKey, nodeName) {
    if (metadata && metadata[scoreKey]) {
      const { type, groups } = metadata[scoreKey];
      const groupedType = _.contains(
        ['QUESGROUP', 'NPSSEG', 'PARMGROUP'],
        type
      );
      if (groupedType && groups && nodeName && groups[nodeName]) {
        if (type === 'NPSSEG') {
          return {
            ...groups[nodeName],
            label: this.getString(
              'dashboards.npsSegments.' + groups[nodeName].abbr
            ),
          };
        }
        let meta = {
          ...groups[nodeName],
        };
        if (meta.abbr === 'AG' || meta.abbr === 'PG') {
          meta.answerKey = meta.key;
          meta.answerLabel = meta.label;
        }
        return meta;
      } else if (type === 'RESPCOUNT') {
        if (metadata[scoreKey] && metadata[scoreKey].label) {
          return {
            label: this.getString('measures.respondents'),
            dataSourceLabel: metadata[scoreKey].label,
          };
        }
        return {
          label: this.getString('measures.respondents'),
        };
      } else if (type === 'RESPPCT') {
        if (metadata[scoreKey] && metadata[scoreKey].label) {
          return {
            label: this.getString('measures.respondents') + ' %',
            dataSourceLabel: metadata[scoreKey].label,
          };
        }
        return {
          label: this.getString('measures.respondents') + ' %',
        };
      } else if (type === 'SATSCORE') {
        return {
          label: this.getString('dashboards.metricLabels.SAT'),
        };
      } else if (type === 'NPSSCORE') {
        return {
          label: this.getString('dashboards.metricLabels.NPS'),
        };
      } else if (type === 'RATING') {
        return {
          label: this.getString('dashboards.metricLabels.rating'),
        };
      } else if (_.contains(['BCSAVG', 'BCSMIN', 'BCSMAX'], type)) {
        return {
          ...metadata[scoreKey],
          label: `${metadata[scoreKey].label} (${this.getString(
            `dashboards.benchmarkMetric.${type}`
          )})`,
        };
      }
      let meta = {
        ...metadata[scoreKey],
        label: metadata[scoreKey].label
          ? metadata[scoreKey].label.replace(/(<([^>]+)>)/gi, '')
          : '',
      };
      if (meta.abbr === 'QG' || meta.abbr === 'PG') {
        meta.questionKey = meta.key;
        meta.questionLabel = meta.label;
      }
      return meta;
    }
    return {
      label: nodeName || scoreKey,
    };
  },

  _getSeriesType: function () {
    return 'cxmeasure';
  },

  _getMeasuresSeries: function (
    metadata,
    seriesMeta,
    nodes,
    dimensions,
    scoreKey,
    aggregates,
    measurementKey,
    metric
  ) {
    const responseKey = this._getResponseKey(measurementKey);
    const responseMeta = this._getMeasuresSeriesMeta(metadata, responseKey);
    const questionDimension = _.findWhere(dimensions, { type: 'QUESTION' });
    const paramDimension = _.findWhere(dimensions, {
      type: Constants.FILTER_TYPE_PARAM,
    });
    const npsDimension = _.findWhere(dimensions, { type: 'NSG' });
    const seriesDateRange = seriesMeta.dateRange;
    const points = [];
    nodes = nodes || [];
    nodes.forEach((n, index) => {
      let meta = {};
      let dates = {};
      if (questionDimension || paramDimension) {
        const sType = questionDimension ? 'QG' : 'PG';
        const sKey = questionDimension
          ? questionDimension.key
          : paramDimension.key;
        meta = this._getMeasuresSeriesMeta(metadata, `${sKey}${sType}`, n.name);
        meta.categoryName = meta.label;
      }
      if (npsDimension) {
        meta = this._getMeasuresSeriesMeta(
          metadata,
          `${measurementKey}NSG`,
          n.name
        );
        meta.categoryName = meta.label;
      }
      const dateDimension = _.findWhere(dimensions, { type: 'DATE' });
      if (dimensions && dimensions.length && dimensions[0].type === 'DATE') {
        dates = this._getDates(
          n.name,
          dateDimension.key,
          this._getEndDate(index, nodes, seriesDateRange),
          index === 0 && seriesDateRange ? seriesDateRange.f : n.name
        );
      }
      const hasValue =
        n.metrics && (n.metrics[scoreKey] || n.metrics[scoreKey] === 0);
      if (meta.dummy) {
        return;
      }
      points.push({
        ...meta,
        ...dates,
        name: n.name,
        rank: n.rank,
        value: hasValue ? n.metrics[scoreKey] : null,
        responses:
          n.metrics && n.metrics[responseKey] ? n.metrics[responseKey] : 0,
      });
    });

    if (questionDimension || paramDimension) {
      const sType = questionDimension ? 'QG' : 'PG';
      const sKey = questionDimension
        ? questionDimension.key
        : paramDimension.key;
      const dimensionMeta = this._getMeasuresSeriesMeta(
        metadata,
        `${sKey}${sType}`
      );
      seriesMeta.dimensionLabel = dimensionMeta.label;
      if (!seriesMeta.answerKey) {
        seriesMeta = {
          ...dimensionMeta,
          ...seriesMeta,
        };
      }
    }
    if (npsDimension) {
      const dimensionMeta = this._getMeasuresSeriesMeta(
        metadata,
        `${measurementKey}NSG`
      );
      seriesMeta.dimensionLabel = dimensionMeta.label;
    }

    const metricMeta = this._getMeasuresSeriesMeta(metadata, scoreKey);
    return {
      ...seriesMeta,
      type: _.contains(this._getBenchmarkMetrics(), metric.type)
        ? 'benchmark'
        : this._getSeriesType(),
      measurementKey,
      dataSourceLabel: responseMeta.dataSourceLabel,
      metricCriteria: metric,
      metricType: metric.type,
      metricKey: metric.key,
      metricReference: metric.metricReference,
      metricLabel: metricMeta ? metricMeta.label : metric.key,
      label: seriesMeta.label,
      aggregate: aggregates[scoreKey],
      responses: aggregates[responseKey] ? aggregates[responseKey] : 0,
      points: points,
    };
  },

  parseSeries: function (data) {
    const series = [];
    const aggregates = data.aggregates || {};
    const returnedCriteria = data.criteria;
    const dateRange = returnedCriteria.dateRange;
    const metadata = data.metadata || {};
    const {
      measurementKey,
      metrics,
      dimensions,
      useMeasureLabel,
      originalCriteria,
    } = this;
    const nodes = data.nodes || [];
    const bmk = _.findWhere(metrics, { type: 'BMK' });
    metrics.forEach((metric, index) => {
      if (metric.type === 'BMK') {
        return;
      }
      const useMeasureKey = !_.contains(['LS', 'QA', 'AD', 'AV'], metric.type);
      let scoreKey = useMeasureKey
        ? `${measurementKey}${metric.type}`
        : `${metric.key}${metric.type}`;
      if (_.contains(this._getBenchmarkMetrics(), metric.type)) {
        if (!bmk) {
          return;
        }
        scoreKey = `${bmk.key}.${metric.key}${metric.type}`;
      }
      if (metric.type === 'RA') {
        if (returnedCriteria.metrics[index].type === 'RATING') {
          scoreKey = `${returnedCriteria.metrics[index].key}RA`;
        }
      }
      if (dimensions.length === 2) {
        const groupKey =
          originalCriteria.groupBy === 'METRIC'
            ? `${originalCriteria.groupMetric.key}${originalCriteria.groupMetric.type}`
            : false;
        nodes.forEach((node, index) => {
          let seriesMeta = {};
          if (dimensions[1].type === 'DATE') {
            seriesMeta = this._getDates(
              index === 0 ? dateRange.f : node.name,
              dimensions[1].key,
              this._getEndDate(index, nodes, dateRange),
              index === 0 && dateRange ? dateRange.f : node.name
            );
          } else {
            seriesMeta = this._getMeasuresSeriesMeta(
              metadata,
              groupKey,
              node.name
            );
          }
          seriesMeta.dateRange = dateRange;
          seriesMeta.parsedDateRange = this._parseRange(dateRange);
          const serie = this._getMeasuresSeries(
            metadata,
            seriesMeta,
            node.nodes,
            dimensions,
            scoreKey,
            aggregates,
            measurementKey,
            metric
          );
          if (!serie.dummy) {
            series.push(serie);
          }
        });
      } else {
        let seriesMeta = this._getMeasuresSeriesMeta(metadata, scoreKey);
        if (useMeasureLabel) {
          const responseMeta = this._getMeasuresSeriesMeta(
            metadata,
            this._getResponseKey(measurementKey)
          );
          seriesMeta.label = responseMeta.dataSourceLabel;
        }
        seriesMeta.dateRange = dateRange;
        seriesMeta.parsedDateRange = this._parseRange(dateRange);
        const serie = this._getMeasuresSeries(
          metadata,
          seriesMeta,
          nodes,
          dimensions,
          scoreKey,
          aggregates,
          measurementKey,
          metric
        );
        if (!serie.dummy) {
          series.push(serie);
        }
      }
    });
    return series;
  },
});

export default BaseSeriesModel;

import BaseController from 'controllers/base';

class SurveysController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {},
      name: 'surveys',
      dependsOn: ['user', 'projects', 'measures', 'dashboards'],
    });
  }
}

export default SurveysController;

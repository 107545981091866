import Backbone from 'backbone';
import _ from 'underscore';
import ClientRolesModel from 'models/authorization-service/clients/roles';

const ClientRolesCollection = Backbone.Collection.extend({
  model: ClientRolesModel,

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.defaultRole = options.defaultRole;
  },

  parse: function (data) {
    return data.items;
  },

  setUserNames: function (users) {
    this.models.map((model) => {
      const user = users.find((user) => user.userId === model.get('updatedBy'));
      return model.setUserNames(user);
    });
  },

  getUserIds: function () {
    return _.compact(
      _.uniq(
        this.models.map((model) => {
          return model.getUserIds();
        })
      )
    );
  },

  url: function () {
    if (this.defaultRole) {
      return `authorization-service/clients/${this.client}/roles?defaultRole=true`;
    }

    return `authorization-service/clients/${this.client}/roles`;
  },
});

export default ClientRolesCollection;

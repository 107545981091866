import Constants from '../constants';

export default function _addDimensionForKPI(card) {
  const chartType = card.metadata.attributes.chartType;
  if (chartType !== Constants.DASHBOARD.chartTypes.kpi) {
    return card;
  }
  const dateRange = card.metadata.attributes.dateRange;
  const sparkline = card.metadata.attributes.sparkline;
  const datasource = card.metadata.attributes.dataSources[0];

  // 1 year  - Month (Default)
  const dimension = {
    type: 'DATE',
    key: 'MONTHLY',
  };

  if (
    sparkline === Constants.DASHBOARD.kpi.sparkline.trend ||
    sparkline === Constants.DASHBOARD.kpi.sparkline.bar
  ) {
    // IA source 24hrs - hourly
    if (
      datasource.schemaId === Constants.SCHEMAS.INTERACTION_ANALYTICS &&
      ((dateRange.r === 'D' && dateRange.n === 0) ||
        (dateRange.r === 'C' && dateRange.n === 1))
    ) {
      dimension.key = 'HOURLY';
    }
    // 1 month - Day
    else if (
      (dateRange.r === 'M' && dateRange.n < 2) ||
      (dateRange.r === 'C' && dateRange.n < 32) ||
      dateRange.r === 'W' ||
      (dateRange.r === 'D' && dateRange.n < 32)
    ) {
      dimension.key = 'DAILY';
    }
    // 1 quarter - Week
    else if (
      dateRange.r === 'Q' ||
      (dateRange.r === 'C' && dateRange.n > 32 && dateRange.n < 93)
    ) {
      dimension.key = 'WEEKLY';
    }
    // > 2 Year - Quarter
    else if (dateRange.r === 'C' && dateRange.n > 731) {
      dimension.key = 'QUARTERLY';
    }
  }

  return {
    ...card,
    metadata: {
      ...card.metadata,
      attributes: { ...card.metadata.attributes, dimensions: [dimension] },
    },
  };
}

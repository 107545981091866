import _ from 'underscore';
import { cloneDeep } from 'lodash';
import BaseController from 'controllers/base';
import HierarchyFilterModel from 'models/clients/measures/hierarchyfilter';
import HierarchyFilterDetailsModel from 'models/clients/measures/hierarchyfilter-details';
import FiltersCollection from 'collections/clients/measures/hierarchyfilters';
import TreeModel from 'models/tree';
import Constants from 'core/constants';
const HIERARCHY_FILTER = 'HIERARCHY_FILTER';
const HIERARCHY_LEVEL = 'HIERARCHY_LEVEL';
const HIERARCHY_ATTRIBUTE = 'HIERARCHY_ATTRIBUTE';
/**
 * Controller for manipulating hierarchy filters
 */
class HierarchyFiltersController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'hierarchyfilters',
      actions: {
        'measures:initialized': '_getHierarchy',
        'hierarchyfilters:ancestries': '_getHierarchyAncestries',
        'hierarchyfilters:nodes:ancestries': '_getHierarchyAncestriesByNodes',
        'hierarchyfilters:currentMeasure': 'setCurrentMeasure',
        'hierarchyfilters:save': 'saveFilter',
        'hierarchyfilters:update': 'updateFilter',
        'hierarchyfilters:delete': 'deleteFilter',
        'hierarchyfilters:set': 'setFilter',
        'hierarchyfilters:measurementKey:filterId:set': '_setMeasureAndFilter',
        'hierarchyfilters:get': 'getFilters',
        'hierarchyfilters:details:get': 'getFilterById',
        'hierarchyfilters:reset': 'resetFilter',
        'hierarchy-filters:reset:noaction': 'resetFilterNoAction',
        'hierarchyfilters:attribute:edit': '_editAttribute',
        'hierarchyfilters:tree:edit': '_editTree',
        'hierarchyfilters:tree:nodes:get': '_fetchAdditionalNodes',
        'hierarchyfilters:filter:changed': '_filterChanged',
        'hierarchyfilters:filter:search': '_filterSearch',
        'hierarchy:tree:branches:get': '_fetchBranchNodes',
      },
      dependsOn: ['user', 'hierarchy'],
    });
    this.currentMeasure = {
      measurementKey: false,
      factType: false,
    };
    this.setInitialFilters();
  }

  initialize(opts) {
    this.client = this.getClientId();
    this.filter = new HierarchyFilterDetailsModel();
  }

  _getOptions() {
    return {
      clientId: this.client,
      measure: this.currentMeasure.measurementKey,
    };
  }

  _filterChanged(filter) {
    this.publish('storage:set', { key: this.getKey(), data: { filter } });
  }

  setInitialFilters() {
    this.tree = false;
    this.attributes = [];
    this.filterNodes = [];
  }

  deleteFilter(payload) {
    const { filter, callback } = payload;
    const filterModel = new HierarchyFilterModel(filter, this._getOptions());
    filterModel.destroy().then(() => {
      if (callback) {
        callback(filter);
      }
      this.publish('hierarchyfilters:filter:deleted', filter);
    });
  }

  updateFilter(replacementFilter) {
    this._populateModel();
    this.filter.set('filterId', replacementFilter.filterId);
    this.filter.set('externalId', replacementFilter.externalId);
    this.filter.set('name', replacementFilter.name);
    this.filter.set('temp', false);
    const filterDetailsModel = new HierarchyFilterDetailsModel(
      this.filter.toJSON(),
      this._getOptions()
    );
    filterDetailsModel.save().then(() => {
      this.publish(
        'hierarchyfilters:filter:changed',
        filterDetailsModel.toJSON()
      );
      this._publishFilter();
    });
  }

  saveTempFilter(searchAncestry) {
    this._populateModel();
    if (!this.filter.get('temp')) {
      this.filter.set('filterId', null);
      this.filter.set('externalId', null);
    }
    this.filter.set('temp', true);
    const filterModel = new HierarchyFilterModel(
      this.filter.toJSON(),
      this._getOptions()
    );
    filterModel.save().then(() => {
      this.filter = new HierarchyFilterDetailsModel(
        filterModel.toJSON(),
        this._getOptions()
      );
      this._publishFilter(searchAncestry);
      this.publish('hierarchyfilters:filter:changed', this.filter.toJSON());
    });
  }

  saveFilter(filterName) {
    this._populateModel();
    this.filter.set('name', filterName);
    this.filter.set('temp', false);
    const filterModel = new HierarchyFilterModel(
      this.filter.toJSON(),
      this._getOptions()
    );
    filterModel.save().then(() => {
      this.publish('hierarchyfilters:filter:changed', filterModel.toJSON());
      this._publishFilter();
    });
  }

  _setMeasureAndFilter(payload) {
    const { measurementKey, filterId } = payload;
    this.publish('cxmeasure:key:get', {
      measurementKey,
      callback: (measure) => {
        this.setCurrentMeasure(measure, filterId);
      },
      errorCallback: (m, r) => {
        //TODO
      },
    });
  }

  setFilter(payload) {
    const { isInitialization, noReload, searchAncestry, ...filter } = payload;
    const errorHandler = {
      error: () => {},
    };
    this.filter = new HierarchyFilterDetailsModel(filter, this._getOptions());
    this.filter.fetch(errorHandler).then(() => {
      this.tree = this.parseTree(this.tree.root().toJSON());
      this.selectAllDescendants();
      this.attributes = this.parseAttributes(this.attributes);
      this._publishFilter(searchAncestry);
      this.publish('hierarchyfilters:filter:changed', {
        ...this.filter.toJSON(),
        noReload,
        isInitialization,
      });
    });
  }

  getFilterById(filter) {
    const options = {
      clientId: this.client,
      measure: filter.measurementKey,
    };
    const model = new HierarchyFilterDetailsModel(filter, options);
    const errorHandler = {
      error: () => {},
    };
    model.fetch(errorHandler).then(() => {
      this.publish('app:getData', {
        key: 'hierarchyFilterDetails',
        callback: (currentHierarchyFilterDetails) => {
          const hierarchyFilterDetail = model.toJSON();
          const hierarchyFilterDetails =
            cloneDeep(currentHierarchyFilterDetails) || {};
          hierarchyFilterDetails[model.get('filterId')] = hierarchyFilterDetail;
          this.publish('app:updateData', { hierarchyFilterDetails });
          if (filter.callback) {
            filter.callback(hierarchyFilterDetail);
          }
        },
      });
    });
  }

  selectAllDescendants() {
    const conditions = _.where(this.filter.get('conditionGroup').conditions, {
      name: HIERARCHY_LEVEL,
    });
    conditions.forEach((c) => {
      c.args.forEach((arg) => {
        const node = this.tree.find(arg);
        if (node && !node.isLeaf()) {
          const descendents = node.allDescendants();
          descendents.forEach((d) => {
            d.set('selected', true);
          });
        }
      });
    });
  }

  getFilters(payload) {
    const { callback } = payload;
    const filters = new FiltersCollection(this._getOptions());
    filters.fetch().then(() => {
      if (callback) {
        callback(filters.toJSON());
      } else {
        this.publish('hierarchyfilters:get:data', filters.toJSON());
      }
    });
  }

  resetFilterNoAction() {
    this.filter.reset();
  }

  resetFilter(payload = {}) {
    const { callback, isInitialization } = payload;
    const reset = () => {
      return new Promise((resolve) => {
        this.filter.reset();
        this.tree = this.parseTree(this.tree.root().toJSON());
        this.tree.root().set('selected', false);
        this.attributes = this.parseAttributes(this.attributes);
        this._publishFilter();
        this.publish('hierarchyfilters:filter:changed', {
          ...this.filter.toJSON(),
          isInitialization,
        });
        resolve();
      });
    };
    if (this.tree) {
      reset().then(() => {
        if (callback) {
          callback();
        }
      });
    } else if (callback) {
      callback();
    }
  }

  _getHierarchy(filterId) {
    const { hierarchyId } = this.currentMeasure;
    if (hierarchyId) {
      this.publish('hierarchy:tree:get', {
        xyzOrigin: 'hierarchy-filters',
        capabilityName: this.capability,
        hierarchyId,
        callback: this._handleHierarchyTree.bind(this, filterId),
        error: (payload, err) => {
          if (err?.status === 403) {
            // do nothing
          } else {
            this.publish('app:responseStatus', err);
          }
        },
      });
    }
  }

  _getHierarchyAncestries({ searchTerm, callback, onError }) {
    const args = searchTerm ? [searchTerm] : [];
    const { hierarchyId } = this.currentMeasure;
    const payload = {
      onError,
      callback,
      hierarchyId,
      effectiveAsOf: null,
      conditionGroup: {
        conditionGroupType: 'HIERARCHY_FILTER',
        operator: 'AND',
        conditions: [
          {
            subject: {
              type: 'HIERARCHY_ATTRIBUTE',
              id: '__label__',
            },
            operator: 'LIKE',
            valueType: 'TEXT',
            args: args,
          },
        ],
      },
    };
    if (hierarchyId) {
      this.publish('hierarchy:ancestries:get', payload);
    }
  }

  _getHierarchyAncestriesByNodes({ args, callback }) {
    const { hierarchyId } = this.currentMeasure;
    const payload = {
      hierarchyId,
      conditionGroup: {
        conditionGroupType: HIERARCHY_FILTER,
        operator: 'AND',
        conditions: [
          {
            subject: {
              type: HIERARCHY_ATTRIBUTE,
              id: '__value__',
            },
            operator: 'IN',
            valueType: 'TEXT',
            args,
          },
        ],
      },
      callback,
    };
    this.publish('hierarchy:ancestries:get', payload);
  }

  _fetchAdditionalNodes(payload) {
    const { hierarchyId } = this.currentMeasure;
    this.publish('hierarchy:tree:get', {
      capabilityName: Constants.CAPABILITIES.CXMEASURE_ACCESS,
      hierarchyId,
      startNodeId: payload.nodeId,
      callback: (data) => {
        const node = data.tree.toJSON();
        let tree = this.tree;
        const parent = new TreeModel(this.tree.root().find(node.id), {})
          .attributes;
        const nodes = this.parseTree(node, parent);
        let n = tree.find(payload.nodeId);
        n.add(nodes.nodes().models);
        this.tree = tree;
        this._publishFilter();
        if (payload.callback) {
          payload.callback(this.tree);
        }
      },
    });
  }

  _fetchBranchNodes(payload) {
    const { nodeId, siblings, callback = () => {}, level } = payload;
    const { hierarchyId } = this.currentMeasure;
    this.publish('hierarchy:tree:get', {
      capabilityName: Constants.CAPABILITIES.CXMEASURE_ACCESS,
      hierarchyId,
      startNodeId: nodeId,
      level,
      callback: (data) => {
        const node = data.tree.toJSON();
        const parent = new TreeModel(this.tree.root().find(node.id), {})
          .attributes;
        const nodes = this.parseTree(node, parent);
        const tree = this.tree;
        const n = tree.find(nodeId);
        if (nodes.nodes()) {
          n.add(nodes.nodes().models);
          nodes.nodes().models.forEach((child) => this._addBranch(child, tree));
        }
        this.tree = tree;
        if (siblings.length) {
          const startNodeId = siblings.pop();
          this.publish('hierarchy:tree:branches:get', {
            ...payload,
            nodeId: startNodeId.id,
            siblings: siblings,
            callback: callback,
          });
        } else {
          callback();
        }
      },
    });
  }

  _addBranch(node, tree) {
    const n = tree.find(node.id);
    if (node.nodes()) {
      n.add(node.nodes().models);
      node.nodes().models.forEach((child) => this._addBranch(child, tree));
    }
  }

  _filterSearch() {
    const { hierarchyId } = this.currentMeasure;
    if (!this.tree) {
      return;
    }
    this.publish('hierarchy:levels:get', {
      hierarchyId: hierarchyId,
      callback: (data) => {
        const levels = Object.values(data);
        const payload = {
          levels: levels.length,
        };
        this._loadTreeNodes(payload);
      },
    });
  }

  _loadTreeNodes(payload) {
    const {
      callback,
      levels,
      siblings = [],
      startNode = this.tree.id,
    } = payload;

    const treeGroups = _.groupBy(this.tree.allDescendants(), (node) => {
      return node.get('levelName');
    });
    const treeLevel = _.size(treeGroups);
    const level = payload.level ? payload.level : 0;
    if (treeLevel < levels) {
      this.publish('hierarchy:tree:branches:get', {
        nodeId: startNode,
        siblings: siblings,
        level,
        callback: () => {
          const treeGroups = _.groupBy(this.tree.allDescendants(), (node) => {
            return node.get('levelName');
          });
          const treeLevel = _.size(treeGroups);
          const n = this.tree.find(startNode);
          let siblings = [];
          if (treeLevel < levels && !n.isLeaf()) {
            siblings = n.get('children');
            if (!siblings || siblings.length === 0) {
              console.log('hierarchy-filters isLeaf=false but no children');
              return;
            }
            payload = {
              ...payload,
              startNode: siblings[0].id,
              level: level + 1,
              siblings: siblings,
            };
            this._loadTreeNodes(payload);
          }
        },
      });
    } else {
      callback && callback();
      this._publishFilter();
    }
  }

  _handleHierarchyTree(filterId, data) {
    this.tree = this.parseTree(data.tree.toJSON());
    if (
      this.tree
        .allDescendants()
        .filter((i) => !i._nodes.length && !i.get('leafNode'))
    ) {
      this._filterSearch();
    }
    this.publish('master-data:type:get', {
      id: data[0].attributes.__typeId__,
      callback: (data) => {
        this.publish('master-data:type:getAttributes', {
          callback: (attributes) => {
            attributes = _.filter(attributes, (a) => {
              return a.enum;
            });
            this.attributes = this.parseAttributes(
              attributes.map((a) => {
                return {
                  name: a.title,
                  type: a.key,
                  values: a.enum.map((v) => {
                    return { value: v, label: v };
                  }),
                };
              })
            );
            this._checkForStoredFilter(filterId);
          },
        });
      },
    });
  }

  _checkForStoredFilter(filterId) {
    if (filterId) {
      this.setFilter({ filterId, searchAncestry: true });
      return;
    }
    this.publish('storage:get', {
      key: this.getKey(),
      callback: function (payload) {
        if (
          !payload.expired &&
          payload.data &&
          payload.data.data &&
          payload.data.data.filter.filterId
        ) {
          this.setFilter({ ...payload.data.data.filter, searchAncestry: true });
        } else {
          this.resetFilter({ isInitialization: true });
        }
      }.bind(this),
    });
  }

  setCurrentMeasure(measure, filterId) {
    this.currentMeasure = measure;
    this.setInitialFilters();
    this._publishFilter();
    this._getHierarchy(filterId);
  }

  _isFilterEmpty() {
    let attrFound = false;
    this.attributes.forEach((attr) => {
      if (_.findWhere(attr.values, { selected: true })) {
        attrFound = true;
      }
    });

    if (attrFound) return false;

    return !this._getSelectedNodes().length;
  }

  _publishFilter(searchAncestry = false) {
    const conditionGroup = this.filter.get('conditionGroup');
    const conditions = conditionGroup.conditions || [];
    let selected = [];
    conditions.forEach((condition) => {
      if (condition.name === HIERARCHY_LEVEL) {
        selected = selected.concat(condition.args);
      }
    });
    if (selected.length && searchAncestry) {
      const callback = (data) => {
        let initialSelected = [];
        selected.forEach((arg) => {
          const node = data.tree && data.tree.find(arg);
          if (node) {
            node.set({ selected: true });
            initialSelected.push(node);
          }
        });
        this.tree.set({ initialSelected });
        this._publishFilterAction();
      };
      this._getHierarchyAncestriesByNodes({ args: selected, callback });
    } else {
      this._publishFilterAction();
    }
  }

  _publishFilterAction() {
    const conditionGroup = this.filter.get('conditionGroup');
    this.publish('hierarchyfilters:data', {
      tree: this.tree,
      attributes: this.attributes,
      name: this.filter.get('name'),
      treeFilterConditions: _.filter(conditionGroup.conditions, (c) => {
        return c.subject.type === HIERARCHY_LEVEL;
      }),
    });
  }

  _setName() {
    if (this._isFilterEmpty()) {
      this.filter.reset();
    } else {
      this.filter.set('name', `Untitled`);
    }
  }

  _editAttribute(payload) {
    var attribute = _.findWhere(this.attributes, {
      name: payload.attribute.name,
      type: payload.attribute.type,
    });
    attribute.values = payload.values;
    this._setName();

    if (this._isFilterEmpty()) {
      this.resetFilter();
    } else {
      this.saveTempFilter();
    }
  }

  _editTree(payload) {
    this.selectedTree = payload.tree;
    this.selectedTree.root().set('selected', false);
    this._setName();

    if (this._isFilterEmpty()) {
      this.resetFilter();
    } else {
      this.saveTempFilter(true);
    }
  }

  _isNodeSelected(node) {
    const { conditions } = this.filter.get('conditionGroup');
    if (!conditions) {
      return false;
    }
    return _.find(conditions, (c) => {
      if (c.name === HIERARCHY_LEVEL) {
        return _.contains(c.args, node.get('id'));
      }
      return false;
    })
      ? true
      : false;
  }

  _isAttributeSelected(a, v) {
    const { conditions } = this.filter.get('conditionGroup');
    const inFilter = _.filter(conditions, (cond) => {
      return (
        cond.name === HIERARCHY_ATTRIBUTE &&
        a.type === cond.subject.id &&
        _.contains(cond.args, v.value)
      );
    });
    return inFilter.length > 0;
  }

  _getSelectedNodes() {
    const tree = this.selectedTree || this.tree;
    const allNodes = tree.allDescendants();
    return _.filter(allNodes, (node) => node.get('selected'));
  }

  _getSelectedNodeLevels(parentNode) {
    if (
      parentNode.get('isDummyNode') &&
      parentNode._nodes.models &&
      parentNode._nodes.models.length === 1 &&
      parentNode._nodes.models[0].get('selected')
    ) {
      return [parentNode._nodes.models[0]];
    }
    if (parentNode.isRoot() && parentNode.get('selected')) {
      return [parentNode];
    }
    const childNodes = parentNode.nodes();

    let childLevels = [];
    let levels = [];

    // handle leaf node
    if (!childNodes) {
      return [];
    }

    // loop through all child nodes and add the selected nodes to our list of
    // levels. if child is unselected we recursively find selected levels
    childNodes.each((childNode) => {
      if (!childNode.get('selected')) {
        childLevels = childLevels.concat(
          this._getSelectedNodeLevels(childNode)
        );
      } else {
        levels.push(childNode);
      }
    });

    levels = levels.concat(childLevels);

    return levels;
  }

  _populateModel() {
    let conditions = [];
    const selectedNodes = this._getSelectedNodeLevels(
      this.selectedTree || this.tree
    );
    const levels = _.groupBy(selectedNodes, (n) => {
      return n.get('levelName');
    });
    Object.keys(levels).forEach((key) => {
      conditions.push({
        args: levels[key].map((n) => n.id),
        description: key,
        name: HIERARCHY_LEVEL,
        operator: levels[key].length === 1 ? 'EQ' : 'IN',
        subject: {
          id: key,
          type: HIERARCHY_LEVEL,
        },
        valueType: 'TEXT',
      });
    });

    this.attributes.forEach((a) => {
      const selectedValues = _.where(a.values, { selected: true });
      if (selectedValues.length) {
        conditions.push({
          args: selectedValues.map((v) => v.value),
          description: a.type,
          name: HIERARCHY_ATTRIBUTE,
          operator: selectedValues.length === 1 ? 'EQ' : 'IN',
          subject: {
            id: a.type,
            type: HIERARCHY_ATTRIBUTE,
          },
          valueType: 'TEXT',
        });
      }
    });

    this.filter.set('conditionGroup', {
      conditionGroupType: HIERARCHY_FILTER,
      conditions,
      operator: 'OR',
    });
    this.filter.set('clientId', this.getClientId());
    this.filter.set('userId', this.getCurrentUserId());
  }

  parseTree(tree, parent) {
    return new TreeModel(tree, {
      parent: this.hasFeature('enhanced-hierarchy-location-search')
        ? parent
        : null,
      callback: (node) => {
        node.set({
          id: node.get('value'),
          name: node.get('label'),
          levelName: node.get('attributes')
            ? node.get('attributes').__level__
            : '',
          selected: this._isNodeSelected(node),
          open: false,
        });
      },
    });
  }

  parseAttributes(attributes) {
    return attributes.map((a) => {
      return _.extend(a, {
        values: a.values.map((v) => {
          return _.extend(v, {
            selected: this._isAttributeSelected(a, v),
          });
        }),
      });
    });
  }

  getKey() {
    return 'hierarchy-filter-data';
  }
}

export default HierarchyFiltersController;

import BasePanel from 'views/panels/base';
import getConfig from 'core/config';
import Navigation from 'core/utils/navigation';

class BaseNavigation extends BasePanel {
  constructor(props) {
    super(props);

    // No base route specified; most likely hitting base route directly
    const constructBasePath = this.configureBasePath(props);
    this.state = {
      baseRoute: this.getRoute(constructBasePath, props.isPrimaryNavigation),
      selectedTab: 0,
      route: '',
      subMenuOpenStates: null,
    };

    this.actions = {
      'route:data': 'redirectRoute',
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.publish('route:get');
    this.publish('storage:user:get', {
      key: this.getSubMenuStateKey(),
      callback: (payload = {}) => {
        if (payload.data && payload.data.data) {
          this.setState({ subMenuOpenStates: payload.data.data });
        }
      },
    });
  }

  componentDidUpdate(prevProps) {
    const { config, baseRoute, isPrimaryNavigation, hiddenRoutes } = this.props;
    if (
      prevProps.baseRoute !== baseRoute ||
      (prevProps.config && prevProps.config.route !== config.route) ||
      (isPrimaryNavigation &&
        prevProps.config &&
        prevProps.config.primaryTabs[0] &&
        prevProps.config.primaryTabs[0].routes !==
          config.primaryTabs[0].routes) ||
      prevProps.hiddenRoutes !== hiddenRoutes
    ) {
      this.setState(
        {
          baseRoute: this.getRoute(
            baseRoute || config.route,
            isPrimaryNavigation
          ),
          selectedTab: 0,
        },
        () => {
          this.publish('route:get');
        }
      );
    }
  }

  configureBasePath(props) {
    const { config, baseRoute, route, isPrimaryNavigation } = props;
    if (isPrimaryNavigation) {
      return '';
    }

    if (config || baseRoute) {
      return props.baseRoute || props.config.route;
    }

    const slugs = route.split('/');
    let basePath = '';
    for (let i = 0; i < slugs.length - 1; i++) {
      basePath += slugs[i] + '/';
    }
    return basePath;
  }

  getPrimaryNavigationRoute() {
    const { primaryTabs, routes } = this.props.config;

    return {
      path: '',
      route: {
        allTabs: primaryTabs,
        tabs: Navigation.getTabsWithValidPermissions(
          this,
          routes,
          primaryTabs
        ).map(this._filterByClient.bind(this)),
        ...routes,
      },
    };
  }

  _filterByClient(tabs) {
    const client = this.props.config.client;
    const filteredRoutes = tabs.routes.filter((route) => {
      if (route === 'leaderboard') {
        return client.leaderBoardAccess;
      }
      return true;
    });
    tabs.routes = filteredRoutes;
    return tabs;
  }

  getRoute(path, isPrimaryNavigation) {
    if (isPrimaryNavigation) {
      return this.getPrimaryNavigationRoute();
    }

    if (!path) {
      return null;
    }

    // remove trailing '/'
    const routePath = this.removeTrailingSlash(path);
    const routeTree = routePath.split('/');
    let routeObj;
    for (let i = 0; i < routeTree.length; i++) {
      let route = routeTree[i];
      if (!isNaN(route)) {
        route = ':id';
      }

      routeObj = routeObj
        ? this.clone(routeObj[route])
        : this.clone(getConfig().routes[route]);
    }

    if (routeObj && routeObj.tabs) {
      routeObj.allTabs = routeObj.tabs;
      routeObj.tabs = Navigation.getTabsWithValidPermissions(this, routeObj);
    }

    return {
      path: routePath,
      route: routeObj,
    };
  }

  removeTrailingSlash(string) {
    return string.replace(/\/$/, '');
  }

  redirectRoute(payload) {
    const { selectedTab, baseRoute } = this.state;
    const { route, preventRedirect } = payload;

    if (!route || !baseRoute || preventRedirect) {
      return;
    }

    const routePath = this.removeTrailingSlash(route);
    const tabs = baseRoute.route && baseRoute.route.tabs;
    if (routePath === baseRoute.path) {
      if (!tabs || !tabs.length) {
        return;
      }

      const tab = tabs[0];
      const selectedRoute = tab.routes[selectedTab];
      let routeObj = baseRoute.route[selectedRoute];
      if (tab && tab.parentRoute) {
        routeObj = baseRoute.route[tab.parentRoute][selectedRoute];
      }

      if (routeObj.tabs) {
        routeObj.allTabs = routeObj.tabs;
        routeObj.tabs = Navigation.getTabsWithValidPermissions(this, routeObj);
      }

      const route = Navigation.getLastChildRoute(this, selectedRoute, routeObj);
      this.changeRoute(route, tab, true);
      return;
    }

    this.firePropsRouteChange(route);
    // coming from a component
    this.setState({
      selectedTab: this.getSelectedTab(route),
      route: route,
    });
  }

  changeRoute(route, tab, noEntry, keepParams) {
    const { baseRoute } = this.state;

    let path = '/';
    if (tab && tab.parentRoute) {
      path += tab.parentRoute + '/';
    }

    let params = '';
    if (keepParams) {
      params += '?' + this.getQueryParams();
    }

    const newRoute = baseRoute.path + path + route + params;
    this.publish('route:change', {
      route: newRoute,
      noEntry: noEntry,
    });

    this.setState({
      selectedTab: this.getSelectedTab(newRoute),
      route: newRoute,
    });

    this.firePropsRouteChange(route);
  }

  firePropsRouteChange(route) {
    if (this.props.onRouteChange) {
      this.props.onRouteChange(route);
    }
  }

  getSelectedTab(route) {
    const { baseRoute } = this.state;
    const { hiddenRoutes = [] } = this.props;

    if (!baseRoute) {
      return 0;
    }

    const slug = route.replace(baseRoute.path + '/', '');
    const tabs = baseRoute.route.allTabs;

    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      const routes = tab.routes.filter((r) =>
        hiddenRoutes.every((routeToHide) => routeToHide !== r)
      );

      for (let j = 0; j < routes.length; j++) {
        const newRoute = routes[j];
        if (slug.indexOf(newRoute) !== -1) {
          return i * (routes.length + 1) + j;
        }
      }
    }

    return 0;
  }

  // child components will override the render method
  render() {
    return null;
  }

  getQueryParams() {
    return document.location.search.replace(/(^\?)/, '');
  }

  getSubMenuStateKey() {
    return 'navigation-submenu-states';
  }
}

BaseNavigation.defaultProps = {
  onRouteChange: () => {},
  baseRoute: null,
  hiddenRoutes: [],
};

export default BaseNavigation;

import React from 'react';
import $ from 'jquery';
import dispatcher from 'core/dispatcher';

let TooltipManager = {
  _dispatcher: dispatcher,

  publish(action, payload) {
    this._dispatcher.trigger(action, payload);
  },

  handleTooltip(e, tooltipData) {
    if (this.toolTipTimer) clearTimeout(this.toolTipTimer);

    var trigger =
      tooltipData && tooltipData.trigger ? tooltipData.trigger : 'mouseover';
    if (e.type === trigger) {
      var currentTarget = $(e.currentTarget);
      var _this = this,
        delay = tooltipData.delay ? tooltipData.delay : 400;

      this.toolTipTimer = setTimeout(function () {
        _this.showTooltip(currentTarget, tooltipData);
      }, delay);
    } else {
      this.publish('app:showTooltip', false);
    }
  },

  showTooltip(currentTarget, tooltipData) {
    var pos = currentTarget.offset(),
      messageText = tooltipData.tooltip,
      titleText = tooltipData.title,
      message = messageText ? (
        <div className='tooltip__message'>{messageText}</div>
      ) : (
        false
      ),
      title = titleText ? (
        <div className='tooltip__title'>{titleText}</div>
      ) : (
        false
      ),
      position = {
        top: pos.top,
        left: pos.left,
        width: parseInt(currentTarget.outerWidth(), 10),
        height: parseInt(currentTarget.outerHeight(), 10),
      },
      attachment = {
        vertical:
          tooltipData.attachment && tooltipData.attachment.vertical
            ? tooltipData.attachment.vertical
            : 'top',
        horizontal:
          tooltipData.attachment && tooltipData.attachment.horizontal
            ? tooltipData.attachment.horizontal
            : 'center',
        axis:
          tooltipData.attachment && tooltipData.attachment.axis
            ? tooltipData.attachment.axis
            : 'vertical',
      },
      className =
        'tooltip' + (tooltipData.className ? ' ' + tooltipData.className : ''),
      tooltip = {
        position: position,
        className: className,
        title: title,
        message: message,
        attachment: attachment,
        maxWidth: tooltipData.maxWidth,
      };

    this.publish('app:showTooltip', tooltip);
  },
};

export default TooltipManager;

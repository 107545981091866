import $ from 'jquery';
import _ from 'underscore';
import { saveAs } from 'file-saver';
import Permissions from 'core/permissions';
import BaseController from 'controllers/base';
import MeasuresCollection from 'collections/clients/measures';
import ImpactsModel from 'models/clients/measures/impacts';
import LatentModel from 'models/clients/measures/latent';
import LatentsCollection from 'collections/clients/measures/latents';
import BenchmarkCategoryDetailModel from 'models/clients/measures/slatents/bmcat/details';
import QuestionModel from 'models/clients/measures/question';
import QuestionsCollection from 'collections/clients/measures/questions';
import RespondentsCollection from 'collections/clients/measures/respondents';
import RespondentsByIdMetaData from 'models/clients/measures/respondents/id';
import CustomQuestionsModel from 'models/clients/measures/customquestions';
import CustomQuestionsCollection from 'collections/clients/measures/customquestions';
import HierarchyModel from 'models/clients/measures/hierarchy';
import HierarchyConfigModel from 'models/clients/measures/hierarchyconfig';
import FilterMetaDataModel from 'models/clients/measures/model-filter-meta-data';
import moment from 'moment';
import constants from 'core/constants';
import RecommendedPriorityCollection from 'collections/clients/measures/recommended-priority';
import CardBookModel from 'models/export/card-book';
import PPTRequestModel from 'models/export/ppt-request';
import PPTFileModel from 'models/export/ppt-file';
import CSVFileModel from 'models/export/csv-file';
import DateRange from 'models/dateRange';
import MeasuresMetricsModel from 'models/clients/measures/metrics';
import routes from 'core/routes';

class MeasuresController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'user:data': 'handleUserData',
        'measures:get': 'getMeasures',
        'measures:isInitialized': 'isInitialized',
        'measures:questions:get': 'getQuestions',
        'measures:questions:question:get': 'getQuestion',
        'measures:questions:answers:get': 'getAnswersByQuestion',
        'measures:questions:scores:get': 'getScoresByAnswers',
        'measures:latents:get': 'getLatents',
        'measures:latents:latent:get': 'getLatent',
        'measures:set': 'setCurrentMeasure',
        'measures:getCurrent': 'getCurrentMeasure',
        'measures:daterange:getInitialDates': 'getParsedInitialDates',
        'measures:daterange:getFiscalConfig': 'getFiscalConfig',
        'measures:daterange:lookup': 'handleCalendarLookup',
        'measures:daterange:set': 'handleDateRangeChanged',
        'measures:daterange:calendarChange': 'handleCalendarChange',
        'measures:daterange:get': 'getDates',
        'measures:daterange:getBaseDate': 'getBaseDate',
        'location:change': 'getRoute',
        'measures:respondentcount:get': 'getRespondentCount',
        'measures:metadata:id:get': 'getMetaDataByRespondentId',
        'measures:customquestions:get': 'getCustomQuestions',
        'measures:hierarchy:get': 'getHierarchy',
        'measures:filters:menus': 'getFilterMenus',
        'filters:filter:changed': 'handleFilterChanged',
        'filters:filter:update': 'handleUpdateFilter',
        'pagefilters:filter:changed': 'handlePageFilterChanged',
        'measures:pagefilter:get': 'handlePageFilterGet',
        'measures:hierarchyfilter:get': 'handleHierarchyFilterGet',
        'measures:filter:get': 'handleFilterGet',
        'hierarchyfilters:filter:changed': 'handleHierarchyFilterChanged',
        'measures:export:respondents:csv': 'exportMeasuresRespondentsCSV',
        'measures:exportMainDashboardPPT': 'exportMainDashboardPPT',
        'measures:exportCXSurveyDashboardPPT': 'exportCXSurveyDashboardPPT',
        'measures:exportTADashboardPPT': 'exportTADashboardPPT',
        'measures:anomaly:drillIn': 'anomalyDrillin',
      },
      dependsOn: ['user', 'filters', 'hierarchyfilters', 'pagefilters'],
      name: 'measures',
    });
  }

  initialize(opts) {
    const options = opts || {},
      app = options.app,
      client = this.getClientId(),
      collOptions = {
        app: app,
        client: client,
      };

    this.app = app;
    this.client = client;
    this.options = options;
    this.selected = 3;
    this.parseBaseDates = {};

    // Initialize collections
    this.measures = new MeasuresCollection(null, collOptions);

    this.clientModel = this.getClientModel();
    this.fiscalConfig = false;
    if (this.clientModel.hasFiscalCalendar) {
      this.fiscalConfig = {};
      this.fiscalConfig.fiscalWeekStart = this.clientModel.fiscalWeekStart;
      this.fiscalConfig.fiscalYears = this.clientModel.fiscalYears;
      this.publish('measures:daterange:fiscalConfig', this.fiscalConfig);
    }

    this.publish('storage:get', {
      key: 'filter-data',
      callback: function (payload) {
        if (
          !payload.expired &&
          payload.data &&
          payload.data.data &&
          this.controllers.app.pathIds &&
          (!this.controllers.app.pathIds.measures ||
            (this.controllers.app.pathIds.measures &&
              payload.data.data.filter.measurementKey &&
              this.controllers.app.pathIds.measures ===
                payload.data.data.filter.measurementKey.toString()))
        ) {
          this.modelFilter = payload.data.data.filter;
          this.publish('measures:filter:changed', {
            ...this.modelFilter,
            isInitialization: true,
          });
          if (this.modelFilter.measurementKey) {
            this.publish('filters:set', this.modelFilter);
          }
        }

        this.publish('storage:get', {
          key: 'measures-dateRange',
          callback: function (payload) {
            let start_date = new moment().subtract(1, 'months'),
              end_date = new moment(),
              prevStart_date = new moment().subtract(2, 'months'),
              prevEnd_date = new moment()
                .subtract(1, 'days')
                .subtract(1, 'months'),
              rangeType = 'M',
              selected = 3,
              calendarType = 'G',
              number = 0;

            if (!payload.expired && payload.data && payload.data.data) {
              const dateRange = payload.data.data;
              start_date = new moment(dateRange.start_date);
              end_date = new moment(dateRange.end_date);
              prevStart_date = new moment(dateRange.prevStart_date);
              prevEnd_date = new moment(dateRange.prevEnd_date);
              rangeType = dateRange.rangeType;
              selected =
                payload.data.data.selected >= 0
                  ? payload.data.data.selected
                  : selected;
              calendarType =
                typeof payload.data.data.calendarType === 'undefined'
                  ? 'G'
                  : payload.data.data.calendarType;
              number =
                typeof payload.data.data.number === 'undefined'
                  ? 0
                  : payload.data.data.number;
              this.parseBaseDates.fiscalConfig = this.fiscalConfig;
            }

            if (!payload.data) {
              this.publish('storage:set', {
                key: 'measures-dateRange',
                data: {
                  start_date: start_date,
                  end_date: end_date,
                  prevStart_date: prevStart_date,
                  prevEnd_date: prevEnd_date,
                  rangeType: rangeType,
                  selected,
                  fiscalConfig: this.fiscalConfig,
                  calendarType: calendarType,
                  number: number,
                },
              });
            }

            this.publish('storage:get', {
              key: 'measures-dateRangeType',
              callback: function (payload) {
                let dateRangeType = 'DATE_ONLY';
                if (!payload.data) {
                  this.publish('storage:set', {
                    key: 'measures-dateRangeType',
                    data: {
                      dateRangeType: dateRangeType,
                    },
                  });
                } else {
                  dateRangeType = payload.data.data.dateRangeType;
                }
                this.handleCalendarLookup({
                  calendarType: calendarType,
                  rangeType: rangeType,
                  number: number,
                  startDate: start_date,
                  endDate: end_date,
                  setParseDate: true,
                  dateRangeType: dateRangeType,
                  selected,
                  callback: function (data) {
                    this.parseBaseDates.selected = selected;
                    this.parseBaseDates.fiscalConfig = this.fiscalConfig;
                    this.publish(
                      'measures:daterange:parsedDateRange',
                      this.parseBaseDates
                    );
                  }.bind(this),
                });

                this.setBaseDate(
                  start_date.format('YYYY-MM-DD'),
                  end_date.format('YYYY-MM-DD'),
                  prevStart_date.format('YYYY-MM-DD'),
                  prevEnd_date.format('YYYY-MM-DD'),
                  rangeType,
                  calendarType,
                  number,
                  dateRangeType,
                  selected
                );

                this.rangeType = rangeType;

                this.getRoute();
              }.bind(this),
            });
          }.bind(this),
        });
      }.bind(this),
    });
    return this.getMeasures();
  }

  isInitialized() {
    this.publish('measures:initialized');
  }

  getParsedInitialDates(options = {}) {
    let { dateRangeType = constants.DATE_ONLY } = options;
    const { startDate = false, endDate = false } = options;
    if (![constants.DATE_ONLY, constants.DATE_TIME].includes(dateRangeType)) {
      dateRangeType = constants.DATE_ONLY;
    }

    this.publish('storage:get', {
      key: 'measures-dateRange',
      callback: function (payload) {
        if (!payload.expired && payload.data && payload.data.data) {
          const calendar = {
            dateRangeType: dateRangeType,
            setParseDate: true,
            selected: payload.data.data.selected,
            calendarType:
              typeof payload.data.data.calendarType === 'undefined'
                ? 'G'
                : payload.data.data.calendarType,
          };

          const dateRange = payload.data.data;
          if (startDate || endDate) {
            calendar.startDate = new moment(startDate || dateRange.start_date);
            calendar.endDate = new moment(endDate || dateRange.end_date);
            calendar.rangeType = 'C';
          } else {
            calendar.startDate = new moment(dateRange.start_date);
            calendar.endDate = new moment(dateRange.end_date);
            calendar.rangeType = dateRange.rangeType;
            calendar.number =
              typeof payload.data.data.number === 'undefined'
                ? 0
                : payload.data.data.number;
          }

          this.handleCalendarLookup({
            ...calendar,
            callback: function (data) {
              this.parseBaseDates.selected = calendar.selected;
              this.publish(
                'measures:daterange:initialDates',
                this.parseBaseDates
              );
            }.bind(this),
          });
        }
      }.bind(this),
    });
  }

  getFiscalConfig() {
    this.fiscalConfig = false;
    if (this.clientModel.hasFiscalCalendar) {
      this.fiscalConfig = {};
      this.fiscalConfig.fiscalWeekStart = this.clientModel.fiscalWeekStart;
      this.fiscalConfig.fiscalYears = this.clientModel.fiscalYears;
    }
    this.publish('measures:daterange:fiscalConfig', this.fiscalConfig);
  }

  handleFilterGet(payload) {
    if (payload && payload.callback) {
      payload.callback(this.modelFilter);
    } else if (this.modelFilter && Object.keys(this.modelFilter).length > 0) {
      this.publish('measures:filter:data', this.modelFilter);
    }
  }

  handlePageFilterGet(payload) {
    if (payload && payload.callback) {
      payload.callback(this.pageFilter);
    }
  }

  handleHierarchyFilterGet(payload) {
    if (payload && payload.callback) {
      payload.callback(this.hierarchyFilter);
    }
  }

  handlePageFilterChanged(pageFilter) {
    this.pageFilter = pageFilter;
    this.publish('measures:pagefilter:changed', pageFilter);
  }

  handleFilterChanged({ noReload, ...payload }) {
    const { isInitialization, ...filter } = payload;
    this.modelFilter = filter;
    this.publish('storage:set', { key: 'filter-data', data: { filter } });
    this.publish('measures:filter:changed', { ...payload, noReload });
  }

  handleUpdateFilter(filter) {
    this.modelFilter = filter;
    this.publish('storage:set', { key: 'filter-data', data: { filter } });
  }

  handleHierarchyFilterChanged(filter) {
    this.hierarchyFilter = filter;
    this.publish('measures:hierarchyfilter:changed', filter);
  }

  getDates(payload) {
    const options = payload || {},
      callback = options.callback || null;
    if (callback) {
      callback(this.parseBaseDates);
    }
  }

  getBaseDate(payload) {
    if (payload && payload.callback) {
      payload.callback({ baseDate: this.baseDate, ...this.dates });
    }
  }

  setBaseDate(
    start_date,
    end_date,
    prevStart_date,
    prevEnd_date,
    rangeType,
    calendarType,
    number,
    dateRangeType,
    selected
  ) {
    this.dates = {
      start_date: start_date,
      end_date: end_date,
    };
    this.selected = selected;
    dateRangeType = dateRangeType !== 'DATE_TIME' ? 'DATE_ONLY' : dateRangeType;
    if (rangeType === 'C') {
      this.baseDate = {
        c: calendarType,
        r: 'C',
        p: 'D',
        n: 0,
        k: this.client,
        v: '',
        a: new moment().format('YYYY-MM-DD'),
        f: start_date,
        l: end_date,
        t: dateRangeType,
      };

      this.compareDate = {
        c: calendarType,
        r: 'C',
        p: 'D',
        n: 0,
        k: this.client,
        v: '',
        a: new moment().format('YYYY-MM-DD'),
        f: new moment(start_date)
          .subtract(
            new moment(end_date).diff(new moment(start_date), 'days'),
            'days'
          )
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        l: new moment(start_date).subtract(1, 'days').format('YYYY-MM-DD'),
      };
    } else {
      let asOf = new moment().format('YYYY-MM-DD');
      if (
        (dateRangeType === 'DATE_TIME' && number === 0 && rangeType === 'D') ||
        rangeType === 'H'
      ) {
        asOf = null;
      }
      this.baseDate = {
        c: calendarType,
        r: rangeType,
        p: 'D',
        n: number,
        a: asOf,
        k: this.client,
        o: true,
        t: dateRangeType,
      };

      this.compareDate = {
        c: calendarType,
        r: rangeType,
        p: 'P',
        n: number,
        a: new moment().format('YYYY-MM-DD'),
        k: this.client,
        o: true,
      };
    }
  }

  // Insights controller can be instantiated in multiple scenarios
  // multiple functions call setCurrentProject
  // Setting current project key aspect of insights initialization
  // Necessary prior to getting current topics or hit respondent endpoints
  // - Projects are directly passed to function due to user input
  // - In page insights does not contain project id in route initially,
  //   when projects defined, default to first one
  // - Routed projects require project list and route data to set current
  setCurrentMeasure(measure) {
    if (!_.isEmpty(measure)) {
      //measure = measure;
    } else if (!_.isEmpty(this.measures) && !this.currentMeasurementKey) {
      // This is for the iframe, it takes the first project as there is no project Id to begin with.
      measure = this.measures[0];
    } else if (this.currentMeasurementKey && !_.isEmpty(this.measures)) {
      // For both iframe and the other route where projectId is set, just get the measure here.
      measure = this.measures.findWhere({
        measurementKey: this.currentMeasurementKey,
      });

      if (measure) {
        measure = measure.toJSON();
      }
    }

    if (
      this.currMeasure &&
      this.currentMeasurementKey !== measure.measurementKey
    ) {
      this.publish('filters:reset:noaction');
      this.publish('pagefilters:reset:noaction');
      this.modelFilter = {};
      this.hierarchyFilter = {};
      this.pageFilter = {};
      this.publish('hierarchyfilters:reset', { isInitialization: true });
    }

    // Scenario of changing a project.
    if (measure && this.currMeasure !== measure) {
      this.currentMeasurementKey = measure.measurementKey;
      this.currMeasure = measure;
      this.publish('measures:currentMeasure', this.currMeasure);
    }
  }

  getCurrentMeasure(payload) {
    if (payload && payload.callback) {
      payload.callback(this.currMeasure);
    } else if (this.currMeasure) {
      this.publish('measures:currentMeasure', this.currMeasure);
    }
  }

  /**
   * These all behave the same way:
   *   They will return an array unless an appropriate 'id' property is set in the payload
   *   The will emit a 'data' action unless a 'callback' property is set in the payload
   */
  getMeasures(payload) {
    const options = payload || {},
      id = options.id || null,
      callback = options.callback || null;

    const publish = (data) => {
      if (callback) {
        callback(data);
      } else {
        this.publish('measures:data', data);
      }
    };

    if (!Permissions.userRole('cx_measure_view')) {
      const data = {
        measures: this.measures.toJSON(),
      };

      publish(data);
      // return true to indicate that measures is initialized
      return true;
    }

    this.measures.setQuery(
      typeof options.query !== 'undefined' ? options.query : ''
    );

    return this.measures.fetch({ doNotAbort: true }).then(() => {
      const data = {};

      if (id) {
        data.measure = this.measures.find(id).toJSON();
      } else {
        data.measures = this.measures.toJSON();
      }

      this.setCurrentMeasure();
      publish(data);
    });
  }

  getTrendLength(range, days) {
    if (range === 'D' || (days === 0 && range === 'C')) return 7;
    if (range === 'W') return 7;
    if (range === 'M') return 6;
    if (range === 'Q') return 5;
    if (range === 'Y') return 4;
    if (days > 90) return 3;
    if (days > 30) return 4;
    if (days > 7) return 5;
    return 6;
  }

  publishTrendsData(payload, trendsCollection, partialTrendData) {
    const callback = payload.callback || null;

    const data = {
      scores: _.uniq(trendsCollection.toJSON(), 'date'),
      dateRange: _.extend(trendsCollection.dateRange, {
        rangeType: this.rangeType,
      }),
      respondents: trendsCollection.getTotalRespondents(),
    };

    if (partialTrendData) {
      _.extend(data.dateRange, {
        end_date: partialTrendData.dateRange.end_date,
      });
    }

    if (callback) {
      callback(data);
    } else {
      this.publish('measures:latents:trend:data', data);
    }
  }

  getQuestion(payload) {
    const { questionKey, measurementKey, callback } = payload;
    const def = $.Deferred();
    const data = {};

    const options = {
      clientId: this.client,
      measurementKey: measurementKey || this.currentMeasurementKey,
    };

    const question = new QuestionModel({ questionKey }, options);
    question
      .fetch({
        error: (m, r) => {
          if (callback) {
            callback(false, data);
          }
        },
      })
      .then(() => {
        data.question = question.toJSON();

        const measure = this.measures.find({
          measurementKey: measurementKey || this.currentMeasurementKey,
        });
        if (
          measure &&
          measure.get('npsQuestionKey') === data.question.questionKey
        ) {
          data.question.npsSegment = true;
        }

        if (callback) {
          callback(true, data);
        } else {
          this.publish('measures:questions:question:data', data);
        }

        def.resolve(data);
      });
    return def;
  }

  getQuestions(payload) {
    const data = {};
    const options = payload || {},
      id = options.id || null,
      latentKey = options.latentKey || null,
      getAnswers = options.getAnswers || null,
      getAverageRating = options.getAverageRating || null,
      callback = options.callback || null;

    options.clientId = this.client;
    options.measure =
      parseInt(options.measurementKey, 10) || this.currentMeasurementKey;

    const questions = this.measures.getQuestions(options, data);
    if (payload.addDateRange) {
      _.extend(questions, {
        urlparams: {
          dateRange: JSON.stringify(this.baseDate),
        },
      });
    }

    return new Promise((resolve) => {
      questions.fetch().then(() => {
        if (id) {
          data.questions = [questions.findWhere({ questionKey: id })];
        } else if (latentKey) {
          const filtered = questions.where({ latentKey: latentKey });
          data.questions = filtered.map((model) => model);
        } else {
          data.questions = questions;
        }

        if (getAnswers) {
          const defs = [], // Array of promises to track fetching answers and scores by answers
            refs = {}, // Object to store references to scores and answers results
            criteria = { dateRange: this.baseDate };

          if (latentKey) {
            criteria.latentKey = latentKey;
          }

          if (this.modelFilter) {
            criteria.modelFilter = this.getFilterCriteria(
              this.modelFilter,
              'model'
            );
          }

          if (this.pageFilter) {
            criteria.pageFilter = this.getFilterCriteria(
              this.pageFilter,
              'page'
            );
          }

          if (this.hierarchyFilter) {
            criteria.hierarchyFilter = this.getFilterCriteria(
              this.hierarchyFilter,
              'hierarchy'
            );
          }

          data.questions.forEach((question) => {
            const q = question.toJSON();
            q.returnModel = true;
            const scoresByAnswer = this.getScoresByAnswers({
              ...q,
              metricType: payload.metricType,
            });
            const answers = question.getAnswers(options);

            refs[question.get('questionKey') + '__scores'] = scoresByAnswer;
            refs[question.get('questionKey') + '__answers'] = answers;

            defs.push(scoresByAnswer.save());
            defs.push(answers.fetch());
          });

          $.when.apply($, defs).done(() => {
            data.questions.forEach((question) => {
              if (getAverageRating) {
                question.getAverageRating();
              }
              const questionKey = question.get('questionKey');
              const scoresByAnswer = refs[`${questionKey}__scores`].toJSON();

              question.set('respondents', scoresByAnswer.question.respondents);
              question.set('dateRange', scoresByAnswer.question.dateRange);
              question.joinAnswerData(scoresByAnswer);
            });

            data.questions = data.questions.map((question) =>
              question.toJSON({ joined: true })
            );
            if (callback) {
              callback(data);
            } else {
              this.publish('measures:questions:data', data);
            }
            resolve(data);
          });
        } else {
          data.questions = data.questions.map((question) => question.toJSON());
          if (callback) {
            callback(data);
          } else {
            this.publish('measures:questions:data', data);
          }
          resolve(data);
        }
      });
    });
  }

  getScoresByAnswers(payload) {
    const {
      measurementKey,
      questionKey,
      latentKey,
      metricType,
      callback = () => {},
      returnModel,
    } = payload;

    const criteria = {};
    criteria.metrics = [];
    criteria.dateRange = this.baseDate;
    criteria.groupBy = 'METRIC';
    criteria.groupMetric = {
      key: questionKey,
      type: constants.METRIC_TYPES.QG,
    };
    criteria.metrics = [
      { type: constants.METRIC_TYPES.QC, key: questionKey },
      { type: constants.METRIC_TYPES.RC, key: measurementKey },
    ];

    if (latentKey && !metricType) {
      criteria.metrics.push({
        type: constants.METRIC_TYPES.LS,
        key: latentKey,
      });
    } else if (metricType === constants.METRIC_TYPES.NPS) {
      criteria.metrics.push({
        type: constants.METRIC_TYPES.NPS,
        key: measurementKey,
      });
    } else {
      criteria.metrics.push({
        type: constants.METRIC_TYPES.SAT,
        key: measurementKey,
      });
    }

    if (this.modelFilter) {
      criteria.modelFilter = this.getFilterCriteria(this.modelFilter, 'model');
    }

    if (this.pageFilter) {
      criteria.pageFilter = this.getFilterCriteria(this.pageFilter, 'page');
    }

    if (this.hierarchyFilter) {
      criteria.hierarchyFilter = this.getFilterCriteria(
        this.hierarchyFilter,
        'hierarchy'
      );
    }

    const model = new MeasuresMetricsModel(criteria, {
      clientId: this.getClientId(),
      measurementKey,
      parseScoreByAnswer: true,
    });
    if (returnModel) {
      return model;
    } else {
      model
        .save({
          error: () => {
            //TODO
          },
        })
        .then((data) => {
          callback(data);
          return data;
        });
    }
  }

  getAnswersByQuestion(payload) {
    const { question, callback } = payload;
    const options = {
      clientId: this.client,
      measure: question.measurementKey || this.currentMeasurementKey,
    };

    const questionModel = new QuestionModel(question);
    const answers = questionModel.getAnswers(options);

    answers.fetch().then(() => {
      if (callback) {
        callback(answers.toJSON());
      } else {
        this.publish('measures:questions:answers:data', answers.toJSON());
      }
    });
  }

  getLatent(payload) {
    const { latentKey, measurementKey, callback } = payload;
    const def = $.Deferred();
    const data = {};

    const options = {
      clientId: this.client,
      measurementKey: measurementKey || this.currentMeasurementKey,
    };

    const latent = new LatentModel({ latentKey }, options);
    latent.fetch().then(() => {
      data.latent = latent.toJSON();
      if (callback) {
        callback(data);
      } else {
        this.publish('measures:latents:latent:data', data);
      }

      def.resolve(data);
    });
    return def;
  }

  getLatents(payload) {
    const data = {};
    const options = payload || {},
      id = options.id || null,
      callback = options.callback || null;

    options.clientId = this.client;
    options.measure =
      parseInt(options.measurementKey, 10) || this.currentMeasurementKey;

    const latents = this.measures.getLatents(options, data);
    if (!latents) {
      return;
    }
    return new Promise((resolve) => {
      latents.fetch().then(() => {
        if (id) {
          data.latent = latents.find(id).toJSON();
        } else {
          data.latents = latents.toJSON();
        }

        if (callback) {
          callback(data);
        } else {
          this.publish('measures:latents:data', data);
        }
        resolve(data);
      });
    });
  }

  getRespondentCount(payload = {}) {
    const def = $.Deferred();
    const {
      callback,
      dateRange = this.baseDate,
      withoutFilters,
      measurementKey = this.currentMeasurementKey,
    } = payload;

    if (!measurementKey) {
      return;
    }

    const criteria = {
      metrics: [
        {
          type: 'RC',
          key: measurementKey,
        },
      ],
      dateRange: dateRange,
      measurementKey: measurementKey,
    };

    if (!withoutFilters) {
      if (this.modelFilter) {
        criteria.modelFilter = this.getFilterCriteria(
          this.modelFilter,
          'model'
        );
      }

      if (this.pageFilter) {
        criteria.pageFilter = this.getFilterCriteria(this.pageFilter, 'page');
      }

      if (this.hierarchyFilter) {
        criteria.hierarchyFilter = this.getFilterCriteria(
          this.hierarchyFilter,
          'hierarchy'
        );
      }
    }

    const model = new MeasuresMetricsModel(criteria, {
      clientId: this.getClientId(),
      measurementKey: measurementKey,
    });
    model.save().then((data) => {
      data.count = data.aggregates[`${measurementKey}RC`];

      if (callback) {
        callback(data);
      } else {
        this.publish('measures:respondentcount:data', data);
      }
      def.resolve(data);
    });
    return def;
  }

  getMetaDataByRespondentId(payload) {
    const { measure, respondentId, responseDate, errorCallback } = payload;
    const model = new RespondentsByIdMetaData({
      clientId: payload.clientId || this.getClientId(),
      measure,
      respondentKey: respondentId,
    });
    model.urlparams = {
      criteria: JSON.stringify({ responseDate }),
    };
    model.fetch({ error: errorCallback }).then(() => {
      if (payload.callback) {
        payload.callback(model.toJSON());
      }
    });
  }

  // CUSTOM QUESTIONS
  getCustomQuestions(payload = {}) {
    const {
      id = null,
      callback = null,
      noDateRange = false,
      measurementKey = this.currentMeasurementKey,
    } = payload;
    const questions = new CustomQuestionsCollection({
      clientId: this.client,
      measure: measurementKey,
    });

    if (!noDateRange) {
      _.extend(questions, {
        urlparams: {
          dateRange: JSON.stringify(this.baseDate),
        },
      });
    }

    return new Promise((resolve) => {
      questions.fetch().then(() => {
        const data = {};
        if (id) {
          const question = questions.findWhere({ qKey: id });
          data.question = question
            ? question.toJSON()
            : new CustomQuestionsModel().toJSON();
        } else {
          data.questions = questions.toJSON();
        }
        data.rawQuestions = questions;
        if (callback) {
          callback(data);
        } else {
          this.publish('measures:customquestions:data', data);
        }

        resolve(questions);
      });
    });
  }

  getHierarchy(payload) {
    const options = payload || {},
      callback = options.callback || null,
      model = new HierarchyModel({
        clientId: this.client,
        measurementKey: this.currentMeasurementKey,
      });

    model.fetch().then(() => {
      const data = {
        hierarchy: model.toJSON(),
      };
      if (callback) {
        callback(data);
      } else {
        this.publish('measures:hierarchy:data', data);
      }
    });
  }

  // END: CUSTOM QUESTIONS
  getRoute(payload) {
    this.publish('route:get', {
      callback: (data = {}) => {
        const { pathIds = {} } = data;
        const measurementKey = Number(pathIds.measures);

        if (_.isNumber(measurementKey) && !isNaN(measurementKey)) {
          if (this.currentMeasurementKey !== measurementKey) {
            this.currentMeasurementKey = measurementKey;
            this.setCurrentMeasure();
          }
        } else if (
          data.route.indexOf(
            routes.textAnalytics.overview.replace(/^\//, '').replace(/\/$/, '')
          ) !== -1
        ) {
          // Special case: don't reset filters if switching to TA overview page
          // This sucks but the current function already works off the route
        } else {
          this.publish('pagefilters:reset:noaction');
          this.publish('filters:reset:noaction');
          this.publish('hierarchy-filters:reset:noaction');
          this.publish('event-filters:reset:noaction');
          this.modelFilter = {};
          this.hierarchyFilter = {};
          this.pageFilter = {};
        }
      },
    });
  }

  getFilterMetaData(payload) {
    const def = $.Deferred();
    const options = payload || {},
      callback = options.callback || null;

    options.clientId = this.client;
    options.measure = options.measurementKey || this.currentMeasurementKey;

    const filterMetaData = new FilterMetaDataModel({}, options);
    filterMetaData.fetch().then(() => {
      if (callback) {
        callback(filterMetaData.toJSON());
      }
      def.resolve(filterMetaData.toJSON());
    });
    return def;
  }

  getFilterMenus(payload) {
    const { measurementKey, cxFilterAddDateRange = true } = payload;
    const options = payload || {},
      callback = options.callback || null;
    const measure = measurementKey
      ? this.measures.findWhere({ measurementKey })
      : this.currMeasure;
    const hasReplay = measure && measure.attributes.hasReplay;
    const hasNPS = !!(measure && measure.get('npsQuestionKey'));

    Promise.all([
      this.getLatents({ measurementKey }),
      this.getQuestions({
        measurementKey,
        addDateRange: cxFilterAddDateRange,
      }),
      //hasReplay ? this.getFilterMetaData(measure) : null
    ]).then((response) => {
      const latentData = response[0];
      const questionData = response[1];
      const latents = new LatentsCollection(latentData.latents).toJSON();
      const questions = new QuestionsCollection(
        questionData.questions
      ).toJSON();

      callback({
        elements: _.where(latents, { latentType: 'Element' }),
        satisfaction: _.where(latents, { latentType: 'Satisfaction' }),
        futureBehaviors: _.where(latents, { latentType: 'Future Behavior' }),
        modelQuestions: _.where(questions, { questionTypeKey: 'MQ' }),
        customQuestions: _.where(questions, { questionTypeKey: 'CQ' }),
        userDetails: _.where(questions, { questionTypeKey: 'CPP' }).concat(
          _.where(questions, { questionTypeKey: 'EPP' })
        ),
        replayAttributes: hasReplay,
        npsQuestion: hasNPS
          ? _.findWhere(questionData.questions, {
              questionKey: measure.get('npsQuestionKey'),
            })
          : false,
      });
    });
  }

  handleCalendarLookup(payload) {
    let today =
        typeof payload.today !== 'undefined' ? payload.today : new moment(),
      calendarType = payload.calendarType || this.baseDate.c,
      clientId = calendarType === 'G' ? '' : this.getClientId(),
      callback = payload.callback || null,
      first = null,
      last = null,
      dateRangeType = payload.dateRangeType
        ? payload.dateRangeType
        : 'DATE_ONLY';

    if (payload.rangeType === 'C') {
      first = payload.startDate;
      last = payload.endDate;
    }

    if (
      (dateRangeType === 'DATE_TIME' &&
        payload.number === 0 &&
        payload.rangeType === 'D') ||
      payload.rangeType === 'H'
    ) {
      today = null;
    }

    const criteria = {
      customerKey: clientId,
      calendarType: calendarType,
      rangeType: payload.rangeType,
      number: payload.number,
      asOf: today,
      first: first,
      last: last,
      dateRangeType: dateRangeType,
    };

    const date = new DateRange(criteria);

    _.extend(date, {
      urlparams: {
        dateRange: JSON.stringify(date.toJSON({ format: 'YYYY-MM-DD' })),
      },
    });

    return date.fetch({ doNotAbort: true }).then(
      function (setParseDate, data) {
        data = this.parseDates(data, this.parseItem);
        if (setParseDate) {
          this.setBaseParseDates(data);
        }
        if (callback) {
          callback(data);
        }
        const dateCriteria = {
          rangeType: data.r,
          start_date: data.f,
          end_date: data.l,
          number: data.n,
          calendarType: calendarType,
          selected: payload.selected,
          selectedDateId: payload.id,
          dateRangeType: data.t,
        };
        this.handleDateRangeChanged(dateCriteria);
      }.bind(this, payload.setParseDate)
    );
  }

  setBaseParseDates(data) {
    this.parseBaseDates.start_date = new moment(data.f);
    this.parseBaseDates.end_date = new moment(data.l);
    this.parseBaseDates.calendarType = data.c;
    this.parseBaseDates.number = data.n;
    this.parseBaseDates.rangeType = data.r;
    this.parseBaseDates.selected = this.selected;
  }

  parseDates(dateRange, parseItem) {
    const parsed = {
      c: parseItem(dateRange.c, 'calendarTypes'),
      r: parseItem(dateRange.r, 'rangeTypes'),
      p: parseItem(dateRange.p, 'periodTypes'),
      n: dateRange.n,
      a: dateRange.a,
      f: dateRange.f,
      l: dateRange.l,
      k: dateRange.k,
      t: dateRange.t,
      v: dateRange.v,
    };
    return parsed;
  }

  parseItem(t, type) {
    const types = DateRange[type];
    if (_.contains(_.keys(types), t)) {
      t = types[t];
    }
    return t;
  }

  handleDateRangeChanged(payload) {
    let today =
      typeof payload.today !== 'undefined' ? payload.today : new moment();
    const calendarType = payload.calendarType || this.baseDate.c;
    let rangeType = 'C';
    let start_date = null;
    let end_date = null;
    let number = null;
    const selected = payload.selected || 0;
    const verbose = false;
    const rolling = false;
    const clientId =
      calendarType === 'G' || calendarType === 'GREGORIAN'
        ? ''
        : this.getClientId();
    const dateRangeType = payload.dateRangeType
      ? payload.dateRangeType
      : 'DATE_ONLY';

    if (
      (dateRangeType === 'DATE_TIME' &&
        payload.number === 0 &&
        payload.rangeType === 'D') ||
      payload.rangeType === 'H'
    ) {
      today = null;
    }

    if (typeof payload.rangeType === 'undefined' || payload.rangeType === 'C') {
      start_date = payload.start_date;
      end_date = payload.end_date;
    } else {
      rangeType = payload.rangeType;
      number = payload.number;
      rangeType = payload.rangeType;
    }

    const criteria = {
      customerKey: clientId,
      calendarType: calendarType,
      rangeType: rangeType,
      number: number,
      asOf: today,
      first: start_date,
      last: end_date,
      verbose: verbose,
      rolling: rolling,
      dateRangeType: dateRangeType,
    };

    const date = new DateRange(criteria);

    _.extend(date, {
      urlparams: {
        dateRange: JSON.stringify(date.toJSON({ format: 'YYYY-MM-DD' })),
      },
    });

    date.fetch().then(
      function (data) {
        // var dateTerms =
        // {
        //     calendarTypes: {
        //         GREGORIAN: 'G',
        //         FISCAL: 'F'
        //     },
        //     rangeTypes: {
        //         DAY: 'DY',
        //         WEEK: 'WK',
        //         MONTH: 'MO',
        //         QUARTER: 'QR',
        //         YEAR: 'YR',
        //         DAYS: 'D',
        //         WEEKS: 'W',
        //         MONTHS: 'M',
        //         QUARTERS: 'Q',
        //         YEARS: 'Y',
        //         CUSTOM: 'C'
        //     },
        //     periodTypes: {
        //         DEFINED: 'D',
        //         PRIOR: 'P',
        // PRIOR_YEAR: 'Y',
        // YEAR: 'Y'
        //     },
        //     modes: {
        //         RTD: 'rtd',
        //         LAST: 'last',
        //         LASTN: 'lastn',
        //         EXACT: 'exact'
        //     }
        //   };

        data = this.parseDates(data, this.parseItem);
        this.selected = selected;
        this.setBaseParseDates(data);
        const start_date = new moment(data.f),
          end_date = new moment(data.l),
          prevStart_date = new moment(data.f),
          prevEnd_date = new moment(data.l),
          number = data.n,
          dateRangeType = data.t;

        this.setBaseDate(
          start_date.format('YYYY-MM-DD'),
          end_date.format('YYYY-MM-DD'),
          prevStart_date.format('YYYY-MM-DD'),
          prevEnd_date.format('YYYY-MM-DD'),
          rangeType,
          calendarType,
          number,
          dateRangeType,
          selected
        );

        this.rangeType = data.r;

        this.publish('storage:set', {
          key: 'measures-dateRange',
          data: {
            start_date,
            end_date,
            prevStart_date,
            prevEnd_date,
            rangeType,
            selected,
            calendarType,
            number,
          },
        });

        this.publish('measures:dateRange', {
          start_date,
          end_date,
          prevStart_date,
          prevEnd_date,
          rangeType,
          selected,
          calendarType,
          number,
          baseDate: this.baseDate,
          selectedDateId: payload.selectedDateId,
        });
        if (payload.callback) {
          payload.callback(data);
        }
      }.bind(this)
    );
  }

  handleCalendarChange(calendarType) {
    this.baseDate.c = calendarType;
    const dateCriteria = {
      rangeType: this.baseDate.r,
      start_date: this.baseDate.f,
      end_date: this.baseDate.l,
      number: this.baseDate.n,
      calendarType: calendarType,
    };
    this.handleDateRangeChanged(dateCriteria);
  }

  pollCSV(csvRequestModel, filename) {
    const _this = this;

    csvRequestModel.fetch().then(() => {
      const completed = csvRequestModel.get('completed');
      const failed = csvRequestModel.get('failed');

      if (failed) {
        _this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*A server error occurred*',
        });
        return;
      }

      if (!completed) {
        _.delay(_.bind(_this.pollCSV, _this), 2500, csvRequestModel, filename);
      } else {
        const csvFileModel = new CSVFileModel(
          {
            exportId: csvRequestModel.get('exportId'),
          },
          {
            client: this.client,
            measure: this.currentMeasurementKey,
          }
        );

        csvFileModel.fetch().then(() => {
          saveAs(csvFileModel.blob, filename);
        });
      }
    });
  }

  exportMeasuresRespondentsCSV(payload) {
    let hierarchyFilter;
    let modelFilter;
    let pageFilter;
    const { filters = [] } = payload;

    if (this.modelFilter) {
      modelFilter = {
        modelFilterId: this.modelFilter.modelFilterId,
      };
    }

    if (this.pageFilter) {
      pageFilter = {
        pageFilterId: this.pageFilter.pageFilterId,
      };
    }

    if (this.hierarchyFilter) {
      hierarchyFilter = {
        hierarchyFilterId: this.hierarchyFilter.filterId,
      };
    }

    this.publish('export:measures:respondents:csv', {
      measure: this.currMeasure,
      metrics: payload.metrics,
      dateRange: this.baseDate,
      hierarchyFilter,
      pageFilter,
      modelFilter,
      filters,
      fileName: payload.fileName,
    });
  }

  pollPPT(pptRequestModel) {
    const _this = this;

    pptRequestModel.fetch().then(() => {
      const completed = pptRequestModel.get('completed');
      const failed = pptRequestModel.get('failed');

      if (failed) {
        _this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*A server error occurred*',
        });
        return;
      }

      if (!completed) {
        _.delay(_.bind(_this.pollPPT, _this), 2500, pptRequestModel);
      } else {
        const pptFileModel = new PPTFileModel({
          fileName: pptRequestModel.get('fileName'),
          exportId: pptRequestModel.get('exportId'),
        });

        pptFileModel.fetch().then(() => {
          saveAs(
            pptFileModel.blob,
            pptFileModel.get('fileName').replace(/ /g, '_') + '.pptx'
          );
        });
      }
    });
  }

  exportMainDashboardPPT(allChartCardData) {
    const today = new moment().startOf('day');
    this.exportPPT(
      allChartCardData,
      'main-dashboard',
      `ForeSee CX 360: ${today.format('MMM DD, YYYY')}`
    );
  }

  exportCXSurveyDashboardPPT(allChartCardData) {
    const today = new moment().startOf('day');
    this.exportPPT(
      allChartCardData,
      'cx-survey-dashboard',
      `${this.currMeasure.name.replace('.', ' ')}: ${today.format(
        'MMM DD, YYYY'
      )}`
    );
  }

  exportTADashboardPPT(allChartCardData) {
    const today = new moment().startOf('day');
    const name = allChartCardData.name;
    delete allChartCardData.name;
    this.exportPPT(
      allChartCardData,
      'ta-dashboard',
      `${this.getString('general.appName')}: ${name}: ${today.format(
        'MMM DD YYYY'
      )}`
    );
  }

  exportPPT(allChartCardData, url, fileName) {
    const today = new moment().startOf('day');
    const cardBook = new CardBookModel();
    if (url) {
      cardBook.urlRoute = url;
    }

    const slides = [];
    for (const key in allChartCardData) {
      const chart = allChartCardData[key];
      const data = this.clone(chart);
      if (!data) {
        continue;
      }

      delete data.export;
      slides.push({
        chart: {
          route: key,
          data: data,
          resolution:
            chart.export.width && chart.export.height
              ? {
                  width: chart.export.width,
                  height: chart.export.height,
                }
              : null,
        },
        footer: {
          exported: `${this.strings.user.user_full_name} on ${today.format(
            'MMM DD, YYYY'
          )}`,
          n: chart.export.NCount,
          source: this.controllers.user.client.get('name'),
        },
        header: {
          subtitle: {
            date: chart.export.date,
          },
          title: chart.export.title,
        },
      });
    }
    const _this = this;

    cardBook.set('fileName', fileName);
    cardBook.set('slides', slides);
    cardBook.save(function (err, response, status, jqXHR) {
      if (!response) {
        _this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: '*A server error occurred*',
        });
        return;
      } else {
        const pptRequestModel = new PPTRequestModel(response);
        pptRequestModel.save().then(() => {
          _this.pollPPT(pptRequestModel);
        });
      }
    });
  }

  getFilterCriteria(filter, type) {
    const criteria = {};
    criteria.version = filter.version;

    switch (type) {
      case 'model':
        criteria.modelFilterId = filter.modelFilterId;
        // criteria.modelFilterLastUpdated = filter.lastUpdated;    // Needed to invalidate local cache when using new filters model
        break;

      case 'page':
        criteria.pageFilterId = filter.pageFilterId;
        // criteria.pageFilterLastUpdated = filter.lastUpdated;     // Needed to invalidate local cache when using new filters model
        break;

      case 'hierarchy':
        criteria.hierarchyFilterId = filter.filterId;
        criteria.hierarchyFilterLastUpdated = filter.lastUpdated;
        break;

      default:
        break;
    }
    return criteria;
  }

  anomalyDrillin(payload) {
    payload.callback = () => {
      this.changeRoute('measures.dashboard', {
        measurementKey: payload.measurementKey,
        type: payload.route,
      });
      if (payload.questionKey && payload.answerKey) {
        this.publish('filters:chip:add', {
          filterType: constants.FILTER_TYPE_QUESTION,
          measurementKey: payload.measurementKey,
          question: {
            label: payload.questionLabel,
            questionKey: payload.questionKey,
          },
          respType: constants.QT_DROPDOWN, //TODO - This is unknown at this point
          selectedItems: [
            {
              label: payload.answerLabel,
              value: {
                answerKey: payload.answerKey,
                questionKey: payload.questionKey,
              },
            },
          ],
        });
      }
    };
    this.handleDateRangeChanged(payload);
  }
}

export default MeasuresController;

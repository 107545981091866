import _ from 'underscore';
import moment from 'moment';
import classNames from 'classnames';
import BaseController from 'controllers/base';
import AggregrationEventMetricsModel from 'models/aggregation/customer/app/metrics';
import AggregrationEventPropertiesModel from 'models/aggregation/customer/app/events/properties';
import AggregrationEventsModel from 'models/aggregation/customer/app/events';

class CollectionEventsController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'collection-events:apps:get': '_getApps',
        'collection-events:get': '_getEvents',
        'collection-events:properties:get': '_getProperties',
        'collection-events:filters:menus': 'getFilterMenus',
        'collection-events:series:get': '_getSeries',
      },
      name: 'collection-events',
      dependsOn: ['event-filters'],
    });
  }

  initialize() {
    this.apps = [
      {
        key: 'digital_cxmeasure',
        label: this.getString('dashboards.dataSources.digital'),
        events: [],
      },
      {
        key: 'nondigital_cxmeasure',
        label: this.getString('dashboards.dataSources.nondigital'),
        events: [],
      },
      // Descoped from 18.1
      // {
      //   key: "feedback",
      //   label: this.getString("dashboards.dataSources.feedback"),
      //   events: []
      // },
    ];
  }

  _getApps(payload) {
    this.publish('app:updateData', {
      collectionEventApps: this.apps,
    });
  }

  _getEvents(payload) {
    const { appKey } = payload;
    if (!appKey) {
      return;
    }
    const model = new AggregrationEventMetricsModel(false, {
      clientId: this.getClientId(),
      source: appKey,
    });
    model
      .fetch({
        error: () => {},
      })
      .then(() => {
        this.publish('app:getData', {
          key: 'collectionEvents',
          callback: (collectionEvents) => {
            collectionEvents = collectionEvents || {};
            let events = [];
            const counts = model.get('counts');
            const rates = model.get('rates');
            if (counts && counts.length) {
              events.push({
                label: 'Collection Counts',
                type: 'event_count',
                items: counts,
              });
            }
            if (rates && rates.length) {
              events.push({
                label: 'Collection Rates',
                type: 'event_rates',
                items: rates,
              });
            }
            collectionEvents[appKey] = events;
            this.publish('app:updateData', { collectionEvents });
          },
        });
      });
  }

  _getProperties(payload) {
    const { appKey, eventKey } = payload;
    if (!appKey || !eventKey) {
      return;
    }
    const metrics = eventKey.split('/');
    const model = new AggregrationEventPropertiesModel(false, {
      clientId: this.getClientId(),
      source: appKey,
      event: metrics[0],
    });
    model
      .fetch({
        error: () => {},
      })
      .then(() => {
        this.publish('app:getData', {
          key: 'collectionEventProperties',
          callback: (collectionEventProperties) => {
            const propertiesKey = appKey + '_' + metrics[0];
            collectionEventProperties = collectionEventProperties || {};
            collectionEventProperties[propertiesKey] = model.toJSON();
            this.publish('app:updateData', { collectionEventProperties });
          },
        });
      });
  }

  getFilterMenus(payload) {
    const { appKey, eventKey } = payload;
    if (!appKey || !eventKey) {
      return;
    }
    const metrics = eventKey.split('/');
    const model = new AggregrationEventPropertiesModel(false, {
      clientId: this.getClientId(),
      source: appKey,
      event: metrics[0],
    });
    model
      .fetch({
        error: () => {},
      })
      .then(() => {
        payload.callback(model.toJSON());
      });
  }

  _getSeriesCriteria(payload) {
    const { dateRange, dimensions, eventFilterId } = payload;
    const dateDimension = _.findWhere(dimensions, { type: 'DATE' });
    const propertyDimension = _.findWhere(dimensions, {
      type: 'eventProperty',
    });
    let criteria = {
      startDate: new moment(dateRange.f).utc().startOf('day'),
      endDate: new moment(dateRange.l).utc().endOf('day'),
      granularity: 'all',
      aggregations: [
        {
          aggregation: 'sum',
          metric: 'count',
          name: 'count',
        },
      ],
    };
    if (dateDimension) {
      criteria.granularity = classNames({
        hour: dateDimension.key === 'HOURLY',
        day: dateDimension.key === 'DAILY',
        week: dateDimension.key === 'WEEKLY',
        month: dateDimension.key === 'MONTHLY',
        quarter: dateDimension.key === 'QUARTERLY',
        year: dateDimension.key === 'YEARLY',
      });
    }

    if (propertyDimension) {
      criteria.metric = 'count';
      criteria.dimension = propertyDimension.key;
      criteria.threshold = 20;
    }

    if (eventFilterId) {
      criteria.filters = {
        filterId: eventFilterId,
      };
    }
    return criteria;
  }

  _getSeries(payload) {
    const { appKey, eventName, eventLabel, dimensions } = payload;
    const metrics = eventName.split('/');
    const promises = metrics.map((metric) => {
      return new Promise((resolve) => {
        const model = new AggregrationEventsModel(
          this._getSeriesCriteria(payload),
          {
            clientId: this.getClientId(),
            dateDimension: _.findWhere(dimensions, { type: 'DATE' }),
            propertyDimension: _.findWhere(dimensions, {
              type: 'eventProperty',
            }),
            event: metric,
            eventLabel,
            source: appKey,
          }
        );
        model
          .save(null, {
            error: (m, r) => {
              resolve({
                hasError: true,
                unauthorized: r.status === 403,
              });
            },
          })
          .then(() => {
            resolve(model.get('series'));
          });
      });
    });

    Promise.all(promises).then((data) => {
      let numerator = data[0];
      let denominator = data[1];
      if (!denominator) {
        payload.callback(numerator);
      } else {
        if (numerator.hasError) {
          payload.callback(numerator);
          return;
        }
        const rates = numerator.map((s, sIndex) => {
          const sDenominator = denominator[sIndex];
          if (s.points.length && sDenominator) {
            s.points = s.points.map((sPoint, pIndex) => {
              sPoint.value =
                (sPoint.value / sDenominator.points[pIndex].value) * 100;
              return sPoint;
            });
          } else {
            s.numerator = s.aggregate;
            s.numeratorLabel = s.label;
            s.denominator = sDenominator.aggregate;
            s.denominatorLabel = sDenominator.label;
            s.aggregate = (s.aggregate / sDenominator.aggregate) * 100;
          }
          return s;
        });
        payload.callback(rates);
      }
    });
  }
}

export default CollectionEventsController;

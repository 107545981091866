import Backbone from 'backbone';
import _ from 'underscore';
import HierarchyDraftsModel from 'models/clients/hierarchies/drafts';

const HierarchyDraftsCollection = Backbone.Collection.extend({
  model: HierarchyDraftsModel,
});
const HierarchyPageableDraftsModel = Backbone.Model.extend({
  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.hierarchyId = options.hierarchyId;
    this.offset = options.offset;
    this.limit = options.limit;
  },

  parse: function (data) {
    data.results = new HierarchyDraftsCollection(data.results, { parse: true });
    return data;
  },

  toJSON: function () {
    return _.extend(this.attributes, {
      results: this.attributes.results ? this.attributes.results.toJSON() : [],
    });
  },

  getUserIds: function () {
    return this.get('results').map((result) => {
      return result.get('updatedBy');
    });
  },

  setUsers: function (users) {
    this.get('results').forEach((result) => {
      const user = _.findWhere(users, { userId: result.get('updatedBy') });
      result.set('updatedByUser', user);
    });
  },

  url: function () {
    const query = {
      offset: this.offset,
      limit: this.limit,
    };
    return `clients/${this.client}/hierarchies/${
      this.hierarchyId
    }/drafts?${new URLSearchParams(query)}`;
  },
});

export default HierarchyPageableDraftsModel;

import Backbone from 'backbone';

var ProjectSummaryModel = Backbone.Model.extend({
  idAttribute: 'projectId',
  initialize: function () {},

  parse: function (data) {
    return data;
  },
});

export default ProjectSummaryModel;

import Backbone from 'backbone';
import AnswerModel from 'models/clients/projects/questions/answer';

var AnswersCollection = Backbone.Collection.extend({
  model: AnswerModel,
  initialize: function (models, opts) {
    var options = opts || {};

    this.client = options.clientId;
  },

  comparator: 'displayOrder',
});

export default AnswersCollection;

import featureFlags from 'core/featureFlags';
import { camelToKebab } from './string-conversions';
import Constants from '../constants';
export const hasFeature = (flag) => {
  return featureFlags.hasFeature(flag);
};

export const getCardFeatureFlag = (chartType, suffix) => {
  return `${camelToKebab(chartType)}-${suffix}`;
};

export const isOldModelInsights = () => {
  const location = document.location.href;
  const oldPriorityMap = !hasFeature(
    getCardFeatureFlag('priority-map', 'hide-legacy-card')
  );
  const oldScoreSummary = !hasFeature(
    getCardFeatureFlag('score-summary', 'hide-legacy-card')
  );
  return (
    location.indexOf('/analytics/model') > -1 &&
    (oldPriorityMap || oldScoreSummary)
  );
};

import Backbone from 'backbone';
import featureFlags from 'core/featureFlags';

const TAFilterModel = Backbone.Model.extend({
  idAttribute: 'filterId',

  initialize: function (opts) {
    this.clientId = opts.clientId;
    this.measureId = opts.measureId;
    this.customFeedId = opts.customFeedId;
    this.projectId = opts.projectId;
  },

  url: function () {
    let idString = '';
    if (this.id) {
      idString = `/${this.id}`;
    }
    const baseUrl = featureFlags.hasFeature('new_ta-admin')
      ? 'ta-reporting'
      : 'ta-reporting-beta';

    if (this.measureId) {
      return (
        `${baseUrl}/clients/` +
        this.clientId +
        '/filters/measures/' +
        this.measureId +
        '/textAnalyticsFilters' +
        idString
      );
    }
    if (this.customFeedId) {
      return (
        `${baseUrl}/clients/` +
        this.clientId +
        '/filters/feeds/' +
        this.customFeedId +
        '/textAnalyticsFilters' +
        idString
      );
    }
    if (this.projectId) {
      return (
        `${baseUrl}/clients/` +
        this.clientId +
        '/filters/projects/' +
        this.projectId +
        '/textAnalyticsFilters' +
        idString
      );
    }
  },
});

export default TAFilterModel;

/**
 * US English
 * @type {Object}
 */

export default {
  /**
   *  The name of the language
   */
  _name: 'English',
  /*
   *  Site strings
   */
  general: {
    date: 'Date',
    multipleDates: 'Multiple Date Ranges',
    help: 'Help',
    logout: 'Log Out',
    cancel: 'Cancel',
    confirm: 'Confirm',
    delete: 'Delete',
    deleteAreYouSure: 'Are you sure you want to delete ${name}',
    submit: 'Submit',
    select: 'Select',
    find: 'Find...',
    new: 'New',
    ok: 'Ok',
    save: 'Save',
    saveAs: 'Save As...',
    saveFilter: 'Save Filter',
    saving: 'Saving...',
    loading: 'Loading...',
    saved: 'Saved',
    done: 'Done',
    on: 'On',
    and: 'And',
    or: 'Or',
    off: 'Off',
    prev: 'Previous',
    next: 'Next',
    back: 'Back',
    apply: 'Apply',
    clear: 'Clear',
    clearAll: 'Clear All',
    active: 'Active',
    archived: 'Archived',
    add: 'Add',
    all: 'All',
    change: 'Change',
    label: 'Label',
    copied: 'Copied',
    archive: 'Archive',
    unarchive: 'Unarchive',
    copy: 'Copy',
    copyOf: 'Copy of ${name}',
    dismiss: 'Dismiss',
    inactive: 'Inactive',
    yes: 'Yes',
    no: 'No',
    notSet: 'Not set',
    show: 'Show',
    hide: 'Hide',
    visible: 'Visible',
    hidden: 'Hidden',
    defaults: 'Defaults',
    searchableMenu: {
      sorry: 'Sorry, we didn’t find anything.',
      try: 'Try searching something else.',
    },
    errors: {
      default: 'A server error has occurred',
      fileType: 'Unsupported file type',
      processing: {
        header: 'Welcome to the Predictive Experience',
        description:
          'Looks like you have new permissions and we are in the process of applying them',
        unauthorized:
          'Oops! Looks like we were not able to process your changes. Please try logging out and logging back in to the Predictive Experience',
      },
    },
    previous: 'Previous',
    defaultDropdownLabel: 'Choose...',
    search: 'Search...',
    searchField: 'Search ${field}...',
    options: 'Options',
    name: 'Name',
    noneSelected: 'None selected',
    total: 'Total',
    client: 'Client',
    enabled: 'Enabled',
    disabled: 'Disabled',
    status: 'Status',
    access: 'Access',
    owner: 'Owner',
    sharing: 'Sharing',
    public: 'Public',
    private: 'Private',
    one: 'One',
    two: 'Two',
    three: 'Three',
    four: 'Four',
    top: 'Top',
    bottom: 'Bottom',
    priority: 'Priority',
    measure: 'CX Measurement',
    custom: 'Custom Feed',
    hierarchy: 'Hierarchy',
    masterDataAttributes: 'Master Data Atttributes',
    locations: 'Locations',
    csat: 'CSAT',
    nps: 'NPS®',
    npsSegment: 'NPS® Segment',
    responses: 'Responses',
    totalResponses: 'Total Responses',
    dateRange: 'Date Range',
    startDate: 'Start Date',
    endDate: 'End Date',
    segment: 'Segment',
    sent: 'Sent',
    keywords: 'Keywords',
    keyword: 'Keyword',
    verify: 'Verify',
    editDetails: 'Edit Details',
    selectAll: 'Select All',
    continue: 'Continue',
    system: 'System',
    score: 'Score',
    impact: 'Impact',
    other: 'Other',
    replay: 'Replay',
    sentiment: 'Sentiment',
    loadMore: 'Load More',
    added: 'Added',
    removed: 'Removed',
    edit: 'Edit',
    setUp: 'Set Up',
    untitled: 'Untitled',
    title: 'Title',
    subject: 'Subject',
    description: 'Description',
    lastUpdated: 'Last Updated',
    lastModified: 'Last Modified',
    areYouSure: 'Are you sure?',
    credentials: 'Credentials',
    filters: 'Filters',
    addFilter: 'Add Filter',
    addMoreFilters: 'Add More Filters',
    share: 'Share',
    undo: 'Undo',
    negative: 'Negative',
    positive: 'Positive',
    neutral: 'Neutral',
    type: 'Type',
    event: 'Event',
    feedback: 'Feedback',
    timeInTextFormats: {
      minutesAndSeconds: '${minutes}m ${seconds}s',
      seconds: '${seconds}s',
    },
    errorState: {
      heading: 'Something went wrong here...',
      subheading:
        'Sorry, it appears we are having a problem. You can refresh this card (that could work!)',
    },
    refresh: 'Refresh',
    currentlySelected: 'Currently Selected',
    download: 'Download',
    loginAs: 'Login as Another User',
    loginAsWarning:
      'With great power comes great responsibility! This form allows you to sign into any user account within your organization and act as that person. All changes are permanent when you sign in as another user.',
    loginAsButton: 'Log In',
    loginAsTitle: 'Login As...',
    loginAsSignedIn: '${activeUserName} is signed in as ${currentUserName}',
    loginAsLogOut: 'Switch Back to Me',
    na: 'N/A',
    minimum: 'Minimum',
    maximum: 'Maximum',
    minimumValueError: 'Min value must be less or equal to max value',
    maximumValueError: 'Max value must be greater or equal to min value',
    rename: 'Rename',
    discardChanges: 'Discard Changes',
    unsavedChanges: 'Unsaved Changes',
    appName: 'Predictive Experience',
    rank: 'Rank',
    serviceName: 'Verint Connect',
    color: 'Color',
    colors: 'Colors',
    quadrant: 'Quadrant',
    respondentId: 'respondentId',
    remove: 'Remove',
    dateDisplay: 'Date Display',
    relativeDateDisplay: 'Relative',
    absoluteDateDisplay: 'Absolute',
  },

  app: {
    changePassword:
      'Your password is about to expire. Click here to change your password.',
  },

  export: {
    export: 'Export',
    downloadCSV: 'Download CSV',
    downloadPPT: 'Download PPT',
    generatingExport: 'Generating Export File',
    generatePPT: 'Generating PPT',
    generateCSV: 'Generating CSV',
  },

  iframe: {
    empty: {
      heading: 'Predictive Experience cannot be used in this manner.',
      description:
        'Predictive Experience cannot be used in this manner unless the parent URL is first configured within Feedback settings.',
    },
  },

  anomaly: {
    title: 'Anomaly Detection',
    anomaliesFound: 'Anomalies',
    mostRecent: 'Most Recent',
    timeInterval: 'Time Interval',
    dataSource: 'Data Source',
    metric: 'Metric',
    segmentCreated: 'Segment Created',
    segmentCreatedBy: '${date} by ${user}',
    archivedSegments: 'Archived Segments',
    activeSegments: 'Active Segments',
    createAnomalySegment: 'Create New Anomaly Segment',
    createAnomalyDesc:
      'The preview below helps you understand the historical trend for the segment you wish to monitor.',
    historicalPreview: 'Historical Preview',
    historicalDataCheck: 'Historical Data Check',
    plenty: 'Plenty of historical data (more than ${minimum} points)',
    segmentDelete: {
      success: "'${segmentName}' has successfully been ${action}.",
      error: "Unable to delete '${segmentName}', please try again later.",
    },
    segmentArchive: {
      success: "'${segmentName}' has successfully been ${action}.",
      error: "Unable to archive '${segmentName}', please try again later.",
    },
    segmentUnarchive: {
      success: "'${segmentName}' has successfully been ${action}.",
      error: "Unable to unarchive '${segmentName}', please try again later.",
      UNARCHIVE_HIST_ERROR:
        'Please try again in ${count} days when more data is available.',
    },
    granularity: {
      day: 'Daily',
      week: 'Weekly',
    },
    ARCHIVED: 'ARCHIVED',
    UNARCHIVED: 'UNARCHIVED',
    DELETED: 'DELETED',
    archiveModal: {
      header: '${action} Segment?',
      description: {
        opener: 'Are you sure you want to ',
        archive:
          'Archiving this segment stops monitoring for anomalies. Any historical anomalies will be saved.',
        unarchive:
          'Unarchiving this segment automatically starts monitoring this segment for anomalies. There will be no anomalies shown from the date the segment was archived to when it was unarchived.',
        delete:
          'Any historical data collected will be deleted. This is a permanent action and cannot be undone.',
      },
      confirmText: 'Yes, ${action} segment',
    },
    segmentCreatedModal: {
      header: 'Anomaly Detection Segment Created!',
      description:
        "We are looking for historical anomalies on '${name}'. Please check back to see the results in a few hours.",
    },
    allSegments: 'All Segments',
    mySegments: 'My Segments',
    searchPlaceholder: 'anomaly segments',
    new: '${count} New',
    segmentsRemaining: 'segments remaining',
    segmentRemaining: 'segment remaining',
    sortOrder: {
      segmentName_asc: 'Name: A-Z',
      segmentName_desc: 'Name: Z-A',
      anomalies_desc: 'Anomalies: High to Low',
      anomalies_asc: 'Anomalies: Low to High',
      lastAnomaly_desc: 'Most Recent:  Most Recent to Oldest',
      lastAnomaly_asc: 'Most Recent: Oldest to Most Recent',
      sourceName_asc: 'Data Source: A-Z',
      sourceName_desc: 'Data Source: Z-A',
      createdDate_desc: 'Segment Created: Most Recent to Oldest',
      createdDate_asc: 'Segment Created: Oldest to Most Recent',
    },
    internalUserWarning:
      'Customer does not have Anomaly Detection Enabled. Please do not create any segments for this measure.',
    create: {
      copyWarning:
        'Note: The copy feature provides a quick way to create a new segment that has minor differences to the copied segment. Be aware that you cannot create an identical segment to the one that already exists.',
      newAnomalySegment: 'New Anomaly Segment',
      createSegment: 'Create Segment',
      segmentName: 'Segment Name',
      historicalPreview: 'Historical Preview',
      historicalDataCheck: 'Historical Data Check',
      followingIssues: 'We found the following issues:',
      expandByRemovingFilters: 'You may be able to expand your data set by ',
      removingFilters: 'removing filters.',
      checkForAnomaliesBy: 'Check for anomalies by',
      dayRecommended: 'Day (Recommended)',
      week: 'Week',
      pleaseEnterValidName: 'Please enter a valid name.',
      historicalDataCheckEmptyMsg:
        "We'll check to be sure there is enough historical data for the segment you choose.",
      successCreateSegment:
        "Anomaly segment '${segmentName}' has successfully been created!",
      duplicateError:
        'Duplicate segment error: An anomaly segment matching this criteria already exists.',
      duplicateActiveErrorWithName:
        'Duplicate segment error: The active segment {${jobName}} matches this criteria.',
      duplicateArchivedErrorWithName:
        'Duplicate segment error: The archived segment {${jobName}} matches this criteria. Please unarchive that segment.',
      ERROR_CODES: {
        INSUFF_DATA:
          'Not enough data to check for anomalies (${count} out of ${minimum} data points required)',
      },
      noFiltersApplied: 'No filters applied to this segment',
    },

    detail: {
      segmentDetails: 'Segment Details',
      dataSource: 'Data Source',
      metric: 'Metric',
      filters: 'Filters',
      dailyTrend: 'Daily Trend',
      anomalies: 'Anomalies',
      viewResponses: 'View Responses',
      segmentsToInvestigate: ' segments to investigate',
      stillProcessing:
        "We're still in the process of looking at previous data. Check back soon for your results.",
      noneFound: 'No anomalies found.',
      savingSegmentName: 'Updating segment name...',
      successSavingName: "'${name}' was successfully updated.",
      errorSavingName: 'There was an error trying to save the segment name.',
      anomalyConfidence: {
        confident: 'We are confident this is an anomaly',
        veryConfident: 'We are very confident this is an anomaly',
      },
      predicted: 'predicted',
      notEnoughSampleSize: 'Segment sample size too small to investigate.',
    },
  },

  profile: {
    title: 'Profile',
    profileDetails: 'User Details',
    editProfileDetails: 'Edit User Details',
    firstName: 'First Name',
    lastName: 'Last Name',
    editFirstName: 'Edit First Name',
    editLastName: 'Edit Last Name',
    email: 'Email',
    username: 'Username',
    singleSignOn: 'Single Sign On',
    userAdded: 'User Added',
    customPermission:
      'Permissions enabled via role assignment cannot be unassigned',
    role: {
      titleAdmin: 'User Roles',
      titleProfile: 'My Roles',
      toggleAction: 'Manage Roles',
      roles: 'Roles',
      assignedBy: 'Assigned By',
      viewAccessAdmin: "View User's Full Access",
      viewAccessProfile: 'View My Full Access',
      columnHeader: 'Role Name & Description',
      noFeatureAccess: 'This ${object} has no feature access.',
      noDataAccess: 'This ${object} has no data access.',
      allHierarchyAccess:
        'This ${object} has unrestricted access to the hierarchy.',
      noHierarchyAccess: 'This ${object} has no access to the hierarchy.',
      allTouchpointAccess:
        'This user has unrestricted access to all touchpoints.',
      noTouchpointAccess: 'This user has no access to touchpoints.',
      allSitekeyAccess: 'This user has unrestricted access to all sites.',
      noSitekeyAccess: 'This user no access to sites.',
      allDataSourceAccess:
        'This user has unrestricted access to all data sources.',
      noDataSourceAccess: 'This user has no access to data sources.',
      hierarchy: 'Hierarchy:',
      hierarchyLevel: 'Hierarchy Level:',
      hierarchyNodes: 'Hierarchy Nodes:',
      selectNode: 'Select Nodes...',
      selectedNodes: 'Selected ${length} nodes',
      deleteHierarchy: 'Hierarchy will be removed on save',
      required: 'required',
      userAccess: 'User Access',
      role: 'role',
      user: 'user',
      cascadeNodes: 'Cascade Nodes:',
      explicitNodes: 'Explicit Nodes:',
      review:
        "Review current permissions before assigning a role. Assigning a role will overwrite the user's migrated permissions",
      noAssignedRole: 'No Assigned Role',
      editAccess: 'Edit Access',
      customAccess: 'CUSTOM ACCESS',
    },

    odata: {
      title: 'OData Credentials',
      create: 'Create Credential',
      edit: 'Edit Credential',
      createOdata: 'Create OData Credential',
      credentialsDesc:
        "Credentials are connected with your user account and it's associated permissions. Refer to the Help and Training material.",
      thirtyDays: '30 Days',
      sixtyDays: '60 Days',
      ninetyDays: '90 Days',
      accessPeriod: 'Access Period',
      oData: 'OData',
      oDataDesc:
        'Connect CX Measure Data to Excel, Power BI, or any OData 4.0 compatible application. ${link}',
      notSureOdata: 'Not sure how to use OData?',
      username: 'Username',
      password: 'Password',
      expires: 'Expires',
      areYouSure: 'Are you sure you want to delete this credential?',
      deleteCredentials:
        'Deleting the OData credentials will end access for all applications consuming Predictive Experience data.',
      userNotCreated: 'User has not created OData credentials.',
    },

    sms: {
      title: 'Password Reset via Text Message',
      toggleAction: 'Enroll in Password Reset',
      editToggleAction: 'Edit Enrollment Details',
      description:
        'By enrolling in password reset via text message, you give us the permission to send a verification code to your SMS device (at your request) in the event that you no longer have access to your password.',
      inputDesc:
        'Select the country and enter the phone number associated with your mobile phone.',
      country: 'Country',
      phoneNumber: 'Phone Number',
      enterPhoneNumber: 'Enter phone number',
      sendCode: 'Send Code',
      typeCode: 'Enter Verification Code...',
      userEnrolled:
        'Enrolled in password reset via text message with phone number ${phoneNumber}.',
      userNotEnrolled: 'User not enrolled in password reset via text message.',
      user404EnrollmentStatus:
        "There was a problem retrieving this user's enrollment status. Please contact your administrator or create a case at ${link}",
      deleteEnrollment: 'Delete Enrollment',
      deleteEnrollmentDesc:
        'Deleting enrollment removes the phone number and the ability for password reset via text message.',
      enterVerificationCode: 'Enter Verification Code',
      notReceivingText: 'Resend Verification Code',
      phoneNumberAlreadyVerified: 'Phone number already verified.',
      failedToEnrollPhoneNumber: 'Failed to enroll phone number: ${message}',
      failedToResendVerificationCode: 'Failed to resend verification code',
      invalidVerificationCode: 'Invalid verification code',
      areYouSure: 'Are you sure you want to delete this enrollment?',
      deletePhoneNumberDesc:
        'By deleting the phone number, you can no longer reset your password via text message.',
      deleteUserPhoneNumberDesc:
        'By deleting the phone number, this user can no longer reset your password via text message.',
    },
  },

  password: {
    changePassword: 'Change Password',
    descriptionBoth:
      "To meet security requirements, passwords must be changed every ${maxAgeDays} days and can't be the same as any of your ${historyCount} previous passwords.",
    descriptionAge:
      'To meet security requirements, passwords must be changed every ${maxAgeDays} days.',
    descriptionHistory:
      "To meet security requirements, passwords can't be the same as any of your ${historyCount} previous passwords.",
    minCharacters: '${minLength} Characters',
    upperCaseLetter: 'Uppercase Letter',
    lowerCaseLetter: 'Lowercase Letter',
    number: 'Number',
    specialCharacter: 'Special Character',
    currentPasswordMatch: 'Current Password Match',
    passwordsMatch: 'New Password Match',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    retypePassword: 'Retype New Password',
    currentPasswordPlaceholder: 'Enter your current password',
    newPasswordPlaceholder: 'Enter new password',
    retypePasswordPlaceholder: 'Retype new password',
    failedToChangePassword: 'Failed to change password',
    success: 'Success! Password has been changed.',
    initiatePasswordReset: 'Initiate Password Reset',
    initiatePasswordResetDesc:
      "Send user email to initiate password reset. This action will invalidate the user's current password and require them to complete password reset using the password reset email that you initiated.",
    sendPasswordReset: 'Send Password Reset Email',
    successPasswordReset: 'Success! Password reset email sent.',

    reset: {
      newPassword: 'New Password',
      typePassword: 'Type Password...',
      confirmNewPassword: 'Confirm New Password',
      saveNewPassword: 'Save New Password',
      passwordRequirements: 'Password Requirements',
      success: 'Your password has been successfully updated',
      expired: 'Your password reset link has expired or is invalid',
      failure: 'Failed to set new password.',
      showDetails: 'Show details',
      hideDetails: 'Hide details',
    },

    forgot: {
      resetPassword: 'Reset Password',
      resetDescription:
        'To reset your password, please provide your email or username and choose your preferred method.',
      placeholder: 'Enter username or email address',
      resetViaEmail: 'Reset via Email',
      resetViaSms: 'Reset via Text Message',
      smsDescription:
        'Reset via Text Message can only be used if you enrolled your mobile number via your user profile.',
      returnToLogin: 'Return to Login',
      passwordExpired: 'Your password reset link has expired or is invalid',
      passwordExpiredDesc:
        'Please either return to the login page or try the reset password process again.',

      email: {
        sent: 'If you are a valid Predictive Experience (ForeSee) user, then you will receive an email with instructions on resetting your password. If you do not receive an email, please contact your Predictive Experience (ForeSee) administrator or open a new case using our online support portal at ${link}.',
        notReceivingEmail: 'Not Receiving an Email?',
        tooltip: {
          spam: 'Be sure to check your spam folder for the email.',
          note: 'Note that if your email address is associated with multiple accounts, you will need to provide the username to reset the password.',
          support:
            'If you are still not receiving your email, contact your Predictive Experience (ForeSee) administrator or open a new case using our online support portal at ${link}',
        },
      },

      sms: {
        sent: "If you are a valid Predictive Experience (ForeSee) user who has enrolled in Password Reset via Text Message, then you will receive a verification code which you can use to reset your password. If you don't receive a verification code, please contact your Predictive Experience (ForeSee) administrator or open a new case using our online support portal at ${link}.",
        notReceivingSMS: 'Not receiving a text? Reset via Email',
        enterVerificationCode: 'Enter Verification Code',
        placeholder: 'Type Code...',
        failed: 'Expired or invalid verification code',
      },
    },
  },

  leaderboards: {
    label: 'Leaderboard',
    peerAvg: '(${avg} Peer Avg)',

    customQuestion: 'Custom Question',
    modelQuestion: 'Model Question',

    dashboard: {
      date: 'Date: ',
      search: 'Search ${level}...',
      rankBy: 'Rank by: ',
      cxScore: 'CX Score',
      chooseLevel: 'Choose Level...',
      nps: 'NPS®',
      status: 'Status: ',
      emptyState: {
        heading: "You don't have any leaderboards!",
        subheading:
          "In order to populate a leaderboard, you'll need to measure either: store, contact center, or employee level satisfaction.",
        button: 'See Available Measure Touchpoints',
      },
      card: {
        noData: 'No Data This Period',
        emptyState: {
          heading: 'This survey does not use our model scores for ranking',
          subheading: 'Please use full leaderboards to get more details',
          button: 'See Full Leaderboard',

          noLevelHeading: 'No associated levels found for this survey',
        },
        hasNPS: {
          heading: 'This survey only has NPS® for ranking.',
          subheading:
            'Please change your Rank By or use the full leaderboard to get more details',
        },
        tooLargeError: {
          heading:
            'We are unable to load your Leaderboard Dashboard due to the size of your Hierarchy.',
          subheading:
            'If you require assistance, please open a new case using our online support portal at ${link}.',
        },
      },
    },
    details: {
      rank: 'Rank',
      rc: 'Respondent Count',
      rankByColumn: 'Rank by this metric',
      nps: 'NPS®',
      help: "Add additional columns by clicking 'Customize Columns' or choose from a Saved View",
      members: 'Members',
      summary: 'Summary',
      removeFilter: {
        title: 'Remove Survey Filter',
        description:
          'Adding columns from additional measures will remove survey filtering capability.',
        confirmText: 'Add column and remove filter',
      },
    },
  },

  tree: {
    formSelect: {
      selectedNodes: '${number} nodes selected',
      selectedNode: '${selected}',
      selectNodes: 'Select Nodes...',
    },
  },

  audit: {
    autoAssignedUserRole: '${user} auto assigned to ${role}',
    assignedUserRole: '${responsible} assigned ${user} to ${role}',
    deleteUserRole: '${responsible} removed ${user} from ${role}',
    deletedRole: '${responsible} deleted ${role}',
    createdRole: '${responsible} created ${role}',
    updatedRole: '${responsible} updated ${role}',
  },

  authorization: {
    default: {
      defaultPermissions: 'Default Permissions',
      defaultPermissionsDesc: 'Edit your default permissions.',
      dataAccess: 'Data Access',
      measurementAccess: 'Measurement Access',
      organizationAccess: 'Organization Access',
      clientDefinedHierarchies: 'Client Hierarchies',
      featureAccess: 'Feature Access',
      systemGeneratedPermissions: 'System Generated Permissions',
      measurementsEnabled: 'All Measurements Enabled',
      viewAllMeasures:
        'By default, new users get access to view all measurements under their client organization.',
      viewAllHierarchies:
        'By default, new users get access to all client defined hierarchies',
      savePermissions: 'Save Permissions',
    },
    clientAccess: {
      savedCapabilities: 'Updated client access',
    },
    hierarchy: {
      siblings: 'Siblings',
      children: 'Siblings + Children',
      node: 'Node',
    },
    users: {
      active: 'Enabled',
      inactive: 'Disabled',
      all: 'All Users',
      search: 'Find Users (${userCount})',
      delete:
        'Are you sure you want to remove ${firstName} ${lastName} from ${role}',
      deleteDesc: 'Is this something you really want to do?',
      inconsistentProfile:
        'There was a problem synching your user profile. Please contact your administrator or create a case at ${link}.',
      inconsistentUser:
        "There was a problem synching this user's profile. Please try editing the user's details and saving the change to correct the issue.",
      profileMasteringEnabled:
        "You cannot edit this user's details since the Profile Updates and Access is controlled by the Client's Identity Provider. To change this behavior for your organization, please open a new case using our online support portal at ${link}.",
      xcEnabled:
        "You cannot edit this user's details since the Profile Updates and Access are controlled centrally by your administrator",
      create: {
        addNewUser: 'Add New User',
        editUser: 'Edit User',

        userDetails: 'User Details',
        firstName: 'First Name',
        firstNamePlaceholder: 'John',
        lastName: 'Last Name',
        lastNamePlaceholder: 'Smith',

        emailAddress: 'Email Address',
        emailAddressPlaceholder: 'example@gmail.com',
        emailDomainError: 'Email domain not registered by client',
        emailFormatError:
          'Email not in proper format. Please use email@domainname.com',

        phoneNumber: 'Phone Number',
        phoneNumberPlaceholder: 'xxx-xxx-xxxx',
        username: 'Username',
        usernameRegisteredError: 'Username is already taken.',

        hierarchyRole: 'Hierarchy Role',
        defaultRole: 'Default Role',
        defaultRoleDesc:
          'Please create a case at ${link} to change hierarchy role.',
        status: 'User Status: ${status}',
        selectClient: 'Select Client...',
        selectClientDesc:
          'You can use this tool to switch users to another client by finding and editing a previously created user',
        selectHierarchyRole: 'Select Hierarchy Role...',
      },

      status: {
        enableUser: 'Enable User',
        disableUser: 'Disable User',
        enableDesc:
          'Are you sure you want to enable the user ${firstName} ${lastName}?',
        disableDesc:
          'Are you sure you want to disable the user ${firstName} ${lastName}?',
        yesEnable: 'Yes, Enable',
        yesDisable: 'Yes, Disable',
        userEnableToast:
          'The user account ${firstName} ${lastName} has been enabled.',
        userDisableToast:
          'The user account ${firstName} ${lastName} has been disabled.',
      },
      details: {
        roleOverride: 'Role Override',
        noRoles: 'No roles created',
        selectRole: 'Select a role...',
        lastName: 'Last Name',
        firstName: 'First Name',
        userName: 'Username',
        role: 'Role',
        noRole: 'No role assigned',
        email: 'Email',
        lastLogin: 'Last Login',
        loginCounts: 'Login Count',
        userIncluded: 'User is included in dynamic uploads',
        userNotIncluded: 'User is not included in dynamic uploads',
        editUserInfo: 'Edit User Info',
      },
    },
    assignments: {
      createAssignmentRule: 'Create Assignment Rule',
      ruleNameAndDesc: 'Rule Name & Description',
      role: 'Role',
    },
    rules: {
      add: 'Create Assignment Rule',
      edit: 'Edit Assignment Rule',
      save: 'Save Rule',
      ruleName: 'Rule Name',
      roleNamePlaceholder: 'Add Name...',
      ruleDesc: 'Rule Description',
      descriptionPlaceholder: 'Add Description...',
      roleAssignment: 'Role Assignment',
      role: 'Role',
      manageRole: 'Manage Role',
      addRole: 'Add Role',
      editRole: 'Edit Role',
      onlyOneRole: 'A Rule can only have one role assignment.',
      ruleDef: 'Rule Definition',
      assignRoleToAll: 'Assign Role to All Users',
      assignRoleToHierarchy: 'Assign Role Using Hierarchy',
      assignRoleToUsers: 'Assign Role Manually',
      excludeUsers: 'Exclude Users',
      excludeFromRuleDef: 'Select Users to Exclude',
      hierarchy: 'Hierarchy',
      userAttribute: 'User Attribute',
      hierarchyAttribute: 'Hierarchy Attribute',
      roleMatchOne:
        'Hierarchy is defined by the hierarchy file on the role assignment.',
      roleMatchTwo:
        'Select the User Attribute from the Predictive Experience and the Hierarchy Attribute from ${hierarchyName}. When the two attributes match, then the role is assigned.',
      username: 'Username',
      email: 'Email',
      assignAll: 'Assign Rule to All Users',
      excludeAll: 'Exclude Rule to All Users',
      assignedUsers: 'Assigned Users (${count})',
      excludedUsers: 'Excluded Users (${count})',
      selectToExclude: 'Select Users to Exclude',
      noRoleSelected:
        'A role must be selected in order to create an Assignment Rule.',
      noHierarchySelected:
        'In order to match with this role, a Hierarchy must be provided.',
      alreadyExists: 'An Assignment Rule with the same name already exists!',
      delete: 'Are you sure you want to delete ${name}',
      deleteDesc: 'Is this something you really want to do?',
      deleteRule: 'Delete Rule',
      tooltip1:
        'Assign Role Using Hierarchy can be used when a Hierarchy is associated with the selected Role.',
      tooltip2:
        'Assign Role Manually can be used when there is no Hierarchy associated with the role.',
      profileMasteringEnabled:
        'SSO enabled users do not have the permission to Create or Edit Bulk Assignment Rules. Your organization has opted to do Profile Mastering (i.e. use the SAML exchange to determine the Profile Updates). Please create a case at ${link} to change this behaviour for your organization',
    },
    roles: {
      roleNameError: 'Role name includes invalid characters.',
      delete: 'Delete ${name}',
      deleteDesc:
        'Deleting ${roleName} will un-assign it from ${usedBy} users and delete any associated Assignment Rules.',
      create: 'Create Role',
      update: 'Save',
      save: 'Save Role',
      deleteRole: 'Delete Role',
      add: 'Create Role',
      edit: 'Edit Role',
      addPeople: 'Add User',
      addNewPeople: 'Add New User',
      nameRole: 'Name Your Role',
      setPermission: 'Set Permissions',
      setPermissionFor: 'Set Permissions for ${role}',
      search: 'Search Roles...',
      info: 'Edit the permissions and users contained to this role',
      roleName: 'Role Name',
      roleNameDesc: 'Give your new role a name',
      roleNamePlaceholder: 'Add Name...',
      description: 'Role Description',
      descriptionPlaceholder: 'Add Description...',
      dynamicHierarchy: 'Dynamic Hierarchy',
      dynamicHierarchyDesc:
        'Assign the hierarchy which users will be dynamically matched to',
      dynamicHierarchyLevel: 'Hierarchy Level',
      dynamicHierarchyLevelDesc:
        'The level of the hierarchy this role will be matched to',
      lastUpdated: 'Last Updated',
      usedBy: 'Used By',
      internallyUsedBy: 'Internally Used By',
      lastModifiedBy: '${date} by ${by}',
      roleNameAndDescription: 'Role Name & Description',
      assignedUsers: 'Assigned Users',
      lastUpdatedBy: 'Last Updated By',
      lastUpdatedDate: 'Last Updated Date',
      featureAccess: 'Feature Access',
      dataAccess: 'Data Access',
      hierarchyAccess: 'Hierarchy Access',
      touchpoints: 'Touchpoints',
      touchpointsSelectAll: 'Access to All Touchpoints',
      touchpointsSelectNo: 'Access to No Touchpoints',
      dataSources: 'Data Sources',
      dataSourcesSelectAll: 'Access to All Data Sources',
      dataSourcesSelectNo: 'Access to No Data Sources',
      sitekeys: 'Sitekeys',
      sitekeysSelectAll: 'Access to All Site Keys',
      sitekeysSelectNo: 'Access to No Site Keys',
      clients: 'Clients',
      clientsSelectAll: 'Access to All Clients',
      hierarchySelectAll: 'Access to All Defined Hierarchies',
      hierarchySome: 'Access to Some Defined Hierarchies',
      hierarchyNo: 'Access to No Defined Hierarchies',
      roleHierarchy: 'Role Hierarchy',
      roleHierarchyDesc:
        'The Hierarchy, Hierarchy Level, and Hierarchy Access will restrict any user assigned this role to data specific to that user',
      hierarchyAccessLevel: 'Hierarchy Access',
      ancestryAccessLevel: 'Top Level Aggregation',
      ancestryAccessLevelDefault: 'No Top Level Aggregation',
      siblingAccessLevel: 'Peer Level Scope',
      siblingAccessLevelDefault: 'No Restrictions',
      siblingTooltip:
        'Select the level in the hierarchy this role uses for peer comparison',
      ancestryTooltip:
        'Select the highest level in the hierarchy where this role can see aggregated data',
      onlyYou: 'Only You',
      youAndSame: 'You + Others at Same Level',
      youAndSameAndAll: 'You + Others at Same Level & All Levels Below',
      hierarchy: 'Hierarchy',
      selectHierarchy: 'Select Hierarchy...',
      hierarchyLevel: 'Hierarchy Level',
      selectHierarchyLevel: 'Select Hierarchy Level...',
      selectHierarchyNodes: 'Select Nodes you need Drill Down Access To',
      createSuccess: 'Successfully saved role: ${name}',
      invalidHierarchyLevel:
        'No hierarchy levels found with the selected hierarchy.',
      switchHierarchyAccess:
        'Changing Hierarchy access for this role will result in the role being unassigned from all users. Also, any Bulk Assignment Rule that assigns this role will be deleted.',
      warningRoleEdit:
        'Changing the role will affect all users assigned the role. Are you sure you want to change the role?',
      noHierarchySelected: 'No hierarchy provided.',
      cancelText: 'No, Keep Role',
      confirmText: 'Yes, Delete Role',
      dataSourceAccess: 'Data Source Access',
      dataSourcesSelectAllLabel: 'Access to All Data Sources',
    },
    capability: {
      noCapabilities: 'No permissions found',
      limitedHierarchyPermissions: 'Limited Hierarchy Permissions',
      hierarchyPermissions: 'Hierarchy Permissions',
      hierarchyAccess: 'Hierarchy Access',
      hierarchyName: 'Hierarchy',
      hierarchyLevel: 'Hierarchy Level',
      roleView: 'Role View',
      assignDynamically: 'Assign Dynamically',
      assignManually: 'Assign Manually',
      dynamicDescription:
        'When assigning dynamically, we’ll take email addresses that correspond to a user and automatically match them to a location and will see the children of where they matched. You’ll have the ability to determine which level of the hierarchy they are assigned to.',
      manualDescription:
        'Manually choose what level(s) of the hierarchy are included in this role. Once the role is created, you’ll be prompted to assign individual active users to the role.',
      andCanView: 'and can view',
      andCanViewChildren: 'and can view children',
      additionalAccess: 'Additional Access',
      clearAccess: 'Clear Access',
      noLevel: 'No level',
    },
  },

  pageOptions: {
    ten: '10 results per page',
    twentyFive: '25 results per page',
    fifty: '50 results per page',
  },

  globalSettings: {
    title: 'Global Settings',
    setup: 'Setup',
    apps: 'Apps',
    integrate: 'Integrate',
    SITE_ALIAS_CHARS_RANGE_MSG:
      'Acceptable characters are spaces, periods, dashes, underscores, a-z, A-Z, 0-9',
    setupSettings: {
      sidebarMenu: {
        userAdmin: 'User Admin',
        permissions: 'Permissions',
        users: 'Users',
        clientAccess: 'Client Access',
        roles: 'Roles',
        roleAssignments: 'Bulk Role Assignments',
        peopleRoles: 'Users & Roles',
        hierarchyMasterData: 'Hierarchy & Master Data',
        hierarchy: 'Hierarchy',
        masterData: 'Master Data',
        changeLog: 'Change Log',
        customDataSources: 'Custom Data Sources',
      },
      masterData: {
        header: 'Master Data Management',
        typeHeader: '${type} Master Data',
        description:
          'Master Data is the home for all the core data across your enterprise. Here you can view and manage your master data or create new types of master data.',
        records: 'Records',
        addNew: 'Add New Master Data Entry',
        attributes: 'Attributes',
        details: 'Details',
        versions: 'Change Log',
        changesMade: 'Changes Made ${date}',
        locations: 'Locations',
        employees: 'Employees',
        agents: 'Agents',
        createNew: 'Create New Master Data Type',
        name: 'Name',
        chooseAnIcon: 'Choose an Icon',
        diff: {
          number: 'Version ${number} by ${user} at ${time}',
          before: 'Before',
          after: 'After',
          created: 'Created',
        },
        empty: {
          heading: 'Nothing to show yet',
          subheading:
            'It looks like your ${name} master data is currently empty.  We recommend adding your first set of master data during the initial upload of your hierarchy.',
        },
        emptySearch: {
          heading: 'No results found',
          subheading:
            'Try refining your search and remember you’re only searching within the ${name} column.  Also note that search is case sensitive.',
        },
        emptyAttributes: {
          heading: 'No results found',
          subheading:
            'Try refining your search to find the attribute you are looking for.',
        },
        newDataSet: {
          heading: "You don't have any ${name} Master Data yet.",
          subheading:
            'To add master data either upload a new hierarchy and choose this Master Data Type, or independently upload Master Data using the upload button above.',
        },
        createdModal: {
          heading: 'New Master Data Type Created',
          description:
            'You can get started by uploading a CSV with Master Data right now or add your data later.',
          uploadNow: 'Upload Master Data',
          uploadLater: "I'll add Master Data later",
        },
        downloadCSV: 'Download CSV',
        downloadEmpty:
          'You do not have any records in this master data type. To download, you must first upload your master data.',
        upload: 'Upload',
        hideRecords: 'Hide records not currently active',
        addNewAttribute: 'Add New Attribute',
        additional: 'Additional',
        required: 'Required',
        createNewMDType: 'Create New Master Data Type',
        nameError: 'Please enter a unique name.',
        fileError: 'Please upload a file.',
        emptyMDState: {
          heading: "You don't have any Master Data types set up yet",
          subheading: `If you'd like to upload only Master Data click the "Create New Master Data Type" button above. If you'd like to upload a hierarchy that includes Master Data you can do so from the Hierarchy page.`,
        },
      },
      hierarchy: {
        blankName: 'The Hierarchy Name cannot be blank',
        optional: '(Optional)',
        header: 'Hierarchy Management',
        hierarchy: 'Hierarchy',
        description:
          'Here is a list of all the different hierarchies that you have set up. Click below to view the hierarchy tree, make changes, see past versions and other operations.',
        drafts: 'Drafts',
        tree: 'Tree View',
        table: 'Table View',
        associations: 'Associations',
        settings: 'Settings',
        versions: 'Version History',
        setUpNewHierarchy: 'Set Up a New Hierarchy',
        noCurrentHierarchies: 'There are no current hierarchies',
        downloadingHierarchy:
          'Downloading CSV "${name}". This should only take a few minutes.',
        association: {
          cxMeasureDialog: {
            question: 'CX Measurement Association',
            description:
              'Below is a list of all active measures that you can choose from. At this time, only certain measures can be associated with a hierarchy. Please be aware that there is not a way to remove an association of a hierarchy once it has been saved. Please select which measures you would like this hierarchy to use for CX Measurement.',
          },
          predictiveDialog: {
            question: 'Model Survey Association',
          },
          capabilities: {
            CXMEASURE: 'CX Measurement',
            PREDICTIVE: 'Predictive',
          },
        },
        unavailable: {
          heading: 'Hierarchy services are currently unavailable.',
          subheading:
            'Sorry for the inconvenience. Please try again shortly. If the issue persists, please open a new case using our online support portal at ${link}',
        },
        congrats: {
          title: 'You are almost there!',
          p1: "Your hierarchy is now available to use. We've saved and published this.",
          p2: "Next, you'll want to associate this hierarchy with a CX Measure or Case Management so it can be used in the portals. If this hierarchy is just an updated version and already linked, there is nothing else you need to do.",
          p3: 'Lastly, make sure you notify your analyst that your hierarchy has changed as it may impact any analysis they are doing on your behalf.',
          associations: 'Go to Associations',
          later: 'Do This Later',
        },
        keySelection: {
          id: {
            heading: 'ID',
            description:
              'This data must be unique and will be used as a global identifier.',
          },
          label: {
            heading: 'Name',
            description:
              'This data will be the display name of your member in the hierarchy tree visualization.',
          },
          level: {
            heading: 'Level',
            description:
              'This data labels each member within a group or level (ie. Store, District, Region, etc.)',
          },
          parentId: {
            heading: 'Parent ID',
            description:
              'This will determine the place within your tree that members are found and will consist of data found in your “ID” column.',
          },
          email: {
            heading: 'Email',
            description:
              'This column is used to associate an email to members of your hierarchy and is incredibly useful when setting up automatic permissions based on users’ email address.',
          },
          user: {
            heading: 'Username',
            description:
              'This column is used to associate a username to members of your hierarchy and is incredibly useful when setting up automatic permissions based on usernames.',
          },
          effectiveFrom: {
            heading: 'Start Date',
            description:
              'This column represents the date each member began. (Ex. date a location opened, employee hire date, or date a contact center agent began answering customer calls.) This column is not mandatory but highly recommended. If you choose to use this column, each row will need an associated date and cannot be left empty.',
          },
          effectiveTo: {
            heading: 'End Date',
            description:
              'This column represents the date each member ended. (Ex. Location closed date, employee termination date, or date a contact center agent stopped answering customer calls) This column is not mandatory but highly recommended. It is okay if this column is left blank as it indicates the members are still active.',
          },
          lastUpdated: {
            heading: 'Version Effective Date',
            description:
              'This column represents the date when the change for a member becomes effective. It is not mandatory but highly recommended. If not selected, our system will default this to current system date. If you choose to use this column, each row will need an associated date and cannot be left empty.',
          },
          errorMessages: {
            id: 'There are duplicate cells in this column. The ID key must be unique.',
            email:
              'Some cells in this column are not properly formatted for email.',
            user: "Some usernames in the column are not in a valid email format (e.g. user@company.com). Update all usernames to an email format using your organizations's approved email domain.",
            effectiveFrom:
              "Some cells in this column are not properly formatted for dates or are empty. We require dates to be in either YYYY-MM-DD or YYYY-MM-DD'T'HH:mm'Z",
            date: "Some cells in this column are not properly formatted for dates. We require dates to be in either YYYY-MM-DD or YYYY-MM-DD'T'HH:mm'Z",
          },
        },
        upload: {
          VALID: 'No issues',
          NEW: 'New attribute',
          ERROR: {
            FORMAT: 'Incorrect format found',
            EMPTY: 'Empty entries found',
            DUPLICATE: 'Duplicate entries found',
            MISSING: 'Column missing',
            HEADER: 'Invalid header format',
            DB: {
              heading: 'Unknown Error',
              subheading:
                'Please try again. If this issue continues,please open a new case using our online support portal at ${link}.',
            },
            RAGGED: {
              heading: 'Ragged Hierarchy',
              subheading:
                'This hierarchy is a non-standard format that we do not currently support. Please make sure all branches are the same depth and reload.',
            },
            ROOT: {
              heading: 'Invalid Root',
              subheading:
                'Your CSV must contain exactly one root node (highest level with no parent)',
            },
            TIME_OUT: {
              heading: 'Timed Out',
              subheading:
                'Please try again. If this issue continues,please open a new case using our online support portal at ${link}.',
            },
            INVALID_PARENT: {
              heading: 'Invalid Parent',
              subheading: 'The following rows have an invalid parent id:',
            },
            INVALID_CSV: {
              heading: 'Invalid CSV',
              subheading:
                'Our system has detected that your file has errors and is not formatted by proper CSV standards.',
            },
            TYPE: {
              heading: 'Format Syntax Error',
              subheading:
                "It looks like the following rows didn't meet the formatting requirements:",
            },
            REQUIRED: {
              heading: 'Missing Required Data',
              subheading:
                'The following rows are invalid because they are missing data for required columns:',
            },
            EFFECTIVE_FROM_CONFLICT: {
              heading: 'Version Effective Date Conflict',
              subheading:
                'The following rows are invalid because they contain Version Effective Date(s) that conflict with another version:',
            },
            FUTURE_EF: {
              heading: 'Invalid Effective From Date',
              subheading: 'The effective from date is in the future.',
            },
            FUTURE_VER: {
              heading: 'Invalid Version Effective Date',
              subheading: 'The version date is in the future.',
            },
            VER_BEF_EF: {
              heading: 'Invalid Version Effective Date',
              subheading: 'The version date is before the effective date.',
            },
            VER_AFT_ET: {
              heading: 'Invalid Version Effective Date',
              subheading: 'The version date is after the effective to date.',
            },
            EF_AFT_ET: {
              heading: 'Invalid Effective From Date',
              subheading:
                'The effective from date is after the effective to date.',
            },
          },
          WARN: {
            MISSING: 'Column missing (not required)',
          },
          help: {
            combo: {
              title: 'Help on uploading your Hierarchy with Master Data CSV',
              columns: {
                title: 'What columns are needed?',
                description:
                  "The only columns that are required for uploading a hierarchy are: ID, Name, Parent ID, Level and Email. They can have different titles but we'll need to define them after the file is uploaded.",
                description2:
                  'If desired, you can include additional columns in your CSV to manage when your hierarchy members take effect (start date, end date, and version effective date).',
              },
              suggested: {
                title: 'Master Data Attributes (optional)',
                description:
                  "If desired, you can include additional columns of attributes in your CSV at this time as well. We'll go through each of the columns individually in the next step and add them to your Master Data.",
              },
            },
            hierarchy: {
              title:
                'Help on uploading a hierarchy CSV (for use with existing master data)',
              columns: {
                title: 'What columns are needed?',
                description:
                  "The only columns that are required for uploading a hierarchy are: ID, Name, Parent ID and Level. They can have different titles but we'll need to define them after the file is uploaded.",
              },
              suggested: {
                title: 'Suggested Columns',
                description:
                  'If desired, you can include additional columns of attributes in your CSV to manage when your hierarchy members take effect (start date, end date, and version effective date).',
              },
            },
            example: {
              title: "Here's an example CSV for reference",
              button: 'View Example',
            },
            template: {
              title: 'Use this CSV template to get you started',
              button: 'Download Template',
            },
            formatting:
              '*Formatting Notes: Header formatting allow only letters, numbers and _ (and must begin with a letter). Dates must be in YYYY-MM-DD format. Your ID column must not contain any duplicate entries.',
          },
          masterData: 'Master Data',
          keySelectionTitle: 'Key Selection',
          masterDataSetup: 'Master Data Setup',
          checkYourTree: 'Check Your Tree',
          publish: 'Publish',
          upload: 'Upload',
          checkTreeHeader:
            "We've built your hierarchy tree structure with the data you gave us!",
          checkTreeBody:
            'Take a look below and make sure everything looks right',
          setUpHierarchy: 'Set Up Hierarchy',
          hierarchyName: 'Hierarchy Name',
          nameYourHierarchy: 'Name your hierarchy...',
          hierarchyType: 'Hierarchy Type',
          newMasterDataName: 'New Master Data Name',
          enterTextHere: 'Enter Text Here...',
          nameMasterData: 'Name your master data type...',
          selectExistingMDType: 'Select Existing Master Data Type...',
          masterDataType: 'Master Data Type',
          uploadCSV: 'Upload a CSV',
          mdTypeError: 'Please select a Master Data Type.',
          masterDataIcon: 'Master Data Icon',
          hierarchyOnly: 'Hierarchy-Only',
          hierarchyWithMD: 'Hierarchy with Master Data',
          pageDescription:
            "We'll walk you through a few steps and help you set up your new hierarchy. But first you'll need to select what type of hierarchy this is, give it a name, and decide whether you would like to upload a CSV or manually build your hierarchy.",
          supportRaggedHierarchies: 'Support Ragged Hierarchy ',
          raggedHierarchyTooltip:
            'Ragged hierarchies (with uneven number of levels within different branches) are now supported by our system but currently can not be associated with CX Measures.',
        },
      },
      users: {
        header: 'Users',
        users: 'Users',
        roles: 'Roles',
        changes: 'Version History',
        details: 'User Info',
        permissions: 'Permissions',
      },
      combinedDataSources: {
        new: 'New Custom Data Source',
        edit: 'Edit Custom Data Source',
        combinedScores: 'Combined Scores',
        sourcesUsed: 'Sources Used',
        weighting: 'Weighting',
        externalData: 'External Data',
        deleteDescription:
          'Any dashboard cards using this data source will not display any data if this data source is deleted.',
        weightType: {
          CUSTOM: 'Custom Weighting - Assign custom weights by measure',
          RC: 'By Response Count - Each respondent is weighted equally',
          EVEN: 'Even Distribution - Each measure is weighted equally',
        },
        form: {
          nameLabel: 'Name',
          namePlaceholder: 'Give your custom data source a name...',
          descritionLabel: 'Description (optional)',
          descritionPlaceholder: 'Describe your custom data source...',
          dataSourcesLabel: 'Choose Sources To Combine',
        },
        error: {
          noDataSources: {
            title: 'An error occurred',
            message: 'Seems like there are no datasources here!',
          },
          invalidCustomWeight:
            'The total custom weight should be between 0 and 100',
        },
      },
    },
    appSettings: {
      sidebarMenu: {
        replay: 'Replay',
        textAnalytics: 'Text Analytics',
        emailTemplates: 'Email Templates',
        responseTemplates: 'Response Templates',
        settings: 'Settings',
        cxDataFeeds: 'CX Data Feeds',
        keywordManager: 'Keyword Manager',
        taxonomyManager: 'Taxonomy Manager',
        customFeeds: 'Custom Data Feeds',
        feedbackFeeds: 'Feedback Data Feeds',
        cxFeeds: 'CX Data Feeds',
      },
      feedback: {
        inpage: {
          description:
            'To enable in-page reporting for Feedback, you must input the top level domains this site key is tied to.  We are requiring this as an extra security step to ensure you remain protected while using this feature. Click here for ${formattingRules}',
          formattingRules: 'formatting rules',
          rules: {
            domainFormat: 'Acceptable domain format:',
            protocol: 'Must not include http/https protocol',
            protocolExample: 'www.domain.com',
            domain: 'Must include subdomain, domain, and top-level domain:',
            domainExample: 'www.domain.com, shop.domain.com',
            noPath: 'Must not include any path after top-level domain:',
            noPathExample: 'www.domain.com/path',
          },
          addUrls:
            'Enter the domains where you want in-page reporting to appear.',
          enterUrl: 'Enter domain here',
          addUrl: 'Add Domain',
          discardAllChanges: 'Discard All Changes',
        },
      },
      textAnalytics: {
        feeds: {
          header: '${product} Data Feeds',
          description:
            'Keep track of all available data feeds that can be processed by the Predictive Experience Text Analytics Engine. Keep track of the amount of data is being processed broken down by name and type. Additional fees may occur. Please contact your Account Manager for details.',
        },
        columns: {
          dateCreated: 'Date Created',
          feedName: 'Feed Name',
          feedId: 'Feed ID',
          lastUpload: 'Last Upload',
          processing: 'Processing',
          notProcessing: 'Not Processing',
          toggle: 'Show',
          lastProcessed: 'Last Processed',
          uploadDate: 'Upload Date',
          fileName: 'File Name',
          comments: 'Comments',
          user: 'User',
          uploadStatus: 'Status',
          editFeed: 'Edit Feed',
        },
        buttons: {
          createNewFeed: 'Create New Feed',
          editFeed: 'Edit Feed',
          uploadData: 'Upload New Data to Custom Feed',
        },
        steps: {
          nameFeed: 'Name Your New Feed',
          chooseFileType: 'Choose File Type',
          processFile: 'Process File',
        },
        createNewFeed: {
          nameLabel: 'Name',
          cancelLabel: 'Cancel',
          nextLabel: 'Next',
          finishLabel: 'Finish',
          nameSubstring: 'Give your custom feed a name',
          descriptionLabel: 'Description',
          uploadCSV: 'Upload a CSV',
          generateFeed: 'Generate Feed ID (API)',
          yourFeedId: 'Your Feed ID',
          copyClipboard: 'Copy To Clipboard',
          selectOption: 'Select This Option',
        },
        upload: {
          buttonLabel: 'Browse Files',
          inputLabel: 'Upload Data Feed File',
          placeholder: 'Choose File...',
          substring: 'File upload requires .csv files in a predefined format',
          templateText:
            'Information that can be uploaded includes: text to be analyzed, date/time of text origin, and optional information such as source, ratings, rating scale, and topic. We suggest ${downloadLink} to help get started.',
          uploadFeed: 'Upload Data To Feed',
          downloadText: 'downloading our sample file template',
          downloadURL:
            'https://s3.amazonaws.com/textanalytics/SampleCustomFeedFile.csv',
        },
        keywordManager: {
          description:
            'Enter keywords to exclude from the Text Analytics dashboard (use commas to separate).',
          addKeywords: 'Add Keywords',
          placeholder: 'Enter Keywords Here...',
          search: 'Search Keywords...',
        },
      },
      masterdata: {
        tabs: {
          records: {},
          attributes: {
            createNewAttribute: 'Create New Attribute',
            editAttribute: 'Edit Attribute',
            singleSelectOptions: 'Single Select Options',
            fieldIsRequired: 'This field is required',
            optionsMustBeUnique: 'Options must be unique',
            deleteQuestion: 'Delete Single Select Options',
            deleteDescription:
              'You’re about to delete an option that is currently being used by ${confirmDeleteSelects} records. Are you sure you want to delete this option? Note: we will not change any existing master data records but you can manually edit them or upload a CSV with updated entries.',
            saveAttribute: 'Save Attribute',
            saving: 'Saving',
            details: 'Details',
            changeLog: 'Change Log',
            attribute: 'Attribute',
          },
        },
      },
    },
    hostedCode: {
      title: 'Web SDK',
      settings: 'Settings',
      searchSiteKeys: 'Search Site Keys...',
      description:
        'Please set what code version package you would like to have applied across your different workspaces. Note: This will impact CX Measurement Trigger, Replay, and Feedback. To ensure the latest version of Predictive Experience Javascript runs without a hitch, please test your survey(s) on your staging environment first.',
      secureCookie: 'Use Secure Cookies (not recommended)',
      secureCookieTooltip:
        'Only possible if the entire site is on https. Please refer to the ${link} for additional details.',
      stagingEnvironment: 'Staging Environment',
      productionEnvironment: 'Production Environment',
      copyStagingToProduction: 'Copy Staging to Production',
      productsInstalled: 'Product(s) Installed',
      codeVersion: 'Code Version',
      stagingCode: 'Staging Code',
      productionCode: 'Production Code',
      clipboard: 'Copy to clipboard',
      saveChanges: 'Save Changes',
      advancedSettings: 'Advanced Settings',
      viewLatestUpdates: 'View latest updates on the ${portal}.',
      developmentPortal: 'ForeSee Developer Portal.',
      lastUpdated: 'Last updated ${date}',
      addOrRemoveTooltip:
        'By selecting this checkbox, you are enabling / disabling the product(s) from updating your environment.',
      advancedSettingTooltip:
        'These are different options for how Predictive Experience tracks site visitors. Please refer to the ${portal} for further details.',
      pleaseEmbed:
        'Please embed this snippet directory into every page in your website template for your ${env} and ${link}.',
      publishToProduction: 'Publish to Production',
      publishDescription:
        'Are you sure you want to push staging settings to your production environment?',
      updateCodeSettings: 'Yes, update code settings',
      notSelected: 'Not selected',
      codeVersionNotSupported:
        'The code version selected in your environment is no longer supported. Please change your code version.',
      failedToFindClient:
        'To have your client and site key assigned for publishing, please open a new case using our online support portal at ${link}',
      saveChangesSuccess: 'Your settings have been updated.',
      advanced: {
        cookie: 'Cookie based tracking (recommended)',
        micro: 'Microcookie tracking',
        cookieless: 'Cookieless tracking (desktop only)',
        dom: 'DOM storage tracking',
      },
      products: {
        trigger: 'CX Measurement',
        record: 'Replay',
        feedback: 'Feedback',
        mpathy: 'M-Pathy',
        opinionlab: 'Digital Experience',
      },
      otherOptions: 'Other Options',
      deferredLoading: 'Deferred Loading',
      modernRecord: 'Use 19.7+ Record',
      disableCpps: 'Disable CPPs',
      disableCppsTooltip:
        'Enter default CPPs to disable as comma separated values',
      disableCppsPlaceholder: 'Ex: url, browser, os, referrer, site, code',
      deferredLoadingTooltip:
        "When selected, the Web SDK won't load until the window onload event",
      trackingMode: 'Tracking Mode',
      trackingModeTooltip:
        'These are different options for how Predictive Experience tracks site visitors. Please refer to the ${link}',
      cookieSettings: 'Cookie Settings',
      cookieExpiration: 'Cookie Expiration (value in days)',
      cookieExpirationPlaceholder: 'Ex: value of 730 = 2 years',
      cookieExpirationTooltip:
        'Set the number of days until the cookie expires. Ex: default value of 730 = 2 years',
      otherSettings: 'Other Settings',
      editCookiePlacement: 'Edit Cookie Placement Options',
      viewCookiePlacement: 'View Cookie Placement Options',
      adobeAnalytics: 'Adobe Analytics Report Suite ID',
      adobeAnalyticsTooltip:
        'Report Suite ID required for Adobe Analytics to Feedback paid integration only',
      adobePlaceholder: 'Enter the Report Suite ID here',
      addAdobeRsid: 'Add Additional Report Suite ID',
      cookiePlacementModal: {
        description:
          'Set the origin of application cookie that is placed to track visitors to your site. Improve performance by setting cookies to subdomain level, if appropriate.',
        customCookieOptions: 'Custom Cookie Options',
        ifUrlMatches: 'If URL matches',
        setCookieOrigin: 'Set cookie origin to',
        cookieUrlPlaceholder: 'qa.yoursite.com',
        addNewRow: '${icon} Add New Row',
        footer:
          'Cookies will be set to the top-level domain by default, i.e. yoursite.com.',
      },
      DUPLICATE_SITE_ALIAS: 'Duplicate site key name',
      INVALID_CHARACTERES: 'Invalid characters used',

      SITE_NOTE: 'Site key alias is updated from oldvalue: ',
    },
    dataConnectors: {
      connectors: 'Connectors',
      foreseeApi: {
        title: 'Predictive Experience Public API',
        description:
          'Access Voice of Customer data through the Predictive Experience API',
        createButtonText: 'Create Credentials',
        editButtonText: 'Save Credentials',
        createModalHeading: 'Create Credentials',
        editModalHeading: 'Edit Credentials',
        resources: {
          cxMeasures: 'CX Measures',
        },
        permissions: {
          readOnly: 'Read Only',
          readWrite: 'Read / Write',
        },
        credentialHeadings: {
          name: 'Credential Name',
          source: 'Data Source',
          permissions: 'Permissions',
          id: 'Client Id',
          secret: 'Client Secret',
        },
        deleteModal: {
          heading: 'Are you sure?',
          body: 'Deleting the API credentials will end access for all applications consuming Predictive Experience data.',
        },
        serverResponses: {
          newCredential: 'Success! New credential created.',
          editCredential: 'Success! Changes have been saved.',
        },
      },
    },
    textAnalytics: {
      dataFeeds: {},
      taxonomyManager: {},
    },
    ERRORS: {
      SITE_KEY_ERROR: 'Site key does not exist.',
    },
    messages: {
      noneAvailable: 'None available',
    },
  },

  login: {
    secureLogin: 'Secure Login',
    feedback: 'Feedback',
    predictiveExperience: 'Predictive Experience',
    username: 'Enter Username',
    password: 'Enter Password',
    newPassword: 'New Password',
    newPasswordPlaceholder: 'Type New Password...',
    confirmNewPassword: 'Confirm New Password',
    confirmNewPasswordPlaceholder: 'Type Password Again...',
    activationCode: 'Activation Code',
    login: 'Log In',
    next: 'Next',
    passwordHeader: 'Logging into Predictive Experience as:',
    multipleAccountsHeader:
      "Which of your company's accounts are you attempting to login to?",
    checkboxText: 'Show Password',
    error:
      'Invalid username and/or password, please try again or use forgot password link to reset password.',
    errorLockedAccount:
      'Your account is locked, please wait at least 30 minutes and try again. If you can’t remember your password, please use forgot password link to reset password after waiting for 30 mins. You can also request Verint administrator in your organization to initiate a password reset request on your behalf.',
    errorDisabledAccount:
      'Your account has been disabled. To get access, please contact your Predictive Experience administrator or open a new case using our online support portal at ${link}',
    errorExpiredPassword:
      'Your password has expired, please use forgot password link to reset password.',
    errorExpiredAccount:
      'Your account has expired, please contact your administrator or create a case at Verint Connect to get access',
    errorNoAccess:
      'You do not have access to Predictive Experience, please contact your administrator or create a case at ${link} to get access',
    errorCommunication:
      'There was an issue trying to log you in, please try again later. If the issue persists, please open a new case using our online support portal at ${link}',
    errorNetwork:
      "We're having an issue trying to log you in, please try again later. If the issue persists, please open a new case using our online support portal at ${link}",
    errorDisabled:
      'This URL is no longer available for Predictive Modeling™  reporting. You should have received an email communication with new login credentials for Verint Experience Management. If you did not receive this information, please contact your customer administrator for assistance.',
    forgotSuccess: 'Success!',
    forgotSuccessDetail:
      'You should receive an email containing a password reset link and activation code shortly.',
    forgotSuccessBottom:
      'If you do not receive this email within a few minutes, contact ',
    resetSuccess: 'Done!',
    resetSuccessDetail: 'Your password has been successfully updated.',
    resetSuccessBottom: 'You will be now redirected to the log in page.',
    resetSuccessLink: 'Or you may click here to go to the log in page.',
    devSupport: 'Developer Support and Documentation',
    forgotPassword: 'Forgot Password?',
    privacy: 'Privacy Policy',
    change90days: 'We suggest changing your password every 60-90 days',
    usernameOptional: 'Username (Optional)',
    email: 'Email Address',
    send: 'Reset Password',
    forgotPasswordHeader: 'Reset Password',
    forgotPasswordInstructions:
      'To reset your password, please provide your email and username.  If you do not receive your instructions by email, contact ',
    passwordRequirementsHeader: 'Password Requirements: ',
    selectLabel: 'Select Company Account...',
    numberOfPasswordRequirementsMet:
      '${numberOfRequirementsMet} of ${numberOfRequirements} Password Requirements Met',
    saveNewPassword: ' Save New Password',
    passwordRequirements: {
      passwordLengthRequirement: 'At least ${numberOfCharacters} characters',
      passwordUppercaseRequirement: 'Upper-Case Letter',
      passwordLowercaseRequirement: 'Lower-Case Letter',
      passwordNumberRequirement: 'Number',
      passwordSpecialCharacterRequirement: 'Special Character',
      passwordConfirmationRequirement: 'Matching Passwords',
      passwordDaysValid: 'This password is only valid for ${daysValid} days.',
      numberOfPreviouslyUsedPasswords:
        'Must be different from your previous ${numberOfPasswords} passwords.',
    },
    problem: 'There was a problem, please try again',
    invalidEmailUsername:
      'Email and username did not match our records. Please check and try again.',
    noEmailProvided: 'Please provide a valid email address',
    invalidUsername: 'Please provide a valid username',
    changedToday: 'Your password has already been changed today',
    tokenNotValidOrExpired:
      'This link has expired or is invalid, we are not able to process your request. To request a new link click here',
    forgotPasswordTitle: 'Forgot Username or Password?',
    justEmailed:
      "We just emailed a link to you to reset your password. Keep an eye on your inbox-the message should arrive soon. If you don't receive our email, please submit your request.",
    organizationInstruction:
      "If you use your organization's login information to access an Enterprise account, you'll need to change your directly with your organization.",
    checkEmail: 'Check your email!',
    badCredentials:
      'Invalid username and/or password, please try again or use forgot password link to reset password.',
    redirectError: "We're redirecting you to your company's login page...",
    ssoLoginError:
      "We're having an issue trying to log you in, please try again and if the issue persists please contact ${link}.",
    defaultError:
      "We're sorry, we are experiencing an unexpected issue at this time. Any questions please contact ${link}.",

    maintenance: {
      assistance: ' for assistance.',
      support: 'Verint Connect',
      single:
        'The Feedback portal will be unavailable for scheduled maintenance on ${day}. During this time you will not be able to access your Feedback projects. Any questions please contact ',
      double:
        'The Feedback portal will be unavailable for scheduled maintenance on ${day} and ${secondDay}. During this time you will not be able to access your Feedback projects. Any questions please contact ',
    },
  },

  feedbackSubnav: {
    downloadPDF: 'Download PDF',
    downloadCSV: 'Download CSV',
    pdf: {
      generating: 'Generating PDF File',
      complete: 'PDF File Download Complete',
    },
  },

  feedback: {
    chooseSurvey: 'Choose Survey Option:',
    chooseSurveyButtonLabel: "Let's Go!",
    newSurvey: 'Create a new feedback survey',
    newSurveyDetails: 'Start from scratch',
    newSurveyAdditionalText:
      'One of the simplest ways to create a survey is to start from the beginning.',
    newSurveyNameLabel: 'Name',
    newSurveyNameSubstring:
      'Give your survey a custom name everyone at your organization will understand.',
    newSurveyNamePlaceholder: 'e.g. Help & Support Survey',
    newSurveyDescriptionLabel: 'Description (optional)',
    newSurveyDescriptionSubstring:
      'Provide a robust description that describes the goal of your survey and target audience.',
    newSurveyDescriptionPlaceholder:
      'Example: "This survey will be used to capture comments on our global Help and Support site. We\'re targeting the survey for the general public."',
    existingSurvey: 'Copy an existing Survey',
    existingSurveyAdditionalText:
      'Get results faster when you start from an existing survey',
    existingSurveyDetails: 'Start with a pre-existing feedback survey',
    startFromScratchHeader: "Let's start from scratch!",
    copyExistingSurveyHeader: 'Copy an existing survey',
    startBuilding: 'Start Building!',
  },

  questions: {
    prologue: 'question prologue',
    starRating: 'star rating',
    multipleChoice: 'multiple choice question',
    epilogue: 'question epilogue',
    openEnd: 'open endded question',
    topicChooser: 'topic chooser',
    default: 'current question',
  },

  dashboards: {
    notFound: {
      heading: "Sorry, we didn't find that dashboard",
      subheading:
        'The dashboard you are looking for may have been hidden, deleted or you may just have an invalid URL.',
      button: 'Ok, got it',
    },
    filters: {
      title: 'Dashboards',
      renameFilter: 'Rename Filter',
      updateFilter: 'Update Filter',
      myFavorites: 'My Favorites',
      others: 'Others',
      conditionNotValidError:
        'Value does not exist or does not match filter criteria for the data source.',
      conditionNotValidErrorMulti:
        'Value does not exist or does not match filter criteria for the data sources: ${dataSourceNames}.',
      conditionNotValidErrorMultiStatic:
        'Filter criteria is not valid for all the data sources selected',
      forbiddenError: 'Sorry, you are not allowed to see this information',
      globalFilter: {
        toggleOff: 'Turn off global filters',
        toggleOn: 'Turn on global filters',
        saveGlobalFilters: 'Save global date filter',
        saveGlobalFiltersDesc:
          'Global date filter is currently turned on. Do you want to save the card based on the global date?',
        saveGlobalFiltersNo: 'No, keep original date',
        saveGlobalFiltersYes: 'Yes, apply global date',
      },
    },
    access: {
      PERSONAL: '',
      PRIVATE: '',
      FORESEE: '',
    },
    delete: {
      question: 'Are you sure you want to delete ${dashboardName}?',
      description: 'This is a permanent action and cannot be undone.',
    },
    cardDelete: {
      question: 'Are you sure you want to delete this card?',
      description: 'This is a permanent action and cannot be undone.',
    },
    snackbar: {
      deleteDashboard: 'Dashboard has been deleted',
      deleteCard: 'Card has been deleted',
      moveCard: 'Card has been moved to new dashboard',
      copyCard: 'Card has been copied',
      copyDashboard: 'Dashboard has been copied',
      emailInvitation: 'Dashboard invitation sent',
      cardCreated: "'${name}' has been created",
    },
    tools: {
      manage: 'Manage Dashboards',
      new: 'Create Dashboard',
      settings: 'Settings',
      copy: 'Copy',
      delete: 'Delete',
      addCard: 'Add Card',
      editCard: 'Edit Card',
      sharing: 'Share',
      downloadPPT: 'Download PPT',
      downloadCSV: 'Download CSV',
      copyCard: 'Copy Card',
      moveCard: 'Move Card',
      moveTop: 'Send to top',
      moveBottom: 'Send to bottom',
      deleteCard: 'Delete Card',
      schedule: 'Schedule for Others',
      email: 'Schedule for Myself',
      saveToDashboard: 'Save to Dashboard',
      clearChanges: 'Clear Changes',
    },
    export: {
      header: 'Export Data',
      format: 'Format',
      bodyFormat:
        'You are about to export ${dashboard} data. Choose a file type to export.',
      body: 'You are about to export ${dashboard} data.',
      defaultFileName: 'User Dashboard',
    },
    segmentDisplay: {
      title: 'Segment Display',
      all: 'All',
      top10: 'Top 10',
      bottom10: 'Bottom 10',
      both5: 'Top 5 & Bottom 5',
    },
    segmentCalculation: {
      title: 'Segment Calculation',
      AVERAGE: 'Average',
      SUM: 'Sum',
    },
    sortOrder: {
      default: 'Default',
      az: 'A to Z',
      za: 'Z to A',
      lowHigh: 'Low to High',
      highLow: 'High to Low',
    },
    scheduling: {
      title: {
        others: 'Schedule for Others',
        self: 'Schedule for Myself',
        delete: 'Delete Schedule',
      },
      testEmail: 'Send Test Email to Me',
      invalidSchedule:
        'The end date occurs before the first scheduled delivery date. Please extend the end date or change the scheduled delivery date and time.',
      schedule: 'Schedule',
      mySchedule: 'My Schedule',
      details: 'Schedule Details',
      delivery: 'Delivery',
      editRecipients: 'Edit Recipients',
      recipients: 'Recipients',
      missingRecipient: 'Missing someone? Share the dashboard first.',
      all: 'All',
      receiving: 'Receiving',
      notReceiving: 'Not receiving',
      reportType: 'Report Type',
      source: 'Source',
      descriptionPlaceholder: 'Add some text that will show up in the email',
      nextRun: 'Your next scheduled report will run on ${day} at ${time}',
      firstRun: 'Your first scheduled report will run on ${day} at ${time}',
      lastModified: 'Last modified by: ${user} on ${time}',
      timePeriod: 'Frequency',

      empty: `The dashboard owner or editor has not yet created a shared schedule. To create your own schedule, use the Schedule for myself option.`,

      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      DAY_OF_WEEK: 'Day of the Week',
      DAY_OF_MONTH: 'Day of the Month',
      WEEK_OF_MONTH: 'Week of the Month',
      WEEK_OF_MONTH_Options: {
        title: 'Every',
        FIRST: { label: '1st', value: 'FIRST' },
        SECOND: { label: '2nd', value: 'SECOND' },
        THIRD: { label: '3rd', value: 'THIRD' },
        FOURTH: { label: '4th', value: 'FOURTH' },
        LAST: { label: 'Last', value: 'LAST' },
      },
      ends: 'Schedule End Date',
      never: 'Never',
      on: 'On',

      history: {
        emptyState: 'This schedule has not yet run',
        emptyStateSub:
          'Once this schedule runs for the first time, details will display here.',
        runStatus: {
          SUCCESS: 'Completed successfully',
          FAILED: 'Failed',
          PARTIAL: 'Completed with errors',
          INPROGRESS: 'Running',
        },
        userRunStatus: {
          ALL: 'All',
          READY: 'Ready',
          COMPLETED: 'Completed',
          IN_PROCESS: 'Running',
          PERMANENTLY_FAILED: 'Failed',
        },
        rerun: {
          button: 'Rerun ${status}',
          question: 'Rerun Jobs',
          description:
            "You're about to rerun ${status} jobs for this scheduled run. Are you sure you want to proceed?",
          confirm: 'Rerun',
        },
      },

      delete: {
        heading: 'Are you sure you want to delete this schedule?',
        subheadings: {
          DASHBOARD:
            'You are about to delete "${scheduleName}". Are you sure you want to do this? All recipients currently getting this dashboard will stop receiving emails.',
          USER: 'You are about to delete "${scheduleName}". Are you sure you want to do this?',
        },
      },
    },
    rawData: {
      priorityMap: {
        name: 'Name',
        scoreLabel: 'Score',
        rankRatio: 'Priority Index',
        latentName: 'Name',
        impactLabel: 'Impact',
        responses: 'Responses',
        totalResponses: 'Total Responses',
      },
      scoreSummary: {
        latentName: 'Name',
        latentType: 'Type',
        scoreLabel: 'Score',
        impactLabel: 'Impact',
        responses: 'Responses',
      },
    },
    thresholds: {
      addThreshold: 'Add Threshold',
      threshold: 'Threshold ${index}',
      thresholdRange: 'Range ${index}',
      thresholdRangeLimit: 'Range ${index} (${min} - ${max})',
    },
    emptyState: {
      default: 'No records available',
      noDataAdded: 'No data added',
    },
    noDashboardsHeader: 'No Dashboards to show',
    noDashboardsSubheader:
      'Use the buttons below to create a new custom dashboard or adjust your visible dashboards via Dashboard Management',
    untitledCard: 'Untitled Card',
    manageEmpty: 'No dashboards found.',
    emptyHeader: 'This dashboard is currently empty.',
    emptySubheader:
      'To add your first chart click on the "Add a Card" button below.',
    addCard: 'Add a Card',
    copyCard: 'Copy Card',
    moveCard: 'Move Card',
    cardName: 'Card Name',
    copy: 'Copy Existing',
    selectCopy: 'Select Target Dashboard',
    chooseDashboard: 'New Dashboard',
    cardSize: 'Size',
    position: 'Position',
    afterCard: 'After ${cardName}',
    copyTo: 'Copy To',
    moveTo: 'Move To',
    cardNamePlaceholder: 'Give your card a name',
    ownerSubtext:
      'Only the dashboard owner is able to make changes to the dashboard.',
    chartTypeHeader: 'Choose a chart type',
    chartTypeInstructions:
      'Take a look at the preview for that card on the right to make sure it is the style you are looking for.',
    copyLimit: 'The target dashboard is full. Select another dashboard.',
    manageHeader: 'Dashboard Management',
    publicDashboardPermission:
      "You don't have permission to create a public dashboard",
    manageInstructions: 'Drag the items to re-order your dashboards.',
    settings: 'Dashboard Settings',
    new: 'New Dashboard',
    namePlaceholder: 'Give your dashboard a name',
    PERSONAL: 'Private (Only seen by me)',
    CLIENT: 'Public (Available to entire company)',
    previewTitle: '${chartType} Example',
    emptyPreview: 'Select a Card Type on the left to see a preview',
    referenceLineLabel: 'Reference Line Label',
    referenceLineValue: 'Reference Line Value',
    comparisonTrend: 'Comparison Trend',
    benchmark: 'Benchmark',
    engagementBenchmarkDisclosure:
      'Benchmark categories currently include only Measurement Survey data.',
    multiMeasureBenchmarkLabel:
      'Adjust the benchmark score and category associated with each measure below:',
    benchmarkScore: 'Benchmark Score',
    benchmarkCategory: 'Benchmark Category',
    benchmarkCategoryUnavailable:
      'Benchmark Category unavailable for the selected score',
    viewBenchmarkMembers: 'View members and description',
    benchmarkCategoryMembers: {
      header: 'Benchmark Members: ${category}',
      total: '${total} Total Members',
      description:
        'Below is a list of every member included in the ${category} Benchmark.',
      errorMessageHeader: 'Benchmark members are not available',
      errorMessageText: `The selected category doesn’t meet the minimum thresholds for displaying benchmark members. Check again in a few days or select a different category.`,
    },
    benchmarkMetric: {
      BCSAVG: 'Average',
      BCSMIN: 'Min',
      BCSMAX: 'Max',
      BCSOFF: 'Off',
    },
    noBenchmarkValue:
      'Benchmark category does not meet minimum requirements for the selected timeframe',
    responseDistribution: '${responses} (${percent}% of ${total})',
    metricDisplay: {
      title: 'Metric Display',
      dots: 'On - Min / Max Dots',
      values: 'On - Min / Max Values',
      allValues: 'On - All Values',
      bestFit: 'On - Best Fit',
      off: 'Off',
    },
    trendMetricDisplay: {
      title: 'Trend Metric Display',
      allDots: 'On - All Dots',
    },
    centerMetricDisplay: 'Center Metric Display',
    scale: {
      yLabel: 'Y-Axis Range',
      xLabel: 'X-Axis Range',
      auto: 'Auto',
      full: 'Full',
    },
    barType: {
      groupedBar: 'Grouped Bars',
      stackedBar: 'Stacked Bars',
    },
    chartType: {
      gauge: 'Gauge',
      donut: 'Donut',
      trendLine: 'Trend Line',
      verticalBar: 'Vertical Bar',
      horizontalBar: 'Horizontal Bar',
      scoreSummary: 'Score Summary',
      priorityMap: 'Priority Map',
      priorityIndex: 'Top Priorities',
      voc: 'Voice of Customer',
      external: 'External Content',
      wordCloud: 'Word Cloud',
      kpi: 'KPI Summary',
      tableCard: 'Cross Tab',
      scatterPlot: 'Scatter Plot',
    },
    chartTypeDescription: {
      gauge:
        'The Gauge chart displays how you are performing in comparison to a previous period or compared to the Predictive Experience Benchmarks and gives you context as to how far away you are from the maximum score.',
      donut:
        'The donut chart is a great way to visualize and compare segments to each other. ',
      trendLine:
        'The Line chart displays continuous data and gives you an understanding of performance over a period of time.',
      verticalBar:
        'A bar chart displays a series of categorical information and can be used to easily and visually compare these categories.',
      horizontalBar:
        'A bar chart displays a series of categorical information and can be used to easily and visually compare these categories.',
      scoreSummary:
        'The Scorecard Summary is a full report card of your customer experience for a specific measure. Using model scores and impacts to visually show you what you need to focus on and why it matters.',
      priorityMap:
        'The Priority Map is a two dimensional compass that visually guides you on what is most important to address within the customer experience.',
      priorityIndex:
        'The Priority Index is a quick and easy high-level view on what is most important to address across your customer experience touchpoints.',
      voc: 'The VOC card gives you a quick sample of your most recent responses with the ability to drill in to see more.',
      external:
        'An external content card allows you to embed interactive charts, help text and other external content through an iframe.',
      wordCloud:
        "The Wordcloud card visualizes frequency of emerging words used in customer comments, color coded with average sentiment for each word. The larger the word, the more commonly it's used.",
      kpi: 'The KPI Summary card provides a quick view of the overall performance of particular metrics in your data set(s).',
      tableCard:
        'The Cross Tab card allows comparing data in a multidimensional table.',
      scatterPlot:
        'A scatter plot chart uses dots to show the relationship between two metrics within a data source. The position of the dot on the horizontal and vertical axis indicates the value of an individual data point.',
    },
    morePriorities: '${count} More Priorities...',
    singularMorePriorities: '1 More Priority...',
    moreResponses: '${count} More Responses...',
    singularMoreResponses: '1 More Response...',
    answers: 'Answers',
    moreAnswers: '+${count} More Open End Answers',
    singularMoreAnswers: '+${count} More Open End Answer',
    focusOn: 'Focus on: ',
    commonTopics: 'Common Topics: ',
    commonTopicsHeader: 'Common Topics',
    topKeywords: 'Top Keywords: ',
    topKeywordsHeader: 'Top Keywords',
    priorityIndexScore: 'Priority Index',
    textAnalyticsInfo: 'Text Analytics Info',
    surveyTypeIcons: 'Survey Type Icons',
    textCommonTopics: 'On - Common Topics',
    textTopKeywords: 'On - Top Keywords',
    legendDisplay: 'Legend Display',
    quadrantDisplay: 'Quadrant Display',
    gradientColors: 'Gradient Colors',
    dataSourceDisplay: 'Data Source Display',
    includeSurveyMetric: 'Include Survey Metric',
    lastPeriod: 'Last Period',
    lastYear: 'Last Year',
    quadrantDisplayOn: 'On',
    quadrantDisplayOff: 'Off',
    range: 'Range',
    onBenchmark: 'On - Benchmark',
    dateRange: 'Date Range',
    dataSource: 'Data Source',
    suppressZeros: 'Suppress zero responses',
    selectDataSource: 'Select Data Source',
    addDataSource: 'Add Data Source',
    addMetric: 'Add Metric',
    multipleSources: '${number} Sources',
    multipleTouchpoints: '${selected} Data Sources', //(${selected} of ${total})
    multipleMetrics: '${selected} Metrics', //(${selected} of ${total})
    multipleQuestions: '${selected} Questions', //(${selected} of ${total})
    metric: 'Metric',
    scatterMetricXAxis: 'Metric (X-Axis)',
    scatterMetricYAxis: 'Metric (Y-Axis)',
    labelXAxis: 'Label X-Axis',
    plotYAxis: 'Plot Y-Axis',
    additionalMetricDisplay: 'Additional Metric Display',
    additionalMetrics: 'Additional Display Metrics',
    unifiedDisplay: 'Unified Display',
    rowTotals: 'Row Totals',
    columnTotals: 'Column Totals',
    selectMetric: 'Select Metric',
    dimension: 'Dimension',
    timeDimension: 'Time Dimension (X-Axis)',
    lineDimension: 'Line Dimension (Group By)',
    selectDimension: 'Select Dimension',
    dataSourceUnavailable: 'Data Source Unavailable',
    metricUnavailable: 'Metric Unavailable',
    dimensionUnavailable: 'Dimension Unavailable',
    questionUnavailable: 'Question Unavailable',
    filterUnavailable: 'Filter Unavailable',
    addDimension: 'Add Dimension',
    switch: 'Switch',
    textAnalyticsSentiment: 'Text Analytics Sentiment',
    replayIndicator: 'Replay Indicator',
    commentCount: '# of comments shown on 100% card',
    topicsDimension: 'Enter the number of Topics to display in this chart.',
    quadrantDisplayLabels: {
      monitor: 'Monitor',
      statusQuo: 'Status Quo',
      topPriority: 'Top Priority',
      highPerforming: 'High Performing',
    },
    dimensionLabels: {
      DATE: 'Time',
      HOURLY: 'Hour',
      DAILY: 'Day',
      WEEKLY: 'Week',
      MONTHLY: 'Month',
      QUARTERLY: 'Quarter',
      YEARLY: 'Year',
      eventProperty: 'Eventing',
      NPSSEG: 'NPS® Segment',
      textAnalyticsData: 'TEXT ANALYTICS DATA',
    },
    npsSegments: {
      NSP: 'Promoters',
      NSN: 'Passives',
      NSD: 'Detractors',
      singular: {
        NSP: 'Promoter',
        NSN: 'Passive',
        NSD: 'Detractor',
      },
    },
    metricLabels: {
      SAT: 'CSAT Score',
      NPS: 'NPS®',
      rating: 'Average Rating',
      fs_inviteShown: 'Invites Shown',
      fs_inviteAccepted: 'Invites Accepted',
      fs_surveyShown: 'Surveys Shown',
      fs_surveyCompleted: 'Surveys Completed',
      fs_inviteAcceptanceRate: 'Invite Acceptance Rate',
      fs_surveyCompletionRate: 'Survey Completion Rate',
      feedback_survey_shown: 'Survey Shown',
      feedback_submitted: 'Surveys Completed',
    },
    scores: 'Scores',
    responseData: 'Response Data',
    responseCount: 'Response Count',
    responsePercent: 'Response Percent',
    event_count: 'Collection Counts',
    event_rates: 'Collection Rates',
    openEndQuestions: 'Open End Questions',
    selectOpenEndQuestion: 'Select Open End Question',
    dataSources: {
      activeMeasures: 'Active CX Measures',
      measures: 'Measures',
      inactiveMeasures: 'Inactive CX Measures',
      events: 'Collection Activity',
      digital: 'Digital CX Measures',
      nondigital: 'Non-Digital CX Measures',
      feedback: 'Feedback',
      activeFeedback: 'Active Feedback Surveys',
      inactiveFeedback: 'Inactive Feedback Surveys',
      customDataSources: 'Custom Data Sources',
      activeCustomFeed: 'ACTIVE CUSTOM_FEED',
      inactiveCustomFeed: 'INACTIVE CUSTOM_FEED',
      adHoc: 'AdHoc',
    },
    advancedSettings: 'Advanced Settings',
    scoreSummary: {
      elements: 'Elements',
      score: 'Score',
      scoreImpact: 'Impact on ${type}',
      futureBehavior: 'Future Behavior',
      futureBehaviorImpact: 'Impact on F.B.',
    },
    priorityIndex: {
      advancedSettings:
        'Adjust the Future Behavior associated with each measure below. This will be used to generate the Priority Index for each measure.',
      errorCode: {
        PM01: "Some of your Measures aren't being displayed. Click into the card and view full screen for details.",
      },
      warningIndicator:
        'Global date filter cannot be applied to Top Priorities cards that use Measurement survey data sources.',
      withNoDataSourceAccess:
        ', or the data sources do not meet the minimum requirements. Click on the card to view details.',
    },
    priorityMap: {
      errorCode: {
        PM01: "Some of your Measures aren't being displayed. Click into the card and view full screen for details.",
        PM02: "Some of your Measures aren't included in this list because they are yet to meet the 300 response requirement.",
      },
      warningIndicator:
        'Global date filter cannot be applied to Priority Map cards that use more than one Measurement survey data source.',
      withNoDataSourceAccess:
        ', or they have not collected minimum required responses yet.',
    },
    verticalBar: {
      calculation: 'Calculation',
      sum: 'Sum (Count)',
      percent: 'Distribution (%)',
      sumAndPercent: 'Sum & Distribution',
    },
    tableCard: {
      rows: 'Rows',
      columns: 'Columns',
    },
    sharing: {
      title: 'Dashboard Sharing',
      headings: {
        currentAccess: 'Current Access:',
        accessDetailsUsers: 'Shared with ${userCount} users',
        accessDetailRoles: ', including ${roleCount} role(s)',
        userColumnHeading: 'Users and roles with access',
        privilegesColumnHeading: 'Privileges',
      },
      search: 'Search current list of users & roles...',
      privileges: {
        show: 'Show',
        all: 'All',
        owner: 'Owner',
        readOnly: 'View Only',
        readWrite: 'Can Edit',
        ROLE: 'Roles',
        USER: 'Users',
      },
      access: {
        removeAppend: ' has been removed',
        public: 'Accessible to everyone in the company',
      },
      labels: {
        users: 'Users',
        roles: 'Roles',
        sendEmail: 'Send email invitation (link to dashboard included)',
        addMessage: 'Add a message (optional)...',
        readSubstring:
          "Users won't be able to save card changes or share with other users",
        writeSubstring:
          'Users will be able to save card changes and share with other users',
      },
      actions: {
        addUser: 'Add Users & Roles',
      },
      shareTo: 'Share To:',
      users: '${userCount} user(s)',
      roles: '${roleCount} role(s)',
      including: ', including ',
    },
    dashboardOwner: {
      title: 'Dashboard Owner',
      subText:
        '(Only the dashboard owner is able to make changes to the dashboard)',
      warningText:
        'If you continue, ${newOwner} will be the only user that can save adjustments to cards or settings for the "${dashboardName}" dashboard.',
    },
    hiddenByDefault: 'Make Dashboard Hidden to other users by Default',
    copyDashboard: 'Copy Dashboard',
    source: 'Source',
    sources: 'Sources',
    lastPeriodScore: "Last Period's Score",
    textAnalyticsMetrics: 'Text Analytics Data',
    textAnalyticsCommentCount: 'Comment Count',
    keywordFrequency: 'Keyword Frequency',
    odi: {
      generatingImpactsTitle:
        'Hold tight, we are generating your custom impacts!',
      generateImpactsDescription:
        "Click on the generate button below and we'll start calculating",
      leavePage: 'Feel free to leave this page.',
      cancel: 'Cancel Calculation',
      continue: 'Continue Calculation',
      updateDataSegment: 'Update Data Segment',
      generateImpacts: 'Generate Impacts',
      slowCalculationTitle: 'This might take a while',
      notEnoughResponsesTitle: 'Not enough responses',
      expandDate: 'Try expanding the date range.',
      expandDateOrRemoveFilters:
        'Try expanding the date range, or removing filters.',
      reduceDate: 'To narrow the results, try reducing the date range.',
      returnToEditing: 'Return to Editing',
      checkingResponses: 'Checking responses',
      fetchingData: 'Fetching data',
      calculating: 'Calculating',
      leavePageDescription:
        "We'll work on this even when you aren't on this page.",
      highResponseCount: 'Dataset contains ${responses} responses.',
      lowResponseCountPartOne:
        'You need at least ${minimumResponses} responses, but ',
      lowResponseCountPartTwo: 'only have ${responses}',
      lowResponseCount:
        'You need at least ${minimumResponses} responses for each survey.',
      partitionTresholdNotMet:
        'The distribution of responses does not meet the minimum threshold.',
      maxResponseCountExceededTitle: 'Too many responses',
      maxResponseCountExceeded:
        'Impacts can only be generated for a maximum of ${maxResponses} responses.',
      errorTitle: 'Oops. Something went wrong :(',
      error: 'Try changing the date range or selecting other data sources.',
    },
    leavePageDialog: {
      question: 'Are you sure you want to leave this page?',
      description: 'Changes you have made will not be saved.',
      confirmText: 'Leave',
      cancelText: 'Cancel',
    },
    shortOpenEnds: 'Open Ends',
    noPermission: `Sorry, you don't have permission.`,
    useDifferentDataSource: 'Try selecting a different data source.',
    externalCard: {
      allowScroll: 'Allow content to scroll',
      height: 'Display height on full size card',
      content: 'Content',
      url: 'URL',
      error:
        "This content is external to Predictive Experience, if you are seeing this message it didn't load correctly",
    },
    error: {
      noDataSourceAccess:
        'Some data sources are not displayed because either you do not have permission to view them',
      dataSourceIsHidden: ' or they are hidden.',
      loading: 'Loading...',
      selectDataSource: 'Please select a data source.',
      selectDataSourceAndMetric: 'Please select a data source and metric.',
      noData: 'No data for this segment',
      noQuestions: 'To view data, please select an open-end question',
      unauthorized: "You don't have permission to access this data",
      tooManyValues: 'The dimension you selected has too many values to chart',
      tooManySegments:
        'Data points cannot exceed 500. Reduce the date range or select another dimension.',
      loadingData: 'There was an error trying to load this data',
      missingCriteria: 'Please select a data source, metric and dimension',
      missingBenchmarkCriteria:
        'To view data, please select benchmark categories',
    },
    kpiCard: {
      delta: 'Delta',
      showPercentageChange: 'Show % change',
      showMetricValueChange: 'Show metric value change',
      off: 'Off',
      sparkline: 'Sparkline',
      gaugeUnsupported: 'Gauge is not supported for this metric.',
    },
    visualization: {
      chart: 'Switch to chart view',
      table: 'Switch to table view',
    },
    referenceLines: {
      addReferenceLine: 'Add Reference Line',
      referenceLineTitle: 'Reference Line ${number}',
      referenceLineAxis: 'Reference Line (${value}-Axis)',
      quadrantReferenceLineLabel:
        'Reference Line (X: ${xAxisMin}-${xAxisMax}), (Y: ${yAxisMin}-${yAxisMax})',
      referenceLineError:
        'Reference line for this data point is already defined. Enter another value.',
      gradientRangeTitle: 'Gradient Range ${number}',
      gradientRange: 'Gradient Range ${number}: from ${from} up to ${to}',
      gradientRangePoint: 'Gradient Range ${number}: ${value}',
    },
  },
  latents: {
    elements: 'Elements',
    elementsDescription: 'The aspects that define the customer experience.',
    satisfaction: 'Satisfaction',
    satisfactionDescription:
      "The customer's overall satisfaction with their experience.",
    futureBehaviors: 'Future behaviors',
    futureBehaviorsDescription: "The customer's future intention.",
  },

  operators: {
    equals: 'Equals',
    doesNotEqual: 'Does not equal',
    lessThan: 'Is less than',
    greaterThan: 'Is greater than',
    lessThanOrEqual: 'Is less than or equal',
    greaterThanOrEqual: 'Is greater than or equal',
    contains: 'Contains',
    doesNotContain: 'Does not contain',
    isBetween: 'Is between',
  },

  chartExport: {
    pdf: 'Generating PDF. Your file will be ready soon.',
    ppt: 'Generating PowerPoint. Your file will be ready soon.',
    downloadClickInfo:
      'Generating Power Point project. Your file will be ready soon.',
    downloadCSVClickInfo: 'Generating CSV file.',
    csvRespondentLimitDesc:
      "We're sorry, you can only export ${limit} respondents at a time.",
    chartNotSupported: {
      title: 'An error occurred',
      message: "We're sorry, this chart cannot be exported at this time.",
    },
  },

  replayAttributes: {
    title: 'Replay Attributes',
    sessionClicks: 'Session Clicks',
    sessionEvents: 'Session Events',
    sessionDuration: 'Session Duration',
    sessionErrors: 'Session Errors',
    sessionStartPage: 'Session Start Page',
    sessionEndPage: 'Session End Page',
    sessionMouseMiles: 'Session Mouse Miles',
    sessionReferrerTitle: 'Session Referrer Title',
    sessionReferrerURL: 'Session Referrer URL',
    sessionPageCount: 'Session Page Count',
    sessionPageURLS: 'Session Page URLs',
    sessionPageTitles: 'Session Page Titles',
    screenWidth: 'Screen Width',
    screenHeight: 'Screen Height',
    pageClicks: 'Page Clicks',
    pageEvents: 'Page Events',
    pageDuration: 'Page Duration',
    pageErrors: 'Page Errors',
    pageMouseMiles: 'Page Mouse Miles',
    pageReferrerURL: 'Page Referrer URL',
    pageReferrerTitle: 'Page Referrer Title',
  },

  pageFilters: {
    saveFilter: 'Save Page Filter',
    addFilter: 'Add a Page Filter',
    enterURLPlaceholder: 'Enter URL here...',
    pageTypes: {
      entry: 'Entry Page',
      entryDescription:
        "Enter a page or URL of the user's Entry Page (first page visited) below.",
      visit: 'Visit Page',
      visitDescription:
        'Enter page names or URLs below for the page(s) visited by the user.',
      exit: 'Exit Page',
      exitDescription:
        "Enter a page or URL of the user's Exit Page (last page visited) below.",
    },
    operators: {
      or: 'or',
      and: 'and',
      urlContains: 'URL Contains',
      urlEquals: 'URL Equals',
      urlStartsWith: 'URL Starts With',
      pageNameContains: 'Page Name Contains',
      pageNameEquals: 'Page Name Equals',
      pageStartsWith: 'Page Starts With',
    },
  },

  /*
   *  Projects strings
   */
  projects: {
    create: '+ Add Feedback',
    createSurvey: '+ Add Survey',

    projectCentre: {
      liveDate: 'Live Date: ',
      updated: 'Updated: ',
      updatedVersionAvailable: 'Updated version available',
    },

    filters: {
      title: 'Surveys',
      placeholder: 'Search surveys...',
      downloadCSV: 'Download CSV',
      downloadPPT: 'Download PPT',
      download: 'Download',
      CSV: 'CSV',
      PDF: 'PDF',
      addFilter: 'Add a Filter',
      addFilterTab: 'Add Filter',
      updateFilterTab: 'Update Filter',
      saveFilter: 'Save Filter',
      filterName: 'Filter Name',
      addHierarchyFilter: 'Add Hierarchy Filter',
      addFeedbackFilter: 'Add a Filter',
      addTextAnalyticsFilter: 'Add a TA Filter',
      selectedLocation: '1 Selected Location',
      selectedLocations: '${locations} Selected Locations',
      expandAll: 'Expand All',
      collapseAll: 'Collapse All',
      selectAll: 'Select All',
      clearAll: 'Clear All',
      untitled: 'Untitled (${chips})',
      untitledFilter: 'Untitled Filter',
      delete: 'Delete ${filterName}?',
      confirmDelete: 'Are you sure you want to delete this filter?',
      overwrite: 'Overwrite ${filterName}?',
      confirmOverwrite: 'Are you sure you want to overwrite this filter?',
      impactsWarningHeader:
        'Are you sure you want to reset your applied filter?',
      impactsWarningDescription:
        'In order to see generated impacts, you must remove your filter. We recommend you save your filter to prevent any losses.',
      questions: {
        customSurvey: 'Custom survey questions',
        openEnds: 'Open ended questions',
        modelQuestions: 'Model questions',
        userDetails: 'User Details',
      },
      filterSelect: {
        newFilter: 'New filter',
        savedFilters: 'Saved filters',
      },
      comparisonFilter: {
        enterScore: 'Enter a score between 0 and 100',
      },
      numberFilter: {
        placeholder: 'Enter value here...',
      },
      openendsFilter: {
        regularExpression: 'Regular expression',
        question: 'Question: ',
        enterText: 'Enter text here...',
      },
      feedbackFilter: {
        general: 'GENERAL',
        rating: 'Rating',
        topic: 'Topic',
      },
      taFilter: {
        nameSingular: 'TA Filter',
        namePlural: 'TA Filters',
      },
      sortOrder: {
        nameAsc: 'Name: A-Z',
        ratingAsc: 'Score/Rating: High to Low',
        responsesAsc: 'Responses: High to Low',
        nameDesc: 'Name: Z-A',
        ratingDesc: 'Score/Rating: Low to High',
        responsesDesc: 'Responses: Low to High',
      },
      dateRangeSort: {
        allTime: 'All Time',
        yesterday: 'Yesterday/ 24H',
        last7days: 'Last 7 Days',
        last30days: 'Last 30 Days',
      },
      noFiltersApplied: 'No filters applied to this view',
      enterName: 'Enter a name for this filter',
    },

    tabs: {
      all: 'All Surveys',
    },

    survey: {
      draft: 'Draft',
      active: 'Active',
      inActive: 'Inactive',
    },

    favorite: {
      // Data Sources Page
      add: 'Add to My Favorites',
      remove: 'Remove from My Favorites',
      // Old Surveys Page
      icon: 'star-o',
      text: 'Add to My Favorites',
      color: 'gray-dark-10',
      where: {
        condition: 'isFavorite',
        icon: 'star',
        color: 'orange-dark-10',
        tooltip: 'Remove from My Favorites',
      },
    },
    tooltips: {
      insights: 'Insights',
      analytics: 'Analytics',
      edit: 'Edit',
      duplicate: 'Duplicate',
      delete: 'Delete',
      exportPDF: 'Export PDF',
      unpublishedEdit: {
        tooltip:
          'This project has modifications that have not been published yet.',
        title: 'UNPUBLISHED EDITS!',
      },
    },

    headings: {
      project: 'Project',
      created: 'Created',
      day: '24 Hours',
      week: '7 Days',
      cumulative: 'Cumulative',
      hierarchies: {
        title: 'Hierarchies',
      },
      responses: 'RESPONSES',
      author: 'AUTHOR',
      siteKey: 'SITE KEY',
      siteName: 'SITE NAME',
      status: 'STATUS',
      avgRating: 'AVG RATING',
      staging: 'STAGING',
      production: 'PRODUCTION',
      feedback: {
        badge: 'FB',
        title: 'Feedback Surveys',
      },
      measures: {
        badge: 'CX',
        title: 'CX Surveys',
      },
      measures_overview: {
        badge: 'CX',
        title: 'CX Measures',
      },
      measure_sat: {
        badge: 'CX',
        title: 'CX Surveys (CSAT)',
      },
      measure_nps: {
        badge: 'CX',
        title: 'CX Surveys (NPS®)',
      },
      measure_nonmodel: {
        badge: 'CX',
        title: 'CX Surveys (Non-model)',
      },
      replay: {
        badge: 'RP',
        title: 'Replay',
      },
      favorites: {
        title: 'My Favorites',
      },
      projectSelection: {
        cx: 'Cx Data Feeds',
        feedback: 'Feedback Data Feeds',
        custom: 'Custom Data Feeds',
      },
    },

    greeting: {
      morning: 'Good morning',
      afternoon: 'Good afternoon',
      evening: 'Good evening',
      defaultUser: 'User',
      suffix: 'here are your projects:',
    },

    empty: {
      heading: 'You have no current surveys',
      subheading: 'Surveys that you create will appear here.',
      callToAction: 'Create a new survey',

      search: {
        heading: 'No surveys match your search',
        subheading:
          'Try adjusting your search to find what you are looking for.',
      },
    },

    copy: {
      onSuccess: "'${projectName}' has been created.",
      onError: "Error trying to copy '${projectName}'. Please try again.",
    },

    delete: {
      heading: 'Delete Project?',
      description0: 'Are you sure you want to delete the project',
      description1: "and all of it's contents?",
      areYouSure:
        "Are you sure you want to delete the project '${projectName}' and all of it's contents?",
      confirm: 'Delete',
      cancel: "No, I'll keep it",
      success: ' has been deleted.', // :name has been deleted
      error: 'An error occurred deleting ', //An error occurred deleting :name
      beforeDelete:
        "Before you delete this project '${projectName}' you must turn it OFF in the publish section.",
      ok: 'OK',
    },

    deleteEnabled: {
      heading: 'Delete Project?',
      description0: 'Before you can delete this project',
      description1:
        'you must turn OFF the project in the publish section and re-publish it.',
      description2:
        'you must turn OFF tracking in the replay settings and re-publish it.',
      cancel: 'ok',
    },

    deleteTopic: {
      heading: 'Delete Topic?',
      description0: 'Are you sure you want to delete the topic',
      description1:
        "and all of it's contents?  Associated alerts may also be affected.",
      confirm: 'Delete',
      cancel: "No, I'll keep it",
    },

    deleteQuestion: {
      heading: 'Delete Question?',
      description:
        'Are you sure you want to delete this question and all of its contents?',
      confirm: 'Delete',
      cancel: "No, I'll keep it",
    },

    feedback: {
      title: 'Feedback',
      builder: {
        contentTypes: 'Components',
        clipBoard: 'Clipboard',
        clipBoardEmpty: 'You have no items in your clipboard',

        tooltips: {
          random: 'Random',
          delete: 'Delete',
          undo: 'Undo',
          redo: 'Redo',
          moveup: 'Move up',
          movedown: 'Move down',
          copy: 'Copy to clip board',
          required: 'Required',
          duplicate: 'Duplicate',
        },
      },
    },

    feedbackStepper: {
      editSurvey: 'Edit Survey',
      collectionMethods: 'Collection Methods',
      publishingSettings: 'Publishing Settings',
    },

    publishing: {
      error409Staging: 'Failed to update staging environment',
    },
    //end projects.feedbackStepper

    createTabs: {
      1: 'Choose a Project',
      2: 'Create a Survey',
    }, //end projects.createTabs

    replayTabs: {
      1: 'Site Settings',
      2: 'Advanced Settings',
      3: 'Publish',
    }, //end projects.replayTabs

    feedbackTabs: {
      1: 'Create/Edit Survey',
      2: 'Choose Survey Type',
      3: 'Configure Survey',
      4: 'Publish',
      5: 'Overview',
    }, //end projects.feedbackTabs

    createFeedback: {
      fromScratch: {
        title: 'Create New Feedback Survey',
        form: {
          title: {
            title: 'title',
            placeholder: 'Give your survey a name',
            description: 'Keep it within 80 characters',
          },
          description: {
            title: 'description',
            placeholder: 'Describe your survey',
            description: 'Keep it within 80 characters.',
          },
        },
        validation: {
          length:
            'Please fill out all fields with at least 1 character and less than 80 characters',
        },
        buttonLabel: "Let's go",
        multipleSiteDescription:
          'Your organization has more than one site. As a result, you must select a specific site to publish your survey. For more details, please contact your administrator.',
      },
      fromTemplate: {
        title: 'Start from a template',
        form: {
          title: {
            title: 'title',
            placeholder: 'Give your survey a name',
            description: 'Keep it within 80 characters.',
          },
          description: {
            title: 'description',
            placeholder: 'Describe your survey',
            description: 'Keep it within 80 characters.',
          },
          industry: {
            title: 'industry',
            options: {
              1: 'Select Industry',
              2: 'Retail & E-commerce',
              3: 'Government',
            },
          },
        },
        buttonLabel: "Let's go",
      },
      fromExisting: {
        title: 'Copy From Existing',
        buttonLabel: "Let's go",
      },
      noSiteKeys: {
        heading: 'You have no active Site Keys',
        subheading:
          'In order to access Feedback and create a new survey, you need to ensure you have Feedback enabled on your Site Key. Please contact your administrator or create a case at ${link}.',
        hostedCode: 'Web SDK Settings',
      },
    }, //end projects.createFeedback

    createReplay: {
      fromScratch: {
        title: 'Create New Replay Project',
        form: {
          title: {
            title: 'title',
            placeholder: 'Give this Replay project a name',
            description: 'Keep it within 80 characters',
          },
          description: {
            title: 'description',
            placeholder: 'Describe your Replay project',
            description: 'Keep it within 80 characters.',
          },
        },
        validation: {
          length:
            'Please fill out all fields with at least 1 character and less than 80 characters',
        },
        buttonLabel: "Let's go",
      },
    }, //end projects.createFeedback

    replay: {
      settings: {
        title: 'Replay Settings',
        tracking: {
          heading: 'Monitor activity on your site:',
          toggleLabel: 'Tracking is ',
        },
        blacklist: {
          heading: 'Customize where we record on your site:',
          explanation: {
            title: 'Black List Urls',
            text1:
              'To block monitoring on specific pages, select "Do not monitor the following page URLs".  \nUse standard URL paths to identify where we should not monitor.',
            text2: 'An asterisk (*) can be used as a wildcard',
          },
          checkbox: 'Do not monitor the following page URLs',
        },
      },
      advancedSettings: {
        title: 'Custom PII Masking',
        heading: 'Advanced Settings (Advanced Users Only)',
        note: 'Note: Your code must be validated before you can save it.',
        button: 'Validate',
        success: 'Great job! Your code is valid.',
      },
      publish: {
        snippet:
          'With our Staging Code snippet you can publish to any sub-environment (e.g. QA, Staging, Pre-Prod) to validate your survey before publishing Live.',
        terms: {
          title: 'Terms and Conditions',
          text: 'You must read and agree to our terms and conditions before publishing replay to your site.',
          modal: {
            heading: 'Cannot Publish',
            description:
              'You cannot publish your Replay Project until the Terms & Conditions are accepted',
          },
          save: 'Save a Copy',
          agree: 'Yes, I Agree',
          disagree: "No, I Don't Agree",
        },
      },
    },

    edit: {
      defaultDropdownLabel: 'Dropdown Question Initial Text',
      ourPrivacyPolicy: 'Our Privacy Policy',
      imageSize: '(233px x 233px)',
      backgroundSize: '(1000px x 233px)',
      uploadYourLogo: 'Upload Your Logo',
      uploadABackground: 'Upload A Background',
      question: {
        rateExperience: '<p>Rate your experience</p>',
        emptyQuestion: '<p>Enter your question...</p>',
        emptyRating: '<p>Click here to type your rating question</p>',
        emptyDropDown: '<p>Click here to type your dropdown question</p>',
        emptyRadio: '<p>Click here to type your radio button question</p>',
        emptyCheckbox: '<p>Click here to type your checkbox question</p>',
        emptyComment: '<p>Click here to type your comment question</p>',
        emptyShortText: '<p>Click here to type your short text question</p>',
        defaultRatingQuestion:
          '<p>Please rate your experience on our website.</p>',
        defaultTopicQuestion: '<p>What is your feedback regarding?</p>',
        emptyAnswer: 'Enter a response...',
        emptyMultiAnswer: 'Click here to type Response ',
        emptyPrologue:
          '<h1>Click here to enter survey Title</h1><p>Click here to type Welcome Message</p>',
        emptyEpilogue:
          '<h1>We Appreciate Your Feedback</h1><p>Your feedback will help us improve</p>',
        chooseAnswer: 'Choose a response...',
        chooseTopic: 'Choose a topic',
        textAreaPlaceholder: 'Customer response will be in here...',
        emptyTitle: 'We Appreciate Your Feedback',
        addResponse: 'Add response',
        thankYou: 'Thank You Message',
        save: 'Save',
        cancel: 'Cancel',
        introduction: 'Introduction',
        question: 'Question',
        answers: 'Answers',
        editor: 'Editor',
        logic: 'Logic',
        chooseQuestion: 'Choose a question',
        showQuestionIf: 'Show this question if',
        add: 'Add',
      },
      error: {
        length: 'Maximum character limit reached',
      },
      topic: {
        title: 'Click here to type new topic',
      },
      preview: 'Preview',
      unsavedChanges: 'Please save or cancel your changes to the <%= name %>.',
      addNewTopic: 'ADD A NEW TOPIC',
      publishedToProduction: 'Publish to Production',
      publishSurvey: 'Publish Survey',
      published: 'Published',
      nav: {
        thisSurveyIs: 'This survey is ',
        active: 'Active',
        inactive: 'Inactive',
        drafts: 'Draft',
        duplicateSurvey: 'Duplicate Survey',
        deleteSurvey: 'Delete Survey',
        copySurveyLink: 'Copy Survey Link',
        previewSurvey: 'Preview Survey',
        analyzeSurvey: 'Overview',
        more: 'More',
        areYouSure:
          'Are you sure you want to push staging changes to your production environment?',
        yesUpdateProd: 'Yes, update production environment',
        cancel: 'Cancel',
      },
      topicSelectNote:
        'NOTE: This question is shown on surveys when there are 2 Topics or more',
      topicSelector:
        'TOPIC SELECTOR (This question is shown on surveys when there are 2 Topics or more)',
      topicSelectDropdown: 'Topic Dropdown Text',
      topicSelectDropdownPlaceholder: 'Enter your default dropdown text here.',
      customFieldsHeader: 'LANGUAGE CUSTOMIZATIONS',
      privacyPolicy: 'Privacy Policy',
      privacyPolicyTooltip:
        "This should be the exact translation of 'Privacy Policy'.",
      errorMessage: 'Error Message',
      showPreview: 'Show Preview',
      editButtonText: 'Edit Button Text',
      submitButton: 'SUBMIT BUTTON',
    }, //end projects.edit

    settings: {
      sitePopup: {
        popupTitle: 'Site Pop-Up',
        desktop: 'Desktop',
        tablet: 'Tablet',
        mobile: 'Mobile',
        designOptions: {
          title: 'Design Options',
          description:
            'Customize the look, feel and location of your badge in this panel. Each device has its own settings.',
          show: 'Show',
          hide: 'Hide',
          showHideInfo: 'this badge on ${device} devices.',
          badgeIconTitle: 'Badge Icon',
          buttonTextTitle: 'Badge Text (Optional)',
          textTitle: 'Text',
          badgeTitle: 'Badge',
          rotationTitle: 'Rotation',
          sizeTitle: 'Size',
          positionTitle: 'Position',
          badgeAnimationLabel: 'Badge Animation',
          badgeAnimationCheckboxLabel: 'Animate Badge',
          scrollBehavior: 'SCROLL BEHAVIOR',
          oldVersionWarningText:
            'We notice you’re using an older version of our client code, which may impact your ability to use some of our newest features. To leverage all of these new features, please contact your administrator or create a case at ${link} to upgrade to the latest client code version.',
        },
        urlTargeting: {
          formattingRules: {
            site: 'If site displays either http or https, display an asterisk ( * ) before domain. Example: *abccompany.com*',
            staging:
              'If staging and production environs have different domains, include both URLS. Example: *stg.abccompany.com*, *abccompany.com*',
            foreseeCode:
              'If Predictive Experience code is deployed across various pages, be sure to specify the domain. Example: *abccompany.com/cart* instead of *cart*',
            slashes: 'Remove all slashes (/) from the end of the URL.',
            blacklist:
              'Here is an example of how to exclude any checkout pages. Example: *abccompany.com/*/checkout.html',
            whitelist:
              'Here is an example of how to include any checkout pages. Example: *abccompany.com/*/checkout.html',
            note: 'NOTE: An asterisk(*) can be used as a wildcard.',
            placeholder:
              'your.domain.com/this/page\n*.domain.com/*/these\n*/this/*',
          },
          formattingRulesV2: {
            site: 'abccompany.com/**/cart will match any url on abccompany.com ending with /cart',
            staging:
              'If staging and production environs have different domains, include both URLS. Example: *stg.abccompany.com*, *abccompany.com*',
            foreseeCode:
              'If Predictive Experience code is deployed across various pages, be sure to specify the domain. Example: *abccompany.com/cart* instead of *cart*',
            slashes:
              'abccompany.com// will match abccompany.com and abccompany.com/ but it will not match abccompany.com/anything',
            blacklist:
              'Here is an example of how to exclude any checkout pages. Example: *abccompany.com/**/checkout.html',
            whitelist:
              'Here is an example of how to include any checkout pages. Example: *abccompany.com/**/checkout.html',
            note: 'NOTE: The protocol (http://, https://) is ignored. Several patterns are separated by commas.',
            patternSyntax:
              'Pattern syntax:\n** matches any character\n* matches everything but slash\n// matches zero or more slashes\n/ matches one or more slashes\n? delimits the URL path from the query (ex: www.mywebsite.com?q=search)',
            placeholder:
              'abccompany.com/**/cart will match any url on abccompany.com ending with /cart\nabccompany.com// will match abccompany.com and abccompany.com/ but it will not match abccompany.com/anything\n**?*foo*bar will match any page where foo and bar appear in the query string in that order.',
          },
        },
        replay: {
          recordUserSession: 'Record user sessions',
          recordUserSessionDescription:
            'Digital Behaviour Analytics enhances your customer experience analytics for web and mobile so you can more accurately pinpoint where your visitors are struggling and efficiently resolve issues that are negatively impacting the customer experience.',
        },
        footer: {
          saveButtonText: 'Save Settings',
        },
      },
      weblink: {
        emptyValueError: 'Must not be an empty string.',
        popupTitle: 'Web Link',
        leftMenuTitle: 'Survey URL Setup',
        urlSettingsTitle: 'URL Settings',
        urlSettingsDescription:
          'A Web Link collector is the most versatile method of gathering responses for your survey where Predictive Experience generates a survey URL that you can post on a website, email as a hyperlink, print on a newsletter, share on social media, and more.',
        linkActivationTitle: 'This Link Deactivates:',
        linkActivationLocalTimeText: '12:00 AM survey-taker local time.',
        visitorInfo:
          'Deactivation Message (VISITORS WILL SEE THE FOLLOWING MESSAGE AFTER DEACTIVATION DATE)',
        deactivationMessagePlaceholder:
          'If blank, your last saved value will be saved.',
        deactivationHeaderMessage: 'Deactivation Header Message',
        deactivationBodyMessage: 'Deactivation Body Message',
      },
      heading: 'How would you like to deliver this survey?',
      label: {
        title: 'Choose text',
      },
      active: {
        title: 'Active',
      },
      publish: {
        title: 'Set Publishing Options',
        heading: 'Customize where your survey is shown:',
        checkbox: 'Show Site-wide',
        explanation: {
          heading: 'White List',
          text: 'Publish a survey, by topic to your entire site by checking "Show Site-wide" (Default).',
          text2:
            'To publish a survey or topic on specific pages deselect "Show Site-wide". Use standard URL paths to identify where the survey should appear.',
          text3: 'An asterisk ( * ) can be used as a wildcard.',
        },
      },
      delay: {
        title: 'Rendering Rules',
        delay0: 'Show immediatley',
        delay5000: 'Show after 5 seconds',
        delay10000: 'Show after 10 seconds',
      },
      display: {
        title: 'Survey Delivery',
        popup: 'New Window/Tab',
        overlay: 'Window Overlay',
        modal: 'Window Overlay',
        fullpage: 'Full Page',
      },
      fixed: {
        title: 'Scroll Behaviour',
        fixed: 'Fixed to a static location',
        floating: 'Floating along with scroll',
      },
      template: {
        title: 'Select Template',
        general: 'General Template',
      },
      direction: {
        title: 'Badge Direction',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
      },
      color: {
        title: 'Badge Color',
        suggested: 'Or select a suggested color',
        value: 'Input a custom hexcode color',
        placeholder: 'i.e. #ef3024',
        save: 'Save Change',
      },
      animate: {
        title: 'Badge Animation',
        false: 'Always show full badge',
        true: 'Animate badge on hover',
      },
      position: {
        title: 'Icon Positioning',
        topLeft: 'Top Left',
        top: 'Top',
        topRight: 'Top Right',
        right: 'Right',
        bottomRight: 'Bottom Right',
        bottom: 'Bottom',
        bottomLeft: 'Bottom Left',
        left: 'Left',
      },
      badge: {
        title: 'Update settings',
        heading: 'A badge on my web site',
      },
      url: {
        heading: 'Web Link',
        title: 'Survey web link',
        explanation: {
          text1:
            'Copy this link and use it to share via email, social media, etc...',
          duration: 'This survey ends:',
        },
        clipboard: 'Copy to clipboard',
        duration: 'Duration',
        never: 'Never',
        on: 'On',
        expiration: {
          title: 'Expiration Message',
          placeholder:
            'Thank you for your interest in this survey, however, this survey is no longer available.',
        },
      },
      manual: {
        heading: 'Manually Launch Survey with JavaScript',
        title: 'Launch this survey',
        explanation: {
          text1:
            'To manually launch this survey, use the javascript function call below.',
          text2: 'This function will only work for this project.',
        },
        example: 'Example: ',
        launch: 'FSR.launchFeedback',
      },
      whitelist: {
        title: 'Where do you want to show this survey?',
      },
      privacypolicy: {
        title: 'Privacy Policy',
        explanation:
          "Link to your own privacy policy. If these fields are left blank, they will default to Verint's standard privacy policy.",
        text: {
          title: 'Choose Privacy Policy Text',
          placeholder: 'Privacy Policy',
        },
        url: {
          title: 'Privacy Policy URL',
          placeholder: 'http://www.website.com/privacy_policy',
          defaultUrl:
            'https://www.verint.com/our-company/legal-documents/privacy-policy/',
        },
      },
      button: 'Next',
    }, //end projects.settings
    collection: {
      collectionMethods: 'Collection Methods',
      foreseeOffers:
        'Predictive Experience offers many ways to surface your survey. Each option type works differently so they all have different set of options to choose from when you set them up.',
      webLink: 'Web Link',
      hostedOnForesee: 'Hosted on Foresee.com. ',
      perfectForSharing: 'Perfect for sharing via email, social media, etc.',
      modified: 'MODIFIED',
      sitePopup: 'Site Badge',
      hostedOnSite: 'Hosted on your website. ',
      embedYourSurvey: 'Embed your survey on your website',
      delete: 'Delete',
      edit: 'Edit',
    }, //end projects.collection
    publish: {
      title: 'Publish Your Project',
      firstTime: 'First time installing Predictive Experience?',
      firstTimeReplay: 'First time installing replay?',
      getCode: 'Get Installation Code',
      staging: 'Staging',
      production: 'Production',
      embedModal: {
        done: "I'm done",
        copyClipboard: 'Copy to clipboard',
        yourCode: 'Your Embed Code',
        stagingHeader: 'Staging Code',
        productionHeader: 'Production Code',
        staging: 'staging',
        production: 'production',
        environment: 'environment.',
        description:
          'Please embed this snippet directly into every page in your website template for your ',
        optimalEmbed:
          'For optimal performance, embed directly into template, not as linked file.',
      },
      status: 'status',
      heading: 'Validate Before You Go Live',
      paragraph:
        'With our Staging Code snippet you can publish to any sub-environment (e.g. QA, Staging, Pre-Prod) to validate your survey before publishing Live.',
      ready: 'Are you ready to publish this survey to your site?',
      publishSuccessNotice:
        'Your changes could take several minutes to propagate across all your sites',
      iGotIt: 'I GOT IT',
      publishCards: {
        copiedSuccess: 'Copied to clipboard.',
        copiedFail: 'Error: Copy to clipboard failed',
        envServers: '${env} Servers',
        autoSavedonStaging:
          'All survey changes are automatically saved on your ${env} environment',
        lastUpdatedBy: 'Last updated ${time} by ${userName}',
        copyClipboard: 'Copy to Clipboard',
        active: 'Active',
        inactive: 'Inactive',
        webLinkSurveyUrl: 'Web link ${env} survey url:',
        codeVersion: 'Code Version: ${code}',
        feedbackUninstalled:
          'Feedback is currently not installed in your environment.  Please contact your administrator to enable feedback.',
        noRight:
          'You do not have the permission to publish your survey changes.  Please contact your administrator to publish these changes.',
        unsupported:
          'The code version selected in your environment is no longer supported.  Please contact your administrator to update to the latest version.',
        outOfSync:
          'Your staging and production environments are on different code versions. Please contact your administrator to synchronize the code versions.',
        stepLabel: 'Step',
        republishButton: 'Republish',
        published: 'Last Published',
        notPublished: 'Not Published',
        publishCompleted: 'Published',
        publishFailed: 'Publish Failed',
        publishInProgress: 'Publishing...',
        publishAgain: 'Republish',
        publishToStagingNotice:
          'Publishing to staging may take several minutes',
        publishToProdNotice:
          'Publishing to production may take several minutes',
        thisSurveyIs: 'This survey is ',
        onYourServer: ' on your ${env} server.',
        staging: {
          title: 'Publish to Staging',
          button: 'Publish',
          description:
            'Check your code in a production-like setting before having it affect your actual users.',
        },
        production: {
          title: 'Publish to Production',
          button: 'Publish',
          description:
            'Once your staging project is up and running properly, you can publish these settings to your production survey.',
          confirmText: 'Yes, publish to production',
        },
        alerts: {
          title: 'Set up Alerts',
          button: 'Set up Alerts',
          description:
            'Alert you or your team via email when feedback is received that meets your alerting criteria.',
        },
      },
      surveyStatus: {
        surveyStatus: 'Survey Status',
        description: 'Description',
        createdBy: 'Created by: ',
        siteKey: 'Site Key: ',
        createDate: 'Create Date: ',
        activeDate: 'Active Date: ',
        surveyChangeLog: 'Survey Change Log',
        eachChangeLogged:
          'Each change to the survey on your production environment is logged here.',
        searchChanges: 'Search Changes...',
      },
      customerDefined: {
        title: 'Customer Defined Link (Optional)',
        embedHeading: 'Embed the code',
        embedInstructions:
          'Please embed these snippets directly into every page in your website template for the appropriate environment.  For optimal performance, this must be embedded directly into the template, not referenced as an external file.',
        launchHeading: 'Launch this survey',
        launchInstructions:
          'To launch this feedback survey manually, use the javascript function call below. This function will only work for this project.',
      },
      warningTurnOff: 'turn off',
      warningTurnOn: 'turn on',
      warningOff: 'off',
      warningOn: 'on',

      warning1: 'You have chosen to ',
      warning3:
        ' this project.  Please complete the following steps to complete this process: ',
      warning4: '1. Click on the "Publish to Staging" button to move the ',
      warning6: ' status into your staging environment.',
      warning7: '2. Click on the "Publish to Production" button to move the ',
      warning9: ' status into your production environment.',

      publishWarning:
        'Your organization is not authorized to publish, please contact us.',
    }, //end projects.publish
  }, //end projects

  textAnalytics: {
    allThemes: 'All Themes',
    noSourcesFound: 'No Sources Found',
    sources: '${num} Sources',
    title: 'Text Analytics',
    sentiment: 'Sentiment',
    averageSentiment: 'Average Sentiment',
    positive: 'Positive',
    positiveAbbr: 'Pos',
    negative: 'Negative',
    negativeAbbr: 'Neg',
    neutral: 'Neutral',
    neutralAbbr: 'Neu',
    commentCount: 'Comment Count',
    keywordFrequency: 'Keyword Frequency',
    topic: 'Topic',
    topics: 'Topics',
    singleTopicSelected: '1 Topic Selected',
    pluralTopicsSelected: '${topics} Topics Selected',
    tabs: {
      comments: 'Comments',
      keywords: 'Keywords',
      overview: 'Overview',
      settings: 'Settings',
      themesTopics: 'Themes and Topics',
      topics: 'Topics',
    },
    topicsSelected: '${topics} selected',
    searchPlaceholder: 'Search keywords',
    headings: {
      wordcloud: 'Keyword Wordcloud',
      keywords: 'Emerging Keywords',
      comments: 'Comments',
      visualizer: 'Keyword Treemap',
      measure: 'for ${measure}',
      topicsOverview: 'Top 5 Positive/Negative Topics',
      topicsTrend: 'Topic Trend',
      topicsComments: 'Themes, Topics, and Comments',
      wordcloudTooltip:
        'This represents emerging keywords for the criteria selected',
    },
    wordcloud: {
      tooltip:
        'Word Clouds are useful for relationship building. The larger the word in the visual, the more common the word was in your document(s) or survey(s).',
    },
    treemap: {
      tooltip:
        'Treemaps are useful for relationship building. The larger the area in the visual, the more common the word was in your document(s) or survey(s).',
    },
    topKeywords: {
      tooltip:
        'Keywords are grouped into clusters. Use these clusters to identify top keywords (by frequency) that your customers are talking about.',
    },
    topicsOverview: {
      tooltip: 'Overview of topics by volume.',
    },
    topicsTrend: {
      tooltip: 'Trends of topic.',
    },
    topicsComments: {
      tooltip:
        'Drill down shows the hierarchy of High Level Themes, Sub Themes and Topics based on the taxonomy configured. Comments tab shows responses at each level of drill down.',
      allTopics: 'All Themes and Topics',
      columns: {
        topic: 'Topic',
        theme: 'Theme',
        sentimentDistribution: 'Topic Sentiment Distribution',
        comments: 'Comments',
        sentiment: 'Sentiment',
        score: 'Satisfaction',
        rating: 'Rating',
        text: 'Comment',
        date: 'Date',
        source: 'Source',
        nps: 'NPS',
      },
    },
    noData: 'Data is not available for the selected criteria.',
    notConfigured: {
      heading: 'Text Analytics is not configured',
      contact: 'Please contact ',
      support: 'Support',
      help: ' for help.',
    },
    noProcessedText: {
      heading: 'Text Analysis Unavailable',
      subheading:
        'The current measure does not have text analysis data available for review. Please set up your Feeds and Questions under the settings tab to begin the Text Analysis process. If you have already set up Feeds for Text Analysis, please come back soon to check on the analyzed text.',
    },
    noQuestionsEnabled: 'No questions enabled for Text Analytics',
    invalidDateRange: {
      heading: 'Max Date Range Exceeded',
      subheading:
        'Reporting date range cannot be more than 366 days, please select a smaller range.',
    },
    comments: {
      tooltip:
        'Sentiment coloring represents overall comment score ranging from -2 to +2. Red (Negative) represents -2 to -0.21, Gray (Neutral) -0.2 to +0.2, Positive +0.21 to +2',
      columns: {
        score: 'Score: Sat',
        sentiment: 'Sentiment',
        date: 'Date',
      },
      comments: 'Comments',
      pagination: ' comments per page',
      seeRespondent: 'View Response',
      respondentDetails: 'View additional details in Respondents Page',
      respondentID: 'Respondent ID:',
      commentDetails: 'Comment Details',
      latents: 'LATENTS',
      customQuestions: 'CUSTOM QUESTIONS',
      modelQuestions: 'MODEL QUESTIONS',
      viewReplay: 'View Replays',
    },
    settings: {
      title: 'Text Analytics Settings',
      feedManager: {
        heading: 'Data Feed Manager',
        description:
          'Please select surveys and questions you want to be processed through Text Analytics.',
        tabs: {
          active: 'Active Feeds',
          inactive: 'Inactive Feeds',
        },
        columns: {
          feed: 'Feed Name',
          type: 'Type',
          comments: 'Comments Processed',
          questions: 'Questions',
          processed: '',
        },
        modalLink: 'Edit',
        noQuestions: 'There are no questions for this measure.',
      },
      keywordManager: {
        tabs: {
          group: 'Group Keywords',
          exclude: 'Exclude Keywords',
        },
      },
      processingModal: {
        heading: 'Text Anayltics Processing',
        description:
          'You have selected responses to ${QUESTION} to process through Text Analytics Engine.  Please choose from options below:',
        processAll:
          'Process all historical comments before ${DATE} (${COMMENTCOUNT} additional comments)',
        processNone: 'Turn off',
        disclaimer:
          'Please be aware of billing impact changes in Text Analytics processing, increasing volume of text analyzed might increase processing cost.',
      },
    },
    vta: 'Verint Text Analytics',
    errorCsvDownload:
      'There was an error trying to download this data. Please try again later.',
  }, // end text analytics
  measures: {
    channelIds: {
      CORP_METRICS: 'Corp Metrics',
      MULTICHANNEL: 'Multichannel',
      POST_EXPERIENCE: 'Post Experience',
      WEB: 'Web',
      STORE: 'Store',
      CALL_CENTER: 'Call Center',
      MOBILE: 'Mobile',
      EMAIL: 'Email',
      SOCIAL_MEDIA: 'Social Media',
      EMPLOYEE_SAT: 'Employee Satisfaction',
    },
    surveyType: 'Survey Type',
    surveyTypes: {
      CSAT: 'CSAT',
      CSAT_NPS: 'CSAT with NPS®',
      PREDCSAT: 'Predictive CSAT',
      PREDCSAT_NPS: 'Predictive CSAT with NPS®',
      PREDNPS: 'Predictive NPS®',
      NPS: 'NPS®',
      NONMODEL: 'Non-model',
      FEEDBACK: 'Feedback',
      UNKNOWN: 'Unknown',
      PREDCSAT_NODR: 'Predictive CSAT with only Future Behavior',
      PREDCSAT_NODR_NPS: 'Predictive CSAT with only Future Behavior and NPS®',
      MICROCSAT: 'Micro CSAT',
      MICRONPS: 'Micro NPS®',
    },
    headings: {
      type: 'Type',
      title: 'Customer Experience Measurement',
      name: 'Name',
      channel: 'CHANNEL',
      status: 'STATUS',
      satScore: 'SAT SCORE',
      responses: 'RESPONSES',
      cx: 'CX',
      cxMeasurement: 'Customer Experience Surveys',
    },
    quadrants: {
      monitor: 'Monitor',
      topPriority: 'Top Priority',
      statusquo: 'Status Quo',
      maintain: 'Maintain or Improve',
    },
    incompatibleWithBuilder:
      'This survey is not compatible with survey builder',
    impact: 'Impact',
    score: 'Score',
    respondents: 'Responses',
    totalRespondents: 'Total Responses',
    modelInsights: 'Model Insights',
    noTopicData: 'No Topic Data',
    noKeywordData: 'No Keyword Data',
    // overview: "Overview",
    // questions: "Questions",
    // seeResponses: "See Responses",

    interactionCount: '${number} Interactions',
    respondentCount: '${number} Responses',
    totalRespondentCount: '${number} Total Responses',
    emptySubheading: 'No data sources to display.',
    errorSubheading:
      'Error loading data. Try again later. If the issue persists, contact Support.',
    zoomDirections: 'Click and drag to zoom',
    priorityMap: {
      hasFilter:
        'To view the priority map please remove your filter or click the generate impacts link.',
    },
    survey: {
      customQuestions: 'CUSTOM QUESTIONS',
      errors: {
        deploy: 'An error occurred while trying to deploy the survey',
        deploymentStatusUndetermined: 'Could not determine deployment status',
        publish: 'An error occurred while trying to publish the survey',
        scaleRange:
          'Minimum must be less than maximum. Range must be between 0 and 10.',
      },
      messages: 'Messages',
      metadata: 'Metadata',
      modelQuestions: 'MODEL QUESTIONS',
      publish: 'Publish',
      publishNotes: 'Publish Notes',
      publishSuccessful: 'Survey has been published and deployed successfully.',
      questions: {
        addBlankAnswer: 'Add answer',
        addDontKnow: `Add "Don't Know" selection`,
        addNextQuestion: 'Add Next Question',
        addOtherPleaseSpecify: 'Add "Other please specify"',
        advanced: 'Advanced options',
        answerMaxLengthError: 'Answer text cannot be more than 512 characters',
        answerPinning: 'Answer pinning',
        answersInTwoColumns: 'Answers in two columns',
        answerWithEmail: 'Answer with Email Address',
        backslashNotAllowed: '\\ are not allowed in answer text',
        benchmark: 'Benchmark',
        cancel: 'Cancel',
        confirmDeleteDescription:
          'Are you sure you want to delete this question?',
        confirmDeleteQuestion: 'Delete question?',
        confirmSaveDescription: 'Are you sure you want to save these changes?',
        confirmSaveQuestion: 'Save changes to question?',
        duplicatePinnedAnswer: 'Duplicate value for pinned answer position',
        editQuestion: 'Edit question',
        emptyQuestionTextError: 'Question text must not be empty',
        eppQuestion: 'EPP Question',
        exclusiveAnswer: 'Exclusive Answer',
        invalidQuestionPositionDescription:
          'Questions with display logic may not be placed before the question they depend on',
        invalidQuestionPosition: 'Invalid Question Position',
        leftLabel: 'Left label...',
        logic: 'Logic',
        longQuestionTextError:
          'Question text character limit is ${max} characters',
        makeAnswerExclusive: 'Make one or more answers exclusive',
        maximumSelections: 'Maximum selections',
        multipleChoiceType: 'Multi-Select',
        multiTypeShowLogicPrompt: 'Choose at least one answer',
        newBlankQuestion: 'New blank question',
        openEndedType: 'Text',
        pinnedAnswerIndexMustBeWholeNumber:
          'Pinned answer position must be a whole number',
        questionLabel: 'Question Label',
        questionLabelSub:
          'This question label is a short form identifier for this specific question. It is used in place of the entire question text in some locations. You can read more about question label best practices on our help site.',
        requiredQuestion: 'Required Question',
        scaledType: 'Scale',
        smallTextBox: 'Small Text Box',
        randomizeAnswers: 'Randomize answers',
        rightLabel: 'Right label...',
        saveChanges: 'Save Changes',
        scaleLabelMaxLengthError:
          'Scale label max character length is 512 characters',
        scaleLabels: 'Scale Labels',
        scaleTypeShowLogicPrompt: 'Enter upper and lower values',
        selectCondition: 'Select condition',
        showLogic: 'Show logic',
        showQuestionIf: 'Show this question if',
        singleChoiceType: 'Single-Select',
        singleTypeShowLogicPrompt: 'Choose an answer',
        standard: 'Standard',
        steps: 'Steps',
        typeAnswerHere: 'Type answer here',
      },
      questionsTitle: 'Questions',
      sectionTitles: {
        modelQuestions: 'Model Questions (${numQuestions})',
        customQuestions: 'Custom Questions (${numQuestions})',
        externalQuestions: 'External Questions (${numQuestions})',
      },
      settings: {
        model: {
          title: 'Survey Model Questions',
          elements: {
            description:
              'This is an area that describes what each of the elements is. We may want to consider how each of these elements impacts satisfaction and how satisfaction impacts future behaviors.',
          },
          satisfaction: {
            description:
              'This is an area that describes what each of the elements is. We may want to consider how each of these elements impacts satisfaction and how satisfaction impacts future behaviors.',
          },
          futureBehaviors: {
            description:
              'This is an area that describes what each of the elements is. We may want to consider how each of these elements impacts satisfaction and how satisfaction impacts future behaviors.',
          },
        },
        custom: {},
      },
    },

    tabs: {
      1: 'Overview',
      2: 'Questions',
      3: 'View Responses',
      4: 'Reports',
    },
    seeRespondents: {
      surveyTaken: 'Survey Taken: ',
      rowNumber: 'Row # ',
      modelQuestions: 'Model Questions',
      customQuestions: 'Custom Questions',
      scores: 'Scores',
      openEndedQuestions: 'Open Ended Questions',
      replay: 'Replay',
      replayIsBeingProcessed: 'Session replay is being processed.',
      replayIsNotExisting: 'Session replay is not existing.',
      replayProcessingFailed: 'Session replay processing failed.',
      userDetails: 'User Details',
      date: 'Date',
      transactionDate: 'Transaction Date',
      responseDate: 'Response Date',
      satisfaction: 'Satisfaction',
      customizeColumns: 'Customize Columns',
      columns: '${num} Columns',
      savedViews: 'Saved Views',
      savedViewsNew: 'New Saved View',
      mySavedView: 'My Saved Views',
      companyDefault: 'Company Default',
      saveNewView: 'Save a New View',
      saveViewDescription:
        'Give this custom view a name and choose the options (if any) that you would like it to have.',
      enterSaveViewName: 'Enter a name for this saved view...',
      savedViewDefault: 'Make this my default view',
      savedViewEveryonesDefault: "Make this everyone's default view",
      savedViewMakeViewPublic: 'Make this view public (available to all users)',
      saveView: 'Save View',
      defaultSaveViewTooltip:
        'This Saved View is currently set to be your default view for ${view}. You can choose a new default by editing that view.',
      deleteSaveView: 'Are you sure you want to delete ${name}?',
      sortAscending: 'Sort Ascending',
      sortDescending: 'Sort Descending',
      removeColumn: 'Remove Column',
      sendToFirst: 'Send to first',
      sendToLast: 'Send to last',
      errorTooMany: "We weren't able to fetch the responses.",
      searchPlaceholder: 'Search for Respondent ID',
    },
    invalidDateRange: {
      heading: 'Max Date Range Exceeded',
      subheading:
        'Reporting date range cannot be more than 366 days, please select a smaller range.',
    },
  }, // END MEASURES

  surveys: {
    createSurvey: {
      tellUs: 'Tell us about your new survey',
      form: {
        surveyName: {
          title: 'Survey Name',
          substring:
            'This is what your survey will be called in Predictive Experience',
        },
        surveyTitle: {
          title: 'Survey Title',
          substring: 'This will be the displayed title in your survey',
        },
        language: {
          title: 'Language',
          label: 'English (American English)',
          substring: `Changing the language will make all our question
            bank questions default to the selected language`,
        },
        omb: {
          type: 'toggle',
          title: 'Federal Government Survey',
          substring: 'Enabling this will add OMB values to your survey',
        },
        addHierarchy: {
          type: 'toggle',
          title: 'Add Hierarchy',
          substring: `Enabling this will require additional configuration with Predictive Experience.`,
        },
        errorMessages: {
          pattern: `Only letters, numbers and special characters allowed.
           Characters / and \\ are not allowed`,
        },
        startBuilding: 'Start Building',
      },
    },
  },
  //END SURVEY BUILDER
  /*
   * Master Data
   */
  masterData: {
    errorMessages: {
      pattern: `Only letters, numbers and special characters allowed. Characters ’ and – are not allowed.`,
      disallowedValues:
        'Please enter a unique value. Another record exists with this id.',
    },
  },
  //END MASTER DATA
  /*
   *  Insight strings
   */
  insights: {
    editSurvey: 'Edit Survey',
    seeRespondents: {
      rating: 'Rating',
      search: 'Search keyword or user details...',
      deleteThisResponse: 'Delete This Response',
    },

    submissions: 'Submissions',
    avgRating: 'Average Rating',
    noResponse: 'No Response',

    charts: {
      answer: 'Answer',
      sat: 'Satisfaction',
      dist: 'Distribution %',
      n: 'n',
      filter: 'fltr',
    },

    recommendedPriorities: {
      title: 'Recommended CX Priorities',
      noActiveMeasures: 'No Active Measures',
      contactAccountTeam:
        'Please contact your Account Management Team for further details or to create a new CX Survey.',
      stillCollectingHeader: "We're Still Collecting Data",
      stillCollectingContent:
        'Recommendations are based off collection of 300 survey responses. Your measures are still collecting to reach this point. Check back soon!',
      priority: {
        title: 'PRIORITY',
        analyzeSurvey: 'Overview',
        noElements:
          'This measure does not have any particular priority to focus on.',
        content_1:
          "We recommend to focus on the following parts of the customer experience: <span class='semibold'>${firstElement}</span>",
        content_2: " and <span class='semibold'>${secondElement}</span>",
        info: {
          title: 'DETAILS',
          dateRange: 'Date Range',
          nCount: 'n Count',
          CSAT: 'CSAT Score',
          priorityIndex: 'Priority Index',
          measureActivationDate: 'Measure Activation Date',
        },
      },
      settings: {
        name: 'Settings for Recommended CX Priorities',
        description:
          'Adjust which measures are displayed in the Recommended Priorities section by turning on / off the switch next to the measure name below.',
        measuresSurveys: 'MEASURES (SURVEYS)',
        associatedFutureBehavior: 'ASSOCIATED FUTURE BEHAVIOR',
        priorityIndex: 'PRIORITY INDEX',
        hideShow: 'HIDE/SHOW',
        defaultSettings: 'USE DEFAULT SETTINGS',
        priorityIndexTooltip:
          'The index is calculated using both impacts and scores to provide a ranking that will help prioritize your efforts based on our predictive measurement methodology.',
        unqualifiedTooltip:
          'The current number of responses to your survey is only ${count}. To generate impacts and priorities, at least ${minimum} responses are needed.',
        receiptIntervalTooltip:
          'We are still gathering responses for this survey. Please check back after ${completionDate} once this survey response collection period is complete.',
        unknownErrorTooltip:
          'Impacts unavailable at this time, please contact your Predictive Experience account management team for further assistance.',
        tooManyRespondentsBD:
          'The number of responses exceed the limit (${maximum}) for generating impacts and priorities.',
        partitionThresholdNotMetBD:
          'The distribution of responses in the survey does not meet the minimum threshold for generating impacts and priorities.',
        selectedFutureBehavior: 'Select Future Behavior',
        note: 'Note: ',
        defaultSettingsMessage:
          'By selecting Default Settings you will lose any custom changes.',
      },
      incompletePriorities: {
        note: 'NOTE',
        topMessage:
          'Currently you have ${count} out of ${maxCount} measurements that we have not calculated impacts and priority index for yet.',
        label: 'More to come!',
        content:
          'You have ${value} other measurement(s) that we have not calculated impacts for yet.',
        seeProgress: 'See Progress',
        tooltip: 'SURVEY n COUNT /n Checkout Abandon: 289',
      },
    }, //end insights.recommendedPriorities
    scoreSummary: {
      text: 'If you focused on ELEMENT, your business would see a positive impact in your FUTURE_BEHAVIOR scores.',
      elements: 'Levers that drive satisfaction',
      futureBehaviors: 'Outcomes',
    }, // end insights.scoreSummary

    topicDropdown: {
      all: 'All Topics',
      empty: 'No Topics for Selected Project',
    }, // end Insights.topicDropdown

    openEndDisplayGroups: {
      dateReverse: 'Date - New to Old',
      date: 'Date - Old to New',
      scoreReverse: 'Score - High to Low',
      score: 'Score - Low to High',
      ratingReverse: 'Rating - High to Low',
      rating: 'Rating - Low to High',
    }, // end insights.openEndDisplayGroups

    tabs: {
      1: 'Overview',
      2: 'Questions',
      3: 'View Responses',
      4: 'Reports',
    }, // end insights.tabs

    viewSurvey: {
      noData: 'No Data Yet',
    },
    // End of View Survey
    respondents: {
      respondentDetail: {
        replay: 'Replay',
        survey: 'Survey',
        userDetails: 'User Details',
        surveyQuestions: 'Survey Questions',
      },
      replay: {
        delete: 'Delete Replay?',
        confirmDelete: 'Are you sure you want to delete this replay?',
        backToVideo: 'Back to video',
        allReplays: 'All Replays',
        processing: 'This replay is still processing. Please try again.',
        reload: 'Try again',
      },
      delete: 'Delete Response',
      delete2: 'Delete Response?',
      confirmDelete: 'Are you sure you want to delete this response?',
      surveyResponse: 'Survey Response',
      metadata: 'Meta Data',
      respondentInfo: 'Respondent Info',
      userDetails: 'User Details',
      respondentId: 'Respondent ID: ',
      templatedRespondentId: 'Respondent ID: ${id}',
      headings: {
        replay: 'Replay',
        time: 'Start Time',
        pages: 'Page Count',
        duration: 'Duration (Sec)',
        tools: 'Tools',
      },
      respondent_id: 'respondent_id',
    }, // end insights.respondents

    impacts: {
      unavailable: 'Impacts are not available for this period. ',
      generate: 'Generate most recent impacts.',
      tooltip:
        'The ForeSee algorithm requires more respondents to calculate a reliable impact score. Generating impacts will also update the date range.',
    }, // end insights.impacts

    openEndsGroupName: {
      unrated: 'Unrated',
      today: 'Today',
      yesterday: 'Yesterday',
    }, // end insights.openEndsGroupName
    invalidDateRange: {
      heading: 'Max Date Range Exceeded',
      subheading:
        'Reporting date range cannot be more than 366 days, please select a smaller range.',
    }, // end insights.invalidDateRange
    empty: {
      heading: 'No responses for current selection',
      subheading: 'your last filter choice',
      cta: 'Undo',
    }, // end insights.empty
  }, // END INSIGHTS

  reports: {
    topicDropdown: {
      all: 'All Topics',
      empty: 'No Topics for Selected Project',
    }, // end reports.TopicDropdown

    sections: {
      create: {
        title: 'Create Report',
        output: {
          title: 'Output',
          CSVDownload: 'CSV Download',
        },
        selection: {
          title: 'Selection',
          dateRange: 'By Date Range',
        },
        date: 'Date',
      },
      settings: {
        title: 'Settings',
        mapping: {
          title: 'Mapping file',
          description:
            'A mapping file corresponds the label headings in a CSV file and the questions and answers.',
          options: {
            on: 'Include question and answer mapping file',
            off: "Don't include mapping file",
          },
        },
      },
    },

    errors: {
      noContent: 'No respondent data was found for the criteria provided',
    },

    downloadButton: {
      message: 'Download',
      activeMessage: 'Downloading...',
    },
  }, // end reports

  alerts: {
    myNotifications: 'My Notifications',
    title: 'Alerts',
    tooltips: {
      edit: 'Edit',
      duplicate: 'Duplicate',
      delete: 'Delete',
    },
    table: {
      name: 'Name',
      status: 'Status',
      description: 'Description',
      author: 'Author',
      lastExecuted: 'Last Executed',
      tools: 'Tools',
      project: 'Project',
    },
    edit: {
      trigger: 'Trigger Events',
      publish: 'Configure Email',
    },
    publish: {
      subject: 'Subject:',
      subjectPlaceholder: 'e.g. Finance Alert',
      email: 'Email to:',
      emailPlaceholder: 'e.g. name@domain.com, name2@domain2.com',
      emailDescription:
        '* Please separate multiple email addresses with a comma',
      emailBody: 'Email Body:',
    },
    select: {
      criteria: 'Choose Criteria',
      operator: 'Choose Operator',
      value: 'Choose Value',
    },
    choose: 'Choose ',
    project: ' Project',
    if: 'IF:',
    addCriteria: 'Add criteria',
    add: 'Create a Notification',
    delete: {
      heading: 'Delete Alert?',
      description: 'Are you sure you want to delete this alert?',
      confirm: 'Delete',
      cancel: "No, I'll keep it",
      success: ' has been deleted', // :name has been deleted
      error: 'An error occurred deleting ', //An error occurred deleting :name
    },
    example: 'test',
  },

  siteNav: {
    newProject: 'New Project',
    viewAllProjects: 'View All Projects',
    external: {
      advancedAnalyticsPortal: 'Advanced Analytics Portal',
      executivePortal: 'Executive Portal',
    },
    dashboards: 'Dashboards',
    sentiments: 'Text Analytics',
    projects: 'Surveys',
    create: 'Create',
    analytics: 'Analytics',
    benchmarks: 'Benchmarks',
    reports: 'Reports',
    alerts: 'Alerts',
    tasks: 'Tasks',
    admin: 'Admin',
    help: 'Help Center',
    profileSettings: 'Profile',
    globalSettings: 'Global Settings',
    tableauServer: 'Tableau Server',
    mpathy: 'Behavior Analytics',
    surveyManagement: 'Survey Management',
  },

  security: {
    heading: 'Login Required',
    description: 'Please login to access Verint Predictive Experience.',
    expiredSession: 'Your session has expired. Please login again.',
  },

  datePicker: {
    dateFormat: 'MM/DD/YYYY',
    invalidDate: 'Invalid Date',
    startDate: 'Start Date',
    endDate: 'End Date',
    xDays: '${days} day(s)',
    gregorian: 'Gregorian',
    clientFiscal: '${name} Fiscal',
    typeOfCalendar: 'Type of Calendar',
    applyDateRange: 'Apply Date Range',
    rangePhraseFormat: '${start} to ${end}',
    tabs: {
      custom: 'Custom',
      year: 'Year',
      quarter: 'Quarter',
      month: 'Month',
      week: 'Week',
      day: 'Day',
    },
    year: {
      current: 'Current Year Through Yesterday',
      last: 'Last Year',
      previous: 'Previous Years',
      select: 'Select a Year',
    },
    quarter: {
      current: 'Current Quarter Through Yesterday',
      last: 'Last Quarter',
      previous: 'Previous Quarters',
      select: 'Select Quarter',
    },
    month: {
      current: 'Current Month Through Yesterday',
      last: 'Last Month',
      previous: 'Previous Months',
      select: 'Select Months',
    },
    week: {
      current: 'Current Week Through Yesterday',
      last: 'Last Week',
      previous: 'Previous Weeks',
      select: 'Select Week',
    },
    day: {
      current: 'Yesterday',
      previous: 'Previous Days',
      select: 'Select Day',
    },
    presetMenu: {
      cardDefault: 'Card Default',
      customRange: 'Custom Range',
      last24Hours: 'Last 24 Hours',
      yesterday: 'Yesterday',
      mixedYesterday: 'Yesterday / Last 24 Hours',
      weekToDate: 'Week to Date',
      monthToDate: 'Month to Date',
      quarterToDate: 'Quarter to Date',
      yearToDate: 'Year to Date',
      lastWeek: 'Last Week',
      lastMonth: 'Last Month',
      lastQuarter: 'Last Quarter',
      lastYear: 'Last Year',
      last7Days: 'Last 7 Days',
      last30Days: 'Last 30 Days',
      last60Days: 'Last 60 Days',
      last90Days: 'Last 90 Days',
      last120Days: 'Last 120 Days',
      last12Months: 'Last 12 Months',
    },
    invalidDateRange: {
      heading: 'Max Date Range Exceeded',
      subheading:
        'Reporting date range cannot be more than 366 days, please select a smaller range.',
      customRange:
        'Reporting date range cannot be more than ${number} days, please select a smaller range.',
      customRangeMonths:
        'Reporting date range cannot be more than ${number} months, please select a smaller range.',
    }, // end datePicker.invalidDateRange
  },
  logicOperators: {
    numeric: {
      equals: '=',
      gt: '>',
      lt: '<',
    },
    openend: {
      contains: 'Contains',
    },
  },
  pagination: {
    rowDisplayLabel: 'results per page',
    separator: ' of ', // 1-10 of 100
    displayPerPage: '${N} results per page',
  },
  commentCard: {
    title: 'Comments',
    empty: 'Your query has no results',
    headers: {
      date: 'Date',
      comment: 'Comment',
    },
    settings: {
      title: 'Choose Your Comment Card Details',
      tabs: {
        openendsLabel: 'Open End Questions',
        openendsSubLabel: 'Choose One (1)',
        scoresDetailsLabel: 'Scores & Details',
        scoresDetailsSubLabel: 'Choose up to Three (3)',
      },
    },
  },
  searchableMenu: {
    expandAllText: 'Expand All',
    collapseAllText: 'Collapse All',
    selectAllText: 'Select All',
    clearAllText: 'Clear All',
    searchText: 'Search...',
    sorryText: 'Sorry, we didn’t find anything.',
    tryText: 'Try searching something else.',
  },

  replay: {
    disabled: {
      title: 'Replay Maintenance',
      message:
        'We are performing maintenance on replay. Playback will be unavailable, but recordings are not impacted. We will provide notification as soon as replays are made available.',
      cta: 'Thanks for your patience!',
    },
  },

  languages: {
    language: 'Language',
    dutch: 'Dutch',
    english: 'English',
    french: 'French',
    german: 'German',
    portuguese: 'Portuguese',
    spanish: 'Spanish',
    chinese: 'Chinese',
  },

  benchmarks: {
    noDataSubHeading:
      'No data to show. Try adjusting your filters and date range above.',
    titleTooltip:
      'New surveys are added and inactive ones removed every month. The recommended timeframe for accessing benchmark score statistics is monthly.',
    categoryInformation: {
      title: 'Benchmarks Category Information',
      searchPlaceholder: 'Search for Category Name',
    },
    participantInfo: {
      title: 'Benchmark Participant Information',
    },
  },
  datasources: {
    subnavTitle: {
      appendix: '${title} Data Sources',
      favorites: 'Favorites',
      predictive: 'Predictive',
      adhoc: 'Ad Hoc',
      interactionAnalytics: 'Interaction Analytics',
    },
    filters: '${column}:  ${option}',
    AllOptionValue: 'All',
    routes: {
      questions: 'Questions',
      viewResponses: 'View responses',
      template: '${name}',
    },
    views: {
      summary: 'summary',
      responses: 'responses',
      respondents: 'respondents',
    },
  },

  verinTop: {
    profile: 'Profile',
    logOut: 'Log Out',
  },
};

import Backbone from 'backbone';
import guid from 'core/utils/guid';
import _ from 'underscore';

var AlertModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};

    this.clientId = options.clientId;
    this.options = options;
    this.options.alertId = this.alertId;
    this.options.parse = true;
  },

  idAttribute: 'alertId',

  parse: function (alert) {
    var rules = alert.rules;
    if (rules) {
      _.each(rules, (rule) => {
        if (rule.conditions) {
          //get rid of deleted items which services is currently sending as null's
          var deletedItem = rule.conditions.length
            ? rule.conditions.indexOf(null)
            : -1;
          while (deletedItem >= 0) {
            rule.conditions.splice(deletedItem, 1);
            deletedItem = rule.conditions.indexOf(null);
          }
          if (!Array.isArray(rule.conditions)) {
            rule.conditions = [rule.conditions];
          }
          if (!Array.isArray(alert.distribution)) {
            alert.distribution = [alert.distribution];
          }
          for (var i = 0; i < rule.conditions.length; i++) {
            if (
              rule.conditions[i].questionId === 'keyword' ||
              rule.conditions[i].questionId === 'url'
            ) {
              rule.conditions[i].criteriaKey =
                rule.conditions[i].questionId + 'Question';
            }
            rule.conditions[i].id =
              rule.conditions[i].questionId === 'keyword'
                ? rule.conditions[i].questionId + guid()
                : rule.conditions[i].questionId;
          }
        }
      });
    }
    alert.rules = rules;

    return alert;
  },

  serviceId: 'acs-services',

  fetchUrl: function () {
    return (
      'clients/' +
      this.clientId +
      '/projects/' +
      this.projectId +
      '/alerts/' +
      this.alertId
    );
  },

  url: function () {
    var alertId = this.alertId || '';
    var projectId = this.projectId || this.get('projectId');
    return (
      'clients/' +
      this.clientId +
      '/projects/' +
      projectId +
      '/alerts/' +
      alertId
    );
  },
});

export default AlertModel;

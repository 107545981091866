import Backbone from 'backbone';
import MasterDataTypeModel from 'models/clients/masterdatatype';

const MasterDataTypeCollection = Backbone.Collection.extend({
  model: MasterDataTypeModel,

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
  },

  parse: function (data) {
    return data.results;
  },

  url: function () {
    return `clients/${this.client}/masterdatatypes`;
  },
});

export default MasterDataTypeCollection;

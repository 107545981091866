import _ from 'underscore';
import store from 'core/store';
import permissions from 'core/permissions';
import i18n from 'core/i18n';
import oauth from 'core/oauth';
import Router from 'core/router';
import EventTracker from 'core/utils/eventtracker';
import BaseController from 'controllers/base';
import constants from 'core/constants';
import SettingsModel from 'models/settings';
import getConfig from 'core/config';
import AppView from 'views/app';
import UserModel from 'models/currentUser';
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import Backbone from 'backbone';
import { ApolloProvider } from '@apollo/client';
import 'jquery.browser';

class AppController extends BaseController {
  constructor() {
    super(arguments, {
      name: 'app',
      dependsOn: [
        'response-status',
        'post-message',
        'storage',
        'routes',
        'whitelist',
        'export',
        'dates',
      ],
      actions: {
        'track:pageview': 'trackPageView',
        'track:event': 'trackEvent',
        'route:change': 'changeRoute',
        'location:change': 'changeLocation',
        'route:get': 'getRoute',
        'app:authenticated': 'handleAuthenticated',
        'app:logout': 'handleLogout',
        'user:data': 'handleUserData',
        'user:clients:setClient': 'handleClientChanged',
        all: 'listenToAll',
        'featureFlags:ready': 'handleFeatureFlagsReady',
        'app:impersonatedUserLogin': 'handleImpersonatedUserLogin',
        'app:impersonatedUserLogout': 'handleImpersonatedUserLogout',
        'user:initialized': 'setUserTracking',
      },
      oauth,
      timestamp: '!!_timestamp_!!',
      version: '24.8.48',
      // Google analytics event tracker
      eventTracker: new EventTracker(
        window[window.GoogleAnalyticsObject],
        window.pendo
      ),
      // this variable is for dev purposes since the api server could reside on a different machine
      serverRoot: constants.SERVER_ROOT,
      settings: new SettingsModel(getConfig()),
      controller: {},
      controllers: arguments[1],
      pendingActions: {},
      store: store,
      permissions: permissions,
      i18n: i18n,
      startController: arguments[2],
    });
  }

  async initialize(opts) {
    this.browserConfig();
    this.router = this.setupRouter();

    // Trigger popstate event for each route to be handled by the new sidenav
    this.router.on('route', function () {
      window.dispatchEvent(new PopStateEvent('popstate'));
    });

    // Make oauth interface available to Backbone.sync
    Backbone.sync.oauth = this.oauth;

    await this.sonomaAuthCheck();

    // Set default lang to en if it is not set already
    if (!store.get('lang')) {
      store.set('lang', 'en');
    }
  }

  browserConfig() {
    const ua = navigator.userAgent;
    const isIE = ua && ua.indexOf('MSIE') > 0;
    let bodyElement = document.body;

    // Set the default html5 status
    this.isHTML5Browser = false;

    // Detect mobile browsers
    if (typeof window.orientation !== 'undefined' || ua.indexOf('ipad') > -1) {
      bodyElement.classList.add('mobile');

      this.isMobile = true;
      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        this.isIOS = true;
        bodyElement.classList.add('ios');
        this.isHTML5Browser = true;
      }
    } else {
      bodyElement.classList.add('desktop');
    }

    // Add some key class names to the body tag to facilitate cross-browser oddities
    if (isIE) {
      bodyElement.classList.add('ienew');
      this.isHTML5Browser = true;
    }

    // If we aren't old ie, then assume we're html5
    if (!isIE) {
      this.isHTML5Browser = true;
    }

    // Add the html5 flag
    if (this.isHTML5Browser) {
      bodyElement.classList.add('html5');
    }
  }

  /**
   * Extracts the sonoma token from the url and exchanges it for a predict oauth token
   *
   * @returns {Promise<null>}
   */
  async sonomaAuthCheck() {
    // check if we already have the token set
    // we will refresh the predict token if we do
    let idToken = localStorage.getItem('sonomaIDToken');
    let sonomaToken = localStorage.getItem('sonomaAccessToken');
    let bearerToken = localStorage.getItem('sonomaBearerToken');

    // extract the sonoma token from the url
    if (window.location.search.length !== 0) {
      const params = new URLSearchParams(window.location.search);

      if (
        params.has('idToken') &&
        params.has('sonomaToken') &&
        params.has('bearerToken')
      ) {
        idToken = params.get('idToken');
        sonomaToken = params.get('sonomaToken');
        bearerToken = params.get('bearerToken');

        // remove the sonoma token from the url
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );

        localStorage.setItem('sonomaBearerToken', bearerToken);
        localStorage.setItem('sonomaAccessToken', sonomaToken);
        localStorage.setItem('sonomaIDToken', idToken);
      }
    }

    if (!sonomaToken || !idToken || !bearerToken) {
      return null;
    }

    // exchange the sonoma token for a predict oauth token
    const response = await fetch('!!_scpUrl_!!/token2', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-sm-oauth-token': bearerToken || '',
      },
      body: JSON.stringify({
        access_token: sonomaToken,
        id_token: idToken,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return false;
      });

    if (!response) {
      return null;
    }

    const { secret, token } = response;

    // set the lux flag
    const access = sonomaToken?.split('.')[1];
    const decoded = JSON.parse(atob(access || ''));

    store.set('lux', decoded?.migrated === 'true' || false);

    // store the predict oauth token
    this.oauth.setTokens(token, secret);
  }

  setupRouter() {
    // Create a new instance of our special router
    var router = new Router();

    // Make a copy of the default routes so we can restore them later
    router.setBaseRoutes();

    // Set listeners for router events
    this.listenTo(router, 'track:pageview', (url) => {
      this.eventTracker.trackPageView(url);
    });

    this.listenTo(router, 'route:change', (payload) => {
      const { route = payload, params, doNotAbort = false } = payload;

      const configList = [
        'layout',
        'controller',
        'isAuthRequired',
        'parentNav',
        'childNav',
        'focusView',
      ];
      let config = this.settings.getConfigParts(route, configList);
      config.isAuthRequired =
        config.isAuthRequired === undefined ? true : config.isAuthRequired;
      const newConfig = this.settings.getPathConfig(route);
      const pathIds = this.settings.getPathIds(route);
      const newPermissions = this.settings.getPermissions(route);
      const newProduct = this.settings.getProduct(route);
      const newActiveTab = this.settings.getActiveTabValue(route);
      this.pathIds = pathIds;
      this.params = params;
      this.route = route;
      payload.pathIds = pathIds;

      // abort all pending requests
      if (!doNotAbort) {
        $.abortAllPendingRequests();
      }

      // Get route information and use it to invoke layout and check and notify panels
      let nextPayload = payload;
      // Check authenticated
      if (this.authenticated() || config.isAuthRequired === false) {
        if (config.layout && config.controller) {
          nextPayload = _.extend(nextPayload, config);
          nextPayload.permissions = newPermissions;
          nextPayload.pathConfig = newConfig;
          nextPayload.product = newProduct;
          nextPayload.params = params;
          nextPayload.activeTab = newActiveTab;
          this.publish('location:change', nextPayload);
        } else {
          this.publish('route:change', {
            route: 'error',
            response: {
              status: '404',
              statusText: 'Not found',
            },
          });
        }
      } else {
        //The previous route is now going to be the route the user was trying to get to. (as opposed to the last route they were actually on)
        nextPayload.previousRoute = nextPayload.route;
        //Append query parameters, if any.
        if (nextPayload.params) {
          nextPayload.previousRoute += '?';
          for (let prop in nextPayload.params) {
            nextPayload.previousRoute +=
              prop + '=' + nextPayload.params[prop] + '&';
          }
          //Remove last "&" from route.
          nextPayload.previousRoute = nextPayload.previousRoute.slice(0, -1);
        }
        nextPayload.route = 'login';
        store.set('previousRoute', nextPayload.previousRoute);
        this.publish('route:change', nextPayload);
      }
    });

    return router;
  }

  changeRoute(payload) {
    // Listen for route changes coming from dispatcher
    var route = payload.route || payload;
    var noEntry = payload.noEntry;

    this.previousRouteVisited = payload.previousRoute;

    if (!this.justLoggedIn) {
    } else {
      this.justLoggedIn = false;
    }

    this.router.navigate(route, {
      trigger: true,
      replace: noEntry,
    });
  }

  changeLocation(payload) {
    this.changeController(payload);
    this.currentLocation = payload;
    this.render();
  }

  changeController(payload) {
    if (payload.controller !== this.controller.name) {
      if (this.controllers[payload.controller]) {
        this.controller = this.controllers[payload.controller];
      } else {
        this.controller = this.startController(payload.controller);
        this.controllers[payload.controller] = this.controller;
        this.controller.name = payload.controller;
      }
    }
  }

  // Respond to requests for information about current path
  getRoute(payload) {
    var response = {
        pathIds: this.pathIds,
        route: this.route,
        preventRedirect: payload && payload.preventRedirect,
      },
      callback = payload && payload.callback;

    if (callback instanceof Function) {
      callback(response);
    } else {
      this.publish('route:data', response);
    }
  }

  handleAuthenticated(payload) {
    const response = payload.response || {};
    const accessToken = response.token;
    const tokenSecret = response.secret;
    const loginDetails = response.loginDetails;
    if (loginDetails) {
      store.set(
        'showPasswordExpirationWarning',
        loginDetails.showPasswordExpirationWarning
      );
      store.set('sessionToken', loginDetails.sessionToken);
    }

    this.oauth.setTokens(accessToken, tokenSecret);
    this.justLoggedIn = true;
    if (window.location.search.length) {
      const url = new URLSearchParams(window.location.search);
      const redirectUrl = url.get('path');
      //Assumption that any url contained in the path param is relative.
      if (redirectUrl) {
        window.location.assign(redirectUrl);
        return;
      }
    }
    const redirectRoute = this.previousRouteVisited
      ? this.previousRouteVisited
      : payload.previousRoute;

    if (redirectRoute) {
      this.router.navigate(redirectRoute, {
        trigger: true,
      });
    } else if (self === top) {
      this.router.navigate('dashboards', {
        trigger: true,
      });
    } else {
      this.router.navigate('feedback-admin/summary', {
        trigger: true,
      });
    }
  }

  stopControllers(dontStop) {
    // remove all non-essential controllers from from active duty
    //  - remove from controllers array
    //  - run shutdown process for controllers
    //  - only keep user and the ones from app's dependsOn
    var keep = this.dependsOn.slice(),
      controllers = this.controllers;

    keep.push('app');
    keep.push('authenticate');

    if (dontStop) {
      keep.push(dontStop);
    }
    _.forEach(controllers, (controller) => {
      if (keep.indexOf(controller.name) > -1) {
        // We're keeping it
      } else {
        delete controllers[controller.name];
        controller.destroy();
      }
    });

    // Remove reference to current controller
    this.controller = {};
  }

  _drainPendingQueue(name) {
    if (this.pendingActions[name]) {
      this.pendingActions[name].forEach((pa) => {
        this.publish(pa.action, pa.payload);
      });
      this.pendingActions[name] = [];
    }
  }

  _pushToPendingQueue(name, action, payload) {
    if (!this.pendingActions[name]) {
      this.pendingActions[name] = [];
    }
    this.pendingActions[name].push({
      action,
      payload,
    });
  }

  _isControllerUp(name) {
    const controller = this.controllers[name];
    return (
      controller &&
      controller.initialized &&
      controller.initialized.state &&
      controller.initialized.state() === 'resolved'
    );
  }

  listenToAll(action, payload) {
    const split = action.split(':', 2);
    const name = split[0];
    if (split.length === 2 && split[1] === 'initialized') {
      this._drainPendingQueue(name);
    } else if (
      !this._isControllerUp(name) &&
      payload &&
      payload.retryIfUninitialized
    ) {
      this._pushToPendingQueue(name, action, payload);
    }
  }

  // Log event to our analytics system
  trackEvent(payload) {
    var category = payload.category;
    var action = payload.action;

    this.eventTracker.trackEvent(category, action);
  }

  // Flush data when a different client is selected
  handleClientChanged(cb) {
    // stop all controllers
    this.stopControllers(['user', 'settings']);

    //clear app data
    this.publish('app:clearData');

    // flush local storage
    store.safeClear();
    if (cb) {
      cb();
    }

    // trigger current route again to start things up clean with new client
    this.router.trigger('route:change', {
      route: this.settings.get('defaultRoute'),
      previousRoute: this.router.previousRoute,
    });
    // Publish route:change to trigger url and breadcrumb re-render
    this.publish('route:change', this.settings.get('defaultRoute'));
  }

  // Listen for logout
  handleLogout(payload) {
    var cb = () => {
        // Stop controllers
        this.stopControllers();

        //clear app data
        this.publish('app:clearData');
        this.apolloClient.clearStore().then(() => {
          // Hit logout endpoint on services to kill tokens on backend
          // This happens when we save()
          const sonomaToken = localStorage.getItem('sonomaAccessToken');

          if (sonomaToken !== null) {
            localStorage.removeItem('sonomaBearerToken');
            localStorage.removeItem('sonomaAccessToken');
            localStorage.removeItem('sonomaIDToken');

            const access = sonomaToken?.split('.')[1];
            const decoded = JSON.parse(atob(access || ''));

            // Set route to login
            window.location = decoded?.origin + '/logout.aspx';
          } else {
            this.router.navigate('login', { trigger: true });
          }
        });
      },
      logout = new Backbone.Model();

    if (payload.expiredSession) {
      this.publish('app:sessionexpired', {
        previousRoute: payload.previousRoute,
        expiredSession: true,
      });
    }

    logout.set('oauth_token', store.get('accessToken'));
    logout.url = 'logout';
    this.publish('authenticate:removeOktaSession');

    logout
      .save()
      .then(cb)
      .catch(cb)
      .then(() => {
        // Clear out oauth tokens and stored data.
        store.safeClear();
        store.remove('accessToken');
        store.remove('tokenSecret');
        store.remove('idToken');
        store.remove('showPasswordExpirationWarning');
      });
  }

  handleImpersonatedUserLogin() {
    this.publish('user:clients:setClient', store.switchUser);
  }

  handleImpersonatedUserLogout(payload) {
    const { callback } = payload;
    const cb = () => {
        // Clear out oauth tokens and stored data.
        store.safeClear();
        store.restoreUser();

        this.apolloClient.clearStore();
        store.remove('xImpersonatedUserId');
        this.publish('app:clearData');
        this.stopControllers(['user', 'settings']);

        // trigger current route again to start things up clean with new client
        this.router.trigger('route:change', {
          route: this.settings.get('defaultRoute'),
          previousRoute: this.router.previousRoute,
        });
        // Publish route:change to trigger url and breadcrumb re-render
        this.publish('route:change', this.settings.get('defaultRoute'));
        callback();
      },
      logout = new Backbone.Model();

    logout.set('oauth_token', store.get('accessToken'));
    logout.url = 'logout';

    logout.save().then(cb).catch(cb);
  }

  // Listen for User Data, pass user roles into permissions singleton
  handleUserData(payload) {
    this.permissions.populateUserRoles(payload.user.grantedAuthorities);
    this.publish('user:permissions:changed', {});
    this.updateSettings();
    payload.accessTimeout = payload.accessTimeout ? payload.accessTimeout : 0;
    if (payload.user.accessState === 'STALE') {
      let model = new UserModel();
      _.delay(() => {
        model.fetch({ doNotAbort: true }).then(() => {
          if (payload.accessTimeout >= 10) {
            model.set('accessState', null);
          }
          if (model.get('accessState') !== 'STALE') {
            this.userData.user = model.toJSON();
            this.render();
          } else {
            payload.user = model.toJSON();
            payload.accessTimeout += 1;
            this.publish('user:data', payload);
          }
        });
      }, 500);
    }
    let settings = this.settings;
    let config = _.extend({}, settings.toJSON(), payload);
    const client = payload && payload.client && payload.client.name;
    const clientId = payload && payload.client && payload.client.clientId;
    const user = payload && payload.user && payload.user.username;
    const userId = payload && payload.user && payload.user.userId;
    const foreseeUser = payload && payload.user && payload.user.clientId === -1;
    const dims = {
      clientId: clientId,
      client: client,
      user: user,
      userId: userId,
      foreseeUser: foreseeUser,
    };

    this.userData = payload;

    this.setGADims(dims);
    this.filteredConfig = config = settings.returnPermitted(
      config,
      this.permissions.userRole.bind(this.permissions)
    );
    this.render();
  }

  handleFeatureFlagsReady(userData) {
    this.updateSettings();
    this.handleUserData(userData);
    this.router.trigger('route:change', {
      route: this.route,
      params: this.params,
      doNotAbort: true,
    });
  }

  updateSettings() {
    this.settings = new SettingsModel(getConfig());
  }

  setUserTracking() {
    const { user, client } = this.userData;

    const emailDomain = user.email.match(/@(\w+.+)/)[1];
    const isVerintEmployee =
      client.clientId === -1 ||
      emailDomain === 'verint.com' ||
      emailDomain === 'foresee.com';
    const isSystemAdmin = permissions.userRole(
      [
        'permissions_edit',
        'user_admin',
        'global_settings_api_credentials',
        'admin',
      ],
      false
    );
    const isSurveyAdmin = permissions.userRole(
      [
        'hosted_code',
        'feedback_create',
        'feedback_privacy_policy',
        'feedback_weblink',
        'feedback_publish',
        'feedback_admin',
      ],
      false
    );

    this.eventTracker.initializeUser({
      accountId: client.clientId,
      accountName: client.name,
      emailDomain,
      isSurveyAdmin,
      isSystemAdmin,
      isVerintEmployee,
      visitorId: `CXS_${user.userId}`,
    });
  }

  // Set custom dimensions for google analytics
  setGADims(dims) {
    let tracker = this.controllers['app'].eventTracker;

    for (let dim in dims) {
      tracker.setCustomDimension(dim, dims[dim]);
    }
  }

  /**
   * Create main view for app and respond to top-level changes
   */
  render() {
    var settings = this.settings,
      config = _.extend(
        {},
        this.filteredConfig ? this.filteredConfig : settings.toJSON(),
        this.currentLocation
      ),
      element = document.getElementById('app');

    // Set permissions if not set by payload
    if (!config.permissions) {
      config.permissions = settings.getPermissions();
    }

    // Set product if not set by payload
    if (!config.product) {
      config.product = settings.getProduct();
    }

    // Use default layout if not set by payload
    if (!config.layout) {
      config.layout = config.defaultLayout;
    }

    // Render main view to body with settings as config.
    ReactDOM.render(
      <ApolloProvider client={this.apolloClient}>
        <AppView
          config={config}
          apolloClient={this.apolloClient}
          state={this.userData ? this.userData.user.accessState : null}
        />
      </ApolloProvider>,
      element
    );
  }
}

export default AppController;

export default {
  insights: [
    {
      label: 'insights.tabs["1"]',
      action: 'route:change',
      payload: { route: '/summary' },
      indexName: 'insights-summary',
      permissions: 'feedback_access',
    },
    {
      label: 'insights.tabs["2"]',
      action: 'route:change',
      payload: { route: '/survey' },
      indexName: 'insights-questions',
      permissions: 'feedback_access',
    },
    {
      label: 'insights.tabs["3"]',
      action: 'route:change',
      payload: { route: '/respondents' },
      indexName: 'insights-openends',
      permissions: ['feedback_access'],
    },
  ],
  measures: [
    {
      label: 'measures.tabs["2"]',
      action: 'route:change',
      payload: { route: 'survey' },
      indexName: 'measures-survey',
    },
    {
      label: 'measures.tabs["3"]',
      action: 'route:change',
      payload: { route: 'respondents' },
      indexName: 'measures-respondents',
    },
  ],
  survey: [
    {
      label: 'projects.edit.question.editor',
      indexName: 'question-editor',
    },
    {
      label: 'projects.edit.question.logic',
      indexName: 'question-logic',
    },
  ],
  settings: [
    {
      id: 'setup',
      label: 'globalSettings.setup',
      menus: [
        {
          group: 'globalSettings.setupSettings.sidebarMenu.userAdmin',
          items: [
            {
              label: 'globalSettings.setupSettings.sidebarMenu.peopleRoles',
              value: 'people_roles',
              route: '/settings/setup/users',
            },
          ],
        },
        {
          group: 'globalSettings.setupSettings.sidebarMenu.hierarchyMasterData',
          permissions: 'hierarchy_edit',
          items: [
            {
              label: 'globalSettings.setupSettings.sidebarMenu.hierarchy',
              value: 'hierarchy_management',
              route: '/settings/setup/hierarchy',
            },
            {
              label: 'globalSettings.setupSettings.sidebarMenu.masterData',
              value: 'masterdata_management',
              route: '/settings/setup/masterdata',
            },
          ],
        },
      ],
    },
    {
      id: 'apps',
      label: 'globalSettings.apps',
      menus: [
        {
          group: 'globalSettings.appSettings.sidebarMenu.textAnalytics',
          product: 'text_analytics',
          permissions: 'text_analytics_settings',
          items: [
            {
              label: 'globalSettings.appSettings.sidebarMenu.customFeeds',
              value: 'custom_feeds',
              route: '/settings/apps/text-analytics/custom-feeds',
            },
            {
              label: 'globalSettings.appSettings.sidebarMenu.feedbackFeeds',
              value: 'feedback_feeds',
              route: '/settings/apps/text-analytics/feedback-feeds',
            },
            {
              label: 'globalSettings.appSettings.sidebarMenu.cxFeeds',
              value: 'cx_feeds',
              route: '/settings/apps/text-analytics/cx-feeds',
            } /* {
            label: 'globalSettings.appSettings.sidebarMenu.taxonomyManager',
            value: 'taxonomy_manager',
            route: '/settings/apps/text-analytics/taxonomy-manager'
        } */,
          ],
        },
      ],
    },
    {
      id: 'integrate',
      label: 'globalSettings.integrate',
      permissions: 'hosted_code',
      menus: [
        {
          group: 'globalSettings.hostedCode.title',
          items: [
            {
              label: 'globalSettings.hostedCode.settings',
              value: 'hosted_code_settings',
              route: '/settings/integrate/hosted-code',
            },
          ],
        },
      ],
    },
  ],
  datasourcesInternal: [
    {
      label: 'datasources.routes.questions',
      action: 'route:change',
      payload: { route: 'questions' },
      indexName: 'questions',
    },
    {
      label: 'datasources.routes.viewResponses',
      action: 'route:change',
      payload: { route: 'responses' },
      indexName: 'view-responses',
    },
  ],
};

import moment from 'moment';
import _ from 'underscore';
import JSTimezoneDetect from 'jstimezonedetect';
import BaseController from 'controllers/base';
import DateRange from 'models/dateRange';
import CSVExportModel from 'models/clients/csv/csv-export';
import HierarchyMDCSVModel from 'models/clients/download/hierarchy-md-csv';
import CardBookModel from 'models/export/card-book';
import PPTRequestModel from 'models/export/ppt-request';
import { saveAs } from 'file-saver';
import isBigDataMeasure from 'views/utils/is-big-data-measure';

const BLOB_TYPES = {
  csv: 'text/csv;charset=utf-8',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation;charset=UTF-8',
};
class ExportController extends BaseController {
  constructor() {
    super(arguments, {
      actions: {
        'export:masterdata:csv': 'exportMasterDataCSV',
        'export:hierarchy:csv': 'exportHierarchiesCSV',
        'export:leaderboard:details:csv': 'exportLeaderboardCSV',
        'export:measures:respondents:csv': 'exportMeasuresRespondentsCSV',
        'export:feedback:respondents:csv': 'exportFeedbackRespondentsCSV',
        'export:text-analytics:topics:ppt': 'exportTextAnalyticsDashboardPPT',
        'export:dashboard:card:csv': 'exportDashboardCardCSV',
        'export:dashboard:ppt': 'exportDashboardPPT',
        'export:dashboard': 'exportDashboard',
        'export:text-analytics:dashboard:csv': 'exportTextAnalyticsDashboard',
        'export:benchmark:csv': 'exportCSV',
        'export:datasources:csv': 'exportCSV',
      },
      name: 'export',
    });
  }

  exportTextAnalyticsDashboardPPT(payload) {
    const slides = this.convertChartDataToSlides(payload);
    const today = new moment().startOf('day');
    this.generatePowerpointImages(
      'ta-topics-dashboard',
      slides,
      `${this.getString('general.appName')} Topics Dashboard: ${today.format(
        'MMM DD YYYY'
      )}`
    );
  }

  exportDashboard(payload) {
    const { fileName, format, chartData, dashboardName } = payload;
    const slides = this.convertChartDataToSlides(chartData);
    this.generatePowerpointImages('main-dashboard', slides, fileName, format, {
      title: dashboardName,
    });
  }

  exportDashboardPPT(payload) {
    const { chartData, dashboard, cardDetail } = payload;
    const slides = this.convertChartDataToSlides(chartData);
    const today = new moment().startOf('day');
    let fileName = '';
    if (dashboard) {
      fileName = `${dashboard.name}_${today.format('MMM DD, YYYY')}`;
    } else if (cardDetail) {
      fileName = `${cardDetail.name}_${today.format('MMM DD, YYYY')}`;
    } else {
      fileName = `${this.getString('general.appName')} ${this.getString(
        'dashboards.export.defaultFileName'
      )}_${today.format('MMM DD, YYYY')}`;
    }
    this.generatePowerpointImages('main-dashboard', slides, fileName);
  }

  exportDashboardCardCSV(payload) {
    this.exportCSV(payload);
  }

  exportCSV(payload) {
    const { fileName, columns, data } = payload;
    let rows = [columns.map((c) => `"${c.title}"`)];

    data.forEach((row) => {
      let csvRow = [];
      columns.forEach((c) => {
        csvRow.push(`"${`${row[c.name]}`.replace(/"/g, '""')}"`);
      });
      rows.push(csvRow);
    });
    let csvContent = '';
    rows.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    saveAs(
      new Blob([csvContent], {
        type: 'text/csv;charset=utf-8;',
      }),
      `${fileName}.csv`
    );
  }

  exportHierarchiesCSV(payload) {
    const { typeId, fileName } = payload;
    const options = {
      clientId: this.getClientId(),
      type: 'hierarchies',
      typeId: typeId,
      fileName: fileName,
    };

    this.generateExportId(
      new HierarchyMDCSVModel(false, options),
      null,
      'downloadId'
    );
  }
  exportMasterDataCSV(payload) {
    const { typeId, fileName } = payload;
    const options = {
      clientId: this.getClientId(),
      type: 'masterdatatypes',
      typeId: typeId,
      fileName: fileName,
    };

    this.generateExportId(
      new HierarchyMDCSVModel(false, options),
      null,
      'downloadId'
    );
  }

  exportLeaderboardCSV(payload) {
    const { hierarchy, nodeId, level, view, ...criteria } = payload;

    const request = {
      attributes: {
        hierarchyId: hierarchy.id,
        level: level,
        view: view || 'level',
        nodeId: nodeId || undefined,
      },
      criteria: criteria,
    };

    const today = new moment().startOf('day');
    this.generateExportId(
      new CSVExportModel(request, {
        clientId: this.getClientId(),
        type: 'leaderboard',
        fileName: `${this.controllers.user.client.get('name')}_${today.format(
          'MMMMDDYYYY'
        )}_${this.strings.user.user_last_name}`,
      })
    );
  }

  exportFeedbackRespondentsCSV(payload) {
    const {
      project,
      metrics,
      dateRange,
      filterAnswerIds,
      searchTerm,
      timezone,
    } = payload;
    const request = {
      attributes: {
        projectKey: project.projectId,
      },
      criteria: {
        dateRange: new DateRange(dateRange).toJSON('YYYY-MM-DD'),
        timezone: timezone,
        filterAnswerIds: filterAnswerIds,
        metrics: metrics,
        searchTerm: searchTerm,
      },
    };

    this.generateExportId(
      new CSVExportModel(request, {
        clientId: this.getClientId(),
        type: 'fbrespondents',
        fileName: `${project.name}_respondents_${dateRange.a}`,
      })
    );
  }

  exportMeasuresRespondentsCSV(payload) {
    const {
      measure,
      dateRange,
      metrics,
      hierarchyFilter,
      modelFilter,
      pageFilter,
      filters,
      fileName,
      schemaId,
    } = payload;

    let type = '';
    let request = {
      attributes: {},
      criteria: {
        dateRange: new DateRange(dateRange).toJSON('YYYY-MM-DD'),
        metrics: metrics,
      },
    };

    if (isBigDataMeasure(measure.surveyType)) {
      type = 'bdrespondents';
      request.attributes = {
        surveyDefinitionId: measure.modelInstanceId,
      };
      request.criteria = Object.assign({}, request.criteria, {
        filters,
      });
    } else if (measure.surveyType === 'definition') {
      type = 'definition';
      request.attributes = {
        schemaId: schemaId,
        instanceId: measure.modelInstanceId,
      };
      request.criteria = Object.assign({}, request.criteria, {
        filters,
      });
    } else {
      type = 'respondents';
      request.attributes = {
        measurementKey: measure.measurementKey,
      };
      request.criteria = Object.assign({}, request.criteria, {
        hierarchyFilter: hierarchyFilter,
        modelFilter: modelFilter,
        pageFilter: pageFilter,
      });
    }

    this.generateExportId(
      new CSVExportModel(request, {
        clientId: this.getClientId(),
        type,
        fileName: `${fileName}_respondents_${
          new DateRange(dateRange).toJSON('YYYY-MM-DD').a
        }`,
      }),
      {
        timeout: 10000,
      }
    );
  }

  /**
   * fetches file blob and converts to correct file type based on model
   * @param model: contains export controller related logic + route
   */
  saveFile(model) {
    model.set('exportCompleted', true);
    model.fetch(model.getSaveFileOptions()).done((data, status) => {
      if (status === 'success' || status === 200) {
        const blobData =
          model.exportFileType === 'pptx' ? new Uint8Array(data) : data;
        const blob = new Blob([blobData], {
          type: BLOB_TYPES[model.exportFileType],
        });
        saveAs(
          blob,
          `${model.fileName.replace(/ /g, '_')}.${model.exportFileType}`
        );
      }
      this.publish('export:response:complete');
      this.publish('app:hideMessageFlash');
    });
  }

  /**
   * poll requests with the export id received to check the export status.
   * @param model: contains export controller related logic + route
   */
  poll(model, options = {}) {
    const timeout = (options && options.timeout) || 2500;

    model.fetch({ type: 'GET' }).then((response) => {
      if (model.hasExportFailed(response)) {
        this.publish('app:messageFlash', {
          messageType: 'error',
          messageText: response.errorMessage
            ? response.errorMessage
            : '*A server error occurred*',
        });
        return;
      }

      if (model.hasExportCompleted(response)) {
        this.saveFile(model);
        clearTimeout(this.pollTimer);
        return;
      }

      this.pollTimer = setTimeout(() => {
        this.poll(model);
      }, timeout);
    });
  }

  /**
   * helper function to generate powerpoint/csv export ids
   * @param model: contains export controller related logic + routes
   * @param exportIdName: the attribute name to retrieve the exportId
   *                      from the response
   */
  generateExportId(model, options, exportIdName = 'exportId') {
    model.save().then((response) => {
      model.set('exportId', response[exportIdName]);
      if (model.hasExportCompleted(response)) {
        this.saveFile(model);
      } else {
        this.poll(model, options);
      }
    });
  }

  /**
   * Generate powerpoint slide image paths for powerpoint server
   */
  generatePowerpointImages(
    urlRoute,
    slides,
    fileName,
    format = 'ppt',
    cardBookParams = {}
  ) {
    const cardBook = new CardBookModel({
      fileName: fileName,
      slides: slides,
    });

    cardBook.urlRoute = urlRoute;
    cardBook.save(
      (err, response, status, jqXHR) => {
        if (!response) {
          this.publish('app:messageFlash', {
            messageType: 'error',
            messageText: 'There was an error while creating your file',
          });
          this.publish('export:response:error');
          return;
        }
        // Include card size to generate pdf
        if (format === 'pdf') {
          response.slides = response.slides.map((slide, index) => ({
            ...slide,
            chart: {
              ...slide.chart,
              cardSize:
                slides[index].chart?.data?.cardDetail?.metadata?.attributes
                  ?.cardSize,
            },
          }));
        }
        this.generateExportId(
          new PPTRequestModel(
            {
              cardBookParams,
              ...response,
            },
            { fileName, format }
          )
        );
      },
      {
        doNotAbort: true,
      }
    );
  }

  /**
   * Powerpoint service expects chart data to be in
   * a specific format
   */
  convertChartDataToSlides(chartData) {
    const clientTimezone = JSTimezoneDetect.determine().name();
    var today = new moment().startOf('day');
    var slides = [];
    for (var key in chartData) {
      const chart = chartData[key];
      const data = this.clone(chart);
      if (!data) {
        continue;
      }
      data.exportTimezone = clientTimezone;
      delete data.export;

      slides.push({
        chart: {
          route: chart.export.route || key,
          data: data,
          resolution:
            chart.export.width && chart.export.height
              ? {
                  width: chart.export.width,
                  height: chart.export.height,
                }
              : null,
        },
        footer: {
          exported: `${this.strings.user.user_full_name} on ${today.format(
            'MMM DD, YYYY'
          )}`,
          n: chart.export.NCount,
          source: this.controllers.user.client.get('name'),
        },
        header: {
          subtitle: {
            date: chart.export.date,
            segment: chart.export.segment || '',
          },
          title: chart.export.title,
        },
      });
    }

    return slides;
  }

  exportTextAnalyticsDashboard(payload) {
    const {
      clientId,
      type,
      measureId,
      customFeedId,
      taFilterIds = null,
      modelFilterId,
      hierarchyFilterId,
      taQuestionIds,
      taQuestionKeys,
      name,
      dateRange,
    } = payload;
    const today = new moment().startOf('day');
    const parameters = {
      ...(modelFilterId ? { modelFilterId: [modelFilterId] } : {}),
      ...(hierarchyFilterId ? { hierarchyFilterId: [hierarchyFilterId] } : {}),

      ...(measureId ? { measureId: [measureId] } : {}),
      ...(customFeedId ? { customFeedId: [customFeedId] } : {}),
      ...(taQuestionIds && taQuestionIds.length
        ? { questionId: taQuestionIds }
        : {}),
      ...(taQuestionKeys && taQuestionKeys.length
        ? { questionKey: taQuestionKeys }
        : {}),
      clientId: [clientId],
    };
    const date = {
      c: dateRange.calendarType,
      r: 'C',
      p: 'D',
      n: 0,
      k: clientId,
      v: '',
      a: new moment().format('YYYY-MM-DD'),
      f: dateRange.start_date.format('YYYY-MM-DD'),
      l: dateRange.end_date.format('YYYY-MM-DD'),
      t: 'DATE_ONLY',
    };

    const formattedName = name.replace(/ /g, '_');
    const criteria = {
      dateRange: date,
      taFilterIds: taFilterIds,
      projectParameters: [
        {
          ...(type === 'cxmeasure'
            ? { projectType: 'CX_MEASURE', parameters }
            : {}),
          ...(type === 'feedback'
            ? { projectType: 'FEEDBACK', parameters }
            : {}),
          ...(type === 'customFeed'
            ? { projectType: 'CUSTOM_FEED', parameters }
            : {}),
        },
      ],
    };
  }

  _escapeForCsv(val) {
    return `"${val.trim().replace(/"/g, '""')}"`;
  }
}

export default ExportController;

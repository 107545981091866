import Constants from 'core/constants';
import { datadogRum } from '@datadog/browser-rum';
import pkg from '../../../package.json';

class EventTracker {
  pendoActions = new Set(Object.values(Constants.PENDO_EVENTS));

  constructor(ga, pendo) {
    this.ga = ga || window[window.GoogleAnalyticsObject] || function () {};
    this.ga('create', '!!_GAAppId_!!', 'auto');
    this.ga('send', 'pageview');

    this.dataDog = datadogRum;
    this._isDataDogInitialized = false;
    this.initializeDataDog();

    this.pendo = pendo;
    this._isPendoInitialized = false;

    this.Category = {
      FULLSCREEN: 'fullscreen',
      PINNING: 'pinning',
    };

    this.Action = {
      ENTER: 'enter',
      EXIT: 'exit',
      PINNED: 'pinned',
      UNPINNED: 'unpinned',
    };

    this.Dimensions = {
      client: 'dimension1',
      clientId: 'dimension2',
      user: 'dimension3',
      userId: 'dimension4',
      foreseeUser: 'dimension5',
    };
  }

  initializeDataDog() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const automation = urlParams.has('automation');
    const isChartEndpoint =
      window.location.toString().indexOf('client/chart') > 0;

    const env = '!!_dataDogENV_!!';
    if (env.length > 0 && !automation && !isChartEndpoint) {
      this.dataDog.init({
        applicationId: 'd451f614-2035-4607-86e0-b7490943aee8',
        clientToken: 'pub67508315dabc2acb08cf8c8d1588d82b',
        site: 'datadoghq.com',
        service: 'predictive-experience-cx-suite',
        env,
        version: pkg.version,
        sampleRate: 100,
        trackInteractions: true,
      });
      this._isDataDogInitialized = true;
    }
  }

  initializeUser(config) {
    if (this.pendo) {
      this.initializePendo(config);
    }
    if (this.dataDog && this._isDataDogInitialized) {
      this.initializeDataDogUser(config);
    }
  }

  initializeDataDogUser({
    accountId,
    accountName,
    emailDomain,
    isSurveyAdmin,
    isSystemAdmin,
    isVerintEmployee,
    visitorId,
  }) {
    this.dataDog.setUser({
      visitorId,
      isVerintEmployee: isVerintEmployee ? 'Yes' : 'No',
      emailDomain,
      isSystemAdmin: isSystemAdmin ? 'Yes' : 'No',
      isSurveyAdmin: isSurveyAdmin ? 'Yes' : 'No',
      application: 'CXS',
      hostedLocation: 'US',
      cloudInstance: 'US',
      accountId,
      accountName,
    });
  }

  initializePendo({
    accountId,
    accountName,
    emailDomain,
    isSurveyAdmin,
    isSystemAdmin,
    isVerintEmployee,
    visitorId,
  }) {
    this.pendo.initialize({
      visitor: {
        id: visitorId,
        isVerintEmployee,
        emailDomain,
        isSystemAdmin: isSystemAdmin ? 'Yes' : 'No',
        isSurveyAdmin: isSurveyAdmin ? 'Yes' : 'No',
        application: 'CXS',
        hostedLocation: 'US',
        cloudInstance: 'US',
      },
      account: {
        id: accountId,
        accountName,
      },
    });
    this._isPendoInitialized = true;
  }

  trackPageView(url) {
    this.ga('send', 'pageview');
  }

  trackEvent(category, action) {
    // Whitelisted events to track in Pendo
    if (this._isPendoInitialized && this.pendoActions.has(action)) {
      this.pendo.track(action);
    } else {
      // Otherwise track in Google Analytics
      this.ga('send', 'event', category, action);
    }
  }

  setCustomDimension(label, value) {
    if (this.Dimensions[label]) {
      this.ga('set', this.Dimensions[label], value);
    }
  }
}

export default EventTracker;

import moment from 'moment';

//  Do Moment I8ln here
if (typeof moment === 'function') {
  moment.locale('fr-ca', {
    months:
      'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
        '_'
      ),
    monthsShort:
      'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split(
        '_'
      ),
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    longDateFormat: {
      LT: 'HH:mm',
      L: 'YYYY-MM-DD',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY LT',
      LLLL: 'dddd D MMMM YYYY LT',
    },
    calendar: {
      sameDay: "[Aujourd'hui à] LT",
      nextDay: '[Demain à] LT',
      nextWeek: 'dddd [à] LT',
      lastDay: '[Hier à] LT',
      lastWeek: 'dddd [dernier à] LT',
      sameElse: 'L',
    },
    relativeTime: {
      future: 'dans %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans',
    },
    ordinal: function (number) {
      return number + (number === 1 ? 'er' : '');
    },
  });
}
/**
 * Canadian French
 * @type {Object}
 */

export default {
  /**
   *  The name of the language
   */
  _name: 'Français - Canada',

  /*
   *  Site strings
   */
  General: {
    help: 'Aide',
    logout: 'Fermer la session',
    cancel: 'Annuler',
    submit: 'Soumettre',
    ok: 'Ok',
  },
};

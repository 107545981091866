import Backbone from 'backbone';
import Constants from 'core/constants';

const HierarchyAssociationModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.product = options.product; //aka product
    this.id = model.id;
    this.set('id', model.id);
    this.isNew = () => {
      return true;
    };
    this.set('associationType', 'hierarchy');
  },

  url: function () {
    return `clients/${this.client}/associations/${this.product}/hierarchy/${this.id}`;
  },
});

export default HierarchyAssociationModel;

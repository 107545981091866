import { gql } from '@apollo/client';

const DATA_SOURCES_COLUMNS_QUERY = gql`
  query dataSourcesColumns($clientId: ID!, $dataSourceType: ID!) {
    dataSourcesColumns: dataSources(
      clientId: $clientId
      dataSourceType: $dataSourceType
    ) {
      id
      views
      columns {
        title
        tag
        columnType
        columnValues {
          key
          label
        }
        icon
        minWidth
      }
    }
  }
`;

const DATA_SOURCES_ROWS_QUERY = gql`
  query dataSourcesRows(
    $clientId: ID!
    $dataSourceType: ID!
    $dateRange: RawDateRangeInput!
    $filters: [DatasourceFilterInput]
    $pagination: paginationInput
  ) {
    dataSourcesRows: dataSources(
      clientId: $clientId
      dataSourceType: $dataSourceType
    ) {
      id
      rows(dateRange: $dateRange, filters: $filters, pagination: $pagination) {
        data {
          mid
          schemaId
          values {
            tag
            value
          }
        }
        pagination {
          totalItems
        }
      }
    }
  }
`;

const DATA_SOURCE_SCORE = gql`
  query dataSourceScore(
    $clientId: ID!
    $dataSourceType: ID!
    $dataSourceId: ID!
    $schemaId: ID
    $dateRange: RawDateRangeInput!
    $tag: ID!
  ) {
    dataSourceScore(
      clientId: $clientId
      dataSourceType: $dataSourceType
      dataSourceID: $dataSourceId
      schemaId: $schemaId
      dateRange: $dateRange
      tag: $tag
    ) {
      score
      totalCount
    }
  }
`;

const SINGLE_DATASOURCE_QUERY = gql`
  query getSingleDataSource(
    $clientId: ID!
    $dataSourceType: ID!
    $dataSourceId: ID!
  ) {
    singleDataSource(
      clientId: $clientId
      dataSourceType: $dataSourceType
      dataSourceID: $dataSourceId
    ) {
      dataSourceId
      dataSourceType
      name
      views
      schemaId
    }
  }
`;

const DATA_SOURCE_DASHBOARDS_CARDS = gql`
  query getPreparedDashboardCards(
    $clientId: ID!
    $dashboardId: ID!
    $schemaId: ID
    $dataSourceId: ID!
    $dataSourceType: ID!
    $cardId: ID
  ) {
    getPreparedDashboardCards(
      clientId: $clientId
      dashboardId: $dashboardId
      schemaId: $schemaId
      dataSourceType: $dataSourceType
      dataSourceId: $dataSourceId
      cardId: $cardId
    ) {
      cardId
      name
      metadata {
        attributes {
          cardSize
          chartType
          dataSourceType
          showDataSources
          changePeriod
          dataSources {
            key
            type
            schemaId
            hasBenchmarks
            useCountPerMetric
          }
          metrics {
            key
            type
            superLatentKey
          }
          dimensions {
            key
            type
          }
          benchmarkType
          comparisonTrend
          questionKeys
          datasourceLocked
          centerMetricDisplay
          legendPosition
          calloutLimits
          scoreColor
          benchmarkColor
          seriesColors
          includeUnifiedScores
          thresholds
          thresholdRanges {
            color
            label
          }
          benchmarkKeys {
            surveyKey
            bmCatKeys
            superLatentKey
            bmCatTag
            superLatentTag
          }
          customLabel
          referenceLines {
            color
            hasError
            label
            value
          }
          calloutLimits
        }
      }
      sortOrder
      dashboardId
      clientId
    }
  }
`;

const DATA_SOURCE_DASHBOARDS = gql`
  query DataSourceDashboards(
    $clientId: ID!
    $dataSourceType: ID!
    $dataSourceId: ID!
    $schemaId: ID
  ) {
    dataSourceDashboards(
      clientId: $clientId
      dataSourceType: $dataSourceType
      dataSourceID: $dataSourceId
      schemaId: $schemaId
    ) {
      dashboardId
      name
      accessType
      route
      isTemplate
    }
  }
`;

const CURRENT_FAVORITE_DATA_SOURCES = gql`
  query favoriteDataSources {
    favoriteDataSources {
      dataSources {
        dataSourceId
        dataSourceType
      }
    }
  }
`;

const LIST_FAVORITE_DATA_SOURCES = gql`
  query favoriteDataSources($clientId: ID) {
    favoriteDataSources {
      dataSources {
        dataSourceId
        dataSourceType
      }
      columns {
        title
        tag
        columnType
        columnValues {
          key
          label
        }
        icon
        minWidth
      }
      rows(clientId: $clientId) {
        dataSourceId
        dataSourceType
        name
        mid
        schemaId
        values {
          tag
          value
        }
      }
    }
  }
`;

const ADD_FAVORITE_DATA_SOURCE = gql`
  mutation ($dataSourceId: ID!, $dataSourceType: ID!) {
    addToFavoriteDataSources(
      dataSourceId: $dataSourceId
      dataSourceType: $dataSourceType
    ) {
      dataSourceId
      dataSourceType
    }
  }
`;

const REMOVE_FAVORITE_DATA_SOURCE = gql`
  mutation ($dataSourceId: ID!, $dataSourceType: ID!) {
    removeFromFavoriteDataSources(
      dataSourceId: $dataSourceId
      dataSourceType: $dataSourceType
    ) {
      dataSourceId
      dataSourceType
    }
  }
`;

export {
  DATA_SOURCES_COLUMNS_QUERY,
  DATA_SOURCES_ROWS_QUERY,
  DATA_SOURCE_SCORE,
  SINGLE_DATASOURCE_QUERY,
  CURRENT_FAVORITE_DATA_SOURCES,
  ADD_FAVORITE_DATA_SOURCE,
  REMOVE_FAVORITE_DATA_SOURCE,
  DATA_SOURCE_DASHBOARDS_CARDS,
  DATA_SOURCE_DASHBOARDS,
  LIST_FAVORITE_DATA_SOURCES,
};

import Backbone from 'backbone';

var Latent = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    latentKey: 0,
    name: '',
    latentType: '',
    latentTypeId: 0,
    modelLatentId: '',
    vndType: 'application/vnd.foresee.latent',
  },

  idAttribute: 'latentKey',

  /**
   * Constructor
   */
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.measure = options.measurementKey;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/latents/${this.get(
      'latentKey'
    )}`;
  },

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default Latent;

import Backbone from 'backbone';
import SLatentModel from 'models/clients/measures/slatent';

var SLatentsCollection = Backbone.Collection.extend({
  model: SLatentModel,
  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/slatents`;
  },
});

export default SLatentsCollection;

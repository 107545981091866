import Backbone from 'backbone';
import Constants from 'core/constants';

var FilterModel = Backbone.Model.extend({
  idAttribute: 'modelFilterId',

  defaults: {
    terms: [],
    operator: Constants.OPERATOR_AND,
  },

  initialize: function (attributes, opts) {
    this.addOptions(opts);
  },

  addOptions: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;

    this.options = options;
  },

  url: function () {
    if (this.get('modelFilterId')) {
      return `clients/${this.client}/measures/${
        this.measure
      }/modelfilters/${this.get('modelFilterId')}`;
    }
    return `clients/${this.client}/measures/${this.measure}/modelfilters/`;
  },

  serviceId: 'foresee-services',
});

export default FilterModel;

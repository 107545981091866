import Backbone from 'backbone';
import HierarchyAssociationModel from 'models/clients/hierarchies/association';
import Constants from 'core/constants';

const HierarchyAssociationsCollection = Backbone.Collection.extend({
  model: HierarchyAssociationModel,

  initialize: function (models, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.capabilityGroup = options.capabilityGroup; //ie. Product in swagger docs
    this.hierarchyId = options.hierarchyId;
    this.associationType = options.associationType;
  },

  url: function () {
    let url = `clients/${this.client}/associations`;

    if (this.capabilityGroup) {
      url += `/${this.capabilityGroup}`;
      if (this.hierarchyId) {
        url += `/hierarchy/${this.hierarchyId}`;
      } else if (this.associationType) {
        url += `/${this.associationType}`;
      }
    }
    return url;
  },

  parse: function (data) {
    if (this.capabilityGroup && data.hierarchy) {
      data.hierarchy.forEach((h) => {
        h.capabilityGroup = this.capabilityGroup;
      });
    } else if (this.capabilityGroup && data.config) {
      data.capabilityGroup = this.capabilityGroup;
    } else {
      if (data[Constants.HIERARCHY.CAPABILITIES.CXMEASURE]) {
        data[Constants.HIERARCHY.CAPABILITIES.CXMEASURE].hierarchy.forEach(
          (h) => {
            h.capabilityGroup = Constants.HIERARCHY.CAPABILITIES.CXMEASURE;
          }
        );
      }
    }
    return data;
  },

  toJSON: function () {
    let data = this.models.length > 0 ? this.models[0].toJSON() : [];
    if (!this.capabilityGroup) {
      if (!data['cx-measure']) {
        data['cx-measure'] = { hierarchy: [] };
      }
    }
    return data;
  },
});

export default HierarchyAssociationsCollection;

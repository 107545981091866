import Backbone from 'backbone';
import Constants from 'core/constants';

const { HOLLOW_DASHBOARD_TYPE } = Constants;

const DashboardModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.id = options.id;
    this.changeOwnerId = options.changeOwnerId;
  },

  idAttribute: 'dashboardId',

  parse: function (data) {
    data.isTemplate = data.type === HOLLOW_DASHBOARD_TYPE;
    return data;
  },

  url: function () {
    if (this.id) {
      if (this.changeOwnerId) {
        return `clients/${this.client}/dashboards/${this.id}/changeowner?destinationOwnerId=${this.changeOwnerId}`;
      }
      return `clients/${this.client}/dashboards/${this.id}`;
    }
    return `clients/${this.client}/dashboards`;
  },
});

export default DashboardModel;

import React from 'react';
import BasePanel from 'views/panels/base';
import ContentResetView from 'views/panels/password/content-reset';

import { Text, FormInput, FormButton } from 'cx-component-library';
import TemplatedComponent from 'views/widgets/templated-component';

class ContentForgotSMS extends BasePanel {
  state = {
    isValidatingCode: false,
    showResetPasswordView: false,
    code: '',
  };

  renderSMSCodeVerify() {
    const { code, isValidatingCode, failedCodeToken } = this.state;

    return (
      <div className='login login--desktop'>
        <div className='login__header'>
          <div className='logo-image' />
        </div>
        <div className='login__content gutter--bottom'>
          <Text theme='body' type='div' className='gutter--bottom'>
            <TemplatedComponent
              string='password.forgot.sms.sent'
              tokens={{
                link: (
                  <Text
                    target='_blank'
                    type='a'
                    href='https://connect.verint.com/'
                  >
                    {this.getString('general.serviceName')}
                  </Text>
                ),
              }}
            />
          </Text>

          <div className='gutter-p--bottom'>
            <FormInput
              label={this.getString(
                'password.forgot.sms.enterVerificationCode'
              )}
              placeholder={this.getString('password.forgot.sms.placeholder')}
              value={code}
              onChange={this.handleCodeChange}
              errorString={
                failedCodeToken && this.getString('password.forgot.sms.failed')
              }
              error={failedCodeToken}
              size='xlarge'
              autoFocus
            />

            <FormButton
              className='gutter--top'
              size='xlarge'
              disabled={!code || isValidatingCode}
              onClick={this.handleCodeVerify}
              icon={isValidatingCode ? 'loader' : ''}
              iconSpin={isValidatingCode}
              label={!isValidatingCode && this.getString('general.continue')}
            />
          </div>

          <div className='clearfix gutter--vertical'>
            <Text
              theme='body'
              className='left'
              type='div'
              linkTo={this.handleReturnToLoginClick}
            >
              {this.getString('password.forgot.returnToLogin')}
            </Text>
            <Text
              theme='body'
              className='right'
              type='div'
              linkTo={this.handleLinkToEmailReset}
            >
              {this.getString('password.forgot.sms.notReceivingSMS')}
            </Text>
          </div>
        </div>
        <div className='login__content--bottom' />
      </div>
    );
  }

  render() {
    const { showResetPasswordView, passwordPolicy, stateToken } = this.state;

    return (
      <div>
        {showResetPasswordView && (
          <ContentResetView
            passwordPolicies={passwordPolicy}
            stateToken={stateToken}
          />
        )}
        {!showResetPasswordView && this.renderSMSCodeVerify()}
      </div>
    );
  }

  handleCodeVerify = () => {
    const { code } = this.state;
    const { config } = this.props;

    const params = config.params || {};
    this.setState({ isValidatingCode: true, failedCodeToken: false });
    this.publish('authenticate:resetToken:verify', {
      token: params.stateToken,
      passCode: code,
      factorType: 'SMS',
      callback: (data) => {
        const { passwordPolicy, stateToken } = data;
        this.setState({
          isValidatingCode: false,
          showResetPasswordView: true,
          passwordPolicy: passwordPolicy,
          stateToken: stateToken,
        });
      },
      errorCallback: () => {
        this.setState({ isValidatingCode: false, failedCodeToken: true });
      },
    });
  };

  handleCodeChange = (e, code) => {
    this.setState({ code });
  };

  handleLinkToEmailReset = () => {
    this.changeRoute('forgot.index');
  };

  handleReturnToLoginClick = () => {
    this.changeRoute('login');
  };
}

export default ContentForgotSMS;

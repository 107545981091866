import Backbone from 'backbone';

var BMCat = Backbone.Model.extend({
  /**
   * Default attributes - these are not suitable for transmission to server, and are intended
   * merely to initialize the properties.
   */
  defaults: {
    key: 0,
    desc: '',
    measCat: false,
  },

  idAttribute: 'key',

  /**
   * Constructor
   */
  initialize: function (opts) {},

  // How long to cache this for in local storage. To avoid any caching, omit this attribute
  //cacheMS: 1000 * 60 * 60
});

export default BMCat;

import { cloneDeep } from 'lodash';

var StateMixin = {
  /**
   * Retrieves deeply copied property from state so it is safe to use without mutating state.
   * @param string
   */
  getState: function (prop) {
    var item = this.state[prop];

    if (typeof item !== 'object') {
      return item;
    } else {
      if (item === null) {
        return item;
      } else {
        return cloneDeep(item);
      }
    }
  },
};

export default StateMixin;

import FeatureFlags from 'core/featureFlags';

const BENCHMARK_PERMISSION = 'use_benchmarks';

const getBenchmarksRoutes = () => {
  const route = {
    icon: 'navBenchmark',
    label: 'Benchmarks',
    controller: 'user',
    permissions: BENCHMARK_PERMISSION,
    layout: 'navigation',
    tabs: [
      {
        routes: [],
      },
    ],
  };

  if (FeatureFlags.hasFeature('benchmarks-category-information')) {
    route.tabs[0].routes.push('category-information');
    route['category-information'] = {
      label: 'Category Information',
      layout: 'category-information',
      permissions: BENCHMARK_PERMISSION,
    };
  }
  if (FeatureFlags.hasFeature('benchmark-participant-information')) {
    route.tabs[0].routes.push('participant-information');
    route['participant-information'] = {
      label: 'Participant Information',
      layout: 'participant-information',
      permissions: BENCHMARK_PERMISSION,
    };
  }

  return route;
};

export default getBenchmarksRoutes;

import Loadable from 'react-loadable';
import Loading from 'views/widgets/loading';
import NavigationPanel from 'views/panels/navigation/base';
import ContentAuth from 'views/panels/content-auth';
import ContentForgot from 'views/panels/password/content-forgot';
import ContentForgotEmail from 'views/panels/password/forgot/content-forgot-email';
import ContentForgotSMS from 'views/panels/password/forgot/content-forgot-sms';
import ContentReset from 'views/panels/password/content-reset';

const HeaderVerintTop = Loadable({
  loader: () => import('views/panels/header-verintTop'),
  loading: Loading,
});
HeaderVerintTop.preload();

const NavUnified = Loadable({
  loader: () => import('views/panels/nav-unified'),
  loading: Loading,
});
NavUnified.preload();

const HeaderSiteNav = Loadable({
  loader: () => import('views/panels/header-site-nav'),
  loading: Loading,
});
HeaderSiteNav.preload();

const ContentProjects = Loadable({
  loader: () => import('views/panels/content-projects'),
  loading: Loading,
});
ContentProjects.preload();

const ContentProjectEdit = Loadable({
  loader: () => import('views/panels/content-project-edit'),
  loading: Loading,
});
const ContentProjectFeedbackType = Loadable({
  loader: () => import('views/panels/content-project-feedback-type'),
  loading: Loading,
});
ContentProjectFeedbackType.preload();

const ContentProjectFeedbackCollection = Loadable({
  loader: () => import('views/panels/content-project-feedback-collection'),
  loading: Loading,
});
ContentProjectFeedbackCollection.preload();

const ContentProjectFeedbackWeblink = Loadable({
  loader: () => import('views/panels/content-project-feedback-weblink'),
  loading: Loading,
});

const ContentProjectFeedbackPublish = Loadable({
  loader: () => import('views/panels/content-project-feedback-publish'),
  loading: Loading,
});
ContentProjectFeedbackPublish.preload();

const ContentCreateFeedback = Loadable({
  loader: () => import('views/panels/content-create-feedback'),
  loading: Loading,
});
ContentCreateFeedback.preload();

const ContentError = Loadable({
  loader: () => import('views/panels/content-error'),
  loading: Loading,
});
ContentError.preload();

const SidebarProjectDnd = Loadable({
  loader: () => import('views/panels/sidebar-project-dnd'),
  loading: Loading,
});
SidebarProjectDnd.preload();

const ContentInsightsOpenends = Loadable({
  loader: () => import('views/panels/content-insights-openends'),
  loading: Loading,
});
ContentInsightsOpenends.preload();

const ContentInsightsInsights = Loadable({
  loader: () => import('views/panels/content-insights-questions'),
  loading: Loading,
});
ContentInsightsInsights.preload();

const ContentInsightsSummary = Loadable({
  loader: () => import('views/panels/content-insights-summary'),
  loading: Loading,
});
ContentInsightsSummary.preload();

const ContentCxInsightsSummary = Loadable({
  loader: () => import('views/panels/content-cx-insights-summary'),
  loading: Loading,
});
ContentCxInsightsSummary.preload();

const CxSurvey = Loadable({
  loader: () => import('views/panels/cx-survey'),
  loading: Loading,
});
CxSurvey.preload();

const ContentAnalyticsOpenends = Loadable({
  loader: () => import('views/panels/respondents/view-responses/measures'),
  loading: Loading,
});
ContentAnalyticsOpenends.preload();

const ContentAlerts = Loadable({
  loader: () => import('views/panels/content-alerts'),
  loading: Loading,
});
ContentAlerts.preload();

const ContentAlertsEdit = Loadable({
  loader: () => import('views/panels/content-alerts-edit'),
  loading: Loading,
});
ContentAlertsEdit.preload();

const ContentTextAnalyticsTopicsView = Loadable({
  loader: () => import('views/panels/text-analytics/topics'),
  loading: Loading,
});
ContentTextAnalyticsTopicsView.preload();

const ContentTextAnalyticsOverview = Loadable({
  loader: () => import('views/panels/text-analytics/overview'),
  loading: Loading,
});
ContentTextAnalyticsOverview.preload();

const ContentTextAnalyticsKeywordsView = Loadable({
  loader: () => import('views/panels/text-analytics/keywords'),
  loading: Loading,
});
ContentTextAnalyticsKeywordsView.preload();

const ContentTextAnalyticsOverviewCardDetail = Loadable({
  loader: () =>
    import('views/panels/text-analytics/content-overview-card-detail'),
  loading: Loading,
});

const SubnavFilterTextAnalytics = Loadable({
  loader: () => import('views/panels/text-analytics/legacy-filter'),
  loading: Loading,
});
SubnavFilterTextAnalytics.preload();

const SubnavFilterNotifications = Loadable({
  loader: () => import('views/panels/subnav-filter-notifications'),
  loading: Loading,
});
SubnavFilterNotifications.preload();

const ChartPanel = Loadable({
  loader: () => import('views/panels/content-chart-panel'),
  loading: Loading,
});
ChartPanel.preload();

const CXFilters = Loadable({
  loader: () => import('views/panels/subnav-cx-filters'),
  loading: Loading,
});
CXFilters.preload();

const FeedbackFilters = Loadable({
  loader: () => import('views/panels/subnav-feedback-filters'),
  loading: Loading,
});
FeedbackFilters.preload();

const DashboardFilters = Loadable({
  loader: () => import('views/panels/dashboards/subnav-filter-dashboards'),
  loading: Loading,
});
DashboardFilters.preload();

const ContentHierarchy = Loadable({
  loader: () => import('views/panels/settings/setup/general/content-hierarchy'),
  loading: Loading,
});
ContentHierarchy.preload();

const ContentSettings2HierarchyNew = Loadable({
  loader: () => import('views/panels/settings/setup/hierarchy/new'),
  loading: Loading,
});
ContentSettings2HierarchyNew.preload();

const ContentHierarchyDetails = Loadable({
  loader: () => import('views/panels/settings/setup/hierarchy/details'),
  loading: Loading,
});
ContentHierarchyDetails.preload();

const ContentHierarchyVersionsDetail = Loadable({
  loader: () => import('views/panels/settings/setup/hierarchy/versions-detail'),
  loading: Loading,
});
ContentHierarchyVersionsDetail.preload();

const ContentHierarchyDraftsDetail = Loadable({
  loader: () => import('views/panels/settings/setup/hierarchy/drafts-detail'),
  loading: Loading,
});
ContentHierarchyDraftsDetail.preload();

const ContentMasterData = Loadable({
  loader: () =>
    import('views/panels/settings/setup/general/content-masterdata'),
  loading: Loading,
});
ContentMasterData.preload();

const ContentMasterDataDetails = Loadable({
  loader: () => import('views/panels/settings/setup/masterdata/details'),
  loading: Loading,
});
ContentMasterDataDetails.preload();

const ContentMasterDataCreate = Loadable({
  loader: () =>
    import('views/panels/settings/setup/masterdata/create-new-type'),
  loading: Loading,
});
ContentMasterDataCreate.preload();

const ContentSettingsInpage = Loadable({
  loader: () =>
    import('views/panels/settings/apps/feedback/content-settings-inpage'),
  loading: Loading,
});
ContentSettingsInpage.preload();

const ContentSettingsTextCustomFeeds = Loadable({
  loader: () =>
    import('views/panels/settings/apps/text-analytics/content-ta-feeds-custom'),
  loading: Loading,
});
ContentSettingsTextCustomFeeds.preload();

const ContentSettingsTextCustomFeedsDetail = Loadable({
  loader: () =>
    import(
      'views/panels/settings/apps/text-analytics/content-ta-feeds-custom-detail'
    ),
  loading: Loading,
});
ContentSettingsTextCustomFeedsDetail.preload();

const ContentSettings2Integrate = Loadable({
  loader: () =>
    import(
      'views/panels/settings/integrate/hosted-code/content-integrate-settings'
    ),
  loading: Loading,
});
ContentSettings2Integrate.preload();

const SettingsTextKeywordManager = Loadable({
  loader: () =>
    import(
      'views/panels/settings/apps/text-analytics/content-ta-keyword-manager'
    ),
  loading: Loading,
});
SettingsTextKeywordManager.preload();

const ContentLeaderboardDashboardView = Loadable({
  loader: () => import('views/panels/dashboards/content-leaderboard-dashboard'),
  loading: Loading,
});
ContentLeaderboardDashboardView.preload();

const ContentLeaderboardDetailsView = Loadable({
  loader: () => import('views/panels/dashboards/content-leaderboard-details'),
  loading: Loading,
});
ContentLeaderboardDetailsView.preload();

const SubnavLeaderboardDetailsView = Loadable({
  loader: () => import('views/panels/dashboards/subnav-leaderboard-details'),
  loading: Loading,
});
SubnavLeaderboardDetailsView.preload();

const ContentSettingsHierarchySetup = Loadable({
  loader: () => import('views/panels/settings/setup/hierarchy/setup'),
  loading: Loading,
});
ContentSettingsHierarchySetup.preload();

const SubnavSettings = Loadable({
  loader: () => import('views/panels/subnav-settings'),
  loading: Loading,
});
SubnavSettings.preload();

const ContentDashboard = Loadable({
  loader: () => import('views/panels/dashboards/content-dashboard'),
  loading: Loading,
});

const CreateNewDashboard = Loadable({
  loader: () => import('views/panels/dashboards/modal-new-dashboard'),
  loading: Loading,
});
CreateNewDashboard.preload();

const ContentDashboardDetail = Loadable({
  loader: () => import('views/panels/dashboards/content-dashboard-detail'),
  loading: Loading,
});
ContentDashboardDetail.preload();

const ContentUserProfile = Loadable({
  loader: () => import('views/panels/content-user-profile'),
  loading: Loading,
});
ContentUserProfile.preload();

const ContentClientAccess = Loadable({
  loader: () =>
    import('views/panels/settings/setup/general/content-client-access'),
  loading: Loading,
});
ContentClientAccess.preload();

const ContentUsers = Loadable({
  loader: () => import('views/panels/settings/setup/general/content-users'),
  loading: Loading,
});
ContentUsers.preload();

const ContentRoles = Loadable({
  loader: () => import('views/panels/settings/setup/general/content-roles'),
  loading: Loading,
});
ContentRoles.preload();

const ContentRoleAssignments = Loadable({
  loader: () =>
    import('views/panels/settings/setup/general/content-role-assignments'),
  loading: Loading,
});
ContentRoleAssignments.preload();

const ContentChangeLog = Loadable({
  loader: () =>
    import('views/panels/settings/setup/general/content-change-log'),
  loading: Loading,
});
ContentChangeLog.preload();

const ContentCombinedDataSources = Loadable({
  loader: () =>
    import('views/panels/settings/setup/general/content-combined-data-sources'),
  loading: Loading,
});
ContentCombinedDataSources.preload();

const ContentHierarchySetup = Loadable({
  loader: () => import('views/panels/settings/setup/hierarchy/setup'),
  loading: Loading,
});
ContentHierarchySetup.preload();

const ContentHierarchyNew = Loadable({
  loader: () => import('views/panels/settings/setup/hierarchy/new'),
  loading: Loading,
});
ContentHierarchyNew.preload();

const ContentUserDetails = Loadable({
  loader: () => import('views/panels/settings/users/content-user-details'),
  loading: Loading,
});
ContentUserDetails.preload();

const ContentRoleEdit = Loadable({
  loader: () => import('views/panels/settings/roles/content-role-edit'),
  loading: Loading,
});
ContentRoleEdit.preload();

const ContentRoleNew = Loadable({
  loader: () => import('views/panels/settings/roles/content-role-new'),
  loading: Loading,
});
ContentRoleNew.preload();

const ContentAssignmentRuleEdit = Loadable({
  loader: () => import('views/panels/settings/rules/content-rule-edit'),
  loading: Loading,
});
ContentAssignmentRuleEdit.preload();

const ContentAssignmentRuleNew = Loadable({
  loader: () => import('views/panels/settings/rules/content-rule-new'),
  loading: Loading,
});
ContentAssignmentRuleNew.preload();

const ContentSettingsDataConnectors = Loadable({
  loader: () =>
    import(
      'views/panels/settings/integrate/data-connectors/content-settings-data-connectors'
    ),
  loading: Loading,
});
ContentSettingsDataConnectors.preload();

const ContentAnomalySegments = Loadable({
  loader: () =>
    import('views/panels/analyze/anomaly-detection/content-anomaly-segments'),
  loading: Loading,
});
ContentAnomalySegments.preload();

const ContentAnomalyDetail = Loadable({
  loader: () =>
    import('views/panels/analyze/anomaly-detection/content-anomaly-detail'),
  loading: Loading,
});
ContentAnomalyDetail.preload();

const ContentBenchmarkCategoryInformation = Loadable({
  loader: () => import('views/panels/benchmarks/content-category-information'),
  loading: Loading,
});
ContentBenchmarkCategoryInformation.preload();

const ContentBenchmarkParticipantInformation = Loadable({
  loader: () =>
    import('views/panels/benchmarks/content-participant-information'),
  loading: Loading,
});
ContentBenchmarkParticipantInformation.preload();

const ContentDataSources = Loadable({
  loader: () =>
    import(
      'views/panels/data-sources/content-data-sources/content-data-sources'
    ),
  loading: Loading,
});
ContentDataSources.preload();

const ContentDataSourceList = Loadable({
  loader: () =>
    import(
      'views/panels/data-sources/content-data-source-list/content-data-source-list'
    ),
  loading: Loading,
});
ContentDataSourceList.preload();

const ContentDataSourceDetail = Loadable({
  loader: () => import('views/panels/data-sources/content-data-source-detail/'),
  loading: Loading,
});
ContentDataSourceDetail.preload();

const FavoriteDataSources = Loadable({
  loader: () =>
    import('views/panels/data-sources/content-data-source-favorites'),
  loading: Loading,
});
FavoriteDataSources.preload();

const ContentDBA = Loadable({
  loader: () => import('views/panels/content-dba'),
  loading: Loading,
});

const ContentDBAReplay = Loadable({
  loader: () => import('views/panels/content-dba-replay'),
  loading: Loading,
});

var Panels = {
  'content-navigation': NavigationPanel,
  'content-dashboards': ContentDashboard,
  'content-auth': ContentAuth,
  'content-forgotpw': ContentForgot,
  'content-forgotpw-email': ContentForgotEmail,
  'content-forgotpw-sms': ContentForgotSMS,
  'content-resetpw': ContentReset,
  'content-projects': ContentProjects,
  'content-project-edit': ContentProjectEdit,
  'content-project-feedback-type': ContentProjectFeedbackType,
  'content-project-feedback-collection': ContentProjectFeedbackCollection,
  'content-project-feedback-weblink': ContentProjectFeedbackWeblink,
  'content-project-feedback-publish': ContentProjectFeedbackPublish,
  'content-create-feedback': ContentCreateFeedback,
  'content-error': ContentError,
  'header-site-nav': HeaderSiteNav,
  'header-verintTop': HeaderVerintTop,
  'sidebar-unified': NavUnified,
  'sidebar-project-dnd': SidebarProjectDnd,
  'content-insights-openends': ContentInsightsOpenends,
  'content-insights-questions': ContentInsightsInsights,
  'content-insights-summary': ContentInsightsSummary,
  'content-cx-insights-summary': ContentCxInsightsSummary,
  'content-cx-survey': CxSurvey,
  'content-cx-insights-respondents': ContentAnalyticsOpenends,
  'content-alerts': ContentAlerts,
  'content-alerts-edit': ContentAlertsEdit,
  'subnav-filter-notifications': SubnavFilterNotifications,
  'content-chart-panel': ChartPanel,
  'subnav-cx-filters': CXFilters,
  'subnav-feedback-filters': FeedbackFilters,
  'subnav-dashboards': DashboardFilters,
  'content-settings-text-keyword-manager': SettingsTextKeywordManager,

  'content-dashboard': ContentDashboard,
  'content-dashboard-detail': ContentDashboardDetail,
  'content-new-dashboard': CreateNewDashboard,
  'content-leaderboard-dashboard': ContentLeaderboardDashboardView,
  'content-leaderboard-details': ContentLeaderboardDetailsView,
  'subnav-leaderboard-details': SubnavLeaderboardDetailsView,

  'content-settings-inpage': ContentSettingsInpage,

  'content-settings-text-feeds-custom': ContentSettingsTextCustomFeeds,
  'content-settings-text-feeds-custom-detail':
    ContentSettingsTextCustomFeedsDetail,

  'content-settings2-integrate': ContentSettings2Integrate,

  'subnav-settings': SubnavSettings,
  'content-user-profile': ContentUserProfile,

  //
  // Start of Text Analytics
  //
  'content-ta-topics': ContentTextAnalyticsTopicsView,
  'content-ta-keywords': ContentTextAnalyticsKeywordsView,
  'content-ta-overview-card-detail': ContentTextAnalyticsOverviewCardDetail,
  'subnav-filter-ta': SubnavFilterTextAnalytics,
  'content-ta-overview': ContentTextAnalyticsOverview,

  //
  // Start of Setting Layouts
  //
  // General Setting Layouts
  'content-settings-general-client-access': ContentClientAccess,
  'content-settings-general-users': ContentUsers,
  'content-settings-general-roles': ContentRoles,
  'content-settings-general-role-assignments': ContentRoleAssignments,
  'content-settings-general-change-log': ContentChangeLog,
  // Custom Data Sources
  'content-settings-general-custom-data-sources': ContentCombinedDataSources,
  // Hierarchy Settings
  'content-settings-hierarchy': ContentHierarchy,
  'content-settings-hierarchy-setup': ContentHierarchySetup,
  'content-settings-hierarchy-new': ContentHierarchyNew,
  'content-settings-hierarchy-details': ContentHierarchyDetails,
  'content-settings-hierarchy-versions-detail': ContentHierarchyVersionsDetail,
  'content-settings-hierarchy-drafts-detail': ContentHierarchyDraftsDetail,
  // Masterdata Settings
  'content-settings-masterdata': ContentMasterData,
  'content-settings-masterdata-details': ContentMasterDataDetails,
  'content-settings-masterdata-create': ContentMasterDataCreate,
  // User Settings
  'content-settings-user-details': ContentUserDetails,
  // Role Settings
  'content-settings-role-edit': ContentRoleEdit,
  'content-settings-role-new': ContentRoleNew,
  // Assignment Rule Settings
  'content-settings-rule-edit': ContentAssignmentRuleEdit,
  'content-settings-rule-new': ContentAssignmentRuleNew,
  // Integration Settings
  'content-settings-data-connectors': ContentSettingsDataConnectors,
  //
  // End of Setting Layouts
  //
  //
  // Start of Analyze Layouts
  //
  // Start of Anomaly Layouts
  'content-anomaly-segments': ContentAnomalySegments,
  'content-anomaly-detail': ContentAnomalyDetail,
  //
  // Start of Benchmarks
  'content-benchmark-category-information': ContentBenchmarkCategoryInformation,
  'content-benchmark-participant-information':
    ContentBenchmarkParticipantInformation,
  // End of Benchmarks
  //
  // Start of Data Sources
  'content-data-sources': ContentDataSources,
  'content-data-sources-list': ContentDataSourceList,
  'content-data-source-detail': ContentDataSourceDetail,
  'content-data-source-favorites': FavoriteDataSources,
  // End of Data Sources
  // Redirect
  'content-dba': ContentDBA,
  'content-dba-replay': ContentDBAReplay,
  // End of Redirect
};
export default Panels;

import constants from 'core/constants';
import JSTimezoneDetect from 'jstimezonedetect';

function getDateISOString(date) {
  return new Date(date).toISOString();
}

export default function mixinCardDate(card, paramDateRange, selectedDateId) {
  if (
    paramDateRange &&
    selectedDateId &&
    card &&
    card.metadata.attributes.dateRange
  ) {
    const dateRange = {
      ...paramDateRange,
    };
    const cardDateRange = card.metadata.attributes.dateRange;

    if (cardDateRange.t === constants.DATE_TIME) {
      if (!dateRange.z) {
        dateRange.z = cardDateRange.z || JSTimezoneDetect.determine().name();
      }

      if (dateRange.hasOwnProperty('a')) {
        dateRange.a = getDateISOString(Date.now());
      }
      if (dateRange.hasOwnProperty('f')) {
        dateRange.f = getDateISOString(dateRange.f + 'T00:00:00');
      }
      if (dateRange.hasOwnProperty('l')) {
        dateRange.l = getDateISOString(dateRange.l + 'T23:59:59');
      }
    }
    return {
      ...card,
      metadata: {
        ...card.metadata,
        attributes: {
          ...card.metadata.attributes,
          dateRange: {
            ...dateRange,
            k: card.metadata.attributes.dateRange.k,
            t: card.metadata.attributes.dateRange.t,
          },
          selectedDateId,
        },
      },
    };
  }
  return card;
}

import Backbone from 'backbone';
import LatentModel from 'models/clients/measures/latent';

var DefinitionCollection = Backbone.Collection.extend({
  model: LatentModel,
  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.modelInstanceId = options.modelInstanceId;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/surveys/${this.modelInstanceId}/versions/latest`;
  },
});

export default DefinitionCollection;

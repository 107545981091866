import Backbone from 'backbone';

const DashboardCopyModel = Backbone.Model.extend({
  initialize: function (model, opts) {
    var options = opts || {};
    this.client = options.clientId;
    this.id = options.dashboardId;
  },

  url: function () {
    return `clients/${this.client}/dashboards/${this.id}/copy`;
  },
});

export default DashboardCopyModel;

import Backbone from 'backbone';

const ResetPasswordModel = Backbone.Model.extend({
  initialize: function (model, opts) {},

  url: function () {
    return `platform-scim-service/password`;
  },
});

export default ResetPasswordModel;

import Constants from 'core/constants';
const { cxmeasurebd } = Constants.DASHBOARD.sourceType;

export default function hasCxmeasureBDSource(card) {
  const attributes = card?.metadata?.attributes;
  const dataSources = attributes?.dataSources || [];
  const allDataSources = attributes?.allDataSources;
  const surveyType = attributes?.surveyType;

  return (
    (allDataSources && surveyType === cxmeasurebd) ||
    (!!dataSources.length && dataSources[0].type === cxmeasurebd)
  );
}

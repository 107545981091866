import Backbone from 'backbone';

var PPTRequestModel = Backbone.Model.extend({
  idAttribute: 'exportId',
  // exportFileType: 'pptx',

  initialize: function (model, opts) {
    var options = opts || {};
    this.fileName = options.fileName;
    this.format = options.format || 'ppt';
    const fileTypeMap = {
      ppt: 'pptx',
      pdf: 'pdf',
    };
    this.exportFileType = fileTypeMap[options.format];
  },

  // export related methods
  getSaveFileOptions() {
    return {
      responseType: 'arraybuffer',
      contentType: 'application/json; charset=utf-8',
    };
  },
  hasExportFailed: function (response) {
    return response.failed;
  },
  hasExportCompleted: function (response) {
    return response.completed;
  },

  url: function () {
    if (this.get('exportCompleted')) {
      return `ppt-export-service/${this.format}/${this.get('exportId')}/file`;
    }

    if (this.get('exportId')) {
      return `ppt-export-service/${this.format}/${this.get('exportId')}`;
    }

    return `ppt-export-service/${this.format}`;
  },
});

export default PPTRequestModel;

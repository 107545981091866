import Backbone from 'backbone';
import Permissions from 'core/permissions';

const UserRolesModel = Backbone.Model.extend({
  idAttribute: 'roleId',

  initialize: function (model, opts) {
    this.userId = opts.userId;
    this.roleId = opts.roleId;
  },

  getAssignedById: function (assignedBy) {
    if (this.get('assignedBy') !== assignedBy) {
      return null;
    }

    return this.get('assignedById');
  },

  getRuleIds: function () {
    return this.getAssignedById('RULE');
  },

  getUserIds: function () {
    return this.getAssignedById('USER');
  },

  setRuleNames: function (rule) {
    if (rule) {
      this.set('assignedByName', rule.assignmentRuleName);
      return;
    }

    this.set('assignedByName', this.get('assignedById'));
  },

  setUserNames: function (user) {
    if (!Permissions.userRole('admin_support') && user.internal) {
      this.set('assignedByName', `ForeSee Admin`);
      return;
    }

    if (user) {
      this.set('assignedByName', `${user.firstName} ${user.lastName}`);
      return;
    }

    this.set('assignedByName', this.get('assignedById'));
  },

  url: function () {
    if (this.roleId) {
      return `next-gen-authz-service/users/${this.userId}/roles/${this.roleId}`;
    }
  },
});

export default UserRolesModel;

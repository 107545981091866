import $ from 'jquery';
import _ from 'underscore';
import { cloneDeep } from 'lodash';
import Backbone from 'backbone';
import dispatcher from 'core/dispatcher';
import store from 'core/store';
import i18n from 'core/i18n';
import GenerateRoute from 'core/utils/generateRoute';
import apolloClient from 'core/apolloClient';
import getQueryObject from 'core/utils/get-query-object';

class BaseController {
  constructor(args, props) {
    var [options, controllers, startController] = args,
      deps = props.dependsOn || [],
      actions = props.actions,
      defs = [];

    _.extend(this, props, Backbone.Events);

    this._dispatcher = dispatcher;
    this.strings = i18n.strings;
    this.initialized = $.Deferred();
    this.controllers = controllers;
    this.apolloClient = apolloClient;

    // Check for dependsOn property and then verify presence of those controllers before continuing
    deps.forEach((controllerName) => {
      var controller =
        controllers[controllerName] || startController(controllerName) || {};

      defs.push(controller.initialized);
    });

    // Once all dependencies are loaded
    $.when.apply($, defs).done(() => {
      $.when(this.initialize.apply(this, [options]) || true).done(() => {
        _.each(
          actions,
          _.bind(function (cb, action) {
            var handler = typeof cb === 'string' ? this[cb] : cb;

            this.listenTo(this._dispatcher, action, handler);
          }, this)
        );

        if (this.initialized && this.initialized.resolve) {
          this.initialized.resolve();
        }
        if (this.name) {
          this.publish(this.name + ':initialized');
        }
      });
    });
  }

  initialize(opts) {}

  /**
   * Add a listener to the dispatcher for an action
   * @param action
   * @param cb
   */
  subscribe(action, cb) {
    this.listenTo(this._dispatcher, action, cb);
  }

  /**
   * Remove a particular callback from the dispatcher
   * @param action
   * @param cb
   */
  unsubscribe(action, cb) {
    this.stopListening(this._dispatcher, action, cb);
  }

  /**
   * Publish an action to the dispatcher with a particular payload.
   * @param action
   * @param payload
   * @param rest
   */

  publish(action, payload) {
    this._dispatcher.trigger(action, payload);
  }

  /**
   * Invoke a callback if defined, otherwise publish an action to the dispatcher with a particular payload.
   * @param callback
   * @param action
   * @param payload
   */
  dispatch(callback, action, payload) {
    if (callback) {
      callback(payload);
    } else {
      this.publish(action, payload);
    }
  }

  /**
   * Stop listening and remove controller
   */
  destroy() {
    this.stopListening();
  }

  authenticated() {
    return !!(store.get('accessToken') && store.get('tokenSecret'));
  }

  // Retrieve clientId stored globally
  getClientId() {
    var clientId = null,
      user = this.controllers.user || {},
      userModel = user.model,
      clientModel = user.client;

    if (clientModel) {
      clientId = clientModel.get('clientId');
    } else if (userModel) {
      clientId = userModel.get('clientId');
    }
    return clientId;
  }

  getCurrentUserId() {
    const { model } = this.controllers.user;
    return model.get('userId');
  }

  getCurrentUserMigrated() {
    const { model } = this.controllers.user;
    return model.get('migrated');
  }

  getClientModel() {
    var user = this.controllers.user || {};
    return user.client.toJSON();
  }

  /**
   * Returns deeply copied version of item
   * @param string
   */
  clone(item) {
    if (typeof item !== 'object') {
      return item;
    } else {
      if (item === null) {
        return item;
      } else {
        return cloneDeep(item);
      }
    }
  }

  /**
   * Given a string of form 'a.b.c' will return value from that location in strings.
   * @param string
   */
  getString(string, model) {
    if (string) {
      try {
        const fn = [].constructor.constructor('return arguments[0].' + string);
        const str = fn(this.strings);
        return model ? _.template(str)(model) : str;
      } catch (e) {
        return '**_' + string + '_**';
      }
    } else {
      return '';
    }
  }

  /**
   *  Given a string defined with interpolation tokens of the form ${token},
   *  resolve those tokens from the properties of a provided object.
   *
   *
   */
  getTemplatedString(string, tokens) {
    var template = this.getString(string);

    _.templateSettings = {
      interpolate: /\$\{(.+?)\}/g,
    };

    if (string && tokens) {
      return _.template(template)(tokens);
    } else {
      return '';
    }
  }

  /**
   * Tests for feature flag. Returns undefined if flag is not present in config.
   *
   */
  hasFeature(flag) {
    const { model } = this.controllers.user;
    const featureFlags = model.get('featureFlags');

    if (featureFlags && featureFlags.variation) {
      return featureFlags.variation(flag);
    }
    return false;
  }

  changeRoute(key, tokens) {
    const route = GenerateRoute(key, tokens);
    this.publish('route:change', {
      route,
    });
  }

  /**
   * Publish an action to collect event data in analytics system
   * @param category
   * @param action
   **/
  logEvent(category, action) {
    this.publish('track:event', {
      category: category,
      action: action,
    });
  }

  _getQueryObject(str) {
    return getQueryObject(str);
  }
}

export default BaseController;

import Backbone from 'backbone';
import QuestionModel from 'models/clients/measures/question';

var QuestionsCollection = Backbone.Collection.extend({
  model: QuestionModel,
  initialize: function (opts) {
    var options = opts || {};

    this.client = options.clientId;
    this.measure = options.measure;
  },

  serviceId: 'foresee-services',

  url: function () {
    return `clients/${this.client}/measures/${this.measure}/questions`;
  },

  comparator: 'questionOrder',
});

export default QuestionsCollection;
